import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormControl, NgForm, FormGroup, FormBuilder, Validators, FormArray, ReactiveFormsModule } from '@angular/forms';
import { DataSourceControllerService } from 'src/app/services/api/data-source/data-source-controller.service';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { CatalogService } from "../../../../../../../../services/api/catalogService/catalog.service"
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { CdkDragDrop, moveItemInArray, transferArrayItem, copyArrayItem } from '@angular/cdk/drag-drop';
import { RdbmsIngestionControllerService } from 'src/app/services/api/rdbms-controller/rdbms-ingestion-controller.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';

import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import { IngestionSharingServiceService } from 'src/app/ingestion-sharing-service.service';
import * as $ from 'jquery';
import { ProjectService } from 'src/app/services/project/project-service.service';

export interface database {
  destinationDatabaseName: string,

  sourceDatabaseName: string,
  tables: tables[];

}

export interface tables {
  destinationTableName: string,

  sourceTableName: string,
  tableId: string,
  schema?: string,
  incremental?: string,

  columns: columns[];

}

interface toggle {
  name: string;
}

export interface columns {
  sourceColumnName: string,
  destinationColumnName: string,

  sourceDataType: string,
  destinationDataType: string,
  primaryKey: boolean
  checkColumn: boolean

}
@Component({
  selector: 'app-edit-step-two-database',
  templateUrl: './edit-step-two-database.component.html',
  styleUrls: ['./edit-step-two-database.component.scss']
})
export class EditStepTwoDatabaseComponent implements OnInit {


  //snackbar 

  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  //datasource

  singleDataSource: any

  itemObjectsLeft: database[] = [];
  itemObjectsRight: database[] = [];
  itemObjectsRightVisual: database[] = [];

  itemArrayOne: database[] = [];
  itemArrayTwo: database[] = [];


  term: string
  dataSources: any;
  dataSourceData: any;

  //source and destination fields
  sourceDatabases: database[] = []
  sourceDatabasesTwo: any = []

  sourceTables: any = []
  sourceTablesTwo: any = []
  sourceColumns: any = []

  dataObject: any;
  //treeview fields
  isActive: boolean;
  tableIsActive: boolean;
  columnIsActive: boolean;
  isActiveDestination: boolean;
  tableIsActiveDestination: boolean;
  columnIsActiveDestination: boolean;
  showToggle: any = [];

  status = false;
  buttonEnable: boolean = false;

  //loader

  database: any = []
  allDatabases: any = []
  allTables: database[] = []
  columns: any = []

  toggle: any = [];
  currentIngestion: any

  //disable next button until item is dropped
  itemDropped: boolean = false;


  toggleList(name: string) {
    this.toggle[name] = !this.toggle[name]
  }

  isSubmitShow: boolean = false;
  id = this.activatedRoute.snapshot.params['id'];



  ngAfterViewInit() {
    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);

      function dropheight() {
        var contenth = $(window).height() - 110;
        var dropheight = contenth - 220;
        $(".dragdrop_height").css("max-height", dropheight);
      }
      setTimeout(dropheight, 2);

    });

    $(window).resize(function () {
      function dropheight() {
        var contenth = $(window).height() - 110;
        var dropheight = contenth - 220;
        $(".dragdrop_height").css("max-height", dropheight);
      }
      setTimeout(dropheight, 2);
    })
  }

  constructor(private formBuilder: FormBuilder, private dataSourceController: DataSourceControllerService,
    private catalogService: CatalogService, public service: RdbmsIngestionControllerService, public router: Router,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private ingestionService: IngestionSharingServiceService,
    private rdbmsService: RdbmsIngestionControllerService,
    public projectService: ProjectService,

  ) { }

  ingestionId = this.activatedRoute.snapshot.params['id3'];

  projectName = this.activatedRoute.snapshot.params['id2'];

  //STEPPER

  showDiv = {
    stepone: false,
    steptwo: true,
    stepthree: false,
    stepfour: false,
    stepfive: false,

    stepbtnone: true,
    stepbtntwo: false,
    stepbtnthree: false,
    stepbtnfour: false,
    stepbtnfive: false,

    cancelbtn: false,
    nextbtn: true,
    nextbtntwo: false,
    nextbtnthree: false,
    nextbtnfour: false,


    backbtn: false,
    backbtntwo: false,
    backbtnthree: false,
    backbtnfour: false,
    backbtnfive: false,

    submitbtn: false
  }

  nullcheck(value: any) {
    if (value == null) {
      return ""
    } else {
      return value + "."
    }
  }


  //grab databases 
  async getDatabase(id: string) {
    var start = window.performance.now();

    let databaseResponse = await this.catalogService.getCatalogforDatabases(id).toPromise()
    let data: any = databaseResponse
    this.database = data.sourceDatabases;



    this.getTable()
    var end = window.performance.now();
    var time = end - start;


    console.timeEnd()

  }
  getTable() {



    this.database.map(async database => {
      await this.catalogService.getCatalog('tables/' + database.databaseId).toPromise().then(databasesWithTables => {
        let tableData: any = []
        tableData = databasesWithTables


        this.allDatabases.push(databasesWithTables)
        delete this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].id
        this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].destinationDatabaseName = this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].sourceDatabaseName
        tableData.tables.map(async index => {
          await this.catalogService.getCatalog('columns/' + index.tableId).toPromise().then(columnsFromTables => {
            let data: any = columnsFromTables
            // tableData.tables = data.columns
            // console.log(tableData.tables[tableData.tables.indexOf(index)].columns)
            this.columns = tableData
            this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables[this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables.indexOf(index)].destinationTableName = data.tableName
            this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables[this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables.indexOf(index)].columns = data.columns
            this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables[this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables.indexOf(index)].incremental = "full load"
            this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables[this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables.indexOf(index)].schema = data.schema

            this.allTables.push({
              destinationDatabaseName: database.sourceDatabaseName, sourceDatabaseName: database.sourceDatabaseName,
              tables: [this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables[this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables.indexOf(index)]]
            })

            data.columns.map(columnIndex => {
              this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables[this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables.indexOf(index)].columns[this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables[this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables.indexOf(index)].columns.indexOf(columnIndex)].destinationColumnName = this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables[this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables.indexOf(index)].columns[this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables[this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables.indexOf(index)].columns.indexOf(columnIndex)].sourceColumnName


              this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables[this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables.indexOf(index)].columns[this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables[this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables.indexOf(index)].columns.indexOf(columnIndex)].checkColumn = false
            })

          })
        })


      })
    })




  }


  //drag and drop 
  drop(event: CdkDragDrop<database[]>) {

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      this.itemDropped = true;

    }

    // for (let i = 0; i < this.itemObjectsRight.length; i++) {

    //   // this.countInArray(this.itemObjectsRight, this.itemObjectsRight[i].sourceDatabaseName, i)
    //   this.itemObjectsRight = this.mergeObjects(this.itemObjectsRight)
    // }
    let temp: string
    let count = 0;




    // console.log(this.itemObjectsRight.length)
    // console.log(this.itemObjectsRight)
    // console.log(event.container.data[event.currentIndex].sourceDatabaseName)
    this.ingestionService.setStepTwoData(this.itemObjectsRight)
    console.log(this.itemObjectsRight)

  }

  //combine tables if databases are the same 

  mergeObjects(array: any) {
    let count = 0

    for (let i = 0; i < array.length; i++) {
      for (let j = 1; j < (array.length - i); j++) {
        // console.log("first: " + (j - 1))
        // console.log("second : " + j)

        if (array[j - 1].sourceDatabaseName == array[j].sourceDatabaseName) {



          // console.log(array[j - 1].tables.concat(array[j].tables));
          let newArray: any = array[j - 1]
          newArray.tables = array[j - 1].tables.concat(array[j].tables)
          array.splice(j, 1)
          // array.push({ sourceDatabaseName: array[j - 1].sourceDatabaseName, tables: [array[j - 1].tables, array[j].tables,] })



        }
      }
    }

    return array
  }


  //delete table from droplist
  deleteDatabase(parent: any, child: any) {

    // Swal.fire({
    //   type: 'warning',

    //   title: "Are you sure you want to delete this database?",
    //   showCancelButton: true, confirmButtonText: 'Yes',
    //   cancelButtonText: 'No'
    // }).then(result => {
    // if (result.isConfirmed == true) {
    parent.splice(child, 1)

    this.ingestionService.setStepTwoData(this.itemObjectsRight)
    //   }
    // })
  }

  //delete table from droplist
  deleteTable(parent: any, child: any) {


    parent.splice(child, 1)

    this.ingestionService.setStepTwoData(this.itemObjectsRight)

  }

  deleteColumn(parent: any, child: any) {
    parent.splice(child, 1)
    this.ingestionService.setStepTwoData(this.itemObjectsRight)
  }
  async getCurrentIngestion() {
    let response = await this.rdbmsService.getSingleRdbmsIngestion(this.ingestionId).toPromise();
    this.currentIngestion = response
    this.itemObjectsRight = this.currentIngestion.databases
    this.ingestionService.setStepTwoData(this.itemObjectsRight)

    console.log(this.itemObjectsRight)



  }
  carryData() {
    this.router.navigate([`/project/${this.projectName}/ingest/editStepThreeDatabase/${this.id}/ingestion/${this.currentIngestion.id}`]);

  }
  navigateBack() {
    this.router.navigate([`/project/${this.projectName}/ingest/editStepOneDatabase/${this.currentIngestion.id}`]);

  }

  load: boolean = true;
  setLoader(index: number, lastColumn) {
    if (index == (this.allDatabases.length - 1) && lastColumn == true) {
      this.load = false

    }


  }

  loading() {

    return new Promise((resolve, reject) => {

    })
  }

  checkIfDatabaseHasData(data: any) {
    if (data.tables.length == 0) {
      this.error(data.sourceDatabaseName)
    }
  }
  openSnackBar(status: string, statusLogo: string) {
    this.snackBar.open(status, statusLogo, {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['tertiary-active']

    });
  }
  error(databaseName: string) {
    Swal.fire({
      type: 'Error',

      title: 'Ooops!',
      text: 'No tables in ' + databaseName,
      confirmButtonText: 'Ok',
    })
  }

  ngOnInit(): void {
    // this.getAllDatabases(this.id)
    console.log(this.ingestionService.getStepTwoData())
    if (this.ingestionService.getStepTwoData() != null) {
      console.log("object")
      console.log(this.ingestionService.getStepTwoData())

      console.log(this.itemObjectsRight)
      this.itemDropped = true;

    }
    this.getCurrentIngestion()

    this.getDatabase(this.id)
    setTimeout(() => this.load = false, 5000)

    // setTimeout(() => this.load = false, 1200)









  }

}

import { Injectable } from '@angular/core';
import { Config } from '../../../config';
import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { throwError as observableThrowError } from 'rxjs';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { ProjectService } from '../../project/project-service.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

export interface RdbmsIngestionDetails {
  id: string;
  name: string;
  bulkImport: boolean;
  databases: [];
  createdAt: string;
  databaseType: string;
  username: string;
  error: {};
}

@Injectable({
  providedIn: 'root',
})
export class RdbmsIngestionControllerService {
  static url = Config.url;

  constructor(
    private httpClient: HttpClient,
    private projectService: ProjectService
  ) {}

  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  getAllIngestionsByProjectName(name: string): Observable<any[]> {
    return this.httpClient.get<any[]>(
      RdbmsIngestionControllerService.url + '/rdbms/ingestions/project/' + name
    );
  }

  getIngestionCount(): Observable<any[]> {
    return this.httpClient
      .get<any>(RdbmsIngestionControllerService.url + '/rdbms/ingestions/count')
      .pipe(
        retry(1)
        // catchError(this.processError)
      );
  }

  getAllIngestions(): Observable<any[]> {
    return this.httpClient
      .get<any[]>(RdbmsIngestionControllerService.url + '/rdbms/ingestions')
      .pipe(
        retry(1)
        // catchError(this.processError)
      );
  }

  getAllJobs(): Observable<any[]> {
    return this.httpClient
      .get<any[]>(RdbmsIngestionControllerService.url + '/sqoop/jobs/')
      .pipe(
        retry(1)
        // catchError(this.processError)
      );
  }

  getAllIngestionJobs(data: any) {
    return this.httpClient.post<any[]>(
      RdbmsIngestionControllerService.url + '/jobs/rdbms/latest',
      data
    );
  }

  getJob(id: string): Observable<any[]> {
    return this.httpClient
      .get<any[]>(RdbmsIngestionControllerService.url + '/sqoop/job/' + id)
      .pipe(
        retry(1)
        // catchError(this.processError)
      );
  }

  getRdbmsIngestion(id: string) {
    return this.httpClient
      .get(RdbmsIngestionControllerService.url + '/rdbms/ingestion/' + id)
      .pipe(
        retry(1)
        // catchError(this.processError)
      );
  }
 

  getSingleRdbmsIngestion(id: string) {
    return this.httpClient
      .get(RdbmsIngestionControllerService.url + '/rdbms/ingestion/' + id)
      .pipe(
        retry(1)
        // catchError(this.processError)
      );
  }

  getRdbmsIngestionProgress(id: string): Observable<any[]> {
    return this.httpClient
      .get<any[]>(RdbmsIngestionControllerService.url + '/sqoop/' + id)
      .pipe();
  }

  postRdbmsIngestion(data: any) {
    return this.httpClient
      .post(RdbmsIngestionControllerService.url + '/rdbms/ingestion', data)
      .pipe(retry(1), catchError(this.handleError));
  }
  putRdbmsIngestion(data: any) {
    return this.httpClient
      .put(RdbmsIngestionControllerService.url + '/rdbms/ingestion', data)
      .pipe(retry(1), catchError(this.handleError));
  }

  postRdbmsForJobIngestion(data: any) {
    return this.httpClient
      .post(RdbmsIngestionControllerService.url + '/job/rdbms', data)
      .pipe(retry(1), catchError(this.handleError));
  }

  postRdbmsIngestionJob(id: string) {
    return this.httpClient
      .post(RdbmsIngestionControllerService.url + '/job/rdbms/' + id, {})
      .pipe(retry(1), catchError(this.handleError));
  }

  resumeJob(id: string) {
    return this.httpClient
      .post(
        RdbmsIngestionControllerService.url + '/job/rdbms/' + id + '/resume',
        {}
      )
      .pipe(retry(1), catchError(this.handleError));
  }
  updateRdbmsIngestion(data: any): Observable<RdbmsIngestionDetails> {
    return this.httpClient
      .put<RdbmsIngestionDetails>(
        RdbmsIngestionControllerService.url + '/rdbms/ingestion/',
        data
      )
      .pipe(
        retry(1),
        // catchError(this.processError)
        catchError(this.handleError)
      );
  }

  deleteRdbmsIngestion(id: string) {
    return this.httpClient
      .delete<RdbmsIngestionDetails>(
        RdbmsIngestionControllerService.url + '/rdbms/ingestion/' + id
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    Swal.fire({
      type: 'Failed',

      title: 'Failed',
      text: error.error.message,
      confirmButtonText: 'Ok',
    });
    return throwError(errorMessage);
  }
}


  <div class="cm-content-blocks">
      <ol class="breadcrumb">
          <li><a [routerLink]="['/project', projectService.getCurrentProject()]"><i class="fa fa-home"></i>Home</a></li>
          <li><a routerLink="/project"><i class="fa fa-folder-open"></i>Projects</a></li>
          <li><a routerLink="/project/{{projectName}}"><i class="fa fa-folder"></i>{{projectName}}</a></li>
          <li  class="active"><i class="fa fa-calendar mr-1"></i>Scheduler</li>  
      </ol>
  </div>
  
  
      <div class="clearafter headbtnc">
        <h3 class="headc">
            Job Scheduler - {{projectName}}
            <div class="subhead">Schedule the jobs for this project</div>
        </h3>
        <div class="create-btn-position headbtnc">
          <button class="mr-btn-create pull-right pointerc" routerLink="new-job-schedule" routerLinkActive="active">
                New Schedule 
          </button>
        </div>
      </div>
  
      <section class="clearafter">
        <aside class="zeroplimp float-left col-md-4">
          <div class="searchbarc fifteenpb">
            <input class="search-bar input-field"  type="text"  placeholder="Search for schedule..." [(ngModel)]="term" >
            <img class="search-bar-icon" src="../../../assets/images/search.svg">
          </div>
        </aside>
        <aside class="col-8 text-right clearafter d-flex justify-content-end">
        
          <!-- <div class="inline-block tenmt ">
              <span class=" fivemr">Sort by: </span>
              <label class="radioc">
                  <input type="radio"  name="inlineRadioOptions" (click)="sortDescending(schedules)" id="inlineRadio2" value="option2">
                  <span class="checkmark-r"></span>
                  <span class="text-r">Newest Schedules</span>
              </label>
              <label class="radioc">
                  <input type="radio" name="inlineRadioOptions" (click)="sortAscending(schedules)" id="inlineRadio1" value="option1">
                  <span class="checkmark-r"></span>
                  <span class="text-r">Oldest Schedules</span>
              </label>  
          </div> -->
      </aside>
        <!-- <aside class=" d-flex justify-content-end">
          <span class="inline-block fivemr">Sort Ingestions by date: </span>
          <label class="radioc">
            <input type="radio" name="inlineRadioOptions" (click)="sortAscending(ingestions)" id="inlineRadio1" value="option1">
            <span class="checkmark-r"></span>
            <span class="text-r">Oldest</span>
          </label>
          <label class="radioc">
            <input type="radio"  name="inlineRadioOptions" (click)="sortDescending(ingestions)" id="inlineRadio2" value="option2">
            <span class="checkmark-r"></span>
            <span class="text-r">Newest</span>
          </label> 
        </aside> -->
      </section>
  
  
      <section class="table-ec">
        <div class="table-wrapper position-relative loader_top_adj">
          <div *ngIf="load"class="">
            <div class="loader"></div>
            <figure class="spinner-border loaderico"></figure>
          </div>
          <div class="table-wrapper">
            <table class="table table-borderless mr-table">
              <thead class="mr-table-head">
                <tr class="mr-table-head-row">
                  <th>Name</th>
                  <!-- <th>Ingestion Name</th> -->
                  <th>Owner</th>
                  <th>Cron Expression</th>
                  <th>Current Job Status</th>
                  <th>Next Schedule</th>
                  <th>Status</th>

                  <th>Action</th>
                </tr>
              </thead>
              <tbody  *ngIf="schedules.length == 0" >
                <tr>
                  <td colspan=100% class="text-center">No schedules exist for this project</td>
                </tr>          
              </tbody>
              <tbody>
                </tbody>

                <tbody *ngIf="schedules.length > 0">
                  <tr *ngFor="let schedule of schedules |filter:term | paginate: { id: 'listing_pagination',
                  itemsPerPage: 10,
                  currentPage: page,
                  totalItems: schedules.length }; let i = index ">
                    <td>{{schedule.name}}</td>
                    <!-- <td>{{schedule.ingestionName}}</td> -->
                    <td>{{schedule.owner}}</td>
                    <td>{{schedule.cronExpression}}</td>
                    <td >{{schedule.latestJobTime |  date:'yyyy-MM-dd HH:mm:ss zzzz'}} 
                      <br>
                      <div
                      [class.success-s]="schedule.latestJob === 'Finished'" 
                      [class.info-s]="schedule.latestJob === 'In Progress'"
                      [class.danger-s]="schedule.latestJob ==='FAILED TO LAUNCH' "
                      [class.danger-s]="schedule.latestJob ==='Failed'"
                      [class.warning-s]="schedule.latestJob ==='Discarded'"


                      [class.progress-bar-.primary]="schedule.latestJob ==='NOT LAUNCHED'">
                      {{schedule.latestJob}}
                    </div>
                    </td>
                    <td >{{schedule.nextScheduleTime |  date:'yyyy-MM-dd HH:mm:ss zzzz'}}</td>
                    <td><span  [class.success-s]='schedule.enabled === true' [class.warning-s]='schedule.enabled === false' >{{returnStatus(schedule.enabled)}}</span></td>

                    <td>
                      <div class="icons-container"> 

                        <i class="fa fa-pencil-square-o pointerc" routerLink="/project/{{projectName}}/job-schedule-landing-page/edit-job-schedule/{{schedule.id}}" ngbTooltip="Edit Schedule" placement="left" ></i>
                        <i class="fa fa-check" *ngIf="schedule.enabled == false"  ngbTooltip="Enable Schedule" (click)="enableSchedule(schedule)" ></i>		
                        <i class="fa fa-ban" *ngIf="schedule.enabled == true"  ngbTooltip="Disable Schedule" (click)="disableSchedule(schedule)" ></i>		
                        <i class="fa fa-trash" (click)="deleteSchedule(schedule)"  ngbTooltip="Delete Schedule"></i>
                        
                        <!-- <label class="switch">
                          <input type="checkbox" [checked]="schedule.enabled" (click)="enableDisable(schedule)">
                          <span class="slider round"></span>
                        </label>	 -->
                      </div>

                    </td>
                  </tr>
                </tbody>
                </table>
                <pagination-controls *ngIf="schedules.length > 10" id="listing_pagination" class="pagination" (directionLinks)="true"
                  (pageChange)="page = $event" (autoHide)="true" (responsive)="true" previousLabel="" disabled="true"
                  nextLabel="">
              </pagination-controls>
                <!-- <pagination-controls *ngIf="ingestions.length > 10" id="listing_pagination" class="pagination" (directionLinks)="true"
                (pageChange)="page = $event" (autoHide)="true" (responsive)="true" previousLabel="" disabled="true"
                nextLabel="">
            </pagination-controls> -->
                <!-- <ngb-pagination *ngIf="ingestions.length > 10" class=" pagination float-right" [collectionSize]="ingestions.length" [(page)]="page" aria-label="Default pagination" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>  -->
  
          </div>
        </div>
    </section>

    <div class="modal" id="largeModal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content ">
        
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">             
              Edit Schedule
            </h4>
            <button type="button" class="close" data-dismiss="modal">&times;</button>
          </div>
          <!-- Modal body -->
          <form [formGroup]="editSchedulerForm" (ngSubmit)="updateScedule(editSchedulerForm)" >
          <div class="modal-body">
          <section class="inputc" >
            <aside class="col-md-6 fieldc" >
              <label class="inputlabel">Name</label>
              <input class="input-field" type="text" 
              formControlName="name" 
              >
              <!-- <div class="error-mge" *ngIf="dataSourceCredentials.controls['name'].invalid && (dataSourceCredentials.controls['name'].dirty || dataSourceCredentials.controls['name'].touched)" >
                Data Source Name is required
            </div> -->

            </aside>
            <aside class="col-md-6 fieldc" >
              <label class="inputlabel">Ingestion Name</label>
              <input class="input-field" type="text"  [attr.disabled]="true" 
              formControlName="ingestionName">
              <!-- <div class="error-mge" *ngIf="dataSourceCredentials.controls['description'].invalid && (dataSourceCredentials.controls['description'].dirty || dataSourceCredentials.controls['description'].touched)" >
                Database Description is required
            </div> -->
             
          </aside>
      
         
          </section>
          <section class="inputc" >
            <aside class="col-md-6 fieldc" >
              <label class="inputlabel">Cron Expression</label>
              <input class="input-field" type="text" 
              formControlName="cronExpression" 
              >
              <!-- <div class="error-mge" *ngIf="dataSourceCredentials.controls['name'].invalid && (dataSourceCredentials.controls['name'].dirty || dataSourceCredentials.controls['name'].touched)" >
                Data Source Name is required
            </div> -->

            </aside>
            <aside class="col-md-6 fieldc" >
              <label class="inputlabel clearafter"> 
                <ng-template #popValidateContent>
                    <div>
                    <span class="displayblock fivepb">
                        Enable or disabled scheduler
                    </span>
                    </div>
                </ng-template>
                <ng-template #popValidateTitle>Enable</ng-template>
                <span class="displayblock fl fivemr">Enable </span>
                <span class="iconbtn-round primary-s-bg qubesbg fl fivemr pointerc" placement="right" container="body" [ngbPopover]="popValidateContent" [popoverTitle]="popValidateTitle"> 
                    <i class="fa fa-info" aria-hidden="true"> </i> 
                </span>
            </label>
            
            <select class="form-control" formControlName="enabled">
                <option value="true">True</option>
                <option value="false">False</option>
            </select>
             
          </aside>
      
         
          </section>
          </div>

          <!-- Modal footer -->
          <footer class="modalfooter">
            <button type="button" class="done-btn modalpubtn" data-dismiss="modal">Cancel</button>
            <button  class="done-btn modalpubtn">Save </button>
          </footer>
          </form>

        </div>
      </div>
  </div>
  
import { Injectable } from '@angular/core';
import { Config } from '../../../config';
import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RdbmsConnector } from '../../../models/rdbms/rdbms-connector.model';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Injectable({
  providedIn: 'root',
})
export class StreamIngestionControllerService {
  static url = Config.url;

  constructor(private httpClient: HttpClient) {}

  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  // getStreamIngestionCount() {
  //   return this.httpClient.get(StreamIngestionControllerService.url + "/stream/count")
  //     .pipe(
  //       retry(1),
  //       // catchError(this.processError)
  //     )
  // }

  getAllStreamIngestionsByProjectName(name: string): Observable<any[]> {
    return this.httpClient
      .get<any[]>(
        StreamIngestionControllerService.url +
          '/stream/ingestions/project/' +
          name
      )
      .pipe(retry(1), catchError(this.handleError));
  }
  getAllStreamIngestions() {
    return this.httpClient
      .get(StreamIngestionControllerService.url + '/stream/ingestions')
      .pipe(retry(1), catchError(this.handleError));
  }

  getSingleStreamIngestion(id: string) {
    return this.httpClient
      .get<any>(
        StreamIngestionControllerService.url + '/stream/ingestion/' + id
      )
      .pipe(
        retry(1),
        catchError(this.handleError)

        // catchError(this.processError)
      );
  }

  getAllStreamIngestionJobsByProjectName(name: string) {
    return this.httpClient
      .get<any[]>(
        StreamIngestionControllerService.url + '/jobs/stream/project/' + name
      )
      .pipe(
        retry(1)
        // catchError(this.processError)
      );
  }

  postStreamIngestion(data: any) {
    return this.httpClient
      .post(StreamIngestionControllerService.url + '/stream/ingestion', data)
      .pipe(
        retry(1),
        catchError(this.handleError)

        // catchError(this.processError)
      );
  }

  updateStreamIngestion(id: string, data: any) {
    return this.httpClient
      .put(
        StreamIngestionControllerService.url + '/stream/ingestion/' + id,
        data
      )
      .pipe(
        retry(1),
        catchError(this.handleError)

        // catchError(this.processError)
      );
  }

  deleteStreamIngestion(id: string) {
    return this.httpClient
      .delete(StreamIngestionControllerService.url + '/stream/ingestion/' + id)

      .pipe(
        retry(1),
        catchError(this.handleError)

        // catchError(this.processError)
      );
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    Swal.fire({
      type: 'Failed',

      title: 'Failed',
      text: error.error.message,
      confirmButtonText: 'Ok',
    });
    return throwError(errorMessage);
  }
}

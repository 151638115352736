import { Injectable } from '@angular/core';
import { Config } from '../../config';
import { retry, catchError, map } from 'rxjs/operators';

import { throwError as observableThrowError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Injectable({
  providedIn: 'root'
})
export class ProjectControllerService {

  static url = Config.url;


  constructor(private httpClient: HttpClient) { }

  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  getAllPermittedProjects(): Observable<any[]> {
    return this.httpClient.get<any[]>(ProjectControllerService.url + "/projects/permitted")
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  assignUser(projectName: string, username: string, permission: string): Observable<any> {
    return this.httpClient.post<any>(ProjectControllerService.url + "/project/" + projectName + "/assign/" + username + "/" + permission, {})
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  deleteUserAssignment(projectName: string, username: string): Observable<any> {
    return this.httpClient.delete<any>(ProjectControllerService.url + "/project/" + projectName + "/assign/" + username)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  editUserAssignment(projectName: string, username: string, permission: string): Observable<any> {
    return this.httpClient.put<any>(ProjectControllerService.url + "/project/" + projectName + "/assign/" + username + "/" + permission, "")
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  getProjectCount(): Observable<any> {
    return this.httpClient.get<any>(ProjectControllerService.url + "/projects/count")
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  getAllProjects(): Observable<any[]> {
    return this.httpClient.get<any[]>(ProjectControllerService.url + "/projects")
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  getProject(name: string): Observable<any> {
    return this.httpClient.get<any>(ProjectControllerService.url + "/project/" + name)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  getProjects(id: string) {
    return this.httpClient.get(ProjectControllerService.url + "/project/" + id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  getSingleProjects(id: string) {
    return this.httpClient.get(ProjectControllerService.url + "/project/" + id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }



  errorHandler(error: HttpErrorResponse) {
    return observableThrowError(error.message || "Server Error")
  }


  postProjects(data: any) {
    return this.httpClient.post(ProjectControllerService.url + "/project", data)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }


  updateProjects(data: any) {
    return this.httpClient.put(ProjectControllerService.url + "/project", data)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  deleteProjects(name: string) {
    return this.httpClient.delete(ProjectControllerService.url + "/project/" + name)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }


    Swal.fire({
      type: 'Failed',

      title: "Failed",
      text: error.error.message,
      confirmButtonText: 'Ok',
    })
    return throwError(errorMessage);
  }




}
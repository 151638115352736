import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  NgForm,
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  ReactiveFormsModule,
} from '@angular/forms';
import { DataSourceControllerService } from 'src/app/services/api/data-source/data-source-controller.service';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { CatalogService } from '../../../../../../../../services/api/catalogService/catalog.service';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  copyArrayItem,
} from '@angular/cdk/drag-drop';
import { RdbmsIngestionControllerService } from 'src/app/services/api/rdbms-controller/rdbms-ingestion-controller.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { IngestionServiceService } from 'src/app/services/ingestion/ingestion-service.service';
import { IngestionSharingServiceService } from 'src/app/ingestion-sharing-service.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ProjectService } from 'src/app/services/project/project-service.service';
import * as $ from 'jquery';
import { HiveService } from 'src/app/services/api/hive/hive.service';

@Component({
  selector: 'app-edit-step-three-custom-ingestion',
  templateUrl: './edit-step-three-custom-ingestion.component.html',
  styleUrls: ['./edit-step-three-custom-ingestion.component.scss'],
})
export class EditStepThreeCustomIngestionComponent implements OnInit {
  tableSelected: boolean;
  whereClause: string;
  splitClause: string;
  selectedTableIndex: number;

  //snackbar

  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  isReadOnly: any = [];
  //datasource
  id = this.activatedRoute.snapshot.params['id'];
  ingestionId = this.activatedRoute.snapshot.params['id3'];

  projectName = this.activatedRoute.snapshot.params['id2'];

  itemObjectsRight: any = this.IngestionsharingService.getStepTwoData();
  itemObjectsRightCopy: any = this.IngestionsharingService.getStepTwoCopyData();

  //loader
  load: boolean = true;
  //disable select
  disableSelect: false;
  //pagination
  page = 1;
  // edit table

  canIncrementById: boolean;
  canIncrementByTimeStamp: boolean;

  isSubmitShow: boolean = false;

  //toggle
  toggle: any = [];

  //selected database
  selectedDatabase: any;
  databaseInformation: any = this.IngestionsharingService.getStepOneData();
  numberOfTables: number;
  //selected table
  selectedTable: any;
  csvDestinationDataTypes = [
    'INT',
    'SMALLINT',
    'BIGINT',
    'TINYINT',
    'FLOAT',
    'DOUBLE',
    'DECIMAL',
    'NUMERIC',
    'TIMESTAMP',
    'DATE',
    'INTERVAL',
    'STRING',
    'VARCHAR(255)',
    'CHAR(255)',
    'BOOLEAN',
    'BINARY',
  ];
  avroDestinationDataTypes = [
    'INT',
    'SMALLINT',
    'BIGINT',
    'TINYINT',
    'FLOAT',
    'DOUBLE',
    'DECIMAL',
    'NUMERIC',
    'STRING',
    'BOOLEAN',
    'BINARY',
  ];
  parquetDestinationDataType = [
    'INT',
    'SMALLINT',
    'BIGINT',
    'TINYINT',
    'FLOAT',
    'DOUBLE',
    'NUMERIC',
    'STRING',
    'BOOLEAN',
    'BINARY',
  ];
  //selected columns
  selectedColumns: any;
  incrementalColumnValue: string;
  //individual column
  selectedColumn: any;
  selectedColumnLength: number;
  //selected tables from database
  tablesFromDatabase: any[];
  // columns from selected table
  columnsFromTable: any[];

  //input togggle

  //ngModals
  databaseName: string;
  tableName: string;

  //database names for validation
  databaseNamesForValidation: any = [];
  databaseConflicts: any = [];
  currentIngestion: any;

  constructor(
    private formBuilder: FormBuilder,
    private dataSourceController: DataSourceControllerService,
    private catalogService: CatalogService,
    public service: RdbmsIngestionControllerService,
    public router: Router,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private IngestionsharingService: IngestionSharingServiceService,
    public projectService: ProjectService,
    private rdbmsService: RdbmsIngestionControllerService,
    private hiveService: HiveService
  ) {}

  showDiv = {
    stepone: false,
    steptwo: false,
    stepthree: true,
    stepfour: false,
    stepfive: false,

    stepbtnone: true,
    stepbtntwo: false,
    stepbtnthree: false,
    stepbtnfour: false,
    stepbtnfive: false,

    cancelbtn: false,
    nextbtn: true,
    nextbtntwo: false,
    nextbtnthree: false,
    nextbtnfour: false,

    backbtn: false,
    backbtntwo: false,
    backbtnthree: false,
    backbtnfour: false,
    backbtnfive: false,

    submitbtn: false,
  };

  file = this.formBuilder.group({
    fileType: this.IngestionsharingService.getStepOneData().fileType,
  });

  selectFileType(fileTypeValue: string) {
    this.file.patchValue({
      fileType: fileTypeValue,
    });
    console.log(this.file.value);
    if (
      this.file.value.fileType == 'avro' ||
      this.file.value.fileType == 'AVRO'
    ) {
      this.fileTypeChangeWarning(
        'The Avro does not support timestamps or dates in this version. Timestamps and Date data types can be casted to BigInt.'
      );
      for (let i = 0; i < this.itemObjectsRight.length; i++) {
        for (let j = 0; j < this.itemObjectsRight[i].tables.length; j++) {
          for (
            let k = 0;
            k < this.itemObjectsRight[i].tables[j].columns.length;
            k++
          ) {
            this.itemObjectsRight[i].tables[j].columns[k].destinationDataType =
              this.returnDestinationDataType(
                this.itemObjectsRight[i].tables[j].columns[k]
                  .destinationDataType
              );
          }
        }
      }
      console.log(this.itemObjectsRight);

      this.IngestionsharingService.setStepTwoData(this.itemObjectsRight);
    } else if (
      this.file.value.fileType == 'parquet' ||
      this.file.value.fileType == 'PARQUET'
    ) {
      this.fileTypeChangeWarning(
        'The Parquet option does not support timestamps, dates, or decimal in this version. Timestamps and Date data types can be casted to BigInt. Decimal can be casted to a lower precision data type like Double'
      );
      for (let i = 0; i < this.itemObjectsRight.length; i++) {
        for (let j = 0; j < this.itemObjectsRight[i].tables.length; j++) {
          for (
            let k = 0;
            k < this.itemObjectsRight[i].tables[j].columns.length;
            k++
          ) {
            this.itemObjectsRight[i].tables[j].columns[k].destinationDataType =
              this.returnDestinationDataType(
                this.itemObjectsRight[i].tables[j].columns[k]
                  .destinationDataType
              );
          }
        }
      }
      console.log(this.itemObjectsRight);
      this.IngestionsharingService.setStepTwoData(this.itemObjectsRight);
    } else {
      for (let i = 0; i < this.itemObjectsRight.length; i++) {
        for (let j = 0; j < this.itemObjectsRight[i].tables.length; j++) {
          for (
            let k = 0;
            k < this.itemObjectsRight[i].tables[j].columns.length;
            k++
          ) {
            this.itemObjectsRight[i].tables[j].columns[k].destinationDataType =
              this.itemObjectsRightCopy[i].tables[j].columns[
                k
              ].destinationDataType;
          }
        }
      }
      console.log(this.itemObjectsRight);

      this.IngestionsharingService.setStepTwoData(this.itemObjectsRight);
    }

    let data: any = this.IngestionsharingService.getStepOneData();
    data.fileType = this.file.value.fileType;
    this.IngestionsharingService.setStepOneData(data);

    console.log(this.IngestionsharingService.getStepOneData());
  }
  returnDestinationDataType(destinationDataType: string): any {
    if (
      this.file.value.fileType == 'parquet' ||
      this.file.value.fileType == 'PARQUET'
    ) {
      if (destinationDataType.includes('CHAR')) {
        return 'STRING';
      } else if (destinationDataType.includes('TIMESTAMP')) {
        return 'BIGINT';
      } else if (destinationDataType.includes('DATE')) {
        return 'INT';
      } else if (
        destinationDataType.includes('DECIMAL') ||
        destinationDataType.includes('NUMERIC')
      ) {
        return 'DOUBLE';
      }
    } else if (
      this.file.value.fileType == 'avro' ||
      this.file.value.fileType == 'AVRO'
    ) {
      if (destinationDataType.includes('CHAR')) {
        return 'STRING';
      } else if (destinationDataType.includes('TIMESTAMP')) {
        return 'BIGINT';
      } else if (destinationDataType.includes('DATE')) {
        return 'INT';
      }
    }
    return destinationDataType;
  }
  retrieveTables(database: any) {
    this.selectedDatabase = database;

    this.tablesFromDatabase = [];

    database.tables.map((table) => {
      this.tablesFromDatabase.push(table);
    });
  }

  updateStepData() {
    let stepData: any[] = this.IngestionsharingService.getStepTwoData();

    if (stepData.length == 1) {
      this.IngestionsharingService.setStepTwoData([this.selectedDatabase]);
      this.IngestionsharingService.setStepTwoCopyData([this.selectedDatabase]);
    } else {
      const index = stepData.findIndex(
        (step) =>
          step.sourceDatabaseName == this.selectedDatabase.sourceDatabaseName
      );
      stepData.splice(index, 1, this.selectedDatabase);
      this.IngestionsharingService.setStepTwoData(stepData);
      this.IngestionsharingService.setStepTwoCopyData(stepData);
    }
  }

  updateModifiedTable(type: string) {
    if (type == 'where') {
      this.selectedTable.where = this.whereClause;
      this.selectedDatabase.tables.splice(
        this.selectedTableIndex,
        1,
        this.selectedTable
      );
    }

    if (type == 'split') {
      this.selectedTable.splitBy = this.splitClause;
      this.selectedDatabase.tables.splice(
        this.selectedTableIndex,
        1,
        this.selectedTable
      );
    }

    this.updateStepData();
  }

  retrieveColumns(table: any) {
    //change item
    // document.getElementById(tableInfo).style.backgroundColor = "transparent"
    this.selectedTable = table;

    this.selectedTableIndex = this.selectedDatabase.tables.findIndex(
      (table) => table.sourceTableName == this.selectedTable.sourceTableName
    );

    if (table.splitBy) {
      const splitColumn = table.columns.find(
        (c) => c.sourceColumnName === table.splitBy
      );

      this.splitClause = splitColumn.sourceColumnName;
    } else {
      this.splitClause = null;
    }

    if (table.where) {
      this.whereClause = table.where;
    } else {
      this.whereClause = null;
    }

    let incrementCount = 0;
    for (let i = 0; i < this.selectedTable.columns.length; i++) {
      if (
        this.selectedTable.columns[i].sourceDataType.includes('INT') ||
        this.selectedTable.columns[i].sourceDataType.includes('int')
      ) {
        incrementCount++;
      }
    }
    if (incrementCount > 0) {
      this.canIncrementById = true;
    } else {
      this.canIncrementById = false;
    }
    let timestampCount: number = 0;
    for (let columns of this.selectedTable.columns) {
      if (
        columns.sourceDataType.includes('DATE') ||
        columns.sourceDataType.includes('TIMESTAMP') ||
        columns.sourceDataType.includes('date') ||
        columns.sourceDataType.includes('timestamp')
      ) {
        timestampCount++;
      }
    }
    if (timestampCount > 0) {
      this.canIncrementByTimeStamp = true;
    } else {
      this.canIncrementByTimeStamp = false;
    }

    this.selectedColumnLength = table.columns.length;
    this.tableSelected = true;
    if (table.columns != undefined) {
      this.selectedTable = table;

      this.columnsFromTable = [];
      for (let column of table.columns) {
        this.columnsFromTable.push(column);
      }
    }
  }

  mergeObjects(array: any) {
    let count = 0;
    console.log(array);

    for (let i = 0; i < array.length; i++) {
      for (let j = 1; j < array.length - i; j++) {
        if (
          array[j - 1].destinationDatabaseName ==
          array[j].destinationDatabaseName
        ) {
          let newArray: any = array[j - 1];
          newArray.tables = array[j - 1].tables.concat(array[j].tables);
          array.splice(j, 1);
        }
      }
    }
    this.IngestionsharingService.setStepTwoData(this.itemObjectsRight);

    return array;
  }

  nullcheck(value: any) {
    if (value == null) {
      return '';
    } else {
      return value + '.';
    }
  }

  updateDatabaseName(database: any, table: any) {
    let databaseCount = 0;
    for (let i = 0; i < this.itemObjectsRight.length; i++) {
      if (
        this.itemObjectsRight[i].sourceDatabaseName ==
        database.sourceDatabaseName
      ) {
        databaseCount++;
      }
    }
    if (database.tables.length != 1) {
      Swal.fire({
        type: 'warning',

        title: 'Do you want to split table into new database? ',
        text:
          "Do you want to move the table '" +
          table.destinationTableName +
          "' into it's own database called '" +
          this.databaseName.toLowerCase() +
          "', or rename database for all tables inside this database.",
        showCancelButton: true,
        confirmButtonText: 'Yes, Split To New Database',
        cancelButtonText: 'No, Rename For All Tables',
      }).then((result) => {
        if (result.isConfirmed == true) {
          database.destinationDatabaseName = this.databaseName.toLowerCase();

          if (database.tables.length > 1) {
            let filteredTable = database.tables.filter(
              (tables) => tables.sourceTableName == table.sourceTableName
            );
            let unfilteredTables = database.tables.filter(
              (tables) => tables.sourceTableName != table.sourceTableName
            );

            // let unfilteredTables = JSON.parse(JSON.stringify(database.tables))

            database.tables = filteredTable;
            this.itemObjectsRight.push({
              sourceDatabaseName: database.sourceDatabaseName,
              destinationDatabaseName: database.sourceDatabaseName,
              tables: unfilteredTables,
            });

            this.IngestionsharingService.setStepTwoData(this.itemObjectsRight);

            this.databaseNamesForValidation = [];
            this.hiveService
              .hiveValidate(this.itemObjectsRight)
              .subscribe((response) => {
                this.databaseConflicts = response;
                this.returnKeys(this.databaseConflicts);
              });
            console.log(this.itemObjectsRight);
            this.mergeObjects(this.itemObjectsRight);
          }
        } else {
          database.destinationDatabaseName = this.databaseName.toLowerCase();
          this.databaseNamesForValidation = [];
          this.hiveService
            .hiveValidate(this.itemObjectsRight)
            .subscribe((response) => {
              this.databaseConflicts = response;
              this.returnKeys(this.databaseConflicts);
              console.log(this.itemObjectsRight);

              this.mergeObjects(this.itemObjectsRight);
            });
          console.log(this.itemObjectsRight);

          this.mergeObjects(this.itemObjectsRight);
        }
      });
    } else {
      database.destinationDatabaseName = this.databaseName.toLowerCase();
      this.databaseNamesForValidation = [];
      this.hiveService
        .hiveValidate(this.itemObjectsRight)
        .subscribe((response) => {
          this.databaseConflicts = response;
          this.returnKeys(this.databaseConflicts);
          console.log(this.itemObjectsRight);

          this.mergeObjects(this.itemObjectsRight);
        });
    }

    console.log(this.itemObjectsRight);
  }

  returnKeys(object: any) {
    for (const [key, value] of Object.entries(object)) {
      this.databaseNamesForValidation.push({
        key: `${key}`,
        value: `${value}`,
      });
    }
  }
  deleteDatabase(parent: any, child: any) {
    parent.splice(child, 1);
    for (let i = 0; i < this.itemObjectsRight; i++) {
      console.log(
        this.selectedDatabase.destinationDatabaseName,
        this.itemObjectsRight[i].destinationDatabaseName
      );

      if (
        this.selectedDatabase.destinationDatabaseName !=
        this.itemObjectsRight[i].destinationDatabaseName
      ) {
        this.tableSelected = true;
      }
    }
    this.IngestionsharingService.setStepTwoData(this.itemObjectsRight);
  }

  checkForConflicts(object: any): number {
    let count: number = 0;
    for (const item of object) {
      if (item.value != 'No Conflict.') {
        count++;
      }
    }
    return count;
  }

  updateTableName(database: any, table: any) {
    let tableCount: number = 0;
    for (let item of database.tables) {
      if (item.sourceTableName == this.tableName) {
        tableCount++;
      }
    }
    if (this.tableName != '') {
      database.tables[database.tables.indexOf(table)].destinationTableName =
        this.tableName.toLowerCase();
      this.IngestionsharingService.setStepTwoData(this.itemObjectsRight);

      this.databaseNamesForValidation = [];
      this.hiveService
        .hiveValidate(this.itemObjectsRight)
        .subscribe((response) => {
          this.databaseConflicts = response;
          this.returnKeys(this.databaseConflicts);
        });
    } else {
      this.tableName = table.sourceTableName.toLowerCase();
      table.destinationTableName = table.sourceTableName;
      this.IngestionsharingService.setStepTwoData(this.itemObjectsRight);

      this.databaseNamesForValidation = [];
      this.hiveService
        .hiveValidate(this.itemObjectsRight)
        .subscribe((response) => {
          this.databaseConflicts = response;
          this.returnKeys(this.databaseConflicts);
        });
    }

    // this.IngestionsharingService.setStepTwoData(this.itemObjectsRight);
  }

  editTableLoad(table: any, incrementValue: string) {
    table.incremental = incrementValue;
    this.IngestionsharingService.setStepTwoData(this.itemObjectsRight);

    this.selectedTable = table;
    for (let i = 0; i < table.columns.length; i++) {
      table.columns[i].checkColumn = false;
    }
  }

  editCheckColumn(database: any, table: any, column: any) {
    this.itemObjectsRight[this.itemObjectsRight.indexOf(database)].tables[
      this.itemObjectsRight[
        this.itemObjectsRight.indexOf(database)
      ].tables.indexOf(table)
    ].columns[
      this.itemObjectsRight[this.itemObjectsRight.indexOf(database)].tables[
        this.itemObjectsRight[
          this.itemObjectsRight.indexOf(database)
        ].tables.indexOf(table)
      ].columns.indexOf(column)
    ].checkColumn = true;

    for (let columns of this.itemObjectsRight[
      this.itemObjectsRight.indexOf(database)
    ].tables[
      this.itemObjectsRight[
        this.itemObjectsRight.indexOf(database)
      ].tables.indexOf(table)
    ].columns) {
      if (
        this.itemObjectsRight[this.itemObjectsRight.indexOf(database)].tables[
          this.itemObjectsRight[
            this.itemObjectsRight.indexOf(database)
          ].tables.indexOf(table)
        ].columns.indexOf(columns) !=
        this.itemObjectsRight[this.itemObjectsRight.indexOf(database)].tables[
          this.itemObjectsRight[
            this.itemObjectsRight.indexOf(database)
          ].tables.indexOf(table)
        ].columns.indexOf(column)
      ) {
        columns.checkColumn = false;
      }
      this.IngestionsharingService.setStepTwoData(this.itemObjectsRight);

      this.selectedColumns =
        this.itemObjectsRight[this.itemObjectsRight.indexOf(database)].tables[
          this.itemObjectsRight[
            this.itemObjectsRight.indexOf(database)
          ].tables.indexOf(table)
        ].columns[
          this.itemObjectsRight[this.itemObjectsRight.indexOf(database)].tables[
            this.itemObjectsRight[
              this.itemObjectsRight.indexOf(database)
            ].tables.indexOf(table)
          ].columns.indexOf(column)
        ];
    }
  }
  editDestinationDataType(
    database: any,
    table: any,
    column: any,
    destinationDataType: string
  ) {
    for (let columns of this.itemObjectsRight[
      this.itemObjectsRight.indexOf(database)
    ].tables[
      this.itemObjectsRight[
        this.itemObjectsRight.indexOf(database)
      ].tables.indexOf(table)
    ].columns) {
      if (
        this.itemObjectsRight[this.itemObjectsRight.indexOf(database)].tables[
          this.itemObjectsRight[
            this.itemObjectsRight.indexOf(database)
          ].tables.indexOf(table)
        ].columns.indexOf(columns) ==
        this.itemObjectsRight[this.itemObjectsRight.indexOf(database)].tables[
          this.itemObjectsRight[
            this.itemObjectsRight.indexOf(database)
          ].tables.indexOf(table)
        ].columns.indexOf(column)
      ) {
        columns.destinationDataType = destinationDataType;
      }
    }
    this.IngestionsharingService.setStepTwoData(this.itemObjectsRight);

    this.selectedColumns =
      this.itemObjectsRight[this.itemObjectsRight.indexOf(database)].tables[
        this.itemObjectsRight[
          this.itemObjectsRight.indexOf(database)
        ].tables.indexOf(table)
      ].columns[
        this.itemObjectsRight[this.itemObjectsRight.indexOf(database)].tables[
          this.itemObjectsRight[
            this.itemObjectsRight.indexOf(database)
          ].tables.indexOf(table)
        ].columns.indexOf(column)
      ];
  }

  fileTypeChangeWarning(status: string) {
    Swal.fire({
      type: 'warning',

      title: 'Warning',
      text: status,
      confirmButtonText: 'Ok',
    });
  }
  success(status: string) {
    Swal.fire({
      type: 'Success',

      title: 'Success!',
      text: status,
      confirmButtonText: 'Ok',
    });
  }
  error(status: string) {
    Swal.fire({
      type: 'Error',

      title: 'Ooops!',
      text: status,
      confirmButtonText: 'Ok',
    });
  }

  warning(status: string) {
    Swal.fire({
      type: 'warning',

      title: 'Are you sure you want to delete the database?',
      text: status,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    });
  }

  carryData() {
    this.router.navigate([
      `/project/${this.projectName}/ingest/editStepFourCustom/${this.id}/ingestion/${this.ingestionId}`,
    ]);
  }

  deleteColumn(parent: any, child: any) {
    Swal.fire({
      type: 'warning',

      title: 'Are you sure you want to delete this column?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed == true) {
        parent.splice(child, 1);
        this.IngestionsharingService.setStepTwoData(this.itemObjectsRight);
      }
    });
  }

  // this.router.navigateByUrl(`/ingest-rdbms/${this.id}`);

  tableCount() {
    let tableCount: number = 0;
    for (let database of this.itemObjectsRight) {
      tableCount += database.tables.length;
    }
    this.numberOfTables = tableCount;
  }
  getCurrentIngestion() {
    this.load = true;
    this.rdbmsService
      .getSingleRdbmsIngestion(this.ingestionId)
      .subscribe((response) => {
        this.currentIngestion = response;
        this.selectFileType(this.currentIngestion.fileType);
        console.log('FILE');
        console.log(this.file);

        this.load = false;
      });
  }

  ngOnInit(): void {
    this.getCurrentIngestion();
    setTimeout(() => (this.load = false), 1200);
    this.tableSelected = false;
    console.log(this.databaseInformation);
    this.tableCount();
  }

  ngAfterViewInit() {
    $(document).ready(function () {
      function treeheight() {
        var menuscroll = $(window).height() - 380;
        var selecttable = $(window).height() - 380;
        $('.menublock_scroll').css('height', menuscroll);
        $('.selecttable_c').css('height', selecttable);
      }
      setTimeout(treeheight, 1000);
    });

    $('body').on('click', '.menublock_scroll', function () {
      var menuscroll = $(window).height() - 380;
      var selecttable = $(window).height() - 380;
      $('.menublock_scroll').css('height', menuscroll);
      $('.selecttable_c').css('height', selecttable);
    });

    $(window).resize(function () {
      function treeheight() {
        var menuscroll = $(window).height() - 380;
        var selecttable = $(window).height() - 380;
        $('.menublock_scroll').css('height', menuscroll);
        $('.selecttable_c').css('height', selecttable);
      }
      setTimeout(treeheight, 4);
    });
  }
}

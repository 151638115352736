import { Component, OnInit, AfterViewInit } from '@angular/core';
import { RdbmsIngestionControllerService } from "../../../../services/api/rdbms-controller/rdbms-ingestion-controller.service";
import { JobControllerService } from "../../../../services/api/job/job-controller.service"
import { DataSourceControllerService } from '../../../../services/api/data-source/data-source-controller.service'
import { ProjectControllerService } from "../../../../services/project/project-controller.service"
import { SideNavServiceService } from "../../../../services/side-nav/side-nav-service.service"
import { ProjectService } from 'src/app/services/project/project-service.service';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import { ScheduleService } from 'src/app/services/scheduler/schedule.service';
import { ProjectsService } from 'src/app/services/api/projects.service';

@Component({
  selector: 'app-projects-home-page',
  templateUrl: './projects-home-page.component.html',
  styleUrls: ['./projects-home-page.component.scss']
})
export class ProjectsHomePageComponent implements OnInit, AfterViewInit {

  ingestions: any;
  jobs: any;
  dataSources: any;
  projects: any;
  schedules: any;
  load = true;

  ngAfterViewInit() {
    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);
    });
  }

  constructor(public rdbmsService: RdbmsIngestionControllerService, public jobService: JobControllerService,
    private dataSourceControllerService: DataSourceControllerService, private projectControllerService: ProjectControllerService, public nav: SideNavServiceService, public projectService: ProjectService,
    private activatedRoute: ActivatedRoute, private scheduleService: ScheduleService, private ProjectsServiceForCount: ProjectsService,
  ) { }

  projectName = this.activatedRoute.snapshot.params['id2'];


  getAllIngestionsByProjectName() {
    this.ProjectsServiceForCount.getAllIngestionsCountByProjectName(this.projectName).subscribe(res => {
      console.log(res);
      this.ingestions = res;
    })
  }

  getAllJobsByProjectName() {
    this.ProjectsServiceForCount.getAllJobsCountByProjectName(this.projectName).subscribe(res => {
      this.jobs = res;
    })
  }


  getAllDataSourcesByProjectName() {
    this.ProjectsServiceForCount.getAllDataSourceCountByProjectName(this.projectName).toPromise().then(res => {
      this.dataSources = res;
      console.log(res)
    })
  }

  getAllSchedulesByProjectName() {
    this.scheduleService.getAllSchedulesByProject(this.projectName).subscribe(response => {
      console.log(response);
      this.schedules = response;
    })
  }
  ngOnInit(): void {
    this.getAllIngestionsByProjectName()
    this.getAllJobsByProjectName()
    this.getAllDataSourcesByProjectName()
    this.getAllSchedulesByProjectName();
    this.nav.inProject()
    setTimeout(() => this.load = false, 500)
  }

}

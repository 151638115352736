import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  NgForm,
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  ReactiveFormsModule,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';
import { DataSourceControllerService } from 'src/app/services/api/data-source/data-source-controller.service';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { CatalogService } from '../../../../../../../services/api/catalogService/catalog.service';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  copyArrayItem,
} from '@angular/cdk/drag-drop';
import { RdbmsIngestionControllerService } from 'src/app/services/api/rdbms-controller/rdbms-ingestion-controller.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { IngestionSharingServiceService } from 'src/app/ingestion-sharing-service.service';
import { ProjectService } from 'src/app/services/project/project-service.service';

@Component({
  selector: 'app-step-one-database-ingestion',
  templateUrl: './step-one-database-ingestion.component.html',
  styleUrls: ['./step-one-database-ingestion.component.scss'],
})
export class StepOneDatabaseIngestionComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  //datasource

  singleDataSource: any;
  // treeControl = new NestedTreeControl(node => node.children);

  load = true;

  dataSources: any;
  dataSourceData: any;

  id: string;
  status = false;
  buttonEnable: boolean = false;

  isSubmitShow: boolean = false;

  customIngestionFormFields: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private dataSourceController: DataSourceControllerService,
    public service: RdbmsIngestionControllerService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public IngestionService: IngestionSharingServiceService,
    public projectService: ProjectService
  ) {}

  projectName = this.activatedRoute.snapshot.params['id2'];

  showDiv = {
    stepone: true,
    steptwo: false,
    stepthree: false,
    stepfour: false,
    stepfive: false,

    stepbtnone: true,
    stepbtntwo: false,
    stepbtnthree: false,
    stepbtnfour: false,
    stepbtnfive: false,

    cancelbtn: false,
    nextbtn: true,
    nextbtntwo: false,
    nextbtnthree: false,
    nextbtnfour: false,

    backbtn: false,
    backbtntwo: false,
    backbtnthree: false,
    backbtnfour: false,
    backbtnfive: false,

    submitbtn: false,
  };

  ngOnInit(): void {
    this.customIngestionFormFields = new FormGroup({
      sourceId: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      description: new FormControl(''),
    });

    if (this.IngestionService.getStepOneData() != null) {
      this.customIngestionFormFields.patchValue({
        sourceId: this.IngestionService.getStepOneData().sourceId,
        name: this.IngestionService.getStepOneData().name,
        description: this.IngestionService.getStepOneData().description,
      });

      this.getSingleDataSource(this.IngestionService.getStepOneData().sourceId);
    }

    this.service
      .getAllIngestionsByProjectName(this.projectName)
      .subscribe((response) => {
        const names = response.map((item) => item.name);

        this.cf.name.setValidators([
          Validators.required,
          this.forbiddenNameValidator(names),
        ]);

        this.load = false;
      });

    this.getAllDataSourcesByProjectName();
  }

  get cf() {
    return this.customIngestionFormFields.controls;
  }

  savedDatasourceDetails = this.formBuilder.group({
    name: [''],
  });

  identifyDriver(driver: string): string {
    if (driver == 'mysql') {
      return '../../../../assets/images/mysqllogo.svg';
    } else if (driver == 'oracle') {
      return '../../../../assets/images/Oracle.svg';
    } else if (driver == 'sqlserver') {
      return '../../../../assets/images/sqlserver.png';
    } else if (driver == 'db2') {
      return '../../../../assets/images/ibmDb2.png';
    } else if (driver == 'snowflake') {
      return '../../../../assets/images/snowflake.svg';
    } else if (driver == 'redshift') {
      return '../../../../assets/images/redshift.png';
    } else if (
      driver.toLowerCase() == 'memsql' ||
      driver.toLowerCase() == 'memsql1'
    ) {
      return '../../../../assets/images/memsql.svg';
    } else if (driver == 'postgresql') {
      return '../../../../assets/images/postgresql.png';
    } else if (driver == 'synapse') {
      return '../../../../assets/images/synapse.png';
    } else if (driver == 'explorer') {
      return '../../../../assets/images/explorer-icon-t.svg';
    } else {
      return '../../';
    }
  }

  stepOneForm(fields: any): any {
    this.customIngestionFormFields.patchValue({
      sourceId: fields.value.sourceId,
      name: fields.value.name,
      description: fields.value.description,
    });

    console.log(this.customIngestionFormFields.value);
    this.IngestionService.setStepOneData(this.customIngestionFormFields.value);
    this.id = this.customIngestionFormFields.value.sourceId;
    this.router.navigate([
      `/project/${this.projectName}/ingest/select-ingestion/stepTwoDatabase/${this.id}`,
    ]);
  }

  carryData() {
    if (this.IngestionService.getStepOneData() == null) {
      this.router.navigate([
        `/project/${this.projectName}/ingest/select-ingestion/stepTwoDatabase/${this.id}`,
      ]);
    }
    if (this.IngestionService.getStepOneData() !== null) {
      this.router.navigate([
        `/project/${this.projectName}/ingest/select-ingestion/stepTwoDatabase/${
          this.IngestionService.getStepOneData().sourceId
        }`,
      ]);
    }
  }

  //API METHODS
  getAllDataSourcesByProjectName() {
    this.dataSourceController
      .getAllConnectionsByProjectName(this.projectService.getCurrentProject())
      .subscribe((res) => {
        this.dataSources = res.filter(
          (source) => source.databaseType != 'explorer'
        ); // TODO remove filter when explorer is ready for database ingestion
      });
  }

  getSingleDataSource(id: string) {
    this.dataSourceController.getSingleRDBMSConnector(id).subscribe((res) => {
      this.singleDataSource = res;
      this.savedDatasourceDetails.setValue({
        name: this.singleDataSource.name,
      });
    });
  }

  forbiddenNameValidator(names: Array<string>): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (names) {
        const forbidden = names.some(
          (name) => name.trim().toLowerCase() == value.trim().toLowerCase()
        );

        return forbidden ? { DuplicateName: { value: control.value } } : null;
      } else {
        return null;
      }
    };
  }
}

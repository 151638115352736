import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AceEditorComponent } from 'ng2-ace-editor';
import { IngestionSharingServiceService } from 'src/app/ingestion-sharing-service.service';
import { CatalogService } from 'src/app/services/api/catalogService/catalog.service';
import { DataSourceControllerService } from 'src/app/services/api/data-source/data-source-controller.service';
import { StreamingdataSourceService } from 'src/app/services/api/data-source/streamingdata-source.service';
import { RdbmsIngestionControllerService } from 'src/app/services/api/rdbms-controller/rdbms-ingestion-controller.service';
import { StreamIngestionControllerService } from 'src/app/services/api/streamController/stream-ingestion-controller.service';
import { ProjectService } from 'src/app/services/project/project-service.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as ace from 'ace-builds';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-step-two-query-ingestion',
  templateUrl: './step-two-query-ingestion.component.html',
  styleUrls: ['./step-two-query-ingestion.component.scss'],
})
export class StepTwoQueryIngestionComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private dataSourceController: DataSourceControllerService,
    private catalogService: CatalogService,
    public service: RdbmsIngestionControllerService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public projectService: ProjectService,
    private ingestionService: IngestionSharingServiceService,
    private streamingDataSourceService: StreamingdataSourceService,
    private streamIngestionControllerService: StreamIngestionControllerService,
    private rdbmsIngestionControllerService: RdbmsIngestionControllerService,
    private catalogControllerService: CatalogService,
    private IngestionsharingService: IngestionSharingServiceService
  ) {}

  showDiv = {
    stepone: true,
    steptwo: true,
    stepthree: false,
    stepfour: false,
    stepfive: false,

    stepbtnone: true,
    stepbtntwo: false,
    stepbtnthree: false,
    stepbtnfour: false,
    stepbtnfive: false,

    cancelbtn: false,
    nextbtn: true,
    nextbtntwo: false,
    nextbtnthree: false,
    nextbtnfour: false,

    backbtn: false,
    backbtntwo: false,
    backbtnthree: false,
    backbtnfour: false,
    backbtnfive: false,

    submitbtn: false,
  };
  id = this.activatedRoute.snapshot.params['id'];
  projectName = this.activatedRoute.snapshot.params['id2'];
  fieldsSelected: any = [];

  load: boolean = false;
  //ace editor options
  query: any = '';
  options: any = { maxLines: 1000, printMargin: false };
  queryValidation: boolean;
  fields: any;
  arrayItems: any;
  result: [];
  dataSource: any;
  sourceDatabases: any;
  stepOneInformation = this.ingestionService.getStepOneQueryData();
  columnOptions: any;
  splitBy: any;

  carryData() {
    this.router.navigate([
      `/project/${this.projectName}/ingest/select-ingestion/stepThreeStream/${this.id}`,
    ]);
  }
  formForFields = this.formBuilder.group({
    fields: this.formBuilder.array([]),
  });

  stepTwoForm = this.formBuilder.group({
    query: ['', Validators.required],
    splitBy: ['', Validators.required],
    columnOptions: [''],
  });

  onChange(sqlStatement) {
    this.queryValidation = false;
    if (
      sqlStatement.includes('$CONDITIONS') ||
      sqlStatement.includes('$CONDITIONS'.toLowerCase())
    ) {
      this.stepTwoForm.patchValue({
        query: sqlStatement,
      });
      this.queryValidation = true;
    } else {
      this.stepTwoForm.controls['query'].reset();
      this.queryValidation = false;
    }
  }

  testQuery(query: string) {
    let formattedQuery = query.replace(/[\r\n]+/gm, ' ');

    if (this.queryValidation) {
      this.load = true;
      this.dataSourceController
        .getQueryInfo(formattedQuery, this.stepOneInformation.sourceId)
        .subscribe(
          (response) => {
            this.columnOptions = response;
            this.load = false;
          },
          (err) => {
            this.load = false;
            console.log(err);
          }
        );
    } else {
      this.error(
        'Make sure SQL query has a where clause with $CONDITIONS in it.'
      );
    }
  }

  changeSplitBy(splitBy) {
    console.log('Hello');
    this.stepTwoForm.patchValue({
      splitBy: splitBy,
    });
  }

  submitForm(data: any) {
    this.stepTwoForm.patchValue({ columnOptions: this.columnOptions });
    console.log(this.stepTwoForm.value);

    this.IngestionsharingService.setStepTwoQueryData(this.stepTwoForm.value);
    
    this.router.navigate([
      `/project/${this.projectName}/ingest/select-ingestion/stepThreeQuery/${this.id}`,
    ]);
  }

  saveConfiguration(data: any) {
    this.ingestionService.setStepTwoQueryData(data.value);
    this.ingestionService.setQuerySQLStatement(this.query);
  }
  success(status: string) {
    Swal.fire({
      type: 'Success',
      title: 'Success!',
      text: status,
      confirmButtonText: 'Ok',
    });
  }

  error(status: string) {
    Swal.fire({
      type: 'Error',
      title: 'Query Not Valid!',
      text: status,
      confirmButtonText: 'Ok',
    });
  }

  fileTypeChangeWarning(status: string) {
    Swal.fire({
      type: 'warning',
      title: 'Warning',
      text: status,
      confirmButtonText: 'Ok',
    });
  }

  ngOnInit(): void {
    this.arrayItems = [];
    if (this.IngestionsharingService.getStepTwoQueryData() != null) {
      this.stepTwoForm.patchValue({
        query: this.IngestionsharingService.getStepTwoQueryData().query,
      });
      this.query = this.IngestionsharingService.getStepTwoQueryData().query;
    }
    if (this.IngestionsharingService.getQuerySQLStatement() != null) {
      this.stepTwoForm.patchValue({
        query: this.ingestionService.getQuerySQLStatement(),
      });
      this.query = this.ingestionService.getQuerySQLStatement();
    }
    this.catalogControllerService
      .getCatalogforDatabases(this.id)
      .subscribe((response) => {
        let data: any = response;
        this.sourceDatabases = data.sourceDatabases;
      });
      this.onChange(this.IngestionsharingService.getStepTwoQueryData().query);
  }
}

import { Injectable } from '@angular/core';
import { Config } from '../../config';
import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RdbmsConnector } from "../../models/rdbms/rdbms-connector.model"
import { SqoopConnector } from 'src/app/models/sqoop-connector/sqoop-connector.model';

@Injectable({
  providedIn: 'root'
})
export class SqoopServiceService {

  static url = Config.url;

  constructor(private httpClient: HttpClient) { }

  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  getSqoopJobsByRDBMSJobId(data: any): Observable<SqoopConnector[]> {
    return this.httpClient.get<SqoopConnector[]>(SqoopServiceService.url + "/sqoop/jobs/rdbms/" + data)
      .pipe(
        retry(1),
        // catchError(this.processError)
      )
  }

  getSingleSqoopJobBySqoopId(data: any): Observable<SqoopConnector[]>{
    return this.httpClient.get<SqoopConnector[]>(SqoopServiceService.url + "/sqoop/job/" + data)
    .pipe(
      retry(1),
      //catchError(this.processError)
    )
  }
}

import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  NgForm,
  FormArray,
  Validators,
} from '@angular/forms';
import { DataSourceControllerService } from 'src/app/services/api/data-source/data-source-controller.service';
import { CatalogService } from '../../../../../../../services/api/catalogService/catalog.service';
import { RdbmsIngestionControllerService } from 'src/app/services/api/rdbms-controller/rdbms-ingestion-controller.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { IngestionSharingServiceService } from 'src/app/ingestion-sharing-service.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ProjectService } from 'src/app/services/project/project-service.service';
import { HiveService } from '../../../../../../../services/api/hive/hive.service';
import * as $ from 'jquery';

export interface database {
  destinationDatabaseName: string;

  sourceDatabaseName: string;
  tables: tables[];
}

export interface tables {
  destinationTableName: string;

  sourceTableName: string;
  tableId: string;
  schema?: string;
  incremental?: string;

  columns: columns[];
}

interface toggle {
  name: string;
}

export interface columns {
  sourceColumnName: string;
  destinationColumnName: string;

  sourceDataType: string;
  destinationDataType: string;
  primaryKey: boolean;
  checkColumn: boolean;
}

interface toggle {
  name: string;
}
@Component({
  selector: 'app-step-three-custom-ingestion',
  templateUrl: './step-three-custom-ingestion.component.html',
  styleUrls: ['./step-three-custom-ingestion.component.scss'],
})
export class StepThreeCustomIngestionComponent
  implements OnInit, AfterViewInit
{
  tableSelected: boolean;
  // @ViewChild('tableUpdateForm') form = NgForm;
  @ViewChild("errorEditForm")editForm:ElementRef;
  @ViewChild('closebutton') closebutton;
 

  //snackbar
  whereBy: string;
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  isReadOnly: any = [];
  //datasource
  id = this.activatedRoute.snapshot.params['id'];

  projectName = this.activatedRoute.snapshot.params['id2'];

  itemObjectsRight: any = this.IngestionsharingService.getStepTwoData();
  itemObjectsRightCopy: any = this.IngestionsharingService.getStepTwoCopyData();
  valueUpdate: any;

  fileType: string;
  //loader
  load: boolean = true;
  //disable select
  disableSelect: false;
  //pagination
  page = 1;
  pageSize = 7;
  destinationForm: FormGroup;
  // edit table
  csvDestinationDataTypes = [
    'INT',
    'SMALLINT',
    'BIGINT',
    'TINYINT',
    'FLOAT',
    'DOUBLE',
    'DECIMAL(38,18)',
    'NUMERIC',
    'TIMESTAMP',
    'DATE',
    'INTERVAL',
    'STRING',
    'VARCHAR(255)',
    'CHAR(255)',
    'BOOLEAN',
    'BINARY',
  ];
  avroDestinationDataTypes = [
    'INT',
    'SMALLINT',
    'BIGINT',
    'TINYINT',
    'FLOAT',
    'DOUBLE',
    'DECIMAL(38,18)',
    'NUMERIC',
    'STRING',
    'BOOLEAN',
    'BINARY',
  ];
  parquetDestinationDataType = [
    'INT',
    'SMALLINT',
    'BIGINT',
    'TINYINT',
    'FLOAT',
    'DOUBLE',
    'NUMERIC',
    'STRING',
    'BOOLEAN',
    'BINARY',
  ];
  canIncrementById: boolean;
  canIncrementByTimeStamp: boolean;

  isSubmitShow: boolean = false;

  //toggle
  toggle: any = [];

  //selected database
  selectedDatabase: any;
  databaseInformation: any = this.IngestionsharingService.getStepOneData();
  numberOfTables: number;
  //selected table
  selectedTable: any;
  selectedTableIndex: number;

  //selected columns
  selectedColumns: any;
  incrementalColumnValue: string;
  splitByColumnName: string;
  //individual column
  selectedColumn: any;
  selectedColumnLength: number;
  //selected tables from database
  tablesFromDatabase: any[];
  // columns from selected table
  columnsFromTable: any[];

  //input togggle

  //ngModals
  databaseName: string;
  tableName: string;

  // Eroor Button Section starts Template Driven
  isError:boolean;
  isStepOne: boolean;
  isStepTwo: boolean;
  isStepThree: boolean;
  modelCloseButton:boolean=true;
  errorForm: FormGroup;
  // modifiedItemObjectsRight: any = []; //For storing Modified itemObjectsRight array

  // Eroor Button Section Ends

  //database names for validation
  databaseNamesForValidation: any = [];
  databaseConflicts: any;
  invalidColumnNames: any[] = [];
  invalidTableNames: any[] = [];
  invalidDbNames: any[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private dataSourceController: DataSourceControllerService,
    private catalogService: CatalogService,
    public service: RdbmsIngestionControllerService,
    public router: Router,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private IngestionsharingService: IngestionSharingServiceService,
    public projectService: ProjectService,
    private hiveService: HiveService
  ) {}

  showDiv = {
    stepone: false,
    steptwo: false,
    stepthree: true,
    stepfour: false,
    stepfive: false,

    stepbtnone: true,
    stepbtntwo: false,
    stepbtnthree: false,
    stepbtnfour: false,
    stepbtnfive: false,

    cancelbtn: false,
    nextbtn: true,
    nextbtntwo: false,
    nextbtnthree: false,
    nextbtnfour: false,

    backbtn: false,
    backbtntwo: false,
    backbtnthree: false,
    backbtnfour: false,
    backbtnfive: false,

    submitbtn: false,
  };

  ngOnInit(): void {
    setTimeout(() => (this.load = false), 500);
 
    this.destinationForm = new FormGroup({
      ingestionMode: new FormControl(''),
      incrementBy: new FormControl(''),
      where: new FormControl(''),
      splitBy: new FormControl(''),
    });

    this.errorForm = this.formBuilder.group({
      dbNames: this.formBuilder.array([]),
      tableNames: this.formBuilder.array([]),
      columnNames: this.formBuilder.array([]),
    });

    this.validateColumnName();

    this.tableSelected = false;
    this.tableCount();
    this.hiveService
      .hiveValidate(this.itemObjectsRight)
      .subscribe((response) => {
        this.databaseConflicts = response;
        this.returnKeys(this.databaseConflicts);
      });
    // console.log('original', this.itemObjectsRight);
   
    // console.log('ng',this.itemObjectsRight);
    this.invalidNameTrim();

 } 
  //Replacing destination coulmun name with source column name
  destinationColumnNameFill(){
    this.itemObjectsRight.forEach((db:any)=>{
      // console.log('db',db.tables);
      db.tables.forEach((col:any)=>{
        // console.log('col',col.columns);
        for(let element of col.columns){
          // console.log('element',element.sourceColumnName);
          element.destinationColumnName=element.sourceColumnName;
          
        }
        // console.log('colfill',col.columns);

      })
      
    })
  }
 
//POPUP DISPLAY CONDITION CHECK
NameValidation(){
  // 
  if(this.invalidDbNames.length){
    
    // this.isShow=true;
    this.isStepOne=true;
    this.editForm.nativeElement.click()
  }
  else if(!this.invalidDbNames.length && this.invalidTableNames.length)
  {
    this.editForm.nativeElement.click()
      this.isStepOne=false
      this.isStepTwo=true
    

  }
  else if(!this.invalidDbNames.length && !this.invalidTableNames.length && this.invalidColumnNames.length){
    this.editForm.nativeElement.click()
    this.isStepOne=false;
    this.isStepTwo=false;
    this.isStepThree=true
    
  }
  else{
    this.closebutton.nativeElement.click()
  }
  
}


public dbFormControl(
  sourceDbName: string,
  destinationDbName:string
  
): FormGroup {
  let sourceName = new FormGroup({
    sourceDbName:new FormControl({value:destinationDbName,disabled:false}),
       destinationDbName: new FormControl(sourceDbName,[
      Validators.required,
      // TODO : find correct regex pattern

      Validators.pattern('[a-zA-Z0-9_]*$'),
    ])
    // tableid: new FormControl({ value: tableId, disabled: true }),
  });

  return sourceName;
}


  public tableFormControl(
    sourceDbName: string,
    tableName: string,
    tableId: string
  ): FormGroup {
    let sourceName = new FormGroup({
      sourceDbName: new FormControl({ value: sourceDbName, disabled: true }),
      sourceTableName: new FormControl(tableName, [
        Validators.required,
        // TODO : find correct regex pattern

        Validators.pattern('[a-zA-Z0-9_]*$'),
      ]),
      tableid: new FormControl({ value: tableId, disabled: true }),
    });

    return sourceName;
  }

  public columnFormControl(
    databaseName: string,
    // tableid:string,
    tableName: string,
    columnName: string

  ): FormGroup {
   
    //Duplicate field used because faild to access the value of disabled field in columnNameUpdate() function
    let sourceName = new FormGroup({
      sourceDbName2: new FormControl({value:databaseName, disabled:true}),
      sourceDbName: new FormControl({value:databaseName, disabled:false}),
      sourceColumn:new FormControl(columnName),
      sourceTableName: new FormControl({value:tableName, disabled:false}),
      sourceTableName2: new FormControl({value:tableName, disabled:true}),

      columnName:new FormControl(columnName,[
        Validators.required,
        Validators.pattern('[a-zA-Z0-9_]*$'),
      ])
  


    });
    // console.log('columnName',columnName);
    // console.log('invalid ',this.invalidColumnNames);

    // console.log('form control',sourceName);


    return sourceName
    ;
  }
 

  retrieveTables(database: any) {
    this.selectedDatabase = database;

    this.tablesFromDatabase = [];

    database.tables.map((table) => {
      this.tablesFromDatabase.push(table);
    });
  }

  file = this.formBuilder.group({
    fileType: this.IngestionsharingService.getStepOneData().fileType,
  });

  updateStepData() {
    let stepData: any[] = this.IngestionsharingService.getStepTwoData();

    if (stepData.length == 1) {
      this.IngestionsharingService.setStepTwoData([this.selectedDatabase]);
      this.IngestionsharingService.setStepTwoCopyData([this.selectedDatabase]);
    } else {
      const index = stepData.findIndex(
        (step) =>
          step.sourceDatabaseName == this.selectedDatabase.sourceDatabaseName
      );
      stepData.splice(index, 1, this.selectedDatabase);
      this.IngestionsharingService.setStepTwoData(stepData);
      this.IngestionsharingService.setStepTwoCopyData(stepData);
    }
  }

  updateModifiedTable(type: string, event?: any) {
    if (type == 'where') {
      this.selectedTable.where = this.destinationForm.get('where').value;
      this.selectedDatabase.tables.splice(
        this.selectedTableIndex,
        1,
        this.selectedTable
      );
    }

    if (type == 'split') {
      this.selectedTable.splitBy = event.value;
      this.selectedDatabase.tables.splice(
        this.selectedTableIndex,
        1,
        this.selectedTable
      );
    }

    this.updateStepData();
  }

  selectFileType(fileTypeValue: string) {
    this.file.patchValue({
      fileType: fileTypeValue,
    });
    if (
      this.file.value.fileType == 'avro' ||
      this.file.value.fileType == 'AVRO'
    ) {
      this.fileTypeChangeWarning(
        'The Avro does not support timestamps or dates in this version. Timestamps and Date data types can be casted to BigInt.'
      );
      for (let i = 0; i < this.itemObjectsRight.length; i++) {
        for (let j = 0; j < this.itemObjectsRight[i].tables.length; j++) {
          for (
            let k = 0;
            k < this.itemObjectsRight[i].tables[j].columns.length;
            k++
          ) {
            this.itemObjectsRight[i].tables[j].columns[k].destinationDataType =
              this.returnDestinationDataType(
                this.itemObjectsRight[i].tables[j].columns[k]
                  .destinationDataType
              );
          }
        }
      }
      this.IngestionsharingService.setStepTwoData(this.itemObjectsRight);
      this.IngestionsharingService.setStepTwoCopyData(this.itemObjectsRight);
    } else if (
      this.file.value.fileType == 'parquet' ||
      this.file.value.fileType == 'PARQUET'
    ) {
      this.fileTypeChangeWarning(
        'The Parquet option does not support timestamps, dates, or decimal in this version. Timestamps and Date data types can be casted to BigInt. Decimal can be casted to a lower precision data type like Double'
      );
      for (let i = 0; i < this.itemObjectsRight.length; i++) {
        for (let j = 0; j < this.itemObjectsRight[i].tables.length; j++) {
          for (
            let k = 0;
            k < this.itemObjectsRight[i].tables[j].columns.length;
            k++
          ) {
            this.itemObjectsRight[i].tables[j].columns[k].destinationDataType =
              this.returnDestinationDataType(
                this.itemObjectsRight[i].tables[j].columns[k]
                  .destinationDataType
              );
          }
        }
      }
      this.IngestionsharingService.setStepTwoData(this.itemObjectsRight);
      this.IngestionsharingService.setStepTwoCopyData(this.itemObjectsRight);
    } else {
      for (let i = 0; i < this.itemObjectsRight.length; i++) {
        for (let j = 0; j < this.itemObjectsRight[i].tables.length; j++) {
          for (
            let k = 0;
            k < this.itemObjectsRight[i].tables[j].columns.length;
            k++
          ) {
            this.itemObjectsRight[i].tables[j].columns[k].destinationDataType =
              this.itemObjectsRightCopy[i].tables[j].columns[
                k
              ].destinationDataType;
          }
        }
      }
      this.IngestionsharingService.setStepTwoData(this.itemObjectsRight);
      this.IngestionsharingService.setStepTwoCopyData(this.itemObjectsRight);
    }

    let data: any = this.IngestionsharingService.getStepOneData();
    data.fileType = this.file.value.fileType;
    this.IngestionsharingService.setStepOneData(data);

    // console.log(this.IngestionsharingService.getStepOneData());
  }

  retrieveColumns(table: any) {
    //change item
    // document.getElementById(tableInfo).style.backgroundColor = "transparent";

    this.destinationForm.reset();

    this.selectedTable = table;
    this.selectedTableIndex = this.selectedDatabase.tables.findIndex(
      (table) => table.sourceTableName == this.selectedTable.sourceTableName
    );

    if (table.splitBy) {
      const splitColumn = table.columns.find(
        (c) => c.sourceColumnName === table.splitBy
      );
      this.destinationForm.controls.splitBy.setValue(
        splitColumn.sourceColumnName
      );
    }

    if (table.where) {
      this.destinationForm.controls.where.setValue(table.where);
    }

    let incrementCount = 0;
    for (let i = 0; i < this.selectedTable.columns.length; i++) {
      if (
        this.selectedTable.columns[i].sourceDataType.includes('INT') ||
        this.selectedTable.columns[i].sourceDataType.includes('int')
      ) {
        incrementCount++;
      }
    }
    if (incrementCount > 0) {
      this.canIncrementById = true;
    } else {
      this.canIncrementById = false;
    }
    let timestampCount: number = 0;
    for (let columns of this.selectedTable.columns) {
      if (
        columns.sourceDataType.includes('DATE') ||
        columns.sourceDataType.includes('TIMESTAMP') ||
        columns.sourceDataType.includes('date') ||
        columns.sourceDataType.includes('timestamp')
      ) {
        timestampCount++;
      }
    }
    if (timestampCount > 0) {
      this.canIncrementByTimeStamp = true;
    } else {
      this.canIncrementByTimeStamp = false;
    }

    this.selectedColumnLength = table.columns.length;
    this.tableSelected = true;
    if (table.columns != undefined) {
      this.selectedTable = table;

      this.columnsFromTable = [];
      for (let column of table.columns) {
        this.columnsFromTable.push(column);
      }
    }
  }

  mergeObjects(array: any) {
    let count = 0;
    // console.log(array);

    for (let i = 0; i < array.length; i++) {
      for (let j = 1; j < array.length - i; j++) {
        if (
          array[j - 1].destinationDatabaseName ==
          array[j].destinationDatabaseName
        ) {
          let newArray: any = array[j - 1];
          newArray.tables = array[j - 1].tables.concat(array[j].tables);
          array.splice(j, 1);
        }
      }
    }
    this.IngestionsharingService.setStepTwoData(this.itemObjectsRight);
    this.IngestionsharingService.setStepTwoCopyData(this.itemObjectsRight);

    return array;
  }

  nullcheck(value: any) {
    if (value == null) {
      return '';
    } else {
      return value + '.';
    }
  }

  updateDatabaseName(database: any, table: any) {
    let databaseCount = 0;
    for (let i = 0; i < this.itemObjectsRight.length; i++) {
      if (
        this.itemObjectsRight[i].sourceDatabaseName ==
        database.sourceDatabaseName
      ) {
        databaseCount++;
      }
    }
    if (database.tables.length != 1) {
      Swal.fire({
        type: 'warning',

        title: 'Do you want to split table into new database? ',
        text:
          "Do you want to move the table '" +
          table.destinationTableName +
          "' into it's own database called '" +
          this.databaseName.toLowerCase() +
          "', or rename database for all tables inside this database.",
        showCancelButton: true,
        confirmButtonText: 'Yes, Split To New Database',
        cancelButtonText: 'No, Rename For All Tables',
      }).then((result) => {
        if (result.isConfirmed == true) {
          database.destinationDatabaseName = this.databaseName.toLowerCase();

          if (database.tables.length > 1) {
            let filteredTable = database.tables.filter(
              (tables) => tables.sourceTableName == table.sourceTableName
            );
            let unfilteredTables = database.tables.filter(
              (tables) => tables.sourceTableName != table.sourceTableName
            );

            // let unfilteredTables = JSON.parse(JSON.stringify(database.tables))

            database.tables = filteredTable;
            this.itemObjectsRight.push({
              sourceDatabaseName: database.sourceDatabaseName,
              destinationDatabaseName: database.sourceDatabaseName,
              tables: unfilteredTables,
            });

            this.IngestionsharingService.setStepTwoData(this.itemObjectsRight);
            this.IngestionsharingService.setStepTwoCopyData(
              this.itemObjectsRight
            );
            this.databaseNamesForValidation = [];
            this.hiveService
              .hiveValidate(this.itemObjectsRight)
              .subscribe((response) => {
                this.databaseConflicts = response;
                this.returnKeys(this.databaseConflicts);
              });
            // console.log(this.itemObjectsRight);
            this.mergeObjects(this.itemObjectsRight);
          }
        } else {
          database.destinationDatabaseName = this.databaseName.toLowerCase();
          this.databaseNamesForValidation = [];
          this.hiveService
            .hiveValidate(this.itemObjectsRight)
            .subscribe((response) => {
              this.databaseConflicts = response;
              this.returnKeys(this.databaseConflicts);
              this.mergeObjects(this.itemObjectsRight);
            });
          this.mergeObjects(this.itemObjectsRight);
        }
      });
    } else {
      database.destinationDatabaseName = this.databaseName.toLowerCase();
      this.databaseNamesForValidation = [];
      this.hiveService
        .hiveValidate(this.itemObjectsRight)
        .subscribe((response) => {
          this.databaseConflicts = response;
          this.returnKeys(this.databaseConflicts);
          // console.log(this.itemObjectsRight);

          this.mergeObjects(this.itemObjectsRight);
        });
    }
  }

  returnKeys(object: any) {
    for (const [key, value] of Object.entries(object)) {
      this.databaseNamesForValidation.push({
        key: `${key}`,
        value: `${value}`,
      });
    }
  }

  deleteDatabase(parent: any, child: any) {
    parent.splice(child, 1);
    for (let i = 0; i < this.itemObjectsRight; i++) {
      // console.log(
      //   this.selectedDatabase.destinationDatabaseName,
      //   this.itemObjectsRight[i].destinationDatabaseName
      // );

      if (
        this.selectedDatabase.destinationDatabaseName !=
        this.itemObjectsRight[i].destinationDatabaseName
      ) {
        this.tableSelected = true;
      }
    }
    this.IngestionsharingService.setStepTwoData(this.itemObjectsRight);
    this.IngestionsharingService.setStepTwoCopyData(this.itemObjectsRight);
  }

  checkForConflicts(object: any): number {
    let count: number = 0;
    for (const item of object) {
      if (item.value != 'No Conflict.') {
        count++;
      }
    }
    return count;
  }
//function for checking db,table or column has any invalid name
  validateColumnName() {
    this.itemObjectsRight.forEach((db: any) => {
      if (this.verifyName(db.sourceDatabaseName)) {
        this.invalidDbNames.push(db);
        // console.log('db',this.invalidDbNames);
        
        (<FormArray>this.errorForm.get('dbNames')).push(this.dbFormControl(
          db.sourceDatabaseName,
          db.destinationDatabaseName));
      }
      db.tables.forEach((table: any) => {
        if (this.verifyName(table.sourceTableName)) {
          this.invalidTableNames.push(table);
          (<FormArray>this.errorForm.get('tableNames')).push(
            this.tableFormControl(
              db.sourceDatabaseName,
              table.sourceTableName,
              table.tableId
            ));
        }
        table.columns.forEach((column: any) => {
          if (this.verifyName(column.sourceColumnName)){
          (<FormArray>this.errorForm.get('columnNames')).push(
            this.columnFormControl(
              db.sourceDatabaseName,
              // table.tableId,
              table.sourceTableName,
              column.sourceColumnName,
              
            ));
           
            this.invalidColumnNames.push(column);

          }
          // console.log('pls edit',column.sourceColumnName);
         
        });
      });
      // console.log('error form', this.invalidColumnNames);
this.invalidNameTrim();
    });
    
    
  }
  // To handle whitespace at the begining 
  invalidNameTrim(){
    // console.log('start',this.itemObjectsRight);
    this.itemObjectsRight.forEach((db=>{
      db.destinationDatabaseName=db.destinationDatabaseName.trimStart();
      db.tables.forEach((table)=>{
        table.destinationTableName=table.destinationTableName.trimStart();

        table.columns.forEach((col=>{
         col.sourceColumnName=col.sourceColumnName.trimStart();
          // console.log('chang',col);

        }))
        
      })
      
    }))
    console.log(this.itemObjectsRight);
    
    
  }

  verifyName(name: string): boolean {
    if (name !== name.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '_')) {
      return true;
    }

    return false;
  }

  updateTableName(database: any, table: any) {
    let tableCount: number = 0;
    for (let item of database.tables) {
      if (item.sourceTableName == this.tableName) {
        tableCount++;
      }
    }
    if (this.tableName != '') {
      database.tables[database.tables.indexOf(table)].destinationTableName =
        this.tableName.toLowerCase();
      this.IngestionsharingService.setStepTwoData(this.itemObjectsRight);
      this.IngestionsharingService.setStepTwoCopyData(this.itemObjectsRight);
      this.databaseNamesForValidation = [];
      this.hiveService
        .hiveValidate(this.itemObjectsRight)
        .subscribe((response) => {
          this.databaseConflicts = response;
          this.returnKeys(this.databaseConflicts);
        });
    } else {
      this.tableName = table.sourceTableName.toLowerCase();
      table.destinationTableName = table.sourceTableName;
      this.IngestionsharingService.setStepTwoData(this.itemObjectsRight);
      this.IngestionsharingService.setStepTwoCopyData(this.itemObjectsRight);
      this.databaseNamesForValidation = [];
      this.hiveService
        .hiveValidate(this.itemObjectsRight)
        .subscribe((response) => {
          this.databaseConflicts = response;
          this.returnKeys(this.databaseConflicts);
        });
    }
  }

  editTableLoad(table: any, incrementValue: string) {
    table.incremental = incrementValue;
    this.IngestionsharingService.setStepTwoData(this.itemObjectsRight);
    this.IngestionsharingService.setStepTwoCopyData(this.itemObjectsRight);
    this.selectedTable = table;
    for (let i = 0; i < table.columns.length; i++) {
      table.columns[i].checkColumn = false;
    }
  }

  editCheckColumn(database: any, table: any, column: any) {
    this.itemObjectsRight[this.itemObjectsRight.indexOf(database)].tables[
      this.itemObjectsRight[
        this.itemObjectsRight.indexOf(database)
      ].tables.indexOf(table)
    ].columns[
      this.itemObjectsRight[this.itemObjectsRight.indexOf(database)].tables[
        this.itemObjectsRight[
          this.itemObjectsRight.indexOf(database)
        ].tables.indexOf(table)
      ].columns.indexOf(column)
    ].checkColumn = true;

    for (let columns of this.itemObjectsRight[
      this.itemObjectsRight.indexOf(database)
    ].tables[
      this.itemObjectsRight[
        this.itemObjectsRight.indexOf(database)
      ].tables.indexOf(table)
    ].columns) {
      if (
        this.itemObjectsRight[this.itemObjectsRight.indexOf(database)].tables[
          this.itemObjectsRight[
            this.itemObjectsRight.indexOf(database)
          ].tables.indexOf(table)
        ].columns.indexOf(columns) !=
        this.itemObjectsRight[this.itemObjectsRight.indexOf(database)].tables[
          this.itemObjectsRight[
            this.itemObjectsRight.indexOf(database)
          ].tables.indexOf(table)
        ].columns.indexOf(column)
      ) {
        columns.checkColumn = false;
      }
      this.IngestionsharingService.setStepTwoData(this.itemObjectsRight);
      this.IngestionsharingService.setStepTwoCopyData(this.itemObjectsRight);
      this.selectedColumns =
        this.itemObjectsRight[this.itemObjectsRight.indexOf(database)].tables[
          this.itemObjectsRight[
            this.itemObjectsRight.indexOf(database)
          ].tables.indexOf(table)
        ].columns[
          this.itemObjectsRight[this.itemObjectsRight.indexOf(database)].tables[
            this.itemObjectsRight[
              this.itemObjectsRight.indexOf(database)
            ].tables.indexOf(table)
          ].columns.indexOf(column)
        ];
    }
  }

  editDestinationDataType(
    database: any,
    table: any,
    column: any,
    destinationDataType: string
  ) {
    for (let columns of this.itemObjectsRight[
      this.itemObjectsRight.indexOf(database)
    ].tables[
      this.itemObjectsRight[
        this.itemObjectsRight.indexOf(database)
      ].tables.indexOf(table)
    ].columns) {
      if (
        this.itemObjectsRight[this.itemObjectsRight.indexOf(database)].tables[
          this.itemObjectsRight[
            this.itemObjectsRight.indexOf(database)
          ].tables.indexOf(table)
        ].columns.indexOf(columns) ==
        this.itemObjectsRight[this.itemObjectsRight.indexOf(database)].tables[
          this.itemObjectsRight[
            this.itemObjectsRight.indexOf(database)
          ].tables.indexOf(table)
        ].columns.indexOf(column)
      ) {
        columns.destinationDataType = destinationDataType;
      }
    }
    this.IngestionsharingService.setStepTwoData(this.itemObjectsRight);
    this.IngestionsharingService.setStepTwoCopyData(this.itemObjectsRight);

    this.selectedColumns =
      this.itemObjectsRight[this.itemObjectsRight.indexOf(database)].tables[
        this.itemObjectsRight[
          this.itemObjectsRight.indexOf(database)
        ].tables.indexOf(table)
      ].columns[
        this.itemObjectsRight[this.itemObjectsRight.indexOf(database)].tables[
          this.itemObjectsRight[
            this.itemObjectsRight.indexOf(database)
          ].tables.indexOf(table)
        ].columns.indexOf(column)
      ];
  }

  success(status: string) {
    Swal.fire({
      type: 'Success',
      title: 'Success!',
      text: status,
      confirmButtonText: 'Ok',
    });
  }

  fileTypeChangeWarning(status: string) {
    Swal.fire({
      type: 'warning',
      title: 'Warning',
      text: status,
      confirmButtonText: 'Ok',
    });
  }

  returnDestinationDataType(destinationDataType: string): any {
    if (
      this.file.value.fileType == 'parquet' ||
      this.file.value.fileType == 'PARQUET'
    ) {
      if (destinationDataType.includes('CHAR')) {
        return 'STRING';
      } else if (destinationDataType.includes('TIMESTAMP')) {
        return 'BIGINT';
      } else if (destinationDataType.includes('DATE')) {
        return 'INT';
      } else if (
        destinationDataType.includes('DECIMAL') ||
        destinationDataType.includes('NUMERIC')
      ) {
        return 'DOUBLE';
      }
    } else if (
      this.file.value.fileType == 'avro' ||
      this.file.value.fileType == 'AVRO'
    ) {
      if (destinationDataType.includes('CHAR')) {
        return 'STRING';
      } else if (destinationDataType.includes('TIMESTAMP')) {
        return 'BIGINT';
      } else if (destinationDataType.includes('DATE')) {
        return 'INT';
      }
    }
    return destinationDataType;
  }

  error(status: string) {
    Swal.fire({
      type: 'Error',

      title: 'Ooops!',
      text: status,
      confirmButtonText: 'Ok',
    });
  }

  warning(status: string) {
    Swal.fire({
      type: 'warning',

      title: 'Are you sure you want to delete the database?',
      text: status,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    });
  }

  carryData() {
    // this.IngestionsharingService.stepThreeData(this.itemObjectsRight);
    this.IngestionsharingService.setStepThreeData(this.itemObjectsRight)
    this.router.navigate([
      `/project/${this.projectName}/ingest/select-ingestion/stepFourCustom/${this.id}`,
    ]);
  }
  // onClikNext(){
  //   this.IngestionsharingService.stepThreeData(this.itemObjectsRight);
  // }

  deleteColumn(parent: any, child: any) {
    Swal.fire({
      type: 'warning',

      title: 'Are you sure you want to delete this column?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed == true) {
        parent.splice(child, 1);
        this.IngestionsharingService.setStepTwoData(this.itemObjectsRight);
        this.IngestionsharingService.setStepTwoCopyData(this.itemObjectsRight);
      }
    });
  }

  // this.router.navigateByUrl(`/ingest-rdbms/${this.id}`);

  tableCount() {
    let tableCount: number = 0;
    for (let database of this.itemObjectsRight) {
      tableCount += database.tables.length;
    }
    this.numberOfTables = tableCount;
  }

  ngAfterViewInit() {
    $(document).ready(function () {
      function treeheight() {
        var menuscroll = $(window).height() - 380;
        var selecttable = $(window).height() - 380;
        $('.menublock_scroll').css('height', menuscroll);
        $('.selecttable_c').css('height', selecttable);
      }
      setTimeout(treeheight, 1000);
    });

    $('body').on('click', '.menublock_scroll', function () {
      var menuscroll = $(window).height() - 380;
      var selecttable = $(window).height() - 380;
      $('.menublock_scroll').css('height', menuscroll);
      $('.selecttable_c').css('height', selecttable);
    });

    $(window).resize(function () {
      function treeheight() {
        var menuscroll = $(window).height() - 380;
        var selecttable = $(window).height() - 380;
        $('.menublock_scroll').css('height', menuscroll);
        $('.selecttable_c').css('height', selecttable);
      }
      setTimeout(treeheight, 4);
    });
   
    this.destinationColumnNameFill();
    this.NameValidation();
    // this.errorCheck();

  }
  
  dbNameUpdate(){
    
    // console.log('itemobject',this.itemObjectsRight);
    let value=this.errorForm.get('dbNames').value;
    // console.log('whole',value);
    for(let param of value){
      // console.log('Destination value is',param.destinationDbName);
      let sourceDbaram=param.sourceDbName;
      let destinationDbParam=param.destinationDbName
      // console.log('Source value is',param.sourceDbName);
      const index= this.itemObjectsRight.findIndex((f)=>f.sourceDatabaseName == sourceDbaram);
      // console.log(index);
      this.itemObjectsRight[index].destinationDatabaseName=destinationDbParam;
      // console.log('final db',this.itemObjectsRight);
      this.isStepOne = false;
      console.log('this.invalidTableNames',this.invalidTableNames);

    }
    if(this.invalidTableNames.length){
      console.log('this.invalidTableNames',this.invalidTableNames);
      this.isStepTwo=true;
    }
    else if(this.invalidColumnNames.length){
      this.isStepThree=true
    }
    else if(!this.invalidTableNames.length && !this.invalidColumnNames.length){
      this.closebutton.nativeElement.click();
      
    }
    
    
  }


  tableNameUpdate() {
    // console.log('source is',this.itemObjectsRight);

    let newItemsObjec = this.invalidTableNames
      .map((item, index) => {
        let newItem = {
          ...item,
          destinationTableName:
            this.errorForm.get('tableNames').value[index].sourceTableName,
        };

        newItem.dbName =
          this.errorForm.get('tableNames')['controls'][
            index
          ].controls.sourceDbName.value;

        return newItem;
      })
      .reduce((acc, final) => {
        let { dbName, ...other } = final;

        if (!('dbName' in acc)) {
          acc = {
            dbName: null,
            table: [],
          };
        }

        acc.dbName = dbName;
        acc.table.push(other);

        return acc;
      }, []);

      let db = this.itemObjectsRight.find(
      (item) => item.sourceDatabaseName === newItemsObjec.dbName
      );

      let newTables = db.tables.map((item) => {
      const invalidItem = newItemsObjec.table.find(
        (t) => t.tableId === item.tableId
      );

      if (invalidItem) {
        let newItem = {
          ...item,
          destinationTableName: invalidItem.destinationTableName,
        };

        return newItem;
      } else {
        return item;
      }
    });
    this.isStepOne = false;
    this.isStepTwo = false;
    if(this.invalidColumnNames.length){
      this.isStepThree = true;

    }
    else{
      // this.isFinished=true
      this.closebutton.nativeElement.click();
    }

    db.tables = [...newTables];

    // console.log('updated items object', this.itemObjectsRight);
    // console.log('invalid columns are',this.invalidColumnNames);
    
    
    
  }
  
  
  columnNameUpdate(){
     let value= this.errorForm.get('columnNames').value
    console.log('value',value);
    //  console.log('item',this.itemObjectsRight);
     for(let param of value){
      // console.log('dbname ',param.sourceDbName);
      const dbName=this.itemObjectsRight.find(db=>db.sourceDatabaseName===param.sourceDbName)//Finding database name
      // const tableIndex=this.itemObjectsRight.dbIndex.find(table=>
      let table=dbName.tables.find(table=>table.sourceTableName===param.sourceTableName);//Finding TableName
      // console.log(table)
      let colindex=table.columns.findIndex(table=>table.sourceColumnName===param.sourceColumn);//Finding Column Name
      // console.log('tableIndex',tableIndex);
      // console.log('final',colindex);
      // console.log('edit here',param.columnName);
      
      // console.log('update ',table.columns[colindex].sourceColumnName);
      // if(table.columns.forEach((col)=>col.destinationColumnName))
      table.columns[colindex].destinationColumnName=param.columnName;
  
      
     }
    console.log('final col',this.itemObjectsRight);
     
    // this.isFinished=true
    this.isStepThree=false
    this.closebutton.nativeElement.click();
     
  }
  
   
}

<section class="pagec">  
    <div class="cm-content-blocks">
      <ol class="breadcrumb">
          <li><a routerLink="/dashboard"><i class="fa fa-home"></i>Home</a></li>
      </ol>
    </div>
  
    <div class="errorpage relativepos">
      <div class="notfound_img"> </div>
      <div class="error404 error_text">
        <h1 class="fnfour">404</h1>
        <h2 class="pagenotfound base_clr">PAGE NOT FOUND</h2>
        <h3 class="sorry_t">We’re sorry, we seem to have lost this page.</h3>
       <div class="goback"> <a routerLink="/home">Go back to Home </a></div> 

        <!-- <div class="goback"> <a routerLink="/home">Go back to Previous page </a></div> -->
      </div>
    </div>
</section>
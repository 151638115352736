import { Component, OnInit } from '@angular/core';
import { FormControl, NgForm, FormGroup, FormBuilder, Validators, FormArray, ReactiveFormsModule } from '@angular/forms';
import { DataSourceControllerService } from 'src/app/services/api/data-source/data-source-controller.service';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { CatalogService } from "../../../../../../../../services/api/catalogService/catalog.service"
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { CdkDragDrop, moveItemInArray, transferArrayItem, copyArrayItem } from '@angular/cdk/drag-drop';
import { RdbmsIngestionControllerService } from 'src/app/services/api/rdbms-controller/rdbms-ingestion-controller.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import { IngestionSharingServiceService } from 'src/app/ingestion-sharing-service.service';
import { ProjectService } from 'src/app/services/project/project-service.service';

@Component({
  selector: 'app-edit-step-one-query',
  templateUrl: './edit-step-one-query.component.html',
  styleUrls: ['./edit-step-one-query.component.scss']
})
export class EditStepOneQueryComponent implements OnInit {


  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  //datasource

  singleDataSource: any
  // treeControl = new NestedTreeControl(node => node.children);

  load = true;

  dataSources: any;
  dataSourceData: any;

  id: string = this.activatedRoute.snapshot.params['id'];
  status = false;
  buttonEnable: boolean = false;


  isSubmitShow: boolean = false;


  constructor(private formBuilder: FormBuilder, private dataSourceController: DataSourceControllerService,
    private catalogService: CatalogService, public service: RdbmsIngestionControllerService, public router: Router,
    private snackBar: MatSnackBar, private activatedRoute: ActivatedRoute, public IngestionService: IngestionSharingServiceService,
    public projectService: ProjectService,




  ) { }

  projectName = this.activatedRoute.snapshot.params['id2'];


  showDiv = {
    stepone: true,
    steptwo: false,
    stepthree: false,
    stepfour: false,
    stepfive: false,

    stepbtnone: true,
    stepbtntwo: false,
    stepbtnthree: false,
    stepbtnfour: false,
    stepbtnfive: false,

    cancelbtn: false,
    nextbtn: true,
    nextbtntwo: false,
    nextbtnthree: false,
    nextbtnfour: false,


    backbtn: false,
    backbtntwo: false,
    backbtnthree: false,
    backbtnfour: false,
    backbtnfive: false,

    submitbtn: false
  }


  customIngestionFormFields = this.formBuilder.group({
    sourceId: ["", Validators.required],
    name: ["", Validators.required],
    description: [""],
  })

  savedDatasourceDetails = this.formBuilder.group({
    name: [""]
  })

  identifyDriver(driver: string): string {
    if (driver == "mysql") {
      return "../../../../assets/images/mysqllogo.svg"
    } else if (driver == "oracle") {
      return "../../../../assets/images/Oracle.svg"
    } else if (driver == "sqlserver") {
      return "../../../../assets/images/sqlserver.png"
    } else if (driver == "db2") {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/5/51/IBM_logo.svg/1000px-IBM_logo.svg.png"
    } else if (driver == "snowflake") {
      return "../../../../assets/images/snowflake.svg"
    } else if (driver == "redshift") {
      return "https://cdn.worldvectorlogo.com/logos/aws-rds.svg"
    }
    else if (driver == "postgresql") {
      return "../../../../assets/images/postgresql.png"
    }

    else {
      return "../../"
    }
  }

  stepOneForm(fields: any): any {
    this.customIngestionFormFields.patchValue({

      sourceId: fields.value.sourceId,
      name: fields.value.name,
      description: fields.value.description,

    })


    console.log(this.customIngestionFormFields.value)
    this.IngestionService.setStepOneQueryData(this.customIngestionFormFields.value)
    this.router.navigate([`/project/${this.projectName}/ingest/editStepTwoQuery/${this.id}/${this.customIngestionFormFields.value.sourceId}`]);
  }

  // carryData() {
  //   if (this.IngestionService.getStepOneData() == null) {

  //     this.router.navigate([`/project/${this.projectName}/ingest/select-ingestion/stepTwoQuery/${this.id}`]);
  //   }
  //   if (this.IngestionService.getStepOneData() !== null) {
  //     this.router.navigate([`/project/${this.projectName}/ingest/select-ingestion/stepTwoQuery/${this.IngestionService.getStepOneData().sourceId}`]);

  //   }

  // }

  //API METHODS

  getAllDataSourcesByProjectName() {
    this.dataSourceController.getAllConnectionsByProjectName(this.projectService.getCurrentProject()).subscribe(res => {
      this.dataSources = res;
    })
  }



  getSingleDataSource(id: string) {
    this.load=true;
    // console.log('works');
    
    this.dataSourceController.getSingleRDBMSConnector(id).subscribe(res => {
      this.singleDataSource = res;
      this.savedDatasourceDetails.setValue({
        name: this.singleDataSource.name
      })
     
      this.load=false;

    })
    console.log('singleDataSource',this.singleDataSource);
  }





  ngOnInit(): void {
    this.service.getSingleRdbmsIngestion(this.id).subscribe(response => {
      let data: any = response;
      console.log(data);
      this.customIngestionFormFields.patchValue({
        sourceId: data.sourceId,
        name: data.name,
        description: data.description,
      })
      this.getSingleDataSource(data.sourceId);
    })
    console.log(this.IngestionService.getStepOneData())
    this.getAllDataSourcesByProjectName();
      // this.getSingleDataSource(this.singleDataSource.id)

    if (this.IngestionService.getStepOneData() != null) {
      this.customIngestionFormFields.patchValue({
        sourceId: this.IngestionService.getStepOneData().sourceId,
        name: this.IngestionService.getStepOneData().name,
        description: this.IngestionService.getStepOneData().description,

      })

      // this.getSingleDataSource(this.dataSources.id)

    }
    
    // console.log(this.IngestionService.getStepOneData())
    setTimeout(() => this.load = false, 500)
  }

}

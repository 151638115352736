<div *ngIf="load" class="">
  <div class="loader"></div>
  <figure class="spinner-border loaderico"></figure>
</div>
<div class="cm-content-blocks">
  <ol class="breadcrumb">
    <li><a routerLink="/home"><i class="fa fa-home"></i>Home</a></li>
    <li><a routerLink="/project"><i class="fa fa-folder-open"></i>Projects</a></li>
    <li><a routerLink="/project/{{projectName}}"><i class="fa fa-folder"></i>{{projectName}}</a></li>
    <li><a [routerLink]="['/project', projectService.getCurrentProject(),'ingest']"><i
          class="fa fa-filter"></i>Ingest</a></li>
    <li class="active"><i class="fa fa-sitemap mr-1"></i>Configure Ingestion</li>

  </ol>
</div>

<div class="clearafter headbtnc">
  <h3 class="headc">
    Ingestion Configuration
    <div class="subhead">Select the type of ingestion you wish to configure </div>
  </h3>
  <div class="create-btn-position headbtnc">
    <button class="mr-btn-back pull-right pointerc"
      [routerLink]="['/project', projectService.getCurrentProject(),'ingest']">Back to Ingest</button>
  </div>
</div>



<!-- <article class="db_boxec">
      <div  class="db_boxc" *ngFor = "let item of items" >
        <a routerLink="{{item.link}}" class="boxlink boxbuttons"  (click)="deleteData()"> 
          <div  class="db_box">
            <div class="cardico innerbox-imgc">
              <i class="fa import_img {{item.imageUrl}} " aria-hidden="true" class="cardimg"></i>
            </div>
            <h4 class="cardtitle innerbox_title">{{item.title}}</h4>
            <h5 class="cardtitle innerbox_text">{{item.description}}</h5> 
          </div>
        </a>
      </div> 
    </article> -->

<article class="flex-container fifteenmb">
  <section class="flex_content adminbox" *ngFor="let item of items">
    <a class="boxlink" routerLink="{{item.link}}" (click)="deleteData()">
      <div class="overview-card-wrapper">
        <figure class="adminbox-imgc base_clr">
          <span class="multipleicon_c">
            <i class="fa {{item.imageUrl}}"></i>
            <i class="fa second_icon {{item.imageUrlSecond}}"></i>
          </span>
        </figure>
        <h1 class="adminbox-title">{{item.title}}</h1>
        <p class="adminbox-text">{{item.description}}</p>
      </div>
    </a>
  </section>
</article>
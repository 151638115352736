import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/users/user.service';
import { IngestionSharingServiceService } from 'src/app/ingestion-sharing-service.service';
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  constructor(private userService: UserService, public ingestionSharingService: IngestionSharingServiceService) { }
  currentUser: any;
  user: any

  getUser() {
    this.user = JSON.parse(sessionStorage.getItem('loggedUser'));
    this.userService.getUserByUserName(this.user.authorities.principal.username).subscribe(response => {
      this.currentUser = response;

    })
  }
  getLoggedUser() {
    this.user = JSON.parse(sessionStorage.getItem('loggedUser'));
  }

  ngOnInit(): void {
    this.getLoggedUser();
    this.getUser();

  }

}

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Ingestion } from 'src/app/models/ingestion/ingestion';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { RdbmsIngestionControllerService } from 'src/app/services/api/rdbms-controller/rdbms-ingestion-controller.service';
import { StreamJobControllerService } from 'src/app/services/api/streamJob/stream-job-controller.service';

@Component({
  selector: 'app-ingest-detail',
  templateUrl: './ingest-detail.component.html',
  styleUrls: ['./ingest-detail.component.scss'],
})
export class IngestDetailComponent implements OnInit {
  @Input() public ingestion: Ingestion;
  @Input() public isLoading: boolean;
  @Input() public projectName: string;
  @Input() public loggedUser: any;

  @Output() deleteEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private router: Router,
    private rdbmsService: RdbmsIngestionControllerService,
    private streamJobControllerService: StreamJobControllerService
  ) {}

  ngOnInit(): void {}

  public navigateToViewIngestion() {
    let path: string;
    if (this.ingestion.databaseType === 'Stream') {
      path = `project/${this.projectName.trim()}/ingest/StreamIngestionViewDetails/${
        this.ingestion.id
      }`;
    } else {
      path = `project/${this.projectName.trim()}/ingest/databaseViewDetails/${
        this.ingestion.id
      }`;
    }

    this.router.navigateByUrl(path);
  }

  public toEditIngestionForm() {
    let editPage: string;

    switch (this.ingestion.ingestionType) {
      case 'Table':
        {
          editPage = 'editStepOneCustom';
        }
        break;
      case 'Database':
        {
          editPage = 'editStepOneDatabase';
        }
        break;
      case 'Query':
        {
          editPage = 'editStepOneQuery';
        }
        break;
      case 'Stream':
        {
          editPage = 'editStepOneStream';
        }
        break;
    }

    this.router.navigateByUrl(
      `project/${this.projectName}/ingest/${editPage}/${this.ingestion.id}`
    );
  }

  public getActionType() {
    return this.ingestion.status === 'IN_PROGRESS'
      ? 'fa fa-stop'
      : 'fa fa-play';
  }

  public getToolTip() {
    return this.ingestion.status === 'IN_PROGRESS'
      ? 'Stop Ingestion'
      : 'Run Ingestion';
  }

  public goToIngestionAction() {
    if (this.ingestion.status === 'IN_PROGRESS') {
      this.stopIngestion();
    } else {
      this.runIngestion();
    }
  }

  public runIngestion() {
    if (this.ingestion.ingestionType === 'Stream') {
      this.startStreamJob();
    } else {
      this.configureRdbms();
    }
  }

  public stopIngestion() {
    if (this.ingestion.ingestionType === 'Stream') {
      this.stopStreamJob(this.ingestion.id);
    } else {
      this.stopRdbmsJob();
    }
  }

  private startStreamJob() {
    Swal.fire({
      type: 'warning',
      title: 'Are you sure you want to run this Ingestion?',
      // text: 'All information associated to this ingestion will be permanently deleted',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed == true) {
        this.isLoading = true;
        this.streamJobControllerService
          .startStreamJobById(this.ingestion.id)
          .subscribe((response) => {
            this.isLoading = true;
            this.success(
              `Job Submitted Successfully for ${this.ingestion.name}`,
              true
            );
          });
        // this.getAllIngestionsByProjectName()
      }
    });
  }

  private configureRdbms() {
    Swal.fire({
      type: 'warning',
      title: 'Are you sure you want to run this Ingestion?',
      // text: 'All information associated to this ingestion will be permanently deleted',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed == true) {
        this.isLoading = true;
        this.rdbmsService
          .postRdbmsIngestionJob(this.ingestion.id)
          .subscribe((response) => {
            this.isLoading = false;
            this.success(
              `Job Submitted Successfully for ${this.ingestion.name}`,
              true
            );
          });

        // this.getAllIngestionsByProjectName()
      }
    });
  }

  public stopStreamJob(id: string) {
    Swal.fire({
      type: 'warning',
      title: 'Are you sure you want to stop this Ingestion?',
      // text: 'All information associated to this ingestion will be permanently deleted',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed == true) {
        this.streamJobControllerService
          .stopStreamJobById(id)
          .subscribe((response) => {
            this.success(
              `Job Stopped Succesfully for Ingest ${this.ingestion.name}`,
              false
            );
          });
      }
    });
  }

  public stopRdbmsJob() {
    Swal.fire({
      type: 'error',
      title: 'Stopping Ingestion has Failed now',
      text: 'An error has occured while stopping ingestion',
      confirmButtonText: 'Ok',
    });
  }

  isRunIngestionHidden() {
    for (
      var user_auths = 0;
      user_auths < this.loggedUser.authorities.authorities.length;
      user_auths++
    ) {
      if (
        this.loggedUser.authorities.authorities[user_auths].authority ===
          'PERM_MAINTAINER' ||
        this.loggedUser.authorities.authorities[user_auths].authority ===
          'PERM_OWNER' ||
        this.loggedUser.authorities.authorities[user_auths].authority ===
          'PERM_ADMIN' ||
        this.loggedUser.authorities.authorities[user_auths].authority ===
          'PERM_OPERATOR'
      ) {
        return false;
      }
    }
    return true;
  }

  isActionHidden() {
    for (
      var user_auths = 0;
      user_auths < this.loggedUser.authorities.authorities.length;
      user_auths++
    ) {
      if (
        this.loggedUser.authorities.authorities[user_auths].authority ===
          'PERM_MAINTAINER' ||
        this.loggedUser.authorities.authorities[user_auths].authority ===
          'PERM_OWNER' ||
        this.loggedUser.authorities.authorities[user_auths].authority ===
          'PERM_ADMIN'
      ) {
        return false;
      }
    }
    return true;
  }

  identifyDriver(driver: string): string {
    if (driver == 'mysql') {
      return '../../../assets/images/mysqllogo.svg';
    } else if (driver == 'oracle') {
      return '../../../assets/images/Oracle.svg';
    } else if (driver == 'sqlserver') {
      return '../../../assets/images/sqlserver.png';
    } else if (driver == 'db2') {
      return '../../../assets/images/ibmDb2.svg';
    } else if (driver == 'snowflake') {
      return '../../../assets/images/snowflake.svg';
    } else if (driver == 'redshift') {
      return '../../../assets/images/redshift.png';
    } else if (driver == 'postgresql') {
      return '../../../assets/images/postgresql.png';
    } else {
      return '../../../assets/images/kafka.png';
    }
  }

  miliConvert(milliseconds: number) {
    if (milliseconds == undefined) {
      return 'NOT YET RAN';
    }
    {
      var minutes = milliseconds / 1000 / 60;
      var seconds = (milliseconds / 1000) % 60;
      var minutesRounded = Math.round(minutes);
      var secondsRounded = Math.round(seconds);
      var diff = minutesRounded + ' minutes, ' + secondsRounded + ' seconds';
      return diff;
    }
  }

  bulkImport(bulkImport: boolean): string {
    if (bulkImport == true) {
      return 'Database Import ';
    } else {
      return 'Table Import';
    }
  }
  bulkImportIcon(bulkImport: boolean): string {
    if (bulkImport == true) {
      return 'fa fa-database ';
    } else {
      return 'fa fa-table';
    }
  }

  changeStatusColor(driver: string): string {
    if (driver === undefined) {
      return 'text-danger';
    }
    if (driver.toLocaleLowerCase() == 'finished') {
      return 'text-success';
    } else if (
      driver.toLocaleLowerCase() == 'failed' ||
      driver.toLocaleLowerCase() == 'failed_to_launch'
    ) {
      return 'text-danger';
    } else if (driver.toLocaleLowerCase() == 'discarded') {
      return 'text-warning';
    } else if (driver.toLocaleLowerCase() == 'in_progress') {
      return 'text-primary';
    } else if (driver.toLocaleLowerCase() == 'in_progress_with_errors') {
      return 'text-primary';
    } else {
      return 'text-dark';
    }
  }

  returnStatus(driver: string): string {
    if (driver === undefined || driver.toLocaleLowerCase() == 'stopped') {
      return 'STOPPED';
    } else if (driver.toLocaleLowerCase() == 'finished') {
      return driver.toUpperCase();
    } else if (driver.toLocaleLowerCase() == 'failed') {
      return driver.toUpperCase();
    } else if (driver.toLocaleLowerCase() == 'discarded') {
      return driver.toUpperCase();
    } else if (driver.toLocaleLowerCase() == 'in_progress') {
      return driver.toUpperCase();
    } else {
      return 'N/A';
    }
  }

  deleteIngestion(id: string) {
    this.deleteEvent.emit(id);
  }

  success(status: string, route: boolean) {
    Swal.fire({
      type: 'Success',
      title: 'Success!',
      text: status,
      confirmButtonText: 'Ok',
    }).then(() => {
      if (route) {
        this.router.navigate([`/project/${this.projectName}/jobs`]);
      }
    });
  }
}

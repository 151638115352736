
<div *ngIf="load"class="">
  <div class="loader"></div>
  <figure class="spinner-border loaderico"></figure>
</div>
<div class="cm-content-blocks">
  <ol class="breadcrumb">
      <li class="active"><i class="fa fa-home"></i>Home</li>
  </ol>
</div>

<div class="clearafter headbtnc">
  <h3 class="headc">Overview</h3>
</div>

<article class="db_boxec">
  <div  class=" db_boxc" >
      <a class="boxlink" routerLink="/project" routerLinkActive="active">
      <!-- <a routerLink="databaseSelection" class="boxlink"> -->
        <div  class="db_box">
          <div class="cardico">
            <i class="fa fa-folder-open" aria-hidden="true"></i>
          </div>
          <div class="cardcount">{{projectCount}}</div>
          <h4 class="cardtitle">Projects</h4>
        </div>
      </a>
    </div> 
    <div  class=" db_boxc" >
      <a>
        <!--routerLink="/allsources"--> 
        <div  class="db_box">
          <div class="cardico">
              <i class="fa fa-database" aria-hidden="true"></i>
          </div>
          <div class="cardcount">{{dataSourceCount}}</div>
          <h4 class="cardtitle">Data Sources</h4>
        </div>
      </a>
    </div>  
  <div  class=" db_boxc" >
    <a> 
    <!-- <a routerLink="allingests" class="boxlink"> -->
      <div  class="db_box">
        <div class="cardico">
          <i class="fa fa-filter" aria-hidden="true"></i> 
        </div>
        <div class="cardcount">{{ingestionCount}}</div>
        <h4 class="cardtitle">Ingestions</h4>
      </div>
    </a>
  </div>  
  <div  class=" db_boxc" >
      <a> 
      <!-- <a routerLink="alljobs" class="boxlink"> -->
        <div  class="db_box">
          <div class="cardico">
              <i class="fa fa-wrench" aria-hidden="true"></i>            </div>
          <div class="cardcount">{{jobCount}}</div>
          <h4 class="cardtitle">Jobs</h4>
        </div>
      </a>
    </div>  
 
    <div  class=" db_boxc" >
      <a> 
        <div  class="db_box">
          <div class="cardico">
              <i class="fa fa-calendar" aria-hidden="true"></i>            </div>
          <div class="cardcount">{{scheduleCount}}</div>
          <h4 class="cardtitle">Schedules</h4>
        </div>
      </a>
    </div>  
</article>
            


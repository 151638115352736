<div class="cm-content-blocks">
  <ol class="breadcrumb">
    <li><a [routerLink]="['/project', projectService.getCurrentProject()]"><i class="fa fa-home"></i>Home</a></li>
    <li><a routerLink="/project"><i class="fa fa-folder-open"></i>Projects</a></li>
    <li><a routerLink="/project/{{projectName}}"><i class="fa fa-folder"></i>{{projectName}}</a></li>
    <li class="active"><i class="fa fa-filter mr-1"></i>Ingest</li>
  </ol>
</div>


<div class="clearafter headbtnc">
  <h3 class="headc">
    Ingest - {{projectName}}
    <div class="subhead">Select or configure the ingestions for this project</div>
  </h3>
  <div class="create-btn-position headbtnc">
    <button class="mr-btn-create pull-right pointerc" routerLink="select-ingestion" routerLinkActive="active"
      [hidden]="isIngestConfigHidden()">
      Configure Ingestion
    </button>
  </div>
</div>

<section class="clearafter">
  <aside class="zeroplimp float-left col-md-4">
    <div class="searchbarc fifteenpb">
      <input class="search-bar input-field" type="text" placeholder="Search for ingestion..." [(ngModel)]="term">
      <img class="search-bar-icon" src="../../../assets/images/search.svg">
    </div>
  </aside>
  <aside class="col-8 text-right clearafter d-flex justify-content-end">


    <div class="inline-block tenmt ">
      <span class=" fivemr">Sort by: </span>
      <label class="radioc">
        <input type="radio" name="inlineRadioOptions" (click)="sortDescending(ingestions)" id="inlineRadio2"
          value="option2">
        <span class="checkmark-r"></span>
        <span class="text-r">Newest Ingestions</span>
      </label>
      <label class="radioc">
        <input type="radio" name="inlineRadioOptions" (click)="sortAscending(ingestions)" id="inlineRadio1"
          value="option1">
        <span class="checkmark-r"></span>
        <span class="text-r">Oldest Ingestions</span>
      </label>
    </div>
  </aside>
  <!-- <aside class=" d-flex justify-content-end">
        <span class="inline-block fivemr">Sort Ingestions by date: </span>
        <label class="radioc">
          <input type="radio" name="inlineRadioOptions" (click)="sortAscending(ingestions)" id="inlineRadio1" value="option1">
          <span class="checkmark-r"></span>
          <span class="text-r">Oldest</span>
        </label>
        <label class="radioc">
          <input type="radio"  name="inlineRadioOptions" (click)="sortDescending(ingestions)" id="inlineRadio2" value="option2">
          <span class="checkmark-r"></span>
          <span class="text-r">Newest</span>
        </label> 
      </aside> -->
</section>



<section class="table-ec">
  <div class="table-wrapper position-relative loader_top_adj">
    <div *ngIf="load" class="">
      <div class="loader"></div>
      <figure class="spinner-border loaderico"></figure>
    </div>
    <div class="table-wrapper ">
      <table class="table table-borderless mr-table">
        <thead class="mr-table-head">
          <tr class="mr-table-head-row">
            <!-- <th class="idtd"> </th> -->
            <th>Name</th>
            <th>Ingestion Type</th>
            <th>Last Run Status</th>
            <th>Last Run Time</th>
            <th>Date Created</th>
            <th class="actionfourtd">Actions</th>
          </tr>
        </thead>
        <tbody *ngIf="ingestions.length == 0">
          <tr>
            <td colspan=100% class="text-center">No ingestions exist for this project</td>
          </tr>
        </tbody>

        <tbody *ngIf="ingestions.length > 0">
          <tr class="mr-table-body-row" *ngFor="let ingestion of ingestions | filter: term | paginate: { id: 'listing_pagination',
              itemsPerPage: 10,
              currentPage: page,
              totalItems: ingestions.length }; let i = index ">

            <!-- <td class="mr-table-pad-left"></td> -->
            <td>{{ingestion.name}}</td>
            <td>{{ingestion.ingestionType}}</td>
            <td>{{checkStatus(ingestion.status)}}</td>
            <td>{{dateFormatter(checkStatus(ingestion.jobCreatedAt))}}</td>
            <td>{{dateFormatter(ingestion.createdAt)}}</td>
            <td>
              <div class="icons-container">
                <!-- run ingestions -->
                <i *ngIf="ingestion.ingestionType =='Table' || ingestion.ingestionType =='Database' || ingestion.ingestionType =='Query'"
                  (click)="configureRdbms(ingestion.id, i);" ngbTooltip="Run Ingestion" class="fa fa-play"
                  [hidden]="isRunIngestionHidden()"></i>
                <i *ngIf="ingestion.ingestionType =='Stream'" (click)="startStreamJob(ingestion.id);"
                  ngbTooltip="Run Ingestion" class="fa fa-play" [hidden]="isRunIngestionHidden()"></i>
                <!-- edit ingestions -->
                <i *ngIf="ingestion.ingestionType =='Table'"
                  routerLink="/project/{{this.projectName}}/ingest/editStepOneCustom/{{ingestion.id}}"
                  ngbTooltip="Edit Ingestion" class="fa fa-pencil" [hidden]="isActionHidden()"></i>
                <i *ngIf="ingestion.ingestionType =='Database'"
                  routerLink="/project/{{projectName}}/ingest/editStepOneDatabase/{{ingestion.id}}"
                  ngbTooltip="Edit Ingestion" class="fa fa-pencil" [hidden]="isActionHidden()"></i>
                <i *ngIf="ingestion.ingestionType =='Query'"
                  routerLink="/project/{{projectName}}/ingest/editStepOneQuery/{{ingestion.id}}"
                  ngbTooltip="Edit Ingestion" class="fa fa-pencil" [hidden]="isActionHidden()"></i>
                <i *ngIf="ingestion.ingestionType =='Stream'"
                  routerLink="/project/{{projectName}}/ingest/editStepOneStream/{{ingestion.id}}"
                  ngbTooltip="Edit Ingestion" class="fa fa-pencil" [hidden]="isActionHidden()"></i>
                <!-- view ingestion details -->
                <i *ngIf="ingestion.ingestionType =='Table' || ingestion.ingestionType =='Database' || ingestion.ingestionType =='Query'"
                  routerLink="databaseViewDetails/{{ingestion.id}}" ngbTooltip="View Ingestion Details" placement="left"
                  class="fa fa-eye"></i>
                <i *ngIf="ingestion.ingestionType =='Stream'" routerLink="StreamIngestionViewDetails/{{ingestion.id}}"
                  ngbTooltip="View Ingestion Details" placement="left" class="fa fa-eye"></i>
                <!-- delete ingestions -->
                <i *ngIf="ingestion.ingestionType =='Table' || ingestion.ingestionType =='Database' || ingestion.ingestionType =='Query'"
                  (click)="deleteIngestion(ingestion.id, ingestion.name)" ngbTooltip="Delete Ingestion"
                  class="fa fa-trash" [hidden]="isActionHidden()"></i>
                <i *ngIf="ingestion.ingestionType =='Stream'"
                  (click)="deleteStreamIngestion(ingestion.id, ingestion.name)" ngbTooltip="Delete Ingestion"
                  class="fa fa-trash" [hidden]="isActionHidden()"></i>


                <!-- <i *ngIf="ingestion.ingestionType !='Stream'" (click)="configureRdbms(ingestion.id, i);" ngbTooltip="Run Ingestion" class="fa fa-play" [hidden] = "isRunIngestionHidden()"></i>
                    <i *ngIf="ingestion.ingestionType =='Stream'" (click)="startStreamJob(ingestion.id);" ngbTooltip="Run Ingestion" class="fa fa-play" [hidden] = "isRunIngestionHidden()"></i>

                    <i *ngIf="ingestion.ingestionType !='Stream' && (!ingestion.query || ingestion.query == null)" (click)="navigate(ingestion)" ngbTooltip="Edit Ingestion" class="fa fa-pencil" [hidden]="isActionHidden()"></i>
                    <i *ngIf="ingestion.ingestionType !='Stream' && (ingestion.query || ingestion.query != null)" routerLink="/project/{{this.projectName}}/ingest/editStepOneQuery/{{ingestion.id}}" ngbTooltip="Edit Ingestion" class="fa fa-pencil" [hidden]="isActionHidden()"></i>

                    <i *ngIf="ingestion.ingestionType =='Stream'" routerLink="/project/{{projectName}}/ingest/editStepOneStream/{{ingestion.id}}" ngbTooltip="Edit Ingestion" class="fa fa-pencil" [hidden]="isActionHidden()"></i>

                    <i *ngIf="ingestion.ingestionType !='Stream'" routerLink="databaseViewDetails/{{ingestion.id}}" ngbTooltip="View Ingestion Details" placement="left" class="fa fa-eye"></i>
                    <i *ngIf="ingestion.ingestionType =='Stream'" routerLink="StreamIngestionViewDetails/{{ingestion.id}}" ngbTooltip="View Ingestion Details" placement="left" class="fa fa-eye"></i>

                    <i *ngIf="ingestion.ingestionType !='Stream'" (click)="deleteIngestion(ingestion.id, ingestion.name)" ngbTooltip="Delete Ingestion" class="fa fa-trash" [hidden]="isActionHidden()"></i>
                    <i *ngIf="ingestion.ingestionType =='Stream'" (click)="deleteStreamIngestion(ingestion.id, ingestion.name)" ngbTooltip="Delete Ingestion" class="fa fa-trash" [hidden]="isActionHidden()"></i> -->

              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <pagination-controls *ngIf="ingestions.length > 10" id="listing_pagination" class="pagination"
        (directionLinks)="true" (pageChange)="page = $event" (autoHide)="true" (responsive)="true" previousLabel=""
        disabled="true" nextLabel="">
      </pagination-controls>
      <!-- <ngb-pagination *ngIf="ingestions.length > 10" class=" pagination float-right" [collectionSize]="ingestions.length" [(page)]="page" aria-label="Default pagination" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>  -->

    </div>
  </div>
</section>
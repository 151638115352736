import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  NgForm,
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  ReactiveFormsModule,
} from '@angular/forms';
import { DataSourceControllerService } from 'src/app/services/api/data-source/data-source-controller.service';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { CatalogService } from '../../../../../../../../services/api/catalogService/catalog.service';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  copyArrayItem,
} from '@angular/cdk/drag-drop';
import { RdbmsIngestionControllerService } from 'src/app/services/api/rdbms-controller/rdbms-ingestion-controller.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { ProjectService } from 'src/app/services/project/project-service.service';
import { IngestionSharingServiceService } from 'src/app/ingestion-sharing-service.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-edit-step-four-query',
  templateUrl: './edit-step-four-query.component.html',
  styleUrls: ['./edit-step-four-query.component.scss'],
})
export class EditStepFourQueryComponent implements OnInit {
  //snackbar

  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  //datasource

  isSubmitShow: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private dataSourceController: DataSourceControllerService,
    private catalogService: CatalogService,
    public service: RdbmsIngestionControllerService,
    public router: Router,
    private snackBar: MatSnackBar,
    public projectService: ProjectService,
    private activatedRoute: ActivatedRoute,
    private ingestionService: IngestionSharingServiceService
  ) {}

  id = this.activatedRoute.snapshot.params['id'];
  sourceId = this.activatedRoute.snapshot.params['id3'];

  projectName = this.activatedRoute.snapshot.params['id2'];

  stepOneData = this.ingestionService.getStepOneQueryData();
  stepTwoData = this.ingestionService.getStepTwoQueryData();
  stepThreeData = this.ingestionService.getStepThreeQueryData();

  load = true;

  showDiv = {
    stepone: false,
    steptwo: false,
    stepthree: false,
    stepfour: true,
    stepfive: false,

    stepbtnone: true,
    stepbtntwo: false,
    stepbtnthree: false,
    stepbtnfour: false,
    stepbtnfive: false,

    cancelbtn: false,
    nextbtn: true,
    nextbtntwo: false,
    nextbtnthree: false,
    nextbtnfour: false,

    backbtn: false,
    backbtntwo: false,
    backbtnthree: false,
    backbtnfour: false,
    backbtnfive: false,

    submitbtn: false,
  };

  advancedConfiguration = this.formBuilder.group({
    id: [''],
    ingestionType: ['Query'],
    sourceId: [''],
    name: [''],
    description: [''],
    query: [''],
    bulkImport: [''],
    numMappers: ['4', Validators.required],
    splitLimit: ['0', Validators.required],
    fetchSize: ['1000', Validators.required],
    validate: ['false', Validators.required],
    projectName: this.projectName,
    fileType: [''],
    splitBy: [''],

    databases: this.formBuilder.array([]),
  });
  onSubmit(savedRecord: any) {
    this.advancedConfiguration.patchValue({
      id: this.id,
      sourceId: this.sourceId,
      name: this.stepOneData.name,
      description: this.stepOneData.description,
      query: this.stepTwoData.query,
      bulkImport: false,
      numMappers: savedRecord.value.numMappers,
      splitLimit: savedRecord.value.splitLimit,
      fetchSize: savedRecord.value.fetchSize,
      projectName: this.projectName,
      fileType: this.stepThreeData.fileType,
      splitBy: this.stepTwoData.splitBy,

      // compress: savedRecord.value.compress,
      validate: savedRecord.value.validate,
    });

    if (this.stepThreeData.ingestionMode != 'full load') {
      this.advancedConfiguration.setControl(
        'databases',
        this.formBuilder.array([
          {
            sourceDatabaseName: this.stepThreeData.sourceDatabaseName,
            destinationDatabaseName: this.stepThreeData.databaseName,
            tables: [
              {
                destinationTableName: this.stepThreeData.tableName,
                incremental: this.stepThreeData.ingestionMode,
                columns: [
                  {
                    destinationColumnName: this.stepThreeData.checkColumn,
                    checkColumn: true,
                  },
                ],
              },
            ],
          },
        ])
      );
    } else {
      this.advancedConfiguration.setControl(
        'databases',
        this.formBuilder.array([
          {
            sourceDatabaseName: this.stepThreeData.sourceDatabaseName,
            destinationDatabaseName: this.stepThreeData.databaseName,
            tables: [
              {
                destinationTableName: this.stepThreeData.tableName,
                incremental: 'full load',
              },
            ],
          },
        ])
      );
    }
    console.log(this.stepTwoData);
    console.log(this.advancedConfiguration);

    this.service
      .updateRdbmsIngestion(this.advancedConfiguration.value)
      .subscribe((response) => {
        let data: any;
        data = response;
        data.id;

        this.router.navigateByUrl('project/' + this.projectName + '/ingest');
        localStorage.removeItem('stepOneQuery');
        localStorage.removeItem('stepTwoQuery');
        localStorage.removeItem('querySQLStatement');
      });

    console.log(this.advancedConfiguration.value);

    this.success('Query ingestion successfully updated');
  }

  success(status: string) {
    Swal.fire({
      type: 'Success',
      title: 'Success!',
      text: status,
      confirmButtonText: 'Ok',
    });
  }

  ngOnInit(): void {
    this.service.getSingleRdbmsIngestion(this.id).subscribe((response) => {
      let data: any = response;
      console.log(data);
      this.advancedConfiguration.patchValue({
        numMappers: data.numMappers,
        splitLimit: data.splitLimit,
        fetchSize: data.fetchSize,
        validate: data.validate,
      });
    });
    console.log('STEP TWO');
    console.log(this.stepThreeData);
    setTimeout(() => (this.load = false), 500);
  }
}

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { IngestionSharingServiceService } from '../app/ingestion-sharing-service.service'
declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, AfterViewInit {
  constructor(private IngestionSharing: IngestionSharingServiceService) {

  }

  title = 'new-ingession';

  isSidenav: boolean = false;
  refreshStatus: boolean

  ngOnInit() {
    this.isSidenav = true;


    $('body').addClass('df');

  }



  ngAfterViewInit() {
    $(document).on("click", ".closeprofile", function () {
      $(".profilec").toggleClass("profileshow");
    });

    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);
    });
  }

}

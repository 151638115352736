<!-- <div *ngIf="load"class="">
    <div class="loader"></div>
    <figure class="spinner-border loaderico"></figure>
  </div> -->
  <div class="cm-content-blocks">
    <ol class="breadcrumb">
        <li><a [routerLink]="['/project', projectService.getCurrentProject()]"><i class="fa fa-home"></i>Home</a></li>
        <li><a routerLink="/project"><i class="fa fa-folder-open"></i>Projects</a></li>
        <li><a routerLink="/project/{{projectName}}"><i class="fa fa-folder"></i>{{projectName}}</a></li>
        <li ><a routerLink="/project/{{projectName}}/job-schedule-landing-page"><i class="fa fa-calendar mr-1"></i>Scheduler</a></li>  
		<li class="active"><i class="fa fa-plus mr-1"></i>Create Schedule</li>  

    </ol>
  </div>

  <div class="clearafter headbtnc">
    <h3 class="headc">
        Create Schedule
        <!-- <div class="subhead">Schedule the jobs for this project</div> -->
    </h3>
  </div>

  <div class="menublock">
    <form [formGroup]="schedulerForm" (ngSubmit)="submitScheduler(schedulerForm)" >
		<section class="inputc">
			<div class="col-md-6 fieldc"  [ngClass]="schedulerForm.controls['name'].hasError('required')? '':''">
				<label class="inputlabel"> Schedule Name </label> 
                <input [disabled]="editPage" formControlName="name"
					name="name"  class="input-field" type="text" 
					placeholder="You can use letters, numbers, and underscore characters '_'" />
					<small style="color:#a94442" *ngIf="schedulerForm.controls['name'].hasError('required') && schedulerForm.controls['name'].touched ">
						Schedule Name is required
					</small>
					
			</div>
			<div class="col-md-6 fieldc">

					<label class="inputlabel clearafter"> 
						<ng-template #popContent>
							<div>
							<span class="displayblock fivepb">
								Make sure your cron expression should have 7 fields. Fields are seperated by space(" "). 
							</span>
								Example : Cron Expression for Every Second is * * * * * ? *
							</div>
							<div class="tenpt">
								<img src="../../../../../../assets/images/Cron_Expr_Info.png" class="widthhundred">
							</div>
						</ng-template>
						<ng-template #popTitle>Cron Expression Info</ng-template>
						<span class="displayblock fl fivemr">Cron Expression </span>
						<span class="iconbtn-round primary-s-bg qubesbg fl fivemr pointerc" placement="bottom" [ngbPopover]="popContent" [popoverTitle]="popTitle"> 
							<i class="fa fa-info" aria-hidden="true"> </i> 
						</span>
					</label>
					

					<!-- [ngClass]="{'inputc-error': schedulerForm.controls['cronExpression'].errors || schedulerForm.controls['cronExpression'].hasError('cronPattern')  && (schedulerForm.controls['cronExpression'].dirty || schedulerForm.controls['cronExpression'].touched)}" -->
				<div class="inputc-withicon" [ngClass]="schedulerForm.controls['cronExpression'].hasError('required') ||
				schedulerForm.controls['cronExpression'].hasError('hasCronPattern') || schedulerForm.controls['cronExpression'].hasError('minlength')? '':''"
				
				>
					<input name="cronString" class="input-field" formControlName="cronExpression"
					name="cronExpression"
						type="text" placeholder="Cron String... " required />
					<span class="withicon pointerc topzero">
						<button type="button" class="nextbtn-input"
							(click)="checkCronExpression=true;checkCronScheduleTimes(schedulerForm.value.cronExpression);"
							>
							<i class="fa fa-angle-right"></i>
						</button>
					</span>
					<small  style="color:#a94442" *ngIf="errorCron">
						Cron Expression is invalid
					</small>
						<small style="color:#a94442" *ngIf="schedulerForm.controls['cronExpression'].touched && schedulerForm.controls['cronExpression'].hasError('required') && !schedulerForm.controls['cronExpression'].hasError('hasCronPattern') ">
							Cron expression is required.
						</small>
					</div>
				<div class="thirtypt">
					<span class="displayblock desc-text">
						<span class="bold">Current Server Time : </span> {{todayDate}}
					</span>


					<!-- <span class="text-warning" *ngIf="schedule_form.cron_input.$error.required  && schedule_form.cron_input.$dirty">&nbsp;
							The Cron Expression is required<br /></span>
						<span class="text-warning" *ngIf="schedule_form.cron_input.$dirty && !schedule_form.cron_input.$error.required && errorCron">&nbsp;
							The Cron Expression is invalid <br /></span>-->
					<span class="desc-text tenpt displayblock" *ngIf="generateScheduledTimes">
						<span class="bold">Next 5 Schedule Times : </span>
						<span class="displayblock fivept" *ngFor="let scheduledTime of generateScheduledTimes">
							 {{scheduledTime}}
							<span class="displayblock fivept" > </span>
						</span>
					</span>

					<span></span>
				</div>
			</div>
		</section>
		
		<section class="inputc" >
			<div class="col-md-6 fieldc">
				<section class="inputc justify-content-md-center mt-4" >
                    
					
					  <!-- <mat-form-field appearance="fill">
                        <mat-label>Choose an option...</mat-label>
                        <mat-select >
                        <div *ngFor="let ingestion of ingestions">
                        <mat-option *ngIf="ingestion.bulkImport == false;" class="mr-4"  [value]="ingestion" (click)="IngestionSelect(ingestion)">
                        
                            {{ingestion.name}}                                
                            </mat-option>    
                        </div>
                    
                        </mat-select>  
						<small class="help-block error-mge zeroleftimp zeroleftimp" *ngIf="schedulerForm.controls['ingestionName'].hasError('required')">
							Ingestion is required
						</small>
                    </mat-form-field>   -->


				


					
						<mat-form-field appearance="fill"> 
						  
						  <input type="text"
								 placeholder="Choose an option..."
								 aria-label="Number"
								 matInput
								 formControlName="ingestionName"
						
								 [matAutocomplete]="auto"
								 >
						  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
							<mat-option *ngFor="let option of filteredOptions | async" [value]="option" (click)="IngestionSelect(option)">
							  {{option}}
							  
							</mat-option>
						  </mat-autocomplete>
						  <small class="help-block error-mge zeroleftimp zeroleftimp" *ngIf="schedulerForm.controls['ingestionName'].hasError('required')">
							Ingestion is required
						</small>
						</mat-form-field> 
					  
					  
					
				<!-- <ng-select >
			  </ng-select> -->
              </section>
				<!-- <ng-select >
			  </ng-select> -->
			</div>
		</section>
		<section class="inputc">
			<!-- <div class="col-md-6 fieldc mt-4">
                <section class="inputc justify-content-md-center" >
                    <mat-form-field appearance="fill">
                    <mat-label>Select Ingestion Type...</mat-label>
                    <mat-select >
                    <mat-option class="mr-4" (click)="setIngestionType(true)" [value]="true">
                        Database Import                             
                    </mat-option>
                    <mat-option class="mr-4"(click)="setIngestionType(false)" [value]="false">
                        Table Import                             
                    </mat-option>
                    </mat-select>  
                </mat-form-field>
              </section>
			</div> -->
			<div class="col-md-6 fieldc">
				<label class="inputlabel">Enable</label>
			
				<label class="switch">
					<input formControlname="enabled" type="checkbox"   (click)="buttonStatus()">
					<span class="slider round"></span>
                    {{this.schedulerForm.value.enabled}}
				</label>	
                <!-- <label class="radioc">
						<input type="radio" name="status" [value]="true" ngChecked="true"/>
						<span class="checkmark-r"></span>
						<span class="text-r">Enabled</span>
					</label> -->
					<!-- <label class="radioc">
						<input type="radio" name="status" [value]="false" />
						<span class="checkmark-r"></span>
						<span class="text-r">Disabled</span>
					</label> -->
			</div>
		</section>


		<div class="clearafter">
			<div class="pull-right clearafter">
					<button class="grey-btn" type="button" (click)="cancel()">Cancel</button>
				<button class="normal-btn" type="submit" [disabled]="schedulerForm.invalid || errorCron">Save</button>
			</div>
		</div>

	</form>
    </div>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StreamingdataSourceService } from 'src/app/services/api/data-source/streamingdata-source.service';
import { StreamIngestionControllerService } from 'src/app/services/api/streamController/stream-ingestion-controller.service';
import { StreamJobControllerService } from 'src/app/services/api/streamJob/stream-job-controller.service';
import { LogService } from '../../../../../../services/log/log.service';


@Component({
  selector: 'app-stream-ingestion-view-details',
  templateUrl: './stream-ingestion-view-details.component.html',
  styleUrls: ['./stream-ingestion-view-details.component.scss']
})
export class StreamIngestionViewDetailsComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute,
    private streamIngestionControllerService: StreamIngestionControllerService,
    private streamJobControllerService: StreamJobControllerService,
    private streamingdataSourceService: StreamingdataSourceService,
    public logService: LogService,
  ) { }
  load: boolean;
  projectName = this.activatedRoute.snapshot.params['id2'];
  id = this.activatedRoute.snapshot.params['id'];
  singleIngestion: any;
  streamJob: any;
  dataSource: any;
  logReport: any;
  
  getSingleIngestion() {
    this.load = true;
    this.streamIngestionControllerService.getSingleStreamIngestion(this.id).subscribe(response => {
      this.singleIngestion = response;
      console.log(this.singleIngestion);
      this.streamingdataSourceService.getSingleStreamDataSource(this.singleIngestion.streamConnectorId).subscribe(response => {
        this.dataSource = response;
      })
      this.streamJobControllerService.getAllStreamJobsByProject(this.projectName).subscribe(response => {
        let jobs: any;
        jobs = response;

        let singleJob: any;
        singleJob = jobs.filter(job => this.singleIngestion.id == job.streamIngestionId);
        this.streamJob = singleJob[0];
      })
      this.load = false;
    })
  }
  refresh() {
    this.getSingleIngestion();
  }
  getDataSource() {


  }
  getSingleJob() {

  }

  miliConvert(milliseconds: number) {

    var minutes = (milliseconds / 1000) / 60;
    var seconds = (milliseconds / 1000) % 60;
    var minutesRounded = Math.round(minutes)
    var secondsRounded = Math.round(seconds)
    var diff = (minutesRounded + " minutes, " + secondsRounded + " seconds")
    return diff
  }

  checkforNull(value: string): string {
    if (value == null) {
      return "N/A"
    } else {
      return value
    }
  }

  getStreamJobId(id: string, data: any) {
    this.streamJobControllerService.getStreamJobLog(id).subscribe(response => {
    })
  }

  getLogDataById(id: string) {
    this.logService.getLogById(id).subscribe(logRes => {
      this.logReport = logRes
      console.log(this.logReport)
    })
  }

  ngOnInit(): void {
    this.getSingleIngestion();
    this.getSingleJob();
  }

}

import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';
import { RdbmsIngestionControllerService } from 'src/app/services/api/rdbms-controller/rdbms-ingestion-controller.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IngestionSharingServiceService } from 'src/app/ingestion-sharing-service.service';
import { ProjectService } from 'src/app/services/project/project-service.service';
import { StreamingdataSourceService } from 'src/app/services/api/data-source/streamingdata-source.service';
import { forkJoin } from 'rxjs';
import { StreamIngestionControllerService } from 'src/app/services/api/streamController/stream-ingestion-controller.service';

@Component({
  selector: 'app-step-one-stream-ingestion',
  templateUrl: './step-one-stream-ingestion.component.html',
  styleUrls: ['./step-one-stream-ingestion.component.scss'],
})
export class StepOneStreamIngestionComponent implements OnInit {
  singleDataSource: any;
  // treeControl = new NestedTreeControl(node => node.children);

  load = true;

  dataSources: any;
  dataSourceData: any;

  id: string;
  status = false;
  buttonEnable: boolean = false;

  isSubmitShow: boolean = false;

  stepOneStreamForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public service: RdbmsIngestionControllerService,
    private streamIngestService: StreamIngestionControllerService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public IngestionService: IngestionSharingServiceService,
    public projectService: ProjectService,
    private streamingDataSourceService: StreamingdataSourceService
  ) {}

  projectName = this.activatedRoute.snapshot.params['id2'];

  showDiv = {
    stepone: true,
    steptwo: false,
    stepthree: false,
    stepfour: false,
    stepfive: false,

    stepbtnone: true,
    stepbtntwo: false,
    stepbtnthree: false,
    stepbtnfour: false,
    stepbtnfive: false,

    cancelbtn: false,
    nextbtn: true,
    nextbtntwo: false,
    nextbtnthree: false,
    nextbtnfour: false,

    backbtn: false,
    backbtntwo: false,
    backbtnthree: false,
    backbtnfour: false,
    backbtnfive: false,

    submitbtn: false,
  };

  ngOnInit(): void {
    this.stepOneStreamForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      streamConnectorId: new FormControl('', [Validators.required]),
      description: new FormControl(''),
    });

    if (this.IngestionService.getStepOneStreamData() != null) {
      this.stepOneStreamForm.patchValue({
        streamConnectorId:
          this.IngestionService.getStepOneStreamData().streamConnectorId,
        name: this.IngestionService.getStepOneStreamData().name,
        description: this.IngestionService.getStepOneStreamData().description,
      });

      // this.getSingleDataSource(this.IngestionService.getStepOneStreamData().streamConnectorId)
    }

    forkJoin([
      this.service.getAllIngestionsByProjectName(this.projectName),
      this.streamIngestService.getAllStreamIngestionsByProjectName(
        this.projectName
      ),
    ]).subscribe(([rdbmsIngestion, streamIngestion]) => {
      let names = [...rdbmsIngestion, ...streamIngestion].map(
        (item) => item.name
      );

      this.cf.name.setValidators([
        Validators.required,
        this.forbiddenNameValidator(names),
      ]);

      this.load = false;
    });

    this.getAllDataSourcesByProjectName();
  }

  savedDatasourceDetails = this.formBuilder.group({
    name: [''],
  });

  get cf() {
    return this.stepOneStreamForm.controls;
  }

  identifyDriver(driver: string): string {
    if (driver == 'mysql') {
      return '../../../../assets/images/mysqllogo.svg';
    } else if (driver == 'oracle') {
      return '../../../../assets/images/Oracle.svg';
    } else if (driver == 'sqlserver') {
      return '../../../../assets/images/sqlserver.png';
    } else if (driver == 'db2') {
      return 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/51/IBM_logo.svg/1000px-IBM_logo.svg.png';
    } else if (driver == 'snowflake') {
      return '../../../../assets/images/snowflake.svg';
    } else if (driver == 'redshift') {
      return 'https://cdn.worldvectorlogo.com/logos/aws-rds.svg';
    } else if (driver == 'postgresql') {
      return '../../../../assets/images/postgresql.png';
    } else {
      return '../../';
    }
  }

  submitForm(fields: any): any {
    this.stepOneStreamForm.patchValue({
      streamConnectorId: fields.value.streamConnectorId,
      name: fields.value.name,
      description: fields.value.description,
    });

    console.log(this.stepOneStreamForm.value);
    this.IngestionService.setStepOneStreamData(this.stepOneStreamForm.value);
    this.id = this.stepOneStreamForm.value.streamConnectorId;
    this.router.navigate([
      `/project/${this.projectName}/ingest/select-ingestion/stepTwoStream/${this.id}`,
    ]);
  }

  carryData() {
    if (this.IngestionService.getStepOneStreamData() == null) {
      this.router.navigate([
        `/project/${this.projectName}/ingest//select-ingestion/stepTwoStream/${this.id}`,
      ]);
    }
    if (this.IngestionService.getStepOneStreamData() !== null) {
      this.router.navigate([
        `/project/${this.projectName}/ingest/select-ingestion/stepTwoStream/${
          this.IngestionService.getStepOneStreamData().streamConnectorId
        }`,
      ]);
    }
  }

  //API METHODS

  getAllDataSourcesByProjectName() {
    this.streamingDataSourceService
      .getAllStreamDataSourcesByProjectName(
        this.projectService.getCurrentProject()
      )
      .subscribe((res) => {
        console.log('res', res);
        this.dataSources = res;
      });
  }

  setDataSource(id: string) {
    this.stepOneStreamForm.patchValue({
      streamConnectorId: id,
    });
    console.log(this.stepOneStreamForm.value);
  }

  // getSingleDataSource(id: string) {
  //   this.dataSourceController.getSingleRDBMSConnector(id).subscribe(res => {
  //     this.singleDataSource = res;
  //     this.savedDatasourceDetails.setValue({
  //       name: this.singleDataSource.name
  //     })

  //   })
  // }
  forbiddenNameValidator(names: Array<string>): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (names) {
        const forbidden = names.some(
          (name) => name.trim().toLowerCase() == value.trim().toLowerCase()
        );

        return forbidden ? { DuplicateName: { value: control.value } } : null;
      } else {
        return null;
      }
    };
  }
}

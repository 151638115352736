import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from 'src/app/services/project/project-service.service';
declare var $: any;

@Component({
  selector: 'app-configure-data-sources',
  templateUrl: './configure-data-sources.component.html',
  styleUrls: ['./configure-data-sources.component.scss']
})
export class ConfigureDataSourcesComponent implements OnInit, AfterViewInit {
  constructor(public projectService: ProjectService, private activatedRoute: ActivatedRoute) {
  }

  term: string;
  projectName = this.activatedRoute.snapshot.params['id2'];

  ngAfterViewInit() {
    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);
    });
  }

  items = [
    {
      title: "Oracle",
      imageUrl: "../../../assets/images/Oracle.svg",
      link: "/project/" + this.projectName + "/data-sources/oracle/oracle"
    },
    {
      title: "MySQL",
      imageUrl: "../../../assets/images/mySQL.svg",
      link: "/project/" + this.projectName + "/data-sources/mysql"
    },
    {
      title: "PostgreSQL",
      imageUrl: "../../../assets/images/postgresql.png",
      link: "/project/" + this.projectName + "/data-sources/postgresql"
    },
    {
      title: "Microsoft SQL Server",
      imageUrl: "../../../assets/images/microsoftSQLServer.svg",
      link: "/project/" + this.projectName + "/data-sources/sqlserver"
    },
    {
      title: "IBM DB2",
      imageUrl: "../../../assets/images/ibmDb2.png",
      link: "/project/" + this.projectName + "/data-sources/ibm/db2"
    },
    {
      title: "MemSQL/SingleStore",
      imageUrl: "../../../assets/images/memsql.svg",
      link: "/project/" + this.projectName + "/data-sources/memsql"
    },
    {
      title: "Snowflake",
      imageUrl: "../../../assets/images/snowflake.svg",
      link: "/project/" + this.projectName + "/data-sources/snowflake"
    },
    {
      title: "Amazon Redshift",
      imageUrl: "../../../assets/images/redshift.png",
      link: "/project/" + this.projectName + "/data-sources/redshift"
    },
    {
      title: "Synapse",
      imageUrl: "../../../assets/images/synapse.png",
      link: "/project/" + this.projectName + "/data-sources/synapse"
    },
    {
      title: "Data Explorer",
      imageUrl: "../../../assets/images/explorer-icon-t.svg",
      link: "/project/" + this.projectName + "/data-sources/explorer"
    }

  ]
  sortAscending() {
    this.items.sort((a, b) => (a.title > b.title) ? 1 : -1)
    console.log(this.items)
  }
  sortDescending() {
    this.items.sort((a, b) => (a.title < b.title) ? 1 : -1)
    console.log(this.items)
  }

  sortOnInit(data: any) {
    data.sort((a, b) => (a.title > b.title) ? 1 : -1)
  }

  ngOnInit(): void {
    this.sortOnInit(this.items)



  }

}

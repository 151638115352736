<div *ngIf="load" class="">
  <div class="loader"></div>
  <figure class="spinner-border loaderico"></figure>
</div>
<div class="cm-content-blocks">
  <ol class="breadcrumb">
    <li><a routerLink="/home"><i class="fa fa-home"></i>Home</a></li>
    <li><a routerLink="/project"><i class="fa fa-folder-open"></i>Projects</a></li>
    <li><a routerLink="/project/{{projectName}}"><i class="fa fa-folder"></i>{{projectName}}</a></li>
    <li><a [routerLink]="['/project', projectService.getCurrentProject(),'ingest']"><i
          class="fa fa-filter"></i>Ingest</a></li>
    <li><a [routerLink]="['/project', projectService.getCurrentProject(),'ingest', 'select-ingestion']"><i
          class="fa fa-sitemap mr-1"></i>Configure Ingestion</a></li>
    <li class="active"><i class="fa fa-filter"></i>Query Ingestion</li>

  </ol>
</div>

<div class="clearafter headbtnc">
  <h3 class="headc">
    Query Ingestion
    <div class="subhead">Configure Query Ingestion</div>
  </h3>
  <div class="create-btn-position headbtnc">
    <button class="mr-btn-back pull-right pointerc"
      [routerLink]="['/project', projectService.getCurrentProject(),'ingest']">Back to Ingest</button>
  </div>
</div>

<div class="menublock">
  <!-- steps -->

  <div class="progresslic clearafter ">
    <div class="fifteen_pb">
      <ul class="progressbar clearfix">

        <li class="tabli active pointerc" [ngClass]="showDiv.stepone === true ? 'active':'disabled' ">
          <section class="tabbtn">
            <section class="clearfix relativepos">
              <aside class="absoultepos stipico">
                <i class="fa fa-info" aria-hidden="true"></i>
              </aside>
              <aside class="steptxtc">
                <div class="steptitle">Data Source</div>
                <div class="stepdes mt-1">Select data source for ingestion</div>
              </aside>
            </section>
          </section>
        </li>

        <li class="tabli pointerc" [ngClass]="showDiv.steptwo === true ?'active':'disabled' ">
          <section class="tabbtn">
            <section class="clearafter relativepos">
              <aside class="absoultepos stipico">
                <i class="fa fa-database" aria-hidden="true"></i>
              </aside>
              <aside class="steptxtc">
                <div class="steptitle">Query</div>
                <div class="stepdes">Enter SQL query</div>
              </aside>
            </section>
          </section>
        </li>
        <li class="tabli pointerc" [ngClass]="showDiv.stepthree === true ?'active':'disabled' ">
          <section class="tabbtn">
            <section class="clearfix relativepos">
              <aside class="absoultepos stipico">
                <i class="fa fa-cogs" aria-hidden="true"></i>
              </aside>
              <aside class="steptxtc">
                <div class="steptitle">Destination Configuration</div>
                <div class="stepdes">Choose Destination Location and Settings</div>
              </aside>
            </section>
          </section>
        </li>
        <li class="tabli pointerc" [ngClass]="showDiv.stepfour === true ?'active':'disabled' ">
          <section class="tabbtn">
            <section class="clearfix relativepos">
              <aside class="absoultepos stipico">
                <i class="fa fa-cogs" aria-hidden="true"></i>
              </aside>
              <aside class="steptxtc">
                <div class="steptitle">Advanced Configuration</div>
                <div class="stepdes">Configure ingestion settings</div>
              </aside>
            </section>
          </section>
        </li>
      </ul>
    </div>
    <section class="tabc_config">

      <form [formGroup]="stepTwoForm" (ngSubmit)="submitForm(stepTwoForm)">
        <div class="steptwo_c" *ngIf="showDiv.steptwo">
          <div class="fifteenmb">
            <section class="clearafter">
              <aside class="fourtynine_p float-left"
                [ngClass]="stepTwoForm.controls['query'].touched && stepTwoForm.controls['query'].errors?'inputc-error':''">
                <ngx-spinner bdColor="rgba(0,0,0,0.8)" size="medium" color="#fff" type="ball-pulse"
                  [fullScreen]="false">
                  <p style="color: white"> Loading... </p>
                </ngx-spinner>
                <div class="subtitle">Enter SQL Query</div>

                <div ace-editor [(text)]="query" [mode]="'sql'" (textChanged)="onChange($event)" [theme]="'eclipse'"
                  [readOnly]="false" [autoUpdateContent]="true" [durationBeforeCallback]="1000" class="graybdr"
                  id="json_editor" style="min-height: 180px; width:100%; overflow: auto;">
                </div>
                <div class="clearafter tenmt">
                  <button class="grey-btn float-right" type="button" (click)="testQuery(query)">Test Query</button>
                  <small style="color: #a94442;" *ngIf="queryValidation == false">
                    '$CONDITIONS' is required in the where clause
                  </small>
                </div>
              </aside>

              <aside class="fourtynine_p float-right">
                <section class="inputc" *ngIf="columnOptions != undefined">
                  <aside class="col-md-12 fieldc"
                    [ngClass]="stepTwoForm.controls['splitBy'].touched && stepTwoForm.controls['splitBy'].errors?'inputc-error':''">
                    <label class="inputlabel clearafter">
                      <span class="displayblock fl fivemr">Split By </span>
                      <span class="text-danger">*</span>
                    </label>
                    <mat-form-field appearance="fill">
                      <mat-label>Choose Split By After Entering Query</mat-label>
                      <mat-select formControlName="splitBy">
                        <mat-option *ngFor="let column of columnOptions" [value]="column.destinationColumnName">
                          {{column.destinationColumnName+' ('+column.sourceDataType+')'}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <span class="error-mge mt-2"
                      *ngIf="stepTwoForm.controls['splitBy'].invalid && (stepTwoForm.controls['splitBy'].dirty || stepTwoForm.controls['splitBy'].touched)">
                      Split by is required.
                    </span>
                  </aside>
                </section>
                <span class="example-list-section">
                </span>
              </aside>
            </section>
          </div>
        </div>
        <footer class="tenpt lightgraybdrt clearafter footerbtns">

          <button class="tertiary-active fr btnnext" id="btnnext" type="submit" [disabled]="stepTwoForm.invalid">
            Next
            <i aria-hidden="true" class="fa fa-arrow-right"></i>
          </button>
          <button class="grey-btn fr btnpre" (click)="saveConfiguration(stepTwoForm)"
            routerLink="/project/{{projectName}}/ingest/select-ingestion/stepOneQuery">
            <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
          </button>

          <!-- <button class="tertiary-active fr btnnext" id="btnnext" *ngIf="showDiv.nextbtnfour"
                (click)="showDiv.stepone = false; 
                showDiv.steptwo =false; 
                showDiv.stepthree = false;
                showDiv.stepfour= false;
                showDiv.stepfive = true;
  
                showDiv.backbtn = false; 
                showDiv.backbtntwo = false;
                showDiv.backbtnthree = false; 
                showDiv.backbtnfour = true; 
                showDiv.backbtnfive = false;
  
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false; 
                showDiv.nextbtntwo = false; 
                showDiv.nextbtnthree = false; 
                showDiv.nextbtnfour = false;
  
                showDiv.cancelbtn = false;">
                Next <i aria-hidden="true" class="fa fa-arrow-right"></i>
                </button>
                
     -->
          <button class="grey-btn fr btnpre" *ngIf="showDiv.cancelbtn">
            Cancel
          </button>

          <button class="tertiary-active fr btnnext" *ngIf="showDiv.submitbtn">
            Submit
          </button>

          <button class="grey-btn fr btnpre" *ngIf="showDiv.backbtn" (click)="showDiv.stepone = true; 
                showDiv.steptwo = false; 
                showDiv.stepthree = false;
                showDiv.stepfour = false;
                showDiv.stepfive = false;
  
                showDiv.backbtn = false; 
                showDiv.backbtntwo = false;
                showDiv.backbtnthree = false; 
                showDiv.backbtnfour= false;
                showDiv.backbtnfive = false;
  
                showDiv.submitbtn = false; 
                showDiv.nextbtn = true; 
                showDiv.nextbtntwo = false; 
                showDiv.nextbtnthree = false; 
                showDiv.nextbtnfour = false; 
  
                showDiv.cancelbtn = false;">
            <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
          </button>
          <button class="grey-btn fr btnpre" *ngIf="showDiv.backbtntwo" (click)="showDiv.stepone = false; 
                showDiv.steptwo = true; 
                showDiv.stepthree = false;
                showDiv.stepfour = false;
                showDiv.stepfive = false;
  
                showDiv.backbtn = true; 
                showDiv.backbtntwo = false;
                showDiv.backbtnthree = false;
                showDiv.backbtnfour = false;
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false; 
                showDiv.nextbtntwo = true; 
                showDiv.nextbtnthree = false; 
                showDiv.nextbtnfour = false; 
  
                showDiv.cancelbtn = false;">
            <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
          </button>
          <button class="grey-btn fr btnpre" *ngIf="showDiv.backbtnthree" (click)="showDiv.stepone = false; 
                showDiv.steptwo = false; 
                showDiv.stepthree = true;
                showDiv.stepfour = false;
                showDiv.stepfive = false;
  
                showDiv.backbtn = false; 
                showDiv.backbtntwo = true;
                showDiv.backbtnthree = false; 
                showDiv.backbtnfour = false; 
                showDiv.backbtnfive = false; 
  
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false; 
                showDiv.nextbtntwo = false; 
                showDiv.nextbtnthree = true; 
                showDiv.nextbtnfour = false; 
                showDiv.cancelbtn = false;">
            <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
          </button>
          <!-- <button class="grey-btn fr btnpre"  *ngIf="showDiv.backbtnfour"
                (click)="showDiv.stepone = false; 
                showDiv.steptwo = false; 
                showDiv.stepthree = false;
                showDiv.stepfour = true;
                showDiv.stepfive = false;
  
                showDiv.backbtn = false; 
                showDiv.backbtntwo = false;
                showDiv.backbtnthree = true; 
                showDiv.backbtnfour = false; 
                showDiv.backbtnfive = false; 
  
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false; 
                showDiv.nextbtntwo = false; 
                showDiv.nextbtnthree = false; 
                showDiv.nextbtnfour = true; 
                showDiv.cancelbtn = false;">
                    <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
                </button> -->
          <!-- <button class="grey-btn fr btnpre"  *ngIf="showDiv.backbtnfive"
                (click)="showDiv.stepone = false; 
                showDiv.steptwo = false; 
                showDiv.stepthree = false;
                showDiv.stepfour = false;
                showDiv.stepfive = true;
  
                showDiv.backbtn = true; 
                showDiv.backbtntwo = false;
                showDiv.backbtnthree = false; 
                showDiv.backbtnfour = true; 
                showDiv.backbtnfive = false; 
  
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false; 
                showDiv.nextbtntwo = false; 
                showDiv.nextbtnthree = false; 
                showDiv.nextbtnfour = false; 
                showDiv.cancelbtn = false;">
                    <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
                </button>
               -->
        </footer>
      </form>

    </section>

  </div>

</div>
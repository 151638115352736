import { Component, OnInit, AfterViewInit, ChangeDetectorRef, ComponentFactoryResolver } from '@angular/core';
import { CdkDragDrop, copyArrayItem, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { CatalogService } from "../../../../../../../services/api/catalogService/catalog.service"
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { JsonpClientBackend } from '@angular/common/http';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import { RdbmsIngestionControllerService } from '../../../../../../../services/api/rdbms-controller/rdbms-ingestion-controller.service';
import { DataSourceControllerService } from '../../../../../../../services/api/data-source/data-source-controller.service';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { JobControllerService } from '../../../../../../../services/api/job/job-controller.service';
import { LogService } from '../../../../../../../services/log/log.service';
import { SqoopServiceService } from '../../../../../../../services/sqoop/sqoop-service.service';


declare var $: any;

interface DatabaseNode {
  sourceDatabaseName: string;
  destinationDatabaseName: string;
  id?: string;
  dataType?: string;
  primaryKey?: boolean;
  tables?: TableNode[];
}

interface TableNode {
  sourceTableName: string;
  destinationTableName: string;
  id?: string;
  dataType?: string;
  incremental: string,
  primaryKey?: boolean;
  columns?: columnNode[];
}

interface columnNode {
  sourceColumnName: string;
  destinationColumnName: string;
  id?: string;
  destinationDataType: string;
  dataType?: string;
  sourceDataType?: string;
  primaryKey?: boolean;
}


interface toggle {
  name: string;
}
@Component({
  selector: 'app-database-view-details',
  templateUrl: './database-view-details.component.html',
  styleUrls: ['./database-view-details.component.scss']
})
export class DatabaseViewDetailsComponent implements OnInit {

  panelOpenState = true;
  ingestion: any = []
  connections: any = []
  routeArray: any = []
  showIcon: boolean;
  isBeingEdited: boolean;

  databaseArray: any = [];
  result: any = [];
  latestJob: any;

  logReport: any = [];
  singleSqoop: any = [];
  sqoopCommand: string
  sqoopJob: any = [];
  rdbmsJob: any = [];
  sqoopList: any=[];
  latestProgress: any;
  logMessage: any = [];
  output: any = [];
  data: any;
  refreshStatus = false;
  load = true;

  page = 1;
  pageSize = 7;

  //drag and drop lists
  public databaseDropList: DatabaseNode[] = [];
  ingestionDropList: DatabaseNode[] = [];

  Id = this.activatedRoute.snapshot.params['id'];
  sqoopJobId: any = this.activatedRoute.snapshot.queryParams.data
  ingestObject: any = [];
  dataSource = new MatTreeNestedDataSource();
  flatData: any;

  constructor(
    private rdbmsService: RdbmsIngestionControllerService,
    public activatedRoute: ActivatedRoute,
    private router: Router,
    private dataSourceControllerService: DataSourceControllerService,
    public catalogService: CatalogService,
    public jobservice: JobControllerService,
    public sqoopService: SqoopServiceService,
    public logService: LogService,

  ) { }

  projectName = this.activatedRoute.snapshot.params['id2'];

  checkLastValue(data) {
    if (data === '0') {
      return '???'
    }
    else {
      return data
    }
  }

  refresh() {
    this.refreshStatus = true
    this.getSqoopJobs();

    setTimeout(() => this.refreshStatus = false, 500)
  }

  getLogDataById(id: string) {
    this.logService.getLogById(id).subscribe(logRes => {
      this.logReport = logRes
      console.log(this.logReport)
    })
  }

  getLogData() {
    this.jobservice.getLatestSingleJob(this.Id).subscribe(jobres => {
      this.rdbmsJob = jobres

      // console.log(this.rdbmsJob)
      // console.log("LogDataJob id is: " + this.rdbmsJob.id)
      if (this.rdbmsJob.id == undefined) {
        // console.log("No Jobs")
      } else {
        this.sqoopService.getSqoopJobsByRDBMSJobId(this.rdbmsJob.id).subscribe(sqoopRes => {

          this.sqoopList = sqoopRes;
          console.log("sqoop")
          console.log(this.sqoopList)
          this.mergeObjects(this.sqoopList)
          this.latestProgress = sqoopRes
          this.mergeObjects(this.latestProgress)




          let output = [];
          for (let i = 0; i < this.sqoopList.length; i++) {
            output.push(this.sqoopList[i].id)


            for (let i = 0; i < this.output.length; i++) {
              this.logService.getLogById(this.output.id).subscribe(LogRes => {
                this.logMessage = LogRes
                console.log("Log Message is: " + this.logMessage)
              })
            }
          }


        })
      }
    })
  }

  logChecker(data: any) {
    if (data == '') {
      return "No Data to Display"
    } else {
      return this.logMessage
    }
  }
  
  getSingleSqoopById(id: string) {
    this.sqoopService.getSingleSqoopJobBySqoopId(id).subscribe(res => {

      this.singleSqoop = res;

      this.sqoopCommand = this.singleSqoop.sqoopCommand.join(" ")

      // console.log("SingleSqoopById is: ")
      // console.log(this.singleSqoop)
    })
  }

  changeStatusColor(value: string): string {
    if (value == "Finished") {
      return "text-success"
    } else if (value == "Failed" || value == "In Progress with Errors") {
      return "text-danger"

    }
    else if (value == "In Progress") {
      return "text-primary"

    }
    else if (value == "Discarded") {
      return "text-warning"

    } else {
      return "text-dark"
    }
  }

  miliConvert(milliseconds: number) {

    var minutes = (milliseconds / 1000) / 60;
    var seconds = (milliseconds / 1000) % 60;
    var minutesRounded = Math.round(minutes)
    var secondsRounded = Math.round(seconds)
    var diff = (minutesRounded + " minutes, " + secondsRounded + " seconds")
    return diff
  }

  passItemIdLog(id: string) {
    // this.currentLogId = id;
    this.getLogDataById(id);
    this.getSingleSqoopById(id);
  }

  duration(createdAt: number, finishedAt: number, stringStatus: string) {
    let currentTime = Date.now()
    if (stringStatus.toLowerCase() == "finished") {
      return finishedAt - createdAt

    } else if (stringStatus.toLowerCase() == "failed" || stringStatus.toLowerCase() == "failed to launch" || stringStatus.toLowerCase() == "stopped"
      || stringStatus.toLowerCase() == "killed"
    ) {
      return finishedAt - createdAt

    } else if (stringStatus.toLowerCase() == "in progress" || stringStatus.toLowerCase() == "pending") {

      return currentTime - createdAt

    } else {
      return currentTime - createdAt

    }
  }

  dateFormatter(date: string): string {
    if (date == '???') {
      return date;
    }
    var y: number = +date;
    let dateformat = new Date(y);
    let dateArray = dateformat.toString().split(" ")
    return dateArray[1] + " " + dateArray[2] + ", " + dateArray[3] + `
  ${dateArray[4]} `

  }
  getSqoopJobs() {

    this.jobservice.getLatestSingleJob(this.Id).subscribe(jobres => {
      this.rdbmsJob = jobres

      if (this.rdbmsJob.id == undefined) {
        // console.log("No Jobs")
      } else {
        this.sqoopService.getSqoopJobsByRDBMSJobId(this.rdbmsJob.id).subscribe(sqoopRes => {
          this.sqoopList = sqoopRes;
          console.log("sqoop")
          console.log(this.sqoopList)
          this.latestProgress = sqoopRes


          for (let i = 0; i < this.sqoopList.length; i++) {
            if (this.sqoopJob.sourceId == this.sqoopList[i].id) {

              const obj = { ...this.sqoopList[i] }

              this.sqoopJob.append(obj)
               console.log("obj is: " + obj)

            }
          }
          // console.log("Sqoop list: " + this.sqoopList)
        })
      }
    })
  }
  mergeSqoopbjects(array: any) {
    let count = 0

    for (let i = 0; i < array.length; i++) {
      for (let j = 1; j < (array.length - i); j++) {
        console.log(array[j - 1].database.destinationDatabaseName, array[j].database.destinationDatabaseName)
        if (array[j - 1].database.destinationDatabaseName == array[j].database.destinationDatabaseName) {
          let newArray: any = array[j - 1]
          newArray.database.tables = array[j - 1].database.tables.concat(array[j].database.tables)
          array.splice(j, 1)


        }
      }
    }

    return array
  }

  capitalize(value: string) {
    if (typeof value !== 'string') return ''
    return value.charAt(0).toUpperCase() + value.slice(1)
  }

  datatypeSelector(bulk: any) {
    if (bulk === false) {
      return 'Table'
    }
    else {
      return 'Database'
    }
  }

  getIngestionById(id: string) {
    this.rdbmsService.getSingleRdbmsIngestion(id).subscribe(res => {
      console.log(res)
      this.ingestObject = res
      //console.log(typeof(res));
      console.log(this.ingestObject.query );
      
      
      this.mergeObjects(this.ingestObject.databases)
      //console.log("merge")
      console.log(this.ingestObject)



    })

  }

  mergeObjects(array: any) {
    let count = 0

    for (let i = 0; i < array.length; i++) {
      for (let j = 1; j < (array.length - i); j++) {

        if (array[j - 1].destinationDatabaseName == array[j].destinationDatabaseName) {
          let newArray: any = array[j - 1]
          newArray.tables = array[j - 1].tables.concat(array[j].tables)
          array.splice(j, 1)


        }
      }
    }

  }

  ingestionConfigurationPopulater(id: string) {
    this.rdbmsService.getSingleRdbmsIngestion(id).subscribe(res => {
      this.ingestObject = res
      this.mergeObjects(this.ingestObject.databases)
    })
  }

  checkIfSqoopIsComplete(data: any): any {

    if (data.status == "Finished") {
      return true
    } else {
      return false
    }

  }
  dateMaker(date: string): string {
    let dateformat = new Date(date);
    let dateArray = dateformat.toString().split(" ")
    return dateArray[1] + " " + dateArray[2] + ", " + dateArray[3] + `
    ${dateArray[4]} `

  }

  getJobStatus(id: string) {
    this.jobservice.getLatestSingleJob(id).subscribe(response => {
      console.log(response)
      this.latestJob = response

    })
  }

  checkforNull(value: string): string {
    if (value == null) {
      return "N/A"
    } else {
      return value
    }
  }

  ngOnInit() {
    this.getIngestionById(this.Id);
    this.ingestionConfigurationPopulater(this.Id)
    this.createIngestionsSqoopJobsObject()
    this.getJobStatus(this.Id)
    this.getSqoopJobs();
    this.getAllConnections();
    setTimeout(() => this.load = false, 500)


    let datemaker = new Date(0)
    datemaker.setUTCSeconds(1616623753369)
  }



  getAllConnections() {
    this.jobservice.getJobs().toPromise()
      .then(response => {
        this.data = response;
        this.data.sort((a: any, b: any) => (a.name < b.name) ? 1 : -1)
      }
      )
  }

  createIngestionsSqoopJobsObject() {

    this.rdbmsService.getSingleRdbmsIngestion(this.Id).subscribe(res => {
      this.ingestion = res;

      this.dataSourceControllerService.getRDBMSConnectors().subscribe(connections => {

        this.connections = connections
        // console.log(this.connections)

        // this.ingestion = res;
        const maxLength = Math.max(this.ingestion.length, this.connections.length);


        // console.log(this.ingestion.id)

        for (let i = 0; i < this.connections.length; i++) {
          if (this.ingestion.sourceId == this.connections[i].id) {
            this.ingestion.databaseType = this.connections[i].databaseType;
            this.ingestion.connectionName = this.connections[i].name;
            this.ingestion.username = this.connections[i].username;
            this.ingestion.port = this.connections[i].port;
            this.ingestion.hostName = this.connections[i].hostName;
            this.ingestion.ingestionType = this.connections[i].ingestionType;





          }

        }

        // console.log(this.ingestion)
      })
    })
  }

  showDiv = {
    stepone: true,
    steptwo: false,
    stepthree: false
  }

}

import { Component, OnInit } from '@angular/core';
import {
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
  MatSnackBar,
} from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { Ingestion } from 'src/app/models/ingestion/ingestion';
import { DataSourceControllerService } from 'src/app/services/api/data-source/data-source-controller.service';
import { RdbmsIngestionControllerService } from 'src/app/services/api/rdbms-controller/rdbms-ingestion-controller.service';
import { StreamIngestionControllerService } from 'src/app/services/api/streamController/stream-ingestion-controller.service';
import { StreamJobControllerService } from 'src/app/services/api/streamJob/stream-job-controller.service';
import { ProjectService } from 'src/app/services/project/project-service.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

declare var $: any;

@Component({
  selector: 'app-dashboard-new',
  templateUrl: './dashboard-new.component.html',
  styleUrls: ['./dashboard-new.component.scss'],
})
export class DashboardNewComponent implements OnInit {
  toggleBulk: boolean;
  ingestions: any[] = [];
  progress: any[] = [];
  connections: any[] = [];
  ingestionJobs: any[] = [];

  routesArray: any[];
  ingestionsWithErrors: any = [];

  finishedIngestions: any = [];
  failedIngestions: any = [];
  inProgressIngestions: any = [];
  discardedIngestion: any = [];

  inProgressWithErrors: any = [];

  stoppedIngestions: any = [];
  term: string;
  failedProgress: any = [];
  ingestionDetails: any;
  listToggle: any = [];
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  showIcon: boolean;
  filterOptions: any[];
  filterParams: any[] = [];
  load: boolean = true;
  loggedUser: any;
  page: number;

  constructor(
    public rdbmsService: RdbmsIngestionControllerService,
    public router: Router,
    private snackBar: MatSnackBar,
    public projectService: ProjectService,
    private dataSourceControllerService: DataSourceControllerService,
    private streamIngestionControllerService: StreamIngestionControllerService,
    private streamJobControllerService: StreamJobControllerService,
    private activatedRoute: ActivatedRoute
  ) {
    this.showIcon = false;
  }
  projectName: string;

  ngOnInit() {
    this.loggedUser = JSON.parse(sessionStorage.getItem('loggedUser'));

    this.projectName = this.projectService.getCurrentProject();

    this.filterOptions = [
      { name: 'Finished', value: 0 },
      { name: 'In_Progress', value: 0 },
      { name: 'Errors', value: 0 },
      { name: 'Stopped', value: 0 },
      { name: 'Failed', value: 0 },
      { name: 'Discarded', value: 0 },
    ];

    this.getAllIngestionInformation();
  }

  public refreshPage() {
    this.load = true;
    this.getAllIngestionInformation();
  }

  public toConfigureIngestion() {
    this.router.navigateByUrl(
      `project/${this.projectName}/ingest/select-ingestion`
    );
  }

  sortAscending(data: any) {
    data.sort((a: any, b: any) => (a.createdAt > b.createdAt ? 1 : -1));
  }

  sortDescending(data: any) {
    data.sort((a: any, b: any) => (a.createdAt < b.createdAt ? 1 : -1));
  }

  //refresh page
  getAllConnections() {
    this.dataSourceControllerService
      .getRDBMSConnectors()
      .subscribe((response) => {
        this.connections = response;
      });
  }

  public getAllIngestionInformation() {
    this.rdbmsService
      .getAllIngestionsByProjectName(this.projectService.getCurrentProject())
      .subscribe(async (response) => {
        this.connections = await this.dataSourceControllerService
          .getRDBMSConnectors()
          .toPromise();

        this.ingestionJobs = await this.rdbmsService
          .getAllIngestionJobs(response)
          .toPromise();

        // Data Formatting
        this.ingestions = response.map((res: any) => {
          const connection: any = this.connections.find(
            (connection) => res.sourceId == connection.id
          );

          let singleJob = this.ingestionJobs.find(
            (job) => res.id == job.rdbmsIngestionId
          );

          let newIng = {
            ...res,
            databaseType: connection.databaseType,
          };

          if (res.bulkImport == false && (!res.query || res.query == null)) {
            newIng.ingestionType = 'Table';
          } else if (
            (res.bulkImport == false && res.query) ||
            res.query != null
          ) {
            newIng.ingestionType = 'Query';
          } else if (res.bulkImport == true) {
            newIng.ingestionType = 'Database';
          }

          newIng.jobId = singleJob?.id || null;
          newIng.progress = singleJob?.progress || 0;
          newIng.status = singleJob?.status || 'STOPPED';
          newIng.jobCreatedAt = singleJob?.createdAt || null;
          newIng.sqoopCommand = singleJob?.sqoopCommand || [];
          newIng.sqoopJobId = singleJob?.sqoopJobId || null;
          newIng.estTimeLeft = singleJob?.estTimeLeft || null;
          newIng.duration = singleJob?.duration || null;

          if (singleJob?.finishedAt != null) {
            newIng.finishedAt = this.dateMaker(singleJob.finishedAt);
          } else {
            newIng.finishedAt = 'Not Applicable';
          }

          return newIng;
        });

        this.filterOptions = this.filterOptions.map((option) => {
          const ing = this.ingestions.filter(
            (ingest) => ingest.status === option.name.toUpperCase()
          );

          let newOption = {
            name: option.name,
            value: ing.length,
          };

          return newOption;
        });
        // Pulling Ingestion from StreamJobs need some refactoring ---------->

        this.streamIngestionControllerService
          .getAllStreamIngestionsByProjectName(this.projectName)
          .subscribe((response) => {
            if (response) {
              let data: any = response;
              let jobs: any;
              this.streamJobControllerService
                .getAllStreamJobsByProject(this.projectName)
                .subscribe((response) => {
                  jobs = response;

                  for (let i = 0; i < data.length; i++) {
                    let singleJob: any;
                    singleJob = jobs.filter(
                      (job) => data[i]?.id == job.streamIngestionId
                    );

                    this.ingestions.push({
                      id: data[i].id,
                      name: data[i].name,
                      createdAt: data[i].createdAt,
                      ingestionType: 'Stream',
                      jobId: singleJob[0]?.id,
                      progress: singleJob[0]?.progress,
                      status: singleJob[0]?.status,
                      jobCreatedAt: singleJob[0]?.createdAt,
                      sqoopCommand: singleJob[0]?.sqoopCommand,
                    });
                  }
                  this.ingestions = this.returnSortedList(this.ingestions);
                });
            }
          });
        this.load = false;
      });
  }

  public applyFilter(event: any, option: string) {
    if (event.target.checked) {
      this.filterParams.push(option.toUpperCase());
    } else {
      this.filterParams = this.filterParams.filter(
        (param) => param !== option.toUpperCase()
      );
    }
  }

  public get returnIngestions(): any[] {
    let filteredIngestions = [];

    if (!this.filterParams.length && !this.term) {
      return this.ingestions;
    } else {
      this.filterParams.forEach((param) => {
        this.ingestions.forEach((ing) => {
          if (ing.status === param) {
            filteredIngestions.push(ing);
          }
        });
      });
    }

    if (this.term) {
      if (!filteredIngestions.length) {
        filteredIngestions = this.ingestions.filter((ingest) =>
          ingest.name.toLowerCase().includes(this.term.toLowerCase())
        );
      } else {
        filteredIngestions = filteredIngestions.filter((ingest) =>
          ingest.name.toLowerCase().includes(this.term.toLowerCase())
        );
      }
    }

    return filteredIngestions;
  }

  public searchIngest() {
    console.log(this.term);
  }

  checkForNull(value: string): string {
    if (value == null) {
      return 'N/A';
    } else {
      return value;
    }
  }

  returnSortedList(data: any) {
    return data.sort((a: any, b: any) => (a.createdAt < b.createdAt ? 1 : -1));
  }

  public dateMaker(date: string): string {
    let dateformat = new Date(date);
    let dateArray = dateformat.toString().split(' ');
    return (
      dateArray[0] +
      ', ' +
      dateArray[1] +
      ' ' +
      dateArray[2] +
      ', ' +
      dateArray[3] +
      `
    ${dateArray[4]} `
    );
  }

  formatJobDate(date: string): string {
    console.log(date);
    if (date == undefined) {
      return 'NOT RUN YET';
    } else {
      let dateformat = new Date(date);
      let dateArray = dateformat.toString().split(' ');
      return (
        dateArray[0] +
        ', ' +
        dateArray[1] +
        ' ' +
        dateArray[2] +
        ', ' +
        dateArray[3] +
        `
      ${dateArray[4]} `
      );
    }
  }

  changeStatusIcon(driver: string): string {
    if (driver === undefined) {
      return 'times-circle text-danger';
    }
    if (driver.toLocaleLowerCase() == 'finished') {
      return 'check text-success';
    } else if (driver.toLocaleLowerCase() == 'failed') {
      return 'times-circle text-danger';
    } else if (driver.toLocaleLowerCase() == 'discarded') {
      return 'trash';
    } else {
      return 'spinner';
    }
  }

  returnEstimatedTime(value: number): any {
    let valueInSeconds = value * 0.001;
    return valueInSeconds + ' seconds';
  }
  carryState(id: string, sqoopJobId: string) {
    // this.router.navigate([`ingestionDetail/${id}/`], { queryParams: { data: sqoopJobId } });
    this.router.navigate(
      [`/project/${this.projectName}/databaseViewDetails/${id}/`],
      { queryParams: { data: sqoopJobId } }
    );
  }

  deleteIngestion(event: any) {
    const id = event;
    Swal.fire({
      type: 'warning',
      title: 'Are you sure you want to delete this Ingestion?',
      text: 'All information associated to this ingestion will be permanently deleted',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed == true) {
        this.rdbmsService.deleteRdbmsIngestion(id).subscribe((res) => {
          this.failedIngestions = [];
          this.stoppedIngestions = [];
          this.finishedIngestions = [];
          this.inProgressIngestions = [];
          this.inProgressWithErrors = [];
          this.getAllIngestionInformation();
          Swal.fire('Ingestion deleted!');
        });
      }
    });
  }

  openSnackBar(status: string, statusLogo: string) {
    this.snackBar.open(status, statusLogo, {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['tertiary-active'],
    });
  }

  success(status: string) {
    Swal.fire({
      type: 'Success',
      title: 'Success!',
      text: status,
      confirmButtonText: 'Ok',
    });
  }

  ngAfterViewInit() {
    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $('.pagec').css('height', contenth);
      $('.sidebar-wrapper').css('height', sidebarh);
    });

    /* box mouse over */
    $('body').on('mouseover', '.db_boxc', function () {
      $(this).find('.icon_c').addClass('show');
    });
    /* box mouse out */
    $('body').on('mouseout', '.db_boxc', function () {
      $(this).find('.icon_c').removeClass('show');
    });
  }

  isIngestConfigHidden() {
    for (
      var user_auths = 0;
      user_auths < this.loggedUser.authorities.authorities.length;
      user_auths++
    ) {
      if (
        this.loggedUser.authorities.authorities[user_auths].authority ===
          'PERM_MAINTAINER' ||
        this.loggedUser.authorities.authorities[user_auths].authority ===
          'PERM_OWNER' ||
        this.loggedUser.authorities.authorities[user_auths].authority ===
          'PERM_ADMIN'
      ) {
        return false;
      }
    }
    return true;
  }
}

<nav class="sidebar-wrapper">
    <div class="rightbdr"></div>
    <ul>
        <li class="sidebar-list" routerLink="/home" routerLinkActive="active" (click)="noCurrentProject();">
            <a>
                <i class="fa fa-home"></i>
                <span class="leftnavtxt">Home</span>
            </a>
        </li>
        <!--Outside Project Side Nav-->
        <nav *ngIf="!nav.isInProject()">
            <li class="sidebar-list" routerLink="/project" routerLinkActive="active">
                <a>
                    <i class="fa fa-folder-open"></i>
                    <span class="leftnavtxt">Projects</span>
                </a>
            </li>
            <li class="sidebar-list" routerLink="/user-management" routerLinkActive="active"
                [hidden]="isUserManagementHidden()">
                <a>
                    <i class="fa fa-user fa_topico user_management_ico"></i>
                    <span class="leftnavtxt">User Management</span>
                </a>
            </li>
            <li class="sidebar-list profileclick" routerLinkActive="active">
                <a>
                    <i class="fa fa-user"></i>
                    <span class="leftnavtxt">Profile</span>
                </a>
            </li>
        </nav>
        <!--Inside Project Side Nav-->
        <nav *ngIf="nav.isInProject()">
            <li class="sidebar-list projow_click" routerLink="/project/{{projectService.getCurrentProject()}}"
                routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">
                <a>
                    <i class="fa fa-folder-open"></i>
                    <span class="leftnavtxt">Overview</span>
                </a>
            </li>
            <!-- <li class="sidebar-list" routerLink="/project/{{projectService.getCurrentProject()}}/ingest-board"
                routerLinkActive="active">
                <a>
                    <i class="fa fa-desktop"></i>
                    <span class="leftnavtxt">Ingest - Board</span>
                </a>
            </li> -->
            <li class="sidebar-list" [routerLink]="['/project', projectService.getCurrentProject(), 'data-sources']"
                routerLinkActive="active">
                <a>
                    <i class="fa fa-database"></i>
                    <span class="leftnavtxt">Data Sources</span>
                </a>
            </li>
            <li class="sidebar-list" routerLink="/project/{{projectService.getCurrentProject()}}/ingest"
                routerLinkActive="active" (click)="deleteData()">
                <a>
                    <i class="fa fa-filter"></i>
                    <span class="leftnavtxt">Ingest</span>
                </a>
            </li>
            <!-- <li class="sidebar-list">
            <a [routerLink]="['/projects', projectService.getCurrentProject(), 'ingest']">
                <i class="fa fa-filter" ></i>           
                <span class="leftnavtxt">Custom ingest</span>
            </a>
        </li> -->
            <li class="sidebar-list" [routerLink]="['/project', projectService.getCurrentProject(), 'jobs']"
                routerLinkActive="active">
                <a>
                    <i class="fa  fa-wrench"></i>
                    <span class="leftnavtxt">Jobs</span>
                </a>
            </li>
            <li class="sidebar-list"
                [routerLink]="['/project', projectService.getCurrentProject(), 'job-schedule-landing-page']"
                routerLinkActive="active">
                <a>
                    <i class="fa fa-calendar"></i>
                    <span class="leftnavtxt">Scheduler</span>
                </a>
            </li>
            <li class="sidebar-list" [routerLink]="['/project', projectService.getCurrentProject(), 'project-access']"
                routerLinkActive="active" [hidden]="isAccessHidden()">
                <a>
                    <i class="fa fa-lock"></i>
                    <span class="leftnavtxt">Access</span>
                </a>
            </li>

        </nav>
    </ul>
</nav>


<!--Inside Project Side Nav-->
export class Config {
  // demo url
  //static url = 'https://ingest.demo.insightsquotient.com/api/v1';

  // dev url (use this for all dev task)
  //static url = 'http://ingest.dev.insightsquotient.com/api/v1';

  // qubz url
  // static url = 'https://ingest-api.qubzinsights.com/api/v1';
  // static url = "http://ingest.dev.insightsquotient.com/api/v1";

  // commit url
  
  static url = '/api/v1';
}

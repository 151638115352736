import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from 'src/app/services/users/user.service';
import { CustomValidators } from 'src/app/validation/custom-validators';
import { NavigationComponent } from 'src/app/navigation/navigation/navigation.component';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { IngestionSharingServiceService } from 'src/app/ingestion-sharing-service.service';
declare var $: any;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  typePassword;
  show_pwd: boolean = true;
  currentUser: any
  hide = true;
  imgProfileURL: string = "../../../assets/images/usericon_other.png";
  avatarName;
  profileImgURL: string;
  imgURL1: string;
  imgURL2: any;
  imgURL3: any;
  imgURL4: any;
  imgURL5: any;
  imgName1;
  imgName2;
  imgName3;
  imgName4;
  imgName5;

  togglePassword() {
    this.typePassword = !this.typePassword;
  };

  constructor(private _snackBar: MatSnackBar, private userService: UserService,
    private router: Router, private authService: AuthService, private formBuilder: FormBuilder,
    private ingestionSharingService: IngestionSharingServiceService
  ) { }
  user: any = [];

  userForm = this.formBuilder.group({
    id: [""],
    username: [""],
    password: [""],
    email: [""],
    firstName: [""],
    lastName: [""],
    displayName: [""],
    role: [""],
    imageFileName: [""]
  })

  getUser() {
    this.user = JSON.parse(sessionStorage.getItem('loggedUser'));
    this.userService.getUserByUserName(this.user.authorities.principal.username).subscribe(response => {

      this.currentUser = response;
      if (this.currentUser.imageFileName != undefined) {
        this.profileImgURL = this.currentUser.imageFileName;

      } else {
        this.profileImgURL = "../../../assets/images/usericon_other.png";

      }
      this.userForm.patchValue({
        id: this.currentUser.id,
        username: this.currentUser.username,
        password: this.currentUser.password,
        email: this.currentUser.email,
        firstName: this.currentUser.firstName,
        lastName: this.currentUser.lastName,
        displayName: this.currentUser.displayName,
        role: this.currentUser.role
      })
      this.passwordForm.patchValue({
        id: this.currentUser.id,
        username: this.currentUser.username,
        password: this.currentUser.password,
        email: this.currentUser.email,
        firstName: this.currentUser.firstName,
        lastName: this.currentUser.lastName,
        displayName: this.currentUser.displayName,
        role: this.currentUser.role
      })
    })
  }
  onSelectAvathar(imageSelected: string) {

    this.profileImgURL = imageSelected;

    // this.avatarName = avatarName;
  }

  cancelImg() {
    this.profileImgURL = this.currentUser.imageFileName;
  }
  getLoggedUser() {
    this.user = JSON.parse(sessionStorage.getItem('loggedUser'));
  }



  updateUser(data: any) {

    // let dataURL = [...Buffer.from(this.profileImgURL)];
    // console.log(typeof dataURL);
    this.userForm.patchValue({
      id: this.currentUser.id,
      username: this.currentUser.username,
      password: this.currentUser.password,
      email: this.currentUser.email,
      firstName: data.value.firstName,
      lastName: data.value.lastName,
      displayName: data.value.displayName,
      role: this.currentUser.role,
      imageFileName: this.profileImgURL
    })
    console.log(this.userForm.value);

    this.userService.updateUser(this.userForm.value).subscribe(res => {
      this.success("Update successful");
      this.getUser();
      this.ingestionSharingService.setAvatarImg(this.userForm.value.imageFileName);

      // let comp = new NavigationComponent(this.userService);
      // comp.getUser();
    })
    $('#editprofile').modal().hide();

  }

  passwordForm = this.formBuilder.group({
    id: [""],
    username: [""],
    password: ["", Validators.compose([
      Validators.required,
      CustomValidators.patternValidator(/\d/, { hasNumber: true }),
      CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
      CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
      CustomValidators.patternValidator(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, { hasSpecialCharacters: true }),
      Validators.minLength(8)])],
    email: [""],
    firstName: [""],
    lastName: [""],
    displayName: [""],
    role: [""]
  })

  updatePassword(data: any) {
    this.userForm.patchValue({
      id: this.currentUser.id,
      username: this.currentUser.username,
      password: data.value.password,
      email: this.currentUser.email,
      firstName: this.currentUser.firstName,
      lastName: this.currentUser.lastName,
      displayName: this.currentUser.displayName,
      role: this.currentUser.role
    })
    this.userService.updateUser(this.userForm.value).subscribe(res => {

      this.success("Update successful")
    })
    $('#changepwd').modal().hide();
  }



  updateProfile(data: any, image: string) {
    this.userService.updateUser(data).subscribe(updatedUser => {

      // this.userService.addUserAvatar(data.username, image).subscribe(res => { 
      //   this.success("Profile update successful")
      // })
    })
  }

  signout() {
    sessionStorage.clear();

    this.router.navigate([""]);
    localStorage.removeItem("img");


  }
  connectionSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      panelClass: ['tertiary-active']

    });
  }
  success(status: string) {
    Swal.fire({
      type: 'Success',

      title: 'Success!',
      text: status,
      confirmButtonText: 'Ok',
    })
  }
  ngOnInit(): void {
    this.getLoggedUser();
    this.getUser();

  }

}

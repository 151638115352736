<div class="cm-content-blocks">
  <ol class="breadcrumb">
    <li><a routerLink="/home"><i class="fa fa-home"></i>Home</a></li>
    <li><a routerLink="/project"><i class="fa fa-folder-open"></i>Projects</a></li>
    <li><a routerLink="/project/{{projectName}}"><i class="fa fa-folder"></i>{{projectName}}</a></li>
    <li><a [routerLink]="['/project', projectService.getCurrentProject(),'ingest']"><i
          class="fa fa-filter"></i>Ingest</a></li>
    <li><a [routerLink]="['/project', projectService.getCurrentProject(),'ingest', 'select-ingestion']"><i
          class="fa fa-sitemap mr-1"></i>Configure Ingestion</a></li>
    <li class="active"><i class="fa fa-filter"></i>Stream Ingestion</li>

  </ol>
</div>

<div class="clearafter headbtnc">
  <h3 class="headc">
    Stream Ingestion
    <div class="subhead">Configure Stream Ingestion</div>
  </h3>
  <div class="create-btn-position headbtnc">
    <button class="mr-btn-back pull-right pointerc" routerLink="/project/{{projectName}}/ingest">Back to Ingest</button>
  </div>
</div>

<div class="menublock">
  <!-- steps -->

  <div class="progresslic clearafter ">
    <div class="fifteen_pb">
      <ul class="progressbar clearfix">

        <li class="tabli active pointerc" [ngClass]="showDiv.stepone === true ? 'active':'disabled' ">
          <section class="tabbtn">
            <section class="clearfix relativepos">
              <aside class="absoultepos stipico">
                <i class="fa fa-info" aria-hidden="true"></i>
              </aside>
              <aside class="steptxtc">
                <div class="steptitle">Data Source</div>
                <div class="stepdes mt-1">Select data source for ingestion</div>
              </aside>
            </section>
          </section>
        </li>

        <li class="tabli pointerc" [ngClass]="showDiv.steptwo === true ?'active':'disabled' ">
          <section class="tabbtn">
            <section class="clearafter relativepos">
              <aside class="absoultepos stipico">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
              </aside>
              <aside class="steptxtc">
                <div class="steptitle">Configure Destination</div>
                <div class="stepdes">Edit destination database and table names, and select ingestion mode</div>
              </aside>
            </section>
          </section>
        </li>

        <!-- <li class="tabli pointerc"
                [ngClass]="showDiv.stepfour === true ?'active':'disabled' " 
               
                >
                    <section class="tabbtn">
                        <section class="clearfix relativepos">
                        <aside class="absoultepos stipico">
                            <i class="fa fa-cogs" aria-hidden="true"></i>
                        </aside>
                        <aside class="steptxtc">
                            <div class="steptitle">Advanced Configuration</div>
                            <div class="stepdes">Configure ingestion settings</div>
                        </aside>
                        </section>
                    </section>
                </li>  -->
        <!-- <li class="tabli pointerc"
                [ngClass]="showDiv.stepfive === true ?'active':'disabled' " 
                (click)="showDiv.stepone = false;
                showDiv.steptwo = false; 
                showDiv.stepthree = false;
                showDiv.stepfour = false;
                showDiv.stepfive = true;
  
                showDiv.backbtn = false;
                showDiv.backbtntwo = false;  
                showDiv.backbtnthree = false;  
                showDiv.backbtnfour = false;  
                showDiv.backbtnfive = true;  
   
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false;
                showDiv.nextbtntwo = false; 
                showDiv.nextbtnthree = false; 
                showDiv.nextbtnfour = false; 
                showDiv.cancelbtn = false"
                >
                    <section class="tabbtn">
                        <section class="clearfix relativepos">
                        <aside class="absoultepos stipico">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                        </aside>
                        <aside class="steptxtc">
                            <div class="steptitle">Advanced Configuration</div>
                            <div class="stepdes">Description</div>
                        </aside>
                        </section>
                    </section>
                </li>
                 -->


      </ul>
    </div>
    <!-- tabe content-->

    <section class="tabc_config">


      <div class="steptwo_c" *ngIf="showDiv.steptwo">

        <div class="fifteenmb">

          <section class="clearafter">

            <aside class="fourtynine_p float-right">
              <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-pulse"
                [fullScreen]="false">
                <p style="color: white"> Loading... </p>
              </ngx-spinner>

              <div class="subtitle">Select Fields</div>
              <p>Select which fields to be ingested and their data warehouse data types.</p>
              <div>
                <span class="example-list-section">
                  <mat-checkbox class="example-margin" [checked]="allComplete" [indeterminate]="someComplete()"
                    (change)="setAll($event.checked)" color="primary">
                    Select All
                  </mat-checkbox>
                </span>
                <span class="example-list-section">
                  <table class="table table-borderless mr-table">
                    <thead class="mr-table-head">
                      <tr class="mr-table-head-row">
                        <th>Header</th>
                        <th>Data Type</th>
                        <th>Warehouse Data Type</th>
                        <!-- <th class="actionthreetd">Actions</th> -->
                      </tr>
                    </thead>
                    <tbody *ngFor="let field of dataSource.fields; let i = index">
                      <tr class="mr-table-body-row">
                        <td>
                          <mat-checkbox [(ngModel)]="field.checked" (ngModelChange)="updateAllComplete()"
                            color="primary">
                            {{field.header}}
                          </mat-checkbox>
                        </td>
                        <td>{{field.dataType}}</td>
                        <td>
                          <mat-form-field appearance="fill">
                            <mat-label>{{field.hiveDataType}}</mat-label>

                            <mat-select formControlName="fileType">
                              <div *ngIf="stepTwoForm.value.fileType == 'csv' || stepTwoForm.value.fileType == 'CSV'">
                                <mat-option *ngFor="let type of csvDestinationDataTypes" class="mr-4"
                                  (click)="setHiveDataType(dataSource,i,type)">
                                  {{type}}

                                </mat-option>
                              </div>
                              <div *ngIf="stepTwoForm.value.fileType == 'avro' || stepTwoForm.value.fileType == 'AVRO'">
                                <mat-option *ngFor="let type of avroDestinationDataTypes" class="mr-4"
                                  (click)="setHiveDataType(dataSource,i,type)">
                                  {{type}}

                                </mat-option>
                              </div>
                              <div
                                *ngIf="stepTwoForm.value.fileType == 'parquet' || stepTwoForm.value.fileType == 'PARQUET'">
                                <mat-option *ngFor="let type of parquetDestinationDataType" class="mr-4"
                                  (click)="setHiveDataType(dataSource,i,type)">
                                  {{type}}

                                </mat-option>
                              </div>
                            </mat-select>
                          </mat-form-field>
                        </td>
                        <!-- <td>
                                      <div class="icons-container actionthreetd">
                                        <i  class="fa fa-trash pointerc ml-4" aria-hidden="true" ngbTooltip="Delete" (click)="deleteField(dataSource.fields,i)" placement="left"></i>
                                      </div>
                                    </td> -->
                      </tr>

                    </tbody>

                  </table>

                  <!-- <ul>
                              <li *ngFor="let field of formForFields.value.fields; let i = index">
                                  <div class="row mt-2">
                                      <div class="col-md-6">
                                        <mat-checkbox [(ngModel)]="field.checked"
                                        (ngModelChange)="updateAllComplete()"
                                        color="primary">
                                            {{field.header}}
                                        </mat-checkbox>
                                      </div>
                                      <div class="col-md-6">
                                        <mat-form-field appearance="fill">
                                            <mat-label>{{field.dataType}}</mat-label>
                                            <mat-select >
                                              <mat-option>
                                              </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                      </div>
                                  </div>
                              </li>
                            </ul> -->
                </span>

              </div>

            </aside>




            <aside class="fourtynine_p float-left">

              <div class="subtitle">Enter Destination Details</div>
              <p>Enter database name, table name, and destination file type choice </p>
              <div>
                <span class="example-list-section">
                </span>
                <form [formGroup]="stepTwoForm" (ngSubmit)="submitForm(stepTwoForm)">
                  <section class="inputc">


                    <aside class="col-md-12 fieldc"
                      [ngClass]="stepTwoForm.controls['databaseName'].touched && stepTwoForm.controls['databaseName'].errors?'inputc-error':''">
                      <label class="inputlabel clearafter">
                        <span class="displayblock fl fivemr">Database Name </span>
                        <span class="text-danger">*</span>

                      </label>
                      <input class="input-field" formControlName="databaseName" type="text">
                      <span class="error-mge mt-2"
                        *ngIf="stepTwoForm.controls['databaseName'].invalid && (stepTwoForm.controls['databaseName'].dirty || stepTwoForm.controls['databaseName'].touched)">
                        Database name is required.
                      </span>
                    </aside>
                  </section>
                  <section class="inputc">

                    <aside class="col-md-12 fieldc"
                      [ngClass]="stepTwoForm.controls['tableName'].touched && stepTwoForm.controls['tableName'].errors?'inputc-error':''">
                      <label class="inputlabel clearafter">
                        <span class="displayblock fl fivemr">Table Name </span>
                        <span class="text-danger">*</span>

                      </label>
                      <input class="input-field" formControlName="tableName" type="text">
                      <span class="error-mge mt-2"
                        *ngIf="stepTwoForm.controls['tableName'].invalid && (stepTwoForm.controls['tableName'].dirty || stepTwoForm.controls['tableName'].touched)">
                        Table name is required.
                      </span>
                    </aside>
                  </section>
                  <section class="inputc">

                    <aside class="col-md-12 fieldc"
                      [ngClass]="stepTwoForm.controls['tableName'].touched && stepTwoForm.controls['tableName'].errors?'inputc-error':''">

                      <label class="inputlabel clearafter">
                        <span class="displayblock fl fivemr">File Type </span>
                        <span class="text-danger">*</span>
                      </label>
                      <mat-form-field appearance="fill">
                        <mat-label>{{stepTwoForm.value.fileType}}</mat-label>

                        <mat-select formControlName="fileType">
                          <mat-option [value]="'csv'" (click)="selectFileType('csv')">CSV </mat-option>
                          <mat-option [value]="'avro'" (click)="selectFileType('avro')">AVRO </mat-option>
                          <mat-option [value]="'parquet'" (click)="selectFileType('parquet')">PARQUET </mat-option>

                        </mat-select>
                      </mat-form-field>
                    </aside>
                  </section>

                </form>
              </div>
            </aside>
          </section>
          <!-- <h4>{{streamIngestion.value |json}}</h4> -->

          <!-- <ng-template #itemTemplate let-item="item" let-index="index">
                        <ul>
                        <button class="btn btn-light"> {{item.value}}</button>
                        
  
                       </ul>
                    </ng-template>
  
                   
                    <div class="row">
                    <div class="col-xs-6 col-6 col-md-5 col-lg-3">
                        <bs-sortable
                        [(ngModel)]="itemObjectsLeft"
                        [itemTemplate]="itemTemplate"
                        fieldName="sourceDatabaseName"
                        itemClass="callout sortable"
                        itemActiveClass="sortable-item-active"
                        placeholderItem="Drag here"
                        placeholderClass="placeholderStyle"
                        wrapperClass="sortable-wrapper"
                        ></bs-sortable>
  
                        <pre class="code-preview">model: {{ itemObjectsLeft | json }}</pre>
                    </div>
                    
                 
                    <div class="col-xs-6 col-6 col-md-5 col-lg-3 dragged-question">
                        <bs-sortable
                        [(ngModel)]="itemObjectsRight"
                        [itemTemplate]="itemTemplate"
                        fieldName="sourceDatabaseName"
                        itemClass="callout sortable"
                        itemActiveClass="sortable-item-active"
                        placeholderItem="Drag here"
                        placeholderClass="sortable-item"
                        wrapperClass="sortable-wrapper"
                        ></bs-sortable>
                        <pre class="code-preview">model: {{ itemObjectsRight | json }}</pre>
                    </div>
                    
                    </div> -->

        </div>
      </div>



      <footer class="tenpt lightgraybdrt clearafter footerbtns">

        <button class="tertiary-active fr btnnext" id="btnnext" (click)="submitFields()"
          [disabled]="stepTwoForm.invalid || fieldsSelected == false">
          Save Ingestion
          <i aria-hidden="true" class="fa fa-arrow-right"></i>
        </button>
        <button class="grey-btn fr btnpre" routerLink="/project/{{projectName}}/ingest/select-ingestion/stepOneStream">
          <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
        </button>

        <!-- <button class="tertiary-active fr btnnext" id="btnnext" *ngIf="showDiv.nextbtnfour"
                (click)="showDiv.stepone = false; 
                showDiv.steptwo =false; 
                showDiv.stepthree = false;
                showDiv.stepfour= false;
                showDiv.stepfive = true;
  
                showDiv.backbtn = false; 
                showDiv.backbtntwo = false;
                showDiv.backbtnthree = false; 
                showDiv.backbtnfour = true; 
                showDiv.backbtnfive = false;
  
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false; 
                showDiv.nextbtntwo = false; 
                showDiv.nextbtnthree = false; 
                showDiv.nextbtnfour = false;
  
                showDiv.cancelbtn = false;">
                Next <i aria-hidden="true" class="fa fa-arrow-right"></i>
                </button>
                
     -->
        <button class="grey-btn fr btnpre" *ngIf="showDiv.cancelbtn">
          Cancel
        </button>

        <button class="tertiary-active fr btnnext" *ngIf="showDiv.submitbtn">
          Submit
        </button>

        <button class="grey-btn fr btnpre" *ngIf="showDiv.backbtn" (click)="showDiv.stepone = true; 
                showDiv.steptwo = false; 
                showDiv.stepthree = false;
                showDiv.stepfour = false;
                showDiv.stepfive = false;
  
                showDiv.backbtn = false; 
                showDiv.backbtntwo = false;
                showDiv.backbtnthree = false; 
                showDiv.backbtnfour= false;
                showDiv.backbtnfive = false;
  
                showDiv.submitbtn = false; 
                showDiv.nextbtn = true; 
                showDiv.nextbtntwo = false; 
                showDiv.nextbtnthree = false; 
                showDiv.nextbtnfour = false; 
  
                showDiv.cancelbtn = false;">
          <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
        </button>
        <button class="grey-btn fr btnpre" *ngIf="showDiv.backbtntwo" (click)="showDiv.stepone = false; 
                showDiv.steptwo = true; 
                showDiv.stepthree = false;
                showDiv.stepfour = false;
                showDiv.stepfive = false;
  
                showDiv.backbtn = true; 
                showDiv.backbtntwo = false;
                showDiv.backbtnthree = false;
                showDiv.backbtnfour = false;
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false; 
                showDiv.nextbtntwo = true; 
                showDiv.nextbtnthree = false; 
                showDiv.nextbtnfour = false; 
  
                showDiv.cancelbtn = false;">
          <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
        </button>
        <button class="grey-btn fr btnpre" *ngIf="showDiv.backbtnthree" (click)="showDiv.stepone = false; 
                showDiv.steptwo = false; 
                showDiv.stepthree = true;
                showDiv.stepfour = false;
                showDiv.stepfive = false;
  
                showDiv.backbtn = false; 
                showDiv.backbtntwo = true;
                showDiv.backbtnthree = false; 
                showDiv.backbtnfour = false; 
                showDiv.backbtnfive = false; 
  
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false; 
                showDiv.nextbtntwo = false; 
                showDiv.nextbtnthree = true; 
                showDiv.nextbtnfour = false; 
                showDiv.cancelbtn = false;">
          <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
        </button>
        <!-- <button class="grey-btn fr btnpre"  *ngIf="showDiv.backbtnfour"
                (click)="showDiv.stepone = false; 
                showDiv.steptwo = false; 
                showDiv.stepthree = false;
                showDiv.stepfour = true;
                showDiv.stepfive = false;
  
                showDiv.backbtn = false; 
                showDiv.backbtntwo = false;
                showDiv.backbtnthree = true; 
                showDiv.backbtnfour = false; 
                showDiv.backbtnfive = false; 
  
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false; 
                showDiv.nextbtntwo = false; 
                showDiv.nextbtnthree = false; 
                showDiv.nextbtnfour = true; 
                showDiv.cancelbtn = false;">
                    <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
                </button> -->
        <!-- <button class="grey-btn fr btnpre"  *ngIf="showDiv.backbtnfive"
                (click)="showDiv.stepone = false; 
                showDiv.steptwo = false; 
                showDiv.stepthree = false;
                showDiv.stepfour = false;
                showDiv.stepfive = true;
  
                showDiv.backbtn = true; 
                showDiv.backbtntwo = false;
                showDiv.backbtnthree = false; 
                showDiv.backbtnfour = true; 
                showDiv.backbtnfive = false; 
  
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false; 
                showDiv.nextbtntwo = false; 
                showDiv.nextbtnthree = false; 
                showDiv.nextbtnfour = false; 
                showDiv.cancelbtn = false;">
                    <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
                </button>
               -->
      </footer>
    </section>

  </div>

</div>
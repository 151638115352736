<div *ngIf="load" class="">
  <div class="loader"></div>
  <figure class="spinner-border loaderico"></figure>
</div>

<div class="">
  <ol class="breadcrumb">
    <li><a routerLink="/home"><i class="fa fa-home"></i>Home</a></li>
    <li><a routerLink="/project"><i class="fa fa-folder-open"></i>Projects</a></li>
    <li><a routerLink="/project/{{projectName}}"><i class="fa fa-folder"></i>{{projectName}}</a></li>
    <li class="active"><i class="fa fa-desktop"></i>Dashboard</li>
  </ol>
</div>

<div class="clearafter headbtnc">
  <h3 class="headc">
    Dashboard - {{projectName}}
    <!-- <div class="subhead">View all Ingestions</div>  -->
  </h3>
</div>

<nav>
  <div class="nav nav-tabs" id="nav-tab" role="tablist">
    <button class="nav-link active" id="nav-one-tab" data-bs-toggle="tab" data-bs-target="#nav-one" type="button"
      role="tab" aria-controls="nav-home" aria-selected="true">All Ingestions ({{ingestions.length}})</button>
    <button class="nav-link" id="nav-two-tab" data-bs-toggle="tab" data-bs-target="#nav-two" type="button" role="tab"
      aria-controls="nav-profile" aria-selected="false">Finished({{finishedIngestions.length}})</button>
    <button class="nav-link" id="nav-three-tab" data-bs-toggle="tab" data-bs-target="#nav-three" type="button"
      role="tab" aria-controls="nav-profile" aria-selected="false">In Progress({{inProgressIngestions.length}})
    </button>
    <button class="nav-link" id="nav-four-tab" data-bs-toggle="tab" data-bs-target="#nav-four" type="button" role="tab"
      aria-controls="nav-contact" aria-selected="false">In Progress with Errors ({{inProgressWithErrors.length}})
    </button>
    <button class="nav-link" id="nav-five-tab" data-bs-toggle="tab" data-bs-target="#nav-five" type="button" role="tab"
      aria-controls="nav-contact" aria-selected="false">Stopped ({{stoppedIngestions.length}})</button>
    <button class="nav-link" id="nav-six-tab" data-bs-toggle="tab" data-bs-target="#nav-six" type="button" role="tab"
      aria-controls="nav-contact" aria-selected="false">Failed ({{failedIngestions.length}})</button>
    <button class="nav-link" id="nav-seven-tab" data-bs-toggle="tab" data-bs-target="#nav-seven" type="button"
      role="tab" aria-controls="nav-contact" aria-selected="false">Discarded ({{discardedIngestion.length}})</button>

  </div>
</nav>
<div class="tab-content" id="nav-tabContent" *ngIf="ingestions.length == 0">
  <p class="text-center mt-4">No ingestions exist for this project</p>
</div>

<div class="tab-content" id="nav-tabContent" *ngIf="ingestions.length > 0">
  <div class="tab-pane fade show active" id="nav-one" role="tabpanel" aria-labelledby="nav-one-tab">
    <section class="clearafter row no-gutters mt-4">
      <!-- search box -->
      <aside class="col-md-4 zeroplimp float-left">
        <div class="searchbarc fifteenpb">
          <input class="search-bar input-field" type="text" placeholder="Search all ingestions..." [(ngModel)]="term">
          <img class="search-bar-icon" src="../../../assets/images/search.svg">
        </div>
      </aside>

      <aside class="col-md-8 text-right">
        <span class="inline-block fivemr">Sort by: </span>
        <label class="radioc">
          <input type="radio" name="inlineRadioOptions" (click)="sortDescending(ingestions)" id="inlineRadio1"
            value="option1">
          <span class="checkmark-r"></span>
          <span class="text-r">Newest Ingestion</span>
        </label>
        <label class="radioc">
          <input type="radio" name="inlineRadioOptions" (click)="sortAscending(ingestions)" id="inlineRadio2"
            value="option2">
          <span class="checkmark-r"></span>
          <span class="text-r">Oldest Ingestion</span>
        </label>

      </aside>
    </section>
    <section class="db_boxec ">

      <aside class="db_boxc position-relative" *ngFor="let ingestion of ingestions | filter: term">
        <div class="db_box">

          <div class="icon_c">
            <button class="float-right iconbtn fivemr" routerLink="/project/{{projectName}}/dashboard"
              (click)="deleteIngestion(ingestion.id)"><i class="fa fa-trash fa-lg"></i></button>
            <button class="float-right iconbtn fivemr"
              routerLink="/project/{{projectName}}/ingest/editStepOneCustom/{{ingestion.id}}"><i
                class="fa fa-pencil fa-lg"></i></button>
          </div>
          <div class="text-center mt-4">

            <h4 class="cardtitle tenmt">
              {{ingestion.name.toUpperCase()}}
            </h4>

            <div class="cardico">
              <img class="cardimg" src="{{identifyDriver(ingestion.databaseType)}}">
            </div>
            <h4 class="cardtitle">
              <span>Duration:</span>
              <br>
              <span>{{miliConvert(ingestion.duration)}}</span>
            </h4>
            <h4 class="cardcount adj_text">
              <!-- <span>{{ingestion.description}}</span>  -->
            </h4>
            <h4 class="cardtitle"><i
                class="{{bulkImportIcon(ingestion.bulkImport)}} mr-2"></i>{{bulkImport(ingestion.bulkImport).toUpperCase()}}
            </h4>
            <h4 class="cardtitle {{changeStatusColor(ingestion.status)}}">{{returnStatus(ingestion.status)}} </h4>



            <!-- <a href="#" class="card-link" (click)="delete(ingestion.id)">Delete</a>  -->
            <!-- <h5 class="cardtitle">Progress:<span class="success-s">%</span> </h5>

              <div class="progress">
                <div class="progress-bar bg-success" role="progressbar" style="width %" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
              </div> -->
          </div>
        </div>

      </aside>
    </section>
  </div>


  <div class="tab-pane fade" id="nav-two" role="tabpanel" aria-labelledby="nav-two-tab">
    <section class="clearafter row no-gutters mt-4">
      <!-- search box -->
      <aside class="col-md-4 zeroplimp float-left">
        <div class="searchbarc fifteenpb">
          <input class="search-bar input-field" type="text" placeholder="Search finished ingestions..."
            [(ngModel)]="term">
          <img class="search-bar-icon" src="../../../assets/images/search.svg">
        </div>
      </aside>

      <aside class="col-md-8 text-right">
        <span class="inline-block fivemr">Sort by: </span>
        <label class="radioc">
          <input type="radio" name="inlineRadioOptions" (click)="sortDescending(finishedIngestions)" id="inlineRadio1"
            value="option1">
          <span class="checkmark-r"></span>
          <span class="text-r">Newest Ingestion</span>
        </label>
        <label class="radioc">
          <input type="radio" name="inlineRadioOptions" (click)="sortAscending(finishedIngestions)" id="inlineRadio2"
            value="option2">
          <span class="checkmark-r"></span>
          <span class="text-r">Oldest Ingestion</span>
        </label>
      </aside>
    </section>

    <section class="db_boxec">
      <aside class="db_boxc position-relative" *ngFor="let ingestion of finishedIngestions| filter: term">
        <div class="db_box">
          <div class="icon_c">
            <button class="float-right iconbtn fivemr" routerLink="/project/{{projectName}}/dashboard"
              (click)="deleteIngestion(ingestion.id)"><i class="fa fa-trash fa-lg"></i></button>
            <button class="float-right iconbtn fivemr" routerLink="/databaseViewDetails/{{ingestion.id}}"><i
                class="fa fa-pencil fa-lg"></i></button>
          </div>
          <div class="text-center mt-4">

            <h4 class="cardtitle tenmt">{{ingestion.name.toUpperCase()}} </h4>
            <div class="cardico">
              <img class="cardimg" src="{{identifyDriver(ingestion.databaseType)}}">
            </div>
            <h4 class="cardtitle"> <span>{{dateMaker(ingestion.createdAt)}}</span> </h4>
          </div>
        </div>
      </aside>
    </section>
  </div>


  <div class="tab-pane fade" id="nav-three" role="tabpanel" aria-labelledby="nav-three-tab">
    <section class="clearafter row no-gutters mt-4">
      <!-- search box -->
      <aside class="col-md-4 zeroplimp float-left">
        <div class="searchbarc fifteenpb">
          <input class="search-bar input-field" type="text" placeholder="Search ingestions in progress..."
            [(ngModel)]="term">
          <img class="search-bar-icon" src="../../../assets/images/search.svg">
        </div>
      </aside>

      <aside class="col-md-8 text-right">
        <span class="inline-block fivemr">Sort by: </span>
        <label class="radioc">
          <input type="radio" name="inlineRadioOptions" (click)="sortDescending(inProgressIngestions)" id="inlineRadio1"
            value="option1">
          <span class="checkmark-r"></span>
          <span class="text-r">Newest Ingestion</span>
        </label>
        <label class="radioc">
          <input type="radio" name="inlineRadioOptions" (click)="sortAscending(inProgressIngestions)" id="inlineRadio2"
            value="option2">
          <span class="checkmark-r"></span>
          <span class="text-r">Oldest Ingestion</span>
        </label>

      </aside>
    </section>
    <section class="db_boxec">
      <aside class="db_boxc position-relative" *ngFor="let ingestion of inProgressIngestions|  filter: term;">
        <div class="db_box">
          <div class="icon_c">
            <button class="float-right iconbtn fivemr" routerLink="/databaseViewDetails/{{ingestion.id}}"><i
                class="fa fa-pencil fa-lg"></i></button>
          </div>
          <div class="text-center mt-4">

            <h4 class="cardtitle tenmt">{{ingestion.name.toUpperCase()}} </h4>
            <div class="cardico">
              <img class="cardimg" src="{{identifyDriverWithType(ingestion.databaseType, ingestion.ingestionType)}}">
            </div>
            <h4 class="cardtitle"> <span>{{dateMaker(ingestion.createdAt)}}</span> </h4>
            <span>
              <div class="progress-bar" [class.progress-bar-success]="ingestion.progress === 100"
                [class.progress-bar-info]="ingestion.progress < 100"
                [class.progress-bar-danger]="ingestion.progress < 100 && ingestion.status ==='FAILED TO LAUNCH'"
                [class.progress-bar-.primary]="ingestion.progress < 100 && ingestion.status ==='NOT LAUNCHED'"
                role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"
                [style.width.%]="ingestion.progress==0?3:ingestion.progress">
                {{ ingestion.progress| number: '.0-0' }}%
              </div>
            </span>

          </div>
        </div>
      </aside>
    </section>
  </div>



  <div class="tab-pane fade" id="nav-four" role="tabpanel" aria-labelledby="nav-four-tab">
    <section class="db_boxec">
      <aside class="col-md-4 zeroplimp float-left">
        <div class="searchbarc fifteenpb">
          <input class="search-bar input-field" type="text" placeholder="Search ingestions in progress with errors..."
            [(ngModel)]="term">
          <img class="search-bar-icon" src="../../../assets/images/search.svg">
        </div>
      </aside>

      <aside class="col-md-8 text-right">
        <span class="inline-block fivemr">Sort by: </span>
        <label class="radioc">
          <input type="radio" name="inlineRadioOptions" (click)="sortAscending(inProgressIngestions)" id="inlineRadio1"
            value="option1">
          <span class="checkmark-r"></span>
          <span class="text-r">Newest Ingestion</span>
        </label>
        <label class="radioc">
          <input type="radio" name="inlineRadioOptions" (click)="sortDescending(inProgressIngestions)" id="inlineRadio2"
            value="option2">
          <span class="checkmark-r"></span>
          <span class="text-r">Oldest Ingestion</span>
        </label>
      </aside>
      <aside class="db_boxc position-relative" *ngFor="let ingestion of inProgressWithErrors| filter: term">
        <div class="db_box">

          <div class="icon_c">
            <button class="float-right iconbtn fivemr" [hidden]="!showIcon"
              routerLink="/databaseViewDetails/{{ingestion.id}}"><i class="fa fa-pencil fa-lg"></i></button>
          </div>
          <div class="text-center mt-4">

            <h4 class="cardtitle tenmt">{{ingestion.name.toUpperCase()}} </h4>
            <div class="cardico">
              <img class="cardimg" src="{{identifyDriver(ingestion.databaseType)}}">
            </div>
            <h4 class="cardtitle"> <span>{{dateMaker(ingestion.createdAt)}}</span> </h4>

          </div>
        </div>
      </aside>
    </section>
  </div>

  <div class="tab-pane fade" id="nav-five" role="tabpanel" aria-labelledby="nav-five-tab">
    <section class="db_boxec">
      <aside class="col-md-4 zeroplimp float-left">
        <div class="searchbarc fifteenpb">
          <input class="search-bar input-field" type="text" placeholder="Search stopped ingestions..."
            [(ngModel)]="term">
          <img class="search-bar-icon" src="../../../assets/images/search.svg">
        </div>
      </aside>

      <aside class="col-md-8 text-right">
        <span class="inline-block fivemr">Sort by: </span>
        <label class="radioc">
          <input type="radio" name="inlineRadioOptions" (click)="sortDescending(stoppedIngestions)" id="inlineRadio1"
            value="option1">
          <span class="checkmark-r"></span>
          <span class="text-r">Newest Ingestion</span>
        </label>
        <label class="radioc">
          <input type="radio" name="inlineRadioOptions" (click)="sortAscending(stoppedIngestions)" id="inlineRadio2"
            value="option2">
          <span class="checkmark-r"></span>
          <span class="text-r">Oldest Ingestion</span>
        </label>
      </aside>
      <aside class="db_boxc position-relative"
        *ngFor="let ingestion of stoppedIngestions | filter: term; let i = index">

        <div class="db_box boxlink">
          <div class="d-flex flex-row-reverse">
            <a class="float-right mr-2 text-primary " routerLink="/project/{{projectName}}/dashboard"
              (click)="deleteIngestion(ingestion.id)"><i class="fa fa-trash fa-lg"></i></a>

            <a class="float-right mr-4 text-primary" routerLink="/databaseViewDetails/{{ingestion.id}}"><i
                class="fa fa-pencil fa-lg"></i></a>
          </div>
          <div class="text-center mt-4">
            <div class="row">
              <div class="col-md-8">
                <div class="col-md-8">
                  <div class="cardcount"> {{ingestion.name.toUpperCase()}}</div>
                </div>
              </div>

            </div>
            <div class="row mt-4">
              <div class="col-md-6">
                <h5 class="cardtitle mt-n1"><img height="100" width="100"
                    src="{{identifyDriver(ingestion.databaseType)}}"> </h5>
              </div>

              <div class="col-md-6 mt-4">
                <h4 class="cardtitle"><span>{{dateMaker(ingestion.createdAt)}}</span> </h4>

              </div>
            </div>
            <div class="row">
              <!-- <div class="col-md-6">
                  <h5 class="cardtitle"><span class="dark-s"> Bulk Import: <i class="fa fa-{{bulkImport(ingestion.bulkImport)}}" aria-hidden="true"></i>  </span> </h5>

                </div> -->


            </div>


            <!-- <a href="#" class="card-link" (click)="delete(ingestion.id)">Delete</a>  -->
            <!-- <h5 class="cardtitle">Progress:<span class="success-s">%</span> </h5>

              <div class="progress">
                <div class="progress-bar bg-success" role="progressbar" style="width %" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
              </div> -->
          </div>
        </div>
      </aside>
    </section>
  </div>

  <div class="tab-pane fade" id="nav-six" role="tabpanel" aria-labelledby="nav-six-tab">
    <section class="clearafter row no-gutters mt-4">
      <!-- search box -->
      <aside class="col-md-4 zeroplimp float-left">
        <div class="searchbarc fifteenpb">
          <input class="search-bar input-field" type="text" placeholder="Search failed ingestions..."
            [(ngModel)]="term">
          <img class="search-bar-icon" src="../../../assets/images/search.svg">
        </div>
      </aside>

      <aside class="col-md-8 text-right">
        <span class="inline-block fivemr">Sort by: </span>
        <label class="radioc">
          <input type="radio" name="inlineRadioOptions" (click)="sortAscending(failedIngestions)" id="inlineRadio1"
            value="option1">
          <span class="checkmark-r"></span>
          <span class="text-r">Newest Ingestion</span>
        </label>
        <label class="radioc">
          <input type="radio" name="inlineRadioOptions" (click)="sortDescending(failedIngestions)" id="inlineRadio2"
            value="option2">
          <span class="checkmark-r"></span>
          <span class="text-r">Oldest Ingestion</span>
        </label>
      </aside>
    </section>
    <section class="db_boxec">
      <aside class="db_boxc position-relative" *ngFor="let ingestion of failedIngestions | filter: term">
        <div class="db_box">

          <div class="icon_c">
            <button class="float-right iconbtn fivemr" routerLink="/project/{{projectName}}/dashboard"
              (click)="deleteIngestion(ingestion.id)"><i class="fa fa-trash fa-lg"></i></button>
            <button class="float-right iconbtn fivemr" routerLink="/databaseViewDetails/{{ingestion.id}}"><i
                class="fa fa-pencil fa-lg"></i></button>
          </div>
          <div class="text-center mt-4">

            <h4 class="cardtitle tenmt">{{ingestion.name.toUpperCase()}} </h4>
            <div class="cardico">
              <img class="cardimg" src="{{identifyDriverWithType(ingestion.databaseType, ingestion.ingestionType)}}">
            </div>
            <h4 class="cardtitle"> <span>{{dateMaker(ingestion.createdAt)}}</span> </h4>

          </div>
        </div>
      </aside>
    </section>
  </div>
  <div class="tab-pane fade" id="nav-seven" role="tabpanel" aria-labelledby="nav-seven-tab">
    <section class="clearafter row no-gutters mt-4">
      <!-- search box -->
      <aside class="col-md-4 zeroplimp float-left">
        <div class="searchbarc fifteenpb">
          <input class="search-bar input-field" type="text" placeholder="Search discarded ingestions..."
            [(ngModel)]="term">
          <img class="search-bar-icon" src="../../../assets/images/search.svg">
        </div>
      </aside>

      <aside class="col-md-8 text-right">
        <span class="inline-block fivemr">Sort by: </span>
        <label class="radioc">
          <input type="radio" name="inlineRadioOptions" (click)="sortDescending(discardedIngestion)" id="inlineRadio1"
            value="option1">
          <span class="checkmark-r"></span>
          <span class="text-r">Newest Ingestion</span>
        </label>
        <label class="radioc">
          <input type="radio" name="inlineRadioOptions" (click)="sortAscending(discardedIngestion)" id="inlineRadio2"
            value="option2">
          <span class="checkmark-r"></span>
          <span class="text-r">Oldest Ingestion</span>
        </label>
      </aside>
    </section>
    <section class="db_boxec">
      <aside class="db_boxc position-relative" *ngFor="let ingestion of discardedIngestion | filter: term">
        <div class="db_box">

          <div class="icon_c">
            <button class="float-right iconbtn fivemr" routerLink="/project/{{projectName}}/dashboard"
              (click)="deleteIngestion(ingestion.id)"><i class="fa fa-trash fa-lg"></i></button>
            <button class="float-right iconbtn fivemr" routerLink="/databaseViewDetails/{{ingestion.id}}"><i
                class="fa fa-pencil fa-lg"></i></button>
          </div>
          <div class="text-center mt-4">

            <h4 class="cardtitle tenmt">{{ingestion.name.toUpperCase()}} </h4>
            <div class="cardico">
              <img class="cardimg" src="{{identifyDriverWithType(ingestion.databaseType, ingestion.ingestionType)}}">
            </div>
            <h4 class="cardtitle"> <span>{{dateMaker(ingestion.createdAt)}}</span> </h4>

          </div>
        </div>
      </aside>
    </section>
  </div>
</div>

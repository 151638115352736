import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from 'src/app/services/project/project-service.service';
@Component({
  selector: 'app-select-ingestion',
  templateUrl: './select-ingestion.component.html',
  styleUrls: ['./select-ingestion.component.scss'],
})
export class SelectIngestionComponent implements OnInit {
  constructor(
    public projectService: ProjectService,
    private activatedRoute: ActivatedRoute
  ) {}
  projectName = this.activatedRoute.snapshot.params['id2'];

  load = true;
  items = [
    {
      title: 'Database Ingestion',
      description: 'Copy entire databases',
      //imageUrl: "../../../assets/images/db.svg",
      imageUrl: 'fa-database',
      imageUrlSecond: 'fa-download',
      link:
        '/project/' +
        this.projectName +
        '/ingest/select-ingestion/stepOneDatabase',
    },
    {
      title: 'Table Ingestion',
      description: 'Pick and choose individual tables and columns to ingest',
      //imageUrl: "../../../assets/images/tableimport.svg",
      imageUrl: 'fa-table',
      imageUrlSecond: 'fa-download',
      // link: "/project/" + this.projectName + "/ingest/table-ingestion"
      link:
        '/project/' +
        this.projectName +
        '/ingest/select-ingestion/stepOneCustom',
    },
    {
      title: 'Query Ingestion',
      description: 'Query Databases using standard SQL syntax',
      imageUrl: 'fa-keyboard-o',
      imageUrlSecond: 'fa-download',
      link:
        '/project/' +
        this.projectName +
        '/ingest/select-ingestion/stepOneQuery',
    },

    {
      title: 'Stream Ingestion',
      description: 'Capture stream messages to load into data warehouse',
      imageUrl: 'fa-wifi',
      imageUrlSecond: 'fa-filter',
      link:
        '/project/' +
        this.projectName +
        '/ingest/select-ingestion/stepOneStream',
    },
    // {
    //   title: "Query",
    //   imageUrl: "../../../assets/images/query.png",
    //   link: "/selectdatabase"

    // },

    // {
    //   title: "File",
    //   imageUrl: "../../../assets/images/file.png",
    //   link: "/selectdatabase"
  ];

  deleteData() {
    localStorage.removeItem('stepOne');
    localStorage.removeItem('stepTwo');
    localStorage.removeItem('stepTwoCopy');

    localStorage.removeItem('stepOneStream');
    localStorage.removeItem('stepTwoStream');

    localStorage.removeItem('stepOneQuery');
    localStorage.removeItem('stepTwoQuery');
    localStorage.removeItem('stepThreeQuery');
    localStorage.removeItem('querySQLStatement');
  }
  ngOnInit(): void {
    setTimeout(() => (this.load = false), 500);
  }
}

import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import { UserService } from '../../services/users/user.service';
import { FormControl, NgForm, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { CustomValidators } from '../../validation/custom-validators';

declare var $: any;


@Component({
  selector: 'app-user-landing-page',
  templateUrl: './user-landing-page.component.html',
  styleUrls: ['./user-landing-page.component.scss']
})
export class UserLandingPageComponent implements OnInit, AfterViewInit {


  constructor(
    public UserService: UserService,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
  ) { }

  load: boolean = true;
  userList: any;
  user: any = [];
  page = 1;
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  ngAfterViewInit() {
    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);
    });
  }
  newUser = this.formBuilder.group({
    username: ["", Validators.required],
    email: ["", Validators.required],
    password: ["", Validators.compose([
      Validators.required,
      CustomValidators.patternValidator(/\d/, { hasNumber: true }),
      CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
      CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
      CustomValidators.patternValidator(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, { hasSpecialCharacters: true }),
      Validators.minLength(8)])
    ],
    role: this.formBuilder.group({
      name: ["", Validators.required]
    }),
    enabled: ["", Validators.required]
  })


  userModalForEdit = this.formBuilder.group({
    id: [""],
    username: ["", Validators.required],
    email: ["", Validators.required],
    password: ["", Validators.compose([
      Validators.required,
      CustomValidators.patternValidator(/\d/, { hasNumber: true }),
      CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
      CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
      CustomValidators.patternValidator(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, { hasSpecialCharacters: true }),
      Validators.minLength(8)])
    ],
    role: this.user.role,
    permissions: this.user.permissions,
    enabled: this.user.enabled,
    accountNonExpired: this.user.accountNonExpired,
    credentialsNonExpired: this.user.credentialsNonExpired,
    accountNonLocked: this.user.accountNonLocked

  })

  editPassword = this.formBuilder.group({
    id: [""],
    username: [""],
    email: [""],
    password: ["", Validators.compose([
      Validators.required,
      CustomValidators.patternValidator(/\d/, { hasNumber: true }),
      CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
      CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
      CustomValidators.patternValidator(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, { hasSpecialCharacters: true }),
      Validators.minLength(8)])],
    role: this.user.role,
    permissions: this.user.permissions,
    enabled: this.user.enabled,
    accountNonExpired: this.user.accountNonExpired,
    credentialsNonExpired: this.user.credentialsNonExpired,
    accountNonLocked: this.user.accountNonLocked
  })


  //hide password
  hide = true;

  loggedInUser() {

  }
  createUser(data: any) {
    console.log("data is: ")
    console.log(data.value)
    this.UserService.createUser(data.value).subscribe(res => {
      console.log("new user is: ")
      console.log(res)
      $('#newUserModal').modal().hide();
      this.success("User added")
      this.getAllUsers()
      data.reset();

    })
  }


  getAllUsers() {
    this.UserService.getAllUsers().subscribe(userResponse => {
      console.log("userResponse is:")
      console.log(userResponse)
      this.userList = userResponse
      console.log("userList is: ")
      console.log(this.userList)
    })
  }
  returnStatus(value: boolean): string {
    if (value == true) {
      return "Enabled"
    } else {
      return "Disabled"
    }

  }
  returnStatusColor(value: boolean): string {
    if (value == true) {
      return "text-success"
    } else {
      return "text-danger"
    }

  }


  getUserByUserName(username: string) {
    this.UserService.getUserByUserName(username).subscribe(res => {
      this.user = res
      let data: any;
      data = res
      this.userModalForEdit.patchValue({
        id: this.user.id,
        username: data.username,
        email: data.email,
        password: data.password,
        role: this.user.role,
        permissions: this.user.permissions,
        enabled: this.user.enabled,
        accountNonExpired: this.user.accountNonExpired,
        credentialsNonExpired: this.user.credentialsNonExpired,
        accountNonLocked: this.user.accountNonLocked
      })

      console.log(this.user.role)
      console.log(this.user.permissions)
    })
  }

  editUser(data: any) {
    Swal.fire({
      type: 'warning',

      title: "Are you sure you want to make these updates to this user?",
      // text: 'All information associated with this user will be permanently deleted',
      showCancelButton: true, confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      if (result.isConfirmed == true) {
        this.UserService.updateUser(data.value).subscribe(res => {
          console.log("updated user is: ");
          console.log(res);
          this.getAllUsers();
        })
        $('#editUserModal').modal().hide();

        this.success("User updated")

      }
    })
  }

  userModalForEnable = this.formBuilder.group({
    id: [""],
    username: [""],
    email: [""],
    password: [""],
    enabled: [""],
    role: this.user.role,
    permissions: this.user.permissions
  })

  disableUser(data: any) {
    Swal.fire({
      type: 'warning',

      title: "Are you sure you want to disable this user?",
      // text: 'All information associated with this user will be permanently deleted',
      showCancelButton: true, confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      if (result.isConfirmed == true) {
        this.userModalForEnable.patchValue({
          id: data.id,
          username: data.username,
          email: data.email,
          password: data.password,
          role: data.role,
          permissions: this.user.permissions,
          enabled: false
        })
        console.log(this.userModalForEnable.value);


        this.UserService.updateUser(this.userModalForEnable.value).subscribe(res => {
          console.log("updated user is: ");
          console.log(res);
          this.success("User disabled");
          this.getAllUsers();
        })
      }
    })
  }
  enableUser(data: any) {
    Swal.fire({
      type: 'warning',

      title: "Are you sure you want to enable this user?",
      // text: 'All information associated with this user will be permanently deleted',
      showCancelButton: true, confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      if (result.isConfirmed == true) {
        this.userModalForEnable.patchValue({
          id: data.id,
          username: data.username,
          email: data.email,
          password: data.password,
          role: data.role,
          permissions: this.user.permissions,
          enabled: true
        })

        this.UserService.updateUser(this.userModalForEnable.value).subscribe(res => {
          console.log("updated user is: ");
          console.log(res);
          this.success("User enabled");
          this.getAllUsers();
        })
      }
    })
  }
  updatePassword(data: any) {

    Swal.fire({
      type: 'warning',

      title: "Are you sure you want to update this password?",
      // text: 'All information associated with this user will be permanently deleted',
      showCancelButton: true, confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      if (result.isConfirmed == true) {
        this.editPassword.patchValue({
          id: this.user.id,
          username: this.user.username,
          email: this.user.email,
          password: data.value.password,
          role: this.user.role,
          permissions: this.user.permissions
        })
        // this.updateUser(this.editPassword.value)
        this.UserService.updateUser(this.editPassword.value).subscribe(res => {
          console.log("updated user is: ");
          console.log(res);
          this.success("Update successful");
          this.getAllUsers();
        })
        this.success("Password updated");
        $('#changePasswordModal').modal().hide();




      }
    })
  }


  updateUser(data: any) {
    Swal.fire({
      type: 'warning',

      title: "Are you sure you want to make these updates to this user?",
      // text: 'All information associated with this user will be permanently deleted',
      showCancelButton: true, confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      if (result.isConfirmed == true) {

        this.UserService.updateUser(data).subscribe(res => {
          console.log("updated user is: ");
          console.log(res);
          this.success("Update successful");
          this.getAllUsers();
        })
      }
    })
  }


  deleteUser(username: string) {
    Swal.fire({
      type: 'warning',

      title: "Are you sure you want to delete this user?",
      text: 'All information associated with this user will be permanently deleted',
      showCancelButton: true, confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      if (result.isConfirmed == true) {

        console.log("Username to delete: " + username)
        this.UserService.deleteUser(username).subscribe(res => {
          console.log("Deleted user: ")
          console.log(res)
          this.success("User deleted")
          this.getAllUsers();
        })
      }
    })
  }

  connectionSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      panelClass: ['tertiary-active']

    });
  }

  roleChecker(role: string) {
    if (role == "ROLE_ADMIN") {
      return ("Yes")
    } else {
      return ("No")
    }
  }

  postConnection() {
    // if (this.dataSourceCredentials.value.id == "") {



    this.UserService.createUser(this.newUser.value).subscribe(response => {

      this.success("New User created")
    });




  }

  openSnackBar(status: string, statusLogo: string) {
    this.snackBar.open(status, statusLogo, {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['tertiary-active']

    });
  }
  success(status: string) {
    Swal.fire({
      type: 'Success',
      title: 'Success!',
      text: status,
      confirmButtonText: 'Ok',
    })
  }

  ngOnInit() {
    this.getAllUsers();
    setTimeout(() => this.load = false, 500)
  }

}

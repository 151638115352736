
import { Injectable } from '@angular/core';
import { Config } from '../../../config';
import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RdbmsConnector } from "../../../models/rdbms/rdbms-connector.model"
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Injectable({
  providedIn: 'root'
})
export class StreamingdataSourceService {

  static url = Config.url;


  constructor(private httpClient: HttpClient) { }

  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  // getStreamDataSourceCount() {
  //   return this.httpClient.get(StreamingdataSourceService.url + "/source/stream/count")
  //     .pipe(
  //       retry(1),
  //       // catchError(this.processError)
  //     )
  // }

  getAllStreamDataSourcesByProjectName(name: string): Observable<any[]> {
    console.log(name)

    return this.httpClient.get<RdbmsConnector[]>(StreamingdataSourceService.url + "/source/stream/project/" + name)
      .pipe(

        retry(1),
        catchError(this.handleError)

      )
  }

  getSingleStreamDataSource(id: string) {
    return this.httpClient.get<any>(StreamingdataSourceService.url + "/source/stream/" + id)
      .pipe(
        retry(1),
        catchError(this.handleError)

        // catchError(this.processError)
      )
  }

  postStreamDataSource(data: any) {
    return this.httpClient.post(StreamingdataSourceService.url + "/source/stream", (data))
      .pipe(
        retry(1),
        catchError(this.handleError)

        // catchError(this.processError)
      )
  }

  testStreamDataSource(data: any) {
    return this.httpClient.post(StreamingdataSourceService.url + "/source/stream/test", (data))
      .pipe(
        retry(1),
        catchError(this.handleError)

        // catchError(this.processError)
      )
  }
  updateStreamDataSource(data: any) {
    return this.httpClient.put(StreamingdataSourceService.url + "/source/stream", (data))
      .pipe(
        retry(1),
        catchError(this.handleError)

        // catchError(this.processError)
      )
  }

  deleteStreamDataSources(id: string) {
    return this.httpClient.delete(StreamingdataSourceService.url + '/source/stream/' + id)

      .pipe(
        retry(1),
        catchError(this.handleError)

        // catchError(this.processError)
      )

  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    Swal.fire({
      type: 'Failed',

      title: "Failed",
      text: error.error.message,
      confirmButtonText: 'Ok',
    })
    return throwError(errorMessage);
  }



}


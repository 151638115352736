<div class="loginbg"> </div>

<section class="container-fluid position-relative">
  <article class="loginPC">
    <form class="loginc" [formGroup]="form" (ngSubmit)="login()">
      <img class="loginLogo" src="../../../assets/images/qubzn-text-w.png" alt="Ingest" title="Ingest" />
      <input class="loginInput" placeholder="Username" id="user" type="text" formControlName="username" />
      <input class="loginInput" placeholder="Password" id="password" type="password" formControlName="password" />
      <input class="loginBtn" type="submit" name="sub" value="Login">
    </form>

    <div class="textc">
      <div id="errorfield">{{errMsg}} </div>
    </div>

    <img class="cubemove one" src="../../../assets/images/qubzn-icon-w.png" alt="cube image">
    <img class="cubemove two" src="../../../assets/images/qubzn-icon-w.png" alt="cube image">
    <img class="cubemove three" src="../../../assets/images/qubzn-icon-w.png" alt="cube image">
    <img class="cubemove four" src="../../../assets/images/qubzn-icon-w.png" alt="cube image">
    <img class="cubemove five" src="../../../assets/images/qubzn-icon-w.png" alt="cube image">
    <img class="cubemove six" src="../../../assets/images/qubzn-icon-w.png" alt="cube image">
    <img class="cubemove seven" src="../../../assets/images/qubzn-icon-w.png" alt="cube image">
    <img class="cubemove eight" src="../../../assets/images/qubzn-icon-w.png" alt="cube image">
    <img class="cubemove nine" src="../../../assets/images/qubzn-icon-w.png" alt="cube image">
    <img class="cubemove ten" src="../../../assets/images/qubzn-icon-w.png" alt="cube image">
    <img class="cubemove eleven" src="../../../assets/images/qubzn-icon-w.png" alt="cube image">
    <img class="cubemove twelve" src="../../../assets/images/qubzn-icon-w.png" alt="cube image">
  </article>



</section>



<footer class="copyright logincr flexc footer mt-auto" id="qubzfooter">
  <aside class="flexgrow_one copyrightc"> Copyright © 2021 <img src="assets/images/logo-main.svg" />
    <!-- <a href="https://qubz.ust-global.com/" target="_blank">QUBZ</a> -->
    All rights reserved.
  </aside>
  <aside class="versionc flexgrow_one qubzfooter">
    <!-- <img class="qubzimg_footer" src="../../../assets/images/qubzn-text-b.png" alt=""> -->
    <span class="displayinlineb fr"> 1.5.7 </span>
    <span class="qubzimg_footer fr"> </span>
  </aside>
</footer>
<!-- 
</div> -->
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RdbmsIngestionControllerService } from 'src/app/services/api/rdbms-controller/rdbms-ingestion-controller.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { ProjectService } from 'src/app/services/project/project-service.service';
import { IngestionSharingServiceService } from 'src/app/ingestion-sharing-service.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-step-three-query-ingestion',
  templateUrl: './step-three-query-ingestion.component.html',
  styleUrls: ['./step-three-query-ingestion.component.scss'],
})
export class StepThreeQueryIngestionComponent
  implements OnInit, AfterViewChecked
{
  //snackbar

  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  //datasource

  isSubmitShow: boolean = false;

  constructor(
    private cdf: ChangeDetectorRef,
    public service: RdbmsIngestionControllerService,
    public router: Router,
    public projectService: ProjectService,
    private activatedRoute: ActivatedRoute,
    private IngestionsharingService: IngestionSharingServiceService
  ) {}

  id = this.activatedRoute.snapshot.params['id'];

  projectName = this.activatedRoute.snapshot.params['id2'];

  stepOneData = this.IngestionsharingService.getStepOneQueryData();
  stepTwoData = this.IngestionsharingService.getStepTwoQueryData();

  stepThreeForm: FormGroup;

  showDiv = {
    stepone: false,
    steptwo: false,
    stepthree: true,
    stepfour: false,
    stepfive: false,

    stepbtnone: true,
    stepbtntwo: false,
    stepbtnthree: false,
    stepbtnfour: false,
    stepbtnfive: false,

    cancelbtn: false,
    nextbtn: true,
    nextbtntwo: false,
    nextbtnthree: false,
    nextbtnfour: false,

    backbtn: false,
    backbtntwo: false,
    backbtnthree: false,
    backbtnfour: false,
    backbtnfive: false,

    submitbtn: false,
  };

  ngOnInit(): void {
    this.stepThreeForm = new FormGroup({
      databaseName: new FormControl('', [Validators.required]),
      tableName: new FormControl('', [Validators.required]),
      fileType: new FormControl('', [Validators.required]),
      ingestionMode: new FormControl('', [Validators.required]),
      checkColumn: new FormControl(''),
    });

    if (this.IngestionsharingService.getStepThreeQueryData()) {
      const existingStepData =
        this.IngestionsharingService.getStepThreeQueryData();
      this.stepThreeForm.patchValue({
        databaseName: existingStepData.databaseName,
        tableName: existingStepData.tableName,
        fileType: existingStepData.fileType,
        ingestionMode: existingStepData.ingestionMode,
        checkColumn: existingStepData.checkColumn
          ? existingStepData.checkColumn
          : '',
      });

      if (existingStepData.ingestionMode === 'Full load') {
        this.stepThreeForm.controls.checkColumn.setValidators([
          Validators.required,
        ]);
      }
    }
  }

  submitForm(data: any) {
    this.IngestionsharingService.setStepThreeQueryData(data.value);
    this.router.navigate([
      `/project/${this.projectName}/ingest/select-ingestion/stepFourQuery/${this.id}`,
    ]);
  }

  selectFileType(fileTypeValue: string) {
    this.stepThreeForm.patchValue({
      fileType: fileTypeValue,
    });
  }

  selectIngestionMode(ingestionModeValue: string) {
    if (ingestionModeValue !== 'Full Load') {
      this.stepThreeForm.controls['checkColumn'].setValidators([
        Validators.required,
      ]);
    } else {
      this.stepThreeForm.controls['checkColumn'].clearValidators();
      this.stepThreeForm.updateValueAndValidity();
    }

    this.stepThreeForm.patchValue({
      ingestionMode: ingestionModeValue,
    });
  }

  saveConfiguration(data: any) {
    this.IngestionsharingService.setStepThreeQueryData(data.value);
  }

  success(status: string) {
    Swal.fire({
      type: 'Success',
      title: 'Success!',
      text: status,
      confirmButtonText: 'Ok',
    });
  }

  ngAfterViewChecked() {
    this.cdf.detectChanges();
  }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IngestionSharingServiceService } from 'src/app/ingestion-sharing-service.service';
import { CatalogService } from 'src/app/services/api/catalogService/catalog.service';
import { DataSourceControllerService } from 'src/app/services/api/data-source/data-source-controller.service';
import { StreamingdataSourceService } from 'src/app/services/api/data-source/streamingdata-source.service';
import { RdbmsIngestionControllerService } from 'src/app/services/api/rdbms-controller/rdbms-ingestion-controller.service';
import { StreamIngestionControllerService } from 'src/app/services/api/streamController/stream-ingestion-controller.service';
import { ProjectService } from 'src/app/services/project/project-service.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-edit-step-two-query',
  templateUrl: './edit-step-two-query.component.html',
  styleUrls: ['./edit-step-two-query.component.scss']
})
export class EditStepTwoQueryComponent implements OnInit {

  // @ViewChild('#splitByForm')splitBy:ElementRef;
  
  
  
  constructor(private formBuilder: FormBuilder, private dataSourceController: DataSourceControllerService,
    private catalogService: CatalogService, public service: RdbmsIngestionControllerService, public router: Router,
    private activatedRoute: ActivatedRoute,
    public projectService: ProjectService,
    private ingestionService: IngestionSharingServiceService,
    private streamingDataSourceService: StreamingdataSourceService,
    private streamIngestionControllerService: StreamIngestionControllerService,
    private rdbmsIngestionControllerService: RdbmsIngestionControllerService,
    private catalogControllerService: CatalogService,

    private IngestionsharingService: IngestionSharingServiceService,


  ) { }

  showDiv = {
    stepone: true,
    steptwo: true,
    stepthree: false,
    stepfour: false,
    // stepfive: false,

    // stepbtnone: true,
    // stepbtntwo: false,
    // stepbtnthree: false,
    // stepbtnfour: false,
    // stepbtnfive: false,

    // cancelbtn: false,
    // nextbtn: true,
    // nextbtntwo: false,
    // nextbtnthree: false,
    // nextbtnfour: false,


    // backbtn: false,
    // backbtntwo: false,
    // backbtnthree: false,
    // backbtnfour: false,
    // backbtnfive: false,

    // submitbtn: false
  }
  three=false;
  id = this.activatedRoute.snapshot.params['id'];
  sourceId = this.activatedRoute.snapshot.params['id3'];

  projectName = this.activatedRoute.snapshot.params['id2'];
  fieldsSelected: any = [];

  load: boolean = false;
  //ace editor options
  query: any = "";
  queryValidation: boolean;

  options: any = { maxLines: 1000, printMargin: false };

  fields: any;
  arrayItems: any;
  result: [];
  dataSource: any;
  sourceDatabases: any;
  columnOptions: any;
  stepOneInformation = this.ingestionService.getStepOneQueryData();

  carryData() {
    this.router.navigate([`/project/${this.projectName}/ingest/select-ingestion/stepThreeStream/${this.id}/${this.sourceId}`]);

  }

  formForFields = this.formBuilder.group({
    fields: this.formBuilder.array([])

  });

  stepTwoForm = this.formBuilder.group({
    query: ["", Validators.required],
    splitBy: ["", Validators.required],
    columnOptions: [""]
  });

  selectFileType(fileTypeValue: string) {
    this.stepTwoForm.patchValue({
      fileType: fileTypeValue

    })

  }

  onChange(SqlStatement) {
    this.queryValidation = false;
    console.log(SqlStatement)
    if (SqlStatement.includes("$CONDITIONS")) {
      this.stepTwoForm.patchValue({
        query: SqlStatement
      })
      this.queryValidation = true;
    } else {
      this.stepTwoForm.controls['query'].reset()
      this.queryValidation = false;
    }
  }

  submitForm(data: any) {
    this.stepTwoForm.patchValue({
      query: data.value.query,
      splitBy: data.value.splitBy,
      columnOptions: this.columnOptions
    })
    this.router.navigate([`/project/${this.projectName}/ingest/editStepThreeQuery/${this.id}/${this.sourceId}`]);
    this.IngestionsharingService.setStepTwoQueryData(this.stepTwoForm.value);
    this.router.navigate([`/project/${this.projectName}/ingest/editStepThreeQuery/${this.id}/${this.sourceId}`]);
  }

  saveConfiguration(data: any) {
    this.stepTwoForm.patchValue({
      query: data.query,
      splitBy: data.splitBy,
      columnOptions: this.columnOptions
    })
    this.ingestionService.setStepTwoQueryData(this.stepTwoForm);
    this.ingestionService.setQuerySQLStatement(this.query);
  }
  
  success(status: string) {
    Swal.fire({
      type: 'Success',
      title: 'Success!',
      text: status,
      confirmButtonText: 'Ok',
    })
  }

  error(status: string) {
    Swal.fire({
      type: 'Error',
      title: 'Query Not Valid!',
      text: status,
      confirmButtonText: 'Ok',
    })
  }

  testQuery(query: string) {
    console.log(query)
    if(this.queryValidation) {
      this.load = true;
      this.dataSourceController.getQueryInfo(query, this.stepOneInformation.sourceId).subscribe(response => {
        this.columnOptions = response;
        console.log('res',this.columnOptions);
         this.load = false;
      },error=>{
        this.load = false;
        // this.stepTwoForm.controls['splitBy'].setErrors({'invalid': true});
        // this.splitBy.nativeElement.reset();
        this.stepTwoForm.get('splitBy').setValue([]);//when user change the query then splitby column must be selected again 
        // console.log(this.stepTwoForm.get('splitBy').value);
        
        this.columnOptions=[] //setting dropdown option null for wrong query 
        console.log('2',this.stepTwoForm.get('splitBy').value);

        
      })
    } else {
      this.load = false;
      this.error("Make sure SQL query has a where clause with $CONDITIONS in it.")

    }
  }

  fileTypeChangeWarning(status: string) {
    Swal.fire({
      type: 'warning',
      title: 'Warning',
      text: status,
      confirmButtonText: 'Ok',
    })
  }

  ngOnInit(): void {
    this.arrayItems = [];
    this.service.getSingleRdbmsIngestion(this.id).subscribe(response => {
      let data: any = response;
      this.stepTwoForm.patchValue({
        query: data.query,
        splitBy: data.splitBy,
      })
      this.query = data.query;
      this.dataSourceController.getQueryInfo(data.query, this.stepOneInformation.sourceId).subscribe(response => {
        this.columnOptions = response
      })
    })
    this.catalogControllerService.getCatalogforDatabases(this.sourceId).subscribe(response => {
      let data: any = response;
      this.sourceDatabases = data.sourceDatabases;
    })
    this.queryValidation = true;
  }
}

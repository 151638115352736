import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { CdkDragDrop, copyArrayItem, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { CatalogService } from "../../../../../../services/api/catalogService/catalog.service"
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { JsonpClientBackend } from '@angular/common/http';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import { HiveService } from '../../../../../../services/api/hive/hive.service';
import { RdbmsIngestionControllerService } from '../../../../../../services/api/rdbms-controller/rdbms-ingestion-controller.service';

import { DataSourceControllerService } from '../../../../../../services/api/data-source/data-source-controller.service';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { JobControllerService } from "../../../../../../services/api/job/job-controller.service"
import { isTemplateExpression } from 'typescript';
import { SqoopServiceService } from '../../../../../../services/sqoop/sqoop-service.service';
import { rdbmsJob } from '../../../../../../models/job/rdbmsJob.model';
import { LogService } from '../../../../../../services/log/log.service';
import { logging } from 'selenium-webdriver';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
declare var $: any;

interface DatabaseNode {
  sourceDatabaseName: string;
  destinationDatabaseName: string;
  id?: string;
  dataType?: string;
  primaryKey?: boolean;
  tables?: TableNode[];
}

interface TableNode {
  sourceTableName: string;
  destinationTableName: string;
  id?: string;
  dataType?: string;
  incremental: string,
  primaryKey?: boolean;
  columns?: columnNode[];
}

interface columnNode {
  sourceColumnName: string;
  destinationColumnName: string;
  id?: string;
  destinationDataType: string;
  dataType?: string;
  sourceDataType?: string;
  primaryKey?: boolean;
}


interface toggle {
  name: string;
}


@Component({
  selector: 'app-database-view-validation',
  templateUrl: './database-view-validation.component.html',
  styleUrls: ['./database-view-validation.component.scss']
})
export class DatabaseViewValidationComponent implements OnInit {

  ingestion: any = []
  connections: any = []
  routeArray: any = []
  showIcon: boolean;
  isBeingEdited: boolean;
  ingestions: any = [];
  jobs: any = [];
  data: any;
  durationTime: any;
  sqoopJob: any = [];
  rdbmsJob: any = [];
  sqoopList: any = [];
  logMessage: any = [];
  output: any = [];
  logReport: any = [];
  singleSqoop: any = [];
  sqoopCommand: string
  databases: any = []
  tables: any = []
  limit: any = []
  tableInfo: any;
  tableInformationForLimit: any

  resultSet: any = []

  resultSetKeys: any = []
  resultSetValues: any = []
  //drag and drop lists
  public databaseDropList: DatabaseNode[] = [];
  ingestionDropList: DatabaseNode[] = [];

  Id = this.activatedRoute.snapshot.params['id'];
  projectName = this.activatedRoute.snapshot.params['id2'];

  // sqoopJobId: any = this.activatedRoute.snapshot.queryParams.data;
  sqoopJobId: any;

  dataSource = new MatTreeNestedDataSource();
  toggle: any = [];
  currentLogId: string;
  sqoopReport: any = [];
  ingestObject: any = [];
  page = 1;


  constructor(
    private rdbmsService: RdbmsIngestionControllerService,
    private hiveService: HiveService, public activatedRoute: ActivatedRoute, private router: Router,
    private dataSourceControllerService: DataSourceControllerService,
    public service: JobControllerService,
    public sqoopService: SqoopServiceService,
    public logService: LogService,
    private formBuilder: FormBuilder
  ) { }



  getIngestionById(id: string) {
    this.rdbmsService.getSingleRdbmsIngestion(id).subscribe(res => {
      this.ingestObject = res


      for (let i = 0; i < this.ingestObject.databases.length; i++) {
        this.databases.push(this.ingestObject.databases[i])
        for (let j = 0; j < this.ingestObject.databases[i].tables.length; j++) {
          // this.tables.push({ sourceDatabaseName: this.ingestObject.databases[i].sourceDatabaseName, destinationDatabaseName: this.ingestObject.databases[i].destinationDatabaseName, tables: this.ingestObject.databases[i].tables[j] })
          this.tables.push({ sourceDatabaseName: this.ingestObject.databases[i].sourceDatabaseName, destinationDatabaseName: this.ingestObject.databases[i].destinationDatabaseName, tables: this.ingestObject.databases[i].tables[j] })

          this.tables.destinationDatabaseName = this.ingestObject.databases[i].destinationDatabaseName
          this.tables.tables = this.ingestObject.databases[i].tables[j]


        }

      }
      console.log(this.tables)

    })

  }

  // createDatabases(database: any) {
  //   let table = []
  //   console.log(database)
  //   for (let i = 0; i < database.length; i++) {
  //     table.push(database.database[i])
  //   }
  //   return table
  // }

  query = this.formBuilder.group({
    database: [""],
    table: [""],
    limit: ["", Validators.required],
  })

  setDatabase(database: string) {
    this.query.patchValue({
      database: database
    })
    console.log(this.query.value.database)
  }

  async setTable(table: string) {
    this.query.patchValue({
      table: table
    })
    let tableInformation = await this.hiveService.getTableInformation(this.query.value.database.toLowerCase(), this.query.value.table.toLowerCase()).toPromise();
    let data: any;
    data = tableInformation
    console.log(data.rowCount)
    this.tableInformationForLimit = data.rowCount
    this.limit = []
    for (let i = 50; i < data.rowCount; i += 50) {
      this.limit.push({ "limit": i })
    }
    // if (data.rowCount > 500 && data.rowCount < 5000) {
    //   for (let i = 50; i < data.rowCount; i += 50) {
    //     this.limit.push({ "limit": i })
    //   }
    // } else if (data.rowCount > 5000) {
    //   for (let i = 200; i < data.rowCount; i += 200) {
    //     this.limit.push({ "limit": i })
    //   }
    // } else {
    //   for (let i = 20; i < data.rowCount; i += 20) {
    //     this.limit.push({ "limit": i })
    //   }
    // }
  }

  setLimit(limit: number) {
    this.query.patchValue({
      limit: limit
    })
  }

  async postQuery() {


    let response = await this.hiveService.getAllHiveRowsWithLimits(this.query.value.database.toLowerCase(), this.query.value.table, this.query.value.limit).toPromise()
    let data: any = response
    this.resultSet = data.resultSet
    this.resultSetKeys = []
    this.resultSetValues = []
    let emptyArrayOfKeys = [];
    let emptyArrayOfValues = [];
    let tableInformation = await this.hiveService.getTableInformation(this.query.value.database.toLowerCase(), this.query.value.table.toLowerCase()).toPromise();
    this.tableInfo = tableInformation

    for (let i = 0; i < Object.keys(this.resultSet[0]).length; i++) {

      emptyArrayOfKeys = Object.keys(this.resultSet[i])
      this.resultSetKeys.push({ key: emptyArrayOfKeys[i] })


    }
    for (let j = 0; j < this.resultSet.length; j++) {

      emptyArrayOfValues = Object.values(this.resultSet[j])
      this.resultSetValues.push(emptyArrayOfValues)
    }

    console.log(this.resultSetKeys)

    // for (let j = 0; j < this.resultSet.length; j++) {

    //   emptyArrayOfKeys = Object.keys(this.resultSet[j])
    //   this.resultSetValues.push({ key: emptyArrayOfKeys[j] })
    //   this.resultSetValues.push(this.resultSet[j])
    //   console.log(this.resultSet)
    // }
  }

  getLimitforRows() {

    for (let i = 50; i < 1000; i += 50) {
      this.limit.push({ "limit": i })
    }
  }

  ngOnInit(): void {
    this.getAllConnections();
    this.getIngestionById(this.Id)
    let datemaker = new Date(0)
    datemaker.setUTCSeconds(1616623753369)
    this.getLimitforRows()
  }


  getAllConnections() {
    this.service.getJobs().toPromise()
      .then(response => {
        this.data = response;
        this.data.sort((a: any, b: any) => (a.name < b.name) ? 1 : -1)
      }
      )
  }


  showDiv = {
    stepone: false,
    steptwo: false,
    stepthree: true
  }

}

<!-- <div *ngIf="load" class="">
    <div class="loader"></div>
    <figure class="spinner-border loaderico"></figure>
</div> -->
<div class="cm-content-blocks">
  <ol class="breadcrumb">
      <li><a routerLink="/project/{{projectName}}"><i class="fa fa-home"></i>Home</a></li>
      <li><a routerLink="/project"><i class="fa fa-folder-open"></i>Projects</a></li>
      <li><a routerLink="/project/{{projectName}}"><i class="fa fa-folder"></i>{{projectName}}</a></li>
      <li><a routerLink="/project/{{projectName}}/data-sources"><i class="fa fa-database"></i>Data Sources</a></li>
      <li  class="active"><i class="fa fa-wrench mr-1"></i>Configure Streaming</li>  

  </ol>
</div>

<div class="clearafter headbtnc">
  <h3 class="headc">
    Streaming Configuration
    <div class="subhead">Configure streaming databases</div>
  </h3>
  <!-- <div class="create-btn-position headbtnc">
      <button class="mr-btn-back pull-right pointerc"  routerLink="/project/{{[projectName]}}/data-sources">Back to Data Sources</button>
  </div> -->
</div>

<div class="menublock">
  <!-- steps -->
  <div class="progresslic clearafter " >
      <div class="fifteen_pb pb-0">
          <ul class="progressbar clearfix">
            
              <li class="tabli active pointerc" 
              [ngClass]="showDiv.stepone === true ? 'active':'disabled' " 
              
              >
              <section class="tabbtn">
                  <section class="clearfix relativepos">
                  <aside class="absoultepos stipico">
                      <i class="fa fa-info-circle" aria-hidden="true"></i>
                  </aside>
                  <aside class="steptxtc">
                      <div class="steptitle">Kafka Config</div>
                      <!-- <div class="stepdes mt-1">Select data source for ingestion</div>  -->
                  </aside>
                  </section>
              </section>
              </li>
            
              <li class="tabli pointerc"
              [ngClass]="showDiv.steptwo === true ?'active':'disabled' "
             
              >
                  <section class="tabbtn">
                      <section class="clearafter relativepos">
                      <aside class="absoultepos stipico">
                      <i class="fa fa-database" aria-hidden="true"></i>
                      </aside>
                      <aside class="steptxtc">
                        <div class="steptitle">Message Details</div>
                        <div class="stepdes">Configure incoming message details</div>
                      </aside>
                      </section>
                  </section>
              </li>        
              
      
              <!-- <li class="tabli pointerc"
              [ngClass]="showDiv.stepfive === true ?'active':'disabled' " 
              (click)="showDiv.stepone = false;
              showDiv.steptwo = false; 
              showDiv.stepthree = false;
              showDiv.stepfour = false;
              showDiv.stepfive = true;

              showDiv.backbtn = false;
              showDiv.backbtntwo = false;  
              showDiv.backbtnthree = false;  
              showDiv.backbtnfour = false;  
              showDiv.backbtnfive = true;  
 
              showDiv.submitbtn = false; 
              showDiv.nextbtn = false;
              showDiv.nextbtntwo = false; 
              showDiv.nextbtnthree = false; 
              showDiv.nextbtnfour = false; 
              showDiv.cancelbtn = false"
              >
                  <section class="tabbtn">
                      <section class="clearfix relativepos">
                      <aside class="absoultepos stipico">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                      </aside>
                      <aside class="steptxtc">
                          <div class="steptitle">Advanced Configuration</div>
                          <div class="stepdes">Description</div>
                      </aside>
                      </section>
                  </section>
              </li>
               -->
              
              
          </ul>
        </div>
        <!-- tabe content-->
 
        <section class="tabc_config" >
          <!-- page content -->
          <form [formGroup]="kafkaForm"  (ngSubmit)="submitDataSource(kafkaForm)">            
            <div class="stepone_c" *ngIf="showDiv.stepone">
              <div class="subtitle"></div>
              <div class="fifteenmb">
               
                  <section class="inputc">

                      <aside class="col-md-6">
                          <div class="subtitle zeromtimp">JSON Parsing</div>
                          <div class="">
                            <div class="">
                              <div class="label-asterisk tenmb">Need to input streaming source record here.</div>
                              <!-- <div class='has-error'>
                                <small class='help-block' >
                                  Please input streaming JSON source record sample to generate schema.
                                </small>
                                <small class='help-block'>
                                  Source JSON invalid, Please correct your schema and generate again.
                                </small>
                              </div> -->
                              <!-- <form [formGroup]="validate"> -->
                              <div ace-editor [(text)]="jsonCodeSnippet" [mode]="'json'" 
                                [theme]="'eclipse'" [readOnly]="false" [autoUpdateContent]="true" 
                                [durationBeforeCallback]="1000" class ="graybdr" id="json_editor" style="min-height: 180px; width:100%; overflow: auto;"></div>
                                  <small class="danger-s">{{parser(jsonCodeSnippet)}}</small>

                              <!-- </form> -->
                            </div>


                              <div class="fifteenpt">
                                <button class="tertiary-active fr pull-right" (click)="parseJSON()" type="button">Parse</button>
                              </div>
                            </div>
                      </aside>
                      <aside class="col-md-6">
                          <!-- <mat-form-field appearance="fill" class=" mt-2">
                              <mat-label class="label-asterisk tenmb">Destination Table</mat-label>
                              <mat-select>
                                  <mat-option class="mr-4" >
                                  </mat-option>
                                  <a href=""><i class="fa fa-trash" (click)="deleteSavedConnection(item.id)"></i></a> -->  
                              <!-- </mat-select>  
                              </mat-form-field>  -->
                          <table class="table table-borderless mr-table  mt-2">
                              <thead class="mr-table-head">
                                <tr class="mr-table-head-row">
                                  <th>Field</th>
                                  <!-- <th>Column Value</th> -->
                                  <th>Data Type</th>
                                </tr>
                              </thead>
                              <tbody>
                               
                      
                                <tr class="mr-table-body-row" *ngFor="let item of jsonItem; let i = index;" >
                                  <td>{{item.header}}</td>
                                  <!-- <td>{{item.value}}</td> -->

                                  <td>

                                    <mat-form-field appearance="fill" class=" mt-2">
                                      <mat-label>{{item.dataType.toUpperCase()}}</mat-label>
                                      <mat-select >
                                        <mat-option *ngFor="let dataType of dataTypeList" class="mr-4" (click)="setHeaderDataType(i, dataType)" >
                                          {{dataType}}                        
                                        </mat-option>
                                        
                                       </mat-select>  
                                      </mat-form-field>
                                  </td>
                                  <!-- <td>
                                    <div class="icons-container actionthreetd">
                                      <i  class="fa fa-pencil-square-o pointerc" aria-hidden="true"  ngbTooltip="Edit" placement="left"></i>
                                      <i  class="fa fa-trash pointerc" aria-hidden="true" ngbTooltip="Delete" placement="left"></i>
                                      <i  class="fa fa-upload pointerc" aria-hidden="true" ngbTooltip="Upload" placement="left"></i>
                                    </div>
                                  </td> -->
                                </tr>  
                              </tbody>
                             
                            </table>
                           
                      </aside>
                   
                  
                      </section>
                     
             
                   
              </div>
          </div>
    
    
      
        
          <!-- footer-->
          <footer class="tenpt lightgraybdrt clearafter footerbtns">
              <button  class="tertiary-active fr btnnext" type="submit" [disabled]="jsonItem.length == 0" >
                  Save          
              </button>  
              <button class="grey-btn fr btnpre" routerLink="/project/{{projectName}}/data-sources/configureStreamDataSource/stepOne" type="button">
                  <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
                </button>
      

              <!-- <button class="tertiary-active fr btnnext" id="btnnext" *ngIf="showDiv.nextbtnfour"
              (click)="showDiv.stepone = false; 
              showDiv.steptwo =false; 
              showDiv.stepthree = false;
              showDiv.stepfour= false;
              showDiv.stepfive = true;

              showDiv.backbtn = false; 
              showDiv.backbtntwo = false;
              showDiv.backbtnthree = false; 
              showDiv.backbtnfour = true; 
              showDiv.backbtnfive = false;

              showDiv.submitbtn = false; 
              showDiv.nextbtn = false; 
              showDiv.nextbtntwo = false; 
              showDiv.nextbtnthree = false; 
              showDiv.nextbtnfour = false;

              showDiv.cancelbtn = false;">
              Next <i aria-hidden="true" class="fa fa-arrow-right"></i>
              </button>
              
   -->
              <button class="grey-btn fr btnpre"  *ngIf="showDiv.cancelbtn">
              Cancel
              </button>
  
              <button class="tertiary-active fr btnnext" *ngIf="showDiv.submitbtn">
              Submit
              </button>
  
              <button class="grey-btn fr btnpre"  *ngIf="showDiv.backbtn"
              (click)="showDiv.stepone = true; 
              showDiv.steptwo = false; 
              showDiv.stepthree = false;
              showDiv.stepfour = false;
              showDiv.stepfive = false;

              showDiv.backbtn = false; 
              showDiv.backbtntwo = false;
              showDiv.backbtnthree = false; 
              showDiv.backbtnfour= false;
              showDiv.backbtnfive = false;

              showDiv.submitbtn = false; 
              showDiv.nextbtn = true; 
              showDiv.nextbtntwo = false; 
              showDiv.nextbtnthree = false; 
              showDiv.nextbtnfour = false; 

              showDiv.cancelbtn = false;">
                  <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
              </button>
              <button class="grey-btn fr btnpre"  *ngIf="showDiv.backbtntwo"
              (click)="showDiv.stepone = false; 
              showDiv.steptwo = true; 
              showDiv.stepthree = false;
              showDiv.stepfour = false;
              showDiv.stepfive = false;

              showDiv.backbtn = true; 
              showDiv.backbtntwo = false;
              showDiv.backbtnthree = false;
              showDiv.backbtnfour = false;
              showDiv.submitbtn = false; 
              showDiv.nextbtn = false; 
              showDiv.nextbtntwo = true; 
              showDiv.nextbtnthree = false; 
              showDiv.nextbtnfour = false; 

              showDiv.cancelbtn = false;">
                  <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
              </button>
              <button class="grey-btn fr btnpre"  *ngIf="showDiv.backbtnthree"
              (click)="showDiv.stepone = false; 
              showDiv.steptwo = false; 
              showDiv.stepthree = true;
              showDiv.stepfour = false;
              showDiv.stepfive = false;

              showDiv.backbtn = false; 
              showDiv.backbtntwo = true;
              showDiv.backbtnthree = false; 
              showDiv.backbtnfour = false; 
              showDiv.backbtnfive = false; 

              showDiv.submitbtn = false; 
              showDiv.nextbtn = false; 
              showDiv.nextbtntwo = false; 
              showDiv.nextbtnthree = true; 
              showDiv.nextbtnfour = false; 
              showDiv.cancelbtn = false;">
                  <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
              </button>
              <!-- <button class="grey-btn fr btnpre"  *ngIf="showDiv.backbtnfour"
              (click)="showDiv.stepone = false; 
              showDiv.steptwo = false; 
              showDiv.stepthree = false;
              showDiv.stepfour = true;
              showDiv.stepfive = false;

              showDiv.backbtn = false; 
              showDiv.backbtntwo = false;
              showDiv.backbtnthree = true; 
              showDiv.backbtnfour = false; 
              showDiv.backbtnfive = false; 

              showDiv.submitbtn = false; 
              showDiv.nextbtn = false; 
              showDiv.nextbtntwo = false; 
              showDiv.nextbtnthree = false; 
              showDiv.nextbtnfour = true; 
              showDiv.cancelbtn = false;">
                  <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
              </button> -->
              <!-- <button class="grey-btn fr btnpre"  *ngIf="showDiv.backbtnfive"
              (click)="showDiv.stepone = false; 
              showDiv.steptwo = false; 
              showDiv.stepthree = false;
              showDiv.stepfour = false;
              showDiv.stepfive = true;

              showDiv.backbtn = true; 
              showDiv.backbtntwo = false;
              showDiv.backbtnthree = false; 
              showDiv.backbtnfour = true; 
              showDiv.backbtnfive = false; 

              showDiv.submitbtn = false; 
              showDiv.nextbtn = false; 
              showDiv.nextbtntwo = false; 
              showDiv.nextbtnthree = false; 
              showDiv.nextbtnfour = false; 
              showDiv.cancelbtn = false;">
                  <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
              </button>
             -->
          </footer>
        </form>

        </section> 
    
    </div>
    
  </div>

  <div class="modal" id="largeModal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content ">
      
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Large Modal</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <!-- Modal body -->
        <div class="modal-body">
            Modal Body Content
        </div>
        
        <!-- Modal footer -->
        <footer class="modalfooter">
            <button  class="done-btn modalpubtn">Next </button>
            <button type="button" class="done-btn modalpubtn" data-dismiss="modal">Cancel</button>
        </footer>
      </div>
    </div>
</div>





 <div class="cm-content-blocks">
    <ol class="breadcrumb">
        <li><a routerLink="/project/{{projectName}}"><i class="fa fa-home"></i>Home</a></li>
        <li><a routerLink="/project"><i class="fa fa-folder-open"></i>Projects</a></li>
        <li><a routerLink="/project/{{projectName}}"><i class="fa fa-folder"></i>{{projectName}}</a></li>
        <li  class="active"><i class="fa fa-database mr-1"></i>Data Sources</li>  
    </ol>
</div>



<div class="clearafter headbtnc">
  <h3 class="headc">
      Data Sources - {{projectName}}
      <div class="subhead">Select or configure the data sources for this project</div>
  <div class="create-btn-position headbtnc">
        <button class="mr-btn-create pull-right pointerc" routerLink="configureStreamDataSource/stepOne" [hidden] = "isHidden()" (click)="deleteData()">
        Configure Stream
      </button>
      <button class="mr-btn-create pull-right pointerc" routerLink="select-database" [hidden] = "isHidden()">
        Configure RDBMS
      </button>
    </div>
  </h3>

</div>

  <div class="clearafter">
    <!-- search box -->
    <aside class="col-md-4 zeroplimp float-left">
      <div class="searchbarc fifteenpb">
        <input class="search-bar input-field"  type="text"  placeholder="Search by data source name"  [(ngModel)]="term">
        <img class="search-bar-icon" src="../../../assets/images/search.svg">
      </div>
    </aside>
  </div>
  

  <section class="table-ec">

    <div class="table-wrapper position-relative loader_top_adj" >
      <div *ngIf="load" class="">
        <div class="loader"></div>
        <figure class="spinner-border loaderico"></figure>
      </div>
      
      <table class="table table-borderless mr-table" >
        <thead class="mr-table-head">
          <tr class="mr-table-head-row">
            <!-- <th class="idtd"> </th> -->
            <th >Name</th>
            <th >Description</th>
            <th >Data Source Type</th>
            <th class="actiontwotd">Actions</th>
          </tr>
        </thead>
        <tbody  *ngIf="rdbmsConnectorList.length == 0" >
          <tr>
            <td colspan=100% class="text-center">No data sources exist for this project</td>
          </tr>          
        </tbody>
        <tbody *ngIf="rdbmsConnectorList.length > 0" >
          <tr class="mr-table-body-row" 
          *ngFor="let item of rdbmsConnectorList | filter: term | paginate: { id: 'listing_pagination',
        itemsPerPage: 10,
        currentPage: page,
        totalItems: rdbmsConnectorList.length }; let i = index " >
            <!-- <td class="mr-table-pad-left">{{i + 1}}</td> -->
            <td>{{item.name}} </td>
            <td>{{item.description}}</td>
            <td>{{item.databaseType}}</td>
            <td>
              <div class="icons-container">
                <span *ngIf="item.databaseType =='kafka'" class="multiicon_c btm_icon" (click)="testStreamConnection(item)" ngbTooltip="Test Data Source Connection" placement="left">
                  <i class="fa fa-database" aria-hidden="true"></i>
                </span>
                <span *ngIf="item.databaseType !='kafka'" class="multiicon_c btm_icon" (click)="testConnection(item)" ngbTooltip="Test Data Source Connection" placement="left">
                  <i class="fa fa-database" aria-hidden="true"></i>
                </span>
                <i *ngIf="item.databaseType !='kafka'" class="fa fa-eye mb-2" ngbTooltip="View Data Source Details" routerLink= "/project/{{projectName}}/data-sources/view-details/{{item.id}}" placement="left"></i>
                <i *ngIf="item.databaseType =='kafka'" class="fa fa-eye mb-2"   ngbTooltip="View Data Source Details" routerLink= "/project/{{projectName}}/data-sources/view-stream-details/{{item.id}}" placement="left"></i>

                <i  *ngIf="item.databaseType !='kafka'" class="fa fa-pencil mb-2"  data-toggle="modal" ngbTooltip="Edit Data Source" (click)="populateForm2(item.databaseType, item)" placement="left" [hidden] = "isHidden()"></i>

                <i *ngIf="item.databaseType=='kafka'" class="fa fa-pencil mb-2"  data-toggle="modal" ngbTooltip="Edit Data Source" (click)="populateStreamDataSource(item)" placement="left" [hidden] = "isHidden()"></i>
                <i *ngIf="item.databaseType =='kafka'" class="fa fa-trash mb-2"  (click)="deleteSavedStreamConnection(item.id, item.name)" ngbTooltip="Delete Data Source" placement="left" [hidden] = "isHidden()"></i>
                <i *ngIf="item.databaseType !='kafka'" class="fa fa-trash mb-2"  (click)="deleteSavedConnection(item.id, item.name)" ngbTooltip="Delete Data Source" placement="left" [hidden] = "isHidden()"></i>

              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <ngb-pagination *ngIf="rdbmsConnectorList.length > 10" class="pagination float-right" [collectionSize]="rdbmsConnectorList.length" [(page)]="page" aria-label="Default pagination" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>  -->
      <pagination-controls *ngIf="rdbmsConnectorList.length > 10" id="listing_pagination" class="pagination" (directionLinks)="true"
      (pageChange)="page = $event" (autoHide)="true" (responsive)="true" previousLabel="" disabled="true"
      nextLabel="">
  </pagination-controls>
    </div>
  </section>


  <!-- Edit connection Modal -->
  <div class="modal" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title" id="exampleModalCenterTitle">Edit Data Source</h3>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
        </div>
        <form [formGroup]="dataSourceCredentials" (ngSubmit)="updateSavedConnection(dataSourceCredentials.value)" >

        <div class="modal-body">
                <div class="form-group">  
                <input formControlName="id"  type="hidden" class="form-control">
                </div>
                <section class="inputc" >
                  <aside class="col-md-6 fieldc" >
                    <label class="inputlabel">Data Source Name</label>
                    <input class="input-field" type="text" 
                    formControlName="name" 
                    >
                    <!-- <div class="error-mge" *ngIf="dataSourceCredentials.controls['name'].invalid && (dataSourceCredentials.controls['name'].dirty || dataSourceCredentials.controls['name'].touched)" >
                      Data Source Name is required
                  </div> -->

                  </aside>
                  <aside class="col-md-6 fieldc" >
                    <label class="inputlabel">Database Description</label>
                    <input class="input-field" type="text" 
                    formControlName="description">
                    <!-- <div class="error-mge" *ngIf="dataSourceCredentials.controls['description'].invalid && (dataSourceCredentials.controls['description'].dirty || dataSourceCredentials.controls['description'].touched)" >
                      Database Description is required
                  </div> -->
                   
                </aside>
            
               
                </section>
        
                <section class="inputc" >
                    <aside class="col-md-6 fieldc" [ngClass]="dataSourceCredentials.controls['hostName'].touched && dataSourceCredentials.controls['hostName'].errors?'inputc-error':''">
                        <label class="inputlabel">
                          Host Name
                          <span class="text-danger">*</span>
                        </label>
                        <input class="input-field" formControlName="hostName" type="text" >
                        <div class="error-mge" *ngIf="dataSourceCredentials.controls['hostName'].invalid && (dataSourceCredentials.controls['hostName'].dirty || dataSourceCredentials.controls['hostName'].touched)" >
                          Host Name is required
                      </div>
                    </aside>
                    <aside class="col-md-6 fieldc" [ngClass]="dataSourceCredentials.controls['port'].touched && dataSourceCredentials.controls['port'].errors?'inputc-error':''">
                        <label class="inputlabel">
                          Port
                          <span class="text-danger">*</span>
                        </label>
                        <input class="input-field" formControlName="port" type="text" > 
                        <div *ngIf="(dataSourceCredentials.controls['port'].dirty || dataSourceCredentials.controls['port'].touched)">
                          <div class="error-mge mt-2" *ngIf="dataSourceCredentials.controls['port'].errors && dataSourceCredentials.controls['port'].errors.required">
                              Port is required
                          </div>
                          <div class="error-mge mt-2" *ngIf="dataSourceCredentials.controls['port'].errors && dataSourceCredentials.controls['port'].errors.pattern">
                              Invalid port number
                          </div>
                      </div>       
                    </aside>
                </section>
        
                <section class="inputc">
                    <aside class="col-md-6 fieldc" [ngClass]="dataSourceCredentials.controls['username'].touched && dataSourceCredentials.controls['username'].errors?'inputc-error':''">
                        <label class="inputlabel">
                          User Name
                          <span class="text-danger">*</span>

                        </label>
                        <input class="input-field" formControlName="username" type="text" >
                        <div class="error-mge" *ngIf="dataSourceCredentials.controls['username'].invalid && (dataSourceCredentials.controls['username'].dirty || dataSourceCredentials.controls['username'].touched)" >
                          Username is required
                      </div>
                    </aside>
        
                    <aside class="col-md-6 fieldc" [ngClass]="dataSourceCredentials.controls['password'].touched && dataSourceCredentials.controls['password'].errors?'inputc-error':''">
                        <div class="input-group mb-3">
                            <label for="" class="inputlabel">
                              Password
                              <!-- <span class="text-danger">*</span> -->

                            </label>
                            <input type="text" class="form-control input-field"formControlName="password" type="password" [type]="hide ? 'password':'text'" >
                            <!-- <div class="error-mge" *ngIf="dataSourceCredentials.controls['password'].invalid && (dataSourceCredentials.controls['password'].dirty || dataSourceCredentials.controls['password'].touched)" >
                              Password is required
                          </div> -->
                            <div class="input-group-append">
                              <span class="input-group-text bg-light" id="basic-addon2">    
                                  <i class="{{hide?'fa fa-eye-slash':'fa fa-eye'}}" (click)="hide = !hide"></i>
                              </span>
                            </div>
                          </div>
                    </aside>
                </section>

                <section class="inputc">
                  <aside class="col-md-6 fieldc" [ngClass]="dataSourceCredentials.controls['databaseType'].touched && dataSourceCredentials.controls['databaseType'].errors?'inputc-error':''">
                    <label class="inputlabel">
                      Data Source Type
                      <span class="text-danger">*</span>

                    </label>
                    <input class="input-field" type="text" 
                    formControlName="databaseType" [readOnly]="true"
                    >
                    <div class="error-mge" *ngIf="dataSourceCredentials.controls['databaseType'].invalid && (dataSourceCredentials.controls['databaseType'].dirty || dataSourceCredentials.controls['databaseType'].touched)" >
                      Database Type is required
                  </div>
                   
                </aside>
                <aside class="col-md-6 fieldc d-flex justify-content-center">
                <!-- <button type="button" (click)="testConnection(dataSourceCredentials.value)" ngbTooltip="Test Data Source" class="tertiary-active" [disabled]="dataSourceCredentials.invalid">Test Connection</button> -->
                </aside>
                </section>

              </div>

                <footer  class="modalfooter">
                  <button type="button" class="done-btn modalpubtn" data-bs-dismiss="modal">Cancel</button>
                  <button type="button" (click)="testConnection(dataSourceCredentials.value)" ngbTooltip="Test Data Source" class="done-btn modalpubtn" [disabled]="dataSourceCredentials.invalid">Test Connection</button>
                  <button type="submit" class="done-btn modalpubtn" [disabled]="testSuccessful === false">Save</button>
                </footer>
        
      </form>
 

      </div>
    </div>
  </div>

   <!-- Edit db2 connection Modal -->
   <div class="modal" id="exampleModalCenterDb2" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title" id="exampleModalCenterTitle">Edit Data Source</h3>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
        </div>
        <form [formGroup]="dataSourceCredentialForDb2" (ngSubmit)="updateSavedDb2Connection(dataSourceCredentialForDb2.value)" >

        <div class="modal-body">
                <div class="form-group">  
                <input formControlName="id"  type="hidden" class="form-control">
                </div>
                <section class="inputc" >
                  <aside class="col-md-6 fieldc" >
                    <label class="inputlabel">Data Source Name</label>
                    <input class="input-field" type="text" 
                    formControlName="name" 
                    >
                    <!-- <div class="error-mge" *ngIf="dataSourceCredentials.controls['name'].invalid && (dataSourceCredentials.controls['name'].dirty || dataSourceCredentials.controls['name'].touched)" >
                      Data Source Name is required
                  </div> -->

                  </aside>
                  <aside class="col-md-6 fieldc" >
                    <label class="inputlabel">Database Description</label>
                    <input class="input-field" type="text" 
                    formControlName="description">
                    <!-- <div class="error-mge" *ngIf="dataSourceCredentials.controls['description'].invalid && (dataSourceCredentials.controls['description'].dirty || dataSourceCredentials.controls['description'].touched)" >
                      Database Description is required
                  </div> -->
                   
                </aside>
            
               
                </section>
        
                <section class="inputc" >
                    <aside class="col-md-6 fieldc" [ngClass]="dataSourceCredentialForDb2.controls['hostName'].touched && dataSourceCredentialForDb2.controls['hostName'].errors?'inputc-error':''">
                        <label class="inputlabel">
                          Host Name
                          <span class="text-danger">*</span>
                        </label>
                        <input class="input-field" formControlName="hostName" type="text" >
                        <div class="error-mge" *ngIf="dataSourceCredentialForDb2.controls['hostName'].invalid && (dataSourceCredentialForDb2.controls['hostName'].dirty || dataSourceCredentialForDb2.controls['hostName'].touched)" >
                          Host Name is required
                      </div>
                    </aside>
                    <aside class="col-md-6 fieldc" [ngClass]="dataSourceCredentialForDb2.controls['port'].touched && dataSourceCredentialForDb2.controls['port'].errors?'inputc-error':''">
                        <label class="inputlabel">
                          Port
                          <span class="text-danger">*</span>
                        </label>
                        <input class="input-field" formControlName="port" type="text" > 
                        <div *ngIf="(dataSourceCredentialForDb2.controls['port'].dirty || dataSourceCredentialForDb2.controls['port'].touched)">
                          <div class="error-mge mt-2" *ngIf="dataSourceCredentialForDb2.controls['port'].errors && dataSourceCredentialForDb2.controls['port'].errors.required">
                              Port is required
                          </div>
                          <div class="error-mge mt-2" *ngIf="dataSourceCredentialForDb2.controls['port'].errors && dataSourceCredentialForDb2.controls['port'].errors.pattern">
                              Invalid port number
                          </div>
                      </div>       
                    </aside>
                </section>
        
                <section class="inputc">
                    <aside class="col-md-6 fieldc" [ngClass]="dataSourceCredentialForDb2.controls['username'].touched && dataSourceCredentialForDb2.controls['username'].errors?'inputc-error':''">
                        <label class="inputlabel">
                          User Name
                          <span class="text-danger">*</span>

                        </label>
                        <input class="input-field" formControlName="username" type="text" >
                        <div class="error-mge" *ngIf="dataSourceCredentialForDb2.controls['username'].invalid && (dataSourceCredentialForDb2.controls['username'].dirty || dataSourceCredentialForDb2.controls['username'].touched)" >
                          Username is required
                      </div>
                    </aside>
        
                    <aside class="col-md-6 fieldc" [ngClass]="dataSourceCredentialForDb2.controls['password'].touched && dataSourceCredentialForDb2.controls['password'].errors?'inputc-error':''">
                        <div class="input-group mb-3">
                            <label for="" class="inputlabel">
                              Password
                              <!-- <span class="text-danger">*</span> -->

                            </label>
                            <input type="text" class="form-control input-field"formControlName="password" type="password" [type]="hide ? 'password':'text'" >
                            <!-- <div class="error-mge" *ngIf="dataSourceCredentialForDb2.controls['password'].invalid && (dataSourceCredentialForDb2.controls['password'].dirty || dataSourceCredentialForDb2.controls['password'].touched)" >
                              Password is required
                          </div> -->
                            <div class="input-group-append">
                              <span class="input-group-text bg-light" id="basic-addon2">    
                                  <i class="{{hide?'fa fa-eye-slash':'fa fa-eye'}}" (click)="hide = !hide"></i>
                              </span>
                            </div>
                          </div>
                    </aside>
                </section>

                <section class="inputc">
                  <aside class="col-md-6 fieldc" [ngClass]="dataSourceCredentialForDb2.controls['databaseType'].touched && dataSourceCredentialForDb2.controls['databaseType'].errors?'inputc-error':''">
                    <label class="inputlabel">
                      Database Type
                      <span class="text-danger">*</span>

                    </label>
                    <input class="input-field" type="text" 
                    formControlName="databaseType" [readOnly]="true"
                    >
                    <div class="error-mge" *ngIf="dataSourceCredentialForDb2.controls['databaseType'].invalid && (dataSourceCredentialForDb2.controls['databaseType'].dirty || dataSourceCredentialForDb2.controls['databaseType'].touched)" >
                      Database Type is required
                  </div>
                   
                </aside>

                <aside class="col-md-6 fieldc" [ngClass]="dataSourceCredentialForDb2.controls['databaseName'].touched && dataSourceCredentialForDb2.controls['databaseName'].errors?'inputc-error':''">
                  <div class="input-group mb-3">
                      <label for="" class="inputlabel">
                        Database Name
                        <span class="text-danger">*</span>

                      </label>
                      <input type="text" class="input-field"formControlName="databaseName" type="text" >
                      <div class="error-mge" *ngIf="dataSourceCredentialForDb2.controls['databaseName'].invalid && (dataSourceCredentialForDb2.controls['databaseName'].dirty || dataSourceCredentialForDb2.controls['databaseName'].touched)" >
                         Database Name is required
                    </div>
                    </div>
              </aside>

                </section>

              </div>

                <footer  class="modalfooter">
                  <button type="button" class="done-btn modalpubtn" data-bs-dismiss="modal">Cancel</button>
                  <button type="button" (click)="testConnection(dataSourceCredentialForDb2.value)" ngbTooltip="Test Data Source" class="done-btn modalpubtn" [disabled]="dataSourceCredentialForDb2.invalid">Test Connection</button>
                  <button type="submit" class="done-btn modalpubtn" [disabled]="testSuccessful === false" >Save</button>
                </footer>
        
      </form>
 

      </div>
    </div>
  </div>
   <!-- Edit Stream Data Source Modal -->
   <div class="modal" id="exampleModalCenterOracle" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title" id="exampleModalCenterTitle">Edit Data Source</h3>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
        </div>
        <form [formGroup]="dataSourceCredentialForOracle" (ngSubmit)="updateSavedOracleOrDb2Connection(dataSourceCredentialForOracle.value)" >

        <div class="modal-body">
                <div class="form-group">  
                <input formControlName="id"  type="hidden" class="form-control">
                </div>
                <section class="inputc" >
                  <aside class="col-md-6 fieldc" >
                    <label class="inputlabel">Data Source Name</label>
                    <input class="input-field" type="text" 
                    formControlName="name" 
                    >
                    <!-- <div class="error-mge" *ngIf="dataSourceCredentials.controls['name'].invalid && (dataSourceCredentials.controls['name'].dirty || dataSourceCredentials.controls['name'].touched)" >
                      Data Source Name is required
                  </div> -->

                  </aside>
                  <aside class="col-md-6 fieldc" >
                    <label class="inputlabel">Database Description</label>
                    <input class="input-field" type="text" 
                    formControlName="description">
                    <!-- <div class="error-mge" *ngIf="dataSourceCredentials.controls['description'].invalid && (dataSourceCredentials.controls['description'].dirty || dataSourceCredentials.controls['description'].touched)" >
                      Database Description is required
                  </div> -->
                   
                </aside>
            
               
                </section>
        
                <section class="inputc" >
                    <aside class="col-md-6 fieldc" [ngClass]="dataSourceCredentialForOracle.controls['hostName'].touched && dataSourceCredentialForOracle.controls['hostName'].errors?'inputc-error':''">
                        <label class="inputlabel">
                          Host Name
                          <span class="text-danger">*</span>
                        </label>
                        <input class="input-field" formControlName="hostName" type="text" >
                        <div class="error-mge" *ngIf="dataSourceCredentialForOracle.controls['hostName'].invalid && (dataSourceCredentialForOracle.controls['hostName'].dirty || dataSourceCredentialForOracle.controls['hostName'].touched)" >
                          Host Name is required
                      </div>
                    </aside>
                    <aside class="col-md-6 fieldc" [ngClass]="dataSourceCredentialForOracle.controls['port'].touched && dataSourceCredentialForOracle.controls['port'].errors?'inputc-error':''">
                        <label class="inputlabel">
                          Port
                          <span class="text-danger">*</span>
                        </label>
                        <input class="input-field" formControlName="port" type="text" > 
                        <div *ngIf="(dataSourceCredentialForOracle.controls['port'].dirty || dataSourceCredentialForOracle.controls['port'].touched)">
                          <div class="error-mge mt-2" *ngIf="dataSourceCredentialForOracle.controls['port'].errors && dataSourceCredentialForOracle.controls['port'].errors.required">
                              Port is required
                          </div>
                          <div class="error-mge mt-2" *ngIf="dataSourceCredentialForOracle.controls['port'].errors && dataSourceCredentialForOracle.controls['port'].errors.pattern">
                              Invalid port number
                          </div>
                      </div>       
                    </aside>
                </section>
        
                <section class="inputc">
                    <aside class="col-md-6 fieldc" [ngClass]="dataSourceCredentialForOracle.controls['username'].touched && dataSourceCredentialForOracle.controls['username'].errors?'inputc-error':''">
                        <label class="inputlabel">
                          User Name
                          <span class="text-danger">*</span>

                        </label>
                        <input class="input-field" formControlName="username" type="text" >
                        <div class="error-mge" *ngIf="dataSourceCredentialForOracle.controls['username'].invalid && (dataSourceCredentialForOracle.controls['username'].dirty || dataSourceCredentialForOracle.controls['username'].touched)" >
                          Username is required
                      </div>
                    </aside>
        
                    <aside class="col-md-6 fieldc" [ngClass]="dataSourceCredentialForOracle.controls['password'].touched && dataSourceCredentialForOracle.controls['password'].errors?'inputc-error':''">
                        <div class="input-group mb-3">
                            <label for="" class="inputlabel">
                              Password
                              <!-- <span class="text-danger">*</span> -->

                            </label>
                            <input type="text" class="form-control input-field"formControlName="password" type="password" [type]="hide ? 'password':'text'" >
                            <!-- <div class="error-mge" *ngIf="dataSourceCredentialForOracle.controls['password'].invalid && (dataSourceCredentialForOracle.controls['password'].dirty || dataSourceCredentialForOracle.controls['password'].touched)" >
                              Password is required
                          </div> -->
                            <div class="input-group-append">
                              <span class="input-group-text bg-light" id="basic-addon2">    
                                  <i class="{{hide?'fa fa-eye-slash':'fa fa-eye'}}" (click)="hide = !hide"></i>
                              </span>
                            </div>
                          </div>
                    </aside>
                </section>

                <section class="inputc">
                  <aside class="col-md-6 fieldc" [ngClass]="dataSourceCredentialForOracle.controls['databaseType'].touched && dataSourceCredentialForOracle.controls['databaseType'].errors?'inputc-error':''">
                    <label class="inputlabel">
                      Database Type
                      <span class="text-danger">*</span>

                    </label>
                    <input class="input-field" type="text" 
                    formControlName="databaseType" [readOnly]="true"
                    >
                    <div class="error-mge" *ngIf="dataSourceCredentialForOracle.controls['databaseType'].invalid && (dataSourceCredentialForOracle.controls['databaseType'].dirty || dataSourceCredentialForOracle.controls['databaseType'].touched)" >
                      Database Type is required
                  </div>
                   
                </aside>

                <aside class="col-md-6 fieldc" [ngClass]="dataSourceCredentialForOracle.controls['databaseName'].touched && dataSourceCredentialForOracle.controls['databaseName'].errors?'inputc-error':''">
                  <div class="input-group mb-3">
                      <label for="" class="inputlabel">
                        Service
                        <span class="text-danger">*</span>

                      </label>
                      <input type="text" class="input-field"formControlName="databaseName" type="text" >
                      <div class="error-mge" *ngIf="dataSourceCredentialForOracle.controls['databaseName'].invalid && (dataSourceCredentialForOracle.controls['databaseName'].dirty || dataSourceCredentialForOracle.controls['databaseName'].touched)" >
                         Service is required
                    </div>
                    </div>
              </aside>

                </section>

              </div>

                <footer  class="modalfooter">
                  <button type="button" class="done-btn modalpubtn" data-bs-dismiss="modal">Cancel</button>
                  <button type="button" (click)="testConnection(dataSourceCredentialForOracle.value)" ngbTooltip="Test Data Source" class="done-btn modalpubtn" [disabled]="dataSourceCredentialForOracle.invalid">Test Connection</button>
                  <button type="submit" class="done-btn modalpubtn" [disabled]="testSuccessful === false">Save</button>
                </footer>
        
      </form>
 

      </div>
    </div>
  </div>

  <div class="modal" id="exampleModalCenterStream" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title" id="exampleModalCenterTitle">Edit Data Source</h3>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
        </div>
        <form [formGroup]="streamDataSourceForm" (ngSubmit)="updateSavedStreamConnection(streamDataSourceForm.value)" >

        <div class="modal-body">
          
                <div class="form-group">  
                <input formControlName="id"  type="hidden" class="form-control">
                </div>
                <section class="inputc" >
                  <aside class="col-md-6 fieldc" [ngClass]="streamDataSourceForm.controls['name'].touched && streamDataSourceForm.controls['name'].errors?'inputc-error':''">
                    <label class="inputlabel">
                      Name
                      <span class="text-danger">*</span>

                    </label>
                    <input class="input-field" type="text" 
                    formControlName="name" [readOnly]="true"
                    >
                    <div class="error-mge" *ngIf="streamDataSourceForm.controls['name'].invalid && (streamDataSourceForm.controls['name'].dirty || streamDataSourceForm.controls['name'].touched)" >
                      Name is required
                  </div>
                   
                </aside>

                
                  <aside class="col-md-6 fieldc" >
                    <label class="inputlabel">Description</label>
                    <input class="input-field" type="text" 
                    formControlName="description">
                    <!-- <div class="error-mge" *ngIf="dataSourceCredentials.controls['description'].invalid && (dataSourceCredentials.controls['description'].dirty || dataSourceCredentials.controls['description'].touched)" >
                      Database Description is required
                  </div> -->
                   
                </aside>
            
               
                </section>
        
                <section class="inputc" >
                    <aside class="col-md-6 fieldc" [ngClass]="streamDataSourceForm.controls['hostname'].touched && streamDataSourceForm.controls['hostname'].errors?'inputc-error':''">
                        <label class="inputlabel">
                          Host Name
                          <span class="text-danger">*</span>
                        </label>
                        <input class="input-field" formControlName="hostname" type="text" >
                        <div class="error-mge" *ngIf="streamDataSourceForm.controls['hostname'].invalid && (streamDataSourceForm.controls['hostname'].dirty || streamDataSourceForm.controls['hostname'].touched)" >
                          Host Name is required
                      </div>
                    </aside>
                    <aside class="col-md-6 fieldc" [ngClass]="streamDataSourceForm.controls['port'].touched && streamDataSourceForm.controls['port'].errors?'inputc-error':''">
                        <label class="inputlabel">
                          Port
                          <span class="text-danger">*</span>
                        </label>
                        <input class="input-field" formControlName="port" type="text" > 
                        <div *ngIf="(streamDataSourceForm.controls['port'].dirty || streamDataSourceForm.controls['port'].touched)">
                          <div class="error-mge mt-2" *ngIf="streamDataSourceForm.controls['port'].errors && streamDataSourceForm.controls['port'].errors.required">
                              Port is required
                          </div>
                          <div class="error-mge mt-2" *ngIf="streamDataSourceForm.controls['port'].errors && streamDataSourceForm.controls['port'].errors.pattern">
                              Invalid port number
                          </div>
                      </div>       
                    </aside>
                </section>
        
              

                <section class="inputc">
                  <aside class="col-md-6 fieldc" >
                    <label class="inputlabel">Topic</label>
                    <input class="input-field" type="text" 
                    formControlName="topic" 
                    >
                    <div class="error-mge" *ngIf="streamDataSourceForm.controls['topic'].invalid && (streamDataSourceForm.controls['topic'].dirty || streamDataSourceForm.controls['topic'].touched)" >
                      Topic is required
                  </div>

                  </aside>
            
                </section>
                <section class="inputc">
                 
                  <aside class="col-md-12 fieldc" >
                  <table class="table table-borderless mr-table">
                    <thead class="mr-table-head">
                      <tr class="mr-table-head-row">
                        <th>Header</th>
                        <th>Data Type</th>
                        <!-- <th class="actionthreetd">Actions</th> -->
                      </tr>
                    </thead>
                    <tbody *ngFor="let field of streamDataSource; let i = index">
                      <tr class="mr-table-body-row" >
                        <td>
                           
                                {{field.header}}
                        </td>
                        <td>
                            <mat-form-field appearance="fill">
                                <mat-label>{{field.dataType}}</mat-label>

                                <mat-select formControlName="fileType">
                                    <div >
                                        <mat-option *ngFor="let type of dataTypeList" class="mr-4" (click)="setDataType(streamDataSource,i,type)" >
                                          {{type}}                        
        
                                        </mat-option>
                                      </div>
                                      <!-- <div *ngIf="streamDataSourceForm.value.fileType == 'avro' || streamDataSourceForm.value.fileType == 'AVRO'"  >
                                        <mat-option *ngFor="let type of avroDestinationDataTypes" class="mr-4" >
                                          {{type}}                        
        
                                        </mat-option>
                                      </div>
                                      <div *ngIf="streamDataSourceForm.value.fileType == 'parquet' || streamDataSourceForm.value.fileType == 'PARQUET'" >
                                        <mat-option *ngFor="let type of parquetDestinationDataType" class="mr-4" >
                                          {{type}}                        
        
                                        </mat-option>
                                      </div> -->
                                </mat-select>
                              </mat-form-field>
                        </td>
                        <!-- <td>
                          <div class="icons-container actionthreetd">
                            <i  class="fa fa-trash pointerc ml-4" aria-hidden="true" ngbTooltip="Delete" (click)="deleteField(dataSource.fields,i)" placement="left"></i>
                          </div>
                        </td> -->
                      </tr>
            
                    </tbody>
                  
                  </table>
                  </aside>
                </section>
<h1>{{result | json}}</h1>
              </div>

                <footer  class="modalfooter">
                  <button type="button" class="done-btn modalpubtn" data-bs-dismiss="modal">Cancel</button>
                  <!-- <button type="button" (click)="testConnection(streamDataSourceForm.value)" ngbTooltip="Test Data Source" class="done-btn modalpubtn" [disabled]="streamDataSourceForm.invalid">Test Connection</button> -->
                  <button type="submit" class="done-btn modalpubtn">Save</button>
                </footer>
        
      </form>
 

      </div>
    </div>
  </div>

  
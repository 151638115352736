import { Component, OnInit, EventEmitter } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';

import { User } from '../models/user/user.model';
import { Router } from '@angular/router';
import { AdminService } from '../services/admin/admin.service';
import { IngestionSharingServiceService } from '../ingestion-sharing-service.service';
import { UserService } from '../services/users/user.service';
declare var jQuery: any;
declare var setTheme: any;
declare function addPendo():  any;
@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
  form: FormGroup;
  errMsg: string = '';
  public user: User;
  public input1: any;
  config;
  loggedUser;
  timezone;
  currentUser: any;
  public focusSettingEventEmitter = new EventEmitter<boolean>();
  constructor(
    private authService: AuthService,
    fb: FormBuilder,
    private router: Router,
    private adminService: AdminService,
    private ingestionSharingService: IngestionSharingServiceService,
    private userService: UserService
  ) {
    let user = sessionStorage.getItem('loggedUser');
    if (user) {
      this.router.navigateByUrl('');
    }
  }
  //  focusFunction(){
  //   this.errMsg ='';
  //  }
  ngOnInit() {
    this.form = new FormGroup({
      username: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required]),
    });
    this.form.reset();
    this.errMsg = '';
    this.getUser();
  }

  ngAfterViewInit() {
    jQuery('.loginInput').focus(function () {
      jQuery(jQuery(this).addClass('increasewidth'));
    });
    jQuery('.loginInput').focus(function () {
      jQuery(jQuery(this).addClass('increasewidth'));
    });

    jQuery('.loginInput').focusout(function () {
      jQuery(jQuery(this).removeClass('increasewidth'));
    });
    this.focusSettingEventEmitter.emit(true);
  }
  setFocus(): void {
    this.focusSettingEventEmitter.emit(true);
  }



  //here we need initData(value)



  initData(value) {
		//console.log(value.authorities.name)
		let user = {
			username: value.authorities.name, // Required if user is logged in
			emailId: "", // Recommended if using Pendo Feedback, or NPS Email
			firstName: "", // Recommended if using Pendo Feedback
			lastName: "",
			authorities: value.authorities.authorities[0].authority // Optional
		};
		sessionStorage.setItem("loggedUserData", JSON.stringify(user));
		addPendo();
	}

  login() {
    const credentials = {
      username: this.form.value.username,
      password: this.form.value.password,
    };
    sessionStorage.setItem('credentials', JSON.stringify(credentials));

    this.authService.login().subscribe(
      (data) => {
        if (data.hasOwnProperty('authorities')) {
          this.input1 = data;
          this.user = new User(this.input1);
          //console.log(this.user);
          if (1) {
            sessionStorage.setItem('loggedUser', JSON.stringify(this.user));
this.initData(this.user);
            this.router.navigate(['/home']);
            let user = JSON.parse(sessionStorage.getItem('loggedUser'));

            this.userService
              .getUserByUserName(user.authorities.principal.username)
              .subscribe((response) => {
                this.currentUser = response;
                console.log('img');
                console.log(this.currentUser.imageFileName);
                if (this.currentUser.imageFileName != undefined) {
                  this.ingestionSharingService.setAvatarImg(
                    this.currentUser.imageFileName
                  );
                } else {
                  this.ingestionSharingService.setAvatarImg(
                    '../../../assets/images/usericon_other.png'
                  );
                }
              });
          } else {
            this.router.navigate(['']);
          }
        }
      },
      (error) => {
        if (error?.error?.message?.includes('Disabled'))
          this.errMsg = error.error.message;
        else this.errMsg = 'Bad Credentials. Please Check Username/Password';
      }
    );
  }
  getCurrentUser() {
    this.user = JSON.parse(sessionStorage.getItem('loggedUser'));
    this.userService
      .getUserByUserName(this.user.authorities.principal.username)
      .subscribe((response) => {
        this.currentUser = response;
        console.log('img');
        console.log(this.currentUser.imageFileName);

        this.ingestionSharingService.setAvatarImg(
          this.currentUser.imageFileName
        );
      });
  }
  getUser() {
    this.user = JSON.parse(sessionStorage.getItem('loggedUser'));
    this.userService
      .getUserByUserName(this.user.authorities.principal.username)
      .subscribe((response) => {});
  }
  isUserAdmin(authorities) {
    for (let authority of authorities) {
      if (authority['authority'] == 'ROLE_ADMIN') {
        return true;
      }
    }
    return false;
  }
  ping() {
    this.authService.ping().subscribe();
  }

  //   getAuthorities() {
  //     this.authService.authorities(AuthService.url).subscribe();
  //   }
}

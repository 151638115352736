<div class="cm-content-blocks">
  <ol class="breadcrumb">
             <li><a routerLink="/home"><i class="fa fa-home"></i>Home</a></li>
             <li><a routerLink="/project"><i class="fa fa-folder-open"></i>Projects</a></li>
             <li><a routerLink="/project/{{projectName}}"><i class="fa fa-folder"></i>{{projectName}}</a></li>
             <li><a [routerLink]="['/project', projectService.getCurrentProject(), 'data-sources']"><i class="fa fa-database mr-1"></i>Data Sources</a></li>
             <li class="active"><i class="fa fa-sitemap mr-1"></i>Configure Data Sourcess</li>

         </ol>
</div>
  
  <div class="clearafter headbtnc">
    <h3 class="headc">
      Choose Data Source Type 
      <div class="subhead">Create new database connection </div> 
    </h3>
  
  </div>
  
  
  <section class="clearafter row no-gutters fs-container"> 
    <!-- search box -->
    <aside class="col-md-4 zeroplimp float-left">
      <div class="searchbarc fifteenpb">
        <input class="search-bar input-field"  type="text"  placeholder="Search by database name"  [(ngModel)]="term">
        <img class="search-bar-icon" src="../../../assets/images/search.svg">
      </div>
    </aside>
  
    <aside class="col-md-8 text-right">
      <span class="inline-block fivemr">Sort by: </span>
      <label class="radioc">
        <input type="radio" name="inlineRadioOptions" (click)="sortAscending()" id="inlineRadio1" value="option1">
        <span class="checkmark-r"></span>
        <span class="text-r">Ascending</span>
      </label>
      <label class="radioc">
        <input type="radio"  name="inlineRadioOptions" (click)="sortDescending()" id="inlineRadio2" value="option2">
        <span class="checkmark-r"></span>
        <span class="text-r">Descending</span>
      </label>
    </aside>
  </section>
  
  
  <article class="db_boxec NewDataCatalog">
    <div  class=" db_boxc" *ngFor = "let item of items | filter:term"  >
      <a routerLink="{{item.link}}" class="boxlink boxbuttons"> 
      <!-- <a routerLink="databaseSelection" class="boxlink"> -->
        <div  class="db_box menublock">
          <div class="cardico">
            <img src="{{item.imageUrl}}" class="cardimg"/>
          </div>
          <h4 class="cardtitle">{{item.title}}</h4>
        </div>
      </a>
    </div>  
  </article>
              
  
  
  
  
<div class="cm-content-blocks">
  <ol class="breadcrumb">
    <li><a routerLink="/home"><i class="fa fa-home"></i>Home</a></li>
    <li><a routerLink="/project"><i class="fa fa-folder-open"></i>Projects</a></li>
    <li><a routerLink="/project/{{projectName}}"><i class="fa fa-folder"></i>{{projectName}}</a></li>
    <li class="active"><i class="fa fa-wrench"></i>Jobs</li>

  </ol>
</div>

<h3 class="headc">
  Jobs - {{projectName}}
  <div class="subhead">Track or view jobs</div>
</h3>






<!-- <section class="clearafter row no-gutters"> 

        <aside class="col-md-8 text-right">
            <label class="radioc">
                <input type="radio" name="inlineRadioOptions"  id="inlineRadio1" value="option1">
                <span class="checkmark-r"></span>
                <span class="text-r">New</span>
            </label>
            <label class="radioc">
                <input type="radio"  name="inlineRadioOptions" id="inlineRadio2" value="option2">
                <span class="checkmark-r"></span>
                <span class="text-r">Pending</span>
            </label>
            <label class="radioc">
                <input type="radio" name="inlineRadioOptions"  id="inlineRadio1" value="option1">
                <span class="checkmark-r"></span>
                <span class="text-r">Running</span>
            </label>
                <label class="radioc">
                <input type="radio"  name="inlineRadioOptions" id="inlineRadio2" value="option2">
                <span class="checkmark-r"></span>
                <span class="text-r"></span>
            </label>
            <label class="radioc">
                <input type="radio" name="inlineRadioOptions"  id="inlineRadio1" value="option1">
                <span class="checkmark-r"></span>
                <span class="text-r">Finished</span>
            </label>
            <label class="radioc">
                <input type="radio"  name="inlineRadioOptions" id="inlineRadio2" value="option2">
                <span class="checkmark-r"></span>
                <span class="text-r">Error</span>
            </label>
            <label class="radioc">
                <input type="radio" name="inlineRadioOptions"  id="inlineRadio1" value="option1">
                <span class="checkmark-r"></span>
                <span class="text-r">Discarded</span>
            </label>
        </aside>
    </section> -->
<article class="jobs_ec clearafter" [ngClass]="{ expanded: !expand, collapsed: !collapse }">


  <!--------------------------------------- Left Side Table Starts ------------------------------------------------------>

<section class="menublock position-relative left_c">
  <section class="clearafter row no-gutters">
    <!-- search box -->
    <aside class="col-4 zeroplimp ">
      <div class="searchbarc fifteenpb">
        <input class="search-bar input-field" type="text" placeholder="Search jobs..." [(ngModel)]="term" (ngModelChange)="hidePagination(term)">
        <img class="search-bar-icon" src="../../../assets/images/search.svg">
      </div>
       
    </aside>
    <aside class="col-8 text-right clearafter">
      <aside class="tenml twomt inline-block float-right">
        <button class="iconbtn jobrefresh" placement="left" ngbTooltip="Refresh" container="body" (click)="refresh()">
          <i class="fa fa-refresh" aria-hidden="true"></i>
        </button>
      </aside>
  
      <div class="inline-block float-right tenmt">
        <span class="fivemr"></span>
        <span class=" fivemr">Sort by: </span>
        <label class="radioc">
          <input type="radio" name="inlineRadioOptions" (click)="sortDescending(data)" id="inlineRadio2" value="option2" checked>
          <span class="checkmark-r"></span>
          <span class="text-r">Newest</span>
        </label>
        <label class="radioc">
          <input type="radio" name="inlineRadioOptions" (click)="sortAscending(data)" id="inlineRadio1" value="option1">
          <span class="checkmark-r"></span>
          <span class="text-r">Oldest</span>
        </label>
      </div>
      
    </aside>
    <div class="d-flex w-100 job-filter">
      <div class="d-block tenmt" >
        <span *ngFor="let option of filterOptions" class="d-inline-flex ml-0">
          <label  class="radioc loopRadio">
            <input type="radio" name="filterRadio" class="" (click)="ingestJobFilterig(option.name)" [checked]="option.name=='All'" />
            <span  class="checkmark-r"></span>
           </label>
           <div class="radiolable"> 
          <span> {{filterOptionNameChange(option.name)}}</span><span *ngIf="option.name !='All'" class="mr-0 ml-0">({{option.value}})</span>
          </div>
        </span>
     </div>
    </div>

  </section>
 
    <div class="table-wrapper position-relative loader_top_adj">
      <div *ngIf="load" class="">
        <div class="loader"></div>
        <figure class="spinner-border loaderico"></figure>
      </div>
      <div *ngIf="refreshStatus" class="">
        <div class="loader"></div>
        <figure class="spinner-border loaderico"></figure>
      </div>
      
      <table class="table table-borderless mr-table">
        <thead class="mr-table-head">
          <tr class="mr-table-head-row">
            <th>
             Name
              <!-- <i class="fa fa-angle-down" (click)="sortByName(data)" aria-hidden="true"></i> -->

            </th>
            <th>
              Type
               <!-- <i class="fa fa-angle-down" (click)="sortByName(data)" aria-hidden="true"></i> -->
 
             </th>

            <th class="medtd">
              <span class="sorticon-c"><span class="sorttext medtd-span">Start Time</span></span>
            </th>
            <th class="medtd">
              <span class="sorticon-c"><span class="sorttext medtd-span">Finish Time</span></span>
            </th>
            <th>
             Duration
            </th>
            <th>
             Progress
            </th>
            <th>Status</th>

            <th class="actionthreetd">Actions</th>
          </tr>
        </thead>
        <tbody *ngIf="filteredJobs.length === 0">
          <tr>
            <td colspan=100% class="text-center">No jobs exist for this project</td>
          </tr>
        </tbody>
        <tbody *ngIf="filteredJobs.length > 0 ">
          <tr class="mr-table-body-row" *ngFor="let item of filteredJobs | filter: term | paginate: { id: 'listing_pagination',
          itemsPerPage: 5,
          currentPage: page,
          totalItems: filteredJobs.length }; let i = index  " (click)="onClickExpand(item)">
            <td>
              <div class=" medtd breakword break-all">
                <span>{{item.name}}</span>
              </div>
            </td>
            
            <td>
              <div class=" medtd breakword break-all">
                <span>{{item.ingestionType}}</span>
              </div>
            </td>


            <td>
              <div class="medtd breakword break-all">{{dateFormatter(item.createdAt)}} </div>
            </td>
            <td>
              <div class=" medtd breakword break-all" *ngIf="item.finishedAt ==  0 ">N/A</div>
              <div class=" medtd breakword break-all" *ngIf="item.finishedAt >  0">{{dateFormatter(item.finishedAt)}}
              </div>
            </td>
            <td>
              <div class=" medtd breakword break-all" *ngIf="item.duration ==  0 ">N/A</div>
              <div class=" medtd breakword break-all" *ngIf="item.duration >  0">{{miliConvert(item.duration)}} </div>
            </td>
            <td *ngIf="item.type !='Stream'">
              <div class="progress">
                <div class="progress-bar" [class.progress-bar-success]="item.progress === 100"
                  [class.progress-bar-info]="item.progress < 100"
                  [class.progress-bar-danger]="item.progress < 100 && item.status ==='FAILED_TO_LAUNCH' "
                  [class.progress-bar-danger]="item.progress <= 100 && item.status ==='FAILED'"
                  [class.progress-bar-warning]="item.progress < 100 && item.status ==='DISCARDED'"
                  [class.progress-bar-.primary]="item.progress < 100 && item.status ==='NOT_LAUNCHED'"
                  role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"
                  [style.width.%]="item.progress==0?3:item.progress">
                  {{ item.progress| number: '.0-0' }}%
                </div>
              </div>
            </td>
            <td *ngIf="item.type =='Stream'">
              <span>N/A</span>
            </td>
            <td>
              <div>
                <span
                  [class.info-s]='item.status === "RUNNING" || item.status === "IN_PROGRESS" || item.status === "STARTING"'
                  [class.warning-s]='item.status === "DISCARDED"' [class.success-s]='item.status === "FINISHED"'
                  [class.danger-s]='item.status === "FAILED" || 
                  item.status === "FAILED_TO_LAUNCH" || 
                  item.status === "IN_PROGRESS_WITH_ERRORS"  || 
                  item.status === "STOPPED" || item.status === "STOPPING"'
                  [class.primary-s]='item.status==="NOT_LAUNCHED"'>{{ getJobStatus(item) }}</span>
              </div>
            </td>
            <td>
              <!-- <a  class="mr-2 text-dark"  *ngIf="item.status == 'Finished' "  ><i  class="fa fa-check fa-1x" ></i></a>
                            <a  class="mr-2 text-dark"  *ngIf="item.status == 'Failed' "  ><i class="fa fa-times fa-1x" ></i></a> -->
              <div class="icons-container" *ngIf="item.type !='Stream'">
                <!-- <i class="fa fa-eye"
                  routerLink="/project/{{item.projectName}}/ingest/databaseViewDetails/{{item.rdbmsIngestionId}}"
                  placement="left" ngbTooltip="View Details" [hidden]="isHidden()"></i> -->
                    <!-- Resume Job -->
                  <i (click)="resumeJob(item.id, i, item.name);" class="fa fa-play fa-1x"
                  *ngIf="item.status == 'STOPPED' " placement="left" ngbTooltip="Resume Job" [hidden]="isHidden()"></i>
                <!-- Discard Job -->
                  <i (click)="discard(item.id, item.name)" class="fa fa-times fa-1x" *ngIf="item.status == 'STOPPED' "
                  placement="left" ngbTooltip="Discard Job" [hidden]="isHidden()"></i>
                  <!-- Pause Job -->
                <i (click)="pause(item.id, item.name)" class="fa fa-stop fa-1x"
                  *ngIf="item.status == 'IN_PROGRESS' || item.status == 'IN_PROGRESS_WITH_ERRORS'" placement="left"
                  ngbTooltip="Stop job" [hidden]="isHidden()"></i>
                  <!-- Delete Job -->
                <i (click)="deleteJobById(item.id, item.name)" class="fa fa-trash fa-1x"
                  *ngIf="item.status == 'FINISHED' || item.status == 'FAILED' || item.status == 'DISCARDED'"
                  placement="left" ngbTooltip="Delete job" [hidden]="isHidden()"></i>
                <i class="fa fa-chevron-right right_arrow" (click)="expand = !expand" placement="left"
                  ngbTooltip="Collapse Steps Panel"></i>
                <i class="fa fa-chevron-left left_arrow" (click)="
                                        expand = !expand;onClickExpand(item)
                                    " placement="left" ngbTooltip="Expand Steps Panel"></i>
              </div>
              
              <!-- <i (click)="refresh()" class="fa fa-refresh text-dark"></i> -->
            </td>
          </tr>

        </tbody>
      </table>
      <footer class="clearafter">
        <div class="d-flex w-100">
          <!-- <mat-paginator 
              class="pagination ml-auto"
              [length]="data.length"
              [pageSize]="2"
              [pageSizeOptions]="[2, 3, 5, 10]"
              (page)=" onPageChange($event)"
              aria-label="Select page">
</mat-paginator> -->
      
     <div class="pagination ml-auto" *ngIf="pagination==true">
      <pagination-controls *ngIf="filteredJobs.length > 1 && !term" id="listing_pagination" class="pagination ml-auto" (directionLinks)="true"
        (pageChange)="page = $event" (autoHide)="true" (responsive)="true" previousLabel="" disabled="true"
        nextLabel="">
      </pagination-controls>
     </div>
    </div>
    </footer>
      <!-- <ngb-pagination *ngIf="data.length > 10" class="pagination float-right" [collectionSize]="data.length" [(page)]="page" aria-label="Default pagination" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>  -->

      <!-- <div class="">
                <div class="loader"></div>
                <figure class="spinner-border loaderico"></figure>
            </div> -->

    </div>
  
</section>

 <!---------------------------------------------------- Left Side Table  Ends ------------------------------------>


  <!--------------------------------------------------- Right Side Table Starts ---------------------------------------------------->
  
  <div class="menublock right_c relativepos">
    <i class="fa fa-angle-double-left btn-mr" (click)="collapse = !collapse" placement="left"
      ngbTooltip="Expand Steps Panel"></i>
    <i class="fa fa-angle-double-right btn-ml" (click)="collapse = !collapse" placement="right"
      ngbTooltip="Collapse Steps Panel"></i>
    <i class="fa fa-times falink hidepu-btn" (click)="expand = !expand" placement="left"
      ngbTooltip="Close Steps Panel"></i>


    <div class="tenpb clearafter flex-style" *ngIf="!showJobDetails">
      <!-- <div class="innrcaption subtitle">
       job Name: {{ingestionType}}
      </div> -->
      <div class="displayinlineb tenpr">
        <table  style="text-transform: uppercase;">
          <tr><span class="bold">Job Name:</span> {{ingestionName}} </tr>
          <tr><span class="bold">Ingestion Type : </span> {{ingestionType}}</tr>
          <tr><span class="bold">Start Time : </span><span>{{dateFormatter(startTime)}}</span></tr>
        </table>
        <div class="d-flex w-100 mt-3" *ngIf="filterCheck">      
          <div class="searchbarc">
          <input class="search-bar input-field" type="text" placeholder="Search jobs..." [(ngModel)]="Sqoopterm">
          <img class="search-bar-icon" src="../../../assets/images/search.svg">
          
          </div>
            <!-- Filter pannel for Non Kafka -->
        <div class="ml-2" >
          <select  [(ngModel)]="selectedStatus" (click)="sqoopJobFiltering(selectedStatus)" class="selectoption ng-untouched ng-pristine ng-invalid">
            <option >ALL </option>
            <option *ngFor="let option of sqopFilterOptions">
              <span>{{filterOptionNameChange((option.name))}} ({{option.value}}) </span>
            </option>
          </select>
        </div>
        <!------------------------ Ends Filter pannel for Non Kafka---------------------->
        <!-- <div class="ml-2" *ngIf ="showStreamJob">
          <select [(ngModel)]="selectedStatus" (change)="valueSelected(selectedStatus)" class="selectoption ng-untouched ng-pristine ng-invalid">
            <option>All </option>
            <option *ngFor="let option of sqopFilterOptions">
              <span>{{option.name}} ({{option.value}}) </span>
            </option>
          </select>
        </div> -->
      </div>
  
      </div>
      

      <aside class="tenml twomt inline-block float-right" style="margin-left: auto;">
        <!-- <button class="iconbtn jobrefresh" placement="left" ngbTooltip="Refresh" container="body"
          (click)="refresh()">
          <i class="fa fa-refresh" aria-hidden="true"></i>
        </button> -->
      </aside>
    </div>

    <div class="position-relative clearafter loader_top_adj">
      <div class="" *ngIf="showJobDetails">
        <div class="loader"></div>
        <figure class="spinner-border loaderico"></figure>
      </div>
      <!-- Database / Query / Table ingestion starts -->
      <table class="table table-borderless mr-table" *ngIf="!showStreamJob">
        <thead class="mr-table-head">
          <tr class="mr-table-head-row">
            <th class="medtd">
              <span class="sorticon-c">
                <span class="sorttext medtd-span" *ngIf="!queryCheck">Source Table</span>
                <span class="sorttext medtd-span" *ngIf="queryCheck">Query Destination</span>
              </span>
              </th>
            <!-- <th  class="nametd-sml medtd" *ngIf="!ingestObject.query || ingestObject.query == null">Source Table</th> -->
            <!-- <th  class="nametd-sml medtd" *ngIf="ingestObject.query || ingestObject.query != null">Query Destination</th> -->
            <th class="medtd">Duration</th>
            <th>Row Count</th>
            <th>Progress</th>
    <th class="statustd"><span class="sorticon-c"><span class="sorttext medtd-span">Status</span></span></th>

            <th class="actiontwotd sizetd">Actions</th>
          </tr>
        </thead>
        <tbody  *ngIf="filteredSqoopjob.length === 0" >
          <tr>
            <td colspan=100% class="text-center">NO <span *ngIf="selectedStatus !='ALL'">{{statusParam}}</span> JOB</td>
          </tr>          
        </tbody>
        <tbody *ngIf="filteredSqoopjob && !showJobDetails">
          <tr class="mr-table-body-row" *ngFor="let item of filteredSqoopjob| filter: Sqoopterm | paginate: { id: 'listing_pagination_sqoopjob',
          itemsPerPage: 4,
          currentPage: rdbmsSqoopPage,
          totalItems: filteredSqoopjob.length }; let i = index ">
            <!-- <td>{{checkforNull(ingestions.runHistory)}}</td> -->
            <td *ngIf="!queryCheck">
              <div class="d medtd breakword break-all">{{checkforNull(item.database.tables[0].sourceTableName)}} </div>
            </td>
            <td *ngIf="queryCheck">
              <div class=" medtd breakword break-all">{{checkforNull(item.database.destinationDatabaseName)}} </div>
            </td>
            <!-- <td>
              <div class="statustd">{{checkforNull(item.size)}} </div>
            </td> -->
            <td>
              <div class="  medtd breakword break-all">{{miliConvert(item.duration)}}</div>
            </td>
            <td>
              <div class=" medtd breakword break-all">{{checkforNull(item.rowCount)}}</div>
            </td>
            <td>
              <div class="progress">
                <div class="progress-bar" 
                  [class.progress-bar-success]="item.progress === 100" 
                  [class.progress-bar-info]="item.progress < 100"
                  [class.progress-bar-danger]="item.progress < 100 && item.status ==='FAILED_TO_LAUNCH'"
                  [class.progress-bar-primary]="item.progress < 100 && item.status ==='NOT_LAUNCHED'"
                  role="progressbar"  aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" [style.width.%]="item.progress==0?3:item.progress">
                  <span> {{checkforNull( item.progress| number: '.0-0' )}}% </span>
                </div>
              </div>
            </td>
            <td>

              <!-- <span class="" [class.info-s]='item.status === "RUNNING" || item.status === "PROCESSING_IN_HIVE"'   
              [class.warning-s]='item.status === "DISCARDED" ' 
              [class.success-s]='item.status === "FINISHED"' 
              [class.dark-s]='item.status === "DISCARDED"|| '
              [class.danger-s]='item.status === "FAILED" || item.status === "FAILED_TO_LAUNCH" || 
              item.status === "STOPPED" ||item.status === "KILLED"' 
              [class.primary-s]='item.status==="NOT_LAUNCHED"'></span> -->



              <div class="statustd">
              <span class="" [class.info-s]='item.status === "RUNNING" || 
              item.status === "NEW" || item.status === "NEW_SAVING"|| 
              item.status === "PROCESSING_IN_HIVE"'   
              [class.warning-s]='item.status === "DISCARDED"' 
              [class.success-s]='item.status === "FINISHED"' 
              [class.danger-s]='item.status === "FAILED" || 
              item.status === "FAILED_TO_LAUNCH" || 
              item.status === "KILLED"  || 
              item.status === "STOPPED"' 
              [class.primary-s]='item.status === "PENDING"'>{{ getJobStatus(item) }}</span> 
              </div>
            </td>
          
            <td>
              <div class="icons-container">
                <a ngbTooltip="View" placement="top" data-toggle="modal" data-target="#actions" (click)="getSingleSqoopById(item.id)"><i  class="fa fa-eye" aria-hidden="true" ></i></a>
                <a ngbTooltip="Log" placement="top" data-toggle="modal" data-target="#log" (click)="passItemIdLog(item.id)"><i  class="fa fa-file-text-o" aria-hidden="true" ></i></a>
              </div>
            </td>
          </tr>
        </tbody>
       
      </table>
      <pagination-controls *ngIf="sqoopList.length > 1" id="listing_pagination_sqoopjob" class="pagination" (directionLinks)="true"
        (pageChange)="rdbmsSqoopPage = $event" (autoHide)="true" (responsive)="true" previousLabel="" disabled="true"
        nextLabel="">
      </pagination-controls>
      <!-- Database / Query /Table ingestion ends -->


      
      <!-- Stream ingestion Starts-->
      <table class="table table-borderless mr-table" *ngIf="showStreamJob &&!showJobDetails">
        <thead class="mr-table-head">
          <tr class="mr-table-body-row" *ngFor="let item of streamJob ; let i = index " >
            <!-- <th>Run History</th> -->
            <th  class="nametd-sml">Destination Table Name</th>
            <!-- <th class="statustd">Size (rows)</th> -->
            <th class="datetimetd">Duration</th>
            <th class="rowcounttd">Message Count</th>
            <th class="rowcounttd">Message Rate</th>
            <th class="statustd">Status</th>
          </tr>
        </thead>
        <!-- <tbody *ngIf="!streamJob"  >
          <tr>
            <td colspan=100% class="text-center">This ingestion hasn't been executed</td>
          </tr>          
        </tbody> -->
         <tbody *ngIf="streamJob && !showJobDetails">
          <tr class="mr-table-body-row">
             <td>
              <div class="d medtd breakword break-all">{{streamJob.destinationTableName}} </div>
            </td>
     
            <td>
              <div class="d medtd breakword break-all">{{miliConvert(streamJob.duration)}} </div>
            </td>
            <td>
              <div class="rowcounttd">{{streamJob.messagesProcessed}}</div>
            </td>
            <td>
              <div class="rowcounttd">{{streamJob.processedRowsPerSecond.toFixed(2)}}</div>
            </td>
            <td>
              <div class="statustd">
              <span class="" [class.info-s]='streamJob.status === "RUNNING" || streamJob.status === "IN_PROGRESS"'   
              [class.warning-s]='streamJob.status === "DISCARDED"' 
              [class.success-s]='streamJob.status === "FINISHED"' 
              [class.danger-s]='streamJob.status === "FAILED" || 
              streamJob.status === "FAILED_TO_LAUNCH" || 
              streamJob.status === "IN_PROGRESS_WITH_ERRORS"  || 
              streamJob.status === "STOPPED"' 
              [class.primary-s]='streamJob.status==="NOT_LAUNCHED"'>{{ getJobStatus(streamJob) }}</span> 
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- Strem ingestion Ends -->
    </div>

  </div>


</article>
<!--Error Modal-->
<div class="modal" id="log">
  <div class="modal-dialog modal-lg">
    <div class="modal-content ">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Log</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <!-- Modal body 
      <div class="modal-body break-all">-->
      <div class="modal-body" style="white-space: pre-line;">  
        <h3>Build Log</h3>
        {{logReport.buildLog}}
        <br><br>
        <h3>Sqoop Log</h3>
        {{logReport.sqoopLog}}
        <br><br>
        <h3>Hive Processing Log</h3>
        {{logReport.hiveProcessingLog}}
      </div>

      <!-- Modal footer -->
      <footer class="modalfooter">
        <button type="button" class="done-btn modalpubtn" data-dismiss="modal">Close</button>
      </footer>
    </div>
  </div>
</div>

<!--Action Modal-->
<div class="modal" id="actions">
  <div class="modal-dialog modal-lg">
    <div class="modal-content ">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Sqoop Command</h4>
        <button type="button" class="close" data-dismiss="modal"></button>
      </div>
      <!-- Modal body -->
      <div class="modal-body">
        <div>
          {{sqoopCommand}}
        </div>

        <!-- Modal footer -->

      </div>
      <footer class="modalfooter">
        <button type="button" class="done-btn modalpubtn" data-dismiss="modal">Close</button>
      </footer>
    </div>
  </div>
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { RdbmsIngestionControllerService } from '../../../../services/api/rdbms-controller/rdbms-ingestion-controller.service';
import { JobControllerService } from '../../../../services/api/job/job-controller.service';
import { ProjectService } from 'src/app/services/project/project-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import * as $ from 'jquery';
import { StreamIngestionControllerService } from 'src/app/services/api/streamController/stream-ingestion-controller.service';
import { StreamJobControllerService } from 'src/app/services/api/streamJob/stream-job-controller.service';

@Component({
  selector: 'app-ingest-landing-page',
  templateUrl: './ingest-landing-page.component.html',
  styleUrls: ['./ingest-landing-page.component.scss'],
})
export class IngestLandingPageComponent implements OnInit, AfterViewInit {
  isSidenav: boolean = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  constructor(
    private rdbmsService: RdbmsIngestionControllerService,
    private jobsService: JobControllerService,
    public projectService: ProjectService,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private router: Router,
    private streamIngestionControllerService: StreamIngestionControllerService,
    private streamJobControllerService: StreamJobControllerService
  ) {}

  loggedUser: any;
  ingestions: any[] = [];

  jobs: any[] = [];
  term: string;
  toggle: any = [];
  toggleTwo: any = [];
  page = 1;

  projectName = this.activatedRoute.snapshot.params['id2'];
  load: boolean;

  navigate(ingestion: any) {
    this.deleteData();
    if (ingestion.bulkImport == true) {
      this.router.navigateByUrl(
        'project/' +
          this.projectName +
          '/ingest/editStepOneDatabase/' +
          ingestion.id
      );
    } else {
      this.router.navigateByUrl(
        'project/' +
          this.projectName +
          '/ingest/editStepOneCustom/' +
          ingestion.id
      );
    }
  }

  ngAfterViewInit() {
    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $('.pagec').css('height', contenth);
      $('.sidebar-wrapper').css('height', sidebarh);
    });
  }

  ngOnInit(): void {
    this.getIngestionsByProjectName();
  }

  dateFormatter(date: string): string {
    if (date == 'N/A') {
      return date;
    }
    let dateformat = new Date(date);
    let dateArray = dateformat.toString().split(' ');
    return (
      dateArray[1] +
      ' ' +
      dateArray[2] +
      ', ' +
      dateArray[3] +
      `
  ${dateArray[4]} `
    );
  }

  deleteIngestion(id: string, name: string) {
    Swal.fire({
      type: 'warning',
      title: "Are you sure you want to delete the ingestion '" + name + "'?",
      text: 'All jobs associated with this ingestion will be permanently deleted',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed == true) {
        this.rdbmsService.deleteRdbmsIngestion(id).subscribe((response) => {
          this.getIngestionsByProjectName();
        });
        Swal.fire('Ingestion deleted');
      }
    });
  }

  deleteStreamIngestion(id: string, name: string) {
    Swal.fire({
      type: 'warning',
      title: "Are you sure you want to delete the ingestion '" + name + "'?",
      text: 'All jobs associated with this ingestion will be permanently deleted',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed == true) {
        this.streamIngestionControllerService
          .deleteStreamIngestion(id)
          .subscribe((response) => {
            this.getIngestionsByProjectName();
          });
        Swal.fire('Ingestion deleted');
      }
    });
  }

  getIngestionsByProjectName() {
    this.load = true;
    this.rdbmsService
      .getAllIngestionsByProjectName(this.projectService.getCurrentProject())
      .subscribe((res) => {
        // console.log('[Ingest Landing] All Ingestion by Project Name', res);

        this.rdbmsService.getAllIngestionJobs(res).subscribe((jobResponse) => {
          this.jobs = jobResponse;
          let data: any = res;
          this.ingestions = res;

          console.log('[Ingest] Response in Job API', this.ingestions);

          for (let j = 0; j < this.ingestions.length; j++) {
            if (
              this.ingestions[j].bulkImport == false &&
              (!this.ingestions[j].query || this.ingestions[j].query == null)
            ) {
              this.ingestions[j].ingestionType = 'Table';
            } else if (
              (this.ingestions[j].bulkImport == false &&
                this.ingestions[j].query) ||
              this.ingestions[j].query != null
            ) {
              this.ingestions[j].ingestionType = 'Query';
            } else if (this.ingestions[j].bulkImport == true) {
              this.ingestions[j].ingestionType = 'Database';
            }
            let singleJob: any;

            singleJob = this.jobs.filter(
              (job) => this.ingestions[j]?.id == job.rdbmsIngestionId
            );

            this.ingestions[j].jobId = singleJob[0]?.id;

            this.ingestions[j].progress = singleJob[0]?.progress;
            this.ingestions[j].status = singleJob[0]?.status;
            this.ingestions[j].jobCreatedAt = singleJob[0]?.createdAt;
            this.ingestions[j].sqoopCommand = singleJob[0]?.sqoopCommand;
            // this.ingestions[j].count = count;

            if (singleJob[0]?.finishedAt != null) {
              this.ingestions[j].finishedAt = this.dateFormatter(
                singleJob[0].finishedAt
              );
            } else {
              this.ingestions[j].finishedAt = 'Not Applicable';
            }
          }

          this.streamIngestionControllerService
            .getAllStreamIngestionsByProjectName(this.projectName)
            .subscribe((response) => {
              let data: any = response;
              let jobs: any;
              this.streamJobControllerService
                .getAllStreamJobsByProject(this.projectName)
                .subscribe((response) => {
                  jobs = response;

                  for (let i = 0; i < data.length; i++) {
                    let singleJob: any;
                    singleJob = jobs.filter(
                      (job) => data[i]?.id == job.streamIngestionId
                    );

                    this.ingestions.push({
                      id: data[i].id,
                      name: data[i].name,
                      createdAt: data[i].createdAt,
                      ingestionType: 'Stream',
                      jobId: singleJob[0]?.id,
                      progress: singleJob[0]?.progress,
                      status: singleJob[0]?.status,
                      jobCreatedAt: singleJob[0]?.createdAt,
                      sqoopCommand: singleJob[0]?.sqoopCommand,
                    });
                  }
                  this.ingestions = this.returnSortedList(this.ingestions);
                });

              this.load = false;
            });
        });
      });
  }

  success(status: string) {
    Swal.fire({
      type: 'Success',

      title: 'Success!',
      text: status,
      confirmButtonText: 'Ok',
    });
  }

  deleteData() {
    localStorage.removeItem('stepOne');
    localStorage.removeItem('stepTwo');
    localStorage.removeItem('stepTwoCopy');
  }

  isIngestConfigHidden() {
    this.loggedUser = JSON.parse(sessionStorage.getItem('loggedUser'));
    for (
      var user_auths = 0;
      user_auths < this.loggedUser.authorities.authorities.length;
      user_auths++
    ) {
      if (
        this.loggedUser.authorities.authorities[user_auths].authority ===
          'PERM_MAINTAINER' ||
        this.loggedUser.authorities.authorities[user_auths].authority ===
          'PERM_OWNER' ||
        this.loggedUser.authorities.authorities[user_auths].authority ===
          'PERM_ADMIN'
      ) {
        return false;
      }
    }
    return true;
  }

  isRunIngestionHidden() {
    this.loggedUser = JSON.parse(sessionStorage.getItem('loggedUser'));
    for (
      var user_auths = 0;
      user_auths < this.loggedUser.authorities.authorities.length;
      user_auths++
    ) {
      if (
        this.loggedUser.authorities.authorities[user_auths].authority ===
          'PERM_MAINTAINER' ||
        this.loggedUser.authorities.authorities[user_auths].authority ===
          'PERM_OWNER' ||
        this.loggedUser.authorities.authorities[user_auths].authority ===
          'PERM_ADMIN' ||
        this.loggedUser.authorities.authorities[user_auths].authority ===
          'PERM_OPERATOR'
      ) {
        return false;
      }
    }
    return true;
  }

  isActionHidden() {
    this.loggedUser = JSON.parse(sessionStorage.getItem('loggedUser'));
    for (
      var user_auths = 0;
      user_auths < this.loggedUser.authorities.authorities.length;
      user_auths++
    ) {
      if (
        this.loggedUser.authorities.authorities[user_auths].authority ===
          'PERM_MAINTAINER' ||
        this.loggedUser.authorities.authorities[user_auths].authority ===
          'PERM_OWNER' ||
        this.loggedUser.authorities.authorities[user_auths].authority ===
          'PERM_ADMIN'
      ) {
        return false;
      }
    }
    return true;
  }

  checkStatus(value: string): string {
    if (value == undefined) {
      return 'N/A';
    } else {
      return value;
    }
  }

  sortAscending(data: any) {
    data.sort((a: any, b: any) => (a.createdAt > b.createdAt ? 1 : -1));
  }

  sortDescending(data: any) {
    data.sort((a: any, b: any) => (a.createdAt < b.createdAt ? 1 : -1));
  }
  returnSortedList(data: any) {
    return data.sort((a: any, b: any) => (a.createdAt < b.createdAt ? 1 : -1));
  }

  configureRdbms(id: string, index: number) {
    Swal.fire({
      type: 'warning',
      title: 'Are you sure you want to run this Ingestion?',
      // text: 'All information associated to this ingestion will be permanently deleted',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed == true) {
        this.rdbmsService.postRdbmsIngestionJob(id).subscribe((response) => {});
        this.toggle[index] = !this.toggle[index];
        this.router.navigate([`/project/${this.projectName}/jobs`]);

        this.success('Job submitted successfully');

        // this.getAllIngestionsByProjectName()
      }
    });
  }
  startStreamJob(id: string) {
    Swal.fire({
      type: 'warning',

      title: 'Are you sure you want to run this Ingestion?',
      // text: 'All information associated to this ingestion will be permanently deleted',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed == true) {
        this.streamJobControllerService
          .startStreamJobById(id)
          .subscribe((response) => {
            console.log(response);
          });
        this.router.navigate([`/project/${this.projectName}/jobs`]);

        this.success('Job submitted successfully');

        // this.getAllIngestionsByProjectName()
      }
    });
  }

  stopStreamJob(id: string) {
    this.streamJobControllerService
      .stopStreamJobById(id)
      .subscribe((response) => {
        console.log(response);
      });
  }

  pause(id: string) {
    this.jobsService.pauseJob(id).subscribe((response) => {});
  }

  discard(id: string) {
    this.jobsService.discardJob(id).subscribe((response) => {});
  }
}

import { Injectable } from '@angular/core';
import { Config } from '../../config';
import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RdbmsConnector } from "../../models/rdbms/rdbms-connector.model"
import { SqoopConnector } from 'src/app/models/sqoop-connector/sqoop-connector.model';
import { Jobs } from '../api/job/job-controller.service';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  static url = Config.url;

  constructor(private httpClient: HttpClient) { }

  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  getLogById(data: any): Observable<any> {
    return this.httpClient.get<any>(LogService.url + "/log/" + data)
      .pipe(
        retry(1),
        // catchError(this.processError)
      )
  }
}

import { Injectable } from '@angular/core';
import { Config } from '../../../config';
import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

export interface Catalog {


}

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  static url = Config.url;


  constructor(private httpClient: HttpClient, private router: Router, private activatedRoute: ActivatedRoute) { }
  projectName = this.activatedRoute.snapshot.params['id2'];

  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  getCatalogforDatabases(id: string) {
    return this.httpClient.get(CatalogService.url + "/rdbms/catalog/datasource/" + id)
      .pipe(
        retry(1),
        catchError(this.handleError)

      )
  }

  getCatalog(id: string) {
    return this.httpClient.get(CatalogService.url + "/rdbms/catalog/" + id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  getSingleCatalog(id: string): Observable<Catalog> {
    return this.httpClient.get<Catalog>(CatalogService.url + "/rdbms/catalog/" + id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  // OUTDATED
  // postCatalog(id: string) {
  //   return this.httpClient.post(CatalogService.url + "/rdbms/catalog/" + id, {})
  //     .pipe(
  //       retry(1),
  //       // catchError(this.processError)
  //     )
  // }

  updateCatalog(id: string, data: any): Observable<Catalog> {
    return this.httpClient.put<Catalog>(CatalogService.url + "/rdbms/catalog/" + id, JSON.stringify(data), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  deleteCatalog(id: string) {
    return this.httpClient.delete<Catalog>(CatalogService.url + "/rdbms/catalog/" + id, this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  getSingleDatabase(id: string): Observable<Catalog> {
    return this.httpClient.get<Catalog>(CatalogService.url + '/rdbms/catalog/tables/' + id)
      .pipe(
        retry(1)
      )

  }
  handleError(error) {

    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    Swal.fire({
      type: 'Failed',

      title: "Failed",
      text: error.error.message,
      confirmButtonText: 'Ok',
    })

    return throwError(errorMessage);
  }

}
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { RdbmsIngestionControllerService } from 'src/app/services/api/rdbms-controller/rdbms-ingestion-controller.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { ProjectService } from 'src/app/services/project/project-service.service';
import { IngestionSharingServiceService } from 'src/app/ingestion-sharing-service.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { StreamIngestionControllerService } from 'src/app/services/api/streamController/stream-ingestion-controller.service';

@Component({
  selector: 'app-step-three-stream-ingestion',
  templateUrl: './step-three-stream-ingestion.component.html',
  styleUrls: ['./step-three-stream-ingestion.component.scss'],
})
export class StepThreeStreamIngestionComponent implements OnInit {
  //snackbar
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  //datasource
  isSubmitShow: boolean = false;
  id: string;
  projectName: string;
  itemData: any;
  itemObjectsRight: any;

  load = true;

  advancedConfiguration: any;
  constructor(
    private formBuilder: FormBuilder,
    public service: RdbmsIngestionControllerService,
    public streamService: StreamIngestionControllerService,
    public router: Router,
    public projectService: ProjectService,
    private activatedRoute: ActivatedRoute,
    private ingestionService: IngestionSharingServiceService
  ) {}

  //STEPPER
  //********************************************************************************************************************************************************************************** */

  showDiv = {
    stepone: false,
    steptwo: false,
    stepthree: false,
    stepfour: true,
    stepfive: false,

    stepbtnone: true,
    stepbtntwo: false,
    stepbtnthree: false,
    stepbtnfour: false,
    stepbtnfive: false,

    cancelbtn: false,
    nextbtn: true,
    nextbtntwo: false,
    nextbtnthree: false,
    nextbtnfour: false,

    backbtn: false,
    backbtntwo: false,
    backbtnthree: false,
    backbtnfour: false,
    backbtnfive: false,

    submitbtn: false,
  };
  ngOnInit(): void {
    this.advancedConfiguration = this.formBuilder.group({
      ingestionType: ['STREAM'],
      sourceId: [''],
      name: [''],
      description: [''],
      bulkImport: [''],
      numMappers: ['4', Validators.required],
      splitLimit: ['0', Validators.required],
      fetchSize: ['1000', Validators.required],
      validate: ['false', Validators.required],
      projectName: this.projectName,

      databases: this.formBuilder.array([]),
    });

    this.id = this.activatedRoute.snapshot.params['id'];
    this.projectName = this.activatedRoute.snapshot.params['id2'];
    this.itemData = this.ingestionService.getStepOneData();
    this.itemObjectsRight = this.ingestionService.getStepTwoData();

    this.load = true;
  }

  onSubmit(savedRecord: any) {
    for (let i = 0; i < this.itemObjectsRight.length; i++) {
      for (let j = 0; j < this.itemObjectsRight[i].tables.length; j++) {
        delete this.itemObjectsRight[i].tables[j].tableId;

        for (
          let k = 0;
          k < this.itemObjectsRight[i].tables[j].columns.length;
          k++
        ) {
          this.itemObjectsRight[i].tables[j].columns[k].destinationColumnName =
            this.itemObjectsRight[i].tables[j].columns[k].sourceColumnName;
        }
      }

      // this.success('Stream ingestion successfully updated');
    }

    this.advancedConfiguration.patchValue({
      sourceId: this.id,
      name: this.itemData.name,
      description: this.itemData.description,
      bulkImport: true,
      numMappers: savedRecord.value.numMappers,
      splitLimit: savedRecord.value.splitLimit,
      fetchSize: savedRecord.value.fetchSize,
      projectName: this.projectName,

      // compress: savedRecord.value.compress,
      validate: savedRecord.value.validate,
    });
    this.advancedConfiguration.setControl(
      'databases',
      this.formBuilder.array(this.itemObjectsRight)
    );

    this.load = true;
    this.streamService
      .postStreamIngestion(this.advancedConfiguration.value)
      .subscribe(
        (response: any) => {
          localStorage.removeItem('stepOne');
          localStorage.removeItem('stepTwo');
          localStorage.removeItem('stepTwoCopy');
          this.load = false;
          this.success(
            `Stream ingestion '${response.name}' successfully created`
          );
        },
        () => {
          this.load = false;
          this.router.navigateByUrl(
            `project/${this.projectName}/ingest/select-ingest`
          );
        }
      );
  }

  success(status: string) {
    Swal.fire({
      type: 'Success',
      title: 'Success!',
      text: status,
      confirmButtonText: 'Ok',
    }).then(() => {
      this.router.navigateByUrl('project/' + this.projectName + '/ingest');
    });
  }
}

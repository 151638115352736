<div class="cm-content-blocks">
  <ol class="breadcrumb">
      <li><a routerLink="/home"><i class="fa fa-home"></i>Home</a></li>
      <li><a routerLink="/project/{{projectName}}/ingest"><i class="fa fa-filter mr-1"></i>Ingest</a></li>  
      <li  class="active"><i class="fa fa-eye mr-1"></i>View Details</li>
  </ol>
</div>
<div class="menublock">
  <section class="table-ec">
    <ul class="progressbar clearfix">
          
      <li class="tabli active pointerc" 
      [ngClass]="showDiv.stepone === true ? 'active':'' " 
      (click)="showDiv.stepone = false;
      showDiv.steptwo = true;
      showDiv.stepthree = false; "
      routerLink="/project/{{projectName}}/databaseViewDetails/{{Id}}"
      >
          <section class="tabbtn">
              <section class="clearfix relativepos">
              <aside class="absoultepos stipico">
                  <i class="fa fa-cog" aria-hidden="true"></i>
              </aside>
              <aside class="steptxtc">
                  <div class="steptitle tenpt">Configuration</div>
              </aside>
              </section>
          </section>
      </li>
    
      <li class="tabli pointerc"
      [ngClass]="showDiv.steptwo === true ?'active':'' "
      (click)="showDiv.stepone = false;
      showDiv.steptwo = true; 
      showDiv.stepthree = false; "
      
      routerLink="/project/{{projectName}}/databaseViewExecution/{{Id}}"
      >
          <section class="tabbtn">
              <section class="clearafter relativepos">
              <aside class="absoultepos stipico">
              <i class="fa fa-tasks" aria-hidden="true"></i>
              </aside>
              <aside class="steptxtc">
              <div class="steptitle tenpt">Execution</div>
              </aside>
              </section>
          </section>
      </li>
      <!-- <li *ngIf="sqoopList[0].status == 'FINISHED'"  class="tabli pointerc"
      [ngClass]="showDiv.stepthree === true ?'active':'' "
      (click)="showDiv.stepone = false;
      showDiv.steptwo = true; 
      showDiv.stepthree = false"
      routerLink="/project/{{projectName}}/databaseViewValidation/{{Id}}"
      >
          <section class="tabbtn">
              <section class="clearafter relativepos">
              <aside class="absoultepos stipico">
              <i class="fa fa-clipboard" aria-hidden="true"></i>
              </aside>
              <aside class="steptxtc">
              <div class="steptitle tenpt">Validation</div>
              </aside>
              </section>
          </section>
      </li> -->
      <aside class="tenml twomt inline-block float-right">
        <button class="iconbtn jobrefresh" placement="left" ngbTooltip="Refresh" container="body" (click)="refresh()">
            <i class="fa fa-refresh" aria-hidden="true"></i>
        </button>
    </aside>
      
  </ul>  
    <div class=" fifteenmb"  id="tableid">	
   
                  
        <table class="table table-borderless mr-table">
          <thead class="mr-table-head">
            <tr class="mr-table-head-row">
              <!-- <th>Run History</th> -->
              <th  class="nametd-sml">Table</th>
              <!-- <th class="statustd">Size (rows)</th> -->
              <th class="datetimetd">Duration</th>
              <th class="statustd">Status</th>
              <th class="progresstd">Progress</th>
              <th class="actiontwotd">Info</th>
            </tr>
          </thead>
          <tbody>
            <tr class="mr-table-body-row" *ngFor="let item of sqoopList;">
              <!-- <td>{{checkforNull(ingestions.runHistory)}}</td> -->
              <td>
                <div class="descriptiontd breakword">{{checkforNull(item.database.tables[0].sourceTableName)}} </div>
              </td>
              <!-- <td>
                <div class="statustd">{{checkforNull(item.size)}} </div>
              </td> -->
              <td>
                <div class=" descriptiontd breakword">{{miliConvert(duration(item.createdAt,item.finishedAt, item.status))}}</div>
              </td>
              <td>
                <div class="statustd">
                <span class="" [class.info-s]='item.status === "RUNNING"'  [class.success-s]='item.status === "FINISHED"' [class.danger-s]='item.status === "FAILED TO LAUNCH"' [class.primary-s]='item.status==="NOT LAUNCHED"'>{{ item.status }}</span> 
                </div>
              </td>
              <td>
                
                  <div class="progress-bar" 
                    [class.progress-bar-success]="item.progress === 100" 
                    [class.progress-bar-info]="item.progress < 100"
                    [class.progress-bar-danger]="item.progress < 100 && item.status ==='FAILED TO LAUNCH'"
                    [class.progress-bar-.primary]="item.progress < 100 && item.status ==='NOT LAUNCHED'"
                    role="progressbar"  aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" [style.width.%]="item.progress==0?3:item.progress">
                    <span> {{checkforNull( item.progress| number: '.0-0' )}}% </span>
                  </div>
              </td>
              <td>
                <div class="icons-container actionthreetd">
                  <a data-toggle="modal" data-target="#actions" (click)="getSingleSqoopById(item.id)"><i  class="fa fa-eye" aria-hidden="true" ></i></a>
                  <a data-toggle="modal" data-target="#log" (click)="passItemIdLog(item.id)"><i  class="fa fa-file-text-o" aria-hidden="true" ></i></a>
                </div>
              </td>
            </tr>
          </tbody>
         
        </table>
       
      </div>
      
     <!-- <ngb-pagination class="pagination" [collectionSize]="70" [(page)]="page" aria-label="Default pagination"></ngb-pagination> -->
  
      
  </section> 
</div>
<!--Error Modal-->
<div class="modal" id="log">
  <div class="modal-dialog modal-lg">
    <div class="modal-content ">
    
      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Log</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <!-- Modal body -->
      <div class="modal-body">
        {{logReport.log}}
      </div>
      
      <!-- Modal footer -->
      <footer class="modalfooter">
          <button type="button" class="done-btn modalpubtn" data-dismiss="modal">Close</button>
      </footer>
    </div>
  </div>
</div>

<!--Action Modal-->
<div class="modal" id="actions">
  <div class="modal-dialog modal-lg">
    <div class="modal-content ">
    
      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Sqoop Command</h4>
        <button type="button" class="close" data-dismiss="modal"></button>
      </div>
      <!-- Modal body -->
      <div class="modal-body">
        <div>
          {{sqoopCommand}}
        </div>

      <!-- Modal footer -->
     
    </div>
    <footer class="modalfooter">
      <button type="button" class="done-btn modalpubtn" data-dismiss="modal">Close</button>
  </footer>
  </div>
</div>
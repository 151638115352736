<div class="cm-content-blocks">
  <ol class="breadcrumb">
             <li><a routerLink="/home"><i class="fa fa-home"></i>Home</a></li>
             <li><a routerLink="/project"><i class="fa fa-folder-open"></i>Projects</a></li>
             <li><a routerLink="/project/{{projectName}}"><i class="fa fa-folder"></i>{{projectName}}</a></li>
             <li><a [routerLink]="['/project', projectService.getCurrentProject(),'ingest']"><i class="fa fa-filter"></i>Ingest</a></li>
             <li><a [routerLink]="['/project', projectService.getCurrentProject(),'ingest']"><i class="fa fa-sitemap mr-1"></i>Configure Ingestion</a></li>
             <li class="active"><i class="fa fa-filter"></i >Edit Database Ingestion</li>

         </ol>
</div>

<div class="clearafter headbtnc">
  <h3 class="headc">
    Edit Database Ingestion
    <div class="subhead">Edit an existing database ingestion</div>
  </h3>
  <div class="create-btn-position headbtnc">
    <button class="mr-btn-back pull-right pointerc"  [routerLink]="['/project', projectService.getCurrentProject(),'ingest']">Back to Ingest</button>
  </div>
</div>

<div class="menublock">
  <!-- steps -->

  <div class="progresslic clearafter " >
      <div class="fifteen_pb">
          <ul class="progressbar clearfix">
            
              <li class="tabli active pointerc" 
              [ngClass]="showDiv.stepone === true ? 'active':'disabled' " 
              >
                  <section class="tabbtn">
                      <section class="clearfix relativepos">
                      <aside class="absoultepos stipico">
                          <i class="fa fa-info" aria-hidden="true"></i>
                      </aside>
                      <aside class="steptxtc">
                        <div class="steptitle">Data Source</div>
                        <div class="stepdes mt-1">Select data source for ingestion</div> 
                      </aside>
                      </section>
                  </section>
              </li>
            
              <li class="tabli pointerc" [ngClass]="showDiv.steptwo === true ?'active':'disabled' ">
                  <section class="tabbtn">
                      <section class="clearafter relativepos">
                      <aside class="absoultepos stipico">
                      <i class="fa fa-database" aria-hidden="true"></i>
                      </aside>
                      <aside class="steptxtc">
                      <div class="steptitle">Choose Data</div>
                      <div class="stepdes">Select databases to ingest from</div>
                    </aside>
                      </section>
                  </section>
              </li>
  
            
              <li class="tabli pointerc" [ngClass]="showDiv.stepfour === true ?'active':'disabled' " >
                  <section class="tabbtn">
                      <section class="clearfix relativepos">
                      <aside class="absoultepos stipico">
                          <i class="fa fa-cogs" aria-hidden="true"></i>
                      </aside>
                      <aside class="steptxtc">
                        <div class="steptitle">Advanced Configuration</div>
                        <div class="stepdes">Configure ingestion settings</div>
                      </aside>
                      </section>
                  </section>
              </li> 
              <!-- <li class="tabli pointerc"
              [ngClass]="showDiv.stepfive === true ?'active':'disabled' " 
              (click)="showDiv.stepone = false;
              showDiv.steptwo = false; 
              showDiv.stepthree = false;
              showDiv.stepfour = false;
              showDiv.stepfive = true;

              showDiv.backbtn = false;
              showDiv.backbtntwo = false;  
              showDiv.backbtnthree = false;  
              showDiv.backbtnfour = false;  
              showDiv.backbtnfive = true;  
 
              showDiv.submitbtn = false; 
              showDiv.nextbtn = false;
              showDiv.nextbtntwo = false; 
              showDiv.nextbtnthree = false; 
              showDiv.nextbtnfour = false; 
              showDiv.cancelbtn = false"
              >
                  <section class="tabbtn">
                      <section class="clearfix relativepos">
                      <aside class="absoultepos stipico">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                      </aside>
                      <aside class="steptxtc">
                          <div class="steptitle">Advanced Configuration</div>
                          <div class="stepdes">Description</div>
                      </aside>
                      </section>
                  </section>
              </li>
               -->
              
              
          </ul>
        </div>
        <!-- tabe content-->

        <section class="tabc_config" >
       

          <div class="steptwo_c" *ngIf="showDiv.steptwo"> 
            
              <div class="fifteenmb">
                
                    <section cdkDropListGroup class="clearafter">
      
                      <aside class="fourtynine_p float-left">
                        <div class="subtitle">Source</div>
                        <p>Select data from source panel to destination panel </p>
                        <div
                        cdkDropList  [cdkDropListData]="allDatabases" [cdkDropListConnectedTo]="[DestinationDropBox]"
                        (cdkDropListDropped)="drop($event)">
                        <div class="dragdrop_height">
                          
                          <section class="menublock_scroll">
                            <div class="menublockc tabletree_parent">
                              <!-- tree item goes here -->
                              <div class="table-wrapper position-relative loader_top_adj">
                                <div *ngIf="load" class="">
                                  <div class="loader"></div>
                                  <figure class="spinner-border loaderico"></figure>
                                </div>
                                
                              <ul class="datatree" *ngFor="let database of allDatabases; let index =index;" cdkDrag >
                                <li class="eighteenpl" >
                                  <span class="dataparent liclick" (click)="toggle['source' + database.sourceDatabaseName ]=!toggle['source' + database.sourceDatabaseName ];checkIfDatabaseHasData(database)" >{{database.sourceDatabaseName}}</span>
                                </li>
                              
                                <div cdkDropList  [cdkDropListData]="allTables" [cdkDropListConnectedTo]="[DestinationDropBox]"
                                (cdkDropListDropped)="drop($event)" >
                                <ul class="nested"  [class.active]="toggle[ 'source' + database.sourceDatabaseName ]">

                                <ul  *ngFor="let tables of allTables; let i =index;" cdkDrag >
                                  <li class="parentli items" *ngIf="(database.sourceDatabaseName == tables.sourceDatabaseName)"   > 
                                    <span class="liclick eighteenpl displayblock" (click)="toggle['source' + tables.tables[0].sourceTableName ]=!toggle['source' + tables.tables[0].sourceTableName];" >
                                      {{nullcheck(tables.tables[0].schema) + tables.tables[0].sourceTableName}}
                                    </span>
                                    <div  *ngFor="let columns of tables.tables; let i =index;" >
                                        <ul class="nested itemslist" [class.active]="toggle['source' + tables.tables[0].sourceTableName]" *ngFor="let column of columns.columns; let i =index;let lastColumn = last" >
                                          <li class="selectli items" > 
                                            <span class="liclick eighteenpl displayblock" (click)="toggle['source' + column.sourceColumnName]=!toggle[ 'source' + column.sourceColumnName]" >
                                              {{column.sourceColumnName}}
                                            </span>
                                            </li>

                                        </ul>                                     
                                      </div>
                                  </li>
                                  
                                </ul> 
                              </ul>

                                </div>

                              </ul>
                              </div>
                              <!-- tree item end here -->
                            </div>
                          </section>
                        
                        </div>

                     
                        </div>
                      </aside>
                    
                        
                      <aside class="fourtynine_p float-right">
                        <div class="subtitle">Destination</div>
                        <p>Data to be ingested</p>
                        <div
                        cdkDropList #DestinationDropBox="cdkDropList" [cdkDropListData]="itemObjectsRight"
                        (cdkDropListDropped)="drop($event)"
                        >
                          <div class="dragdrop_height">
                              <section class="menublock_scroll">
                                  <div class="menublockc tabletree_parent">
                                    <ul class="datatree"  *ngFor="let databases of itemObjectsRight; let index =index;"  >
                                      <li class="eighteenpl">
                                        <span class="dataparent liclick" (click)="toggle[databases.sourceDatabaseName]=!toggle[databases.sourceDatabaseName];" >{{databases.sourceDatabaseName}}</span>
                                        <div class="icons-container li_delete_btn">
                                          <!-- <i class="material-icons mr-2" (click)="redoTableDelete(index)">undo</i> -->
                                          <i class="fa fa-trash pointerc zeromrimp" (click)="deleteDatabase(itemObjectsRight,index)"></i>

                                        </div>
                                      </li>
                                      <ul class="nested" [class.active]="!toggle[databases.sourceDatabaseName]"  *ngFor="let tables of databases.tables; let i =index;"  >
                                        <li class="parentli items" > 
                                         
                                          <div class="relativepos">
                                            <span class="liclick eighteenpl displayblock li_delete_text" (click)="toggle[tables.sourceTableName]=!toggle[tables.sourceTableName];">{{nullcheck(tables.schema) + tables.destinationTableName}}</span>
                                            
                                            <div class="icons-container li_delete_btn">
                                              <!-- <i class="material-icons mr-2" (click)="redoColumnDelete(databases,tables)">undo</i> -->

                                              <!-- <i class="fa fa-trash pointerc zeromrimp" (click)="deleteTable(databases.tables,i)"></i> -->

                                            </div>
                                          </div>
                                        </li>
                                        <ul class="nested itemslist"  [class.active]="toggle[tables.sourceTableName]" *ngFor="let columns of tables.columns; let j =index;">
                                          <li class="selectli items" >
                                            <div class="relativepos">
                                              <span class="liclick eighteenpl displayblock li_delete_text" (click)="toggle[columns.destinationColumnName]=!toggle[columns.destinationColumnName]">{{columns.destinationColumnName}}</span>
                                              
                                              <div class="icons-container li_delete_btn">
                                                <!-- <i class="fa fa-trash pointerc zeromrimp" (click)="deleteColumn(tables.columns,j)"></i> -->
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </ul>
                                    </ul>
                                  </div>
                                </section>
                          </div>
                        </div>
                      </aside>
                      
                    </section>
                    
                    
                  <!-- <ng-template #itemTemplate let-item="item" let-index="index">
                      <ul>
                      <button class="btn btn-light"> {{item.value}}</button>
                      

                     </ul>
                  </ng-template>

                 
                  <div class="row">
                  <div class="col-xs-6 col-6 col-md-5 col-lg-3">
                      <bs-sortable
                      [(ngModel)]="itemObjectsLeft"
                      [itemTemplate]="itemTemplate"
                      fieldName="sourceDatabaseName"
                      itemClass="callout sortable"
                      itemActiveClass="sortable-item-active"
                      placeholderItem="Drag here"
                      placeholderClass="placeholderStyle"
                      wrapperClass="sortable-wrapper"
                      ></bs-sortable>

                      <pre class="code-preview">model: {{ itemObjectsLeft | json }}</pre>
                  </div>
                  
               
                  <div class="col-xs-6 col-6 col-md-5 col-lg-3 dragged-question">
                      <bs-sortable
                      [(ngModel)]="itemObjectsRight"
                      [itemTemplate]="itemTemplate"
                      fieldName="sourceDatabaseName"
                      itemClass="callout sortable"
                      itemActiveClass="sortable-item-active"
                      placeholderItem="Drag here"
                      placeholderClass="sortable-item"
                      wrapperClass="sortable-wrapper"
                      ></bs-sortable>
                      <pre class="code-preview">model: {{ itemObjectsRight | json }}</pre>
                  </div>
                  
                  </div> -->
                    
              </div>
          </div> 
   

    
          <footer class="tenpt lightgraybdrt clearafter footerbtns">
          
              <button class="tertiary-active fr btnnext" id="btnnext" [disabled]="!itemDropped" *ngIf="showDiv.nextbtn"(click)="carryData()">
              Next 
              <i aria-hidden="true" class="fa fa-arrow-right"></i>
              </button>
              <button class="grey-btn fr btnpre" (click)="navigateBack()">
              <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
              </button>
             
              <!-- <button class="tertiary-active fr btnnext" id="btnnext" *ngIf="showDiv.nextbtnfour"
              (click)="showDiv.stepone = false; 
              showDiv.steptwo =false; 
              showDiv.stepthree = false;
              showDiv.stepfour= false;
              showDiv.stepfive = true;

              showDiv.backbtn = false; 
              showDiv.backbtntwo = false;
              showDiv.backbtnthree = false; 
              showDiv.backbtnfour = true; 
              showDiv.backbtnfive = false;

              showDiv.submitbtn = false; 
              showDiv.nextbtn = false; 
              showDiv.nextbtntwo = false; 
              showDiv.nextbtnthree = false; 
              showDiv.nextbtnfour = false;

              showDiv.cancelbtn = false;">
              Next <i aria-hidden="true" class="fa fa-arrow-right"></i>
              </button>
              
   -->
              <button class="grey-btn fr btnpre"  *ngIf="showDiv.cancelbtn">
              Cancel
              </button>
  
              <button class="tertiary-active fr btnnext" *ngIf="showDiv.submitbtn">
              Submit
              </button>
  
              <button class="grey-btn fr btnpre"  *ngIf="showDiv.backbtn"
              (click)="showDiv.stepone = true; 
              showDiv.steptwo = false; 
              showDiv.stepthree = false;
              showDiv.stepfour = false;
              showDiv.stepfive = false;

              showDiv.backbtn = false; 
              showDiv.backbtntwo = false;
              showDiv.backbtnthree = false; 
              showDiv.backbtnfour= false;
              showDiv.backbtnfive = false;

              showDiv.submitbtn = false; 
              showDiv.nextbtn = true; 
              showDiv.nextbtntwo = false; 
              showDiv.nextbtnthree = false; 
              showDiv.nextbtnfour = false; 

              showDiv.cancelbtn = false;">
                  <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
              </button>
              <button class="grey-btn fr btnpre"  *ngIf="showDiv.backbtntwo"
              (click)="showDiv.stepone = false; 
              showDiv.steptwo = true; 
              showDiv.stepthree = false;
              showDiv.stepfour = false;
              showDiv.stepfive = false;

              showDiv.backbtn = true; 
              showDiv.backbtntwo = false;
              showDiv.backbtnthree = false;
              showDiv.backbtnfour = false;
              showDiv.submitbtn = false; 
              showDiv.nextbtn = false; 
              showDiv.nextbtntwo = true; 
              showDiv.nextbtnthree = false; 
              showDiv.nextbtnfour = false; 

              showDiv.cancelbtn = false;">
                  <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
              </button>
              <button class="grey-btn fr btnpre"  *ngIf="showDiv.backbtnthree"
              (click)="showDiv.stepone = false; 
              showDiv.steptwo = false; 
              showDiv.stepthree = true;
              showDiv.stepfour = false;
              showDiv.stepfive = false;

              showDiv.backbtn = false; 
              showDiv.backbtntwo = true;
              showDiv.backbtnthree = false; 
              showDiv.backbtnfour = false; 
              showDiv.backbtnfive = false; 

              showDiv.submitbtn = false; 
              showDiv.nextbtn = false; 
              showDiv.nextbtntwo = false; 
              showDiv.nextbtnthree = true; 
              showDiv.nextbtnfour = false; 
              showDiv.cancelbtn = false;">
                  <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
              </button>
              <!-- <button class="grey-btn fr btnpre"  *ngIf="showDiv.backbtnfour"
              (click)="showDiv.stepone = false; 
              showDiv.steptwo = false; 
              showDiv.stepthree = false;
              showDiv.stepfour = true;
              showDiv.stepfive = false;

              showDiv.backbtn = false; 
              showDiv.backbtntwo = false;
              showDiv.backbtnthree = true; 
              showDiv.backbtnfour = false; 
              showDiv.backbtnfive = false; 

              showDiv.submitbtn = false; 
              showDiv.nextbtn = false; 
              showDiv.nextbtntwo = false; 
              showDiv.nextbtnthree = false; 
              showDiv.nextbtnfour = true; 
              showDiv.cancelbtn = false;">
                  <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
              </button> -->
              <!-- <button class="grey-btn fr btnpre"  *ngIf="showDiv.backbtnfive"
              (click)="showDiv.stepone = false; 
              showDiv.steptwo = false; 
              showDiv.stepthree = false;
              showDiv.stepfour = false;
              showDiv.stepfive = true;

              showDiv.backbtn = true; 
              showDiv.backbtntwo = false;
              showDiv.backbtnthree = false; 
              showDiv.backbtnfour = true; 
              showDiv.backbtnfive = false; 

              showDiv.submitbtn = false; 
              showDiv.nextbtn = false; 
              showDiv.nextbtntwo = false; 
              showDiv.nextbtnthree = false; 
              showDiv.nextbtnfour = false; 
              showDiv.cancelbtn = false;">
                  <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
              </button>
             -->
          </footer>
        </section> 
    
    </div>
    
  </div>

  <div class="modal" id="largeModal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content ">
      
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Large Modal</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <!-- Modal body -->
        <div class="modal-body">
            Modal Body Content
        </div>
        
        <!-- Modal footer -->
        <footer class="modalfooter">
            <button  class="done-btn modalpubtn">Save </button>
            <button type="button" class="done-btn modalpubtn" data-dismiss="modal">Cancel</button>
        </footer>
      </div>
    </div>
</div>



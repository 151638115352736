import { Component, OnInit, AfterViewInit, NgZone } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-basic-layout',
  templateUrl: './basic-layout.component.html',
  styleUrls: ['./basic-layout.component.scss']
})
export class BasicLayoutComponent implements OnInit,AfterViewInit {
  isSidenav: boolean=false;
  loggedUser: any;
  notifications: any;
  notificationCount: number = 0;
  accessNotification: any[] = [];
  jobNotification: any[] = [];
  constructor(private _ngZone: NgZone) { 
    window['angularComponentRef'] = {component: this, zone: _ngZone};

  }
  ngOnDestroy() {
    // window['angularComponentRef'] = null;
  }

  ngOnInit() {
    this.isSidenav=true;
    this.loggedUser=JSON.parse(sessionStorage.getItem("loggedUser"));
  }
  ngAfterViewInit() {
    $(document).on("click", ".closeprofile", function(){
      $(".profilec").toggleClass("profileshow");
    });

    $(document).ready(function(){
      var contenth = $( window ).height() - 110 ;
      var sidebarh = $( window ).height() - 111 ;
      $(".pagec").css("height", contenth );
      $(".sidebar-wrapper").css("height", sidebarh );
    });
  }

}

<div class="">
    <ol class="breadcrumb">
        <li><a routerLink="/home"><i class="fa fa-home"></i>Home</a></li>
        <li><a routerLink="/project"><i class="fa fa-folder-open"></i>Projects</a></li>
        <li><a routerLink="/project/{{projectName}}"><i class="fa fa-folder"></i>{{projectName}}</a></li>
        <li class="active"><i class="fa fa-filter"></i>Ingest</li>
    </ol>
</div>

<div class="clearafter headbtnc">
    <h3 class="headc">
        Ingest - {{projectName}}
        <div class="subhead">View all ingestions available for this project</div>
    </h3>
    <div class="create-btn-position headbtnc">
        <button class="mr-btn-create pull-right pointerc" routerLink="ingest/select-ingestion" routerLinkActive="active"
            [hidden]="isIngestConfigHidden()" (click)="toConfigureIngestion()">
            Configure Ingestion
        </button>
    </div>
</div>

<div *ngIf="load" class="">
    <div class="loader"></div>
    <figure class="spinner-border loaderico"></figure>
</div>


<!-- filter and search container -->
<div class="fs-container flex-util menublock" *ngIf="!load">
    <div class="searchbarc">
        <input class="search-bar input-field" type="text" placeholder="Search all ingestions..." [(ngModel)]="term"
            (change)="searchIngest()">
        <img class="search-bar-icon" src="../../../assets/images/search.svg">
    </div>
    <div class="fs-container__filter--options flex-util">
        <div class="fs-container__filter--name flex-util" *ngFor="let option of filterOptions">
            <input type="checkbox" (click)="applyFilter($event, option.name)" />
            <span>{{option.name}} ({{option.value}})</span>
        </div>
    </div>
    <div class="fs-container__filter--sort flex-util">
        <span class="inline-block fivemr">Sort by: </span>
        <label class="flex-util">
            <input type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1">
            <span class="checkmark-r"></span>
            <span class="text-r ml-1">Newest</span>
        </label>
        <label class="flex-util">
            <input type="radio" name="inlineRadioOptions" (click)="sortAscending(ingestions)" id="inlineRadio2"
                value="option2">
            <span class="checkmark-r"></span>
            <span class="text-r ml-1">Oldest</span>
        </label>
    </div>
    <div class="fs-container__filter--refresh">
        <button class="iconbtn" placement="left" ngbTooltip="Refresh" container="body">
            <i class="fa fa-refresh" aria-hidden="true" (click)="refreshPage()"></i>
        </button>
    </div>
</div>


<section class="db_boxec NewIngest">
    <app-ingest-detail *ngFor="let ingestion of returnIngestions | paginate: { id: 'listing_pagination',
        itemsPerPage: 8,
        currentPage: page,
        totalItems: returnIngestions.length };" [ingestion]="ingestion" [isLoading]="load"
        (deleteEvent)="deleteIngestion($event)" [projectName]="projectName" [loggedUser]="loggedUser" class="db_boxc">
    </app-ingest-detail>
</section>

<pagination-controls *ngIf="returnIngestions.length > 10" id="listing_pagination" class="pagination"
    (directionLinks)="true" (pageChange)="page = $event" (autoHide)="true" (responsive)="true" previousLabel=""
    disabled="true" nextLabel="">
</pagination-controls>
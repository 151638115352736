import { Injectable } from '@angular/core';

import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ProjectService } from 'src/app/services/project/project-service.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(private projectService: ProjectService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = JSON.parse(sessionStorage.getItem('credentials'));

    request = request.clone({
      headers: request.headers.append(
        'Authorization',
        'Basic ' + btoa(token.username + ':' + token.password)
      ),
    });
    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        headers: request.headers.append('Content-Type', 'application/json'),
      });
    }
    if (request.headers.has('Content-Type')) {
      request = request.clone({
        headers: request.headers.delete('Content-Type', 'application/json'),
      });
    }
    if (request.headers.has('Content-Type')) {
      request = request.clone({
        headers: request.headers.append('Accept', 'application/zip'),
      });
    }
    if (request.headers.has('Accept')) {
      request = request.clone({
        headers: request.headers.append('Accept', 'image/jpeg'),
      });
    }
    if (!request.headers.has('Accept')) {
      request = request.clone({
        headers: request.headers.append('Accept', 'application/json'),
      });
    }
    if (!request.headers.has('X-Requested-With')) {
      request = request.clone({
        headers: request.headers.append('X-Requested-With', 'XMLHttpRequest'),
      });
    }
    if (this.projectService.getCurrentProject() !== 'null') {
      request = request.clone({
        params: request.params.append(
          'project',
          this.projectService.getCurrentProject()
        ),
      });
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      })
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IngestionSharingServiceService } from 'src/app/ingestion-sharing-service.service';
import { StreamingdataSourceService } from 'src/app/services/api/data-source/streamingdata-source.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-step-two-stream-data-source',
  templateUrl: './step-two-stream-data-source.component.html',
  styleUrls: ['./step-two-stream-data-source.component.scss']
})
export class StepTwoStreamDataSourceComponent implements OnInit {

  jsonItem: any = [];
  jsonError: boolean;
  showDiv = {
    stepone: true,
    steptwo: true,
    stepthree: false,
    stepfour: false,
    stepfive: false,

    stepbtnone: true,
    stepbtntwo: false,
    stepbtnthree: false,
    stepbtnfour: false,
    stepbtnfive: false,

    cancelbtn: false,
    nextbtn: true,
    nextbtntwo: false,
    nextbtnthree: false,
    nextbtnfour: false,

    backbtn: false,
    backbtntwo: false,
    backbtnthree: false,
    backbtnfour: false,
    backbtnfive: false,

    submitbtn: false
  }

  constructor(private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, private router: Router,
    private streamingDataSourceService: StreamingdataSourceService, private ingestionSharingService: IngestionSharingServiceService
  ) { }
  dataTypeList: string[] = ["Binary", "String", "Boolean", "Date", "Double", "Float", "Byte", "Integer", "Long", "Short"];
  jsonCodeSnippet: any = "";
  projectName = this.activatedRoute.snapshot.params['id2'];
  stepOneStreamingDataSourceInformation: any = this.ingestionSharingService.getStepOneStreamingDataSourceData();
  stepOneForm
  validate = this.formBuilder.group({
    jsonInput: ["", Validators.required]
  })

  parser(obj: any) {

    try {
      JSON.parse(obj);
    } catch (e) {
      let error: any = e;
      console.log(error);
      return e// error in the above string (in this case, yes)!
    }
  }
  parseJSON() {
    let data: any = JSON.parse(this.jsonCodeSnippet);
    this.jsonItem = [];
    for (const property in data) {

      // if (parseInt(data[property]) != NaN) {
      if (typeof data[property] === 'number') {
        if (data[property] % 1 === 0 && data[property].toString().length <= 12) {
          this.jsonItem.push({ header: property, dataType: "Integer" })
        } else if (data[property] % 1 !== 0 && data[property].toString().length <= 12) {
          this.jsonItem.push({ header: property, dataType: "Decimal" })

        } else if (data[property].toString().length > 12) {

          this.jsonItem.push({ header: property, dataType: "Timestamp" })
        }
      } else if (typeof data[property] === 'string' && data[property].length <= 256) {

        //this is where we will check for date string

        let isDate = new Date(data[property])

        var regexp = new RegExp("^(012[1-9]|3[0-1]){1}[/-]?(0[1-9]|1[0-2]){1}[/-]?((19|20)?[0-9][0-9])")

        var regexp2 = new RegExp("^(0[1-9]|1[0-2]){1}[/-]?(012[1-9]|3[0-1]){1}[/-]?((19|20)?[0-9][0-9])")

        if (isDate.toString() != 'Invalid Date' || regexp.test(data[property]) || regexp2.test(data[property])) {

          this.jsonItem.push({ header: property, dataType: "Date" })

        } else {

          this.jsonItem.push({ header: property, dataType: "String" })

        }

      } else if ((typeof data[property] === 'string' && data[property].length > 256)) {

        this.jsonItem.push({ header: property, dataType: "String" })

      }
      console.log(this.jsonItem);

    }
    this.ingestionSharingService.setStepTwoStreamingKafkaStatement(this.jsonCodeSnippet);
    this.ingestionSharingService.setStepTwoStreamingDataSourceData(this.jsonItem);
    console.log(this.ingestionSharingService.getStepTwoStreamingDataSourceData());
  }

  setHeaderDataType(index, value) {
    this.jsonItem[index].dataType = value;
    console.log(this.jsonItem)
  }

  kafkaForm = this.formBuilder.group({
    name: this.stepOneStreamingDataSourceInformation.name,
    description: this.stepOneStreamingDataSourceInformation.description,
    topic: this.stepOneStreamingDataSourceInformation.topic,
    hostname: this.stepOneStreamingDataSourceInformation.hostname,
    port: this.stepOneStreamingDataSourceInformation.port,
    projectName: this.projectName,
    fields: [""]

  })

  submitDataSource(data: any) {
    this.kafkaForm.patchValue({
      name: this.stepOneStreamingDataSourceInformation.name,
      description: this.stepOneStreamingDataSourceInformation.description,
      topic: this.stepOneStreamingDataSourceInformation.topic,
      hostname: this.stepOneStreamingDataSourceInformation.hostname,
      port: this.stepOneStreamingDataSourceInformation.port,
      projectName: this.projectName,
      fields: this.jsonItem
    });
    console.log(this.kafkaForm.value);
    this.streamingDataSourceService.postStreamDataSource(this.kafkaForm.value).subscribe(response => {
      console.log(response);
      this.router.navigate([`/project/${this.projectName}/data-sources`]);
      this.success("Data source successfully saved");
      this.deleteData();
    })
  }

  success(status: string) {
    Swal.fire({
      type: 'Success',
      title: 'Success!',
      text: status,
      confirmButtonText: 'Ok',
    })
  }
  deleteData() {
    localStorage.removeItem("stepOneStreamingDataSource");
    localStorage.removeItem("stepTwoStreamingDataSource");
    localStorage.removeItem("stepTwoStreamingKafkaStatement");

  }
  ngOnInit(): void {
    console.log(this.ingestionSharingService.getStepOneStreamingDataSourceData());
    if (this.ingestionSharingService.getStepTwoStreamingDataSourceData() != null) {
      this.jsonItem = this.ingestionSharingService.getStepTwoStreamingDataSourceData();
      this.jsonCodeSnippet = this.ingestionSharingService.getStepTwoStreamingKafkaStatement();
    }
  }

}



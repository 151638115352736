<div *ngIf="isLoading" class="">
    <div class="loader"></div>
    <figure class="spinner-border loaderico"></figure>
</div>


<aside class=" position-relative">
    <div class="db_box menublock">
        <div class="text-center">

            <h4 class="cardtitle tenmt">
                <img class="cardimg" src="{{identifyDriver(ingestion.databaseType)}}">
                <span>{{ingestion.name.toUpperCase()}}</span>
            </h4>
            <span class="titleBorder"> </span>



            <div class="ingest-card--duration">
                Duration : <span>{{miliConvert(ingestion.duration)}}</span>
            </div>
            <div class="ingest-card--ingest_type flex-util">
                <i class="{{bulkImportIcon(ingestion.bulkImport)}}"></i>
                <!-- <span>{{bulkImport(ingestion.bulkImport).toUpperCase()}}</span> -->
                <span>{{ingestion.ingestionType.toUpperCase()}}</span>
            </div>
            <div class="ingest-card-status">
                <span class="{{changeStatusColor(ingestion.status)}}">{{returnStatus(ingestion.status)}}</span>
            </div>
            <div class="ingest-card--action icon_c flex-util">
                <!-- run ingestion -->
                <button [disabled]="ingestion.status === 'IN_PROGRESS'" class="float-right iconbtn fivemr" type="button"
                    ngbTooltip="Run Ingestion" placement="bottom" (click)="runIngestion()">
                    <i class="fa fa-play"></i>
                </button>
                <!-- delete ingestion -->
                <button class="float-right iconbtn fivemr" (click)="deleteIngestion(ingestion.id)"
                    ngbTooltip="Delete Ingestion" placement="bottom">
                    <i class="fa fa-trash fa-lg"></i>
                </button>
                <!-- edit ingestion -->
                <button class="float-right iconbtn fivemr" (click)="toEditIngestionForm()" ngbTooltip="Edit Ingestion"
                    placement="bottom">
                    <i class="fa fa-pencil fa-lg" [hidden]="isActionHidden()"></i>
                </button>
                <!-- View Ingestion -->
                <button class="float-right iconbtn fivemr" ngbTooltip="View Ingestion Details" placement="bottom"
                    (click)="navigateToViewIngestion()">
                    <i class="fa fa-eye"></i>
                </button>
            </div>
        </div>
    </div>

</aside>
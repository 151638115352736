<app-navigation [ngClass]="{'adjusticons':isSidenav}"></app-navigation>
  <div  (click)="isSidenav=!isSidenav;" [ngClass]="{'fullwidth':isSidenav}" class="collapsebtn large" > 
    <i class="fa fa-bars sidenavbtn expandBtn" aria-hidden="true"></i>
  </div>

  <div  class="collapsebtn small" > 
    <i class="fa fa-bars sidenavbtn expandBtn" aria-hidden="true"></i>
  </div>

<!-- Main view/routes wrapper-->
<div class="sidenav_sh flex-shrink-0">
  <main class="page-ec expandmode" [ngClass]="{'expandmode':isSidenav}">
    <app-side-nav></app-side-nav>
    
    <!-- profile block-->
    <div class="pagec cm-content-blocks">
      <!-- <div *ngIf="refreshStatus" class="">
        <div class="loader"></div>
        <figure class="spinner-border loaderico"></figure>
      </div>    -->
        <router-outlet></router-outlet>
      
        <section class="profilec">
          <div class="profilebg"> </div>
          <div class="profileblock"> 
              <div class="closeprofile"> 
                  <i class="fa fa-times profileclick" aria-hidden="true"></i>
              </div>
              <app-profile></app-profile> 
          </div>
        </section>
    </div>

    <!-- user notification -->
    <!-- user notification -->
    <section class="notificationec">
      <div class="notificationbg"> 
        
      </div>
      <div class="notificationblock"> 
        <div class="closenotification"> 
              <i id="closeNotificationBtn" class="fa fa-times " aria-hidden="true"></i>
          </div>
          <section class="inputtextb "> 
           
            <div class="inputc fieldtctext notify_c"> 
              <aside class="rounded-circle notify_icon tenmr fivep">
                <i class="fa fa fa-wrench" aria-hidden="true"></i>
              </aside> 
              <div class="notify_text"> <a routerLink="/project" [routerLinkActive]="['active']">Item Message </a> </div>
            </div>

            <div class="inputc fieldtctext notify_c"> 
              <aside class="rounded-circle notify_icon tenmr fivep">
                <i class="fa fa fa-user" aria-hidden="true"></i>
              </aside> 
              <div class="notify_text"> <a >Item Message</a></div>
            </div>

          </section>
          <div class="clearall"><a >Clear all notifications</a></div>
        </div>
    </section>

  </main>
</div>
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ProjectControllerService } from "../../../../services/project/project-controller.service"
import { FormControl, NgForm, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { SideNavServiceService } from "../../../../services/side-nav/side-nav-service.service"
import { ProjectService } from "../../../../services/project/project-service.service"
import * as $ from 'jquery';
import Swal from 'sweetalert2/dist/sweetalert2.js';

import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
declare var $: any;

@Component({
  selector: 'app-all-projects',
  templateUrl: './all-projects.component.html',
  styleUrls: ['./all-projects.component.scss']
})
export class AllProjectsComponent implements OnInit, AfterViewInit {

  term: string
  projects: any = [];
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  confirmDelete: any
  load = true;
  page = 1;
  numberOfProjects: number;
  constructor(
    private projectControllerService: ProjectControllerService,
    private formBuilder: FormBuilder,
    public nav: SideNavServiceService,
    public projectService: ProjectService,
    private snackBar: MatSnackBar,

  ) { }


  refreshStatus: boolean = true;
  project: any = [];

  projectForm = this.formBuilder.group({
    name: ["", Validators.required],
    description: [""],

  })

  projectFormEdit = this.formBuilder.group({
    createdAt: [""],
    id: [""],
    name: [""],
    description: [""],
    owner: [""],
    permittedUsers: [""]

  })

  populateEditForm(data: any) {
    console.log(data);
    this.projectFormEdit.patchValue({
      createdAt: data.createdAt,
      id: data.id,
      name: data.name,
      description: data.description,
      owner: data.owner,
      permittedUsers: data.permittedUsers
    })
    console.log(this.projectFormEdit.value);
  }
  sortAscending(data: any) {
    data.sort((a: any, b: any) => (a.createdAt > b.createdAt) ? 1 : -1)

  }
  sortDescending(data: any) {
    data.sort((a: any, b: any) => (a.createdAt < b.createdAt) ? 1 : -1)
  }
  clearEditForm() {
    this.projectFormEdit.controls['description'].markAsPristine();
  }
  clearprojectForm() {
    // this.projectForm.controls['name'].reset();
    this.projectForm.reset();
    
  }

  getProjectById(id: any) {
    this.projectControllerService.getSingleProjects(id).subscribe(res => {
      this.project = res;
      console.log("project info:")
      console.log(res)
    })
  }

  ngAfterViewInit() {
    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);
    });
  }

  getAllPermittedProjects() {
    this.load = true;
    this.projectControllerService.getAllPermittedProjects().subscribe(res => {
      this.projects = res;
      this.numberOfProjects = res.length;
      console.log(this.projects.length)
      this.projects.sort((a: any, b: any) => (a.createdAt < b.createdAt) ? 1 : -1)
      this.load = false;
    })
  }

  console(input: any) {
    console.log("Hello")
    console.log(input)
  }

  postProject() {

    this.projectControllerService.postProjects(this.projectForm.value).subscribe(res => {
      console.log("project is:")
      console.log(res)
      this.getAllPermittedProjects()
      this.success("Project created")

    })
    $('#projectModal').modal().hide();

    this.projectForm.reset();
  }

  updateProject(data: any) {

    console.log("Data is")
    console.log(data.value)
    Swal.fire({
      type: 'warning',

      title: "Are you sure you want to make these updates to the project '" + data.value.name + "'?",
      // text: 'All information associated with this user will be permanently deleted',
      showCancelButton: true, confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      if (result.isConfirmed == true) {


        this.projectControllerService.updateProjects(data.value).subscribe(projectRes => {
          console.log("updated project is: ");
          console.log(projectRes);
          this.success("Project has been updated");
          this.refresh();
          this.getAllPermittedProjects()

          $('#projectModalEdit').modal().hide();
        })
      }
    })
  }

  refresh() {
    this.load = true;
    setTimeout(() => this.load = false, 500)
  }


  deleteProject(name: string) {
    Swal.fire({
      type: 'warning',

      title: "Are you sure you want to delete the project '" + name + "'?",
      text: "All information associated with the project '" + name + "' will be permanently deleted",
      showCancelButton: true, confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      console.log(result)
      if (result.isConfirmed == true) {
        this.projectControllerService.deleteProjects(name).subscribe(res => {
          console.log("project deleted: ")
          console.log(res)
          this.refresh();
          Swal.fire('Project Deleted!')
          this.getAllPermittedProjects()


        })
      }
    })






  }

  displayOwners(data: []): string {
    console.log(data.length)
    if (data.length > 0) {
      return " " + data

    } else {
      return "No owner assigned to project"
    }
  }


  displayPermittedUsers(data: []): string {
    if (data.length > 0) {
      return data.join(", ")

    } else {
      return "No permitted users for project"
    }
  }

  saveCurrentProject(name: string) {
    console.log(name)
    this.projectService.saveCurrentProject(name)
  }

  openSnackBar(status: string, statusLogo: string) {
    this.snackBar.open(status, statusLogo, {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['tertiary-active']

    });
  }
  success(status: string) {
    Swal.fire({
      type: 'Success',

      title: 'Success!',
      text: status,
      confirmButtonText: 'Ok',
    })
  }
  warning() {
    Swal.fire({
      type: 'warning',

      title: "Are you sure you want to delete this project?",
      text: 'All information associated with this project will be permanently deleted',
      showCancelButton: true, confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    })

  }

  ngOnInit(): void {
    console.log(this.projects.length)

    this.getAllPermittedProjects()
    this.nav.outOfProject()
    setTimeout(() => this.refreshStatus = false, 500)
    setTimeout(() => this.load = false, 500)

  }

}

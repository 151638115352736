import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { RdbmsIngestionControllerService } from 'src/app/services/api/rdbms-controller/rdbms-ingestion-controller.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { ProjectService } from 'src/app/services/project/project-service.service';
import { IngestionSharingServiceService } from 'src/app/ingestion-sharing-service.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-step-four-query-ingestion',
  templateUrl: './step-four-query-ingestion.component.html',
  styleUrls: ['./step-four-query-ingestion.component.scss'],
})
export class StepFourQueryIngestionComponent implements OnInit {
  //snackbar
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  //datasource
  isSubmitShow: boolean = false;
  load = true;
  id: string;
  projectName: string;
  stepOneData: any;
  stepTwoData: any;
  stepThreeData: any;

  advancedConfiguration: any;

  showDiv = {
    stepone: false,
    steptwo: false,
    stepthree: false,
    stepfour: true,
    stepfive: false,

    stepbtnone: true,
    stepbtntwo: false,
    stepbtnthree: false,
    stepbtnfour: false,
    stepbtnfive: false,

    cancelbtn: false,
    nextbtn: true,
    nextbtntwo: false,
    nextbtnthree: false,
    nextbtnfour: false,

    backbtn: false,
    backbtntwo: false,
    backbtnthree: false,
    backbtnfour: false,
    backbtnfive: false,

    submitbtn: false,
  };

  constructor(
    private formBuilder: FormBuilder,
    public service: RdbmsIngestionControllerService,
    public router: Router,
    public projectService: ProjectService,
    private activatedRoute: ActivatedRoute,
    private ingestionService: IngestionSharingServiceService
  ) {}

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.params['id'];

    this.projectName = this.activatedRoute.snapshot.params['id2'];

    this.advancedConfiguration = this.formBuilder.group({
      ingestionType: ['Query'],
      sourceId: [''],
      name: [''],
      description: [''],
      query: [''],
      bulkImport: [''],
      numMappers: ['4', Validators.required],
      splitLimit: ['0', Validators.required],
      fetchSize: ['1000', Validators.required],
      validate: ['false', Validators.required],
      projectName: this.projectName,
      fileType: [''],
      splitBy: [''],
      databases: this.formBuilder.array([]),
    });

    this.stepOneData = this.ingestionService.getStepOneQueryData();
    this.stepTwoData = this.ingestionService.getStepTwoQueryData();
    this.stepThreeData = this.ingestionService.getStepThreeQueryData();
    this.load = false;
  }

  onSubmit(savedRecord: any) {
    this.advancedConfiguration.patchValue({
      sourceId: this.id,
      name: this.stepOneData.name,
      description: this.stepOneData.description,
      query: this.stepTwoData.query,
      bulkImport: false,
      numMappers: savedRecord.value.numMappers,
      splitLimit: savedRecord.value.splitLimit,
      fetchSize: savedRecord.value.fetchSize,
      projectName: this.projectName,
      fileType: this.stepThreeData.fileType,
      splitBy: this.stepTwoData.splitBy,

      // compress: savedRecord.value.compress,
      validate: savedRecord.value.validate,
    });
    if (this.stepThreeData.ingestionMode != 'full load') {
      this.advancedConfiguration.setControl(
        'databases',
        this.formBuilder.array([
          {
            sourceDatabaseName: this.stepThreeData.sourceDatabaseName,
            destinationDatabaseName: this.stepThreeData.databaseName,
            tables: [
              {
                destinationTableName: this.stepThreeData.tableName,
                incremental: this.stepThreeData.ingestionMode,
                columns: [
                  {
                    destinationColumnName: this.stepThreeData.checkColumn,
                    checkColumn: true,
                  },
                ],
              },
            ],
          },
        ])
      );
    } else {
      this.advancedConfiguration.setControl(
        'databases',
        this.formBuilder.array([
          {
            sourceDatabaseName: this.stepThreeData.sourceDatabaseName,
            destinationDatabaseName: this.stepThreeData.databaseName,
            tables: [
              {
                destinationTableName: this.stepThreeData.tableName,
                incremental: 'full load',
              },
            ],
          },
        ])
      );
    }
    this.load = true;

    this.service.postRdbmsIngestion(this.advancedConfiguration.value).subscribe(
      (response: any) => {
        localStorage.removeItem('stepOneQuery');
        localStorage.removeItem('stepTwoQuery');
        localStorage.removeItem('querySQLStatement');
        this.load = false;
        this.success(`Query ingestion '${response.name}' successfully created`);
      },
      () => {
        this.load = false;
        this.router.navigateByUrl(
          `project/${this.projectName}/ingest/select-ingest`
        );
      }
    );
  }

  success(status: string) {
    Swal.fire({
      type: 'Success',
      title: 'Success!',
      text: status,
      confirmButtonText: 'Ok',
    }).then(() => {
      this.router.navigateByUrl('project/' + this.projectName + '/ingest');
    });
  }
}

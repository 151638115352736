import { Component, OnInit, AfterViewInit } from '@angular/core';
import { RdbmsIngestionControllerService } from "../../services/api/rdbms-controller/rdbms-ingestion-controller.service";
import { JobControllerService } from "../../services/api/job/job-controller.service"
import { DataSourceControllerService } from '../../services/api/data-source/data-source-controller.service'
import { ProjectControllerService } from "../../services/project/project-controller.service"
import { ProjectService } from 'src/app/services/project/project-service.service';
import { SideNavServiceService } from 'src/app/services/side-nav/side-nav-service.service';
import { Router } from '@angular/router';
import { ScheduleService } from 'src/app/services/scheduler/schedule.service';
import { ProjectsService } from 'src/app/services/api/projects.service';
declare var $: any;

@Component({
  selector: 'app-main-projects-homepage',
  templateUrl: './main-projects-homepage.component.html',
  styleUrls: ['./main-projects-homepage.component.scss']
})
export class MainProjectsHomepageComponent implements OnInit, AfterViewInit {



  ingestionCount: any;
  jobCount: any;
  dataSourceCount: any;
  projectCount: any;
  scheduleCount: any;

  load = true;


  constructor(public rdbmsService: RdbmsIngestionControllerService,
    public jobService: JobControllerService,
    private dataSourceControllerService: DataSourceControllerService,
    private projectControllerService: ProjectControllerService,
    public sideNavServiceService: SideNavServiceService,
    private projectService: ProjectService,
    private scheduleService: ScheduleService,
    private ProjectsServiceForCount: ProjectsService,
    private router: Router
  ) { }

  ngAfterViewInit() {
    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);
    });
  }


  // getIngestionCount() {
  //   this.rdbmsService.getIngestionCount().subscribe(res => {
  //     this.ingestionCount = res;
  //   })
  // }

  // getJobCount() {
  //   this.jobService.getJobCount().subscribe(res => {
  //     this.jobCount = res;
  //   })
  // }


  // getDataSourceCount() {
  //   this.dataSourceControllerService.getDataSourceCount().toPromise().then(res => {
  //     this.dataSourceCount = res;
  //   })
  // }

  // getProjectCount() {
  //   this.projectControllerService.getProjectCount().subscribe(res => {
  //     this.projectCount = res;
  //   })
  // }

  getScheduleCount() {
    this.scheduleService.getScheduleCount().subscribe(res => {
      this.scheduleCount = res;
      console.log(res);
    })
  }

  getIngestionCount() {
    this.ProjectsServiceForCount.getAllIngestionsCount().subscribe(res => {
      this.ingestionCount = res;
    })
  }

  getJobCount() {
    this.ProjectsServiceForCount.getAllJobsCount().subscribe(res => {
      this.jobCount = res;
    })
  }


  getDataSourceCount() {
    this.ProjectsServiceForCount.getAllDataSourceCount().toPromise().then(res => {
      this.dataSourceCount = res;
    })
  }

  getProjectCount() {
    this.ProjectsServiceForCount.getAllProjectsCount().subscribe(res => {
      this.projectCount = res;
    })
  }


  carryData() {
    this.router.navigate([`/project`]);

  }
  ngOnInit(): void {
    this.getIngestionCount();
    this.getJobCount();
    this.getDataSourceCount();
    this.getProjectCount();
    this.getScheduleCount();
    this.sideNavServiceService.outOfProject();
    this.projectService.clearCurrentProject();
    setTimeout(() => this.load = false, 500)
  }

}

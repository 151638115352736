import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthComponent } from './auth/auth.component';
import { HomeComponent } from './components/projects/dashboard/home/home.component';
import { DataSourceComponent } from './components/projects/data-sources/data-source/data-source.component';
import { ConfigureDataSourcesComponent } from './components/projects/data-sources/configure-data-sources/configure-data-sources.component';
import { CreateAndTestConnectionComponent } from './components/projects/data-sources/create-and-test-connection/create-and-test-connection.component';
import { JobsProgressComponent } from './components/projects/jobs-progress/jobs-progress.component';
import { SelectIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workflowGeneral/select-ingestion/select-ingestion.component';
import { IngestLandingPageComponent } from './components/projects/ingest/ingest-landing-page/ingest-landing-page.component';
import { DatabaseViewExecutionComponent } from './components/projects/ingest/rdbmsWorkFlow/workflowGeneral/database-view-execution/database-view-execution.component';
import { DatabaseViewDetailsComponent } from './components/projects/ingest/rdbmsWorkFlow/workflowGeneral/database-view-details/database-view-details/database-view-details.component';
import { AllProjectsComponent } from './components/projects/projects-landing-page/all-projects/all-projects.component';
import { CreateAndTestDb2Component } from './components/projects/data-sources/create-and-test-db2/create-and-test-db2.component';
import { CreateAndTestOracleComponent } from './components/projects/data-sources/create-and-test-oracle/create-and-test-oracle.component';
import { UserLandingPageComponent } from './components/userManagement/user-landing-page.component';
import { AccessLandingPageComponent } from './components/projects/access/access-landing-page.component';
import { ProjectsHomePageComponent } from './components/projects/projects-landing-page/projects-home-page/projects-home-page.component';
import { DatabaseViewValidationComponent } from './components/projects/ingest/rdbmsWorkFlow/workflowGeneral/database-view-validation/database-view-validation.component';
import { AuthGuard } from './guard/auth/auth.guard';
import { StepOneCustomIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/custom/step-one-custom-ingestion/step-one-custom-ingestion.component';
import { StepTwoCustomIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/custom/step-two-custom-ingestion/step-two-custom-ingestion.component';
import { StepThreeCustomIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/custom/step-three-custom-ingestion/step-three-custom-ingestion.component';
import { StepFourCustomIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/custom/step-four-custom-ingestion/step-four-custom-ingestion.component';
import { MainProjectsHomepageComponent } from './components/home/main-projects-homepage.component';
import { StepOneDatabaseIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/database/step-one-database-ingestion/step-one-database-ingestion.component';
import { StepTwoDatabaseIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/database/step-two-database-ingestion/step-two-database-ingestion.component';
import { StepThreeDatabaseIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/database/step-three-database-ingestion/step-three-database-ingestion.component';
import { PageNotFoundComponent } from './navigation/page-not-found/page-not-found.component';
import { EditStepOneCustomIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/edit/table/edit-step-one-custom-ingestion/edit-step-one-custom-ingestion.component';
import { EditStepTwoCustomIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/edit/table/edit-step-two-custom-ingestion/edit-step-two-custom-ingestion.component';
import { EditStepThreeCustomIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/edit/table/edit-step-three-custom-ingestion/edit-step-three-custom-ingestion.component';
import { EditStepOneDatabaseComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/edit/database/edit-step-one-database/edit-step-one-database.component';
import { EditStepTwoDatabaseComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/edit/database/edit-step-two-database/edit-step-two-database.component';
import { EditStepThreeDatabaseComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/edit/database/edit-step-three-database/edit-step-three-database.component';
import { EditStepFourCustomIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/edit/table/edit-step-four-custom-ingestion/edit-step-four-custom-ingestion.component';
import { BasicLayoutComponent } from './layouts/basic-layout/basic-layout.component';
import { BlankLayoutComponent } from './layouts/blank-layout/blank-layout.component';
import { ViewDetailsComponent } from './components/projects/data-sources/view-details/view-details.component';
import { JobScheduleLandingPageComponent } from './components/projects/jobScheduler/job-schedule-landing-page/job-schedule-landing-page.component';
import { NewJobScheduleComponent } from './components/projects/jobScheduler/new-job-schedule/new-job-schedule.component';
import { EditScheduleComponent } from './components/projects/jobScheduler/edit-schedule/edit-schedule.component';
import { StepOneQueryIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/query/step-one-query-ingestion/step-one-query-ingestion.component';
import { StepTwoQueryIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/query/step-two-query-ingestion/step-two-query-ingestion.component';
import { StepThreeQueryIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/query/step-three-query-ingestion/step-three-query-ingestion.component';
import { StepFourQueryIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/query/step-four-query-ingestion/step-four-query-ingestion.component';
import { StepOneStreamDataSourceComponent } from './components/projects/data-sources/ConfigureStreamDataSource/step-one-stream-data-source/step-one-stream-data-source.component';
import { StepTwoStreamDataSourceComponent } from './components/projects/data-sources/ConfigureStreamDataSource/step-two-stream-data-source/step-two-stream-data-source.component';
import { StepOneStreamIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/stream/step-one-stream-ingestion/step-one-stream-ingestion.component';
import { StepTwoStreamIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/stream/step-two-stream-ingestion/step-two-stream-ingestion.component';
import { StepThreeStreamIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/stream/step-three-stream-ingestion/step-three-stream-ingestion.component';
import { StreamIngestionViewDetailsComponent } from './components/projects/ingest/rdbmsWorkFlow/workflowGeneral/stream-ingestion-view-details/stream-ingestion-view-details.component';
import { ViewStreamDetailsComponent } from './components/projects/data-sources/view-stream-details/view-stream-details.component';
import { EditStepTwoStreamIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/edit/stream/edit-step-two-stream-ingestion/edit-step-two-stream-ingestion.component';
import { EditStepOneStreamIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/edit/stream/edit-step-one-stream-ingestion/edit-step-one-stream-ingestion.component';
import { EditStepOneQueryComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/edit/query/edit-step-one-query/edit-step-one-query.component';
import { EditStepTwoQueryComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/edit/query/edit-step-two-query/edit-step-two-query.component';
import { EditStepThreeQueryComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/edit/query/edit-step-three-query/edit-step-three-query.component';
import { EditStepFourQueryComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/edit/query/edit-step-four-query/edit-step-four-query.component';
import { DashboardNewComponent } from './components/projects/dashboard/dashboard-new/dashboard-new.component';

const routes: Routes = [
  //login
  // {
  //   path: '',
  //   children: [
  //    {
  //      path: '', component: AuthComponent
  //     },
  //     {
  //       path: '404', component: PageNotFoundComponent
  //     },
  //   ]
  // },
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      {
        path: 'auth',
        children: [{ path: '', component: AuthComponent }],
      },
    ],
  },
  {
    path: '',
    component: BasicLayoutComponent,
    children: [
      {
        path: 'home',
        children: [
          {
            path: '',
            component: MainProjectsHomepageComponent,
            canActivate: [AuthGuard],
            data: { role: ['ROLE_USER', 'ROLE_ADMIN'] },
          },
        ],
      },

      {
        path: 'project',
        children: [
          {
            path: '',
            component: AllProjectsComponent,
            canActivate: [AuthGuard],
            data: { role: ['ROLE_USER', 'ROLE_ADMIN'] },
          },
          {
            path: ':id2',
            children: [
              {
                path: '',
                component: ProjectsHomePageComponent,
                canActivate: [AuthGuard],
                data: { role: ['ROLE_USER', 'ROLE_ADMIN'] },
              },
              //project pages
              {
                path: 'dashboard',
                component: DashboardNewComponent,
                canActivate: [AuthGuard],
                data: {
                  role: [
                    'PERM_VIEWER',
                    'PERM_OPERATOR',
                    'PERM_MAINTAINER',
                    'PERM_OWNER',
                    'PERM_ADMIN',
                  ],
                },
              },
              {
                path: 'ingest-board',
                component: DashboardNewComponent,
                canActivate: [AuthGuard],
                data: {
                  role: [
                    'PERM_VIEWER',
                    'PERM_OPERATOR',
                    'PERM_MAINTAINER',
                    'PERM_OWNER',
                    'PERM_ADMIN',
                  ],
                },
              },

              //data sources
              {
                path: 'data-sources',
                children: [
                  {
                    path: '',
                    component: DataSourceComponent,
                    canActivate: [AuthGuard],
                    data: {
                      role: [
                        'PERM_VIEWER',
                        'PERM_OPERATOR',
                        'PERM_MAINTAINER',
                        'PERM_OWNER',
                        'PERM_ADMIN',
                      ],
                    },
                  },
                  {
                    path: 'select-database',
                    component: ConfigureDataSourcesComponent,
                    canActivate: [AuthGuard],
                    data: {
                      role: ['PERM_MAINTAINER', 'PERM_OWNER', 'PERM_ADMIN'],
                    },
                  },
                  // {
                  //   path: 'configureStreamDataSource', component: ConfigureStreamDataSourceComponent,
                  //   canActivate: [AuthGuard],
                  //   data: { role: ['PERM_MAINTAINER', 'PERM_OWNER', 'PERM_ADMIN'] }
                  // },
                  {
                    path: 'configureStreamDataSource/stepOne',
                    component: StepOneStreamDataSourceComponent,
                    canActivate: [AuthGuard],
                    data: {
                      role: ['PERM_MAINTAINER', 'PERM_OWNER', 'PERM_ADMIN'],
                    },
                  },
                  {
                    path: 'configureStreamDataSource/stepTwo',
                    component: StepTwoStreamDataSourceComponent,
                    canActivate: [AuthGuard],
                    data: {
                      role: ['PERM_MAINTAINER', 'PERM_OWNER', 'PERM_ADMIN'],
                    },
                  },
                  {
                    path: ':id3',
                    component: CreateAndTestConnectionComponent,
                    canActivate: [AuthGuard],
                    data: {
                      role: ['PERM_MAINTAINER', 'PERM_OWNER', 'PERM_ADMIN'],
                    },
                  },
                  {
                    path: 'ibm/db2',
                    component: CreateAndTestDb2Component,
                    canActivate: [AuthGuard],
                    data: {
                      role: ['PERM_MAINTAINER', 'PERM_OWNER', 'PERM_ADMIN'],
                    },
                  },
                  {
                    path: 'oracle/oracle',
                    component: CreateAndTestOracleComponent,
                    canActivate: [AuthGuard],
                    data: {
                      role: ['PERM_MAINTAINER', 'PERM_OWNER', 'PERM_ADMIN'],
                    },
                  },
                  {
                    path: 'view-details/:id',
                    component: ViewDetailsComponent,
                    canActivate: [AuthGuard],
                    data: {
                      role: [
                        'PERM_VIEWER',
                        'PERM_OPERATOR',
                        'PERM_MAINTAINER',
                        'PERM_OWNER',
                        'PERM_ADMIN',
                      ],
                    },
                  },
                  {
                    path: 'view-stream-details/:id',
                    component: ViewStreamDetailsComponent,
                    canActivate: [AuthGuard],
                    data: {
                      role: [
                        'PERM_VIEWER',
                        'PERM_OPERATOR',
                        'PERM_MAINTAINER',
                        'PERM_OWNER',
                        'PERM_ADMIN',
                      ],
                    },
                  },
                ],
              },
              //ingest Workflow
              {
                path: 'ingest',
                children: [
                  {
                    path: '',
                    component: DashboardNewComponent,
                    canActivate: [AuthGuard],
                    data: {
                      role: [
                        'PERM_VIEWER',
                        'PERM_OPERATOR',
                        'PERM_MAINTAINER',
                        'PERM_OWNER',
                        'PERM_ADMIN',
                      ],
                    },
                  },
                  {
                    path: 'select-ingestion',
                    children: [
                      {
                        path: '',
                        component: SelectIngestionComponent,
                        canActivate: [AuthGuard],
                        data: {
                          role: ['PERM_MAINTAINER', 'PERM_OWNER', 'PERM_ADMIN'],
                        },
                      },
                      //stepper custom
                      {
                        path: 'stepOneCustom',
                        component: StepOneCustomIngestionComponent,
                        canActivate: [AuthGuard],
                        data: {
                          role: ['PERM_MAINTAINER', 'PERM_OWNER', 'PERM_ADMIN'],
                        },
                      },
                      {
                        path: 'stepTwoCustom/:id',
                        component: StepTwoCustomIngestionComponent,
                        canActivate: [AuthGuard],
                        data: {
                          role: ['PERM_MAINTAINER', 'PERM_OWNER', 'PERM_ADMIN'],
                        },
                      },
                      {
                        path: 'stepThreeCustom/:id',
                        component: StepThreeCustomIngestionComponent,
                        canActivate: [AuthGuard],
                        data: {
                          role: ['PERM_MAINTAINER', 'PERM_OWNER', 'PERM_ADMIN'],
                        },
                      },
                      {
                        path: 'stepFourCustom/:id',
                        component: StepFourCustomIngestionComponent,
                        canActivate: [AuthGuard],
                        data: {
                          role: ['PERM_MAINTAINER', 'PERM_OWNER', 'PERM_ADMIN'],
                        },
                      },

                      //stepper database
                      {
                        path: 'stepOneDatabase',
                        component: StepOneDatabaseIngestionComponent,
                        canActivate: [AuthGuard],
                        data: {
                          role: ['PERM_MAINTAINER', 'PERM_OWNER', 'PERM_ADMIN'],
                        },
                      },
                      {
                        path: 'stepTwoDatabase/:id',
                        component: StepTwoDatabaseIngestionComponent,
                        canActivate: [AuthGuard],
                        data: {
                          role: ['PERM_MAINTAINER', 'PERM_OWNER', 'PERM_ADMIN'],
                        },
                      },
                      {
                        path: 'stepThreeDatabase/:id',
                        component: StepThreeDatabaseIngestionComponent,
                        canActivate: [AuthGuard],
                        data: {
                          role: ['PERM_MAINTAINER', 'PERM_OWNER', 'PERM_ADMIN'],
                        },
                      },

                      //stepper query
                      {
                        path: 'stepOneQuery',
                        component: StepOneQueryIngestionComponent,
                        canActivate: [AuthGuard],
                        data: {
                          role: ['PERM_MAINTAINER', 'PERM_OWNER', 'PERM_ADMIN'],
                        },
                      },
                      {
                        path: 'stepTwoQuery/:id',
                        component: StepTwoQueryIngestionComponent,
                        canActivate: [AuthGuard],
                        data: {
                          role: ['PERM_MAINTAINER', 'PERM_OWNER', 'PERM_ADMIN'],
                        },
                      },
                      {
                        path: 'stepThreeQuery/:id',
                        component: StepThreeQueryIngestionComponent,
                        canActivate: [AuthGuard],
                        data: {
                          role: ['PERM_MAINTAINER', 'PERM_OWNER', 'PERM_ADMIN'],
                        },
                      },
                      {
                        path: 'stepFourQuery/:id', component: StepFourQueryIngestionComponent,
                        canActivate: [AuthGuard],
                        data: { role: ['PERM_MAINTAINER', 'PERM_OWNER', 'PERM_ADMIN'] }
                      },
                      //stepper stream
                      {
                        path: 'stepOneStream',
                        component: StepOneStreamIngestionComponent,
                        canActivate: [AuthGuard],
                        data: {
                          role: ['PERM_MAINTAINER', 'PERM_OWNER', 'PERM_ADMIN'],
                        },
                      },
                      {
                        path: 'stepTwoStream/:id',
                        component: StepTwoStreamIngestionComponent,
                        canActivate: [AuthGuard],
                        data: {
                          role: ['PERM_MAINTAINER', 'PERM_OWNER', 'PERM_ADMIN'],
                        },
                      },
                      {
                        path: 'stepThreeStream/:id',
                        component: StepThreeStreamIngestionComponent,
                        canActivate: [AuthGuard],
                        data: {
                          role: ['PERM_MAINTAINER', 'PERM_OWNER', 'PERM_ADMIN'],
                        },
                      },
                    ],
                  },
                  //edit stepper table
                  {
                    path: 'editStepOneCustom/:id',
                    component: EditStepOneCustomIngestionComponent,
                    canActivate: [AuthGuard],
                    data: {
                      role: ['PERM_MAINTAINER', 'PERM_OWNER', 'PERM_ADMIN'],
                    },
                  },
                  {
                    path: 'editStepTwoCustom/:id/ingestion/:id3',
                    component: EditStepTwoCustomIngestionComponent,
                    canActivate: [AuthGuard],
                    data: {
                      role: ['PERM_MAINTAINER', 'PERM_OWNER', 'PERM_ADMIN'],
                    },
                  },
                  {
                    path: 'editStepThreeCustom/:id/ingestion/:id3',
                    component: EditStepThreeCustomIngestionComponent,
                    canActivate: [AuthGuard],
                    data: {
                      role: ['PERM_MAINTAINER', 'PERM_OWNER', 'PERM_ADMIN'],
                    },
                  },
                  {
                    path: 'editStepFourCustom/:id/ingestion/:id3',
                    component: EditStepFourCustomIngestionComponent,
                    canActivate: [AuthGuard],
                    data: {
                      role: ['PERM_MAINTAINER', 'PERM_OWNER', 'PERM_ADMIN'],
                    },
                  },

                  //edit stepper database
                  {
                    path: 'editStepOneDatabase/:id',
                    component: EditStepOneDatabaseComponent,
                    canActivate: [AuthGuard],
                    data: {
                      role: ['PERM_MAINTAINER', 'PERM_OWNER', 'PERM_ADMIN'],
                    },
                  },
                  {
                    path: 'editStepTwoDatabase/:id/ingestion/:id3',
                    component: EditStepTwoDatabaseComponent,
                    canActivate: [AuthGuard],
                    data: {
                      role: ['PERM_MAINTAINER', 'PERM_OWNER', 'PERM_ADMIN'],
                    },
                  },
                  {
                    path: 'editStepThreeDatabase/:id/ingestion/:id3',
                    component: EditStepThreeDatabaseComponent,
                    canActivate: [AuthGuard],
                    data: {
                      role: ['PERM_MAINTAINER', 'PERM_OWNER', 'PERM_ADMIN'],
                    },
                  },
                  //edit stepper query
                  {
                    path: 'editStepOneQuery/:id',
                    component: EditStepOneQueryComponent,
                    canActivate: [AuthGuard],
                    data: {
                      role: ['PERM_MAINTAINER', 'PERM_OWNER', 'PERM_ADMIN'],
                    },
                  },
                  {
                    path: 'editStepTwoQuery/:id/:id3',
                    component: EditStepTwoQueryComponent,
                    canActivate: [AuthGuard],
                    data: {
                      role: ['PERM_MAINTAINER', 'PERM_OWNER', 'PERM_ADMIN'],
                    },
                  },
                  {
                    path: 'editStepThreeQuery/:id/:id3',
                    component: EditStepThreeQueryComponent,
                    canActivate: [AuthGuard],
                    data: {
                      role: ['PERM_MAINTAINER', 'PERM_OWNER', 'PERM_ADMIN'],
                    },
                  },
                  {
                    path: 'editStepFourQuery/:id/:id3', component: EditStepFourQueryComponent,
                    canActivate: [AuthGuard],
                    data: { role: ['PERM_MAINTAINER', 'PERM_OWNER', 'PERM_ADMIN'] }
                  },
                  //edit stepper stream
                  {
                    path: 'editStepOneStream/:id',
                    component: EditStepOneStreamIngestionComponent,
                    canActivate: [AuthGuard],
                    data: {
                      role: ['PERM_MAINTAINER', 'PERM_OWNER', 'PERM_ADMIN'],
                    },
                  },
                  {
                    path: 'editStepTwoStream/:id/ingestion/:id3',
                    component: EditStepTwoStreamIngestionComponent,
                    canActivate: [AuthGuard],
                    data: {
                      role: ['PERM_MAINTAINER', 'PERM_OWNER', 'PERM_ADMIN'],
                    },
                  },

                  //view ingestion details
                  {
                    path: 'databaseViewDetails/:id',
                    component: DatabaseViewDetailsComponent,
                    canActivate: [AuthGuard],
                    data: {
                      role: [
                        'PERM_VIEWER',
                        'PERM_OPERATOR',
                        'PERM_MAINTAINER',
                        'PERM_OWNER',
                        'PERM_ADMIN',
                      ],
                    },
                  },
                  {
                    path: 'StreamIngestionViewDetails/:id',
                    component: StreamIngestionViewDetailsComponent,
                    canActivate: [AuthGuard],
                    data: {
                      role: [
                        'PERM_VIEWER',
                        'PERM_OPERATOR',
                        'PERM_MAINTAINER',
                        'PERM_OWNER',
                        'PERM_ADMIN',
                      ],
                    },
                  },
                ],
              },
              //jobs
              {
                path: 'jobs',
                children: [
                  {
                    path: '',
                    component: JobsProgressComponent,
                    canActivate: [AuthGuard],
                    data: {
                      role: [
                        'PERM_VIEWER',
                        'PERM_OPERATOR',
                        'PERM_MAINTAINER',
                        'PERM_OWNER',
                        'PERM_ADMIN',
                      ],
                    },
                  },
                ],
              },
              //job scheduler
              {
                path: 'job-schedule-landing-page',
                children: [
                  {
                    path: '',
                    component: JobScheduleLandingPageComponent,
                    canActivate: [AuthGuard],
                    data: {
                      role: [
                        'PERM_VIEWER',
                        'PERM_OPERATOR',
                        'PERM_MAINTAINER',
                        'PERM_OWNER',
                        'PERM_ADMIN',
                      ],
                    },
                  },
                  {
                    path: 'new-job-schedule',
                    component: NewJobScheduleComponent,
                    canActivate: [AuthGuard],
                    data: {
                      role: [
                        'PERM_VIEWER',
                        'PERM_OPERATOR',
                        'PERM_MAINTAINER',
                        'PERM_OWNER',
                        'PERM_ADMIN',
                      ],
                    },
                  },
                  {
                    path: 'edit-job-schedule/:id',
                    component: EditScheduleComponent,
                    canActivate: [AuthGuard],
                    data: {
                      role: [
                        'PERM_VIEWER',
                        'PERM_OPERATOR',
                        'PERM_MAINTAINER',
                        'PERM_OWNER',
                        'PERM_ADMIN',
                      ],
                    },
                  },
                ],
              },
              //project access
              {
                path: 'project-access',
                component: AccessLandingPageComponent,
                canActivate: [AuthGuard],
                data: { role: ['PERM_OWNER', 'PERM_ADMIN'] },
              },
            ],
          },
        ],
      },

      {
        path: 'user-management',
        children: [
          {
            path: '',
            component: UserLandingPageComponent,
            canActivate: [AuthGuard],
            data: { role: ['ROLE_ADMIN'] },
          },
        ],
      },
      //-------------------------end of update to routing --------------------------------------//

      //database view page
      {
        path: 'project/:id2/databaseViewExecution/:id',
        component: DatabaseViewExecutionComponent,
        canActivate: [AuthGuard],
        data: {
          role: [
            'PERM_VIEWER',
            'PERM_OPERATOR',
            'PERM_MAINTAINER',
            'PERM_OWNER',
            'PERM_ADMIN',
          ],
        },
      },
      {
        path: 'project/:id2/databaseViewValidation/:id',
        component: DatabaseViewValidationComponent,
        canActivate: [AuthGuard],
        data: { role: ['PERM_OPERATOR', 'c', 'PERM_ADMIN'] },
      },
      //-------------------------------------^^check with Alazar^^----------------------------------------------------------//

      //error page
      {
        path: 'error',
        component: PageNotFoundComponent,
        canActivate: [AuthGuard],
        data: { role: ['PERM_MAINTAINER', 'PERM_OWNER', 'PERM_ADMIN'] },
      },
      {
        path: '**',
        redirectTo: '/error',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

 <!-- header starts-->
 <header class="header">
    <div class="header-component">
        <nav class="clearafter">
            
          <div class="ico-wtno-txt navbar-brand mr-header-brand-bg logoc pointerc float-left" routerLink="/home">
              <span class="cubemove one"> </span>
          </div>

          <div class="ico-wt-txt navbar-brand mr-header-brand-bg logoc pointerc float-left" routerLink="/home">
            <span class="cubemove one"> </span>
          </div>

          
          <figure class="themeicoec clearafter" id="themeicoec">
            <span id="themeico" onclick="buttonthemefun()">
              <nav class="themeico teal" id="themet_btn" onclick="tealthemefun()"  placement="right" ngbTooltip="UST Theme"></nav>
              <nav class="themeico blue" id="themeb_btn" onclick="bluethemefun()" placement="right" ngbTooltip="Sunlight Theme"></nav>
              <nav class="themeico grey" id="themeg_btn" onclick="greythemefun()" placement="right" ngbTooltip="Night Theme"></nav>
              <nav class="themeico red" id="themer_btn" onclick="redthemefun()"  placement="right" ngbTooltip="Red Theme"></nav>
            </span>
          </figure>
          
          <nav class="profile-icoc clearafter profileclick" >
            <i class="fa fa-caret-down userdown fr displayblock" aria-hidden="true"></i>
            <figure class="headicoc profileicon">
            <img alt="profile" src="{{ingestionSharingService.getAvatarImg()}}">    z                
            </figure>
            <aside class="fl usernamec">
            <span class="username-txt ellipsistxt">{{currentUser?.username}}</span>
            </aside>
          </nav>

          <nav class="pendo_notification notificationc float-right cursor-pointer rounded-circle position-relative">
            <a><i class="fa fa-question threemt twoml" aria-hidden="true"></i></a>
          </nav>


        <!-- 
            <nav class="profile-icoc clearafter profileclick" >
            <i class="fa fa-caret-down userdown fr displayblock" aria-hidden="true"></i>
            <figure class="headicoc profileicon">
            <img alt="profile">                    
            </figure>
            <aside class="fl usernamec">
            <span class="username-txt ellipsistxt">username</span>
            </aside>
            </nav>
         -->

         <!--notification tab-->

            <!-- <nav class="notificationc float-right cursor-pointer notificationclick rounded-circle position-relative"  placement="left">
            <i class="fa fa-bell" aria-hidden="true"></i>
            <span class="notify_count">1</span>
            </nav>  -->
        
       

            <!--
              <div class="collapse navbar-collapse nav-ml headnav">
              
                    <ul class="nav navbar-nav navbar-right">
                      <li class="nav-item" ngbDropdown>
                          <a class="profile-icoc"  id="dropdownForm1" ngbDropdownToggle>
                              <div class="headicoc profileicon">
                                <img [src]="imgURL" *ngIf="imgURL" alt="profile">
                              </div>
                              <span class="mr-link">{{userData?.displayName?userData?.displayName:userData?.username}} </span>
                          </a>
                          <div class="profiledd" ngbDropdownMenu aria-labelledby="dropdownForm1">
                            <figure class="profileimg">
                              <img [src]="imgURL" *ngIf="imgURL" alt="profile">
                            </figure>
                            <nav class="profilebtnc clearafter"> 										
                              <button class="iconbtn fivemr" (click)="onEdit()" ngbDropdownToggle><i class="fa fa-pencil-square-o" aria-hidden="true" ></i> Profile</button>
                              <button class="iconbtn" (click)="logout()"> <i class="fa fa-power-off" aria-hidden="true" alt="Sign out" title="Sign out"></i> Sign Out</button>
                            </nav>
                          </div>
                      </li>
                      <li class="nav-item">
                          <a class="nav-link mr-link" href="#">
                              <div class="headicoc"><img src="../../../assets/images/settings.png"
                                      alt="settings"></div>Settings
                          </a>
                      </li> 
                    </ul>
              </div>
              -->
        </nav>
    </div>
</header>
import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  NgForm,
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  ReactiveFormsModule,
} from '@angular/forms';
import { DataSourceControllerService } from 'src/app/services/api/data-source/data-source-controller.service';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { CatalogService } from '../../../../../../../../services/api/catalogService/catalog.service';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  copyArrayItem,
} from '@angular/cdk/drag-drop';
import { RdbmsIngestionControllerService } from 'src/app/services/api/rdbms-controller/rdbms-ingestion-controller.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { ProjectService } from 'src/app/services/project/project-service.service';
import { IngestionSharingServiceService } from 'src/app/ingestion-sharing-service.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

declare var $: any;

@Component({
  selector: 'app-edit-step-four-custom-ingestion',
  templateUrl: './edit-step-four-custom-ingestion.component.html',
  styleUrls: ['./edit-step-four-custom-ingestion.component.scss'],
})
export class EditStepFourCustomIngestionComponent implements OnInit {
  //snackbar

  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  //datasource

  isSubmitShow: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private dataSourceController: DataSourceControllerService,
    private catalogService: CatalogService,
    public rdbmsService: RdbmsIngestionControllerService,
    public router: Router,
    private snackBar: MatSnackBar,
    public projectService: ProjectService,
    private activatedRoute: ActivatedRoute,
    private ingestionService: IngestionSharingServiceService
  ) {}

  id = this.activatedRoute.snapshot.params['id'];
  ingestionId = this.activatedRoute.snapshot.params['id3'];
  projectName = this.activatedRoute.snapshot.params['id2'];

  load: boolean;

  itemData = this.ingestionService.getStepOneData();
  itemObjectsRight = this.ingestionService.getStepTwoData();

  currentIngestion: any;
  //STEPPER
  //********************************************************************************************************************************************************************************** */

  showDiv = {
    stepone: false,
    steptwo: false,
    stepthree: false,
    stepfour: true,
    stepfive: false,

    stepbtnone: true,
    stepbtntwo: false,
    stepbtnthree: false,
    stepbtnfour: false,
    stepbtnfive: false,

    cancelbtn: false,
    nextbtn: true,
    nextbtntwo: false,
    nextbtnthree: false,
    nextbtnfour: false,

    backbtn: false,
    backbtntwo: false,
    backbtnthree: false,
    backbtnfour: false,
    backbtnfive: false,

    submitbtn: false,
  };

  getIngestion() {
    this.rdbmsService.getSingleRdbmsIngestion(this.id).subscribe((response) => {
      console.log(response);
    });
  }

  advancedConfiguration = this.formBuilder.group({
    id: this.ingestionId,
    ingestionType: ['Table'],
    sourceId: [''],
    name: [''],
    description: [''],
    bulkImport: [''],
    numMappers: ['', Validators.required],
    splitLimit: ['', Validators.required],
    fetchSize: ['', Validators.required],
    validate: ['', Validators.required],
    projectName: this.projectName,
    nullNonString: [''],
    nullString: [''],
    query: [''],
    splitBy: [''],
    append: [''],
    compress: [''],
    // compressionCodec: [""],
    createdAt: [''],
    fileType: [''],
    databases: this.formBuilder.array([]),
  });
  onSubmit(savedRecord: any) {
    for (let i = 0; i < this.itemObjectsRight.length; i++) {
      for (let j = 0; j < this.itemObjectsRight[i].tables.length; j++) {
        delete this.itemObjectsRight[i].tables[j].tableId;

        // for (let k = 0; k < this.itemObjectsRight[i].tables[j].columns.length; k++) {
        //   this.itemObjectsRight[i].tables[j].columns[k].destinationColumnName = this.itemObjectsRight[i].tables[j].columns[k].sourceColumnName
        // }
      }
    }

    this.advancedConfiguration.patchValue({
      id: this.currentIngestion.id,
      sourceId: this.itemData.sourceId,
      name: this.itemData.name,
      description: this.itemData.description,
      bulkImport: false,
      numMappers: savedRecord.value.numMappers,
      splitLimit: savedRecord.value.splitLimit,
      fetchSize: savedRecord.value.fetchSize,
      projectName: this.projectName,
      append: this.currentIngestion.append,
      compress: this.currentIngestion.compress,
      nullNonString: this.currentIngestion.nullNonString,
      nullString: this.currentIngestion.nullString,
      query: this.currentIngestion.query,
      splitBy: this.currentIngestion.splitBy,
      fileType: this.itemData.fileType,

      validate: savedRecord.value.validate,
      // compress: savedRecord.value.compress,
      // compressionCodec: this.currentIngestion.compressionCodec,
      createdAt: this.currentIngestion.createdAt,
    });
    this.advancedConfiguration.setControl(
      'databases',
      this.formBuilder.array(this.itemObjectsRight)
    );

    console.log(this.advancedConfiguration.value);
    // localStorage.removeItem("stepOne")
    // localStorage.removeItem("stepTwo")

    this.rdbmsService
      .updateRdbmsIngestion(this.advancedConfiguration.value)
      .subscribe((response) => {
        let data: any;
        data = response;
        console.log('response');
        console.log(data);

        this.success('Ingestion successfully updated');

        this.router.navigateByUrl('project/' + this.projectName + '/ingest');
      });
  }
  getCurrentIngestion() {
    this.load = true;
    this.rdbmsService
      .getSingleRdbmsIngestion(this.ingestionId)
      .subscribe((response) => {
        this.currentIngestion = response;
        console.log(response);
        this.advancedConfiguration.patchValue({
          numMappers: this.currentIngestion.numMappers,
          splitLimit: this.currentIngestion.splitLimit,
          fetchSize: this.currentIngestion.fetchSize,
          validate: this.currentIngestion.validate,
          fileType: this.currentIngestion.fileType,
        });

        this.load = false;
      });
  }
  success(status: string) {
    Swal.fire({
      type: 'Success',
      title: 'Success!',
      text: status,
      confirmButtonText: 'Ok',
    });
  }

  ngOnInit(): void {
    this.getCurrentIngestion();
    console.log('this.ingestionService.getStepOneData()');

    console.log(this.ingestionService.getStepTwoData());
  }
}

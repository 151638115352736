import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SideNavServiceService {

    constructor() {}
  
    inProject() {
      localStorage.setItem("inProject", "true")
    }

    outOfProject() {localStorage.setItem("inProject", "false")}

    isInProject(): boolean {
      return localStorage.getItem("inProject") == "true"
    }
}

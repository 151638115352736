<div class="clearafter headbtnc">

    <div class="cm-content-blocks">
        <ol class="breadcrumb">
            <li><a routerLink="/home"><i class="fa fa-home"></i>Home</a></li>
            <li class="active"><i class="fa fa-id-badge"></i>My Profile</li>
        </ol>
      </div>
      <h3 class="headc mt-4"> My Profile </h3>
      <div class="subhead fifteenmb">View and edit your current credentials</div>
      <br>
      <br>
      
    <!-- <button class="mr-btn-create pull-right pointerc" data-toggle="modal" data-target="#newUser">Create New User</button>
 -->
  
        <div class="formc inputtextb fifteenmb" >
            <section  class="inputc fieldtctext">
                <label class="labeltext"> Username</label>
                <div class="inputtext"> {{user.authorities.name}}</div>
            </section>
    
            <section  class="inputc fieldtctext">
                <label class="labeltext"> My Email </label>
                <div class="inputtext">  {{user.authorities.principal.email}}</div>
            </section>
            <section  class="inputc fieldtctext">
              <label class="labeltext"> Role</label>
              <div class="inputtext"> {{user.authorities.principal.role.name.split("_")[1]}}</div>
          </section>
  
          <!-- <section  class="inputc fieldtctext">
              <label class="labeltext"> Status: </label>
              <div class="inputtext">  {{user.authorities.enabled}}</div>
          </section> -->
    
         
       
          
        </div>
 
  
  </div>
  <button class="tertiary-active btnnext tenmt" data-toggle="modal" data-target="#changePassword">
    <i aria-hidden="true" class="fa fa-key fivemr"></i>
    Change Password          
</button>
  <button class="tertiary-active btnnext tenmt" data-toggle="modal" data-target="#editUser">
    <i aria-hidden="true" class="fa fa-pencil-square-o fivemr"></i>
    Edit Profile           
</button>

<!-- </section> -->
        
       <!-- <ngb-pagination class="pagination" [collectionSize]="70" [(page)]="page" aria-label="Default pagination"></ngb-pagination> -->
    
        
    <!-- </section> 
</div>
 -->
<!--Modals-->
<!-- New User Modal -->
<!-- Edit Modal -->
<div class="modal" id="editUser">
    <div class="modal-dialog modal-lg">
      <div class="modal-content ">
      
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Edit User</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <!-- Modal body -->
        <div class="modal-body">
            <section class="inputc">
                <!-- <div class="col-md-6 fieldc" >
                    <label class="inputlabel">UserId</label>  -->
                <!-- </div> -->
                <div class="col-md-6 fieldc" >
                  <label class="inputlabel">User Name <span class="text-danger font-weight-bold">*</span></label> 
                  <input disabled="disabled" name="name"  class="input-field" type="text" placeholder="{{credentials.username}}" [(ngModel)]="credentials.username"/>         
              </div>
              <div class="col-md-6 fieldc">
                <label class="inputlabel">Password <span class="text-danger font-weight-bold">*</span></label> 
                <input name="name"  class="input-field" type="text" placeholder="{{credentials.password}}" [(ngModel)]="credentials.password"/>         
            </div>
            </section>
            <section class="inputc">
              <div class="col-md-6 fieldc">
                <label class="inputlabel">Email <span class="text-danger font-weight-bold">*</span></label> 
                <input name="name"  class="input-field" type="text" placeholder="{{user.authorities.principal.email}}" [(ngModel)]="user.authorities.principal.email"/>         
            </div>
            </section>
            <section class="inputc">
             
            <input  hidden name="name"  class="input-field" type="text" placeholder="{{user.id}}" [(ngModel)]="user.id"/>        

               
            </section>
            
        </div>
        
        <!-- Modal footer -->
        <footer class="modalfooter">
          <button type="button" class="done-btn modalpubtn" data-dismiss="modal">Cancel</button>
            <button  class="done-btn modalpubtn">Save </button>
        </footer>
      </div>
    </div>
</div>

<!-- Password Modal -->
<div class="modal" id="changePassword">
    <div class="modal-dialog modal-lg">
      <div class="modal-content ">
      
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Change Password </h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <!-- Modal body -->
        <form [formGroup]="passwordForm" (ngSubmit)="updatePassword()">

        <div class="modal-body" >

            <section class="inputc">
                <!-- <div class="col-md-6 fieldc" >
                    <label class="inputlabel">UserId</label> 
                    <input hidden name="user" class="input-field" type="text" value="{{user.id}}" readonly/>      
                </div> -->
                <!-- <div class="col-md-6 fieldc" >
                    <label class="inputlabel">User Name</label> 
                    <input name="name"  class="input-field" type="text" placeholder="{{user.authorities.username}}" [(ngModel)]="user.authorities.principal.username"/>         
                </div> -->
                <div class="col-md-6 fieldc"  [ngClass]="passwordForm.controls['password'].touched && passwordForm.controls['password'].errors?'inputc-error':''">
                  <label class="inputlabel">Password <span class="text-danger font-weight-bold">*</span></label> 
                  <input name="name"  class="input-field" type="text" placeholder="{{user.authorities.password}}" formControlName="password" [(ngModel)]="user.authorities.principal.password"/> 
                  <span class="error-mge" *ngIf="passwordForm.controls['password'].invalid && (passwordForm.controls['password'].dirty || passwordForm.controls['password'].touched)" >
                    Password required
                  </span>   
                  <small>Password should be atleast 8 characters long and should contain one number, one uppercase character,one lowercase character, and one special character</small>     
              </div>
            </section>
            <section class="inputc">
               
            </section>
        </div>
        
        <!-- Modal footer -->
        <footer class="modalfooter">
            <button type="button" class="done-btn modalpubtn" data-dismiss="modal">Cancel</button>
            <button class="done-btn modalpubtn" [disabled]="passwordForm.invalid" >Save </button>

        </footer>
      </form>

      </div>
    </div>
</div>

import { Component, OnInit, Input, NgModule } from '@angular/core';
import { FormControl, NgForm, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Config } from '../../../../config'
import { DataSourceControllerService } from '../../../../services/api/data-source/data-source-controller.service'
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';

import { RdbmsConnector } from "../../../../models/rdbms/rdbms-connector.model"

@Component({
  selector: 'app-create-and-test-db2',
  templateUrl: './create-and-test-db2.component.html',
  styleUrls: ['./create-and-test-db2.component.scss']
})
export class CreateAndTestDb2Component implements OnInit {


  //test connection object
  data: any;

  //post connection object
  connection: any;

  rdbmsConnectorList: any[] = [];
  hide = true;
  //snackbar 
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  status: string;

  testSuccessful: boolean;

  //validator
  connectionForm: FormGroup;

  form = { driver: '', hostName: '', username: '', password: '', };


  constructor(private formBuilder: FormBuilder, public service: DataSourceControllerService, public router: Router,
    private snackBar: MatSnackBar, private activatedRoute: ActivatedRoute
  ) {


  }
  projectName = this.activatedRoute.snapshot.params['id2'];


  //form model

  dataSourceCredentials = this.formBuilder.group({
    id: [""],
    name: [""],
    description: [""],
    databaseType: ["db2"],
    hostName: ["", Validators.required],
    port: ["50000", [Validators.required, Validators.pattern('[0-9]*')]],
    username: ["", Validators.required],
    password: ["", Validators.required],
    databaseName: ["", Validators.required],
    projectName: this.projectName,


  })




  getAllConnectionsByProjectName() {
    this.service.getAllConnectionsByProjectName(this.projectName).toPromise()
      .then(response => {
        console.log(response)
        let data: any

        data = response;
        for (let i = 0; i < data.length; i++) {
          if (data[i].databaseType == "db2") {
            this.rdbmsConnectorList.push(data[i])

          }
        }
      }
      )
  }

  //populate form with saved connection

  populateForm(savedRecord: any) {

    this.dataSourceCredentials.setValue({
      id: savedRecord.id,
      name: savedRecord.name,
      description: savedRecord.description,
      databaseType: "db2",
      hostName: savedRecord.hostName,
      port: savedRecord.port,
      username: savedRecord.username,
      password: savedRecord.password,
      databaseName: savedRecord.databaseName,
      projectName: this.projectName

    })


  }

  //populate form with saved connection

  clearForm() {

    this.dataSourceCredentials.setValue({
      id: "",
      name: "",
      description: "",
      databaseType: "db2",
      projectName: this.projectName,
      hostName: "",
      port: "",
      username: "",
      password: "",
      databaseName: "",

    })


  }

  // post connection method
  postConnection() {
    console.log(this.dataSourceCredentials)
    if (this.dataSourceCredentials.value.id != "") {
      this.error("Connection already exists")


    } else {
      this.service.postRDBMSConnector(this.dataSourceCredentials.value).subscribe((response) => {
        console.log(response)
        this.connection = response;
        this.router.navigateByUrl(`project/${this.projectName}/data-sources`);
        this.success("New Data Source created")
      });


    }
  }

  // test connectio nmethod

  testConnection() {
    this.service.testRDBMSConnector(this.dataSourceCredentials.value).subscribe((response) => {
      this.data = response;
      console.log(response)
      if (this.data.status.toLowerCase() == "connection successful") {
        console.log(this.data.status)
        this.success(this.data.status)
        this.testSuccessful = true;
      } else {
        this.error(this.data.status)
        this.testSuccessful = false;

      }





    });

  }

  //snackbar
  openSnackBar(status: string, statusLogo: string) {
    this.snackBar.open(status, statusLogo, {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['tertiary-active']

    });
  }

  success(status: string) {
    Swal.fire({
      type: 'Success',

      title: 'Success!',
      text: status,
      confirmButtonText: 'Ok',
    })
  }
  error(status: string) {
    Swal.fire({
      type: 'Error',

      title: 'Test Connection Failed!',
      text: status,
      confirmButtonText: 'Ok',
    })
  }

  ngOnInit(): void {
    this.getAllConnectionsByProjectName();

    this.testSuccessful = false;

    this.connectionForm = new FormGroup({
      hostname: new FormControl(this.form.hostName, Validators.required),
      username: new FormControl(this.form.username, Validators.required),
      password: new FormControl(this.form.password, Validators.required),
    })


  }


}

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormControl, NgForm, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { StreamingdataSourceService } from 'src/app/services/api/data-source/streamingdata-source.service';

import { DataSourceControllerService } from '../../../../services/api/data-source/data-source-controller.service'
import { RdbmsConnector } from "../../../../models/rdbms/rdbms-connector.model"
import { ProjectService } from 'src/app/services/project/project-service.service';
import * as $ from 'jquery';

declare var $: any;

@Component({
  selector: 'app-data-source',
  templateUrl: './data-source.component.html',
  styleUrls: ['./data-source.component.scss']
})
export class DataSourceComponent implements OnInit, AfterViewInit {

  loggedUser: any

  isSidenav: boolean = false;
  load: boolean;

  testSuccessful: boolean;

  //response model
  rdbmsConnectorList: any = [];
  //form for update
  ingestionObject: any = {};

  //toggle password visability
  hide = true;

  //search term
  term: string;
  result: [];
  //field for test
  test: any
  page = 1;
  pageSize = 7;
  fields: any;
  streamDataSource: any;
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  ngAfterViewInit() {
    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);
    });
  }

  constructor(public service: DataSourceControllerService, public router: Router, public formBuilder: FormBuilder,
    public projectService: ProjectService, private activatedRoute: ActivatedRoute,
    private snackbar: MatSnackBar, private streamingdataSourceService: StreamingdataSourceService
  ) {
  }

  projectName = this.activatedRoute.snapshot.params['id2'];
  dataTypeList: string[] = ["Binary", "String", "Boolean", "Date", "Double", "Float", "Byte", "Integer", "Long", "Short"];


  ngOnInit(): void {
    this.getAllConnectionsByProjectName()
    this.testSuccessful = false;
  }

  sortAscending(data: any) {
    data.sort((a: any, b: any) => (a.createdAt > b.createdAt) ? 1 : -1)

  }

  deleteData() {
    localStorage.removeItem("stepOneStreamingDataSource");
    localStorage.removeItem("stepTwoStreamingDataSource");
    localStorage.removeItem("stepTwoStreamingKafkaStatement");

  }

  sortDescending(data: any) {
    data.sort((a: any, b: any) => (a.createdAt < b.createdAt) ? 1 : -1)
  }
  returnSortedList(data: any) {
    return data.sort((a: any, b: any) => (a.createdAt < b.createdAt) ? 1 : -1)
  }


  //form modal
  dataSourceCredentials = this.formBuilder.group({
    id: ["", Validators.required],
    name: [""],
    description: [""],
    databaseType: ["", Validators.required],
    hostName: ["", Validators.required],
    port: ["", [Validators.required, Validators.pattern('[0-9]*')]],
    username: ["", Validators.required],
    password: [""],
    projectName: this.projectName

  })

  //form modal
  dataSourceCredentialForOracle = this.formBuilder.group({
    id: ["", Validators.required],
    name: [""],
    description: [""],
    databaseType: ["", Validators.required],
    databaseName: ["", Validators.required],
    hostName: ["", Validators.required],
    port: ["", [Validators.required, Validators.pattern('[0-9]*')]],
    username: ["", Validators.required],
    password: [""],
    projectName: this.projectName

  })
  dataSourceCredentialForDb2 = this.formBuilder.group({
    id: ["", Validators.required],
    name: [""],
    description: [""],
    databaseType: ["", Validators.required],
    databaseName: ["", Validators.required],
    hostName: ["", Validators.required],
    port: ["", [Validators.required, Validators.pattern('[0-9]*')]],
    username: ["", Validators.required],
    password: [""],
    projectName: this.projectName

  })



  // ngAfterViewInit() {

  //   $(document).ready(function () {
  //     var contenth = $(window).height() - 110;
  //     var sidebarh = $(window).height() - 111;
  //     $(".pagec").css("height", contenth);
  //     $(".sidebar-wrapper").css("height", sidebarh);
  //   });
  // }

  getAllConnectionsByProjectName() {
    this.load = true;
    this.rdbmsConnectorList = [];

    this.service.getAllConnectionsByProjectName(this.projectName).toPromise()
      .then(response => {

        let data: any = response;

        for (let i = 0; i < data.length; i++) {
          this.rdbmsConnectorList.push({
            databaseType: data[i].databaseType,
            description: data[i].description,
            hostName: data[i].hostName,
            id: data[i].id,
            name: data[i].name,
            username: data[i].username,

            password: data[i].password,
            port: data[i].port,
            projectName: data[i].projectName,
            createdAt: data[i].createdAt

          })
        }
        console.log(this.rdbmsConnectorList);
      })
    this.load = true;

    this.streamingdataSourceService.getAllStreamDataSourcesByProjectName(this.projectName).subscribe(response => {
      // this.rdbmsConnectorList = this.rdbmsConnectorList.concat(response);
      let data: any = response;
      console.log(data);
      for (let i = 0; i < data.length; i++) {
        this.rdbmsConnectorList.push({
          databaseType: "kafka",
          description: data[i]?.description,
          hostname: data[i].hostname,
          id: data[i].id,
          fields: data[i].fields,
          name: data[i].name,
          topic: data[i].topic,
          port: data[i].port,
          projectName: data[i].projectName,
          createdAt: data[i].createdAt

        })

      }
      this.load = false;
      this.rdbmsConnectorList = this.returnSortedList(this.rdbmsConnectorList);

    })

    console.log(this.rdbmsConnectorList);
  }

  allComplete: boolean = false;

  updateAllComplete() {
    this.allComplete = this.formForFields.value.fields != null && this.formForFields.value.fields.every(t => t.completed);
  }

  someComplete(): boolean {
    if (this.formForFields.value.fields == null) {
      return false;
    }
    return this.formForFields.value.fields.filter(t => t.completed).length > 0 && !this.allComplete;
  }
  setDataType(data: any, i: number, dataType: string) {
    data[i].dataType = dataType;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.formForFields.value.fields == null) {
      return;
    }
    this.formForFields.value.fields.forEach(t => t.checked = completed);
  }


  //get all connections

  getAllConnections() {
    this.service.getRDBMSConnectors().toPromise()
      .then(response => {
      }
      )
  }

  //delete connection
  deleteSavedConnection(id: string, name: string) {
    Swal.fire({
      type: 'warning',

      title: "Are you sure you want to delete the data source '" + name + "'?",
      text: 'All information associated to this data source will be permanently deleted',
      showCancelButton: true, confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      if (result.isConfirmed == true) {
        this.service.deleteRDBMSConnector(id).subscribe(res => {

          this.getAllConnectionsByProjectName();

          Swal.fire('Data source deleted!')

        })

      }
    })

  }
  deleteSavedStreamConnection(id: string, name: string) {
    Swal.fire({
      type: 'warning',

      title: "Are you sure you want to delete the data source '" + name + "'?",
      text: 'All information associated to this data source will be permanently deleted',
      showCancelButton: true, confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      if (result.isConfirmed == true) {
        this.streamingdataSourceService.deleteStreamDataSources(id).subscribe(res => {

          this.getAllConnectionsByProjectName();

          Swal.fire('Data source deleted!')

        })

      }
    })

  }
  //populate form fields for update

  populateForm(savedRecord: any) {
    console.log(savedRecord);
    this.dataSourceCredentials.setValue({
      id: savedRecord.id,
      name: savedRecord.name,
      description: savedRecord.description,
      databaseType: savedRecord.databaseType,
      hostName: savedRecord.hostName,
      port: savedRecord.port,
      username: savedRecord.username,
      password: savedRecord.password,
      projectName: this.projectName,

    })


  }
  populateForm2(databaseType, savedRecord: any) {

    if (databaseType === 'db2') {
      $('#exampleModalCenterDb2').modal('show');
      this.dataSourceCredentialForDb2.setValue({
        id: savedRecord.id,
        name: savedRecord.name,
        description: savedRecord.description,
        databaseType: savedRecord.databaseType,
        databaseName: savedRecord.databaseName,
        hostName: savedRecord.hostName,
        port: savedRecord.port,
        username: savedRecord.username,
        password: savedRecord.password,
        projectName: this.projectName,
      })
    } else
      if (databaseType === 'oracle') {
        $('#exampleModalCenterOracle').modal('show');
        this.dataSourceCredentialForOracle.setValue({
          id: savedRecord.id,
          name: savedRecord.name,
          description: savedRecord.description,
          databaseType: savedRecord.databaseType,
          databaseName: savedRecord.databaseName,
          hostName: savedRecord.hostName,
          port: savedRecord.port,
          username: savedRecord.username,
          password: savedRecord.password,
          projectName: this.projectName,
        })
      } else {
        $('#exampleModalCenter').modal('show');
        this.dataSourceCredentials.setValue({
          id: savedRecord.id,
          name: savedRecord.name,
          description: savedRecord.description,
          databaseType: savedRecord.databaseType,
          hostName: savedRecord.hostName,
          port: savedRecord.port,
          username: savedRecord.username,
          password: savedRecord.password,
          projectName: this.projectName,

        })
      }


  }

  editSelector(databaseType) {
    if (databaseType === 'db2') {
      $('#exampleModalCenterDb2').modal('show');
    } else
      if (databaseType === 'oracle') {
        $('#exampleModalCenterOracle').modal('show');
      } else {
        $('#exampleModalCenter').modal('show');
      }
  }

  testConnection(data: any) {
    this.load = true;

    this.service.testRDBMSConnector(data).subscribe((response) => {
      this.test = response;

      if (this.test.status.toLowerCase() == "connection successful") {
        this.success(this.test.status)
        this.testSuccessful = true;
      } else {
        this.error(this.test.status)
        this.testSuccessful = false;

      }
      this.load = false;
    });

  }

  testStreamConnection(data: any) {
    console.log(data);
    this.streamingdataSourceService.testStreamDataSource(data).subscribe(response => {
      let status: any = response;
      console.log(status);
      if (status.status.toLowerCase() == "connection successful") {
        this.success(status.status);

      } else {
        this.warning(status.status);
      };
    })
  }

  //update connection



  updateSavedConnection(data: any) {
    Swal.fire({
      type: 'warning',

      title: "Are you sure you want to update this data source?",
      text: 'All jobs associated with this data source will be killed as a result of this update',
      showCancelButton: true, confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      if (result.isConfirmed == true) {
        $('#exampleModalCenter').modal().hide();
        this.service.updateRDBMSConnector(data).subscribe(res => {
          this.getAllConnectionsByProjectName();
          this.success("Data source successfully updated")
        })

      }
    })

  }
  updateSavedDb2Connection(data: any) {
    Swal.fire({
      type: 'warning',

      title: "Are you sure you want to update this data source?",
      text: 'All jobs associated with this data source will be killed as a result of this update',
      showCancelButton: true, confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      if (result.isConfirmed == true) {
        $('#exampleModalCenterDb2').modal().hide();
        this.service.updateRDBMSConnector(data).subscribe(res => {
          this.getAllConnectionsByProjectName();
          this.success("Data source successfully updated")
        })
      }
    })

  }

  updateSavedOracleOrDb2Connection(data: any) {
    Swal.fire({
      type: 'warning',

      title: "Are you sure you want to update this data source?",
      text: 'All jobs associated with this data source will be killed as a result of this update',
      showCancelButton: true, confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      if (result.isConfirmed == true) {
        $('#exampleModalCenterOracle').modal().hide();
        this.service.updateRDBMSConnector(data).subscribe(res => {
          this.getAllConnectionsByProjectName();
          this.success("Data source successfully updated")
        })
      }
    })

  }

  //determin if user can see ui thing
  isHidden() {
    this.loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"))
    for (var user_auths = 0; user_auths < this.loggedUser.authorities.authorities.length; user_auths++) {
      if (this.loggedUser.authorities.authorities[user_auths].authority === 'PERM_MAINTAINER' || this.loggedUser.authorities.authorities[user_auths].authority === 'PERM_OWNER' || this.loggedUser.authorities.authorities[user_auths].authority === 'PERM_ADMIN') {
        return false
      }
    }
    return true
  }


  streamDataSourceForm = this.formBuilder.group({
    id: [""],
    name: [""],
    description: [""],
    fields: this.formBuilder.array([]),
    hostname: [""],
    port: [""],
    topic: [""],
    projectName: this.projectName
  })
  formForFields = this.formBuilder.group({
    fields: this.formBuilder.array([])

  });
  populateStreamDataSource(data: any) {
    console.log(data);
    this.formForFields.value.fields = data.fields;
    this.streamDataSource = data.fields;

    $('#exampleModalCenterStream').modal('show');
    this.streamDataSourceForm.patchValue({
      description: data.description,
      hostname: data.hostname,
      id: data.id,
      name: data.name,
      port: data.port,
      projectName: this.projectName,
      topic: data.topic
    })
    this.streamDataSourceForm.setControl('fields', this.formBuilder.array(data.fields));

  }
  updateSavedStreamConnection(data: any) {
    this.streamDataSourceForm.setControl('fields', this.formBuilder.array(this.streamDataSource));
    console.log(this.streamDataSourceForm.value);
    Swal.fire({
      type: 'warning',

      title: "Are you sure you want to update this data source?",
      text: 'All jobs associated with this data source will be killed as a result of this update',
      showCancelButton: true, confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {

      if (result.isConfirmed == true) {

        $('#exampleModalCenterStream').modal().hide();
        this.streamingdataSourceService.updateStreamDataSource(data).subscribe(res => {
          this.getAllConnectionsByProjectName();
          this.success("Data source successfully updated");

        })
      }
    })

  }

  success(status: string) {
    Swal.fire({
      type: 'Success',

      title: 'Success!',
      text: status,
      confirmButtonText: 'Ok',
    })
  }
  warning(status: string) {
    Swal.fire({
      type: 'Warning',
      title: 'Warning',
      text: status,
      confirmButtonText: 'Ok',
    })
  }


  error(status: string) {
    Swal.fire({
      type: 'Error',

      title: 'Ooops!',
      text: status,
      confirmButtonText: 'Ok',
    })
  }


}


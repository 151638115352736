<div *ngIf="load" class="">
    <div class="loader"></div>
    <figure class="spinner-border loaderico"></figure>
</div>
<div class="cm-content-blocks">
    <ol class="breadcrumb">
        <li><a routerLink="/home"><i class="fa fa-home"></i>Home</a></li>
        <li><a routerLink="/project"><i class="fa fa-folder-open"></i>Projects</a></li>
        <li><a routerLink="/project/{{projectName}}"><i class="fa fa-folder"></i>{{projectName}}</a></li>
        <li><a [routerLink]="['/project', projectService.getCurrentProject(),'ingest']"><i
                    class="fa fa-filter"></i>Ingest</a></li>
        <li><a [routerLink]="['/project', projectService.getCurrentProject(),'ingest', 'select-ingestion']"><i
                    class="fa fa-sitemap mr-1"></i>Configure Ingestion</a></li>
        <li class="active"><i class="fa fa-filter"></i>Database Ingestion</li>

    </ol>
</div>

<div class="clearafter headbtnc">
    <h3 class="headc">
        Database Ingestion
        <div class="subhead">Configure Database Ingestion</div>
    </h3>
</div>

<div class="menublock">
    <!-- steps -->
    <div class="progresslic clearafter ">
        <div class="fifteen_pb pb-0">
            <ul class="progressbar clearfix">

                <li class="tabli active pointerc" [ngClass]="showDiv.stepone === true ? 'active':'disabled' ">
                    <section class="tabbtn">
                        <section class="clearfix relativepos">
                            <aside class="absoultepos stipico">
                                <i class="fa fa-info" aria-hidden="true"></i>
                            </aside>
                            <aside class="steptxtc">
                                <div class="steptitle">Data Source</div>
                                <div class="stepdes mt-1">Select data source for ingestion</div>
                            </aside>
                        </section>
                    </section>
                </li>

                <li class="tabli active pointerc" [ngClass]="showDiv.steptwo === true ?'active':'disabled' ">
                    <section class="tabbtn">
                        <section class="clearafter relativepos">
                            <aside class="absoultepos stipico">
                                <i class="fa fa-database" aria-hidden="true"></i>
                            </aside>
                            <aside class="steptxtc">
                                <div class="steptitle">Source Destination</div>
                                <div class="stepdes">Select databases to ingest from</div>
                            </aside>
                        </section>
                    </section>
                </li>

                <li class="tabli active pointerc" [ngClass]="showDiv.stepfour === true ?'active':'disabled' " (click)="showDiv.stepone = false;
                showDiv.steptwo = false; 
                showDiv.stepthree = false;
                showDiv.stepfour = true;
                showDiv.stepfive = false;
  
                showDiv.backbtn = false;
                showDiv.backbtntwo = false;  
                showDiv.backbtnthree = false;  
                showDiv.backbtnfour = true;  
                showDiv.backbtnfive = false;  
   
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false;
                showDiv.nextbtntwo = false; 
                showDiv.nextbtnthree = false; 
                showDiv.nextbtnfour = true; 
                showDiv.cancelbtn = false">
                    <section class="tabbtn">
                        <section class="clearfix relativepos">
                            <aside class="absoultepos stipico">
                                <i class="fa fa-cogs" aria-hidden="true"></i>
                            </aside>
                            <aside class="steptxtc">
                                <div class="steptitle">Advanced Configuration</div>
                                <div class="stepdes">Configure ingestion settings</div>
                            </aside>
                        </section>
                    </section>
                </li>
                <!-- <li class="tabli pointerc"
                [ngClass]="showDiv.stepfive === true ?'active':'disabled' " 
                (click)="showDiv.stepone = false;
                showDiv.steptwo = false; 
                showDiv.stepthree = false;
                showDiv.stepfour = false;
                showDiv.stepfive = true;
  
                showDiv.backbtn = false;
                showDiv.backbtntwo = false;  
                showDiv.backbtnthree = false;  
                showDiv.backbtnfour = false;  
                showDiv.backbtnfive = true;  
   
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false;
                showDiv.nextbtntwo = false; 
                showDiv.nextbtnthree = false; 
                showDiv.nextbtnfour = false; 
                showDiv.cancelbtn = false"
                >
                    <section class="tabbtn">
                        <section class="clearfix relativepos">
                        <aside class="absoultepos stipico">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                        </aside>
                        <aside class="steptxtc">
                            <div class="steptitle">Advanced Configuration</div>
                            <div class="stepdes">Description</div>
                        </aside>
                        </section>
                    </section>
                </li>
                 -->


            </ul>
        </div>

        <section class="tabc_config">

            <div class="stepfour_c" *ngIf="showDiv.stepfour">
                <div class="fifteenmb">
                    <form [formGroup]="advancedConfiguration" (ngSubmit)="onSubmit(advancedConfiguration)">
                        <div class="form-group">
                            <input formControlName="sourceId" type="hidden" class="form-control">
                        </div>



                        <section class="inputc">


                            <aside class="col-md-6 fieldc"
                                [ngClass]="advancedConfiguration.controls['fetchSize'].touched && advancedConfiguration.controls['fetchSize'].errors?'inputc-error':''">
                                <label class="inputlabel clearafter">
                                    <ng-template #popFetchContent>
                                        <div>
                                            <span class="displayblock fivepb">
                                                Number of entries to read from database at once. Choose a larger number
                                                for large ingestions.
                                            </span>
                                        </div>
                                    </ng-template>
                                    <ng-template #popFetchTitle>Fetch size</ng-template>
                                    <span class="displayblock fl fivemr">Fetch size </span>
                                    <span class="iconbtn-round primary-s-bg qubesbg fl fivemr pointerc"
                                        placement="right" container="body" [ngbPopover]="popFetchContent"
                                        [popoverTitle]="popFetchTitle">
                                        <i class="fa fa-info" aria-hidden="true"> </i>
                                    </span>
                                    <span class="text-danger">*</span>
                                </label>
                                <input class="input-field" formControlName="fetchSize" type="text">
                                <span class="error-mge mt-2"
                                    *ngIf="advancedConfiguration.controls['fetchSize'].invalid && (advancedConfiguration.controls['fetchSize'].dirty || advancedConfiguration.controls['fetchSize'].touched)">
                                    Fetch Size is required.
                                </span>
                            </aside>
                            <aside class="col-md-6 fieldc"
                                [ngClass]="advancedConfiguration.controls['splitLimit'].touched && advancedConfiguration.controls['splitLimit'].errors?'inputc-error':''">
                                <label class="inputlabel clearafter">
                                    <ng-template #popSplitContent>
                                        <div>
                                            <span class="displayblock fivepb">
                                                Upper Limit for each split size.
                                            </span>
                                        </div>
                                    </ng-template>
                                    <ng-template #popSplitTitle>Split Limit</ng-template>
                                    <span class="displayblock fl fivemr">Split Limit </span>
                                    <span class="iconbtn-round primary-s-bg qubesbg fl fivemr pointerc"
                                        placement="right" container="body" [ngbPopover]="popSplitContent"
                                        [popoverTitle]="popSplitTitle">
                                        <i class="fa fa-info" aria-hidden="true"> </i>
                                    </span>
                                    <span class="text-danger">*</span>
                                </label>
                                <input class="input-field" formControlName="splitLimit" type="text">
                                <span class="error-mge mt-2"
                                    *ngIf="advancedConfiguration.controls['splitLimit'].invalid && (advancedConfiguration.controls['splitLimit'].dirty || advancedConfiguration.controls['splitLimit'].touched)">
                                    Split Limit is required.
                                </span>
                            </aside>

                        </section>
                        <section class="inputc">
                            <aside class="col-md-6 fieldc"
                                [ngClass]="advancedConfiguration.controls['numMappers'].touched && advancedConfiguration.controls['numMappers'].errors?'inputc-error':''">
                                <label class="inputlabel clearafter">
                                    <ng-template #popNumContent>
                                        <div>
                                            <span class="displayblock fivepb">
                                                Specifies the number of map tasks (parallel processes) to use to perform
                                                the ingestion. Some databases may see improved performance by increasing
                                                this value to 8 or 16. Do not increase the degree of parallelism greater
                                                than that available within your MapReduce cluster; tasks will run
                                                serially and will likely increase the amount of time required to perform
                                                the import. Likewise, do not increase the degree of parallism higher
                                                than that which your database can reasonably support. Connecting 100
                                                concurrent clients to your database may increase the load on the
                                                database server to a point where performance suffers as a result.
                                            </span>
                                        </div>
                                    </ng-template>
                                    <ng-template #popNumTitle>Num-Mappers</ng-template>
                                    <span class="displayblock fl fivemr">Num-Mappers </span>
                                    <span class="iconbtn-round primary-s-bg qubesbg fl fivemr pointerc"
                                        placement="right" container="body" [ngbPopover]="popNumContent"
                                        [popoverTitle]="popNumTitle">
                                        <i class="fa fa-info" aria-hidden="true"> </i>
                                    </span>
                                    <span class="text-danger">*</span>
                                </label>
                                <input class="input-field" formControlName="numMappers" type="text">
                                <span class="error-mge mt-2"
                                    *ngIf="advancedConfiguration.controls['numMappers'].invalid && (advancedConfiguration.controls['numMappers'].dirty || advancedConfiguration.controls['numMappers'].touched)">
                                    Num-mappers is required.
                                </span>
                            </aside>
                            <aside class="col-md-6 fieldc">
                                <label class="inputlabel clearafter">
                                    <ng-template #popValidateContent>
                                        <div>
                                            <span class="displayblock fivepb">
                                                Run basic validation on ingestion once complete. If validation finds and
                                                issue the job will fail.
                                            </span>
                                        </div>
                                    </ng-template>
                                    <ng-template #popValidateTitle>Validate</ng-template>
                                    <span class="displayblock fl fivemr">Validate </span>
                                    <span class="iconbtn-round primary-s-bg qubesbg fl fivemr pointerc"
                                        placement="right" container="body" [ngbPopover]="popValidateContent"
                                        [popoverTitle]="popValidateTitle">
                                        <i class="fa fa-info" aria-hidden="true"> </i>
                                    </span>
                                    <span class="text-danger">*</span>
                                </label>
                                <select class="form-control" formControlName="validate">
                                    <option value="true">True</option>
                                    <option value="false">False</option>
                                </select>
                            </aside>
                        </section>
                        <section class="inputc">
                            <aside class="col-md-6 fieldc">
                                <label class="inputlabel clearafter">
                                    <ng-template #popFileTypeContent>
                                        <div>
                                            <span class="displayblock fivepb">
                                                Select a file type.
                                            </span>
                                        </div>
                                    </ng-template>
                                    <ng-template #popValidateTitle>File Type</ng-template>
                                    <span class="displayblock fl fivemr">File Type </span>
                                    <span class="iconbtn-round primary-s-bg qubesbg fl fivemr pointerc"
                                        placement="right" container="body" [ngbPopover]="popFileTypeContent"
                                        [popoverTitle]="popFileTypeContent">
                                        <i class="fa fa-info" aria-hidden="true"> </i>
                                    </span><span class="text-danger">*</span>
                                </label>
                                <mat-form-field appearance="fill">
                                    <mat-label>{{advancedConfiguration.value.fileType}}</mat-label>

                                    <mat-select formControlName="fileType">
                                        <mat-option [value]="'csv'">CSV </mat-option>
                                        <mat-option [value]="'avro'">AVRO </mat-option>
                                        <mat-option [value]="'parquet'">PARQUET </mat-option>

                                    </mat-select>
                                </mat-form-field>
                            </aside>
                        </section>

                        <!-- <section class="inputc" >
                        <aside class="col-md-6 fieldc" >
                            <label class="inputlabel">Fetch Size</label>
                            <input class="input-field" formControlName="fetchSize" type="text" >
                        </aside>
                      
                    </section> -->
                        <div class="graybdrt fifteen_pt fifteen_pb clearafter">
                            <button class="tertiary-active fr float-right" click="submit"
                                [disabled]="advancedConfiguration.invalid">
                                <span>Save Configuration</span>
                                <i class="fa fa-arrow-right fiveml" aria-hidden="true"></i>
                            </button>
                            <button class="grey-btn fr btnpre"
                                routerLink="/project/{{projectName}}/ingest/select-ingestion/stepTwoDatabase/{{id}}">
                                <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
                            </button>


                        </div>



                    </form>

                </div>
            </div>


            <footer class="tenpt lightgraybdrt clearafter footerbtns">
                <!-- <button class="tertiary-active fr btnnext" id="btnnext" *ngIf="showDiv.nextbtn"
                (click)="showDiv.stepone = false; 
                showDiv.steptwo = true; 
                showDiv.stepthree = false;
                showDiv.stepfour = false;
                showDiv.stepfive = false;
  
                showDiv.backbtn = true; 
                showDiv.backbtntwo = false;
                showDiv.backbtnthree = false; 
                showDiv.backbtnfour= false;
                showDiv.backbtnfive= false;
  
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false; 
                showDiv.nextbtntwo = true; 
                showDiv.nextbtnthree= false; 
                showDiv.nextbtnfour = false; 
  
                showDiv.cancelbtn = false;"
                [disabled]="customIngestionFormFields.invalid"
                >
                Next <i aria-hidden="true" class="fa fa-arrow-right"></i>
                </button> -->
                <button class="tertiary-active fr btnnext" id="btnnext" *ngIf="showDiv.nextbtntwo" (click)="showDiv.stepone = false; 
                showDiv.steptwo =false; 
                showDiv.stepthree = true;
                showDiv.stepfour= false;
                showDiv.stepfive = false;
                showDiv.backbtn = false; 
                showDiv.backbtntwo = true;
                showDiv.backbtnthree = false;
                showDiv.backbtnfour = false;
                showDiv.backbtnfive = false;
  
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false; 
                showDiv.nextbtntwo = false; 
                showDiv.nextbtnthree = true; 
                showDiv.nextbtnfour = false; 
  
                showDiv.cancelbtn = false;">
                    Next <i aria-hidden="true" class="fa fa-arrow-right"></i>
                </button>
                <button class="tertiary-active fr btnnext" id="btnnext" *ngIf="showDiv.nextbtnthree" (click)="showDiv.stepone = false; 
                showDiv.steptwo =false; 
                showDiv.stepthree = false;
                showDiv.stepfour= true;
                showDiv.stepfive = false;
  
                showDiv.backbtn = false; 
                showDiv.backbtntwo = false;
                showDiv.backbtnthree = true; 
                showDiv.backbtnfour = false;
                showDiv.backbtnfive = false;
  
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false; 
                showDiv.nextbtntwo = false; 
                showDiv.nextbtnthree = false; 
                showDiv.nextbtnfour = true;
                showDiv.cancelbtn = false;">
                    Next <i aria-hidden="true" class="fa fa-arrow-right"></i>
                </button>
                <!-- <button class="tertiary-active fr btnnext" id="btnnext" *ngIf="showDiv.nextbtnfour"
                (click)="showDiv.stepone = false; 
                showDiv.steptwo =false; 
                showDiv.stepthree = false;
                showDiv.stepfour= false;
                showDiv.stepfive = true;
  
                showDiv.backbtn = false; 
                showDiv.backbtntwo = false;
                showDiv.backbtnthree = false; 
                showDiv.backbtnfour = true; 
                showDiv.backbtnfive = false;
  
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false; 
                showDiv.nextbtntwo = false; 
                showDiv.nextbtnthree = false; 
                showDiv.nextbtnfour = false;
  
                showDiv.cancelbtn = false;">
                Next <i aria-hidden="true" class="fa fa-arrow-right"></i>
                </button>
                
     -->
                <button class="grey-btn fr btnpre" *ngIf="showDiv.cancelbtn">
                    Cancel
                </button>

                <button class="tertiary-active fr btnnext" *ngIf="showDiv.submitbtn">
                    Submit
                </button>

                <button class="grey-btn fr btnpre" *ngIf="showDiv.backbtn" (click)="showDiv.stepone = true; 
                showDiv.steptwo = false; 
                showDiv.stepthree = false;
                showDiv.stepfour = false;
                showDiv.stepfive = false;
  
                showDiv.backbtn = false; 
                showDiv.backbtntwo = false;
                showDiv.backbtnthree = false; 
                showDiv.backbtnfour= false;
                showDiv.backbtnfive = false;
  
                showDiv.submitbtn = false; 
                showDiv.nextbtn = true; 
                showDiv.nextbtntwo = false; 
                showDiv.nextbtnthree = false; 
                showDiv.nextbtnfour = false; 
  
                showDiv.cancelbtn = false;">
                    <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
                </button>
                <button class="grey-btn fr btnpre" *ngIf="showDiv.backbtntwo" (click)="showDiv.stepone = false; 
                showDiv.steptwo = true; 
                showDiv.stepthree = false;
                showDiv.stepfour = false;
                showDiv.stepfive = false;
  
                showDiv.backbtn = true; 
                showDiv.backbtntwo = false;
                showDiv.backbtnthree = false;
                showDiv.backbtnfour = false;
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false; 
                showDiv.nextbtntwo = true; 
                showDiv.nextbtnthree = false; 
                showDiv.nextbtnfour = false; 
  
                showDiv.cancelbtn = false;">
                    <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
                </button>
                <!-- <button class="grey-btn fr btnpre"  *ngIf="showDiv.backbtnthree"
                (click)="showDiv.stepone = false; 
                showDiv.steptwo = false; 
                showDiv.stepthree = true;
                showDiv.stepfour = false;
                showDiv.stepfive = false;
  
                showDiv.backbtn = false; 
                showDiv.backbtntwo = true;
                showDiv.backbtnthree = false; 
                showDiv.backbtnfour = false; 
                showDiv.backbtnfive = false; 
  
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false; 
                showDiv.nextbtntwo = false; 
                showDiv.nextbtnthree = true; 
                showDiv.nextbtnfour = false; 
                showDiv.cancelbtn = false;">
                    <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
                </button> -->
                <!-- <button class="grey-btn fr btnpre"  *ngIf="showDiv.backbtnfour"
                (click)="showDiv.stepone = false; 
                showDiv.steptwo = false; 
                showDiv.stepthree = false;
                showDiv.stepfour = true;
                showDiv.stepfive = false;
  
                showDiv.backbtn = false; 
                showDiv.backbtntwo = false;
                showDiv.backbtnthree = true; 
                showDiv.backbtnfour = false; 
                showDiv.backbtnfive = false; 
  
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false; 
                showDiv.nextbtntwo = false; 
                showDiv.nextbtnthree = false; 
                showDiv.nextbtnfour = true; 
                showDiv.cancelbtn = false;">
                    <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
                </button> -->
                <!-- <button class="grey-btn fr btnpre"  *ngIf="showDiv.backbtnfive"
                (click)="showDiv.stepone = false; 
                showDiv.steptwo = false; 
                showDiv.stepthree = false;
                showDiv.stepfour = false;
                showDiv.stepfive = true;
  
                showDiv.backbtn = true; 
                showDiv.backbtntwo = false;
                showDiv.backbtnthree = false; 
                showDiv.backbtnfour = true; 
                showDiv.backbtnfive = false; 
  
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false; 
                showDiv.nextbtntwo = false; 
                showDiv.nextbtnthree = false; 
                showDiv.nextbtnfour = false; 
                showDiv.cancelbtn = false;">
                    <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
                </button>
               -->
            </footer>
        </section>

    </div>

</div>

<div class="modal" id="largeModal">
    <div class="modal-dialog modal-lg">
        <div class="modal-content ">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Large Modal</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                Modal Body Content
            </div>

            <!-- Modal footer -->
            <footer class="modalfooter">
                <button class="done-btn modalpubtn">Save </button>
                <button type="button" class="done-btn modalpubtn" data-dismiss="modal">Cancel</button>
            </footer>
        </div>
    </div>
</div>
<div class="cm-content-blocks">
  <ol class="breadcrumb">
    <li><a routerLink="/home"><i class="fa fa-home"></i>Home</a></li>
    <li><a routerLink="/project"><i class="fa fa-folder-open"></i>Projects</a></li>
    <li><a routerLink="/project/{{projectName}}"><i class="fa fa-folder"></i>{{projectName}}</a></li>
    <li><a [routerLink]="['/project', projectService.getCurrentProject(),'ingest']"><i
          class="fa fa-filter"></i>Ingest</a></li>
    <li><a [routerLink]="['/project', projectService.getCurrentProject(),'ingest']"><i
          class="fa fa-sitemap mr-1"></i>Configure Ingestion</a></li>
    <li class="active"><i class="fa fa-filter"></i>Edit Table Ingestion</li>

  </ol>
</div>

<div class="clearafter headbtnc position-relative">
  <h3 class="headc">
    Edit Ingestion
    <div class="subhead">Edit an existing table ingestion</div>
  </h3>
  <div class="create-btn-position headbtnc">
    <button class="mr-btn-back pull-right pointerc"
      [routerLink]="['/project', projectService.getCurrentProject(),'ingest']">Back to Ingest</button>
  </div>
</div>

<div class="menublock">
  <!-- steps -->
  <div class="progresslic clearafter ">
    <div class="fifteen_pb pb-0">
      <ul class="progressbar clearfix">

        <li class="tabli active pointerc" [ngClass]="showDiv.stepone === true ? 'active':'disabled' ">
          <section class="tabbtn">
            <section class="clearfix relativepos">
              <aside class="absoultepos stipico">
                <i class="fa fa-info" aria-hidden="true"></i>
              </aside>
              <aside class="steptxtc">
                <div class="steptitle">Data Source</div>
                <div class="stepdes mt-1">Select data source for ingestion</div>
              </aside>
            </section>
          </section>
        </li>

        <li class="tabli active pointerc" [ngClass]="showDiv.steptwo === true ?'active':'disabled' ">
          <section class="tabbtn">
            <section class="clearafter relativepos">
              <aside class="absoultepos stipico">
                <i class="fa fa-database" aria-hidden="true"></i>
              </aside>
              <aside class="steptxtc">
                <div class="steptitle">Choose Data</div>
                <div class="stepdes">Select databases, tables, and columns to ingest from</div>
              </aside>
            </section>
          </section>
        </li>

        <li class="tabli pointerc" [ngClass]="showDiv.stepthree === true ?'active':'disabled' ">
          <section class="tabbtn">
            <section class="clearfix relativepos">
              <aside class="absoultepos stipico">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
              </aside>
              <aside class="steptxtc">
                <div class="steptitle">Configure Destination</div>
                <div class="stepdes">Edit destination database and table names, and select ingestion mode</div>

              </aside>
            </section>
          </section>
        </li>


        <li class="tabli pointerc" [ngClass]="showDiv.stepfour === true ?'active':'disabled' ">
          <section class="tabbtn">
            <section class="clearfix relativepos">
              <aside class="absoultepos stipico">
                <i class="fa fa-cogs" aria-hidden="true"></i>
              </aside>
              <aside class="steptxtc">
                <div class="steptitle">Advanced Configuration</div>
                <div class="stepdes">Configure ingestion settings</div>
              </aside>
            </section>
          </section>
        </li>



      </ul>
    </div>

    <section class="tabc_config">
      <!-- page content -->

      <!-- accordian -->

      <div *ngIf="load" class="">
        <div class="loader"></div>
        <figure class="spinner-border loaderico"></figure>
      </div>
      <div class="stepthree_c" *ngIf="showDiv.stepthree">
        <section class="clearafter ">
          <div class="row">
            <div class="col-md-4">
              <div class="file">
                <form [formGroup]="file">
                  <label class="inputlabel clearafter">
                    <ng-template #popFileTypeContent>
                      <div>
                        <span class="displayblock fivepb">
                          Select a file type.
                        </span>
                      </div>
                    </ng-template>
                    <ng-template #popValidateTitle>File Type</ng-template>
                    <span class="displayblock fl fivemr">File Type </span>
                    <span class="iconbtn-round primary-s-bg qubesbg fl fivemr pointerc" placement="right"
                      container="body" [ngbPopover]="popFileTypeContent" [popoverTitle]="popFileTypeContent">
                      <i class="fa fa-info" aria-hidden="true"> </i>
                    </span><span class="text-danger">*</span>
                  </label>
                  <mat-form-field appearance="fill">
                    <mat-label>{{file.value.fileType}}</mat-label>

                    <mat-select formControlName="fileType">
                      <mat-option [value]="'csv'" (click)="selectFileType('csv')">CSV </mat-option>
                      <mat-option [value]="'avro'" (click)="selectFileType('avro')">AVRO </mat-option>
                      <mat-option [value]="'parquet'" (click)="selectFileType('parquet')">PARQUET </mat-option>

                    </mat-select>
                  </mat-form-field>

                  <!-- <small class="danger-s mt-2" *ngIf="file.value.fileType == 'parquet' || file.value.fileType == 'avro'">Timestamp and date are not supported by this file type</small> -->
                </form>
              </div>

            </div>

          </div>
          <aside class="treeblock_float fl twentypr mt-3">
            <div class="menublock border_imp menublock_scroll">
              <!-- <i class="fa fa-folder fa-1x mr-2"></i>
                              <h4 class="headc">Databases</h4> -->
              <ul class="datatree" *ngFor="let database of itemObjectsRight; let i =index;">
                <li class="eighteenpl">
                  <span class="dataparent liclick"
                    (click)="toggle[database.destinationDatabaseName ]=!toggle[database.destinationDatabaseName];">{{database.destinationDatabaseName}}</span>
                  <div class="float-right">
                    <!-- <i class="material-icons mr-2" (click)="redoTableDelete(index)">undo</i> -->
                    <i class="fa fa-trash pointerc zeromrimp" (click)="deleteDatabase(itemObjectsRight,i)"></i>

                  </div>
                </li>
                <ul class="nested" [class.active]="!toggle[database.destinationDatabaseName]">
                  <ul *ngFor="let tables of database.tables; let index =index;">
                    <li class="selectTableli items"
                      (click)="retrieveTables(database);retrieveColumns(tables);tableName = tables.destinationTableName;databaseName = database.destinationDatabaseName;">
                      <span class="liclick displayblock">
                        {{nullcheck(tables.schema) + tables.destinationTableName}}
                      </span>
                    </li>
                  </ul>
                </ul>
              </ul>
            </div>
          </aside>


          <aside class="rightblock_float fl mt-3" *ngIf="tableSelected == false">
            <div class="menublock border_imp selecttable_c">
              <div class="cardico innerbox-imgc">
                <div class="row d-flex justify-content-center" style="margin-top: 30px;">
                  <i class="fa fa-table fa-4x"></i>
                </div>
                <div class="row d-flex justify-content-center">
                  <h4 class="cardtitle innerbox_title">Select table to get started</h4>
                </div>
                <div class="row d-flex justify-content-center">
                  <h5 class="cardtitle innerbox_text">Rename databases and tables, specify ingestion modes, and select
                    incremental column keys</h5>
                </div>
              </div>
            </div>
          </aside>

          <aside class="rightblock_float fl mt-3" *ngIf="tableSelected == true">
            <!-- tables edit fields -->
            <div class="menublock border_imp" *ngIf="selectedTable">
              <div *ngIf="selectedTable && selectedDatabase">
                <div *ngIf="this.itemObjectsRight[this.itemObjectsRight.indexOf(selectedDatabase)]">
                  <div
                    *ngIf="this.itemObjectsRight[this.itemObjectsRight.indexOf(selectedDatabase)].tables[this.itemObjectsRight[this.itemObjectsRight.indexOf(selectedDatabase)].tables.indexOf(selectedTable)]">
                    <section class="inputc">

                      <aside class="col-md-6 fieldc">

                        <label class="inputlabel clearafter">
                          <ng-template #popSplitContentOne>
                            <div>
                              <span class="displayblock fivepb">
                                Edit your data warehouse database name
                              </span>
                            </div>
                          </ng-template>
                          <ng-template #popSplitTitleOne>
                            <small>Edit Database Name</small>

                          </ng-template>
                          <span class="displayblock fl fivemr">Database Name </span>
                          <span class="iconbtn-round primary-s-bg qubesbg fl fivemr pointerc" placement="right"
                            container="body" [ngbPopover]="popSplitContentOne" [popoverTitle]="popSplitTitleOne">
                            <i class="fa fa-info" aria-hidden="true"> </i>
                          </span>
                        </label>
                        <input name="inputclick" class="input-field" type="text" [(ngModel)]="databaseName"
                          (blur)="updateDatabaseName(this.itemObjectsRight[this.itemObjectsRight.indexOf(selectedDatabase)],this.itemObjectsRight[this.itemObjectsRight.indexOf(selectedDatabase)].tables[this.itemObjectsRight[this.itemObjectsRight.indexOf(selectedDatabase)].tables.indexOf(selectedTable)])" />
                        <!-- <span class="withicon pointerc topzero">
                                                    <button type="button" class="nextbtn-input" type="submit" >
                                                        <i class="fa fa-pencil"></i>
                                                    </button>
                                                </span> -->


                      </aside>

                      <aside class="col-md-6 fieldc">

                        <label class="inputlabel clearafter">
                          <ng-template #popSplitContentTwo>
                            <div>
                              <span class="displayblock fivepb">
                                Edit your hive table name
                              </span>
                            </div>
                          </ng-template>
                          <ng-template #popSplitTitleTwo>
                            <small>Edit Table Name</small>
                          </ng-template>
                          <span class="displayblock fl fivemr">Table Name </span>
                          <span class="iconbtn-round primary-s-bg qubesbg fl fivemr pointerc" placement="right"
                            container="body" [ngbPopover]="popSplitContentTwo" [popoverTitle]="popSplitTitleTwo">
                            <i class="fa fa-info" aria-hidden="true"> </i>
                          </span>
                        </label>

                        <input name="inputclick" class="input-field" type="text" [(ngModel)]="tableName"
                          (blur)="updateTableName(this.itemObjectsRight[this.itemObjectsRight.indexOf(selectedDatabase)],this.itemObjectsRight[this.itemObjectsRight.indexOf(selectedDatabase)].tables[this.itemObjectsRight[this.itemObjectsRight.indexOf(selectedDatabase)].tables.indexOf(selectedTable)])" />
                        <!-- <span class="withicon pointerc topzero">
                                                      <button type="button" class="nextbtn-input" type="submit">
                                                          <i class="fa fa-pencil"></i>
                                                      </button>
                                                  </span> -->

                      </aside>

                    </section>

                    <section class="inputc">
                      <aside class="col-md-6 fieldc">
                        <label class="inputlabel clearafter">
                          <ng-template #popSplitContentFour>
                            <div>
                              <span class="displayblock fivepb">
                                This will be the where by clause
                              </span>
                            </div>
                          </ng-template>
                          <ng-template #popSplitTitleFour>
                            <small>Enter the Condition for Where Clause</small>

                          </ng-template>
                          <span class="displayblock fl fivemr">Where</span>
                          <span class="iconbtn-round primary-s-bg qubesbg fl fivemr pointerc" placement="right"
                            container="body" [ngbPopover]="popSplitContentFour" [popoverTitle]="popSplitTitleFour">
                            <i class="fa fa-info" aria-hidden="true"> </i>
                          </span>
                        </label>
                        <input name="inputclick" class="input-field" type="text" [(ngModel)]="whereClause"
                          (change)="updateModifiedTable('where')" />
                      </aside>

                      <aside class="col-md-6 fieldc">
                        <label class="inputlabel clearafter">
                          <ng-template #popSplitContentThree>
                            <div>
                              <span class="displayblock fivepb">
                                This will be the Split by
                              </span>
                            </div>
                          </ng-template>
                          <ng-template #popSplitTitleThree>
                            <small>Select Split By Clause for the Columns</small>

                          </ng-template>
                          <span class="displayblock fl fivemr">Split By</span>
                          <span class="iconbtn-round primary-s-bg qubesbg fl fivemr pointerc" placement="right"
                            container="body" [ngbPopover]="popSplitContentThree" [popoverTitle]="popSplitTitleThree">
                            <i class="fa fa-info" aria-hidden="true"> </i>
                          </span>
                        </label>
                        <mat-form-field appearance="fill">
                          <mat-label>Split by</mat-label>
                          <mat-select (selectionChange)="updateModifiedTable('split')" [(ngModel)]="splitClause">
                            <mat-option *ngFor="let column of selectedTable.columns" [value]="column.sourceColumnName">
                              {{column.sourceColumnName}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </aside>
                    </section>
                    <section class="inputc">
                      <aside class="col-md-6 fieldc">
                        <label class="inputlabel clearafter">
                          <ng-template #popSplitContentThree>
                            <div>
                              <span class="displayblock fivepb">
                                Select your table load preference
                              </span>
                            </div>
                          </ng-template>
                          <ng-template #popSplitTitleThree><small>Ingestion Mode</small></ng-template>
                          <span class="displayblock fl fivemr">
                            Ingestion Mode
                          </span>
                          <span class="iconbtn-round primary-s-bg qubesbg fl fivemr pointerc" placement="right"
                            container="body" [ngbPopover]="popSplitContentThree" [popoverTitle]="popSplitTitleThree">
                            <i class="fa fa-info" aria-hidden="true"> </i>
                          </span>
                        </label>
                        <mat-form-field appearance="fill">
                          <mat-label>
                            {{this.itemObjectsRight[this.itemObjectsRight.indexOf(selectedDatabase)].tables[this.itemObjectsRight[this.itemObjectsRight.indexOf(selectedDatabase)].tables.indexOf(selectedTable)].incremental.toUpperCase()}}
                          </mat-label>
                          <mat-select>
                            <mat-option class="mr-4"
                              (click)="editTableLoad(this.itemObjectsRight[this.itemObjectsRight.indexOf(selectedDatabase)].tables[this.itemObjectsRight[this.itemObjectsRight.indexOf(selectedDatabase)].tables.indexOf(selectedTable)], 'full load')">
                              FULL LOAD
                            </mat-option>

                            <mat-option class="mr-4"
                              (click)="editTableLoad(this.itemObjectsRight[this.itemObjectsRight.indexOf(selectedDatabase)].tables[this.itemObjectsRight[this.itemObjectsRight.indexOf(selectedDatabase)].tables.indexOf(selectedTable)], 'increment by id')"
                              *ngIf="canIncrementById ==true">
                              INCREMENT BY ID
                            </mat-option>
                            <mat-option class="mr-4"
                              (click)="editTableLoad(this.itemObjectsRight[this.itemObjectsRight.indexOf(selectedDatabase)].tables[this.itemObjectsRight[this.itemObjectsRight.indexOf(selectedDatabase)].tables.indexOf(selectedTable)], 'increment by timestamp')"
                              *ngIf="canIncrementByTimeStamp == true">
                              INCREMENT BY TIMESTAMP
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </aside>
                      <aside class="col-md-6 fieldc" *ngIf="selectedTable.incremental == 'increment by id'">
                        <label class="inputlabel clearafter">
                          <ng-template #popSplitContent>
                            <div>
                              <span class="displayblock fivepb">
                                Select Incremental Column </span>
                            </div>
                          </ng-template>
                          <ng-template #popSplitTitle>
                            <small>Increment Column</small>
                          </ng-template>
                          <span class="displayblock fl fivemr">Incremental Column </span>
                          <span class="iconbtn-round primary-s-bg qubesbg fl fivemr pointerc" placement="right"
                            container="body" [ngbPopover]="popSplitContent" [popoverTitle]="popSplitTitle">
                            <i class="fa fa-info" aria-hidden="true"> </i>
                          </span>
                        </label>

                        <mat-form-field appearance="fill">
                          <div *ngFor="let columns of selectedTable.columns;let i = index">
                            <mat-label *ngIf="columns.checkColumn == true">{{columns.destinationColumnName}}</mat-label>
                          </div>
                          <mat-select formControlName="checkColumn">
                            <div *ngFor="let columns of selectedTable.columns">
                              <mat-option class="mr-4"
                                *ngIf="columns.sourceDataType.includes('INT') || columns.sourceDataType.includes('int')  "
                                (click)="editCheckColumn(selectedDatabase,selectedTable,columns)">
                                {{columns.destinationColumnName}}
                              </mat-option>
                            </div>
                          </mat-select>
                        </mat-form-field>
                      </aside>
                      <aside class="col-md-6 fieldc" *ngIf="selectedTable.incremental == 'increment by timestamp'">
                        <label class="inputlabel clearafter">
                          <ng-template #popSplitContent>
                            <div>
                              <span class="displayblock fivepb">
                                Select Incremental Column
                              </span>
                            </div>
                          </ng-template>
                          <ng-template #popSplitTitle>Incremental Column</ng-template>
                          <span class="displayblock fl fivemr">Incremental Column </span>
                          <span class="iconbtn-round primary-s-bg qubesbg fl fivemr pointerc" placement="right"
                            container="body" [ngbPopover]="popSplitContent" [popoverTitle]="popSplitTitle">
                            <i class="fa fa-info" aria-hidden="true"> </i>
                          </span>
                        </label>
                        <mat-form-field appearance="fill">
                          <div *ngFor="let columns of selectedTable.columns;let i = index">
                            <mat-label *ngIf="columns.checkColumn == true;">{{columns.destinationColumnName}}
                            </mat-label>

                          </div>
                          <mat-select>
                            <div *ngFor="let columns of selectedTable.columns">
                              <mat-option class="mr-4"
                                *ngIf="columns.sourceDataType.includes('DATE') || columns.sourceDataType.includes('TIMESTAMP') ||columns.sourceDataType.includes('date') || columns.sourceDataType.includes('timestamp'); "
                                (click)="editCheckColumn(selectedDatabase,selectedTable,columns)">
                                {{columns.destinationColumnName}}
                              </mat-option>
                            </div>
                          </mat-select>

                        </mat-form-field>

                      </aside>
                    </section>
                    <section class="inputc">
                      <aside class="col-md-6 fieldc">
                        <button *ngIf="databaseNamesForValidation.length > 0" class="tertiary-active text-warning"
                          data-toggle="modal" data-target="#largeModal">Warnings
                          ({{checkForConflicts(databaseNamesForValidation)}})</button>

                      </aside>


                    </section>

                    <section class="fifteenmt table-ec ">
                      <div class="table-wrapper ingest_tableh_adj ingest-custome" *ngIf="columnsFromTable">
                        <table class="table table-borderless mr-table">
                          <thead class="mr-table-head">
                            <tr class="mr-table-head-row">
                              <th>Source Column Name</th>
                              <th>Source Data Type</th>
                              <th>Destination Data Type </th>
                              <!-- <th class="actionthreetd">Actions</th> -->
                            </tr>
                          </thead>
                          <tbody *ngFor="let column of columnsFromTable | paginate: { id: 'destination_pagination',
                                              itemsPerPage: 10,
                                              currentPage: page,
                                              totalItems: columnsFromTable.length }; let i = index ">
                            <tr class="mr-table-body-row">
                              <td>
                                {{column.destinationColumnName}}
                                <i class="fa fa-key text-warning mr-2" *ngIf="column.primaryKey == true"></i>

                                <i class="fa fa-check text-success" *ngIf="column.checkColumn == true"></i>

                              </td>

                              <td>{{column.sourceDataType}}</td>
                              <td>
                                <div class="mat-small">
                                  <mat-form-field appearance="fill">
                                    <mat-label>{{column.destinationDataType}}</mat-label>
                                    <mat-select>
                                      <div *ngIf="file.value.fileType == 'csv' || file.value.fileType == 'CSV'">
                                        <mat-option *ngFor="let type of csvDestinationDataTypes" class="mr-4"
                                          (click)="editDestinationDataType(selectedDatabase, selectedTable,column, type)">
                                          {{type}}

                                        </mat-option>
                                      </div>
                                      <div *ngIf="file.value.fileType == 'avro' || file.value.fileType == 'AVRO'">
                                        <mat-option *ngFor="let type of avroDestinationDataTypes" class="mr-4"
                                          (click)="editDestinationDataType(selectedDatabase, selectedTable,column, type)">
                                          {{type}}

                                        </mat-option>
                                      </div>
                                      <div *ngIf="file.value.fileType == 'parquet' || file.value.fileType == 'PARQUET'">
                                        <mat-option *ngFor="let type of parquetDestinationDataType" class="mr-4"
                                          (click)="editDestinationDataType(selectedDatabase, selectedTable,column, type)">
                                          {{type}}

                                        </mat-option>
                                      </div>

                                    </mat-select>
                                  </mat-form-field>
                                </div>
                              </td>
                              <!-- <td>                                            
                                                  <i  class="fa fa-trash pointerc" aria-hidden="true" ngbTooltip="Delete" placement="left" (click)="deleteColumn(columnsFromTable,i)"></i>
                                                </td> -->
                            </tr>
                        </table>
                        <pagination-controls *ngIf="columnsFromTable.length > 10" id="destination_pagination"
                          class="pagination" (directionLinks)="true" (pageChange)="page = $event" (responsive)="true"
                          previousLabel="" disabled="true" nextLabel="">
                        </pagination-controls>

                        <!-- <ngb-pagination class="pagination mt-n3" [collectionSize]="columnsFromTable.length" [(page)]="page" aria-label="Default pagination" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>  -->
                        <!-- <ngb-pagination  *ngIf="columnsFromTable.length > 10" class="pagination float-right" [collectionSize]="columnsFromTable.length" [(page)]="page" aria-label="Default pagination" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>   -->
                        <br>
                      </div>

                    </section>
                  </div>
                </div>

              </div>
            </div>

          </aside>

        </section>
      </div>


      <!-- footer-->
      <footer class="tenpt lightgraybdrt clearafter footerbtns tenmt">

        <button class="tertiary-active fr btnnext" id="btnnext" *ngIf="showDiv.nextbtn" (click)="carryData()">
          Next
          <i aria-hidden="true" class="fa fa-arrow-right"></i>
        </button>
        <!-- <button class="tertiary-active" data-toggle="modal" data-target="#largeModal">Naming Conflicts ({{databaseConflicts.length}})</button> -->

        <button class="grey-btn fr btnpre"
          routerLink="/project/{{projectName}}/ingest/editStepTwoCustom/{{id}}/ingestion/{{ingestionId}}">
          <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
        </button>


        <!-- <button class="tertiary-active fr btnnext" id="btnnext" *ngIf="showDiv.nextbtnthree"
                (click)="showDiv.stepone = false; 
                showDiv.steptwo =false; 
                showDiv.stepthree = false;
                showDiv.stepfour= true;
                showDiv.stepfive = false;
  
                showDiv.backbtn = false; 
                showDiv.backbtntwo = false;
                showDiv.backbtnthree = true; 
                showDiv.backbtnfour = false;
                showDiv.backbtnfive = false;
  
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false; 
                showDiv.nextbtntwo = false; 
                showDiv.nextbtnthree = false; 
                showDiv.nextbtnfour = true;
                showDiv.cancelbtn = false;">
                Next <i aria-hidden="true" class="fa fa-arrow-right"></i>
                </button> -->
        <!-- <button class="tertiary-active fr btnnext" id="btnnext" *ngIf="showDiv.nextbtnfour"
                (click)="showDiv.stepone = false; 
                showDiv.steptwo =false; 
                showDiv.stepthree = false;
                showDiv.stepfour= false;
                showDiv.stepfive = true;
  
                showDiv.backbtn = false; 
                showDiv.backbtntwo = false;
                showDiv.backbtnthree = false; 
                showDiv.backbtnfour = true; 
                showDiv.backbtnfive = false;
  
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false; 
                showDiv.nextbtntwo = false; 
                showDiv.nextbtnthree = false; 
                showDiv.nextbtnfour = false;
  
                showDiv.cancelbtn = false;">
                Next <i aria-hidden="true" class="fa fa-arrow-right"></i>
                </button>
                
     -->
        <button class="grey-btn fr btnpre" *ngIf="showDiv.cancelbtn">
          Cancel
        </button>

        <button class="tertiary-active fr btnnext" *ngIf="showDiv.submitbtn">
          Submit
        </button>

        <button class="grey-btn fr btnpre" *ngIf="showDiv.backbtn">
          <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
        </button>
        <button class="grey-btn fr btnpre" *ngIf="showDiv.backbtntwo">
          <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
        </button>
        <button class="grey-btn fr btnpre" *ngIf="showDiv.backbtnthree">
          <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
        </button>
        <!-- <button class="grey-btn fr btnpre"  *ngIf="showDiv.backbtnfour"
                (click)="showDiv.stepone = false; 
                showDiv.steptwo = false; 
                showDiv.stepthree = false;
                showDiv.stepfour = true;
                showDiv.stepfive = false;
  
                showDiv.backbtn = false; 
                showDiv.backbtntwo = false;
                showDiv.backbtnthree = true; 
                showDiv.backbtnfour = false; 
                showDiv.backbtnfive = false; 
  
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false; 
                showDiv.nextbtntwo = false; 
                showDiv.nextbtnthree = false; 
                showDiv.nextbtnfour = true; 
                showDiv.cancelbtn = false;">
                    <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
                </button> -->
        <!-- <button class="grey-btn fr btnpre"  *ngIf="showDiv.backbtnfive"
                (click)="showDiv.stepone = false; 
                showDiv.steptwo = false; 
                showDiv.stepthree = false;
                showDiv.stepfour = false;
                showDiv.stepfive = true;
  
                showDiv.backbtn = true; 
                showDiv.backbtntwo = false;
                showDiv.backbtnthree = false; 
                showDiv.backbtnfour = true; 
                showDiv.backbtnfive = false; 
  
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false; 
                showDiv.nextbtntwo = false; 
                showDiv.nextbtnthree = false; 
                showDiv.nextbtnfour = false; 
                showDiv.cancelbtn = false;">
                    <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
                </button>
               -->
      </footer>
    </section>

  </div>

</div>

<div class="modal" id="largeModal">
  <div class="modal-dialog modal-lg">
    <div class="modal-content ">

      <div class="modal-header ">
        <h4 class="modal-title">Warnings</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">

        <div class="d-flex justify-content-center" *ngIf="databaseConflicts">
          <table class="table table-borderless mr-table">
            <thead class="mr-table-head">
              <tr class="mr-table-head-row">
                <th>Destination Name</th>
                <th>Issue</th>
                <!-- <th class="actionthreetd">Actions</th> -->
              </tr>
            </thead>
            <tbody *ngFor="let key of databaseNamesForValidation| paginate: { id: 'listing_pagination',
                    itemsPerPage: 10,
                    currentPage: page,
                    totalItems: databaseNamesForValidation.length }; let i = index ">
              <tr class="mr-table-body-row">
                <td>
                  {{key.key}}

                </td>

                <td>
                  {{key.value}}
                </td>
                <!-- <td *ngIf="key.value =='No Conflict.'">
                        <i class="fa fa-check text-success"></i>
                      </td> 
                      <td *ngIf="key.value !='No Conflict.'">
                        <i class="fa fa-info text-danger"></i>
                      </td>  -->
                <!-- <td>                                            
                        <i  class="fa fa-trash pointerc" aria-hidden="true" ngbTooltip="Delete" placement="left" (click)="deleteColumn(columnsFromTable,i)"></i>
                      </td> -->
              </tr>
          </table>

        </div>
      </div>

      <footer class="modalfooter">
        <button type="button" class="done-btn modalpubtn" data-dismiss="modal">Ok</button>
      </footer>
    </div>
  </div>
</div>
import { Injectable } from '@angular/core';
import { Config } from '../../config';
import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import Swal from 'sweetalert2/dist/sweetalert2.js';

export interface Schedule {
  cronExpression: string,
  enabled: boolean,
  id: string,
  ingestionId: string,
  ingestionName: string,
  name: string,
  owner: string,
  project: string,
}

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  static url = Config.url + '/schedule/rdbms';

  constructor(private httpClient: HttpClient) { }

  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  createSchedule(data: any): Observable<any> {
    return this.httpClient.post<any>(ScheduleService.url, data)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  updateSchedule(data: any) {
    return this.httpClient.put(ScheduleService.url, data)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  deleteAllSchedules(data: any): Observable<Schedule> {
    return this.httpClient.delete<Schedule>(ScheduleService.url, this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  getScheduleCount() {
    return this.httpClient.get(ScheduleService.url + "/count")
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  getServerTime() {
    return this.httpClient.get(ScheduleService.url + "/servertime")
      .pipe(
        retry(1),
        // catchError(this.handleError)
      )
  }

  deleteScheduleById(id: any): Observable<Schedule> {
    return this.httpClient.delete<Schedule>(ScheduleService.url + "/" + id, this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  getAllSchedulesByProject(projectName: any): Observable<any> {
    return this.httpClient.get<any>(ScheduleService.url + '/project/' + projectName)
  }

  getScheduleById(id: any): Observable<Schedule> {
    return this.httpClient.get<Schedule>(ScheduleService.url + "/" + id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  enableSchedule(id: any): Observable<Schedule> {
    return this.httpClient.get<Schedule>(ScheduleService.url + "/" + id + '/enable')
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  disableSchedule(id: any): Observable<Schedule> {
    return this.httpClient.get<Schedule>(ScheduleService.url + "/" + id + '/disable')
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  validateCron(cronExpression: string) {
    let params = new HttpParams();
    let cronParams = params.append('expression', cronExpression);

    return this.httpClient.get(ScheduleService.url + "/validate", { params: cronParams })

      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  generate(cronExpression: string, amountOfServerTimes: number) {
    let params = new HttpParams();

    let cronParams = params.append('expression', cronExpression);
    let countParams = params.append("count", "5");

    return this.httpClient.get<Schedule>(ScheduleService.url + "/generate", {
      params: {
        "expression": cronExpression,
        "count": amountOfServerTimes.toString()
      }
    })

      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }


  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    Swal.fire({
      type: 'Failed',

      title: "Failed",
      text: error.error.message,
      confirmButtonText: 'Ok',
    })
    return throwError(errorMessage);
  }







}

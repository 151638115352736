<div class="cm-content-blocks">
    <ol class="breadcrumb">
        <li><a routerLink="/home"><i class="fa fa-home"></i>Home</a></li>
        <li><a routerLink="/project"><i class="fa fa-folder-open"></i>Projects</a></li>
        <li><a routerLink="/project/{{projectName}}"><i class="fa fa-folder"></i>{{projectName}}</a></li>
        <li><a routerLink="/project/{{projectName}}/ingest"><i class="fa fa-filter mr-1"></i>Ingest</a></li>  
        <li  class="active"><i class="fa fa-eye mr-1"></i>View Details</li>
    </ol>
  </div>
  
  
  <h3 class="headc">
     Stream Ingestion details - {{projectName}}
    <div class="subhead">View ingestion configuration and execution details</div>
  </h3>
  
  
  <div class="menublock position-relative">
    <a routerLink="/project/{{projectName}}/ingest">
      <span class="iconbtn pointerc closeabs">
        <i aria-hidden="true" class="fa fa-times"></i>
      </span>
    </a>
   
    <!--Configuration-->
    <article class="tabec">
  
      <nav class="tabbar navbar-light bg-faded">
        <div class="nav tabbar">
          <a class="tabitem nav-link active" data-toggle="tab" href="#tabone" >Configuration</a>
          <a class="tabitem nav-link" data-toggle="tab" href="#tabtwo">Execution</a>
        </div>
      </nav>
      
    
      <section class="tab-content relativepos">
      
        <!-- <div *ngIf="load" class="">
          <div class="loader"></div>
          <figure class="spinner-border loaderico"></figure>
      </div> -->
        <aside class="tab-pane active fifteenmt" id="tabone">
        
          <div >
            <ul ngbNav #nav="ngbNav" class="nav-tabs">
              <!--Configuration Info-->
              <li ngbNavItem>
                <a ngbNavLink>Ingestion Info</a>
                <ng-template ngbNavContent>
  
                  <div *ngIf="singleIngestion" class="inputtextb fifteenmb" id="tableid">
  
                    <section  class="inputc fieldtctext">
                        <label class="labeltext">Ingestion Name</label>
                        <div class="inputtext">{{singleIngestion.name}}</div>
                    </section>
  
                    <section  class="inputc fieldtctext">
                      <label class="labeltext">Description</label>
                      <div class="inputtext">{{singleIngestion.description}}</div>
                  </section>
                    <section  class="inputc fieldtctext">
                      <label class="labeltext">Ingestion Id</label>
                      <div class="inputtext">{{singleIngestion.id}}</div>
                    </section>
<!--   
                    <section  class="inputc fieldtctext">
                      <label class="labeltext">Ingestion Description</label>
                      <div class="inputtext"></div>
                  </section> -->
  
                  <section  class="inputc fieldtctext">
                    <label class="labeltext">Ingestion Type</label>
                    <div class="inputtext">kafka</div>
                 </section> 
  
                  <section  class="inputc fieldtctext">
                    <label class="labeltext">Master</label>
                    <div class="inputtext">{{singleIngestion.master}}</div>
                  </section> 
                  <section  class="inputc fieldtctext">
                    <label class="labeltext">Message Format</label>
                    <div class="inputtext">{{singleIngestion.messageFormat}}</div>
                  </section> 
          
                  <section  class="inputc fieldtctext">
                      <label class="labeltext">File Type</label>
                      <div class="inputtext">{{singleIngestion.fileType}}</div>
                    </section> 
                  </div>
                  
                </ng-template>
              </li>
              <!--Ingestion Configurations-->
              <li ngbNavItem >
                <a ngbNavLink>Ingestion Configuration</a>
                <ng-template ngbNavContent>
                  <section class="table-ec">
                    <div class=" fifteenmb"  id="tableid">	
                      <div class="clearafter">
                        <div class="fourtynineper matacco_ec float-left">
                          <h3 class="text-center tenpt">Source</h3>
                          <mat-accordion>
                            <mat-expansion-panel hideToggle [expanded]="true">
                              <mat-expansion-panel-header>
                                <mat-panel-title>
                                  <i class=" mr-2"></i>
                                </mat-panel-title>
                                <mat-panel-description>
                                </mat-panel-description>
                              </mat-expansion-panel-header>
                                <mat-accordion >
                                  
                                    <table *ngIf="dataSource" class="table table-borderless mr-table mb-0">
                                      <thead class="mr-table-head">
                                        <tr class="mr-table-head-row">
                                          <th>Header</th>
                                          <th>Data Type</th>
  
                                          <!-- <th>Primary Key</th>
                                          <th>Check Column</th> -->
                                        </tr>
                                      </thead>
                                      
                                      <tbody *ngFor="let field of dataSource.fields">
                                        <tr  class="mr-table-body-row mt-2" >
                                          <td>
                                            {{field.header}}
                                          
                                          </td>
                                          <td>{{field.dataType}}</td>
  
                                     
                                        </tr>
                                      </tbody>
                                    </table>
                                  
                                  
                                </mat-accordion>
                            </mat-expansion-panel>
                          </mat-accordion>
  
                        </div>
                        <div class="fourtynineper float-right matacco_ec">
                          <h3 class="text-center tenpt">Destination</h3>
                          <!-- accordian -->
                          <div >
                            <mat-accordion *ngIf="singleIngestion">
                              <mat-expansion-panel hideToggle  [expanded]="true">
                                <mat-expansion-panel-header>
                                  <mat-panel-title>
                                    <i class="fa fa-database mr-2"></i>
                                    <span>{{singleIngestion.destinationDatabaseName}}</span>
                                  </mat-panel-title>
                                  <mat-panel-description>
                                  </mat-panel-description>
                                </mat-expansion-panel-header>
                                  <mat-accordion>
                                    <mat-expansion-panel hideToggle >
                                      <mat-expansion-panel-header>
                                        <mat-panel-title>
                                          <i class="fa fa-table mr-2"></i>
                                          <span>{{singleIngestion.destinationTableName}}</span>
                                          <!-- <br>
                                          Ingestion Mode: {{capitalize(tables.incremental)}} | Incremental Last Value: {{tables.lastValue}} -->
                                        </mat-panel-title>
                                        <mat-panel-description>
                                          <span class="text-center">
                                          </span>
                                        </mat-panel-description>
                                      </mat-expansion-panel-header>
                                      
                                      <table *ngIf="singleIngestion" class="table table-borderless mr-table mb-0">
                                        <thead class="mr-table-head">
                                          <tr class="mr-table-head-row">
                                            <th>Column Name</th>
                                            <th>Destination Data Type</th>
          
                                          </tr>
                                        </thead>
                                        <tbody *ngFor="let field of singleIngestion.fields">
                                          <tr  class="mr-table-body-row mt-2" >
                                            <td>
                                              {{field.header}}
                                            </td>
    
                                            <td>
                                              {{field.hiveDataType}}
                                            </td>
                                     
                                          </tr>
                                        </tbody>
                                      </table>
                                    
                                    </mat-expansion-panel>
                                  </mat-accordion>
                              </mat-expansion-panel>
                            </mat-accordion>
                          </div>
                          <div >
                          
                          </div>
           
                        </div>
                      </div>											 
                    </div> 
                  </section> 
                </ng-template>
              </li>
              <!--Advanced Configurations-->
              <!-- <li ngbNavItem  *ngIf="singleIngestion">
                <a ngbNavLink>Advanced Configuration</a>
                <ng-template ngbNavContent>
                  <div class="formc inputtextb fifteenmb"  >
                    <section  class="inputc fieldtctext">
                        <label class="labeltext">Destination Database Name</label>
                        <div class="inputtext">{{singleIngestion.destinationDatabaseName}}</div>
                    </section>
            
                    <section  class="inputc fieldtctext">
                      <label class="labeltext">Destination Table Name</label>
                      <div class="inputtext">{{singleIngestion.destinationTableName}}</div>
                    </section>
            
                    <section  class="inputc fieldtctext">
                        <label class="labeltext">Master</label>
                        <div class="inputtext">{{singleIngestion.master}}</div>                 
                    </section>
            
                
                  </div>
                </ng-template>
              </li> -->
            </ul>
            <div [ngbNavOutlet]="nav"></div>
          </div>
        </aside>
  
        <aside class="tab-pane" id="tabtwo">
          <!-- <aside class="tenml twomt inline-block float-right">
            <button class="iconbtn jobrefresh" placement="left" ngbTooltip="Refresh" container="body" (click)="refresh()">
                <i class="fa fa-refresh" aria-hidden="true"></i>
            </button>
          </aside> -->
  
          <div class="tenpb clearafter">
            <aside class="tenml twomt inline-block float-right">
              <button class="iconbtn jobrefresh" placement="left" ngbTooltip="Refresh" container="body" (click)="refresh()">
                  <i class="fa fa-refresh" aria-hidden="true"></i>
              </button>
            </aside>
          </div>
  
          <div class="position-relative clearafter loader_top_adj">
            
  
            <div class="" *ngIf="load">
              <div class="loader"></div>
              <figure class="spinner-border loaderico"></figure>
            </div>
            <table class="table table-borderless mr-table">
              <thead class="mr-table-head">
                <tr class="mr-table-head-row">
                  <!-- <th>Run History</th> -->
                  <th  class="nametd-sml">Destination Table Name</th>
                  <!-- <th class="statustd">Size (rows)</th> -->
                  <th class="datetimetd">Duration</th>
                  <th class="rowcounttd">Message Count</th>
                  <th class="rowcounttd">Message Rate</th>
                  <th class="statustd">Status</th>
                </tr>
              </thead>
              <tbody *ngIf="!streamJob"  >
                <tr>
                  <td colspan=100% class="text-center">This ingestion hasn't been executed</td>
                </tr>          
              </tbody>
               <tbody *ngIf="streamJob">
                <tr class="mr-table-body-row">
                   <td>
                    <div class="descriptiontd breakword">{{streamJob.destinationTableName}} </div>
                  </td>
           
                  <td>
                    <div class="descriptiontd breakword">{{miliConvert(streamJob.duration)}} </div>
                  </td>
                  <td>
                    <div class="rowcounttd">{{streamJob.messagesProcessed}}</div>
                  </td>
                  <td>
                    <div class="rowcounttd">{{streamJob.processedRowsPerSecond.toFixed(2)}}</div>
                  </td>
                  <td>
                    <div class="statustd">
                    <span class="" [class.info-s]='streamJob.status === "RUNNING" || streamJob.status === "IN_PROGRESS"'   [class.warning-s]='streamJob.status === "DISCARDED"' [class.success-s]='streamJob.status === "FINISHED"' [class.danger-s]='streamJob.status === "FAILED" || streamJob.status === "FAILED_TO_LAUNCH" || streamJob.status === "IN_PROGRESS_WITH_ERRORS"  || streamJob.status === "STOPPED"' [class.primary-s]='streamJob.status==="NOT_LAUNCHED"'>{{ streamJob.status }}</span> 
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </aside>
      </section>
    </article>
  </div>


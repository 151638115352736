import { Component, OnInit, Input, NgModule } from '@angular/core';
import { FormControl, NgForm, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Config } from '../../../../config'
import { DataSourceControllerService } from '../../../../services/api/data-source/data-source-controller.service'
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { interval } from 'rxjs';
import Swal from 'sweetalert2/dist/sweetalert2.js';

import { RdbmsConnector } from "../../../../models/rdbms/rdbms-connector.model"
import { ProjectService } from 'src/app/services/project/project-service.service';

@Component({
  selector: 'app-create-and-test-connection',
  templateUrl: './create-and-test-connection.component.html',
  styleUrls: ['./create-and-test-connection.component.scss']
})
export class CreateAndTestConnectionComponent implements OnInit {

  //test connection object
  data: any;

  //post connection object
  connection: any;
  load:any;
  rdbmsConnectorList: any[] = [];
  hide = true;
  //snackbar 
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  status: string;

  testSuccessful: boolean;

  //validator
  connectionForm: FormGroup;

  form = { driver: '', hostName: '', username: '', password: '' };


  constructor(private formBuilder: FormBuilder, public service: DataSourceControllerService, public router: Router, public projectService: ProjectService,
    private snackBar: MatSnackBar, private activatedRoute: ActivatedRoute
  ) {


  }

  projectName = this.activatedRoute.snapshot.params['id2'];
  databaseType = this.activatedRoute.snapshot.params['id3'];

  //form model

  dataSourceCredentials = this.formBuilder.group({
    id: [""],
    name: ["",Validators.required],
    description: [""],
    databaseType: this.databaseType,
    hostName: ["", Validators.required],
    port: [this.portSelector(this.databaseType), [Validators.required, Validators.pattern('[0-9]*')]],
    username: ["", Validators.required],
    password: [""],
    projectName: this.projectName
  })

  portSelector(type){
    if (type === 'redshift') {
      return '5439'
    } else if (type === 'mysql') {
      return '3306'
    } else if (type === 'synapse') {
      return '1433'
    } else if (type === 'memsql') {
      return '3306'
    } else if (type === 'sqlserver') {
      return '1433'
    } else if (type === 'postgresql') {
      return '5432'
    } else if (type === 'snowflake') {
      return '443'
    } else {
      return ''
    }
  }
  
  getAllConnectionsByProjectName() {
    this.service.getAllConnectionsByProjectName(this.projectName).toPromise()
      .then(response => {
        let data: any

        data = response;
        for (let i = 0; i < data.length; i++) {
          if (data[i].databaseType == this.databaseType) {
            this.rdbmsConnectorList.push(data[i])

          }
        }
      }
      )
  }

  //populate form with saved connection
  populateForm(savedRecord: RdbmsConnector) {

    this.dataSourceCredentials.setValue({
      id: savedRecord.id,
      name: savedRecord.name,
      description: savedRecord.description,
      databaseType: this.databaseType,
      hostName: savedRecord.hostName,
      port: savedRecord.port,
      username: savedRecord.username,
      password: savedRecord.password,
      projectName: this.projectName,

    })


  }

  //populate form with saved connection
  clearForm() {

    this.dataSourceCredentials.setValue({
      id: "",
      name: "",
      description: "",
      databaseType: this.databaseType,
      hostName: "",
      port: "",
      username: "",
      password: "",
      projectName: this.projectName,

    })
    this.testSuccessful=false

  }

  // post connection method
  postConnection() {
    console.log(this.dataSourceCredentials)
    if (this.dataSourceCredentials.value.id != "") {
      this.error("Connection already exists")


    } else {
      this.service.postRDBMSConnector(this.dataSourceCredentials.value).subscribe((response) => {
        console.log(response)
        this.connection = response;
        this.router.navigateByUrl(`project/${this.projectName}/data-sources`);
        this.success("New Data Source created")
      });


    }
  }

  // test connectio nmethod
  testConnection() {
    this.load=true
    this.service.testRDBMSConnector(this.dataSourceCredentials.value).subscribe((response) => {
      this.data = response;
      console.log(response)
      if (this.data.status.toLowerCase() == "connection successful") {
        console.log(this.data.status)
        this.success(this.data.status)
        this.testSuccessful = true;
        this.load=false
      } else {
        this.error(this.data.status)
        this.testSuccessful = false;
        this.load=false
      }
    });

  }

  //snackbar
  openSnackBar(status: string, statusLogo: string) {
    this.snackBar.open(status, statusLogo, {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['tertiary-active']

    });
  }

  success(status: string) {
    Swal.fire({
      type: 'Success',
      title: 'Success!',
      text: status,
      confirmButtonText: 'Ok',
    })
  }

  error(status: string) {
    Swal.fire({
      type: 'Error',

      title: 'Test Connection Failed!',
      text: status,
      confirmButtonText: 'Ok',
    })
  }

  capitalize(value: string) {
    if (typeof value !== 'string') return ''
    return value.charAt(0).toUpperCase() + value.slice(1)
  }



  ngOnInit(): void {
    this.getAllConnectionsByProjectName();
    console.log(this.rdbmsConnectorList)
    this.testSuccessful = false;

    this.connectionForm = new FormGroup({
      hostname: new FormControl(this.form.hostName, Validators.required),
      username: new FormControl(this.form.username, Validators.required),
      password: new FormControl(this.form.password),
    })


  }


}

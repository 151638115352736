import { Component, OnInit } from '@angular/core';
import { FormControl, NgForm, FormGroup, FormBuilder, Validators, FormArray, ReactiveFormsModule } from '@angular/forms';
import { DataSourceControllerService } from 'src/app/services/api/data-source/data-source-controller.service';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { CatalogService } from "../../../../../../../../services/api/catalogService/catalog.service"
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { CdkDragDrop, moveItemInArray, transferArrayItem, copyArrayItem } from '@angular/cdk/drag-drop';
import { RdbmsIngestionControllerService } from 'src/app/services/api/rdbms-controller/rdbms-ingestion-controller.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import { IngestionSharingServiceService } from 'src/app/ingestion-sharing-service.service';
import { ProjectService } from 'src/app/services/project/project-service.service';
import { StreamingdataSourceService } from 'src/app/services/api/data-source/streamingdata-source.service';
import { StreamIngestionControllerService } from 'src/app/services/api/streamController/stream-ingestion-controller.service';

@Component({
  selector: 'app-edit-step-one-stream-ingestion',
  templateUrl: './edit-step-one-stream-ingestion.component.html',
  styleUrls: ['./edit-step-one-stream-ingestion.component.scss']
})
export class EditStepOneStreamIngestionComponent implements OnInit {


  singleDataSource: any
  // treeControl = new NestedTreeControl(node => node.children);

  load = true;

  dataSources: any;
  dataSourceData: any;

  streamConnectorId: string;
  status = false;
  buttonEnable: boolean = false;


  isSubmitShow: boolean = false;


  constructor(private formBuilder: FormBuilder, private dataSourceController: DataSourceControllerService,
    private catalogService: CatalogService, public service: RdbmsIngestionControllerService, public router: Router,
    private snackBar: MatSnackBar, private activatedRoute: ActivatedRoute, public IngestionService: IngestionSharingServiceService,
    public projectService: ProjectService, private streamingDataSourceService: StreamingdataSourceService,
    private streamIngestionControllerService: StreamIngestionControllerService



  ) { }
  ingestionId: string = this.activatedRoute.snapshot.params['id'];

  projectName = this.activatedRoute.snapshot.params['id2'];


  showDiv = {
    stepone: true,
    steptwo: false,
    stepthree: false,
    stepfour: false,
    stepfive: false,

    stepbtnone: true,
    stepbtntwo: false,
    stepbtnthree: false,
    stepbtnfour: false,
    stepbtnfive: false,

    cancelbtn: false,
    nextbtn: true,
    nextbtntwo: false,
    nextbtnthree: false,
    nextbtnfour: false,


    backbtn: false,
    backbtntwo: false,
    backbtnthree: false,
    backbtnfour: false,
    backbtnfive: false,

    submitbtn: false
  }


  stepOneStreamForm = this.formBuilder.group({
    streamConnectorId: [""],
    name: ["", Validators.required],
    description: [""]
  })

  savedDatasourceDetails = this.formBuilder.group({
    name: [""]
  })

  identifyDriver(driver: string): string {
    if (driver == "mysql") {
      return "../../../../assets/images/mysqllogo.svg"
    } else if (driver == "oracle") {
      return "../../../../assets/images/Oracle.svg"
    } else if (driver == "sqlserver") {
      return "../../../../assets/images/sqlserver.png"
    } else if (driver == "db2") {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/5/51/IBM_logo.svg/1000px-IBM_logo.svg.png"
    } else if (driver == "snowflake") {
      return "../../../../assets/images/snowflake.svg"
    } else if (driver == "redshift") {
      return "https://cdn.worldvectorlogo.com/logos/aws-rds.svg"
    }
    else if (driver == "postgresql") {
      return "../../../../assets/images/postgresql.png"
    }

    else {
      return "../../"
    }
  }

  submitForm(fields: any): any {
    this.stepOneStreamForm.patchValue({

      streamConnectorId: fields.value.streamConnectorId,
      name: fields.value.name,
      description: fields.value.description,

    })


    this.IngestionService.setStepOneStreamData(this.stepOneStreamForm.value)
    this.streamConnectorId = this.stepOneStreamForm.value.streamConnectorId
    this.router.navigate([`/project/${this.projectName}/ingest/editStepTwoStream/${this.streamConnectorId}/ingestion/${this.ingestionId}`]);
  }

  carryData() {
    if (this.IngestionService.getStepOneStreamData() == null) {

      this.router.navigate([`/project/${this.projectName}/ingest//select-ingestion/stepTwoStream/${this.ingestionId}`]);
    }
    if (this.IngestionService.getStepOneStreamData() !== null) {
      this.router.navigate([`/project/${this.projectName}/ingest/select-ingestion/stepTwoStream/${this.IngestionService.getStepOneStreamData().streamConnectorId}`]);

    }

  }

  //API METHODS

  getAllDataSourcesByProjectName() {
    this.streamingDataSourceService.getAllStreamDataSourcesByProjectName(this.projectService.getCurrentProject()).subscribe(res => {
      this.dataSources = res;
    })
  }

  setDataSource(id: string) {
    this.stepOneStreamForm.patchValue({
      streamConnectorId: id
    })
  }


  // getSingleDataSource(id: string) {
  //   this.dataSourceController.getSingleRDBMSConnector(id).subscribe(res => {
  //     this.singleDataSource = res;
  //     this.savedDatasourceDetails.setValue({
  //       name: this.singleDataSource.name
  //     })


  //   })
  // }





  ngOnInit(): void {
    this.streamIngestionControllerService.getSingleStreamIngestion(this.ingestionId).subscribe(response => {
      let data: any = response;
      console.log(data);
      this.stepOneStreamForm.patchValue({
        streamConnectorId: data.streamConnectorId,
        name: data.name,
        description: data.description,
      })
    })

    this.getAllDataSourcesByProjectName();
    if (this.IngestionService.getStepOneStreamData() != null) {
      this.stepOneStreamForm.patchValue({
        streamConnectorId: this.IngestionService.getStepOneStreamData().streamConnectorId,
        name: this.IngestionService.getStepOneStreamData().name,
        description: this.IngestionService.getStepOneStreamData().description,

      })

      // this.getSingleDataSource(this.IngestionService.getStepOneStreamData().streamConnectorId)

    }
    setTimeout(() => this.load = false, 500)
  }

}

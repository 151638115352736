import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from 'src/app/services/project/project-service.service';
import { JobControllerService } from '../../../services/api/job/job-controller.service';
import { RdbmsIngestionControllerService } from '../../../services/api/rdbms-controller/rdbms-ingestion-controller.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import * as $ from 'jquery';
import { StreamJobControllerService } from 'src/app/services/api/streamJob/stream-job-controller.service';
import { SqoopServiceService } from 'src/app/services/sqoop/sqoop-service.service';
import { LogService } from 'src/app/services/log/log.service';
import { SqoopConnector } from 'src/app/models/sqoop-connector/sqoop-connector.model';
import { StreamingdataSourceService } from 'src/app/services/api/data-source/streamingdata-source.service';
import { StreamIngestionControllerService } from 'src/app/services/api/streamController/stream-ingestion-controller.service';
import { forkJoin, Observable } from 'rxjs';
import { NgxPaginationModule } from 'ngx-pagination';
import { PageEvent } from '@angular/material/paginator';


@Component({
  selector: 'app-jobs-progress',
  templateUrl: './jobs-progress.component.html',
  styleUrls: ['./jobs-progress.component.scss'],
})
export class JobsProgressComponent implements OnInit, AfterViewInit {
  isSidenav: boolean = false;

  constructor(
    public jobsService: JobControllerService,
    private rdbmsService: RdbmsIngestionControllerService,
    private projectService: ProjectService,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    public sqoopService: SqoopServiceService,
    public jobservice: JobControllerService,
    public logService: LogService,
    private streamJobControllerService: StreamJobControllerService,
    private streamingdataSourceService: StreamingdataSourceService,
    private streamIngestionControllerService: StreamIngestionControllerService
    
    
  ) {}
  //stream ingestion starts
  //load: boolean;
  id = this.activatedRoute.snapshot.params['id'];
  //singleIngestion: any;
  streamJob: any=[];
  //dataSource: any;

  //logReport: any;
  streamJobForm: boolean;
  sqoopJob: any = [];
  //Stream Ingestion Ends
  loggedUser: any;
  data: any = [];
  toggle: any = [];
  toggleTwo: any = [];
  play: boolean;
  term: string;
  Sqoopterm:string;
  pagination:boolean=true;
  
  projectName = this.activatedRoute.snapshot.params['id2'];
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  refreshStatus: boolean = false;
  load: boolean;
  page:number = 1;
  rdbmsSqoopPage =1;
  streamSqoopPage=1;
  sqoopJobPage:number=1;
  expand = true;
  check
  collapse = true;
  errorMessage: string;
  isError: boolean = false;
  noJob:boolean=false;

  //job list section begins
  sqoopJobId: any = this.activatedRoute.snapshot.queryParams.data;
  Id = this.activatedRoute.snapshot.params['id'];
  //rdbmsJob: any = [];
  sqoopList: any = [];
  sqoopJobIdsList: any[] = [];
  latestProgress: any;
  output: any = [];
  logMessage: any = [];

  singleSqoop: any = [];
  sqoopCommand: string;
  logReport: any = [];
  ingestObject: any = [];
  showJobDetails: boolean = false;
  jobDetailsName: string;
  showStreamJob:boolean;
  item:any;
  ingestionType:any;
  ingestionName:string;
  startTime:any;
  queryCheck:boolean;
  filteredSqoopjob:any=[]
  filterOptions: any=[];
  filterParams: any = [];
  sqopFilterOptions:any=[];
  sqopFilterOptionsNew:any=[]
  selectedStatus :string="All ()";
  statusParam:string;
  clickedStatus:string='All';
  filteredJobs:any=[];
  // streamFilterCheck:boolean; //for hiding search and filter option
  filterCheck:boolean=false; //for hiding search and filter option
 ingestJob:any=[];
 //color for status
 finished="finished";
 running="running";
 error="error";
 stoped="stoped";
 failed="failed";
 discarded="discarded";
 newValue:any;
  
//   public pageSlice=this.data.slice(0,3);
// //pagination for left table
// onPageChange(event:PageEvent){
//   console.log(event);
//   const startIndex=event.pageIndex * event.pageSize;
//   let endIndex=startIndex+event.pageSize;
//   if(endIndex>this.data.length){
//     endIndex=this.data.length;

//   }
//   this.pageSlice=this.data.slice(startIndex,endIndex);
// }

  filterOptionNameChange(option:string){
    let name=option.toLowerCase();
    switch(name){
      case 'in_progress':
        return 'RUNNING';

        case 'in_progress_with_errors':
        return 'ERROR'
    
      case 'new_saving':
        return 'NEW SAVING';
   
      case 'all':
        return 'ALL';
    }
    return name.toUpperCase();
  }

  // getJobStatus() usted to transform status column value in both table (Eg:"IN_PROGRESS_WITH_ERRORS" to "PROGRESS")

  
  getJobStatus(job:any):string{
    let jobStatusValue = job.status.trim().toLowerCase();
    switch(jobStatusValue){
      case 'finished':
        return 'FINISHED';
  
      case 'in_progress':
        return 'RUNNING';
         
      case 'stopped':
        return 'STOPPED';
        
      case 'in_progress_with_errors':
        return 'ERROR';
        
        case 'processing_in_hive':
        return 'IN PROGRESS';
  
      case 'discarded':
        return 'DISCARDED';

      case 'errors':
        return 'ERROR';

      case 'failed':
        return  'FAILED' ;
    }
    
    return jobStatusValue.toUpperCase();
  }
  



  onClickExpand(item:any) {
    let ingestionTypeValue=item.ingestionType.toLowerCase();
    let jobId=item.id;
    let jobName=item.name;
    this.selectedStatus='ALL';
    this.sqoopJobFiltering(this.selectedStatus);
    this.rdbmsSqoopPage = 1;//for pagination 
    


    //console.log(ingestionTypeValue);
    //console.log('Job id = '+jobId);
    //console.log('JobName= '+jobName);
    switch (ingestionTypeValue) {
      case 'table':
          //console.log("It is a table.");
          this.getSqoopJobs(item);
          break;
      case 'query':
        this.getSqoopJobs(item);  
          //console.log("It is a query.");
          break;
      case 'database':
          this.getSqoopJobs(item);
          //console.log("It is a database.");
          break;
      case 'stream':
          this.getStreamIngestion(item);
          //console.log("It is a stream.");
          break;
      
      default:
          //console.log("No such type exists!");
          break;
  }
  }

 
  //stream
  getStreamIngestion(item:any){
    let jobId=item.id;
    this.ingestionName=item.name;
    this.ingestionType=item.ingestionType.toLowerCase;
    this.showJobDetails=true;
    this.showStreamJob=true;
    this.streamJobControllerService.getSingleStreamJob(jobId).subscribe((res:any)=>{
      //console.log(res);
      this.streamJob=res;
      this.filterCheck=false;//to hide filter and search option
      this.showJobDetails=false;
    })
  }
//Non stream
  getSqoopJobs(item:any){
    let jobId=item.id;
    this.ingestionName=item.name;
    this.ingestionType=item.ingestionType.toLowerCase();
    if(this.ingestionType =='query'){ //Used to customize table column name 
      this.queryCheck=true;
      this.filterCheck=false;
    }
    else{
      this.queryCheck=false;
      this.filterCheck=true;
    };
    this.startTime=item.createdAt;
    this.showJobDetails=true;
    this.showStreamJob=false;
    //console.log('the type is',typeof(this.ingestionType));
    
    this.jobsService.getSingleSqoopJob(jobId).subscribe((res:any)=>{
     // console.log(res);
      //console.log("Rdbms job id" ,jobId);
      this.sqoopList=res;
      this.ingestObject=res;
      //console.log('check',this.ingestObject[0].database);
     // console.log(this.sqoopList);
      
      
///filter option  value length (eg: finished(0),failed(1)) 


// declare an array sqopFilterOptions to store final values of status.here we update values
//like status "new" count will be combined count of new and new_saving
//status running will show combined count of running and PROCESSING_IN_HIVE

this.sqopFilterOptions=[  
  // { Id: 1, name: 'All', value: 0  },
    { Id: 1, name: 'Pending', value: 0 },
    { Id: 2, name: 'Accepted',value: 0 },
    { Id: 3, name: 'New', value: 0 },
    // { Id: 4, name: 'New_Saving', value: 0 },
    { id: 5, name: 'Running', value: 0 },
    // { id: 6, name: 'Processing_In_Hive', value: 0 },
    // { id: 7, name: 'Failed_To_Launch', value: 0 },
    { Id: 8, name: 'Failed',value: 0 },
    { Id: 9, name: 'Killed',value: 0 },
    { Id:10, name: 'Finished',value: 0 },
    { Id:11, name: 'Submitted',value: 0}
  
  
  ] 
      this.sqopFilterOptionsNew.map((option,index) => {
        const filtered = this.sqoopList.filter(
          (sqoops) => sqoops.status === option.name.toUpperCase()
        );

        if(option.name.toUpperCase()==='NEW_SAVING'){
            // let optionIndex=this.sqopFilterOptions.findIndex('New')
            let optionIndex=this.sqopFilterOptions.findIndex((item)=>item==='New')
            this.sqopFilterOptions[optionIndex]= {
              name:'New',
              value:(this.sqopFilterOptions[optionIndex].value ||0) +filtered.length
            }

          }

          else if(option.name.toUpperCase()==='FAILED_TO_LAUNCH'){
            // let optionIndex=this.sqopFilterOptions.findIndex('Failed')
            let optionIndex=this.sqopFilterOptions.findIndex((item)=>item==='Failed')
            this.sqopFilterOptions[optionIndex]={
              name:'Failed',
              value:(this.sqopFilterOptions[optionIndex].value ||0) +filtered.length
            }

          }
          else if(option.name.toUpperCase()==='PROCESSING_IN_HIVE'){
            let optionIndex=this.sqopFilterOptions.findIndex((item)=>item==='Running')
            this.sqopFilterOptions[optionIndex]= {
              name:'Running',
              value:(this.sqopFilterOptions[optionIndex]?.value ||0) +filtered.length
            }
          }

          else{
           // console.log("At else-----------");
            // let optionIndex=this.sqopFilterOptions.findIndex((item)=>item===option.name)
            this.sqopFilterOptions[index]={
              name:option.name,
              value:(this.sqopFilterOptions[index].value ||0 )+filtered.length
            }
          }
        // let newOption = {
        //   name: option.name,
        //   value: filtered.length,
        // };
       
        // //  switch(option.name){
        // //   case option.name=='KILLED':
        // //    return [name:'new',
        // //            value: filtered.length]


        // //  }
        // return newOption;
        
      });
      
      this.sqoopJobFiltering(this.selectedStatus)
      this.showJobDetails=false;
    })

  }

//Sqoop Job Filtering

  public sqoopJobFiltering(status:any){
    // this.selectedStatus="All";
    // console.log('the stause you have selected ',status);
        
    let recivedStatus =status.split(" ");
     this.statusParam=recivedStatus[0].toUpperCase();
     
    //  console.log("the statas param is",this.statusParam);
     
    // this.selectedStatus=trecivesStatus[0];
    if(this.statusParam == 'ALL'){
      this.filteredSqoopjob=this.sqoopList;
      // console.log(this.filteredSqoopjob);
    }
    //if user select In_Progress then both "In_Progress" and "In_Progress_with_Errors" will be fetched
    else if(this.statusParam =='FAILED'){ 
      this.filteredSqoopjob=this.sqoopList.filter(
        (item)=>this.statusParam.includes(item.status)
      );
      // console.log(this.filteredSqoopjob);


    }
    else if(this.statusParam == 'RUNNING'){
      this.filteredSqoopjob=this.sqoopList.filter((job)=>{
        job.status=='RUNNING' && job.status=='PROCESSING_IN_HIVE'
      });
      // console.log(this.filteredSqoopjob);

    }
    else if(this.statusParam == 'NEW'){
      this.filteredSqoopjob=this.sqoopList.filter((job)=>{
        job.status=='NEW' && job.status=='NEW_SAVING'
      });
      // console.log(this.filteredSqoopjob);

    }
    else{
      // console.log(this.selectedStatus)
      this.filteredSqoopjob = this.sqoopList.filter(
        (item) => item.status === this.statusParam.toUpperCase()
        
        // (item) => this.selectedStatus.includes(item.status)
      );
      // console.log(this.statusParam);

    }
  
    
    
    
    
  }
   

  //for view sqoop command and log(works when click action icons of right side table (jobs table))
  passItemIdLog(id: string) {
    // this.currentLogId = id;
    // console.log("log id "+id)
    this.getLogDataById(id);
    this.getSingleSqoopById(id);
  }

  getSingleSqoopById(id: string) {
    this.sqoopService.getSingleSqoopJobBySqoopId(id).subscribe((res) => {
      this.singleSqoop = res;

      this.sqoopCommand = this.singleSqoop.sqoopCommand.join(' ');

      //console.log("SingleSqoopById is: "+id)
      // console.log(this.singleSqoop)
    });
  }
  getLogDataById(id: string) {
    //console.log("log is working--"+id)
    this.logService.getLogById(id).subscribe((logRes) => {
      this.logReport = logRes;
      //console.log(this.logReport)
    });
  }



 
  checkforNull(value: string): string {
    if (value == null) {
      return 'N/A';
    } else {
      return value;
    }
  }

  capitalize(value: string) {
    if (typeof value !== 'string') return '';
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  datatypeSelector(bulk: any) {
    if (bulk === false) {
      return 'Table';
    } else {
      return 'Database';
    }
  }

  //job list section ends

  refresh() {
    this.refreshStatus = true;
    this.expand = true;
    this.getAllJobsByProjectName();
    this.clickedStatus='All';
    setTimeout(() => (this.refreshStatus = false), 500);
  }


  ngAfterViewInit() {
    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $('.pagec').css('height', contenth);
      $('.sidebar-wrapper').css('height', sidebarh);
    });
  }

  isHidden() {
    this.loggedUser = JSON.parse(sessionStorage.getItem('loggedUser'));
    for (
      var user_auths = 0;
      user_auths < this.loggedUser.authorities.authorities.length;
      user_auths++
    ) {
      if (
        this.loggedUser.authorities.authorities[user_auths].authority ===
          'PERM_MAINTAINER' ||
        this.loggedUser.authorities.authorities[user_auths].authority ===
          'PERM_OWNER' ||
        this.loggedUser.authorities.authorities[user_auths].authority ===
          'PERM_ADMIN' ||
        this.loggedUser.authorities.authorities[user_auths].authority ===
          'PERM_OPERATOR'
      ) {
        return false;
      }
    }
    return true;
  }

  changeStatusColor(value: string): string {
    if (value == 'Finished') {
      return 'text-success';
    } else if (value == 'Failed' || value == 'In Progress with Errors') {
      return 'text-danger';
    } else if (value == 'In Progress') {
      //return 'text-primary';
      return 'text-danger';
    } else if (value == 'Discarded') {
      return 'text-warning';
    } else {
      return 'text-dark';
    }
  }
  //left table begins


  getAllJobsByProjectName() {
    this.showJobDetails=true;
    this.showJobDetails=true;
    this.refreshStatus=true;
    this.jobsService
      .getAllJobsByProjectName(this.projectService.getCurrentProject())
      .toPromise()
      .then((response) => {
        this.data = response;
        //console.log("project names "+this.data);
        for (let i = 0; i < this.data.length; i++) {
          //console.log(this.data)
          // console.log(this.data[i].type = "RDBMS");
        }
        this.data.sort((a: any, b: any) =>
          a.createdAt < b.createdAt ? 1 : -1
        );
        ////this.load = false;

        this.streamJobControllerService
          .getAllStreamJobsByProject(this.projectName)
          .subscribe((response) => {
            // console.log(response, this.data);
            // console.log(this.data)
            
            let data: any = response;
            this.data = this.data.concat(data);
           // console.log(this.data)

            // Getting Individual Filter Option count 
            this.filterOptions = this.filterOptions.map((option) => {
              const filtered = this.data.filter(
                (jobs) => jobs.status === option.name.toUpperCase()
              );
    
              let newOption = {
                name: option.name,
                value: filtered.length,
              };
           
              
              return newOption;
            });
            // //////
            
            this.data = this.returnSortedList(this.data);
            this.ingestJobFilterig(this.clickedStatus);
            this.refreshStatus=false;

          });
          
        // console.log(this.data);
      });
      // this.ingestJobFilterig(this.clickedStatus);
      // this.showJobDetails=false;
      
  }
  ingestJobFilterig(option:string){
    this.expand=true;
    // this.showJobDetails=true;
    // console.log(this.clickedStatus);
    this.clickedStatus=option.toUpperCase();
    // console.log(this.clickedStatus);
    if(this.clickedStatus=='ALL'){
      this.filteredJobs=this.data;
      // console.log(this.filteredJobs);
    }
    else if(this.clickedStatus=='ERROR'){
      this.filteredJobs=this.data.filter((job)=>job.status ==='IN_PROGRESS_WITH_ERRORS');
      // console.log(this.filteredJobs);
    }
    else{
      this.filteredJobs=this.data.filter((job)=>job.status ===this.clickedStatus)
      // console.log(this.filteredJobs);
    }
      this.showJobDetails=false;

  }

  // Filtering Ingestion Jobs

    public applyFilter(event: any, option: string) {
    if (event.target.checked) {
      this.filterParams.push(option.toUpperCase());
    } else {
      this.filterParams = this.filterParams.filter(
        (param) => param !== option.toUpperCase()
      );
    }
    // console.log(this.filterParams);
    
        // (item) => this.selectedStatus.includes(item.status)

  
  }
  // filtering using checkbox
  // public get returnIngestionJob(): any[] {
  //   let filteredIngestions = [];

  //   if (!this.filterParams.length && !this.term) {
  //     return this.data;
  //   } else {
  //     // switch(this.filterParams){
  //     //   case "in_progeerss":
  //     //   return filteredIngestions= this.data.filter(
  //     //     (job)=>this.statusParam.includes(job.status)
          
  //     //   );
        
  //     // }


      
  //     this.filterParams.forEach((param) => {
  //       this.data.forEach((job) => {
  //         if (job.status===param) {
  //           filteredIngestions.push(job);
  //         }
  //       });
  //     });
  //   }
  //   return filteredIngestions;
  // }
 
   hidePagination(term:string){
    if(term){
      this.pagination=false
    }
    else{
      this.pagination=true;
    }
    // this.noJobfound(term);
    
  }
  // noJobfound(term:string)
  // {
  //   this.filteredJobs.forEach((job)=>{
  //     if(job['projectName'].toLowerCase()===term.toLowerCase()){
  //     this.noJob=false;
        
  //     }else{
  //       console.log('not ok');
  //       this.noJob=true;
  //     }
      
  //     })
  // }


  getAllJobs() {
    this.jobsService
      .getJobs()
      .toPromise()
      .then((response) => {
        this.data = response;
      });
  }

  deleteJobById(id: string, name: string) {
    Swal.fire({
      type: 'warning',

      title:
        "Are you sure you want to delete this job from the ingestion '" +
        name +
        "'?",
      text: 'All information associated to this ingestion will be permanently deleted. If this is an incremental job you will lose the increment interval this job was handling.',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed == true) {
        this.expand=true;
        this.jobsService
          .deleteJobs(id)
          .toPromise()
          .then((response) => {
            this.data = response;
            this.getAllJobsByProjectName();
          });
      }
    });
  }
  deleteStreamJobById(id: string, name: string) {
    Swal.fire({
      type: 'warning',

      title:
        "Are you sure you want to delete this job from the ingestion '" +
        name +
        "'?",
      text: 'All information associated to this ingestion will be permanently deleted. If this is an incremental job you will lose the increment interval this job was handling.',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed == true) {
        this.expand=true;
        this.streamJobControllerService
          .deleteStreamJobById(id)
          .toPromise()
          .then((response) => {
            this.data = response;
            this.getAllJobsByProjectName();
          });
      }
    });
  }

  dateFormatter(date: string): string {
    let dateformat = new Date(date);
    let dateArray = dateformat.toString().split(' ');
    return (
      dateArray[1] +
      ' ' +
      dateArray[2] +
      ', ' +
      dateArray[3] +
      `
  ${dateArray[4]} `
    );
  }

  sortAscending(data: any) {
    data.sort((a: any, b: any) => (a.createdAt > b.createdAt ? 1 : -1));
  }
  sortDescending(data: any) {
    data.sort((a: any, b: any) => (a.createdAt < b.createdAt ? 1 : -1));
  }
  returnSortedList(data: any) {
    return data.sort((a: any, b: any) => (a.createdAt < b.createdAt ? 1 : -1));
  }
  sortByName(data: any) {
    data.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
  }

  startStreamIngestion(id: string, index: number, name: string) {
    Swal.fire({
      type: 'Warning',
      title:
        "Are you sure you want to resume this job from the ingestion '" +
        name +
        "'?",
      text: 'This stream will resume running from last saved checkpoint.',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed == true) {
        this.streamJobControllerService
          .startStreamJobById(id)
          .subscribe((response) => {
            this.success('Job Resumed');
            this.getAllJobsByProjectName();
          });
        this.getAllJobsByProjectName();
      }
    });
  }

  resumeJob(id: string, index: number, name: string) {
    Swal.fire({
      type: 'Warning',
      title:
        "Are you sure you want to resume this job from the ingestion '" +
        name +
        "'?",
      text: 'The job will be restarted. Any finished sqoop jobs will not be run.',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed == true) {
        this.jobsService.resumeJob(id).subscribe((response) => {
          this.success('Job Resumed');
          this.getAllJobsByProjectName();
        });
        this.getAllJobsByProjectName();
      }
    });
  }
  pause(id: string, name: string) {
    Swal.fire({
      type: 'Warning',
      title: "Are you sure you want to stop the job '" + name + "'?",
      text: 'The job will be halted',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed == true) {
        this.jobsService.pauseJob(id).subscribe((response) => {
          this.success('Job Paused');

          // console.log(response)
          this.getAllJobsByProjectName();
        });
        this.getAllJobsByProjectName();
      }
    });
  }

  stopStreamJob(id: string, name: string) {
    Swal.fire({
      type: 'Warning',
      title: "Are you sure you want to pause the job '" + name + "'?",
      text: 'The job will be halted',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed == true) {
        this.streamJobControllerService
          .stopStreamJobById(id)
          .subscribe((response) => {
            this.success('Stream Stopped');

            //console.log(response)
            this.getAllJobsByProjectName();
          });
      }
    });
  }

  discard(id: string, name: string) {
    Swal.fire({
      type: 'Warning',
      title: "Are you sure you want to discard this job '" + name + "'?",
      text: 'This job will be permanently discarded ',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed == true) {
        this.jobsService.discardJob(id).subscribe((response) => {
          // console.log(response)
          this.success('Job Successfully Discarded');
          this.getAllJobsByProjectName();
        });
      }
    });
  }

  
  ngOnInit(): void {
    this.getAllJobsByProjectName();
    
    this.filterOptions = [
      { name: 'All', type :"radio",value: 0 },
      { name: 'Finished', type :"radio",value: 0 },
      { name: 'In_Progress',type :"radio", value: 0 },
      { name: 'In_Progress_With_ERRORS',type :"radio", value: 0 },
      { name: 'Stopped',type :"radio", value: 0 },
      { name: 'Failed',type :"radio", value: 0 },
      { name: 'Discarded',type :"radio", value: 0 },
      

    ];
    
    // this.sqopFilterOptions = [
      
    //   // { Id: 1, name: 'All', value: 0  },
    //   { Id: 1, name: 'Pending', value: 0 },
    //   { Id: 2, name: 'Accepted',value: 0 },
    //   { Id: 3, name: 'New', value: 0 },
    //   // { Id: 4, name: 'New_Saving', value: 0 },
    //   { id: 5, name: 'Running', value: 0 },
    //   // { id: 6, name: 'Processing_In_Hive', value: 0 },
    //   // { id: 7, name: 'Failed_To_Launch', value: 0 },
    //   { Id: 8, name: 'Failed',value: 0 },
    //   { Id: 9, name: 'Killed',value: 0 },
    //   { Id:10, name: 'Finished',value: 0 },
    //   { Id:11, name: 'Submitted',value: 0}

    // ];
    this.sqopFilterOptionsNew=[
      
      //  { Id: 1, name: 'All', value: 0  },
      { Id: 1, name: 'Pending', value: 0 },
      { Id: 2, name: 'Accepted',value: 0 },
      { Id: 3, name: 'New', value: 0 },
      { id: 5, name: 'Running', value: 0 },
      
      { Id: 8, name: 'Failed',value: 0 },
      { Id: 9, name: 'Killed',value: 0 },
      { Id:10, name: 'Finished',value: 0 },
      { Id:11, name: 'Submitted',value: 0},
      { id: 6, name: 'Processing_In_Hive', value: 0 },
      { id: 7, name: 'Failed_To_Launch', value: 0 },
      { Id: 4, name: 'New_Saving', value: 0 },

    ];
    
  
  }

  openSnackBar(status: string, statusLogo: string) {
    this.snackBar.open(status, statusLogo, {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['tertiary-active'],
    });
  }

  success(status: string) {
    Swal.fire({
      type: 'Success',

      title: 'Success!',
      text: status,
      confirmButtonText: 'Ok',
    });
  }

  duration(createdAt: number, finishedAt: number, stringStatus: string) {
    let currentTime = Date.now();
    if (
      stringStatus.toLowerCase() == 'failed' ||
      stringStatus.toLowerCase() == 'stopped' ||
      stringStatus.toLowerCase() == 'failed to launch'
    ) {
      return finishedAt - createdAt;
    } else if (stringStatus.toLowerCase() == 'finished') {
      return finishedAt - createdAt;
    } else if (
      stringStatus.toLowerCase() == 'discarded' ||
      stringStatus.toLowerCase() == 'n/a'
    ) {
      return 0;
    } else {
      return currentTime - createdAt;
    }
  }
  miliConvert(milliseconds: number) {
    var minutes = milliseconds / 1000 / 60;
    var seconds = (milliseconds / 1000) % 60;
    var minutesRounded = Math.round(minutes);
    var secondsRounded = Math.round(seconds);
    var diff = minutesRounded + ' minutes, ' + secondsRounded + ' seconds';
    return diff;
  }
}

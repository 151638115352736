import { Component, OnInit } from '@angular/core';
import { FormControl, NgForm, FormGroup, FormBuilder, Validators, FormArray, ReactiveFormsModule } from '@angular/forms';
import { DataSourceControllerService } from 'src/app/services/api/data-source/data-source-controller.service';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { CatalogService } from "../../../../../../../../services/api/catalogService/catalog.service"
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { CdkDragDrop, moveItemInArray, transferArrayItem, copyArrayItem } from '@angular/cdk/drag-drop';
import { RdbmsIngestionControllerService } from 'src/app/services/api/rdbms-controller/rdbms-ingestion-controller.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import { IngestionSharingServiceService } from 'src/app/ingestion-sharing-service.service';
import { ProjectService } from 'src/app/services/project/project-service.service';

@Component({
  selector: 'app-edit-step-one-custom-ingestion',
  templateUrl: './edit-step-one-custom-ingestion.component.html',
  styleUrls: ['./edit-step-one-custom-ingestion.component.scss']
})
export class EditStepOneCustomIngestionComponent implements OnInit {

  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  //datasource

  singleDataSource: any
  // treeControl = new NestedTreeControl(node => node.children);

  load = true;

  dataSources: any;
  dataSourceData: any;

  status = false;
  buttonEnable: boolean = false;


  isSubmitShow: boolean = false;


  constructor(private formBuilder: FormBuilder, private dataSourceController: DataSourceControllerService,
    private catalogService: CatalogService, public service: RdbmsIngestionControllerService, public router: Router,
    private snackBar: MatSnackBar, private activatedRoute: ActivatedRoute, public IngestionService: IngestionSharingServiceService,
    public projectService: ProjectService, private rdbmsService: RdbmsIngestionControllerService




  ) { }

  id = this.activatedRoute.snapshot.params['id'];
  projectName = this.activatedRoute.snapshot.params['id2'];
  currentIngestion: any;

  showDiv = {
    stepone: true,
    steptwo: false,
    stepthree: false,
    stepfour: false,
    stepfive: false,

    stepbtnone: true,
    stepbtntwo: false,
    stepbtnthree: false,
    stepbtnfour: false,
    stepbtnfive: false,

    cancelbtn: false,
    nextbtn: true,
    nextbtntwo: false,
    nextbtnthree: false,
    nextbtnfour: false,


    backbtn: false,
    backbtntwo: false,
    backbtnthree: false,
    backbtnfour: false,
    backbtnfive: false,

    submitbtn: false
  }


  customIngestionFormFields = this.formBuilder.group({
    sourceId: ["", Validators.required],
    name: ["", Validators.required],
    description: [""],
    fileType: [""]

  })

  savedDatasourceDetails = this.formBuilder.group({
    name: [""]
  })




  stepOneForm(fields: any): any {
    this.customIngestionFormFields.patchValue({

      sourceId: fields.value.sourceId,
      name: fields.value.name,
      description: fields.value.description,

    })


    // console.log(this.customIngestionFormFields.value)
    this.IngestionService.setStepOneData(this.customIngestionFormFields.value)
    this.id = this.customIngestionFormFields.value.sourceId
    this.router.navigate([`/project/${this.projectName}/ingest/editStepTwoCustom/${this.id}/ingestion/${this.currentIngestion.id}`]);

  }

  async getCurrentIngestion() {
    let response = await this.rdbmsService.getSingleRdbmsIngestion(this.id).toPromise();
    this.currentIngestion = response
    this.IngestionService.setStepTwoData(this.currentIngestion.databases)

    this.customIngestionFormFields.patchValue({
      sourceId: this.currentIngestion.sourceId,
      name: this.currentIngestion.name,
      description: this.currentIngestion.description

    })

    // console.log(this.currentIngestion)

    this.getSingleDataSource(this.currentIngestion.sourceId)
    this.IngestionService.setStepTwoData(this.currentIngestion.databases)
  }

  carryData() {
    if (this.IngestionService.getStepOneData() == null) {

      this.router.navigate([`/project/${this.projectName}/editStepTwoCustom/${this.id}/ingestion/${this.currentIngestion.id}`]);
    }
    if (this.IngestionService.getStepOneData() !== null) {
      this.router.navigate([`/project/${this.projectName}/editStepTwoCustom/${this.IngestionService.getStepOneData().sourceId}/ingestion/${this.currentIngestion.id}`]);

    }

  }

  //API METHODS

  getAllDataSourcesByProjectName() {
    this.dataSourceController.getAllConnectionsByProjectName(this.projectService.getCurrentProject()).subscribe(res => {
      this.dataSources = res;
    })
  }

  identifyDriver(driver: string): string {
    if (driver == "mysql") {
      return "../../../../assets/images/mysqllogo.svg"
    } else if (driver == "oracle") {
      return "../../../../assets/images/Oracle.svg"
    } else if (driver == "sqlserver") {
      return "../../../../assets/images/sqlserver.png"
    } else if (driver == "db2") {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/5/51/IBM_logo.svg/1000px-IBM_logo.svg.png"
    } else if (driver == "snowflake") {
      return "../../../../assets/images/snowflake.svg"
    } else if (driver == "redshift") {
      return "https://cdn.worldvectorlogo.com/logos/aws-rds.svg"
    } else if (driver.toLowerCase() == "memsql" || driver.toLowerCase() == "memsql1") {
      return "https://venturebeat.com/wp-content/uploads/2020/05/1200px-Logo_memsql_color_trans-bg_400x240.svg_-e1588914678476.png?w=1200&strip=all"
    }
    else if (driver == "postgresql") {
      return "../../../../assets/images/postgresql.png"
    }

    else {
      return "../../"
    }
  }
  getSingleDataSource(id: string) {   
    this.load=true
    this.dataSourceController.getSingleRDBMSConnector(id).subscribe(res => {
      this.singleDataSource = res;
      this.id = this.singleDataSource.id;
      this.savedDatasourceDetails.setValue({
        name: this.singleDataSource.name
      })
      this.load=false;


    })
  }





  ngOnInit(): void {
    this.getCurrentIngestion();
    // console.log(this.id)
    // console.log(this.IngestionService.getStepOneData())
    this.getAllDataSourcesByProjectName();
    if (this.IngestionService.getStepOneData() != null) {
      this.customIngestionFormFields.patchValue({
        sourceId: this.IngestionService.getStepOneData().sourceId,
        name: this.IngestionService.getStepOneData().name,
        description: this.IngestionService.getStepOneData().description,
        fileType: this.IngestionService.getStepOneData().fileType,

      })

      this.getSingleDataSource(this.IngestionService.getStepOneData().sourceId)

    }
    // console.log(this.IngestionService.getStepOneData())
    setTimeout(() => this.load = false, 500)
  }

}

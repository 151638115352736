import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/services/users/user.service';
import { User } from '../../../models/user/user.model';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-user-profile-page',
  templateUrl: './user-profile-page.component.html',
  styleUrls: ['./user-profile-page.component.scss']
})
export class UserProfilePageComponent implements OnInit {

  constructor(public UserService: UserService,
    private _snackBar: MatSnackBar,
    private formBuilder: FormBuilder
  ) { }

  credentials: any
  user: any
  userInfo: any

  passwordForm = this.formBuilder.group({
    password: ["", Validators.required]
  })


  updatePassword() {

  }
  getLoggedUser() {
    this.credentials = JSON.parse(sessionStorage.getItem('credentials'));
    this.user = JSON.parse(sessionStorage.getItem('loggedUser'));
  }

  updateUser(user: any, credentials: any) {
    this.UserService.getUserByUserName(credentials.username).subscribe(userRes => {
      userRes.username = credentials.username
      userRes.password = credentials.password
      userRes.email = user.authorities.principal.email
      delete userRes.authorities
      delete userRes.accountNonExpired
      delete userRes.credentialsNonExpired
      delete userRes.accountNonExpired
      delete userRes.accountNonLocked
      delete userRes.permissions
      delete userRes.enabled
      this.UserService.updateUser(userRes).subscribe(res => {
        sessionStorage.setItem("loggedUser", JSON.stringify(new User(res)))
        sessionStorage.setItem("credentials", JSON.stringify(credentials))
        this.success("Update successful")
      })
    })
  }

  connectionSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      panelClass: ['tertiary-active']

    });
  }
  success(status: string) {
    Swal.fire({
      type: 'Success',

      title: 'Success!',
      text: status,
      confirmButtonText: 'Ok',
    })
  }

  ngOnInit(): void {
    this.getLoggedUser();
  }
}


import { Injectable } from '@angular/core';
import { Config } from '../../../config';
import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RdbmsConnector } from "../../../models/rdbms/rdbms-connector.model"
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Injectable({
  providedIn: 'root'
})
export class StreamJobControllerService {

  static url = Config.url;

  constructor(private httpClient: HttpClient,) { }

  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  // getStreamIngestionCount() {
  //   return this.httpClient.get(StreamJobControllerService.url + "/stream/count")
  //     .pipe(
  //       retry(1),
  //       // catchError(this.processError)
  //     )
  // }


  getAllStreamJobsByProject(projectName: string) {

    return this.httpClient.get(StreamJobControllerService.url + "/jobs/stream/project/" + projectName)
      .pipe(

        retry(1),
        catchError(this.handleError)

      )
  }

  getAllStreamJobs() {

    return this.httpClient.get(StreamJobControllerService.url + "/jobs/stream")
      .pipe(

        retry(1),
        catchError(this.handleError)

      )
  }

  

  getSingleStreamJob(id: string) {
    return this.httpClient.get<any>(StreamJobControllerService.url + "/job/stream/" + id)
      .pipe(
        retry(1),
        catchError(this.handleError)

        // catchError(this.processError)
      )
  }

  startStreamJobById(id: String) {
    return this.httpClient.post(StreamJobControllerService.url + "/job/stream/" + id, {})
      .pipe(
        retry(1),
        catchError(this.handleError)

        // catchError(this.processError)
      )
  }

  stopStreamJobById(id: string) {
    return this.httpClient.get(StreamJobControllerService.url + "/job/stream/" + id + "/stop")
      .pipe(
        retry(1),
        catchError(this.handleError)

        // catchError(this.processError)
      )
  }

  deleteStreamJobById(id: string) {
    return this.httpClient.delete(StreamJobControllerService.url + '/job/stream/' + id)

      .pipe(
        retry(1),
        catchError(this.handleError)

        // catchError(this.processError)
      )

  }

  deleteAllStreamJobs() {
    return this.httpClient.delete(StreamJobControllerService.url + '/job/stream')

      .pipe(
        retry(1),
        catchError(this.handleError)

        // catchError(this.processError)
      )

  }

  getStreamJobLog(id: string) {
    return this.httpClient.get(StreamJobControllerService.url + "/log/" + id)

      .pipe(
        retry(1),
        catchError(this.handleError)

        // catchError(this.processError)
      )


  }

  getStreamSqoopLog(id: string, data: any) {
    return this.httpClient.post(StreamJobControllerService.url + "/log/" + id + "/sqoop", data)

      .pipe(
        retry(1),
        catchError(this.handleError)

        // catchError(this.processError)
      )


  }

  startStreamJobWithBody(data: any) {

    return this.httpClient.post(StreamJobControllerService.url + '/job/stream/', data)

      .pipe(
        retry(1),
        catchError(this.handleError)

        // catchError(this.processError)
      )


  }



  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    Swal.fire({
      type: 'Failed',

      title: "Failed",
      text: error.error.message,
      confirmButtonText: 'Ok',
    })
    return throwError(errorMessage);
  }



}



import { Injectable } from '@angular/core';
import { Config } from '../../config';
import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

  static url = Config.url;


  constructor(private httpClient: HttpClient) { }

  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }



  getAllIngestionsCount() {
    return this.httpClient.get<any>(ProjectsService.url + "/project/ingestion/count")
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  getAllIngestionsCountByProjectName(projectName: string) {
    return this.httpClient.get<any>(ProjectsService.url + "/project/ingestion/count/" + projectName)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }
  getAllJobsCount() {
    return this.httpClient.get<any>(ProjectsService.url + "/project/job/count")
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  getAllJobsCountByProjectName(projectName: string) {
    return this.httpClient.get<any>(ProjectsService.url + "/project/job/count/" + projectName)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }
  getAllDataSourceCount() {
    return this.httpClient.get<any>(ProjectsService.url + "/project/source/count")
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  getAllDataSourceCountByProjectName(projectName: string) {
    return this.httpClient.get<any>(ProjectsService.url + "/project/source/count/" + projectName)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  getAllProjectsCount() {
    return this.httpClient.get<any>(ProjectsService.url + "/projects/count")
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }



  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    Swal.fire({
      type: 'Failed',

      title: "Failed",
      text: error.error.message,
      confirmButtonText: 'Ok',
    })
    return throwError(errorMessage);
  }



}


<div class="cm-content-blocks">
  <ol class="breadcrumb">
      <li><a routerLink="/home"><i class="fa fa-home"></i>Home</a></li>
      <li><a routerLink="/project/{{projectName}}/ingest"><i class="fa fa-filter mr-1"></i>Ingest</a></li>  
      <li  class="active"><i class="fa fa-eye mr-1"></i>View Details</li>
  </ol>
</div>
<div class="menublock">
    <section class="table-ec">
      <ul class="progressbar clearfix">
            
        <li class="tabli active pointerc" 
        [ngClass]="showDiv.stepone === true ? 'active':'' " 
        (click)="showDiv.stepone = true;
        showDiv.steptwo = false; "
        routerLink="/project/{{projectName}}/databaseViewDetails/{{Id}}"
        >
            <section class="tabbtn">
                <section class="clearfix relativepos">
                <aside class="absoultepos stipico">
                    <i class="fa fa-cog" aria-hidden="true"></i>
                </aside>
                <aside class="steptxtc">
                    <div class="steptitle tenpt">Configuration</div>
                </aside>
                </section>
            </section>
        </li>
      
        <li class="tabli pointerc"
        [ngClass]="showDiv.steptwo === true ?'active':'' "
        (click)="showDiv.stepone = false;
        showDiv.steptwo = true; "
        routerLink="/project/{{projectName}}/databaseViewExecution/{{Id}}"
        >
            <section class="tabbtn">
                <section class="clearafter relativepos">
                <aside class="absoultepos stipico">
                <i class="fa fa-tasks" aria-hidden="true"></i>
                </aside>
                <aside class="steptxtc">
                <div class="steptitle tenpt">Execution</div>
                </aside>
                </section>
            </section>
        </li>
        <li  class="tabli pointerc"
        [ngClass]="showDiv.steptwo === true ?'active':'' "
        (click)="showDiv.stepone = false;
        showDiv.steptwo = true; "
        routerLink="/project/{{projectName}}/databaseViewValidation/{{Id}}"
        >
            <section class="tabbtn">
                <section class="clearafter relativepos">
                <aside class="absoultepos stipico">
                <i class="fa fa-clipboard" aria-hidden="true"></i>
                </aside>
                <aside class="steptxtc">
                <div class="steptitle tenpt">Validation</div>
                </aside>
                </section>
            </section>
        </li>
    </ul>  
      <div class=" fifteenmb"  id="tableid">												

            <form [formGroup]="query" (ngSubmit)="postQuery()">

              <section class="inputc" >
                <aside class="col-md-6 fieldc" >
                  <label class="inputlabel">Select Database</label>
                  <mat-form-field appearance="fill">
                    <mat-label>Select database</mat-label>
                    <mat-select formControlName="database">
                      <mat-option *ngFor="let database of databases"  [value]="database.sourceDatabaseName" (click)="setDatabase(database.sourceDatabaseName)">
                        {{database.sourceDatabaseName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </aside>

                <!-- <aside class="col-md-6 fieldc"  *ngIf="query.value.database != ''"> -->
                  <aside class="col-md-6 fieldc" >
                  <label class="inputlabel">Select Table</label>
                  <mat-form-field appearance="fill" >
                    <mat-label>Select Table</mat-label>
                    <mat-select  formControlName="table">
                      <div *ngFor="let table of tables" >
                        <mat-option *ngIf="query.value.database == table.sourceDatabaseName" [value]="table.tables.sourceTableName" (click)="setTable(table.tables.sourceTableName)">
                        {{table.tables.sourceTableName}}
                        </mat-option>
                      </div>
                    </mat-select>
                  </mat-form-field>
                </aside>
              </section>

              <section class="inputc" >
                <!-- <aside class="col-md-6 fieldc"  *ngIf="query.value.table != ''"> -->
                  <aside class="col-md-6 fieldc">
                  <label class="inputlabel">Select Limit</label> 
                  <mat-form-field appearance="fill">
                    <mat-label>Limit</mat-label>
                    <mat-select  formControlName="limit">
                      <mat-option *ngFor="let number of limit"  [value]="number.limit">
                        {{number.limit}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </aside>
              
              </section>

                <div class="tenpt lightgraybdrt clearafter"> 
                   <button class="tertiary-active fr" click="submit" >Fetch rows</button>
                </div> 
             
            </form>

          
        
          <section class="fifteenpt">
            <h3 class="mt-4" *ngIf="tableInfo"><i class="fa fa-table mr-2"></i>{{tableInfo.tableName}}</h3>
            <h4 *ngIf="tableInfo">Row Count: {{tableInfo.rowCount}}</h4>
            <div class="tableWrap">
              <table class="table table-borderless mr-table" *ngIf="resultSet">
                <thead class="mr-table-head">
                  <tr class="mr-table-head-row">
                    <!-- <th>Run History</th> -->
                    <th *ngFor="let tableHead of resultSetKeys">{{tableHead.key}}</th>
                  </tr>
                </thead>
              <tbody>
                  <tr class="mr-table-body-row" *ngFor="let values of resultSetValues | paginate: { id: 'listing_pagination',
                  itemsPerPage: 10,
                  currentPage: page,
                  totalItems: resultSetValues?.length }; let i = index "  >
                  <!-- <td>{{checkforNull(ingestions.runHistory)}}</td> -->
                  <td   *ngFor="let key of values"   >
                    {{key}}
                  </td>
                  <!-- <td>
                    <div class="statustd">{{checkforNull(item.size)}} </div>
                  </td> -->
                
                </tr>
              
              </tbody>
            
            </table>
            
            </div>
            <!-- <pagination-controls id="listing_pagination" class="pagination" (directionLinks)="true"
            (pageChange)="page = $event" (autoHide)="true" (responsive)="true" previousLabel="" disabled="true"
            nextLabel="">
        </pagination-controls> -->
            <ngb-pagination class="pagination mt-1" [collectionSize]="query.value.limit" [(page)]="page" aria-label="Default pagination" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination> 
 
          </section>
         
        </div>
        
    
       <!-- <pagination-controls id="listing_pagination" class="pagination" directionLinks="true"
       (pageChange)="page = $event" autoHide="true" responsive="true" previousLabel="" disabled="true"
       nextLabel="">
      </pagination-controls> -->
    </section> 
  </div>
  <!--Error Modal-->
  <div class="modal" id="log">
    <div class="modal-dialog modal-lg">
      <div class="modal-content ">
      
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Log</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <!-- Modal body -->
        <div class="modal-body">
          {{logReport.log}}
        </div>
        
        <!-- Modal footer -->
        <footer class="modalfooter">
            <button type="button" class="done-btn modalpubtn" data-dismiss="modal">Close</button>
        </footer>
      </div>
    </div>
  </div>
  
  <!--Action Modal-->
  <div class="modal" id="actions">
    <div class="modal-dialog modal-lg">
      <div class="modal-content ">
      
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Sqoop Command</h4>
          <button type="button" class="close" data-dismiss="modal"></button>
        </div>
        <!-- Modal body -->
        <div class="modal-body">
          <div>
            {{sqoopCommand}}
          </div>
  
        <!-- Modal footer -->
        <footer class="modalfooter">
            <button type="button" class="done-btn modalpubtn" data-dismiss="modal">Close</button>
        </footer>
      </div>
    </div>
  </div>

import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomValidators } from 'src/app/validation/custom-validators';
import { StreamingdataSourceService } from 'src/app/services/api/data-source/streamingdata-source.service';
import { IngestionSharingServiceService } from 'src/app/ingestion-sharing-service.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-step-one-stream-data-source',
  templateUrl: './step-one-stream-data-source.component.html',
  styleUrls: ['./step-one-stream-data-source.component.scss']
})
export class StepOneStreamDataSourceComponent implements OnInit {

  showDiv = {
    stepone: true,
    steptwo: false,
    stepthree: false,
    stepfour: false,
    stepfive: false,

    stepbtnone: true,
    stepbtntwo: false,
    stepbtnthree: false,
    stepbtnfour: false,
    stepbtnfive: false,

    cancelbtn: false,
    nextbtn: true,
    nextbtntwo: false,
    nextbtnthree: false,
    nextbtnfour: false,

    backbtn: false,
    backbtntwo: false,
    backbtnthree: false,
    backbtnfour: false,
    backbtnfive: false,

    submitbtn: false
  }

  constructor(private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, private router: Router,
    private streamingDataSourceService: StreamingdataSourceService, private ingestionSharingService: IngestionSharingServiceService
  ) { }

  connectionSuccessful: boolean = false;
  KafkaForm = this.formBuilder.group({
    name: ["", Validators.required],
    description: [""],
    topic: ["", Validators.required],
    hostname: ["", Validators.required],
    port: [9092, [Validators.required, Validators.pattern("^[0-9]*$")]],

    // port: ["", Validators.required, Validators.pattern('[0-9]*')],
  })
  saveStreamDataSourceInformation(data: any) {
    this.ingestionSharingService.setStepOneStreamingDataSourceData(data.value);
    console.log("source");
    console.log(this.ingestionSharingService.getStepOneStreamingDataSourceData());
    console.log(data.value);
    this.router.navigate([`/project/${this.projectName}/data-sources/configureStreamDataSource/stepTwo`]);

  }
  testStream() {
    this.connectionSuccessful = false;
    this.streamingDataSourceService.testStreamDataSource(this.KafkaForm.value).subscribe(response => {
      let status: any = response;
      console.log(status);
      if (status.status.toLowerCase() == "connection successful") {
        this.connectionSuccessful = true;
        this.success(status.status);

      } else {
        this.warning(status.status);
      };
    })
  }
  projectName = this.activatedRoute.snapshot.params['id2'];

  success(status: string) {
    Swal.fire({
      type: 'Success',
      title: 'Success!',
      text: status,
      confirmButtonText: 'Ok',
    })
  }
  warning(status: string) {
    Swal.fire({
      type: 'Warning',
      title: 'Warning',
      text: status,
      confirmButtonText: 'Ok',
    })
  }

  ngOnInit(): void {
    if (this.ingestionSharingService.getStepOneStreamingDataSourceData() != null) {
      this.KafkaForm.patchValue({
        name: this.ingestionSharingService.getStepOneStreamingDataSourceData().name,
        description: this.ingestionSharingService.getStepOneStreamingDataSourceData().description,
        topic: this.ingestionSharingService.getStepOneStreamingDataSourceData().topic,
        hostname: this.ingestionSharingService.getStepOneStreamingDataSourceData().hostname,
        port: this.ingestionSharingService.getStepOneStreamingDataSourceData().port
      })
    }
  }

}






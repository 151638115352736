<div *ngIf="load"class="">
  <div class="loader"></div>
  <figure class="spinner-border loaderico"></figure>
</div>

<div class="cm-content-blocks">
  <ol class="breadcrumb">
      <li><a routerLink="/home"><i class="fa fa-home"></i>Home</a></li>
      <li class="active"><i class="fa fa-folder-open"></i>Projects</li>

  </ol>
</div>


<div class="clearafter headbtnc">
  <h3 class="headc"> Projects 
    <div class="subhead">Define new projects and maintain existing projects</div>
  </h3>
  <div class="create-btn-position headbtnc">
    <button class="mr-btn-create pointerc" data-toggle="modal" data-target="#projectModal" data-backdrop="static" data-keyboard="false">Create New Project</button>
  </div>
</div> 

<section class="clearafter">
  <aside class="zeroplimp float-left col-md-4">
    <div class="searchbarc fifteenpb">
      <input class="search-bar input-field"  type="text"  placeholder="Search by project name"  [(ngModel)]="term">
      <img class="search-bar-icon" src="../../../assets/images/search.svg">
    </div>
  </aside>

    <aside class="col-8 text-right clearafter d-flex justify-content-end">
      <div class="inline-block  tenmt">
        <span class=" fivemr">Sort by: </span>
        <label class="radioc">
            <input type="radio"  name="inlineRadioOptions" (click)="sortDescending(projects)" id="inlineRadio2" value="option2">
            <span class="checkmark-r"></span>
            <span class="text-r">Newest Projects</span>
        </label>
        <label class="radioc">
            <input type="radio" name="inlineRadioOptions" (click)="sortAscending(projects)" id="inlineRadio1" value="option1">
            <span class="checkmark-r"></span>
            <span class="text-r">Oldest Projects</span>
        </label>  
      </div>
    </aside>
</section>

<section class="table-ec">

    <div class=" fifteenmb"  id="tableid">		
      <div class="table-wrapper position-relative loader_top_adj">
        
        
      <table class="table table-borderless mr-table">
        <thead class="mr-table-head">
          <tr class="mr-table-head-row">
            <th class="descriptiontd">Name</th>
            <th>Description</th>
            <th class="nametd">Owner</th>
            <!-- <th class="nametd">Permitted Users</th> -->
            <th class="actiontwotd">Actions</th>
          </tr>
        </thead>
        <tbody *ngIf="projects.length ==0" >
          <tr>
            <td colspan=100% class="text-center">No projects have been created</td>
          </tr>          
        </tbody>
        <tbody *ngIf="projects.length>0" >
          <tr class="mr-table-body-row" 
          *ngFor="let project of projects | filter: term | paginate: { id: 'listing_pagination',
        itemsPerPage: 10,
        currentPage: page,
        totalItems: projects.length }; let i = index ">
            <td routerLink="{{project.name}}" (click)="saveCurrentProject(project.name)">
              <div class=" descriptiontd">{{project.name}}</div>
            </td>
            <td routerLink="{{project.name}}" (click)="saveCurrentProject(project.name)">
              <div class="break-all"> {{project.description}} </div>
            </td>
            <td routerLink="{{project.name}}" (click)="saveCurrentProject(project.name)">
              <div class="nametd">{{project.owner}}</div>
            </td>
            <!-- <td routerLink="{{project.name}}" (click)="saveCurrentProject(project.name)">
              <div class="nametd">{{displayPermittedUsers(project.permittedUsers)}} </div>
            </td>  -->
            <td>
              <div class="icons-container actiontwotd">
                <!-- <i  class="fa fa-eye pointerc" aria-hidden="true"  ngbTooltip="View Project" placement="left" routerLink="/project/{{project.name}}" routerLinkActive="active" (click)="saveCurrentProject(project.name)" ></i> -->
                <i  class="fa fa-pencil-square-o pointerc" aria-hidden="true" data-backdrop="static" data-keyboard="false"  ngbTooltip="Edit" placement="left" (click)="populateEditForm(project)" data-toggle="modal" data-target="#projectModalEdit"></i>
                <i  class="fa fa-trash pointerc" aria-hidden="true" ngbTooltip="Delete" placement="left" (click)="deleteProject(project.name)"></i>
              </div>
            </td>
          </tr>


        </tbody>
       
      </table>
      </div>
      <pagination-controls *ngIf="projects.length > 10" id="listing_pagination" class="pagination" (directionLinks)="true"
      (pageChange)="page = $event" (autoHide)="true" (responsive)="true" previousLabel="" disabled="true"
      nextLabel="">
  </pagination-controls>
      <!-- <ngb-pagination *ngIf="projects.length > 10" class="pagination float-right" [collectionSize]="projects.length" [(page)]="page" aria-label="Default pagination" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>  -->
 
    </div>
    
   <!-- <ngb-pagination class="pagination" [collectionSize]="70" [(page)]="page" aria-label="Default pagination"></ngb-pagination> -->

    
</section> 


<div class="modal" id="projectModal">
    <div class="modal-dialog">
      <div class="modal-content">
      
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Create New Project</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <!-- Modal body -->
        <form [formGroup]="projectForm" (ngSubmit)="postProject()">

        <div class="modal-body">

            <section class="inputc">
                <div class="col-md-12 fieldc pr-0"  [ngClass]="projectForm.controls['name'].touched && projectForm.controls['name'].errors?'inputc-error':''">
                    <label class="inputlabel label-asterisk"> Project name</label> 
                    <input name="name"  class="input-field" type="text"   formControlName="name" /> 
                    <span class="error-mge mt-2"
                    *ngIf="projectForm.controls['name'].invalid && (projectForm.controls['name'].dirty || projectForm.controls['name'].touched)">
                    Project Name is required.
                  </span>        
                </div>
              
            </section>
            <section class="inputc">
              
                <div class="col-md-12 fieldc pr-0" [ngClass]="projectForm.controls['description'].touched && projectForm.controls['description'].errors?'inputc-error':''">
                    <label class="inputlabel"> Project Description </label> 
                    <textarea  class="input-field" rows="4"  formControlName="description"></textarea>
                    
                </div>
        
            </section>
         
        
        <!-- Modal footer -->
       
        </div>
        <footer class="modalfooter">
          <button type="button" class="done-btn modalpubtn" data-dismiss="modal" (click)="clearprojectForm()" >Cancel</button>
          <button  class="done-btn modalpubtn" type="submit" [disabled]="projectForm.invalid">Save </button>
        </footer>
        </form>

      </div>
    </div>
</div>

<div class="modal" id="projectModalEdit">
  <div class="modal-dialog">
    <div class="modal-content">
    
      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title" >Edit Project</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="getAllPermittedProjects();" >&times;</button>
      </div>
      <!-- Modal body -->
      <form [formGroup]="projectFormEdit" (ngSubmit)="updateProject(projectFormEdit)">

      <div class="modal-body">

          <section class="inputc">
              <div class="col-md-12 fieldc"  >
                  <label class="inputlabel label-asterisk"> Project name</label> 
                  <input [attr.disabled]="true" class="input-field" type="text" formControlName="name"/> 
              </div>
            
          </section>
          <section class="inputc">
            
              <div class="col-md-12 fieldc" [ngClass]="projectFormEdit.controls['description'].touched && projectFormEdit.controls['description'].errors?'inputc-error':''">
                  <label class="inputlabel"> Project Description </label> 
                  <textarea  class="input-field" rows="4"  formControlName="description"></textarea>
                  
              </div>
      
          </section>

          <!-- <input  hidden formControlName="name"   class="input-field" type="text" placeholder="{{project.name}}" value="{{project.name}}" />   -->
       
      
      <!-- Modal footer -->
     
      </div>
      <footer class="modalfooter">
        <button type="button" class="done-btn modalpubtn" data-dismiss="modal" >Cancel</button>
        <button  class="done-btn modalpubtn" type="submit" [disabled]="projectFormEdit.invalid">Save </button>
      </footer>
      </form>

    </div>
  </div>
</div>
import { Component, NgModule, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JobControllerService } from '../../../../services/api/job/job-controller.service';
import { RdbmsIngestionControllerService } from '../../../../services/api/rdbms-controller/rdbms-ingestion-controller.service';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { forkJoin } from 'rxjs';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DataSourceControllerService } from '../../../../services/api/data-source/data-source-controller.service';
import { interval } from 'rxjs';
import { ProjectService } from 'src/app/services/project/project-service.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { StreamIngestionControllerService } from 'src/app/services/api/streamController/stream-ingestion-controller.service';

declare var $: any;

export interface RdbmsIngestionDetails {
  id: string;
  name: string;
  bulkImport: boolean;
  databases: [];
  createdAt: string;
  error: any;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit {
  toggleBulk: boolean;
  ingestions: any;
  progress: any = [];
  connections: any = [];

  routesArray: any[];
  ingestionsWithErrors: any = [];

  finishedIngestions: any = [];
  failedIngestions: any = [];
  inProgressIngestions: any = [];
  discardedIngestion: any = [];

  inProgressWithErrors: any = [];

  stoppedIngestions: any = [];
  term: string;
  failedProgress: any = [];
  ingestionDetails: any;
  listToggle: any = [];
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  showIcon: boolean;
  load = true;

  constructor(
    public rdbmsService: RdbmsIngestionControllerService,
    public streamService: StreamIngestionControllerService,
    public router: Router,
    private snackBar: MatSnackBar,
    public projectService: ProjectService,
    private dataSourceControllerService: DataSourceControllerService,
    private activatedRoute: ActivatedRoute
  ) {
    this.showIcon = false;
  }
  projectName = this.activatedRoute.snapshot.params['id2'];

  ngOnInit() {
    this.getAllIngestionInformation();
    setTimeout(() => (this.load = false), 1200);
  }

  sortAscending(data: any) {
    data.sort((a: any, b: any) => (a.createdAt > b.createdAt ? 1 : -1));
  }

  sortDescending(data: any) {
    data.sort((a: any, b: any) => (a.createdAt < b.createdAt ? 1 : -1));
  }

  //refresh page
  getAllConnections() {
    this.dataSourceControllerService
      .getRDBMSConnectors()
      .subscribe((response) => {
        this.connections = response;
      });
  }

  async getAllIngestionInformation() {
    this.ingestions = [];
    console.time();

    this.ingestions = await this.rdbmsService
      .getAllIngestionsByProjectName(this.projectService.getCurrentProject())
      .toPromise();
    this.progress = await this.rdbmsService
      .getAllIngestionJobs(this.ingestions)
      .toPromise();
    this.connections = await this.dataSourceControllerService
      .getRDBMSConnectors()
      .toPromise();

    this.ingestions.map((ingestion) => {
      this.connections.map((connection) => {
        if (ingestion.sourceId == connection.id) {
          this.ingestions[this.ingestions.indexOf(ingestion)].databaseType =
            this.connections[this.connections.indexOf(connection)].databaseType;
        }
      });
    });

    this.ingestions.map((ingestion) => {
      this.progress.map((progress) => {
        if (ingestion.id == progress.rdbmsIngestionId) {
          this.ingestions[this.ingestions.indexOf(ingestion)].progress =
            this.progress[this.progress.indexOf(progress)].progress;
          this.ingestions[this.ingestions.indexOf(ingestion)].status =
            this.progress[this.progress.indexOf(progress)].status;
          this.ingestions[this.ingestions.indexOf(ingestion)].finishedAt =
            this.progress[this.progress.indexOf(progress)].finishedAt;
          this.ingestions[this.ingestions.indexOf(ingestion)].sqoopJobId =
            this.progress[this.progress.indexOf(progress)].sqoopJobId;
          this.ingestions[this.ingestions.indexOf(ingestion)].estTimeLeft =
            this.progress[this.progress.indexOf(progress)].estTimeLeft;
          this.ingestions[this.ingestions.indexOf(ingestion)].duration =
            this.progress[this.progress.indexOf(progress)].duration;
        }
      });
    });

    const { databases, ...rest } = this.ingestions[0];
    console.log(JSON.stringify(rest));
    console.log(this.ingestions);
    this.finishedIngestions = this.ingestions.filter(
      (ingestion) => ingestion.status == 'FINISHED'
    );
    this.stoppedIngestions = this.ingestions.filter(
      (ingestion) => ingestion.status == 'STOPPED'
    );
    this.discardedIngestion = this.ingestions.filter(
      (ingestion) => ingestion.status == 'DISCARDED'
    );
    this.failedIngestions = this.ingestions.filter(
      (ingestion) =>
        ingestion.status == 'FAILED' || ingestion.status == 'FAILED_TO_LAUNCH'
    );
    this.inProgressIngestions = this.ingestions.filter(
      (ingestion) => ingestion.status == 'IN_PROGRESS'
    );
    this.inProgressWithErrors = this.ingestions.filter(
      (ingestion) => ingestion.status == 'IN_PROGRESS_WITH_ERRORS'
    );

    // this.ingestions.map(ingestion => {
    //   console.log(this.ingestions[this.ingestions.indexOf(ingestion)])
    //   if (this.ingestions[this.ingestions.indexOf(ingestion)]?.status == "Finished") {
    //     this.finishedIngestions.push(this.ingestions[this.ingestions.indexOf(ingestion)])
    //   } else if (this.ingestions[this.ingestions.indexOf(ingestion)]?.status == "Failed to Launch" ||

    //     this.ingestions[this.ingestions.indexOf(ingestion)]?.status == "Failed"
    //   ) {
    //     this.failedIngestions.push(this.ingestions[this.ingestions.indexOf(ingestion)])
    //   } else if (this.ingestions[this.ingestions.indexOf(ingestion)]?.status === undefined) {
    //     this.stoppedIngestions.push(this.ingestions[this.ingestions.indexOf(ingestion)])
    //   }

    //   else if (this.ingestions[this.ingestions.indexOf(ingestion)]?.status == "In Progress") {
    //     let array: any;

    //     this.inProgressIngestions.push(this.ingestions[this.ingestions.indexOf(ingestion)])
    //     // this.inProgressIngestions = array
    //   } else if (this.ingestions[this.ingestions.indexOf(ingestion)]?.status == "In Progress with Errors") {
    //     this.inProgressWithErrors.push(this.ingestions[this.ingestions.indexOf(ingestion)])
    //   } else if (this.ingestions[this.ingestions.indexOf(ingestion)]?.status == "stopped" || this.ingestions[this.ingestions.indexOf(ingestion)]?.status == "Stopped") {
    //     this.stoppedIngestions.push(this.ingestions[this.ingestions.indexOf(ingestion)])
    //   } else if (this.ingestions[this.ingestions.indexOf(ingestion)]?.status == "discarded") {
    //     this.discardedIngestion.push(this.ingestions[this.ingestions.indexOf(ingestion)])
    //   }
    // })
  }

  checkForNull(value: string): string {
    if (value == null) {
      return 'N/A';
    } else {
      return value;
    }
  }

  miliConvert(milliseconds: number) {
    if (milliseconds == undefined) {
      return 'NOT YET RAN';
    }
    {
      var minutes = milliseconds / 1000 / 60;
      var seconds = (milliseconds / 1000) % 60;
      var minutesRounded = Math.round(minutes);
      var secondsRounded = Math.round(seconds);
      var diff = minutesRounded + ' minutes, ' + secondsRounded + ' seconds';
      return diff;
    }
  }
  dateMaker(date: string): string {
    let dateformat = new Date(date);
    let dateArray = dateformat.toString().split(' ');
    return (
      dateArray[0] +
      ', ' +
      dateArray[1] +
      ' ' +
      dateArray[2] +
      ', ' +
      dateArray[3] +
      `
    ${dateArray[4]} `
    );
  }

  formatJobDate(date: string): string {
    console.log(date);
    if (date == undefined) {
      return 'NOT RUN YET';
    } else {
      let dateformat = new Date(date);
      let dateArray = dateformat.toString().split(' ');
      return (
        dateArray[0] +
        ', ' +
        dateArray[1] +
        ' ' +
        dateArray[2] +
        ', ' +
        dateArray[3] +
        `
      ${dateArray[4]} `
      );
    }
  }

  getIngestionType(type: string, bulkImport: boolean): string {
    if (type == "stream") {
      return "Stream Ingestion"
    }
    return this.bulkImport(bulkImport)
  }
  getIngestionTypeIcon(type: string, bulkImport: boolean): string {
    if (type == "stream") {
      return "fa fa-share-alt"
    }
    return this.bulkImportIcon(bulkImport)
  }

  bulkImport(bulkImport: boolean): string {
    if (bulkImport == true) {
      return 'Database Import ';
    } else {
      return 'Table Import';
    }
  }
  bulkImportIcon(bulkImport: boolean): string {
    if (bulkImport == true) {
      return 'fa fa-database ';
    } else {
      return 'fa fa-table';
    }
  }

  identifyDriver(driver: string): string {
    if (driver == 'mysql') {
      return '../../../assets/images/mysqllogo.svg';
    } else if (driver == 'oracle') {
      return '../../../assets/images/Oracle.svg';
    } else if (driver == 'sqlserver') {
      return '../../../assets/images/sqlserver.png';
    } else if (driver == 'db2') {
      return '../../../assets/images/ibmDb2.svg';
    } else if (driver == 'snowflake') {
      return '../../../assets/images/snowflake.svg';
    } else if (driver == 'redshift') {
      return '../../../assets/images/redshift.png';
    } else if (driver == 'postgresql') {
      return '../../../assets/images/postgresql.png';
    } else {
      return '../../';
    }
  }

  identifyDriverWithType(driver: string, type: string): string {
    if (type == "stream") {
      return "../../../assets/images/kafka.png"
    }
    return this.identifyDriver(driver);
  }

  returnStatus(driver: string): string {
    if (driver === undefined) {
      return 'STOPPED';
    } else if (driver.toLocaleLowerCase() == 'finished') {
      return driver.toUpperCase();
    } else if (driver.toLocaleLowerCase() == 'failed') {
      return driver.toUpperCase();
    } else if (driver.toLocaleLowerCase() == 'discarded') {
      return driver.toUpperCase();
    } else if (driver.toLocaleLowerCase() == 'in_progress') {
      return driver.toUpperCase();
    } else {
      return 'N/A';
    }
  }
  changeStatusColor(driver: string): string {
    if (driver === undefined) {
      return 'text-danger';
    }
    if (driver.toLocaleLowerCase() == 'finished') {
      return 'text-success';
    } else if (
      driver.toLocaleLowerCase() == 'failed' ||
      driver.toLocaleLowerCase() == 'failed_to_launch'
    ) {
      return 'text-danger';
    } else if (driver.toLocaleLowerCase() == 'discarded') {
      return 'text-warning';
    } else if (driver.toLocaleLowerCase() == 'in_progress') {
      return 'text-primary';
    } else if (driver.toLocaleLowerCase() == 'in_progress_with_errors') {
      return 'text-primary';
    } else {
      return 'text-dark';
    }
  }

  changeStatusIcon(driver: string): string {
    if (driver === undefined) {
      return 'times-circle text-danger';
    }
    if (driver.toLocaleLowerCase() == 'finished') {
      return 'check text-success';
    } else if (driver.toLocaleLowerCase() == 'failed') {
      return 'times-circle text-danger';
    } else if (driver.toLocaleLowerCase() == 'discarded') {
      return 'trash';
    } else {
      return 'spinner';
    }
  }

  returnEstimatedTime(value: number): any {
    let valueInSeconds = value * 0.001;
    return valueInSeconds + ' seconds';
  }
  carryState(id: string, sqoopJobId: string) {
    // this.router.navigate([`ingestionDetail/${id}/`], { queryParams: { data: sqoopJobId } });
    this.router.navigate(
      [`/project/${this.projectName}/databaseViewDetails/${id}/`],
      { queryParams: { data: sqoopJobId } }
    );
  }

  deleteIngestion(id: string) {
    Swal.fire({
      type: 'warning',

      title: 'Are you sure you want to delete this Ingestion?',
      text: 'All information associated to this ingestion will be permanently deleted',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed == true) {
        this.rdbmsService.deleteRdbmsIngestion(id).subscribe((res) => {
          this.failedIngestions = [];
          this.stoppedIngestions = [];
          this.finishedIngestions = [];
          this.inProgressIngestions = [];
          this.inProgressWithErrors = [];
          this.getAllIngestionInformation();
          Swal.fire('Ingestion deleted!');
        });
      }
    });
  }

  getAllIngestions() {
    return this.rdbmsService.getAllIngestions().subscribe((res) => {});
  }

  openSnackBar(status: string, statusLogo: string) {
    this.snackBar.open(status, statusLogo, {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['tertiary-active'],
    });
  }
  success(status: string) {
    Swal.fire({
      type: 'Success',

      title: 'Success!',
      text: status,
      confirmButtonText: 'Ok',
    });
  }

  ngAfterViewInit() {
    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $('.pagec').css('height', contenth);
      $('.sidebar-wrapper').css('height', sidebarh);
    });

    /* box mouse over */
    $('body').on('mouseover', '.db_boxc', function () {
      $(this).find('.icon_c').addClass('show');
    });
    /* box mouse out */
    $('body').on('mouseout', '.db_boxc', function () {
      $(this).find('.icon_c').removeClass('show');
    });
  }
}

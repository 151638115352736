import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ProjectControllerService } from 'src/app/services/project/project-controller.service';
import { ProjectService } from 'src/app/services/project/project-service.service';
import { UserService } from '../../../services/users/user.service'
import * as $ from 'jquery';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import Swal from 'sweetalert2/dist/sweetalert2.js';


declare var $: any;

@Component({
  selector: 'app-access-landing-page',
  templateUrl: './access-landing-page.component.html',
  styleUrls: ['./access-landing-page.component.scss']
})
export class AccessLandingPageComponent implements OnInit, AfterViewInit {

  constructor(public UserService: UserService, public projectService: ProjectService, public projectControllerService: ProjectControllerService,
    private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder, private snackBar: MatSnackBar) { }

  userList: any;
  permittedUserList: any;
  user: any
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  load: boolean = true;
  page = 1;

  projectName = this.activatedRoute.snapshot.params['id2'];

  permissions: any[] = [
    { perm: "VIEWER" },
    { perm: "OPERATOR" },
    { perm: "MAINTAINER" }
  ];

  assignUser = this.formBuilder.group({
    username: ["", Validators.required],
    permission: ["", Validators.required]
  })

  ngAfterViewInit() {
    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);
    });
  }

  assignUserPermission(assignUser) {
    this.projectControllerService.assignUser(this.projectName, assignUser.value.username, assignUser.value.permission).subscribe(response => {
      this.getAllUsers()
      this.getAllPermittedUsers()
      this.success("Permission assigned")
      $('#access').modal().hide();
    })
  }

  deleteUser(username: string) {
    Swal.fire({
      type: 'warning',

      title: "Are you sure you want to remove access for this user?",
      text: "This user won't be able to access this project once removed",
      showCancelButton: true, confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      if (result.isConfirmed == true) {

        this.projectControllerService.deleteUserAssignment(this.projectName, username).subscribe(response => {
          this.getAllUsers()
          this.getAllPermittedUsers()
        })

        Swal.fire("User removed")
      }
    })
  }


  getAllPermittedUsers() {
    this.projectControllerService.getProject(this.projectName).subscribe(projectResponse => {
      this.permittedUserList = []
      for (var user in projectResponse.permittedUsers) {
        this.UserService.getUserByUserName(projectResponse.permittedUsers[user]).subscribe(userResponse => {
          delete userResponse.password
          for (var perm in userResponse.role.permissions) {
            console.log(userResponse.role.name)
            if (userResponse.role.name === "ROLE_ADMIN") {
              console.log("Conditional Worked")
              userResponse.currProjectPermission = 'PERM_ADMIN/OWNER'
            }
            if (userResponse.role.permissions[perm].projectName == this.projectName) {
              userResponse.currProjectPermission = userResponse.role.permissions[perm].permission
            }
            console.log(userResponse)
          }
          this.permittedUserList.push(userResponse)

        })
      }
    })
  }

  getAllUsers() {
    this.load = true;

    this.projectControllerService.getProject(this.projectName).subscribe(projectResponse => {
      this.userList = []

      this.UserService.getAllUsers().subscribe(userResponse => {
        for (var resUser in userResponse) {
          console.log(userResponse[resUser].username)

          if (!projectResponse.permittedUsers.includes(userResponse[resUser].username) && userResponse[resUser].role.name !== "ROLE_ADMIN") {
            this.userList.push(userResponse[resUser])
          }
        }
      })
      console.log(this.userList)
      this.load = false;

    })
  }

  updateUserAccess(data: any, projectName: string, user: string, permission: string) {
    this.projectControllerService.editUserAssignment(projectName, user, permission).subscribe(res => {
      console.log(res)
      this.getAllPermittedUsers()
    })

  }

  openSnackBar(status: string, statusLogo: string) {
    this.snackBar.open(status, statusLogo, {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['tertiary-active']

    });
  }

  success(status: string) {
    Swal.fire({
      type: 'Success',

      title: 'Success!',
      text: status,
      confirmButtonText: 'Ok',
    })
  }

  ngOnInit() {
    this.getAllPermittedUsers();
    this.getAllUsers();
  }

}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

    constructor() {}

    saveCurrentProject(projectName: string) {
        localStorage.setItem("currentProject", projectName)
    }

    getCurrentProject() : string {
        return localStorage.getItem("currentProject");
    }

    clearCurrentProject() {
        localStorage.setItem("currentProject", null)
    }

}
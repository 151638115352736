<!-- <div *ngIf="load" class="">
    <div class="loader"></div>
    <figure class="spinner-border loaderico"></figure>
</div> -->
<div class="cm-content-blocks">
    <ol class="breadcrumb">
        <li><a routerLink="/project/{{projectName}}"><i class="fa fa-home"></i>Home</a></li>
        <li><a routerLink="/project"><i class="fa fa-folder-open"></i>Projects</a></li>
        <li><a routerLink="/project/{{projectName}}"><i class="fa fa-folder"></i>{{projectName}}</a></li>
        <li><a routerLink="/project/{{projectName}}/data-sources"><i class="fa fa-database"></i>Data Sources</a></li>
        <li  class="active"><i class="fa fa-wrench mr-1"></i>Configure Streaming</li>  
    </ol>
  </div>
  
  <div class="clearafter headbtnc">
    <h3 class="headc">
        Streaming Configuration
        <div class="subhead">Configure streaming databases</div>
      </h3>
    <div class="create-btn-position headbtnc">
        <button class="mr-btn-back pull-right pointerc"  routerLink="/project/{{[projectName]}}/data-sources">Back to Data Sources</button>
    </div>
  </div>
  
  <div class="menublock">
    <!-- steps -->
    <div class="progresslic clearafter " >
        <div class="fifteen_pb pb-0">
            <ul class="progressbar clearfix">
              
                <li class="tabli active pointerc" 
                [ngClass]="showDiv.stepone === true ? 'active':'disabled' " 
                
                >
                <section class="tabbtn">
                    <section class="clearfix relativepos">
                    <aside class="absoultepos stipico">
                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                    </aside>
                    <aside class="steptxtc">
                        <div class="steptitle">Kafka Config</div>
                        <!-- <div class="stepdes mt-1">Select data source for ingestion</div>  -->
                    </aside>
                    </section>
                </section>
                </li>
              
                <li class="tabli pointerc"
                [ngClass]="showDiv.steptwo === true ?'active':'disabled' "
               
                >
                    <section class="tabbtn">
                        <section class="clearafter relativepos">
                        <aside class="absoultepos stipico">
                        <i class="fa fa-database" aria-hidden="true"></i>
                        </aside>
                        <aside class="steptxtc">
                        <div class="steptitle">Message Details</div>
                         <div class="stepdes">Configure incoming message details</div>
                        </aside>
                        </section>
                    </section>
                </li>        
                
        
                <!-- <li class="tabli pointerc"
                [ngClass]="showDiv.stepfive === true ?'active':'disabled' " 
                (click)="showDiv.stepone = false;
                showDiv.steptwo = false; 
                showDiv.stepthree = false;
                showDiv.stepfour = false;
                showDiv.stepfive = true;
  
                showDiv.backbtn = false;
                showDiv.backbtntwo = false;  
                showDiv.backbtnthree = false;  
                showDiv.backbtnfour = false;  
                showDiv.backbtnfive = true;  
   
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false;
                showDiv.nextbtntwo = false; 
                showDiv.nextbtnthree = false; 
                showDiv.nextbtnfour = false; 
                showDiv.cancelbtn = false"
                >
                    <section class="tabbtn">
                        <section class="clearfix relativepos">
                        <aside class="absoultepos stipico">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                        </aside>
                        <aside class="steptxtc">
                            <div class="steptitle">Advanced Configuration</div>
                            <div class="stepdes">Description</div>
                        </aside>
                        </section>
                    </section>
                </li>
                 -->
                
                
            </ul>
          </div>
          <!-- tabe content-->
   
          <section class="tabc_config" >
            <!-- page content -->
            <div class="stepone_c" *ngIf="showDiv.stepone">
                <div class="subtitle"></div>
                <div class="fifteenmb">
                    <section >
  
                    <form [formGroup]="KafkaForm"  (ngSubmit)="saveStreamDataSourceInformation(KafkaForm)">
                        <section class="inputc" >
                            <aside class="col-md-6 fieldc">
                                <label class="inputlabel label-asterisk">Data source Name </label>
                                <input class="input-field" formControlName="name" type="text"   name="name"  required>
                                <small style="color: #a94442;"
                                *ngIf="KafkaForm.controls['name'].invalid && (KafkaForm.controls['name'].dirty || KafkaForm.controls['name'].touched)">
                                Data Source Name is required.
                            </small>
                            </aside>
                            <aside class="col-md-6 fieldc">
                                <label class="inputlabel">Data Source Description </label>
                                <textarea class="input-field" rows="4" formControlName="description" type="text"   name="description" ></textarea> 
                
                               
                            </aside>        
                          
                          
                        </section>
                     
                        
                        <section class="inputc">
                        
                            <div class="col-md-6 fieldc"
                            [ngClass]="KafkaForm.controls['hostname'].touched && KafkaForm.controls['hostname'].errors?'inputc-error':''"  
                            >
                                <div class="poosition-relative">
                                    <label class="inputlabel label-asterisk"> Hostname </label> 
                                    <input name="name"  class="input-field" type="text"   formControlName="hostname"    required />    
                                    <span class="error-mge four_minus_bottom"
                                        *ngIf="KafkaForm.controls['hostname'].invalid && (KafkaForm.controls['hostname'].dirty || KafkaForm.controls['hostname'].touched)">
                                        Hostname is required.
                                    </span>  
                                </div>
                            </div>
                            <div class="col-md-6 fieldc"
                            [ngClass]="KafkaForm.controls['port'].touched && KafkaForm.controls['port'].errors?'inputc-error':''"  
                            >
                            <label class="inputlabel"> Port</label> 
                                <input name="name" formControlName="port"  class="input-field" type="text" /> 
                            <span class="error-mge four_minus_bottom"
                            *ngIf="KafkaForm.controls['port'].invalid && (KafkaForm.controls['port'].dirty || KafkaForm.controls['port'].touched)">
                            Invalid port number
                            </span> 
                            <!-- <div class="error-mge mt-2" *ngIf="KafkaForm.controls['port'].errors && KafkaForm.controls['port'].errors.required">
                                Port is required
                            </div>
                            <div class="error-mge mt-2" *ngIf="KafkaForm.controls['port'].errors && KafkaForm.controls['port'].errors.pattern">
                                Invalid port number
                            </div> -->
                            </div>
                        </section>
                        <section class="inputc">
                            <div class="col-md-6 fieldc"
                            [ngClass]="KafkaForm.controls['topic'].touched && KafkaForm.controls['topic'].errors?'inputc-error':''"  
                            >
                                <div class="poosition-relative">
                                    <label class="inputlabel label-asterisk"> Topic </label> 
                                    <input name="name"  class="input-field" type="text"   formControlName="topic"  required />    
                                    <span class="error-mge four_minus_bottom"
                                        *ngIf="KafkaForm.controls['topic'].invalid && (KafkaForm.controls['topic'].dirty || KafkaForm.controls['topic'].touched)">
                                        Topic is required.
                                    </span>  
                                </div>
                            </div>
                        </section>
                     
                        <div class="graybdrt fifteen_pt fifteen_pb clearafter">

                        <button  class="tertiary-active fr" type="submit" [disabled]="KafkaForm.invalid || connectionSuccessful == false">
                            Next          
                        </button>  
                        <button  class="tertiary-active fr" type="button"  (click)="testStream()" [disabled]="KafkaForm.invalid">
                            Test Connection         
                        </button>  
                        </div>
                        <!-- <button *ngIf="IngestionService.getStepOneData() != null" class="tertiary-active btnnext tenmt float-right" type="submit" >
                            Next          
                        </button>   -->
  
                    </form>
                    </section>
  
                </div>
            </div>
      
      
        
          
            <!-- footer-->
            <footer class="tenpt lightgraybdrt clearafter footerbtns">
                
          
                <!-- <button class="tertiary-active fr btnnext" id="btnnext" *ngIf="showDiv.nextbtnfour"
                (click)="showDiv.stepone = false; 
                showDiv.steptwo =false; 
                showDiv.stepthree = false;
                showDiv.stepfour= false;
                showDiv.stepfive = true;
  
                showDiv.backbtn = false; 
                showDiv.backbtntwo = false;
                showDiv.backbtnthree = false; 
                showDiv.backbtnfour = true; 
                showDiv.backbtnfive = false;
  
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false; 
                showDiv.nextbtntwo = false; 
                showDiv.nextbtnthree = false; 
                showDiv.nextbtnfour = false;
  
                showDiv.cancelbtn = false;">
                Next <i aria-hidden="true" class="fa fa-arrow-right"></i>
                </button>
                
     -->
                <button class="grey-btn fr btnpre"  *ngIf="showDiv.cancelbtn">
                Cancel
                </button>
    
                <button class="tertiary-active fr btnnext" *ngIf="showDiv.submitbtn">
                Submit
                </button>
    
                <button class="grey-btn fr btnpre"  *ngIf="showDiv.backbtn"
                (click)="showDiv.stepone = true; 
                showDiv.steptwo = false; 
                showDiv.stepthree = false;
                showDiv.stepfour = false;
                showDiv.stepfive = false;
  
                showDiv.backbtn = false; 
                showDiv.backbtntwo = false;
                showDiv.backbtnthree = false; 
                showDiv.backbtnfour= false;
                showDiv.backbtnfive = false;
  
                showDiv.submitbtn = false; 
                showDiv.nextbtn = true; 
                showDiv.nextbtntwo = false; 
                showDiv.nextbtnthree = false; 
                showDiv.nextbtnfour = false; 
  
                showDiv.cancelbtn = false;">
                    <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
                </button>
                <button class="grey-btn fr btnpre"  *ngIf="showDiv.backbtntwo"
                (click)="showDiv.stepone = false; 
                showDiv.steptwo = true; 
                showDiv.stepthree = false;
                showDiv.stepfour = false;
                showDiv.stepfive = false;
  
                showDiv.backbtn = true; 
                showDiv.backbtntwo = false;
                showDiv.backbtnthree = false;
                showDiv.backbtnfour = false;
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false; 
                showDiv.nextbtntwo = true; 
                showDiv.nextbtnthree = false; 
                showDiv.nextbtnfour = false; 
  
                showDiv.cancelbtn = false;">
                    <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
                </button>
                <button class="grey-btn fr btnpre"  *ngIf="showDiv.backbtnthree"
                (click)="showDiv.stepone = false; 
                showDiv.steptwo = false; 
                showDiv.stepthree = true;
                showDiv.stepfour = false;
                showDiv.stepfive = false;
  
                showDiv.backbtn = false; 
                showDiv.backbtntwo = true;
                showDiv.backbtnthree = false; 
                showDiv.backbtnfour = false; 
                showDiv.backbtnfive = false; 
  
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false; 
                showDiv.nextbtntwo = false; 
                showDiv.nextbtnthree = true; 
                showDiv.nextbtnfour = false; 
                showDiv.cancelbtn = false;">
                    <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
                </button>
                <!-- <button class="grey-btn fr btnpre"  *ngIf="showDiv.backbtnfour"
                (click)="showDiv.stepone = false; 
                showDiv.steptwo = false; 
                showDiv.stepthree = false;
                showDiv.stepfour = true;
                showDiv.stepfive = false;
  
                showDiv.backbtn = false; 
                showDiv.backbtntwo = false;
                showDiv.backbtnthree = true; 
                showDiv.backbtnfour = false; 
                showDiv.backbtnfive = false; 
  
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false; 
                showDiv.nextbtntwo = false; 
                showDiv.nextbtnthree = false; 
                showDiv.nextbtnfour = true; 
                showDiv.cancelbtn = false;">
                    <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
                </button> -->
                <!-- <button class="grey-btn fr btnpre"  *ngIf="showDiv.backbtnfive"
                (click)="showDiv.stepone = false; 
                showDiv.steptwo = false; 
                showDiv.stepthree = false;
                showDiv.stepfour = false;
                showDiv.stepfive = true;
  
                showDiv.backbtn = true; 
                showDiv.backbtntwo = false;
                showDiv.backbtnthree = false; 
                showDiv.backbtnfour = true; 
                showDiv.backbtnfive = false; 
  
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false; 
                showDiv.nextbtntwo = false; 
                showDiv.nextbtnthree = false; 
                showDiv.nextbtnfour = false; 
                showDiv.cancelbtn = false;">
                    <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
                </button>
               -->
            </footer>
          </section> 
      
      </div>
      
    </div>
  
    <div class="modal" id="largeModal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content ">
        
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">Large Modal</h4>
            <button type="button" class="close" data-dismiss="modal">&times;</button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
              Modal Body Content
          </div>
          
          <!-- Modal footer -->
          <footer class="modalfooter">
              <button  class="done-btn modalpubtn">Next </button>
              <button type="button" class="done-btn modalpubtn" data-dismiss="modal">Cancel</button>
          </footer>
        </div>
      </div>
  </div>
  
  






<div *ngIf="load" class="">
  <div class="loader"></div>
  <figure class="spinner-border loaderico"></figure>
</div>
<div class="cm-content-blocks">
  <ol class="breadcrumb">
      <li><a routerLink="/home"><i class="fa fa-home"></i>Home</a></li>
      <li class="active"><i class="fa fa-user fa_topico user_management_ico mr-4"></i>User Management</li>
  </ol>
</div>

<div class="clearafter headbtnc">
  <h3 class="headc"> User Management 
    <div class="subhead">Create, delete, and edit users</div>
  </h3>
  <div class="create-btn-position headbtnc">
    <button class="mr-btn-create pointerc" data-toggle="modal" data-target="#newUserModal" data-backdrop="static" data-keyboard="false">Create New User</button>
  </div>
</div>   
    
    
    
    
    <section class="table-ec">
        <div class=" fifteenmb"  id="tableid">												
          <table class="table table-borderless mr-table">
            <thead class="mr-table-head">
              <tr class="mr-table-head-row">
                <th>Display Name</th>
                <th>User Email</th>
                <th>Admin</th>
                <th>Status</th>
                <th class="actionthreetd">Actions</th>
              </tr>
            </thead>
            <tbody  *ngIf="userList.length == 0" >
              <tr>
                <td colspan=100% class="text-center">No users have been created</td>
              </tr>          
            </tbody>
            <tbody *ngIf="userList.length>0">
              <tr class="mr-table-body-row" *ngFor="let item of userList | paginate: { id: 'listing_pagination',
              itemsPerPage: 10,
              currentPage: page,
              totalItems: userList.length }; let i = index ">
                <td>{{item.username}}</td>
                <td>{{item.email}}</td>
                <td>{{roleChecker(item.role.name)}}</td>
                <td ><span [class.success-s]="item.enabled == true" [class.danger-s]="item.enabled  == false">{{returnStatus(item.enabled)}}</span></td> 
                <td>
                  <div class="icons-container actionthreetd">
                    <i  class="fa fa-pencil-square-o pointerc ml-n3" aria-hidden="true"  ngbTooltip="Edit" placement="left"
                      data-toggle="modal" data-target="#editUserModal" (click)="getUserByUserName(item.username)">
                    </i>
                    <i  class="fa fa-lock pointerc" aria-hidden="true" ngbTooltip="Change Password" placement="left"
                      data-toggle="modal" data-target="#changePasswordModal" (click)="getUserByUserName(item.username)">
                    </i>
                   <i class="fa fa-check" *ngIf="item.enabled == false"  ngbTooltip="Enable User" (click)="enableUser(item)" ></i>		
                    <i class="fa fa-ban" *ngIf="item.enabled == true"  ngbTooltip="Disable User" (click)="disableUser(item)" ></i>	
                   
                     
                    <!-- later release, check with backend down the road-->
                    <!-- <i  class="fa fa-ban pointerc" aria-hidden="true" ngbTooltip="Disable" placement="left"></i> -->
                    <i class="fa fa-trash pointerc" aria-hidden="true" ngbTooltip="Delete" placement="left"  (click)="deleteUser(item.username)"></i>
                  </div>
                </td>
              </tr>
            </tbody>
           
          </table>
          <pagination-controls *ngIf="userList.length > 10" id="listing_pagination" class="pagination" (directionLinks)="true"
          (pageChange)="page = $event" (autoHide)="true" (responsive)="true" previousLabel="" disabled="true"
          nextLabel="">
      </pagination-controls>
          <!-- <ngb-pagination *ngIf="userList.length > 10" class="pagination float-right" [collectionSize]="userList.length" [(page)]="page" aria-label="Default pagination" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>  -->

         
        </div>
        
       <!-- <ngb-pagination class="pagination" [collectionSize]="70" [(page)]="page" aria-label="Default pagination"></ngb-pagination> -->
    
        
    </section> 


<!--Modals-->
<!-- New User Modal -->
<div class="modal" id="newUserModal">
  <div class="modal-dialog modal-lg">
    <div class="modal-content ">
    
      <!-- Modal Header -->
      <div class="modal-header">
        <h2 class="modal-title">Add New User</h2>
        <button type="button" class="close" data-dismiss="modal" (click)="newUser.reset()">&times;</button>
      </div>
      <!-- Modal body -->
      <form [formGroup]="newUser" (ngSubmit)="createUser(newUser)">

        <div class="modal-body">
          <!-- <div>newUser.valid= {{ newUser.valid }}</div>
          <div>newUser.invalid = {{ newUser.invalid }}</div>
          <div>newUser.status = {{ newUser.status }}</div>
          <div>newUser.disabled = {{ newUser.disabled }}</div>  -->
       
          <section class="inputc">
            
              <div class="col-md-6 fieldc"  [ngClass]="newUser.controls['email'].touched && newUser.controls['email'].errors?'inputc-error':''">
                <div class="position-relative">  
                  <label class="inputlabel label-asterisk">Email ID</label> 
                  <input formControlName="email" name="email"  class="input-field" type="text"/> 
                  <span class="error-mge zeroleftimp"
                  *ngIf="newUser.controls['email'].invalid && (newUser.controls['email'].dirty || newUser.controls['email'].touched)">
                  Email ID is required.
                  </span>
                </div>
              </div>
              
              <div class="col-md-6 fieldc" >
              <div class="form-group " formGroupName="role" >
                <div>
                  <label class="inputlabel label-asterisk">Roles</label>               
                  <select style="width: 370px" formControlName="name" name="name" class="form-control"  type="text" placeholder="">
                    <option value="ROLE_ADMIN">ROLE_ADMIN</option>
                    <option value="ROLE_USER">ROLE_USER</option>
                </select> 
                </div>

              </div>
              </div>
          </section>
          <section class="inputc">
            <div class="col-md-6 fieldc"  [ngClass]="newUser.controls['username'].touched && newUser.controls['username'].errors?'inputc-error':''">
              <div class="position-relative">
                <label class="inputlabel label-asterisk">User Name</label> 
                <input formControlName="username" name="username"  class="input-field" type="text"/>  
                <span class="error-mge zeroleftimp"
                *ngIf="newUser.controls['username'].invalid && (newUser.controls['username'].dirty || newUser.controls['username'].touched)">
                User Name is required.
                </span>  
                <!-- <span class="error-mge zeroleftimp"
                *ngIf="newUser.controls['username'].errors?.forbiddenUsernameValidator">
                 {{newUser.controls['username'].errors?.forbiddenUsernameValidator.value}}
                </span>   -->
              </div> 

            </div>
            
            <div class="col-md-6 fieldc"
          [ngClass]="newUser.controls['password'].hasError('required') ||
          newUser.controls['password'].hasError('hasNumber') ||
          newUser.controls['password'].hasError('hasCapitalCase') ||
          newUser.controls['password'].hasError('hasSmallCase')   ||
          newUser.controls['password'].hasError('hasSpecialCharacters')   ||
          newUser.controls['password'].hasError('minlength')  ? '':''">
                <div class="position-relative">
                  <div class="input-group mb-3">

                  <label class="inputlabel label-asterisk">Password </label> 
                  <input formControlName="password" name="password" class="form-control input-field" type="text" [type]="hide ? 'password':'text'" > 
                  <span class="input-group-text bg-light" id="basic-addon2">    
                    <i class="{{hide?'fa fa-eye-slash':'fa fa-eye'}}" (click)="hide = !hide"></i>
                    </span>
                  </div>

                    <small  class=" zeroleftimp text-danger"
                    *ngIf="newUser.controls['password'].invalid && (newUser.controls['password'].dirty || newUser.controls['password'].touched) &&(newUser.controls['password'].hasError('required') ||  newUser.controls['password'].hasError('hasNumber') || newUser.controls['password'].hasError('hasCapitalCase') ||
                    newUser.controls['password'].hasError('hasSpecialCharacters') || newUser.controls['password'].hasError('hasSmallCase') ||
                    newUser.controls['password'].hasError('minlength'))">
                    Enter a valid password
                </small> 
                <small>
                  Password should be atleast 8 characters long and should contain one number, one uppercase character, one lowercase character, and one special character

                </small>
                    <!-- <small 
                    *ngIf="newUser.controls['password'].hasError('hasCapitalCase')">
                      Password must contain 1 capital character
                    </small>
                    <small 
                    *ngIf="newUser.controls['password'].hasError('hasSpecialCharacters')">
                      Password must contain 1 special character
                    </small>
                    <small 
                    *ngIf="newUser.controls['password'].hasError('hasSmallCase')">
                      Password must contain 1 lowercase character
                    </small>
                  <small 
                  *ngIf="newUser.controls['password'].hasError('minlength')">
                    Password must be 8 characters long
                  </small> -->
                  
                  <!-- <span class="error-mge zeroleftimp"
                    *ngIf="newUser.controls['password'].invalid && (newUser.controls['password'].dirty || newUser.controls['password'].touched);">
                    Password is required.
                  </span> 
                  <span class="fifteenpt fontsml gray-text zerombimp"
                  *ngIf="newUser.controls['password'].errors?.pattern;"
                  > Password should be atleast 8 characters long and should contain one
                      number, one uppercase character, one lowercase character and one special character.
                  </span>   -->
                </div> 
              
    
            </div>
            
        </section>
        <section class="inputc">
          <div class="col-md-6 fieldc"  [ngClass]="newUser.controls['enabled'].touched && newUser.controls['enabled'].errors?'inputc-error':''">
            <div class="position-relative">
              <label class="inputlabel label-asterisk">Status</label> 
              <label class="radioc">
                <input type="radio" name="inlineRadioOptions" formControlName="enabled" value="true">
                <span class="checkmark-r"></span>
                <span class="text-r">Enable</span>
            </label>
            <label class="radioc">
              <input type="radio"  name="inlineRadioOptions" formControlName="enabled" value="false">
              <span class="checkmark-r"></span>
              <span class="text-r">Disable</span>
            </label>              
            <span class="error-mge zeroleftimp"
              *ngIf="newUser.controls['enabled'].invalid && (newUser.controls['enabled'].dirty || newUser.controls['enabled'].touched)">
              Status is required.
              </span>  
              <!-- <span class="error-mge zeroleftimp"
              *ngIf="newUser.controls['username'].errors?.forbiddenUsernameValidator">
               {{newUser.controls['username'].errors?.forbiddenUsernameValidator.value}}
              </span>   -->
            </div> 

          </div>
        </section>
      </div>

       <!-- Modal footer -->
       <footer class="modalfooter">
        <button type="button" class="done-btn modalpubtn" data-dismiss="modal" (click)="newUser.reset()">Cancel</button>
        <button  class="done-btn modalpubtn" click="submit" [disabled]="newUser.invalid">Save </button>

    </footer>

      </form>
    </div>
  </div>
</div>


<!-- Edit Modal -->
<div class="modal" id="editUserModal">
    <div class="modal-dialog  modal-lg">
      <div class="modal-content ">
      
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Edit User</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <!-- Modal body -->
        <form [formGroup]="userModalForEdit" (ngSubmit)="editUser(userModalForEdit)" >

        <div class="modal-body">
          
            <section class="inputc">
                <!-- <div class="col-md-6 fieldc" >
                    <label class="inputlabel">UserId</label>  -->
                <!-- </div> -->
                <div class="col-md-6 fieldc" >
                  <label class="inputlabel label-asterisk">User Name</label> 
                  <input  disabled  class="input-field" type="text"  value="{{userModalForEdit.value.username}}"
                  />         
              </div>
             
                <div class="col-md-6 fieldc" [ngClass]="userModalForEdit.controls['email'].touched && userModalForEdit.controls['email'].errors?'inputc-error':''">
                  <label class="inputlabel label-asterisk">Email </label> 
                  <input   class="input-field" type="text" formControlName="email" value="{{userModalForEdit.value.email}}" /> 
                  <!-- <span class="error-mge mt-2"
                  *ngIf="userModalForEdit.controls['email'].invalid && (userModalForEdit.controls['email'].dirty || userModalForEdit.controls['email'].touched)">
                  Email is required.
                  </span>          -->
              </div>
              
               
            </section>
            <section class="inputc">
              <div class="col-md-6 fieldc" 
              [ngClass]="userModalForEdit.controls['password'].hasError('required') ||
              userModalForEdit.controls['password'].hasError('hasNumber') ||
              userModalForEdit.controls['password'].hasError('hasCapitalCase') ||
              userModalForEdit.controls['password'].hasError('hasSmallCase')   ||
              userModalForEdit.controls['password'].hasError('minlength')  ? '':''">
              <div class="input-group mb-3">
                <label class="inputlabel label-asterisk">Password</label> 
                <input   class="form-control input-field" type="text" formControlName="password" value="{{userModalForEdit.value.password}}" [type]="hide ? 'password':'text'" />         
                <div class="input-group-append">
                  <span class="input-group-text bg-light" id="basic-addon2">    
                      <i class="{{hide?'fa fa-eye-slash':'fa fa-eye'}}" (click)="hide = !hide"></i>
                  </span>
                </div>
              </div>

                
                <!-- <span class="error-mge mt-2"
                *ngIf="userModalForEdit.controls['password'].invalid && (userModalForEdit.controls['password'].dirty || userModalForEdit.controls['password'].touched)">
                User Name is required.
                </span>        -->
                <small  class=" zeroleftimp text-danger"
                *ngIf="userModalForEdit.controls['password'].invalid && (userModalForEdit.controls['password'].dirty || userModalForEdit.controls['password'].touched) &&(userModalForEdit.controls['password'].hasError('required') && userModalForEdit.controls['password'].hasError('hasNumber') || userModalForEdit.controls['password'].hasError('hasCapitalCase') ||
                userModalForEdit.controls['password'].hasError('hasSpecialCharacters') || userModalForEdit.controls['password'].hasError('hasSmallCase') ||
                userModalForEdit.controls['password'].hasError('minlength'))">
                Enter a valid password
              </small> 
                <small>
                  Password should be atleast 8 characters long and should contain one number, one uppercase character, one lowercase character, and one special character
                </small>

              </div>
            
            
            <input  hidden formControlName="id"   class="input-field" type="text" placeholder="{{userModalForEdit.value.id}}" />        


             
            </section>
        </div>
        
        <!-- Modal footer -->
        <footer class="modalfooter">
          <button type="button" class="done-btn modalpubtn" data-dismiss="modal">Cancel</button>
          <button  class="done-btn modalpubtn" type="submit" [disabled]="userModalForEdit.invalid">Save </button>
        </footer>
      </form>

      </div>
    </div>
</div>

<!-- Password Modal -->
<div class="modal" id="changePasswordModal">
    <div class="modal-dialog ">
      <div class="modal-content ">
      
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Change Password</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <!-- Modal body -->
        <form [formGroup]="editPassword" (ngSubmit)="updatePassword(editPassword)" >

        <div class="modal-body" >
          
            <section class="inputc">
              <div class="col-md-12 fieldc" [ngClass]="editPassword.controls['password'].hasError('required') ||
              editPassword.controls['password'].hasError('hasNumber') ||
              editPassword.controls['password'].hasError('hasCapitalCase') ||
              editPassword.controls['password'].hasError('hasSmallCase')   ||
              editPassword.controls['password'].hasError('hasSpecialCharacters')   ||
              editPassword.controls['password'].hasError('minlength')  ? '':''"> 

                <!-- <div class="col-md-6 fieldc" >
                    <label class="inputlabel">UserId</label> 
                    <input hidden name="user" class="input-field" type="text" value="{{user.id}}" readonly/>      
                </div> -->
                <div class="input-group mb-3" >
                  <label class="inputlabel label-asterisk">Password</label> 
                  <input formControlName="password"  class="form-control input-field" type="text" value="{{user.password}}" type="password" [type]="hide ? 'password':'text'" />
                  <div class="input-group-append">
                    <span class="input-group-text bg-light" id="basic-addon2">    
                        <i class="{{hide?'fa fa-eye-slash':'fa fa-eye'}}" (click)="hide = !hide"></i>
                    </span>
                  </div> 
                </div>

                  <small  class=" zeroleftimp text-danger"
                *ngIf="editPassword.controls['password'].hasError('required') ||  editPassword.controls['password'].hasError('hasNumber') || editPassword.controls['password'].hasError('hasCapitalCase') ||
                editPassword.controls['password'].hasError('hasSpecialCharacters') || editPassword.controls['password'].hasError('hasSmallCase') ||
                editPassword.controls['password'].hasError('minlength')">
                Enter a valid password
              </small> 
                  <small class="fifteenpt fontsml gray-text"> Password should be atleast 8 characters long and should contain one
                    number, one uppercase character, one lowercase character and one special character.</small>        
            </div>

            </section>
            <section class="inputc">
               
            </section>
        </div>
        
        <!-- Modal footer -->
        <footer class="modalfooter">
            <button type="button" class="done-btn modalpubtn" data-dismiss="modal">Cancel</button>
            <button class="done-btn modalpubtn" type="submit" [disabled]="editPassword.invalid">Save </button>

        </footer>
        </form>
      </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { Config } from '../../../config';
import { retry, catchError, map } from 'rxjs/operators';

import { Observable, throwError } from 'rxjs';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Injectable({
  providedIn: 'root',
})
export class HiveService {
  static url = Config.url;

  constructor(private httpClient: HttpClient) {}

  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  getAllHiveRowsWithLimits(
    databaseName: string,
    tableName: string,
    limit: number
  ): Observable<any[]> {
    return this.httpClient
      .get<any[]>(
        HiveService.url +
          '/hive/rows/' +
          databaseName +
          '/' +
          tableName +
          '/' +
          limit
      )
      .pipe(
        retry(1)
        // catchError(this.processError)
      );
  }

  getTableInformation(
    databaseName: string,
    tableName: string
  ): Observable<any[]> {
    return this.httpClient
      .get<any[]>(
        HiveService.url + '/hive/table/' + databaseName + '/' + tableName
      )
      .pipe(
        retry(1)
        // catchError(this.processError)
      );
  }

  hiveValidateDatabase(databaseName: string) {
    return this.httpClient
      .get(HiveService.url + '/hive/validate/' + databaseName)
      .pipe(
        retry(1),
        catchError(this.handleError)

        // catchError(this.processError)
      );
  }

  hiveValidateTable(databaseName: string, tableName: string) {
    return this.httpClient
      .get(HiveService.url + '/hive/validate/' + databaseName + '/' + tableName)
      .pipe(
        retry(1),
        catchError(this.handleError)

        // catchError(this.processError)
      );
  }
  hiveValidate(databases: any) {
    return this.httpClient
      .post(HiveService.url + '/hive/validate/', databases)
      .pipe(
        retry(1)
        //catchError(this.handleError)

        // catchError(this.processError)
      );
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    Swal.fire({
      type: 'Failed',

      title: 'Failed',
      text: error.error.message,
      confirmButtonText: 'Ok',
    });
    return throwError(errorMessage);
  }
}

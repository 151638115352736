import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from 'src/app/services/project/project-service.service';
import { SideNavServiceService } from 'src/app/services/side-nav/side-nav-service.service';
import { AppComponent } from 'src/app/app.component';
import { IngestionSharingServiceService } from '../../ingestion-sharing-service.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit, AfterViewInit {
  constructor(
    public nav: SideNavServiceService,
    public projectService: ProjectService,
    private router: Router,
    private ingestionSharing: IngestionSharingServiceService,
    private activatedRoute: ActivatedRoute
  ) {}

  projectName = this.activatedRoute.snapshot.params['id2'];
 
  
  loggedUser: any;

  noCurrentProject() {
    this.projectService.saveCurrentProject('');
  }
  

  deleteData() {
    localStorage.removeItem('stepOne');
    localStorage.removeItem('stepTwo');
  }

  ngOnInit(): void {}

  isUserManagementHidden() {
    this.loggedUser = JSON.parse(sessionStorage.getItem('loggedUser'));
    for (
      var user_auths = 0;
      user_auths < this.loggedUser.authorities.authorities.length;
      user_auths++
    ) {
      if (
        this.loggedUser.authorities.authorities[user_auths].authority ===
        'ROLE_ADMIN'
      ) {
        return false;
      }
    }
    return true;
  }

  isAccessHidden() {
    this.loggedUser = JSON.parse(sessionStorage.getItem('loggedUser'));
    for (
      var user_auths = 0;
      user_auths < this.loggedUser.authorities.authorities.length;
      user_auths++
    ) {
      if (
        this.loggedUser.authorities.authorities[user_auths].authority ===
          'PERM_OWNER' ||
        this.loggedUser.authorities.authorities[user_auths].authority ===
          'PERM_ADMIN'
      ) {
        return false;
      }
    }
    return true;
  }

  ngAfterViewInit() {
    $(document).ready(function () {
      function profile_sn() {
        $('body').on('click', '.sidebar-list', function () {
          $('.projow_click').removeClass('active');
        });

        $('body').on('click', '.projow_click', function () {
          $('.projow_click').addClass('active');
        });
      }
      setTimeout(profile_sn, 2);
    });
  }
}

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { RdbmsIngestionControllerService } from "../../../../services/api/rdbms-controller/rdbms-ingestion-controller.service"
import { JobControllerService } from "../../../../services/api/job/job-controller.service"
import { ProjectService } from 'src/app/services/project/project-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import * as $ from 'jquery';
import { ScheduleService } from 'src/app/services/scheduler/schedule.service';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-job-schedule-landing-page',
  templateUrl: './job-schedule-landing-page.component.html',
  styleUrls: ['./job-schedule-landing-page.component.scss']
})
export class JobScheduleLandingPageComponent implements OnInit, AfterViewInit {

  isSidenav: boolean = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  constructor(
    private rdbmsService: RdbmsIngestionControllerService,
    private jobsService: JobControllerService,
    public projectService: ProjectService,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private router: Router,
    private scheduleService: ScheduleService,
    private formBuilder: FormBuilder,
    private jobController: JobControllerService
  ) { }


  load: boolean;
  term: string
  projectName = this.activatedRoute.snapshot.params['id2'];
  schedules: any = [];
  page = 1;
  enabled: boolean;
  editSchedulerForm = this.formBuilder.group(
    {
      cronExpression: ["", Validators.required],
      enabled: false,
      id: [""],
      owner: [""],
      ingestionId: [""],
      ingestionName: ["", Validators.required],
      name: ["", Validators.required],
      project: this.projectName
    }
  )

  populateForm(data: any) {
    this.editSchedulerForm.patchValue({
      cronExpression: data.cronExpression,
      enabled: data.enabled,
      id: data.id,
      ingestionId: data.ingestionId,
      ingestionName: data.ingestionName,
      name: data.name,
      owner: data.owner,
      project: this.projectName
    })
  }

  returnStatus(value: boolean): string {
    if (value == true) {
      return "Enabled"
    } else {
      return "Disabled"
    }

  }
  returnStatusColor(value: boolean): string {
    if (value == true) {
      return "text-success"
    } else {
      return "text-warning"
    }

  }

  updateScedule(schedule: any) {
    console.log(schedule.value)
    Swal.fire({
      type: 'warning',

      title: "Are you sure you want to update the schedule '" + schedule.value.name + "'?",
      // text: 'All information associated to this schedule will be permanently deleted',
      showCancelButton: true, confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      console.log(result)
      if (result.isConfirmed == true) {
        this.scheduleService.updateSchedule(schedule.value).subscribe(response => {
          console.log(response)
          this.getAllSchedules();
          this.success("Schedule successfully updated");
          $('#largeModal').modal().hide();


        })

      }
    })

  }

  deleteSchedule(schedule: any) {
    Swal.fire({
      type: 'warning',

      title: "Are you sure you want to delete the schedule '" + schedule.name + "'?",
      text: 'All information associated to this schedule will be permanently deleted',
      showCancelButton: true, confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      console.log(result)
      if (result.isConfirmed == true) {
        this.scheduleService.deleteScheduleById(schedule.id).subscribe(response => {
          this.getAllSchedules();
          this.success("Schedule successfully deleted");

        })

      }
    })

  }

  enableSchedule(data: any) {
    Swal.fire({
      type: 'warning',

      title: "Are you sure you want to enable the schedule '" + data.name + "'?",
      // text: 'All information associated to this schedule will be permanently deleted',
      showCancelButton: true, confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      console.log(result)
      if (result.isConfirmed == true) {
        this.scheduleService.enableSchedule(data.id).subscribe(response => {
          this.getAllSchedulesByProject(this.projectName);
          this.success("Schedule enabled");
          this.getAllSchedules();

          console.log(response);
        })

      }
    })

  }
  currentJobStatus(data: any): string {
    let res: any
    this.jobsService.getLatestSingleJob(data).subscribe(response => {

      res = response;
      console.log(res.status)
    })
    return res.status;
  }


  disableSchedule(data: any) {
    Swal.fire({
      type: 'warning',

      title: "Are you sure you want to disable the schedule '" + data.name + "'?",
      // text: 'All information associated to this schedule will be permanently deleted',
      showCancelButton: true, confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      console.log(result)
      if (result.isConfirmed == true) {
        this.scheduleService.disableSchedule(data.id).subscribe(response => {
          console.log(response);
          this.getAllSchedules();
          this.success("Schedule disabled");

        })

      }
    })

  }



  validateCron(cronExpression: string) {
    this.scheduleService.validateCron(cronExpression)
  }
  getAllSchedulesByProject(projectName: any) {
    this.scheduleService.getAllSchedulesByProject(projectName).subscribe(res => {
      this.schedules = res;

    })
  }

  sortAscending(data: any) {
    data.sort((a: any, b: any) => (a.createdAt > b.createdAt) ? 1 : -1)

  }

  sortDescending(data: any) {
    data.sort((a: any, b: any) => (a.createdAt < b.createdAt) ? 1 : -1)
  }

  getAllSchedules() {
    this.load = true;

    this.scheduleService.getAllSchedulesByProject(this.projectName).subscribe(res => {
      console.log(res);
      this.schedules = res;
      for (let i = 0; i < this.schedules.length; i++) {
        this.scheduleService.generate(this.schedules[i].cronExpression, 1).subscribe(response => {
          let data: any = response;

          this.schedules[i].nextScheduleTime = data[0];
          console.log(response);
        })
        this.jobsService.getLatestSingleJob(this.schedules[i].ingestionId).subscribe(response => {
          let latestRdbmsJob: any = response;
          this.schedules[i].latestJob = latestRdbmsJob.status;
          this.schedules[i].latestJobTime = latestRdbmsJob.createdAt;
          // if (this.schedules[i] == this.schedules[this.schedules.length - 1]) {
          //   this.load = false;
          // }

        })

      }
      this.schedules.sort((a: any, b: any) => (a.createdAt < b.createdAt) ? 1 : -1)

      this.load = false;

    })
  }

  ngOnInit(): void {
    this.getAllSchedules();

    // this.getAllSchedulesByProject(this.projectName)
  }

  changeStatusColor(status: string): string {
    if (status === undefined) {
      return "text-danger"
    }
    if (status.toLocaleLowerCase() == "finished") {
      return "text-success"
    } else if (status.toLocaleLowerCase() == "failed" || status.toLocaleLowerCase() == "failed to launch") {
      return "text-danger"
    } else if (status.toLocaleLowerCase() == "discarded") {
      return "text-warning"
    } else if (status.toLocaleLowerCase() == "in progress") {
      return "text-primary"
    } else if (status.toLocaleLowerCase() == "in progress with errors") {
      return "text-primary"
    }

    else {
      return "text-dark"
    }
  }

  ngAfterViewInit() {
    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);
    });
  }
  success(status: string) {
    Swal.fire({
      type: 'Success',

      title: 'Success!',
      text: status,
      confirmButtonText: 'Ok',
    })
  }

}

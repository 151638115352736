import { Component, OnInit } from '@angular/core';
import { FormControl, NgForm, FormGroup, FormBuilder, Validators, FormArray, ReactiveFormsModule } from '@angular/forms';
import { DataSourceControllerService } from 'src/app/services/api/data-source/data-source-controller.service';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { CatalogService } from "../../../../../../../../services/api/catalogService/catalog.service"
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { CdkDragDrop, moveItemInArray, transferArrayItem, copyArrayItem } from '@angular/cdk/drag-drop';
import { RdbmsIngestionControllerService } from 'src/app/services/api/rdbms-controller/rdbms-ingestion-controller.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import { ProjectService } from 'src/app/services/project/project-service.service';
import { IngestionSharingServiceService } from 'src/app/ingestion-sharing-service.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-edit-step-three-query',
  templateUrl: './edit-step-three-query.component.html',
  styleUrls: ['./edit-step-three-query.component.scss']
})
export class EditStepThreeQueryComponent implements OnInit {


  //snackbar 

  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  //datasource



  isSubmitShow: boolean = false;


  constructor(
    private formBuilder: FormBuilder,
    private dataSourceController: DataSourceControllerService,
    private catalogService: CatalogService,
    public service: RdbmsIngestionControllerService,
    public router: Router,
    private snackBar: MatSnackBar,
    public projectService: ProjectService,
    private activatedRoute: ActivatedRoute,
    private ingestionService: IngestionSharingServiceService
  ) { }

  id = this.activatedRoute.snapshot.params['id'];
  sourceId = this.activatedRoute.snapshot.params['id3'];

  projectName = this.activatedRoute.snapshot.params['id2'];

  stepOneData = this.ingestionService.getStepOneQueryData()
  stepTwoData = this.ingestionService.getStepTwoQueryData()

  load = true;
  columnOptions: any;

  showDiv = {
    stepone: false,
    steptwo: true,
    stepthree: true,
    stepfour: false,
    stepfive: false,

    stepbtnone: true,
    stepbtntwo: false,
    stepbtnthree: false,
    stepbtnfour: false,
    stepbtnfive: false,

    cancelbtn: false,
    nextbtn: true,
    nextbtntwo: false,
    nextbtnthree: false,
    nextbtnfour: false,

    backbtn: false,
    backbtntwo: false,
    backbtnthree: false,
    backbtnfour: false,
    backbtnfive: false,

    submitbtn: false
  }

  stepThreeForm = this.formBuilder.group({
    databaseName: ["", Validators.required],
    tableName: ["", Validators.required],
    fileType: ["", Validators.required],
    ingestionMode: ["", Validators.required],
    checkColumn: ["", Validators.required]
  });

  success(status: string) {
    Swal.fire({
      type: 'Success',
      title: 'Success!',
      text: status,
      confirmButtonText: 'Ok',
    })
  }

  saveConfiguration(data: any) {
    this.ingestionService.setStepThreeQueryData(data.value);
  }

  submitForm(data: any) {
    console.log(data.value)
    this.ingestionService.setStepThreeQueryData(data.value);
    this.router.navigate([`/project/${this.projectName}/ingest/editStepFourQuery/${this.id}/${this.sourceId}`]);
  }

  selectFileType(fileTypeValue: string) {
    this.stepThreeForm.patchValue({
      fileType: fileTypeValue
    })
  }

  selectIngestionMode(ingestionModeValue: string) {
    this.stepThreeForm.patchValue({
      ingestionMode: ingestionModeValue
    })
  }

  ngOnInit(): void {
    this.service.getSingleRdbmsIngestion(this.id).subscribe(response => {
      let data: any = response;
      console.log(data);
      this.stepThreeForm.patchValue({
        databaseName: data.databases[0].destinationDatabaseName,
        tableName: data.databases[0].tables[0].destinationTableName,
        fileType: data.fileType,
        ingestionMode: data.databases[0].tables[0].incremental,
        checkColumn: data.databases[0].tables[0].columns[0].destinationColumnName,
      })
    })


    console.log(this.stepTwoData)
    setTimeout(() => this.load = false, 500)
  }

}

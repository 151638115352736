import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { CdkDragDrop, copyArrayItem, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { CatalogService } from "../../../../../../services/api/catalogService/catalog.service"
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { JsonpClientBackend } from '@angular/common/http';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import { RdbmsIngestionControllerService } from '../../../../../../services/api/rdbms-controller/rdbms-ingestion-controller.service';
import { DataSourceControllerService } from '../../../../../../services/api/data-source/data-source-controller.service';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { JobControllerService } from "../../../../../../services/api/job/job-controller.service"
import { isTemplateExpression } from 'typescript';
import { SqoopServiceService } from '../../../../../../services/sqoop/sqoop-service.service';
import { rdbmsJob } from '../../../../../../models/job/rdbmsJob.model';
import { LogService } from '../../../../../../services/log/log.service';
import { logging } from 'selenium-webdriver';

declare var $: any;

interface DatabaseNode {
  sourceDatabaseName: string;
  destinationDatabaseName: string;
  id?: string;
  dataType?: string;
  primaryKey?: boolean;
  tables?: TableNode[];
}

interface TableNode {
  sourceTableName: string;
  destinationTableName: string;
  id?: string;
  dataType?: string;
  incremental: string,
  primaryKey?: boolean;
  columns?: columnNode[];
}

interface columnNode {
  sourceColumnName: string;
  destinationColumnName: string;
  id?: string;
  destinationDataType: string;
  dataType?: string;
  sourceDataType?: string;
  primaryKey?: boolean;
}


interface toggle {
  name: string;
}


@Component({
  selector: 'app-database-view-execution',
  templateUrl: './database-view-execution.component.html',
  styleUrls: ['./database-view-execution.component.scss']
})
export class DatabaseViewExecutionComponent implements OnInit {


  ingestion: any = []
  connections: any = []
  routeArray: any = []
  showIcon: boolean;
  isBeingEdited: boolean;
  ingestions: any = [];
  jobs: any = [];
  data: any;
  durationTime: any;
  sqoopJob: any = [];
  rdbmsJob: any = [];
  sqoopList: any = [];
  logMessage: any = [];
  output: any = [];
  logReport: any = [];
  singleSqoop: any = [];
  sqoopCommand: string

  latestProgress: any
  //drag and drop lists
  public databaseDropList: DatabaseNode[] = [];
  ingestionDropList: DatabaseNode[] = [];

  Id = this.activatedRoute.snapshot.params['id'];
  // sqoopJobId: any = this.activatedRoute.snapshot.queryParams.data;
  sqoopJobId: any;

  dataSource = new MatTreeNestedDataSource();
  toggle: any = [];
  currentLogId: string;
  sqoopReport: any = [];
  ingestObject: any = [];

  constructor(
    private rdbmsService: RdbmsIngestionControllerService, public activatedRoute: ActivatedRoute, private router: Router,
    private dataSourceControllerService: DataSourceControllerService,
    public service: JobControllerService,
    public sqoopService: SqoopServiceService,
    public logService: LogService,
  ) { }
  projectName = this.activatedRoute.snapshot.params['id2'];


  // passItemIdActions(id: string){
  //   this.sqoopReport = this.getSingleSqoopById(id);
  //   console.log("single sqoop is: ")
  //   console.log(this.singleSqoop)
  // }

  getIngestionById(id: string) {
    this.rdbmsService.getSingleRdbmsIngestion(id).subscribe(res => {
      this.ingestObject = res
      console.log("res" + res)
      // console.log("object is: ");
      // console.log(this.ingestObject)

    })

  }


  passItemIdLog(id: string) {
    // this.currentLogId = id;
    this.getLogDataById(id);
  }

  dateMaker(date: string): string {
    let dateformat = new Date(date);
    let dateArray = dateformat.toString().split(" ")
    return dateArray[1] + " " + dateArray[2] + ", " + dateArray[3] + `
    ${dateArray[4]} `

  }

  configureRdbms(id: string) {

    this.rdbmsService.postRdbmsIngestionJob(id).subscribe(response => {
      // console.log(response);
    })
  }

  getLogDataById(id: string) {
    this.logService.getLogById(id).subscribe(logRes => {
      this.logReport = logRes
      // console.log("Log report is: ")
      // console.log(this.logReport)
    })
  }

  getLogData() {
    this.service.getLatestSingleJob(this.Id).subscribe(jobres => {
      this.rdbmsJob = jobres

      // console.log(this.rdbmsJob)
      // console.log("LogDataJob id is: " + this.rdbmsJob.id)
      if (this.rdbmsJob.id == undefined) {
        // console.log("No Jobs")
      } else {
        this.sqoopService.getSqoopJobsByRDBMSJobId(this.rdbmsJob.id).subscribe(sqoopRes => {
          this.sqoopList = sqoopRes;
          this.latestProgress = sqoopRes

          // console.log("LogDataSqoopList is: ")
          // console.log(this.sqoopList)

          let output = [];
          for (let i = 0; i < this.sqoopList.length; i++) {
            output.push(this.sqoopList[i].id)

            // console.log("output is: ")
            // console.log(output)

            // console.log("LogDataSqoopId is: " + this.output.id)

            for (let i = 0; i < this.output.length; i++) {
              this.logService.getLogById(this.output.id).subscribe(LogRes => {
                this.logMessage = LogRes
                console.log("Log Message is: " + this.logMessage)
              })
            }
          }

          // for (let i = 0; i < this.sqoopList.length; i++) {
          //   if (this.sqoopJob.sourceId == this.connections[i].id) {
          //     //execution
          //     this.sqoopJob.runHistory = this.sqoopList[i].runHistory;
          //     this.sqoopJob.step = this.sqoopList[i].database.tables[0].destinationTableName;
          //     this.sqoopJob.size = this.sqoopList[i].size;
          //     this.sqoopJob.duration = this.sqoopList[i].duration;
          //     this.sqoopJob.status = this.sqoopList[i].status;
          //     this.sqoopJob.progress = this.sqoopList[i].progress;

          //     console.log(this.id)

          //   }

          // for (let i = 0; i < this.sqoopList.length; i++) {
          //   if (this.sqoopJob.sourceId == this.sqoopList[i].id) {

          //     const obj = {...this.sqoopList[i]}

          //     this.sqoopJob.append(obj)
          //     console.log("obj is: " + obj)

          //   }
          // }
        })
      }
    })
  }

  logChecker(data: any) {
    if (data == '') {
      return "No Data to Display"
    } else {
      return this.logMessage
    }
  }
  getSingleSqoopById(id: string) {
    this.sqoopService.getSingleSqoopJobBySqoopId(id).subscribe(res => {

      this.singleSqoop = res;

      this.sqoopCommand = this.singleSqoop.sqoopCommand.join(" ")

      // console.log("SingleSqoopById is: ")
      // console.log(this.singleSqoop)
    })
  }

  getSqoopJobs() {

    this.service.getLatestSingleJob(this.Id).subscribe(jobres => {
      this.rdbmsJob = jobres

      // console.log("Job id is: " + this.rdbmsJob.id)
      // console.log("Ingestion id is: " + this.rdbmsJob.rdbmsIngestionId)
      // console.log(this.rdbmsJob)
      if (this.rdbmsJob.id == undefined) {
        // console.log("No Jobs")
      } else {
        this.sqoopService.getSqoopJobsByRDBMSJobId(this.rdbmsJob.id).subscribe(sqoopRes => {
          this.sqoopList = sqoopRes;

          console.log(this.sqoopList)
          this.latestProgress = sqoopRes


          // console.log("You cleared the plug and play")
          // console.log(this.sqoopList)

          // this.sqoopList = [];

          // for (let i = 0; i < this.sqoopList.length; i++) {
          //   if (this.sqoopJob.sourceId == this.connections[i].id) {
          //     //execution
          //     this.sqoopJob.runHistory = this.sqoopList[i].runHistory;
          //     this.sqoopJob.step = this.sqoopList[i].database.tables[0].destinationTableName;
          //     this.sqoopJob.size = this.sqoopList[i].size;
          //     this.sqoopJob.duration = this.sqoopList[i].duration;
          //     this.sqoopJob.status = this.sqoopList[i].status;
          //     this.sqoopJob.progress = this.sqoopList[i].progress;

          //   }

          for (let i = 0; i < this.sqoopList.length; i++) {
            if (this.sqoopJob.sourceId == this.sqoopList[i].id) {

              const obj = { ...this.sqoopList[i] }

              this.sqoopJob.append(obj)
              // console.log("obj is: " + obj)

            }
          }
          // console.log("Sqoop list: " + this.sqoopList)
        })
      }
    })
  }

  checkIfSqoopIsComplete(data: any): any {
    for (let i = 0; i < data.length; i++) {
      if (data[i].status == "FINISHED") {
        return true
      } else {
        return false
      }
    }
  }

  createRDBMSIngestionObject() {

    this.rdbmsService.getSingleRdbmsIngestion(this.Id).subscribe(res => {
      this.ingestion = res;

      this.dataSourceControllerService.getRDBMSConnectors().subscribe(connections => {

        this.connections = connections
        console.log(this.connections)

        // this.ingestion = res;
        const maxLength = Math.max(this.ingestion.length, this.connections.length);


        console.log(this.ingestion.id)

        for (let i = 0; i < this.connections.length; i++) {
          if (this.ingestion.sourceId == this.connections[i].id) {
            this.ingestion.databaseType = this.connections[i].databaseType;
            this.ingestion.connectionName = this.connections[i].name;
            this.ingestion.username = this.connections[i].username;
            this.ingestion.port = this.connections[i].port;
            this.ingestion.hostName = this.connections[i].hostName;

            //execution
            this.ingestion.runHistory = this.connections[i].runHistory;
            this.ingestion.step = this.connections[i].step;
            this.ingestion.size = this.connections[i].size;
            this.ingestion.duration = this.connections[i].duration;
            this.ingestion.status = this.connections[i].status;
            this.ingestion.progress = this.connections[i].progress;

          }

        }

        console.log(this.ingestion)
      })
    })
  }

  getAllIngestions() {

    this.rdbmsService.getAllIngestions().subscribe(res => {
      this.rdbmsService.getAllJobs().subscribe(jobResponse => {
        this.jobs = jobResponse
        this.ingestions = res;
        const maxLength = Math.max(this.ingestions.length, this.jobs.length);

        for (let j = 0; j < this.ingestions.length; j++) {
          if (this.ingestions[j].bulkImport == false) {
            // console.log(this.ingestions[j])
            this.ingestions[j].ingestionType = "Custom RDBMS Ingestion"
          } else if (this.ingestions[j].bulkImport == true) {
            this.ingestions[j].ingestionType = "Bulk RDBMS Ingestion"

          }
        }
        // console.log(this.jobs)
        for (let i = 0; i < maxLength; i++) {
          if (this.ingestions[i] && this.jobs[i] && this.ingestions[i].id && this.jobs[i].id) {
            let count = 1 + i;

            this.ingestions[i].progress = this.jobs[i].progress;
            this.ingestions[i].status = this.jobs[i].status;
            this.ingestions[i].sqoopCommand = this.jobs[i].sqoopCommand;
            this.ingestions[i].count = count;

            if (this.jobs[i].finishedAt != null) {
              this.ingestions[i].finishedAt = this.dateFormatter(this.jobs[i].finishedAt);
            } else {
              this.ingestions[i].finishedAt = "Not Applicable";

            }


          }
        }
        // console.log(this.ingestions)
        this.ingestions.sort((a: any, b: any) => (a.createdAt < b.createdAt) ? 1 : -1)


      })
    })
  }

  dateFormatter(date: string): string {
    let dateformat = new Date(date);
    let dateArray = dateformat.toString().split(" ")
    return dateArray[1] + " " + dateArray[2] + ", " + dateArray[3] + `${dateArray[4]} `

  }

  duration(createdAt: number, finishedAt: number, stringStatus: string) {
    let currentTime = Date.now()
    if (stringStatus.toLowerCase() == "finished") {
      return finishedAt - createdAt

    } else if (stringStatus.toLowerCase() == "failed" || stringStatus.toLowerCase() == "failed to launch" || stringStatus.toLowerCase() == "stopped") {
      return finishedAt - createdAt

    } else if (stringStatus.toLowerCase() == "in progress" || stringStatus.toLowerCase() == "pending") {

      return currentTime - createdAt

    } else {
      return currentTime - createdAt

    }
  }
  miliConvert(milliseconds: number) {

    var minutes = (milliseconds / 1000) / 60;
    var seconds = (milliseconds / 1000) % 60;
    var minutesRounded = Math.round(minutes)
    var secondsRounded = Math.round(seconds)
    var diff = (minutesRounded + " minutes, " + secondsRounded + " seconds")
    return diff
  }

  refresh() {
    this.getIngestionById(this.Id)

    this.getSqoopJobs();
    this.getAllConnections();
  }
  convertToTime(dateInput: Date) {
    var date = dateInput;
    var time = date.getUTCMilliseconds();
    return time;
  }

  convertTime(inputTime: any) {
    let finishedTime: any = this.dateFormatter(this.sqoopJob.finishedAt)
    let time = finishedTime - inputTime
    return time
  }

  editField() {

  }


  updateIngestion(id: string, data: any) {
    this.rdbmsService.updateRdbmsIngestion(data).subscribe(res => {
      // console.log(res)
      this.router.navigate([`/`])

    })

  }

  checkforNull(value: string): string {
    if (value == null) {
      return "N/A"
    } else {
      return value
    }
  }

  ngOnInit(): void {
    console.log(this.sqoopList)
    this.getIngestionById(this.Id)
    this.getIngestionById(this.Id);
    this.getSqoopJobs();
    this.getAllConnections();

    let datemaker = new Date(0)
    datemaker.setUTCSeconds(1616623753369)
  }


  getAllConnections() {
    this.service.getJobs().toPromise()
      .then(response => {
        this.data = response;
        this.data.sort((a: any, b: any) => (a.name < b.name) ? 1 : -1)
      }
      )
  }

  showDiv = {
    stepone: false,
    steptwo: true,
    stepthree: false,

  }

}

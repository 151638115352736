<div *ngIf="load" class="">
    <div class="loader"></div>
    <figure class="spinner-border loaderico"></figure>
</div>
<div class="cm-content-blocks">
    <ol class="breadcrumb">
        <li><a routerLink="/home"><i class="fa fa-home"></i>Home</a></li>
        <li><a routerLink="/project"><i class="fa fa-folder-open"></i>Projects</a></li>
        <li><a routerLink="/project/{{projectName}}"><i class="fa fa-folder"></i>{{projectName}}</a></li>
        <li><a [routerLink]="['/project', projectService.getCurrentProject(),'ingest']"><i
                    class="fa fa-filter"></i>Ingest</a></li>
        <li><a [routerLink]="['/project', projectService.getCurrentProject(),'ingest', 'select-ingestion']"><i
                    class="fa fa-sitemap mr-1"></i>Configure Ingestion</a></li>
        <li class="active"><i class="fa fa-filter"></i>Table Ingestion</li>

    </ol>
</div>

<div class="clearafter headbtnc">
    <h3 class="headc">
        Table Ingestion
        <div class="subhead">Configure Table Ingestion</div>
    </h3>
    <div class="create-btn-position headbtnc">
        <button class="mr-btn-back pull-right pointerc"
            [routerLink]="['/project', projectService.getCurrentProject(),'ingest']">Back to Ingest</button>
    </div>
</div>

<div class="menublock">
    <!-- steps -->
    <div class="progresslic clearafter position-relative">
        <div class="fifteen_pb">
            <ul class="progressbar clearfix">

                <li class="tabli active pointerc" [ngClass]="showDiv.stepone === true ? 'active':'disabled' ">
                    <section class="tabbtn">
                        <section class="clearfix relativepos">
                            <aside class="absoultepos stipico">
                                <i class="fa fa-info" aria-hidden="true"></i>
                            </aside>
                            <aside class="steptxtc">
                                <div class="steptitle">Data Source</div>
                                <div class="stepdes mt-1">Select data source for ingestion</div>
                            </aside>
                        </section>
                    </section>
                </li>

                <li class="tabli pointerc" [ngClass]="showDiv.steptwo === true ?'active':'disabled' ">
                    <section class="tabbtn">
                        <section class="clearafter relativepos">
                            <aside class="absoultepos stipico">
                                <i class="fa fa-database" aria-hidden="true"></i>
                            </aside>
                            <aside class="steptxtc">
                                <div class="steptitle">Choose Data</div>
                                <div class="stepdes">Select databases, tables, and columns to ingest from</div>
                            </aside>
                        </section>
                    </section>
                </li>

                <li class="tabli pointerc" [ngClass]="showDiv.stepthree === true ?'active':'disabled' ">
                    <section class="tabbtn">
                        <section class="clearfix relativepos">
                            <aside class="absoultepos stipico">
                                <i class="fa fa-map-marker" aria-hidden="true"></i>
                            </aside>
                            <aside class="steptxtc">
                                <div class="steptitle">Configure Destination</div>
                                <div class="stepdes">Edit destination database and table names, and select ingestion
                                    mode</div>
                            </aside>
                        </section>
                    </section>
                </li>


                <li class="tabli pointerc" [ngClass]="showDiv.stepfour === true ?'active':'disabled' ">
                    <section class="tabbtn">
                        <section class="clearfix relativepos">
                            <aside class="absoultepos stipico">
                                <i class="fa fa-cogs" aria-hidden="true"></i>
                            </aside>
                            <aside class="steptxtc">
                                <div class="steptitle">Advanced Configuration</div>
                                <div class="stepdes">Configure ingestion settings</div>
                            </aside>
                        </section>
                    </section>
                </li>
                <!-- <li class="tabli pointerc"
                [ngClass]="showDiv.stepfive === true ?'active':'disabled' " 
                (click)="showDiv.stepone = false;
                showDiv.steptwo = false; 
                showDiv.stepthree = false;
                showDiv.stepfour = false;
                showDiv.stepfive = true;
  
                showDiv.backbtn = false;
                showDiv.backbtntwo = false;  
                showDiv.backbtnthree = false;  
                showDiv.backbtnfour = false;  
                showDiv.backbtnfive = true;  
   
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false;
                showDiv.nextbtntwo = false; 
                showDiv.nextbtnthree = false; 
                showDiv.nextbtnfour = false; 
                showDiv.cancelbtn = false"
                >
                    <section class="tabbtn">
                        <section class="clearfix relativepos">
                        <aside class="absoultepos stipico">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                        </aside>
                        <aside class="steptxtc">
                            <div class="steptitle">Advanced Configuration</div>
                            <div class="stepdes">Description</div>
                        </aside>
                        </section>
                    </section>
                </li>
                 -->


            </ul>
        </div>
        <!-- tabe content-->

        <section class="tabc_config">
            <!-- page content -->
            <div class="stepone_c" *ngIf="showDiv.stepone">

                <div class="fifteenmb">
                    <section>

                        <form [formGroup]="customIngestionFormFields"
                            (ngSubmit)="stepOneForm(customIngestionFormFields)">
                            <section class="inputc">
                                <div class="col-md-6 fieldc"
                                    [ngClass]="customIngestionFormFields.controls['name'].touched && customIngestionFormFields.controls['name'].errors?'inputc-error':''">
                                    <div class="poosition-relative">
                                        <label class="inputlabel label-asterisk"> Ingestion name </label>
                                        <input name="name" class="input-field" type="text" formControlName="name"
                                            required />

                                        <div class="error-mge four_minus_bottom"
                                            *ngIf="cf.name.dirty || cf.name.touched">
                                            <span *ngIf="cf.name?.errors?.required">
                                                Ingestion Name is required.
                                            </span>
                                            <span *ngIf="cf.name?.errors?.DuplicateName">
                                                Ingestion Name {{cf.name.value}} is already taken.
                                            </span>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-6 fieldc">
                                    <div class="position-relative">
                                        <label class="inputlabel label-asterisk"> Select Data Source</label>
                                        <section class="inputc justify-content-md-center">
                                            <mat-form-field appearance="fill">
                                                <mat-label></mat-label>
                                                <mat-select formControlName="sourceId"
                                                    [ngClass]="customIngestionFormFields.controls['sourceId'].touched && customIngestionFormFields.controls['sourceId'].errors?'inputc-error':''">
                                                    <mat-option class="mr-4" *ngFor="let dataSource of dataSources"
                                                        [value]="dataSource.id"
                                                        (click)="getSingleDataSource(dataSource.id)">
                                                        <img class="mr-2"
                                                            src="{{identifyDriver(dataSource.databaseType)}}"
                                                            height="20" width="40" alt="">
                                                        {{dataSource.name}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </section>
                                        <!-- <section class="inputc justify-content-md-center" *ngIf="IngestionService.getStepOneData() != null">
                                        <mat-form-field appearance="fill">
                                        <mat-label></mat-label>
                                        <mat-select formControlName="sourceId" 
                                        [ngClass]="customIngestionFormFields.controls['sourceId'].touched && customIngestionFormFields.controls['sourceId'].errors?'inputc-error':''"  
                                        >
                                        <mat-option class="mr-4" *ngFor="let dataSource of dataSources" [value]="dataSource.id" (click)="getSingleDataSource(dataSource.id)">
                                            {{dataSource.name}}                                
                                        </mat-option>
                                        </mat-select>  
                                    </mat-form-field>
                                    </section> -->
                                        <span class="error-mge zeroleftimp"
                                            *ngIf="customIngestionFormFields.controls['sourceId'].invalid && (customIngestionFormFields.controls['sourceId'].dirty || customIngestionFormFields.controls['sourceId'].touched)">
                                            Data source is required.
                                        </span>
                                    </div>
                                    <!-- <div class="position-relative" *ngIf="IngestionService.getStepOneData() !== null"> 
                                    <label class="inputlabel label-asterisk"> Select Data Source</label> 
                                    <section class="inputc justify-content-md-center" >
                                            <mat-form-field appearance="fill">
                                            <mat-label></mat-label>
                                            <mat-select formControlName="sourceId" 
                                            [ngClass]="customIngestionFormFields.controls['sourceId'].touched && customIngestionFormFields.controls['sourceId'].errors?'inputc-error':''"  
                                            >
                                            <mat-option class="mr-4" *ngFor="let dataSource of dataSources" [value]="savedDatasourceDetails.value.name" (click)="getSingleDataSource(dataSource.id)" >
                                                {{dataSource.name}}                                
                                            </mat-option>
                                            </mat-select>  
                                        </mat-form-field>
                                    </section>
                                
                                    <span class="error-mge zeroleftimp"
                                    *ngIf="customIngestionFormFields.controls['sourceId'].invalid && (customIngestionFormFields.controls['sourceId'].dirty || customIngestionFormFields.controls['sourceId'].touched)">
                                    Data source is required.
                                    </span>   
                                </div> -->

                                    <!-- <div class="tenmt">
                                    <div *ngIf="singleDataSource">
                                        <span class="bold">Data source name:</span> {{singleDataSource.name}},
                                        <span class="bold">Data source type:</span> {{singleDataSource.databaseType}} ,
                                        <span class="bold">Hostname:</span> {{singleDataSource.hostName}}, 
                                        <span class="bold">Port:</span> {{singleDataSource.port}},
                                        <span class="bold">User name:</span> {{singleDataSource.username}} 
                                    </div>
                                </div> -->



                                </div>
                            </section>
                            <section class="inputc">
                                <div class="col-md-6 fieldc">
                                    <label class="inputlabel"> Ingestion Description </label>
                                    <textarea class="input-field" rows="4" formControlName="description"></textarea>
                                </div>
                                <div class="col-md-6 fieldc mt-4">

                                    <div class="formc inputtextb fifteenmb" *ngIf="singleDataSource">
                                        <!-- <section  class="inputc fieldtctext">
                                        <label class="labeltext"> Data Source Name </label>
                                        <div class="inputtext">{{singleDataSource.name}}</div>
                                    </section> -->
                                        <section class="inputc fieldtctext">
                                            <label class="labeltext"> Data Source Description </label>
                                            <div class="inputtext">{{singleDataSource.description}}</div>
                                        </section>
                                        <section class="inputc fieldtctext">
                                            <label class="labeltext"> Data Source Type </label>
                                            <div class="inputtext">{{singleDataSource.databaseType}}</div>
                                        </section>
                                        <section class="inputc fieldtctext">
                                            <label class="labeltext"> Hostname </label>
                                            <div class="inputtext">{{singleDataSource.hostName}}</div>
                                        </section>
                                        <section class="inputc fieldtctext">
                                            <label class="labeltext"> Port </label>
                                            <div class="inputtext">{{singleDataSource.port}}</div>
                                        </section>
                                        <section class="inputc fieldtctext">
                                            <label class="labeltext"> User name </label>
                                            <div class="inputtext">{{singleDataSource.username}}</div>
                                        </section>

                                    </div>
                                </div>



                            </section>

                            <button *ngIf="IngestionService.getStepOneData() == null"
                                class="tertiary-active btnnext tenmt float-right" type="submit"
                                [disabled]="customIngestionFormFields.invalid">
                                Next <i aria-hidden="true" class="fa fa-arrow-right"></i>
                            </button>
                            <button *ngIf="IngestionService.getStepOneData() != null"
                                class="tertiary-active btnnext tenmt float-right" type="submit">
                                Next <i aria-hidden="true" class="fa fa-arrow-right"></i>
                            </button>

                        </form>
                    </section>

                </div>
            </div>




            <!-- footer-->
            <footer class="tenpt lightgraybdrt clearafter footerbtns">


                <!-- <button class="tertiary-active fr btnnext" id="btnnext" *ngIf="showDiv.nextbtnfour"
                (click)="showDiv.stepone = false; 
                showDiv.steptwo =false; 
                showDiv.stepthree = false;
                showDiv.stepfour= false;
                showDiv.stepfive = true;
  
                showDiv.backbtn = false; 
                showDiv.backbtntwo = false;
                showDiv.backbtnthree = false; 
                showDiv.backbtnfour = true; 
                showDiv.backbtnfive = false;
  
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false; 
                showDiv.nextbtntwo = false; 
                showDiv.nextbtnthree = false; 
                showDiv.nextbtnfour = false;
  
                showDiv.cancelbtn = false;">
                Next <i aria-hidden="true" class="fa fa-arrow-right"></i>
                </button>
                
     -->
                <button class="grey-btn fr btnpre" *ngIf="showDiv.cancelbtn">
                    Cancel
                </button>

                <button class="tertiary-active fr btnnext" *ngIf="showDiv.submitbtn">
                    Submit
                </button>

                <button class="grey-btn fr btnpre" *ngIf="showDiv.backbtn" (click)="showDiv.stepone = true; 
                showDiv.steptwo = false; 
                showDiv.stepthree = false;
                showDiv.stepfour = false;
                showDiv.stepfive = false;
  
                showDiv.backbtn = false; 
                showDiv.backbtntwo = false;
                showDiv.backbtnthree = false; 
                showDiv.backbtnfour= false;
                showDiv.backbtnfive = false;
  
                showDiv.submitbtn = false; 
                showDiv.nextbtn = true; 
                showDiv.nextbtntwo = false; 
                showDiv.nextbtnthree = false; 
                showDiv.nextbtnfour = false; 
  
                showDiv.cancelbtn = false;">
                    <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
                </button>
                <button class="grey-btn fr btnpre" *ngIf="showDiv.backbtntwo" (click)="showDiv.stepone = false; 
                showDiv.steptwo = true; 
                showDiv.stepthree = false;
                showDiv.stepfour = false;
                showDiv.stepfive = false;
  
                showDiv.backbtn = true; 
                showDiv.backbtntwo = false;
                showDiv.backbtnthree = false;
                showDiv.backbtnfour = false;
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false; 
                showDiv.nextbtntwo = true; 
                showDiv.nextbtnthree = false; 
                showDiv.nextbtnfour = false; 
  
                showDiv.cancelbtn = false;">
                    <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
                </button>
                <button class="grey-btn fr btnpre" *ngIf="showDiv.backbtnthree" (click)="showDiv.stepone = false; 
                showDiv.steptwo = false; 
                showDiv.stepthree = true;
                showDiv.stepfour = false;
                showDiv.stepfive = false;
  
                showDiv.backbtn = false; 
                showDiv.backbtntwo = true;
                showDiv.backbtnthree = false; 
                showDiv.backbtnfour = false; 
                showDiv.backbtnfive = false; 
  
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false; 
                showDiv.nextbtntwo = false; 
                showDiv.nextbtnthree = true; 
                showDiv.nextbtnfour = false; 
                showDiv.cancelbtn = false;">
                    <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
                </button>
                <!-- <button class="grey-btn fr btnpre"  *ngIf="showDiv.backbtnfour"
                (click)="showDiv.stepone = false; 
                showDiv.steptwo = false; 
                showDiv.stepthree = false;
                showDiv.stepfour = true;
                showDiv.stepfive = false;
  
                showDiv.backbtn = false; 
                showDiv.backbtntwo = false;
                showDiv.backbtnthree = true; 
                showDiv.backbtnfour = false; 
                showDiv.backbtnfive = false; 
  
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false; 
                showDiv.nextbtntwo = false; 
                showDiv.nextbtnthree = false; 
                showDiv.nextbtnfour = true; 
                showDiv.cancelbtn = false;">
                    <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
                </button> -->
                <!-- <button class="grey-btn fr btnpre"  *ngIf="showDiv.backbtnfive"
                (click)="showDiv.stepone = false; 
                showDiv.steptwo = false; 
                showDiv.stepthree = false;
                showDiv.stepfour = false;
                showDiv.stepfive = true;
  
                showDiv.backbtn = true; 
                showDiv.backbtntwo = false;
                showDiv.backbtnthree = false; 
                showDiv.backbtnfour = true; 
                showDiv.backbtnfive = false; 
  
                showDiv.submitbtn = false; 
                showDiv.nextbtn = false; 
                showDiv.nextbtntwo = false; 
                showDiv.nextbtnthree = false; 
                showDiv.nextbtnfour = false; 
                showDiv.cancelbtn = false;">
                    <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
                </button>
               -->
            </footer>
        </section>

    </div>

</div>

<div class="modal" id="largeModal">
    <div class="modal-dialog modal-lg">
        <div class="modal-content ">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Large Modal</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                Modal Body Content
            </div>

            <!-- Modal footer -->
            <footer class="modalfooter">
                <button class="done-btn modalpubtn">Next </button>
                <button type="button" class="done-btn modalpubtn" data-dismiss="modal">Cancel</button>
            </footer>
        </div>
    </div>
</div>
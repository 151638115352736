import { Injectable } from '@angular/core';
import { Config } from '../../../config';
import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import Swal from 'sweetalert2/dist/sweetalert2.js';

export interface Jobs {


}

@Injectable({
  providedIn: 'root'
})
export class JobControllerService {

  static url = Config.url;


  constructor(private httpClient: HttpClient) { }

  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  getAllJobsByProjectName(name: string) {
    return this.httpClient.get(JobControllerService.url + "/jobs/rdbms/project/" + name)
      .pipe(
        retry(1),
        catchError(this.handleError)

      )
  }

  getJobCount() {
    return this.httpClient.get(JobControllerService.url + "/jobs/rdbms/count")
      .pipe(
        retry(1),
        catchError(this.handleError)

      )
  }

  getJobs() {
    return this.httpClient.get(JobControllerService.url + "/job/rdbms/")
      .pipe(
        retry(1),
        catchError(this.handleError)

      )
  }


  pauseJob(id: string) {
    return this.httpClient.get(JobControllerService.url + "/job/rdbms/" + id + "/pause")
      .pipe(
        retry(1),
        catchError(this.handleError)

      )
  }

  discardJob(id: string) {
    return this.httpClient.get(JobControllerService.url + "/job/rdbms/" + id + "/discard")
      .pipe(
        retry(1),
        catchError(this.handleError)

      )
  }

  resumeJob(id: string) {
    return this.httpClient.get(JobControllerService.url + "/job/rdbms/" + id + "/resume")
      .pipe(
        retry(1),
        catchError(this.handleError)

      )
  }
  getSingleJob(id: string): Observable<Jobs> {
    return this.httpClient.get<Jobs>(JobControllerService.url + "/job/rdbms/" + id)
      .pipe(
        retry(1),
        catchError(this.handleError)

      )
  }
  getSingleSqoopJob(id: string): Observable<Jobs> {
    return this.httpClient.get<Jobs>(JobControllerService.url + "/sqoop/jobs/rdbms/" + id)
      .pipe(
        retry(1)
        // catchError(this.processError)
      );
  }

  getLatestSingleJob(id: string): Observable<Jobs> {
    return this.httpClient.get<Jobs>(JobControllerService.url + "/job/rdbms/latest/" + id)
      .pipe(
        retry(1),
        catchError(this.handleError)

      )
  }

  postJobs(data: any) {
    return this.httpClient.post(JobControllerService.url + "/job/rdbms/", data)
      .pipe(
        retry(1),
        catchError(this.handleError)

      )
  }

  postSingleJob(id: string) {
    return this.httpClient.post(JobControllerService.url + "/job/rdbms/" + id, {})
      .pipe(
        retry(1),
        catchError(this.handleError)

      )
  }

  updateJobs(id: string, data: any): Observable<Jobs> {
    return this.httpClient.put<Jobs>(JobControllerService.url + "/job/rdbms/" + id, JSON.stringify(data), this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  deleteJobs(id: string) {
    return this.httpClient.delete<Jobs>(JobControllerService.url + "/job/rdbms/" + id, this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      //client - side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server - side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    Swal.fire({
      type: 'Failed',

      title: "Failed",
      text: error.error.message,
      confirmButtonText: 'Ok',
    })
    return throwError(errorMessage);
  }

}
<!-- <div class="cm-content-blocks">
        <ol class="breadcrumb">
            <li><a [routerLink]="['/dashboard']"><i class="fa fa-home"></i>Home</a></li>
            <li class="active"><i class="fa fa-user"></i>Profile</li>
        </ol>
    </div> -->
<div class="" style="display:none">
    <div class="loader"></div>
    <figure class="spinner-border loaderico"></figure>
</div>

<!-- <div class="clearafter headbtnc">
        <h3 class="headc">
            Profile
        </h3>
    </div> -->

<section class="profileblock-inr clearafter">
    <!-- <a class="profile-edit iconbtn" placement="left" ngbTooltip="Edit"> <i class="fa fa-pencil-square-o"
                (click)="onEditBtn(userData)" aria-hidden="true"></i> </a> -->
    <aside class=" profile-bg">
        <figure class="profile-img">
            <img *ngIf="currentUser?.imageFileName != undefined" _ngcontent-qdj-c6="" alt=""
                src="{{currentUser?.imageFileName}}">
            <img *ngIf="currentUser?.imageFileName == undefined" _ngcontent-qdj-c6="" alt=""
                src="../../../assets/images/usericon_other.png">

        </figure>
    </aside>
    <aside class="">
        <!-- <h1 class="profilename"> {{userData?.displayName?userData?.displayName:userData?.username}}</h1> -->
        <div class="inputtextb">
            <section class="inputc fieldtctext">
                <label class="labeltext"> Display Name </label>
                <div class="inputtext">{{currentUser?.username}}</div>
            </section>
            <section class="inputc fieldtctext">
                <label class="labeltext"> Email </label>
                <div class="inputtext"> {{currentUser?.email}}</div>
            </section>

            <section class="inputc fieldtctext">
                <label class="labeltext"> Role</label>
                <div class="inputtext"> {{currentUser?.role?.name.split("_")[1]}}</div>
            </section>
        </div>
    </aside>
    <div class="clearafter">
        <button class="tertiary-active btnnext tenmt" (click)="signout()">
            <i _ngcontent-jbl-c13="" aria-hidden="true" class="fa fa-sign-out fivemr"></i>
            Sign Out
        </button>
        <button class="tertiary-active btnnext tenmt" data-toggle="modal" data-target="#editprofile">
            <i _ngcontent-jbl-c13="" aria-hidden="true" class="fa fa-pencil-square-o fivemr"></i>
            Edit Profile
        </button>
        <button class="tertiary-active btnnext tenmt" data-toggle="modal" data-target="#changepwd">
            <i _ngcontent-jbl-c13="" aria-hidden="true" class="fa fa-key fivemr"></i>
            Change Password
        </button>

    </div>

</section>


<div id="changepwd" class="modal" role="dialog">
    <div class="modal-dialog ">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Change Password</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <form [formGroup]="passwordForm" (ngSubmit)="updatePassword(passwordForm)">
                <div class="modal-body">
                    <section class="inputc">
                        <!-- input -->
                        <div class="fieldc">
                            <div class="passwordc" [ngClass]="passwordForm.controls['password'].hasError('required') ||
                    passwordForm.controls['password'].hasError('hasNumber') ||
                    passwordForm.controls['password'].hasError('hasCapitalCase') ||
                    passwordForm.controls['password'].hasError('hasSmallCase')   ||
                    passwordForm.controls['password'].hasError('minlength')  ? '':''">


                                <div class="input-group mb-3">
                                    <label class="inputlabel label-asterisk">Password</label>
                                    <input class="form-control input-field" type="text" formControlName="password"
                                        [type]="hide ? 'password':'text'" />
                                    <div class="input-group-append">
                                        <span class="input-group-text bg-light" id="basic-addon2">
                                            <i class="{{hide?'fa fa-eye-slash':'fa fa-eye'}}"
                                                (click)="hide = !hide"></i>
                                        </span>
                                    </div>
                                </div>


                                <!-- <span class="error-mge mt-2"
                          *ngIf="passwordForm.controls['password'].invalid && (passwordForm.controls['password'].dirty || passwordForm.controls['password'].touched)">
                          User Name is required.
                          </span>        -->
                                <small class=" zeroleftimp text-danger" *ngIf="passwordForm.controls['password'].hasError('required') && passwordForm.controls['password'].hasError('hasNumber') || passwordForm.controls['password'].hasError('hasCapitalCase') ||
                          passwordForm.controls['password'].hasError('hasSpecialCharacters') || passwordForm.controls['password'].hasError('hasSmallCase') ||
                          passwordForm.controls['password'].hasError('minlength')">
                                    Enter a valid password
                                </small>
                                <br>
                                <small>
                                    Password should be atleast 8 characters long and should contain one number, one
                                    uppercase character, one lowercase character, and one special character
                                </small>
                            </div>


                        </div>
                    </section>
                </div>
                <footer class="modalfooter">
                    <div class="done-btn modalpubtn" data-dismiss="modal" (click)="cancelImg();">Cancel</div>

                    <button class="done-btn modalpubtn" type="submit" [disabled]="passwordForm.invalid">
                        Save
                    </button>
                </footer>
            </form>
        </div>

    </div>
</div>
<!-- change password pop up end -->

<!-- edit profile -->
<div id="editprofile" class="modal profile-dialog" role="dialog">
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
            <form [formGroup]="userForm" (ngSubmit)="updateUser(userForm)">
                <div class="modal-header">
                    <h4 class="modal-title">Update Profile</h4>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
                <div class="modal-body clearafter">
                    <aside class="col-xs-5 p-0 float-left">
                        <section class="inputc">
                            <figure class="profile-img">
                                <img src="{{profileImgURL}}" alt="">
                            </figure>
                            <input type="hidden" name="avatarName">
                        </section>
                        <h4 class="innrcaption mb-1">Select your Avatar</h4>
                        <section>
                            <aside class="flex-container ">
                                <figure class="flex-container avatar-block ">
                                    <img src="../../../assets/images/usericon_cm.png"
                                        (click)="onSelectAvathar('../../../assets/images/usericon_cm.png')">
                                    <img src="../../../assets/images/usericon_cw.png"
                                        (click)="onSelectAvathar('../../../assets/images/usericon_cw.png')">
                                    <img src="../../../assets/images/usericon_fm.png"
                                        (click)="onSelectAvathar('../../../assets/images/usericon_fm.png')">
                                    <img src="../../../assets/images/usericon_fw.png"
                                        (click)="onSelectAvathar('../../../assets/images/usericon_fw.png')">
                                    <img src="../../../assets/images/usericon_other.png"
                                        (click)="onSelectAvathar('../../../assets/images/usericon_other.png')">
                                </figure>
                            </aside>
                        </section>

                        <!-- <p>Select your Profile Picture</p>
                            <div class="img-av">
                                <img width="100px" (click)="onSelectAvathar(imgURL1)" height="100px" [src]="imgURL1" *ngIf="imgURL1" alt="">
                                <img width="100px" (click)="onSelectAvathar(imgURL2)" height="100px" [src]="imgURL2" *ngIf="imgURL2" alt="">
                                <img width="100px" (click)="onSelectAvathar(imgURL3)" height="100px" [src]="imgURL3" *ngIf="imgURL3" alt="">
                            </div>
                            -->
                    </aside>
                    <aside class="col-7 p-0 float-right" *ngIf="user">
                        <section class="inputc">
                            <div class="fieldc fifteenmb pr-0">
                                <label class="inputlabel label-asterisk"> User Name </label>
                                <input class="input-field uname" formControlName="username" readonly type="text"
                                    name="username">
                            </div>
                            <div class="fieldc fifteenmb">
                                <label class="inputlabel label-asterisk">First Name </label>
                                <input id="firstName" name="firstName" formControlName="firstName" type="text"
                                    class="input-field" required />
                                <!-- <span class="error-mge"> First name is required. </span> -->
                            </div>
                            <div class="fieldc fifteenmb">
                                <label class="inputlabel label-asterisk">Last Name </label>
                                <input id="lastName" name="lastName" formControlName="lastName" type="text"
                                    class="input-field" required />
                                <!-- <span class="error-mge"> Last name is required. </span> -->
                            </div>
                            <div class="fieldc zeroplimp">
                                <label class="inputlabel label-asterisk"> Display Name </label>
                                <input id="displayName" name="displayName" formControlName="displayName" type="text"
                                    class="input-field" required />
                                <!-- <span class="error-mge zeroleftimp"> Display name is required.</span> -->
                            </div>
                        </section>
                    </aside>
                </div>
                <footer class="modalfooter">
                    <button type="button" class="done-btn modalpubtn" data-dismiss="modal">Cancel</button>

                    <button class="done-btn modalpubtn" type="submit">Save</button>
                </footer>
            </form>
        </div>
    </div>
</div>
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormControl, NgForm, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import Swal from 'sweetalert2/dist/sweetalert2.js';

import { DataSourceControllerService } from '../../../../services/api/data-source/data-source-controller.service'
import { RdbmsConnector } from "../../../../models/rdbms/rdbms-connector.model"
import { ProjectService } from 'src/app/services/project/project-service.service';
import * as $ from 'jquery';

declare var $: any;

@Component({
  selector: 'app-view-details',
  templateUrl: './view-details.component.html',
  styleUrls: ['./view-details.component.scss']
})
export class ViewDetailsComponent implements OnInit {

  isSidenav: boolean = false;
  load: boolean = true;

  //response model
  rdbmsConnectorList: RdbmsConnector[];
  //form for update
  ingestionObject: any = {};

  //toggle password visability
  hide = true;

  //search term
  term: string;

  //field for test
  test: any

  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  ngAfterViewInit() {
    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);
    });
  }

  constructor(public service: DataSourceControllerService, public router: Router, public formBuilder: FormBuilder,
    public projectService: ProjectService, private activatedRoute: ActivatedRoute,
    private snackbar: MatSnackBar
  ) {
  }

  id = this.activatedRoute.snapshot.params['id'];

  projectName = this.activatedRoute.snapshot.params['id2'];
  singleDataSource:any;

  ngOnInit(): void {
    this.getSingleDataSource();
  }

  //update connection

  getSingleDataSource(){
    this.service.getSingleRDBMSConnector(this.id).subscribe(response =>{
      console.log(response)
      this.singleDataSource = response;
    })
  }

}

import { Injectable } from '@angular/core';
import { Config } from '../../../config';
import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RdbmsConnector } from "../../../models/rdbms/rdbms-connector.model"
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class DataSourceControllerService {

  static url = Config.url;

  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  constructor(private httpClient: HttpClient, private snackBar: MatSnackBar) { }

  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  getDataSourceCount(): Observable<RdbmsConnector> {
    return this.httpClient.get<RdbmsConnector>(DataSourceControllerService.url + "/source/rdbms/count")
      .pipe(
        retry(1),
        // catchError(this.processError)
      )
  }

  getAllConnectionsByProjectName(name: string): Observable<RdbmsConnector[]> {
    console.log(name)

    return this.httpClient.get<RdbmsConnector[]>(DataSourceControllerService.url + "/source/rdbms/project/" + name)
      .pipe(

        retry(1),
        catchError(this.handleError)

      )
  }

  getRDBMSConnectors(): Observable<RdbmsConnector[]> {
    return this.httpClient.get<RdbmsConnector[]>(DataSourceControllerService.url + "/source/rdbms")
      .pipe(
        retry(1),
        catchError(this.handleError)

        // catchError(this.processError)
      )
  }

  getSingleRDBMSConnector(id: string): Observable<RdbmsConnector> {
    return this.httpClient.get<RdbmsConnector>(DataSourceControllerService.url + "/source/rdbms/" + id)
      .pipe(
        retry(1),
        catchError(this.handleError)

        // catchError(this.processError)
      )
  }

  getStreamConnectorsByProject(project: string) {
    return this.httpClient.get<any>(DataSourceControllerService.url + "/source/stream/project/" + project)
      .pipe(
        retry(1),
        catchError(this.handleError)

        // catchError(this.processError)
      )
  }

  hiveValidate(value: string) {
    return this.httpClient.get(DataSourceControllerService.url + "/hive/validate/" + value)
      .pipe(
        retry(1),
        catchError(this.handleError)

        // catchError(this.processError)
      )
  }
  hiveValidateTable(databaseName: string, tableName: string) {
    return this.httpClient.get(DataSourceControllerService.url + "/hive/validate/" + databaseName + "/" + tableName)
      .pipe(
        retry(1),
        catchError(this.handleError)

        // catchError(this.processError)
      )
  }
  testRDBMSConnector(data: any) {
    return this.httpClient.post(DataSourceControllerService.url + "/source/rdbms/test", (data))
      .pipe(
        retry(1),
        catchError(this.handleError)

        // catchError(this.processError)
      )
  }
  postRDBMSConnector(data: any): Observable<any> {
    return this.httpClient.post(DataSourceControllerService.url + "/source/rdbms", (data))
      .pipe(
        retry(1),
        catchError(this.handleError)

        // catchError(this.processError)
      )
  }
  updateRDBMSConnector(data: any) {
    return this.httpClient.put(DataSourceControllerService.url + "/source/rdbms", (data))
      .pipe(
        retry(1),
        catchError(this.handleError)

        // catchError(this.processError)
      )
  }

  deleteRDBMSConnector(id: string) {
    return this.httpClient.delete<RdbmsConnector>(DataSourceControllerService.url + '/source/rdbms/' + id)

      .pipe(
        retry(1),
        catchError(this.handleError)

        // catchError(this.processError)
      )

  }

  getQueryInfo(sql: string, id: string) {
    // console.log("GET QUERY COLUMNS")
    // console.log(sql)
    // console.log(id)
    // return this.httpClient.get(DataSourceControllerService.url + '/source/rdbms/query/' + id + '/' + sql)
    //   .pipe(
    //     retry(1),
    //     catchError(this.handleError)

    //     // catchError(this.processError)
    //   )
    return this.httpClient.post(DataSourceControllerService.url+'/source/rdbms/query/' + id+'/test',sql)
    .pipe(
        retry(1),
        catchError(this.handleError)

        // catchError(this.processError)
      )
  }


  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    Swal.fire({
      type: 'Failed',
      title: "Failed",
      text: error.error.message,
      confirmButtonText: 'Ok',
    })
    return throwError(errorMessage);
  }
  openSnackBar(status: string, statusLogo: string) {
    this.snackBar.open(status, statusLogo, {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['tertiary-active']

    });
  }



}
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormControl, NgForm, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import Swal from 'sweetalert2/dist/sweetalert2.js';

import { DataSourceControllerService } from '../../../../services/api/data-source/data-source-controller.service'
import { RdbmsConnector } from "../../../../models/rdbms/rdbms-connector.model"
import { ProjectService } from 'src/app/services/project/project-service.service';
import * as $ from 'jquery';
import { StreamingdataSourceService } from 'src/app/services/api/data-source/streamingdata-source.service';

declare var $: any;


@Component({
  selector: 'app-view-stream-details',
  templateUrl: './view-stream-details.component.html',
  styleUrls: ['./view-stream-details.component.scss']
})
export class ViewStreamDetailsComponent implements OnInit {

  constructor(public service: DataSourceControllerService, public router: Router, public formBuilder: FormBuilder,
    public projectService: ProjectService, private activatedRoute: ActivatedRoute,
    private snackbar: MatSnackBar, private streamingDataSourceService: StreamingdataSourceService
  ) {
  }

  id = this.activatedRoute.snapshot.params['id'];

  projectName = this.activatedRoute.snapshot.params['id2'];
  singleDataSource: any;

  ngOnInit(): void {
    this.getSingleDataSource();
  }

  //update connection

  getSingleDataSource() {
    this.streamingDataSourceService.getSingleStreamDataSource(this.id).subscribe(response => {
      console.log(response)
      this.singleDataSource = response;
    })
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {
  CommonModule,
  LocationStrategy,
  HashLocationStrategy,
} from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from '../httpConfigInterceptor/httpConfig.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { A11yModule } from '@angular/cdk/a11y';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { OverlayModule } from '@angular/cdk/overlay';
import { AuthGuard } from './guard/auth/auth.guard';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/projects/dashboard/home/home.component';
import { SideNavComponent } from './navigation/side-nav/side-nav.component';
import { NavigationComponent } from './navigation/navigation/navigation.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { DataSourceComponent } from './components/projects/data-sources/data-source/data-source.component';
import { ConfigureDataSourcesComponent } from './components/projects/data-sources/configure-data-sources/configure-data-sources.component';
import { CreateAndTestConnectionComponent } from './components/projects/data-sources/create-and-test-connection/create-and-test-connection.component';
import { JobsProgressComponent } from './components/projects/jobs-progress/jobs-progress.component';
import { SelectIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workflowGeneral/select-ingestion/select-ingestion.component';
import { NgxPaginationModule } from 'ngx-pagination';

import { IngestLandingPageComponent } from './components/projects/ingest/ingest-landing-page/ingest-landing-page.component';
import { DatabaseViewExecutionComponent } from './components/projects/ingest/rdbmsWorkFlow/workflowGeneral/database-view-execution/database-view-execution.component';
import { DatabaseViewDetailsComponent } from './components/projects/ingest/rdbmsWorkFlow/workflowGeneral/database-view-details/database-view-details/database-view-details.component';
import { AllProjectsComponent } from './components/projects/projects-landing-page/all-projects/all-projects.component';
import { CreateAndTestDb2Component } from './components/projects/data-sources/create-and-test-db2/create-and-test-db2.component';
import { CreateAndTestOracleComponent } from './components/projects/data-sources/create-and-test-oracle/create-and-test-oracle.component';
import { AuthComponent } from './auth/auth.component';
import { UserLandingPageComponent } from './components/userManagement/user-landing-page.component';
import { AccessLandingPageComponent } from './components/projects/access/access-landing-page.component';
import { ProjectsHomePageComponent } from './components/projects/projects-landing-page/projects-home-page/projects-home-page.component';
import { DragToSelectModule } from 'ngx-drag-to-select';
import { DatabaseViewValidationComponent } from './components/projects/ingest/rdbmsWorkFlow/workflowGeneral/database-view-validation/database-view-validation.component';
import { StepOneCustomIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/custom/step-one-custom-ingestion/step-one-custom-ingestion.component';
import { StepTwoCustomIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/custom/step-two-custom-ingestion/step-two-custom-ingestion.component';
import { StepThreeCustomIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/custom/step-three-custom-ingestion/step-three-custom-ingestion.component';
import { StepFourCustomIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/custom/step-four-custom-ingestion/step-four-custom-ingestion.component';
import { UserProfilePageComponent } from './components/profile/user-profile-page/user-profile-page.component';
import { ProfileComponent } from './components/profile/profile/profile.component';
import { MainProjectsHomepageComponent } from './components/home/main-projects-homepage.component';
import { BasicLayoutComponent } from './layouts/basic-layout/basic-layout.component';
import { BlankLayoutComponent } from './layouts/blank-layout/blank-layout.component';
import {
  NgbModule,
  NgbPopoverModule,
  NgbTooltipModule,
  NgbDatepickerModule,
  NgbPaginationModule,
  NgbDropdownModule,
  NgbCollapseModule,
  NgbModalModule,
} from '@ng-bootstrap/ng-bootstrap';
import { StepOneDatabaseIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/database/step-one-database-ingestion/step-one-database-ingestion.component';
import { StepTwoDatabaseIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/database/step-two-database-ingestion/step-two-database-ingestion.component';
import { StepThreeDatabaseIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/database/step-three-database-ingestion/step-three-database-ingestion.component';
import { RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './navigation/page-not-found/page-not-found.component';
import { EditStepOneCustomIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/edit/table/edit-step-one-custom-ingestion/edit-step-one-custom-ingestion.component';
import { EditStepTwoCustomIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/edit/table/edit-step-two-custom-ingestion/edit-step-two-custom-ingestion.component';
import { EditStepThreeCustomIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/edit/table/edit-step-three-custom-ingestion/edit-step-three-custom-ingestion.component';
import { EditStepFourCustomIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/edit/table/edit-step-four-custom-ingestion/edit-step-four-custom-ingestion.component';
import { EditStepOneDatabaseComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/edit/database/edit-step-one-database/edit-step-one-database.component';
import { EditStepTwoDatabaseComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/edit/database/edit-step-two-database/edit-step-two-database.component';
import { EditStepThreeDatabaseComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/edit/database/edit-step-three-database/edit-step-three-database.component';
import { ViewDetailsComponent } from './components/projects/data-sources/view-details/view-details.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { JobScheduleLandingPageComponent } from './components/projects/jobScheduler/job-schedule-landing-page/job-schedule-landing-page.component';
import { NewJobScheduleComponent } from './components/projects/jobScheduler/new-job-schedule/new-job-schedule.component';
import { EditScheduleComponent } from './components/projects/jobScheduler/edit-schedule/edit-schedule.component';
import { StepOneQueryIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/query/step-one-query-ingestion/step-one-query-ingestion.component';
import { StepTwoQueryIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/query/step-two-query-ingestion/step-two-query-ingestion.component';
import { StepThreeQueryIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/query/step-three-query-ingestion/step-three-query-ingestion.component';
import { StepFourQueryIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/query/step-four-query-ingestion/step-four-query-ingestion.component';
import { AceEditorModule } from 'ng2-ace-editor';
import { StepOneStreamDataSourceComponent } from './components/projects/data-sources/ConfigureStreamDataSource/step-one-stream-data-source/step-one-stream-data-source.component';
import { StepTwoStreamDataSourceComponent } from './components/projects/data-sources/ConfigureStreamDataSource/step-two-stream-data-source/step-two-stream-data-source.component';
import { StepOneStreamIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/stream/step-one-stream-ingestion/step-one-stream-ingestion.component';
import { StepTwoStreamIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/stream/step-two-stream-ingestion/step-two-stream-ingestion.component';
import { StepThreeStreamIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/stream/step-three-stream-ingestion/step-three-stream-ingestion.component';
import { StreamIngestionViewDetailsComponent } from './components/projects/ingest/rdbmsWorkFlow/workflowGeneral/stream-ingestion-view-details/stream-ingestion-view-details.component';
import { ViewStreamDetailsComponent } from './components/projects/data-sources/view-stream-details/view-stream-details.component';
import { EditStepOneStreamIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/edit/stream/edit-step-one-stream-ingestion/edit-step-one-stream-ingestion.component';
import { EditStepTwoStreamIngestionComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/edit/stream/edit-step-two-stream-ingestion/edit-step-two-stream-ingestion.component';
import { EditStepOneQueryComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/edit/query/edit-step-one-query/edit-step-one-query.component';
import { EditStepTwoQueryComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/edit/query/edit-step-two-query/edit-step-two-query.component';
import { EditStepThreeQueryComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/edit/query/edit-step-three-query/edit-step-three-query.component';
import { EditStepFourQueryComponent } from './components/projects/ingest/rdbmsWorkFlow/workFlow/edit/query/edit-step-four-query/edit-step-four-query.component';
import { DashboardNewComponent } from './components/projects/dashboard/dashboard-new/dashboard-new.component';
import { IngestDetailComponent } from './components/projects/dashboard/ingest-detail/ingest-detail.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SideNavComponent,
    NavigationComponent,
    DataSourceComponent,
    ConfigureDataSourcesComponent,
    CreateAndTestConnectionComponent,
    JobsProgressComponent,
    SelectIngestionComponent,
    IngestLandingPageComponent,
    DatabaseViewExecutionComponent,
    DatabaseViewDetailsComponent,
    AllProjectsComponent,
    CreateAndTestDb2Component,
    CreateAndTestOracleComponent,
    AuthComponent,
    UserLandingPageComponent,
    AccessLandingPageComponent,
    ProjectsHomePageComponent,
    DatabaseViewValidationComponent,
    StepOneCustomIngestionComponent,
    StepTwoCustomIngestionComponent,
    StepThreeCustomIngestionComponent,
    StepFourCustomIngestionComponent,
    UserProfilePageComponent,
    ProfileComponent,
    MainProjectsHomepageComponent,
    StepOneDatabaseIngestionComponent,
    StepTwoDatabaseIngestionComponent,
    StepThreeDatabaseIngestionComponent,
    PageNotFoundComponent,
    EditStepOneCustomIngestionComponent,
    EditStepTwoCustomIngestionComponent,
    EditStepThreeCustomIngestionComponent,
    EditStepFourCustomIngestionComponent,
    BlankLayoutComponent,
    BasicLayoutComponent,
    EditStepOneDatabaseComponent,
    EditStepTwoDatabaseComponent,
    EditStepThreeDatabaseComponent,
    ViewDetailsComponent,
    JobScheduleLandingPageComponent,
    NewJobScheduleComponent,
    EditScheduleComponent,
    StepOneQueryIngestionComponent,
    StepTwoQueryIngestionComponent,
    StepThreeQueryIngestionComponent,
    StepOneStreamDataSourceComponent,
    StepTwoStreamDataSourceComponent,
    StepOneStreamIngestionComponent,
    StepTwoStreamIngestionComponent,
    StepThreeStreamIngestionComponent,
    StreamIngestionViewDetailsComponent,
    ViewStreamDetailsComponent,
    EditStepOneStreamIngestionComponent,
    EditStepTwoStreamIngestionComponent,
    EditStepOneQueryComponent,
    EditStepTwoQueryComponent,
    EditStepThreeQueryComponent,
    StepFourQueryIngestionComponent,
    EditStepFourQueryComponent,
    DashboardNewComponent,
    IngestDetailComponent,
    
  ],
  imports: [
    BrowserModule,
    RouterModule,
    SortableModule.forRoot(),
    NgSelectModule,
    CommonModule,
    Ng2SearchPipeModule,
    FormsModule,
    AppRoutingModule,
    NgbModule,
    NgbPopoverModule,
    NgbTooltipModule,
    NgbDatepickerModule,
    NgbPaginationModule,
    NgbDropdownModule,
    NgbCollapseModule,
    NgbModalModule,
    BrowserAnimationsModule,
    A11yModule,
    ClipboardModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    OverlayModule,
    PortalModule,
    ScrollingModule,
    HttpClientModule,
    ReactiveFormsModule,
    DragToSelectModule.forRoot(),
    NgxPaginationModule,
    NgxSpinnerModule,
    AceEditorModule,
    // AutoCompleteModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    AuthGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

<div class="cm-content-blocks">
  <ol class="breadcrumb">
      <li><a routerLink="/home"><i class="fa fa-home"></i>Home</a></li>
      <li><a routerLink="/project"><i class="fa fa-folder-open"></i>Projects</a></li>
      <li><a routerLink="/project/{{projectName}}"><i class="fa fa-folder"></i>{{projectName}}</a></li>
      <li><a routerLink="/project/{{projectName}}/ingest"><i class="fa fa-filter mr-1"></i>Ingest</a></li>  
      <li  class="active"><i class="fa fa-eye mr-1"></i>View Details</li>
  </ol>
</div>


<h3 class="headc">
  Ingestion details - {{ingestObject.name}}
  <div class="subhead">View ingestion configuration and execution details</div>
</h3>


<div class="menublock position-relative">
  <a routerLink="/project/{{projectName}}/ingest">
    <span class="iconbtn pointerc closeabs">
      <i aria-hidden="true" class="fa fa-times"></i>
    </span>
  </a>
 
  <!--Configuration-->
  <article class="tabec">

    <nav class="tabbar navbar-light bg-faded">
      <div class="nav tabbar">
        <a class="tabitem nav-link active" data-toggle="tab" href="#tabone" >Configuration</a>
        <a class="tabitem nav-link" data-toggle="tab" href="#tabtwo">Execution</a>
      </div>
    </nav>
    
  
    <section class="tab-content relativepos">
    
      <div *ngIf="load" class="">
        <div class="loader"></div>
        <figure class="spinner-border loaderico"></figure>
    </div>
      <aside class="tab-pane active fifteenmt" id="tabone">
      
        <div *ngIf="ingestion">
          <ul ngbNav #nav="ngbNav" class="nav-tabs">
            <!--Configuration Info-->
            <li ngbNavItem>
              <a ngbNavLink>Ingestion Info</a>
              <ng-template ngbNavContent>

                <div class="inputtextb fifteenmb" id="tableid">

                  <section  class="inputc fieldtctext">
                      <label class="labeltext">Ingestion Name</label>
                      <div class="inputtext">{{checkforNull(ingestObject.name)}}</div>
                  </section>

                  <section  class="inputc fieldtctext">
                    <label class="labeltext">Ingestion Id</label>
                    <div class="inputtext">{{checkforNull(ingestion.id)}}</div>
                </section>

                  <section  class="inputc fieldtctext">
                    <label class="labeltext">Ingestion Description</label>
                    <div class="inputtext">{{checkforNull(ingestObject.description)}}</div>
                </section>

                <section  class="inputc fieldtctext">
                  <label class="labeltext">Ingestion Type</label>
                  <!-- <div class="inputtext">{{checkforNull(datatypeSelector(ingestObject.bulkImport))}}</div> -->
                  <div class="inputtext">{{checkforNull(ingestObject.ingestionType)}}</div>
               </section> 

                <section  class="inputc fieldtctext">
                  <label class="labeltext">Database Type</label>
                  <div class="inputtext">{{checkforNull(ingestion.databaseType)}}</div>
                </section> 
        
                <section  class="inputc fieldtctext">
                    <label class="labeltext">Username</label>
                    <div class="inputtext">{{checkforNull(ingestion.username)}}</div>
                </section> 
                
                <section  class="inputc fieldtctext">
                  <label class="labeltext">File Type</label>
                  <div class="inputtext">{{checkforNull(ingestion.fileType)}}</div>
              </section> 
              <section  class="inputc fieldtctext" *ngIf="ingestObject.query || ingestObject.query != null">
                <label class="labeltext">Query</label>
                <div class="inputtext">{{checkforNull(ingestion.query)}}</div>
              </section>
              <section  class="inputc fieldtctext" *ngIf="ingestObject.query || ingestObject.query != null && ingestObject.databases[0].tables[0].incremental != 'full load'">
                <label class="labeltext">Last Value</label>
                <div class="inputtext" *ngIf="!sqoopList">N/A</div>
                <div class="inputtext" *ngIf="sqoopList">{{checkforNull(sqoopList[0].lastValue)}}</div>
              </section> 
              <section  class="inputc fieldtctext" *ngIf="ingestObject.query || ingestObject.query != null && ingestObject.databases[0].tables[0].incremental != 'full load'">
                <label class="labeltext">New Last Value</label>
                <div class="inputtext" *ngIf="!sqoopList">N/A</div>
                <div class="inputtext" *ngIf="sqoopList">{{checkforNull(sqoopList[0].newLastValue)}}</div>
              </section> 
                </div>
                
              </ng-template>
            </li>
            <!--Ingestion Configurations-->
            <li ngbNavItem *ngIf="!ingestObject.query || ingestObject.query == null">
              <a ngbNavLink>Ingestion Configuration</a>
              <ng-template ngbNavContent>
                <section class="table-ec">
                  <div class=" fifteenmb"  id="tableid">	
                    <div class="clearafter">
                      <div class="fourtynineper matacco_ec float-left">
                        <h3 class="text-center tenpt">Source</h3>
                        <mat-accordion *ngFor="let database of ingestObject.databases">
                          <mat-expansion-panel hideToggle [expanded]="true">
                            <mat-expansion-panel-header>
                              <mat-panel-title>
                                <i class="fa fa-database mr-2"></i>
                                {{database.sourceDatabaseName}}
                              </mat-panel-title>
                              <mat-panel-description>
                              </mat-panel-description>
                            </mat-expansion-panel-header>
                              <mat-accordion *ngFor="let tables of database.tables">
                                <mat-expansion-panel hideToggle >
                                  <mat-expansion-panel-header>
                                    <mat-panel-title>
                                      <i class="fa fa-table mr-2"></i>

                                      {{tables.sourceTableName}}
                                    </mat-panel-title>
                                    <mat-panel-description>
                                    </mat-panel-description>
                                  </mat-expansion-panel-header>
                                  <table class="table table-borderless mr-table mb-0">
                                    <thead class="mr-table-head">
                                      <tr class="mr-table-head-row">
                                        <th>Column Name</th>
                                        <th>Source Data Type</th>

                                        <!-- <th>Primary Key</th>
                                        <th>Check Column</th> -->
                                      </tr>
                                    </thead>
                                    
                                    <tbody >
                                      <tr  class="mr-table-body-row mt-2" *ngFor="let column of tables.columns">
                                        <td>
                                          {{column.destinationColumnName}}
                                          <i class="fa fa-check text-success" *ngIf="column.checkColumn == true"></i>
                                          <i class="fa fa-key text-warning" *ngIf="column.primaryKey == true"></i>
                                        </td>
                                        <td>{{column.sourceDataType}}</td>

                                        <!-- <td>{{column.primaryKey}}</td>
                                        <td>{{column.checkColumn}}</td> -->
                                      </tr>
                                    </tbody>
                                  </table>
                                
                                </mat-expansion-panel>
                              </mat-accordion>
                          </mat-expansion-panel>
                        </mat-accordion>

                      </div>
                      <div class="fourtynineper float-right matacco_ec">
                        <h3 class="text-center tenpt">Destination</h3>
                        <!-- accordian -->
                        <div *ngIf="!sqoopList">
                          <mat-accordion *ngFor="let database of ingestObject.databases">
                            <mat-expansion-panel hideToggle  [expanded]="true">
                              <mat-expansion-panel-header>
                                <mat-panel-title>
                                  <i class="fa fa-database mr-2"></i>
  
                                  {{database.destinationDatabaseName}}
                                </mat-panel-title>
                                <mat-panel-description>
                                </mat-panel-description>
                              </mat-expansion-panel-header>
                                <mat-accordion *ngFor="let tables of database.tables">
                                  <mat-expansion-panel hideToggle >
                                    <mat-expansion-panel-header>
                                      <mat-panel-title>
                                        <i class="fa fa-table mr-2"></i>
                                        {{tables.destinationTableName}}
                                        <!-- <br>
                                        Ingestion Mode: {{capitalize(tables.incremental)}} | Incremental Last Value: {{tables.lastValue}} -->
                                      </mat-panel-title>
                                      <mat-panel-description>
                                        <span class="text-center">
                                          INGESTION MODE: {{tables.incremental.toUpperCase()}}
                                          <!-- <div *ngIf="tables.incremental !== 'full load'">INCREMENTAL LAST VALUE: 0 - {{checkLastValue(sqoop.lastValue)}}</div> -->
  
                                        </span>
                                      </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <!-- <div>Ingestion Mode: {{capitalize(tables.incremental)}} </div> -->
                                    <!-- <div *ngIf="tables.incremental !== 'full load'">Incremental Last Value: {{tables.lastValue}}</div> -->
                                    <table class="table table-borderless mr-table mb-0">
                                      <thead class="mr-table-head">
                                        <tr class="mr-table-head-row">
                                          <th>Column Name</th>
                                          <th>Destination Data Type</th>
                                          <!-- <th>Primary Key</th>
                                          <th>Check Column</th> -->
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr  class="mr-table-body-row mt-2" *ngFor="let column of tables.columns">
                                          <td>
                                            {{column.destinationColumnName}}
                                            <i class="fa fa-check text-success" *ngIf="column.checkColumn == true" ngbTooltip="Check Column"></i>
                                            <i class="fa fa-key text-warning" *ngIf="column.primaryKey == true" ngbTooltip="Primary Key"></i>
                                          </td>
  
                                          <td>{{column.destinationDataType}}</td>
                                          <!-- <td>{{column.primaryKey}}</td>
                                          <td>{{column.checkColumn}}</td> -->
                                        </tr>
                                      </tbody>
                                    </table>
                                  
                                  </mat-expansion-panel>
                                </mat-accordion>
                            </mat-expansion-panel>
                          </mat-accordion>
                        </div>
                        <div *ngIf="sqoopList">
                          <mat-accordion *ngFor="let sqoop of sqoopList">
                            <mat-expansion-panel hideToggle  [expanded]="true">
                              <mat-expansion-panel-header>
                                <mat-panel-title>
                                  <i class="fa fa-database mr-2"></i>
  
                                  {{sqoop.database.destinationDatabaseName}}
                                </mat-panel-title>
                                <mat-panel-description>
                                </mat-panel-description>
                              </mat-expansion-panel-header>
                                <mat-accordion *ngFor="let tables of sqoop.database.tables">
                                  <mat-expansion-panel hideToggle >
                                    <mat-expansion-panel-header>
                                      <mat-panel-title>
                                        <i class="fa fa-table mr-2"></i>
                                        {{tables.destinationTableName}}
                                        <!-- <br>
                                        Ingestion Mode: {{capitalize(tables.incremental)}} | Incremental Last Value: {{tables.lastValue}} -->
                                      </mat-panel-title>
                                      <mat-panel-description>
                                        <span class="text-center">
                                          INGESTION MODE: {{tables.incremental.toUpperCase()}}
                                          <div *ngIf="tables.incremental === 'increment by id'">INCREMENT INTERVAL: {{sqoop.lastValue}} - {{checkLastValue(sqoop.newLastValue)}}</div>
                                          <div *ngIf="tables.incremental === 'increment by timestamp'">INCREMENT INTERVAL: {{dateFormatter(sqoop.lastValue)}} - {{dateFormatter(checkLastValue(sqoop.newLastValue))}}</div>
                                        </span>
                                      </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <!-- <div>Ingestion Mode: {{capitalize(tables.incremental)}} </div> -->
                                    <!-- <div *ngIf="tables.incremental !== 'full load'">Incremental Last Value: {{tables.lastValue}}</div> -->
                                    <table class="table table-borderless mr-table mb-0">
                                      <thead class="mr-table-head">
                                        <tr class="mr-table-head-row">
                                          <th>Column Name</th>
                                          <th>Destination Data Type</th>
                                          <!-- <th>Primary Key</th>
                                          <th>Check Column</th> -->
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr  class="mr-table-body-row mt-2" *ngFor="let column of tables.columns">
                                          <td>
                                            {{column.destinationColumnName}}
                                            <i class="fa fa-check text-success" *ngIf="column.checkColumn == true" ngbTooltip="Check Column"></i>
                                            <i class="fa fa-key text-warning" *ngIf="column.primaryKey == true" ngbTooltip="Primary Key"></i>
                                          </td>
  
                                          <td>{{column.destinationDataType}}</td>
                                          <!-- <td>{{column.primaryKey}}</td>
                                          <td>{{column.checkColumn}}</td> -->
                                        </tr>
                                      </tbody>
                                    </table>
                                  
                                  </mat-expansion-panel>
                                </mat-accordion>
                            </mat-expansion-panel>
                          </mat-accordion>
                        </div>
         
                      </div>
                    </div>											 
                  </div> 
                </section> 
              </ng-template>
            </li>
            <!--Advanced Configurations-->
            <li ngbNavItem>
              <a ngbNavLink>Advanced Configuration</a>
              <ng-template ngbNavContent>
                <div class="formc inputtextb fifteenmb"  >
                  <section  class="inputc fieldtctext">
                      <label class="labeltext">Num-Mappers</label>
                      <div class="inputtext">{{checkforNull(ingestObject.numMappers)}}</div>
                  </section>
          
                  <section  class="inputc fieldtctext">
                      <label class="labeltext">Validate</label>
                      <div class="inputtext">{{checkforNull(ingestObject.validate)}}</div>
                  </section>
          
                  <section  class="inputc fieldtctext">
                      <label class="labeltext">Split-Limit</label>
                      <div class="inputtext">{{checkforNull(ingestObject.splitLimit)}}</div>
                  </section>
          
                  <section  class="inputc fieldtctext">
                      <label class="labeltext">Fetch Size</label>
                      <div class="inputtext">{{checkforNull(ingestObject.fetchSize)}}</div>
                  </section>
                </div>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav"></div>
        </div>
      </aside>
      
<!-- execution -->
      <aside class="tab-pane" id="tabtwo">
        <!-- <aside class="tenml twomt inline-block float-right">
          <button class="iconbtn jobrefresh" placement="left" ngbTooltip="Refresh" container="body" (click)="refresh()">
              <i class="fa fa-refresh" aria-hidden="true"></i>
          </button>
        </aside> -->

        <div class="tenpb clearafter">
          <aside class="tenml twomt inline-block float-right">
            <button class="iconbtn jobrefresh" placement="left" ngbTooltip="Refresh" container="body" (click)="refresh()">
                <i class="fa fa-refresh" aria-hidden="true"></i>
            </button>
          </aside>
        </div>

        <div class="position-relative clearafter loader_top_adj">
          

          <div class="" *ngIf="refreshStatus" >
            <div class="loader"></div>
            <figure class="spinner-border loaderico"></figure>
          </div>
          <table class="table table-borderless mr-table">
            <thead class="mr-table-head">
              <tr class="mr-table-head-row">
                <th  class="nametd-sml" *ngIf="!ingestObject.query || ingestObject.query == null">Source Table</th>
                <th  class="nametd-sml" *ngIf="ingestObject.query || ingestObject.query != null">Query Destination</th>
                <th class="datetimetd">Duration</th>
                <th class="rowcounttd">Row Count</th>
                <th class="progresstd">Progress</th>
                <th class="statustd">Status</th>
  
                <th class="actiontwotd">Actions</th>
              </tr>
            </thead>
            <tbody  *ngIf="!sqoopList" >
              <tr>
                <td colspan=100% class="text-center">This ingestion hasn't been executed</td>
              </tr>          
            </tbody>
            <tbody *ngIf="sqoopList">
              <tr class="mr-table-body-row" *ngFor="let item of sqoopList | paginate: { id: 'listing_pagination',
              itemsPerPage: 5,
              currentPage: page,
              totalItems: sqoopList.length }; let i = index ">
                <!-- <td>{{checkforNull(ingestions.runHistory)}}</td> -->
                <td *ngIf="!ingestObject.query || ingestObject.query == null">
                  <div class="descriptiontd breakword">{{checkforNull(item.database.tables[0].sourceTableName)}} </div>
                </td>
                <td *ngIf="ingestObject.query || ingestObject.query != null">
                  <div class="descriptiontd breakword">{{checkforNull(item.database.destinationDatabaseName)}} </div>
                </td>
                <!-- <td>
                  <div class="statustd">{{checkforNull(item.size)}} </div>
                </td> -->
                <td>
                  <div class=" descriptiontd breakword">{{miliConvert(item.duration)}}</div>
                </td>
                <td>
                  <div class="rowcounttd">{{checkforNull(item.rowCount)}}</div>
                </td>
                <td>
                  <div class="progress">
                    <div class="progress-bar" 
                      [class.progress-bar-success]="item.progress === 100" 
                      [class.progress-bar-info]="item.progress < 100"
                      [class.progress-bar-danger]="item.progress < 100 && item.status ==='FAILED_TO_LAUNCH'"
                      [class.progress-bar-.primary]="item.progress < 100 && item.status ==='NOT_LAUNCHED'"
                      role="progressbar"  aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" [style.width.%]="item.progress==0?3:item.progress">
                      <span> {{checkforNull( item.progress| number: '.0-0' )}}% </span>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="statustd">
                  <span class="" [class.info-s]='item.status === "RUNNING" || item.status === "IN_PROGRESS" || item.status === "PROCESSING_IN_HIVE"'   [class.warning-s]='item.status === "DISCARDED"' [class.success-s]='item.status === "FINISHED"' [class.danger-s]='item.status === "FAILED" || item.status === "FAILED_TO_LAUNCH" || item.status === "IN_PROGRESS_WITH_ERRORS"  || item.status === "STOPPED"' [class.primary-s]='item.status==="NOT_LAUNCHED"'>{{ item.status }}</span> 
                  </div>
                </td>
              
                <td>
                  <div class="icons-container actionthreetd">
                    <a ngbTooltip="View" placement="top" data-toggle="modal" data-target="#actions" (click)="getSingleSqoopById(item.id)"><i  class="fa fa-eye" aria-hidden="true" ></i></a>
                    <a ngbTooltip="Log" placement="top" data-toggle="modal" data-target="#log" (click)="passItemIdLog(item.id)"><i  class="fa fa-file-text-o" aria-hidden="true" ></i></a>
                  </div>
                </td>
              </tr>
            </tbody>
           
           
          </table>
          
        </div>
        <pagination-controls *ngIf="sqoopList.length > 5"  id="listing_pagination" class="pagination" (directionLinks)="true"
          (pageChange)="page = $event" (autoHide)="true" (responsive)="true" previousLabel="" disabled="true"
          nextLabel="">
        </pagination-controls>
      </aside>
      <div>
        
      </div>
     

    </section>
  </article>

</div>

<!--Modals-->
<!--Error Modal-->
<div class="modal" id="log">
  <div class="modal-dialog modal-lg">
    <div class="modal-content ">
    
      <!-- Modal Header -->
      <div class="modal-header">
        <h3 class="modal-title">Logs</h3>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <!-- Modal body -->
      <div class="modal-body break-all">
        <h3>Build Log</h3>
        {{logReport.buildLog}}
        <br><br>
        <h3>Sqoop Log</h3>
        {{logReport.sqoopLog}}
        <br><br>
        <h3>Hive Processing Log</h3>
        {{logReport.hiveProcessingLog}}
      </div>
      <!-- Modal footer -->
      <footer class="modalfooter">
        <button type="button" class="done-btn modalpubtn" data-dismiss="modal">Close</button>
    </footer>
    </div>
  </div>
</div>

<!--Action Modal-->
<div class="modal" id="actions">
  <div class="modal-dialog modal-lg">
    <div class="modal-content ">
    
      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Sqoop Command</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <!-- Modal body -->
      <div class="modal-body">
        <div class="break-all">
          {{sqoopCommand}}
        </div>
        <!-- Modal footer -->
      </div>
      <footer class="modalfooter">
        <button type="button" class="done-btn modalpubtn" data-dismiss="modal">Close</button>
      </footer>
    </div>
  </div>
</div>

<!--Updated Log Format | Waiting on Backend Code-->

<!-- <div class="row">  
  <article class="fifteenmb accordion_c">
    <div class="fifteenmb accordion_c" id="destination">
      <section class="card pull-left">
        <i class="fa fa-check-circle-o fa-2x text-success" aria-hidden="true"></i>
      </section>
      <section class="card pull-right">
        <div class="card-header header-row pointerc" id="headingTwo" data-toggle="collapse" data-target="#build">
          <h5 class="mb-0 innrcaption" >
            Build Log
            <i class="fa fa-chevron-down pull-right" aria-expanded="false" aria-controls="collapseOne"></i>
          </h5>
        </div>
        <div id="build" class="collapse show" aria-labelledby="headingTwo" data-parent="#destination">
          <div class="card-body">
            {{logReport.buildLog}}
          </div>
        </div>
      </section>
    </div>
  </article>
</div>
<div class="row">
  <article class="fifteenmb accordion_c">
    <div class="fifteenmb accordion_c" id="destination">
      <section class="card pull-left">
        <i class="fa fa-times-circle-o fa-2x text-danger" aria-hidden="true"></i>
      </section>
      <section class="card pull-right">
        <div class="card-header header-row pointerc" id="headingTwo" data-toggle="collapse" data-target="#sqoop">
          <h5 class="mb-0 innrcaption" >
            Sqoop Log
            <i class="fa fa-chevron-down pull-right" aria-expanded="false" aria-controls="collapseOne"></i>
          </h5>
        </div>
        <div id="sqoop" class="collapse" aria-labelledby="headingTwo" data-parent="#destination">
          <div class="card-body">
            {{logReport.sqoopLog}}
          </div>
        </div>
      </section>
    </div>
  </article>
</div>
<div class="row">
  <article class="fifteenmb accordion_c">
    <div class="fifteenmb accordion_c" id="destination">
      <section class="card pull-left">
        <i class="fa fa-circle-o fa-2x text-primary" aria-hidden="true"></i>
      </section>
      <section class="card pull-right">
        <div class="card-header header-row pointerc" id="headingTwo" data-toggle="collapse" data-target="#hive">
          <h5 class="mb-0 innrcaption" >
            Hive Processing Log
            <i class="fa fa-chevron-down pull-right" aria-expanded="false" aria-controls="collapseOne"></i>
          </h5>
        </div>
        <div id="hive" class="collapse" aria-labelledby="headingTwo" data-parent="#destination">
          <div class="card-body">
            {{logReport.postImportLog}}
          </div>
        </div>
      </section>
    </div>
  </article>
</div> -->

<div *ngIf="load" class="">
  <div class="loader"></div>
  <figure class="spinner-border loaderico"></figure>
</div>
<div class="cm-content-blocks">
  <ol class="breadcrumb">
    <li><a routerLink="/home"><i class="fa fa-home"></i>Home</a></li>
    <li><a routerLink="/project"><i class="fa fa-folder-open"></i>Projects</a></li>
    <li class="active"><i class="fa fa-folder"></i>{{projectName}}</li>
  </ol>
</div>
<div class="clearafter headbtnc">
  <h3 class="headc">Overview - {{projectName}}</h3>
  <div class="create-btn-position headbtnc">
    <button class="mr-btn-back pull-right pointerc" routerLink="/project">Back to Projects</button>
  </div>
</div>

<article class="db_boxec">
  <div class=" db_boxc">
    <a routerLink="/project/{{projectName}}/data-sources" class="boxlink boxbuttons " >
      <div class="db_box">
        <div class="cardico">
          <i class="fa fa-database" aria-hidden="true"></i>
        </div>
        <div class="cardcount">{{dataSources}}</div>
        <h4 class="cardtitle">Data Sources</h4>
      </div>
    </a>
  </div>
  <div class=" db_boxc">
    <a [routerLink]="['/project', projectService.getCurrentProject(), 'ingest']" class="boxlink boxbuttons ">
      <!-- <a routerLink="databaseSelection" class="boxlink"> -->
      <div class="db_box">
        <div class="cardico">
          <i class="fa fa-filter" aria-hidden="true"></i>
        </div>
        <div class="cardcount">{{ingestions}}</div>
        <h4 class="cardtitle">Ingestions</h4>
      </div>
    </a>
  </div>
  <div class=" db_boxc">
    <a routerLink="/project/{{projectName}}/jobs" class="boxlink boxbuttons ">
      <div class="db_box">
        <div class="cardico">
          <i class="fa fa-wrench" aria-hidden="true"></i>
        </div>
        <div class="cardcount">{{jobs}}</div>
        <h4 class="cardtitle">Jobs</h4>
      </div>
    </a>
  </div>

  <div class=" db_boxc">
    <a routerLink="/project/{{projectName}}/job-schedule-landing-page" class="boxlink boxbuttons ">
      <div class="db_box">
        <div class="cardico">
          <i class="fa fa-calendar" aria-hidden="true"></i>
        </div>
        <div class="cardcount">{{schedules?.length}}</div>
        <h4 class="cardtitle">Schedules</h4>
      </div>
    </a>
  </div>
</article>
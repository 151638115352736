import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Config } from '../../config';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

export interface UserDetails {
  email: string,
  id: string,
  password: string,
  permissions: [
    {
      permission: string,
      projectName: string,
    }
  ],
  role: {
    name: string,
    permissions: [
      {
        permission: string,
        projectName: string,
      }
    ]
  },
  username: string,

}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient: HttpClient) { }
  static url = Config.url;

  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  addUserAvatar(username: string, image: string): Observable<any> {
    return this.httpClient.put<any>(UserService.url + '/user/' + username + '/image', '{ "image": "' + image + '"}')
      .pipe(
        retry(1),
        catchError(this.handleError)

      )
  }

  createUser(data: any): Observable<UserDetails> {
    return this.httpClient.post<UserDetails>(UserService.url + '/user', data)
      .pipe(
        retry(1),
        catchError(this.handleError)

      )
  }

  getAllUsers(): Observable<any[]> {
    return this.httpClient.get<any[]>(UserService.url + '/users')
      .pipe(
        retry(1),
        catchError(this.handleError)

      )
  }
  getUserByUserName(username: string): Observable<any> {
    return this.httpClient.get<any>(UserService.url + '/user/' + username)
  }

  updateUser(data: any) {
    return this.httpClient.put<UserDetails>(UserService.url + '/user', data)
      .pipe(
        retry(1),
        catchError(this.handleError)

      )
  }
  deleteUser(username: string) {
    return this.httpClient.delete<UserDetails>(UserService.url + '/user/' + username)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    Swal.fire({
      type: 'Failed',

      title: "Failed",
      text: error.error.message,
      confirmButtonText: 'Ok',
    })
    return throwError(errorMessage);
  }
}

<!-- <div *ngIf="load" class="">
    <div class="loader"></div>
    <figure class="spinner-border loaderico"></figure>
  </div> -->
  
  <div class="cm-content-blocks">
    <ol class="breadcrumb">
        <li><a routerLink="/project/{{projectName}}"><i class="fa fa-home"></i>Home</a></li>
        <li><a routerLink="/project"><i class="fa fa-folder-open"></i>Projects</a></li>
        <li><a routerLink="/project/{{projectName}}"><i class="fa fa-folder"></i>{{projectName}}</a></li>
        <li  class="active"><i class="fa fa-database mr-1"></i>View Data Source Details</li>  
    </ol>
</div>

<div class="clearafter headbtnc">
  <h3 class="headc">
      Data Source Details - {{projectName}}
      <div class="subhead">View and confirm stream data source details</div>
    <div class="create-btn-position headbtnc">
      <button class="mr-btn-back pull-right pointerc" routerLink="/project/{{projectName}}/data-sources">
        Back to Data Sources
      </button>
    </div>
  </h3>

  

</div>

<div class="formc inputtextb fifteenmb"  >
  <section  class="inputc fieldtctext">
      <label class="labeltext">ID</label>
      <div class="inputtext">{{singleDataSource.id}} </div>
  </section>
  <section  class="inputc fieldtctext">
    <label class="labeltext">Name</label>
    <div class="inputtext">{{singleDataSource.name}} </div>
</section>

<section  class="inputc fieldtctext">
  <label class="labeltext">Description</label>
  <div class="inputtext">{{singleDataSource.description}} </div>
</section>


  <section  class="inputc fieldtctext">
      <label class="labeltext">Topic</label>
      <div class="inputtext">{{singleDataSource.topic}} </div>
  </section>

  <!-- <section  class="inputc fieldtctext">
      <label class="labeltext"> Description </label>
      <div class="inputtext"> {{singleDataSource.topic}} </div>
  </section> -->

  <section  class="inputc fieldtctext">
      <label class="labeltext"> Hostname </label>
      <div class="inputtext">{{singleDataSource.hostname}} </div>
  </section>
  <section  class="inputc fieldtctext">
      <label class="labeltext"> Port </label>
      <div class="inputtext">{{singleDataSource.port}} </div>
  </section>
  
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { RdbmsIngestionControllerService } from './services/api/rdbms-controller/rdbms-ingestion-controller.service';
@Injectable({
  providedIn: 'root',
})
export class IngestionSharingServiceService {
  constructor(private rdbmsService: RdbmsIngestionControllerService) {}
  private stepOneData: any;
  private stepTwoData: any;
  public stepThreeDataObject:any

  //database and table ingestion
  setStepOneData(data: any) {
    localStorage.setItem('stepOne', JSON.stringify(data));
  }
  getStepOneData() {
    let data = localStorage.getItem('stepOne');
    return JSON.parse(data);
  }
  setStepTwoData(data: any) {
    localStorage.setItem('stepTwo', JSON.stringify(data));
  }
  getStepTwoData() {
    let data = localStorage.getItem('stepTwo');
    return JSON.parse(data);
  }
  setStepTwoCopyData(data: any) {
    localStorage.setItem('stepTwoCopy', JSON.stringify(data));
  }
  getStepTwoCopyData() {
    let data = localStorage.getItem('stepTwoCopy');
    return JSON.parse(data);
  }

  //query
  setStepOneQueryData(data: any) {
    localStorage.setItem('stepOneQuery', JSON.stringify(data));
  }
  // stepThreeData(data:any){
  //   this.stepThreeDataObject=data;

  // }
  setStepThreeData(data:any){
    localStorage.setItem('stepThreeData', JSON.stringify(data));

  }
  getStepThreeData(){
   let data= localStorage.getItem('stepThreeData');
   return JSON.parse(data);
  }
  getStepOneQueryData() {
    let data = localStorage.getItem('stepOneQuery');
    return JSON.parse(data);
  }
  setStepTwoQueryData(data: any) {
    localStorage.setItem('stepTwoQuery', JSON.stringify(data));
  }
  getStepTwoQueryData() {
    let data = localStorage.getItem('stepTwoQuery');
    return JSON.parse(data);
  }

  setStepThreeQueryData(data: any) {
    localStorage.setItem('stepThreeQuery', JSON.stringify(data));
  }

  getStepThreeQueryData() {
    let data = localStorage.getItem('stepThreeQuery');
    return JSON.parse(data);
  }

  setQuerySQLStatement(data: any) {
    localStorage.setItem('querySQLStatement', JSON.stringify(data));
  }
  getQuerySQLStatement() {
    let data = localStorage.getItem('querySQLStatement');
    return JSON.parse(data);
  }
  //streaming data source
  setStepOneStreamingDataSourceData(data: any) {
    localStorage.setItem('stepOneStreamingDataSource', JSON.stringify(data));
  }
  getStepOneStreamingDataSourceData() {
    let data = localStorage.getItem('stepOneStreamingDataSource');
    return JSON.parse(data);
  }
  setStepTwoStreamingDataSourceData(data: any) {
    localStorage.setItem('stepTwoStreamingDataSource', JSON.stringify(data));
  }
  getStepTwoStreamingDataSourceData() {
    let data = localStorage.getItem('stepTwoStreamingDataSource');
    return JSON.parse(data);
  }
  setStepTwoStreamingKafkaStatement(data: any) {
    localStorage.setItem(
      'stepTwoStreamingKafkaStatement',
      JSON.stringify(data)
    );
  }
  getStepTwoStreamingKafkaStatement() {
    let data = localStorage.getItem('stepTwoStreamingKafkaStatement');
    return JSON.parse(data);
  }

  //streaming workflow
  setStepOneStreamData(data: any) {
    localStorage.setItem('stepOneStream', JSON.stringify(data));
  }
  getStepOneStreamData() {
    let data = localStorage.getItem('stepOneStream');
    return JSON.parse(data);
  }
  setStepTwoStreamData(data: any) {
    localStorage.setItem('stepTwoStream', JSON.stringify(data));
  }
  getStepTwoStreamData() {
    let data = localStorage.getItem('stepTwoStream');
    return JSON.parse(data);
  }

  //profile picture avatar
  getAvatarImg() {
    let data = localStorage.getItem('img');
    return JSON.parse(data);
  }
  setAvatarImg(data: string) {
    localStorage.setItem('img', JSON.stringify(data));
  }
}

<div class="cm-content-blocks">
    <ol class="breadcrumb">
        <li><a routerLink="/home"><i class="fa fa-home"></i>Home</a></li>
        <li><a routerLink="/project"><i class="fa fa-folder-open"></i>Projects</a></li>
        <li><a routerLink="/project/{{projectName}}"><i class="fa fa-folder"></i>{{projectName}}</a></li>
        <li><a routerLink="/project/{{projectName}}/data-sources"><i class="fa fa-database mr-1"></i>Data Sources</a></li>
        <li><a routerLink="/project/{{projectName}}/data-sources/select-database"><i class="fa fa-sitemap mr-1"></i>Configure Data Source</a></li>
        <li  class="active"><i class="fa fa-spinner mr-1"></i>{{capitalize(databaseType)}}</li> 
    </ol>
  </div>
<div class="clearafter headbtnc">
    <h3 class="headc mt-4">
    Configure {{capitalize(databaseType)}} Data Source
    <!-- <div class="subhead">Create new database connection </div> -->
    </h3>
    <div class="back-btn-position headbtnc">
        <button class="mr-btn-back pointerc" data-toggle="modal" data-target="#projectModal" routerLink="/project/{{projectName}}/data-sources/select-database" >        
            Back to Configure Data Source
        </button>
    
    </div>
</div>

<div class="" *ngIf="load">
    <div class="loader"></div>
    <figure class="spinner-border loaderico"></figure>
  </div>

<section class="menublock position-relative close_btn_page">
<!-- test connection form -->
    <form [formGroup]="dataSourceCredentials" (ngSubmit)="postConnection()">
        <div class="form-group">
        <input formControlName="id" type="hidden" class="form-control">
        </div>
        
        <section class="inputc" >
            <aside class="col-md-6 fieldc" [ngClass]="dataSourceCredentials.controls['name'].touched && dataSourceCredentials.controls['name'].errors?'inputc-error':''">
                <label class="inputlabel label-asterisk">Data Source Name </label>
                <input class="input-field" formControlName="name" type="text"   name="name" required>
                <small style="color: #a94442;"
                *ngIf="dataSourceCredentials.controls['name'].invalid && (dataSourceCredentials.controls['name'].dirty || dataSourceCredentials.controls['name'].touched)">
                Data Source Name is required.
            </small>
            </aside>  
            
            <aside class="col-md-6 fieldc">
                <label class="inputlabel">Data Source Description </label>
                <!-- <input class="input-field" formControlName="description" type="text"   name="description"> -->
                <textarea formControlName="description" type="text"   name="description"  class="input-field" rows="4"></textarea> 

                <!-- <span class="error-mge mt-2"
                *ngIf="dataSourceCredentials.controls['description'].invalid && (dataSourceCredentials.controls['description'].dirty || dataSourceCredentials.controls['description'].touched)">
                Data Source Description is required.
              </span> -->
            </aside>  

           <!--ngIf code-->
            <!--*ngIf="driver.invalid && (driver.dirty || driver.touched)" class="alert alert-danger"-->
            <!--*ngIf="driver.errors.required"-->

          
        </section>
    

        <section class="inputc" >
            <aside class="col-md-6 fieldc" [ngClass]="dataSourceCredentials.controls['hostName'].touched && dataSourceCredentials.controls['hostName'].errors?'inputc-error':''">
                <label class="inputlabel label-asterisk">Hostname </label>
                <input class="input-field" formControlName="hostName" type="text"  >
                
                    <div class="error-mge mt-2" *ngIf="dataSourceCredentials.controls['hostName'].invalid && (dataSourceCredentials.controls['hostName'].dirty || dataSourceCredentials.controls['hostName'].touched)">
                        Host Name is required
                    </div>
            </aside>  
          
            <aside class="col-md-6 fieldc" [ngClass]="dataSourceCredentials.controls['port'].touched && dataSourceCredentials.controls['port'].errors?'inputc-error':''">
                <label class="inputlabel label-asterisk">Port </label>
                <input class="input-field" formControlName="port" type="text"  >             
                <div *ngIf="(dataSourceCredentials.controls['port'].dirty || dataSourceCredentials.controls['port'].touched)">
                    <div class="error-mge mt-2" *ngIf="dataSourceCredentials.controls['port'].errors && dataSourceCredentials.controls['port'].errors.required">
                        Port is required
                    </div>
                    <div class="error-mge mt-2" *ngIf="dataSourceCredentials.controls['port'].errors && dataSourceCredentials.controls['port'].errors.pattern">
                        Invalid port number
                    </div>
                </div>
            </aside>
        </section>

        <section class="inputc">

            <aside class="col-md-6 fieldc" [ngClass]="dataSourceCredentials.controls['username'].touched && dataSourceCredentials.controls['username'].errors?'inputc-error':''">
                <label class="inputlabel label-asterisk">Username</label>
                <input class="input-field" formControlName="username" type="text" >
                
                    <div class="error-mge" *ngIf="dataSourceCredentials.controls['username'].invalid && (dataSourceCredentials.controls['username'].dirty || dataSourceCredentials.controls['username'].touched)" >
                        Username is required
                    </div>
                
            </aside>

            <aside class="col-md-6 fieldc">
                <div class="input-group mb-3">
                    <label for="" class="inputlabel">Password </label>
                    <input type="text" class="form-control input-field"  formControlName="password" type="password" [type]="hide ? 'password':'text'" >
                    <div class="input-group-append">
                      <span class="input-group-text bg-light" id="basic-addon2">    
                          <i class="{{hide?'fa fa-eye-slash':'fa fa-eye'}}" (click)="hide = !hide"></i>
                      </span>
                    </div>
                  </div>
                  <!-- <div class="error-mge" *ngIf="dataSourceCredentials.controls['password'].invalid && (dataSourceCredentials.controls['password'].dirty || dataSourceCredentials.controls['password'].touched)" >                
                        Password is required    
                  </div> -->
            </aside>
            
        </section>
   
        
        <!-- Saved connections -->

        <!-- <section class="inputc" >
            <aside class="col-md-6 fieldc" >
                <label class="inputlabel">Or select from your saved connections:</label>
                <mat-form-field appearance="fill">
                <mat-label></mat-label>
                <mat-select>
                    <mat-option class="mr-4" *ngFor="let item of rdbmsConnectorList" (click)="populateForm(item)">
                    {{item.name}}   
                    </mat-option>
                </mat-select>  
                </mat-form-field>
            </aside>
        </section> -->
                
        
            
        <div class="graybdrt fifteen_pt fifteen_pb clearafter">
           
            
            <button class="tertiary-active fr" click="submit" [disabled]="testSuccessful === false">
                <span>Save connection</span>
            </button>
            <button class="tertiary-active fr" type="button" (click)="testConnection()" [disabled]="dataSourceCredentials.invalid">
                <span class="mr-2">Test connection</span>
            </button> 
            <button class="tertiary-active fr" type="button" (click)="clearForm()" >Clear</button>      
        </div>

        
    <!-- <button (click)="loadData()" class="btn btn-danger btn-block " type="button">Load Data</button> -->
        
    </form>

</section>
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../../config';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  static url = Config.url + '/admin';
  timezone;
  constructor(private http: HttpClient) { }

  list() {
    return this.http.get(AdminService.url);
}
get() {

}
env(){
  return this.http.get(AdminService.url+"/env");
}

config() {
  return this.http.get(AdminService.url+"/config");

}
public_Config(){
  return this.http.get(AdminService.url+"/public_config");
}
publicConfig(publicConfig){

  return this.http.get(AdminService.url+"/"+publicConfig);

}
cleanStorage() {

  return this.http.delete(AdminService.url+"/storage");

}

updateConfig(config) {

  return this.http.put(AdminService.url+"/config",config);

}
setTimeZone(timezone){
  this.timezone=timezone;
}
getTimeZone(){
  return this.timezone;
}
}

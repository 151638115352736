
<div class="cm-content-blocks">
  <ol class="breadcrumb">
    <li><a  routerLink="/home" ><i class="fa fa-home"></i>Home</a></li>
    <li><a routerLink="/project"><i class="fa fa-folder-open"></i>Projects</a></li>
    <li><a routerLink="/project/{{projectName}}"><i class="fa fa-folder"></i>{{projectName}}</a></li>
    <li class="active"><i class="fa fa-lock"></i>Access</li>
  </ol>
</div>

<div class="clearafter headbtnc">
  <h3 class="headc">
    Access - {{projectName}}
     <div class="subhead">Assign users to this project</div> 
    <div class="create-btn-position headbtnc">
      <button class="mr-btn-create pull-right pointerc" data-toggle="modal" data-target="#access">
        Add New Access
      </button>
    </div>
  </h3> 
</div>

<section class="table-ec">
  <div class="table-wrapper position-relative loader_top_adj">
    <div *ngIf="load" class="">
      <div class="loader"></div>
      <figure class="spinner-border loaderico"></figure>
    </div>
    <div class=" fifteenmb"  id="tableid">												
      <table class="table table-borderless mr-table">
        <thead class="mr-table-head">
          <tr class="mr-table-head-row">
            <th>Name</th>
            <th>Access</th>
            <th class="actionthreetd">Actions</th>
          </tr>
        </thead>
        <tbody  *ngIf="permittedUserList.length == 0" >
          <tr>
            <td colspan=100% class="text-center">No users have been created</td>
          </tr>          
        </tbody>

        <tbody *ngIf="permittedUserList.length > 0">
          <tr class="mr-table-body-row" *ngFor="let item of permittedUserList | paginate: { id: 'listing_pagination',
          itemsPerPage: 10,
          currentPage: page,
          totalItems: permittedUserList.length }; let i = index ">
            <td>{{item.username}}</td>
            <td>{{item.currProjectPermission.split("_")[1]}}</td>
            <td>
              <div class="icons-container actionthreetd">
                <!-- <i  class="fa fa-pencil pointerc" aria-hidden="true" ngbTooltip="Edit Assignment" placement="left" data-toggle="modal" data-target="#exampleModalCenter"></i> -->
                <i  (click)="deleteUser(item.username)" class="fa fa-trash pointerc" aria-hidden="true" ngbTooltip="Delete Assignment" placement="left"></i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
       <!-- <ngb-pagination *ngIf="permittedUserList.length > 10" class="pagination float-right" [collectionSize]="permittedUserList.length" [(page)]="page" aria-label="Default pagination" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>   -->
      <pagination-controls *ngIf="permittedUserList.length > 10" id="listing_pagination" class="pagination" (directionLinks)="true"
      (pageChange)="page = $event" (autoHide)="true" (responsive)="true" previousLabel="" disabled="true"
      nextLabel="">
  </pagination-controls>
    </div>
   <!-- <ngb-pagination class="pagination" [collectionSize]="70" [(page)]="page" aria-label="Default pagination"></ngb-pagination> -->
  </div>
</section> 


<!--Modals-->

<!-- Edit Modal -->
<div class="modal" id="access">
    <div class="modal-dialog">
      <div class="modal-content ">
      
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Grant Project Access</h4>
          <button type="button" class="close" data-dismiss="modal" >&times;</button>
        </div>
        <!-- Modal body -->
        <form [formGroup]="assignUser"  (ngSubmit)="assignUserPermission(assignUser)">
        <div class="modal-body">
          <div class="inputc ">
            <div class="col-md-12 fieldc" [ngClass]="assignUser.controls['username'].touched && assignUser.controls['username'].errors?'inputc-error':''">
              <label class="inputlabel">User <span class="text-danger">*</span></label> 
              <div>
                <select class="selectoption" formControlName="username" >
                  <option [selected]="true"> Select User  </option>                  
                  <option *ngFor="let item of userList" >{{item.username}}</option>
                </select>
              </div>
              <span class="error-mge zeroleftimp"
                  *ngIf="assignUser.controls['username'].invalid && (assignUser.controls['username'].dirty || assignUser.controls['username'].touched)">
                  User Name is required.
              </span>  
                  
            </div>
          </div>
          <div class="inputc">
            <div class="col-md-12 fieldc" [ngClass]="assignUser.controls['permission'].touched && assignUser.controls['permission'].errors?'inputc-error':''">
              <label class="inputlabel">Permission <span class="text-danger">*</span></label> 
              <div>
                <select class="selectoption" formControlName="permission" >
                  <option [selected]="true"> Select Permission </option>                  
                    <option *ngFor="let perm of permissions" >{{perm.perm}}</option>
                </select>
              </div>
              <span class="error-mge zeroleftimp"
              *ngIf="assignUser.controls['permission'].invalid && (assignUser.controls['permission'].dirty || assignUser.controls['permission'].touched)">
              Permission is required.
            </span>  
            </div>
          </div>
      </div>
      
        <!-- Modal footer -->
        <footer class="modalfooter">
          <button type="button" class="done-btn modalpubtn" data-dismiss="modal" >Cancel</button>
            <button  class="done-btn modalpubtn" [disabled]="assignUser.invalid">Save </button>
        </footer>
      </form>
      </div>
    </div>
</div>

<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalCenterTitle">Edit Connection</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                  </button>
      </div>
      <div class="modal-body">
          <form [formGroup]="assignUser" (ngSubmit)="updateUserAccess(assignUser.value, projectName,assignUser.value.username, assignUser.value.permission)" >
            
              <section class="inputc" >
                <aside class="col-md-6 fieldc" >
                  <label class="inputlabel">Username</label>
                  <input class="input-field" type="text" 
                  formControlName="username" 
                  >
                
                </aside>
                  <aside class="col-md-6 fieldc" >
                    <select class="selectoption" formControlName="permission">
                      <label class="inputlabel">Permitted Users</label>
                      <option >Select User</option>
                        <option formControlName="permission" ></option>
                    </select>
                     
                  </aside>
          
             
              </section>
      
          


              <footer  class="modalfooter">
                <button type="button" class="done-btn modalpubtn" data-dismiss="modal">Cancel</button>
                <button type="submit" class="done-btn modalpubtn" >Save</button>
              </footer>
      
    </form>
  </div>


    </div>
  </div>
</div>
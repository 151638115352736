import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormControl, NgForm, FormGroup, FormBuilder, Validators, FormArray, ReactiveFormsModule } from '@angular/forms';
import { DataSourceControllerService } from 'src/app/services/api/data-source/data-source-controller.service';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { CatalogService } from "../../../../../../../services/api/catalogService/catalog.service"
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { CdkDragDrop, moveItemInArray, transferArrayItem, copyArrayItem } from '@angular/cdk/drag-drop';
import { RdbmsIngestionControllerService } from 'src/app/services/api/rdbms-controller/rdbms-ingestion-controller.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from "ngx-spinner";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import { IngestionSharingServiceService } from 'src/app/ingestion-sharing-service.service';
import * as $ from 'jquery';
import { ProjectService } from 'src/app/services/project/project-service.service';
import { finalize } from 'rxjs/operators';

export interface database {
  destinationDatabaseName: string,

  sourceDatabaseName: string,
  tables: tables[];

}

export interface tables {
  destinationTableName: string,

  sourceTableName: string,
  tableId: string,
  schema?: string,
  incremental?: string,

  columns: columns[];

}

interface toggle {
  name: string;
}

export interface columns {
  sourceColumnName: string,
  destinationColumnName: string,

  sourceDataType: string,
  destinationDataType: string,
  primaryKey: boolean
  checkColumn: boolean

}
@Component({
  selector: 'app-step-two-custom-ingestion',
  templateUrl: './step-two-custom-ingestion.component.html',
  styleUrls: ['./step-two-custom-ingestion.component.scss']
})
export class StepTwoCustomIngestionComponent implements OnInit, AfterViewInit {
  count: number[] = []

  lastDatabase: boolean
  lastTable: boolean
  lastColumn: boolean

  //snackbar 

  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  //datasource

  singleDataSource: any

  itemObjectsLeft: database[] = [];
  itemObjectsRight: database[] = [];
  itemObjectsRightVisual: database[] = [];

  itemArrayOne: database[] = [];
  itemArrayTwo: database[] = [];

  term: string
  dataSources: any;
  dataSourceData: any;

  //source and destination fields
  sourceDatabases: database[] = []
  sourceDatabasesTwo: any = []

  sourceTables: any = []
  sourceTablesTwo: any = []
  sourceColumns: any = []

  dataObject: any;
  //treeview fields
  isActive: boolean;
  tableIsActive: boolean;
  columnIsActive: boolean;
  isActiveDestination: boolean;
  tableIsActiveDestination: boolean;
  columnIsActiveDestination: boolean;
  showToggle: any = [];

  status = false;
  buttonEnable: boolean = false;

  //loader

  database: any = []
  allDatabases: any = []
  allTables: database[] = []
  columns: any = []

  toggle: any = [];

  //disable next button until item is dropped
  itemDropped: boolean = false;

  //redo fields
  redoTable: any = []
  redoColumn: any = []

  redoTablePositions: any = []
  redoObjectForPositons: any;
  toggleList(name: string) {

    this.toggle[name] = !this.toggle[name]
  }

  load: boolean = true;


  isSubmitShow: boolean = false;
  id = this.activatedRoute.snapshot.params['id'];


  ngAfterViewInit() {
    $(document).ready(function () {
      var contenth = $(window).height() - 110;
      var sidebarh = $(window).height() - 111;
      $(".pagec").css("height", contenth);
      $(".sidebar-wrapper").css("height", sidebarh);

      function dropheight() {
        var contenth = $(window).height() - 110;
        var dropheight = contenth - 220;
        $(".dragdrop_height").css("max-height", dropheight);
      }
      setTimeout(dropheight, 2);

    });

    $(window).resize(function () {
      function dropheight() {
        var contenth = $(window).height() - 110;
        var dropheight = contenth - 220;
        $(".dragdrop_height").css("max-height", dropheight);
      }
      setTimeout(dropheight, 2);
    })
  }

  constructor(private formBuilder: FormBuilder, private dataSourceController: DataSourceControllerService,
    private catalogService: CatalogService, public service: RdbmsIngestionControllerService, public router: Router,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    public projectService: ProjectService,
    private ingestionService: IngestionSharingServiceService,
    public spinnerService: NgxSpinnerService

  ) { }

  projectName = this.activatedRoute.snapshot.params['id2'];

  //STEPPER

  showDiv = {
    stepone: false,
    steptwo: true,
    stepthree: false,
    stepfour: false,
    stepfive: false,

    stepbtnone: true,
    stepbtntwo: false,
    stepbtnthree: false,
    stepbtnfour: false,
    stepbtnfive: false,

    cancelbtn: false,
    nextbtn: true,
    nextbtntwo: false,
    nextbtnthree: false,
    nextbtnfour: false,

    backbtn: false,
    backbtntwo: false,
    backbtnthree: false,
    backbtnfour: false,
    backbtnfive: false,

    submitbtn: false
  }



  //grab databases 
  getDatabase(id: string) {
    this.catalogService.getCatalogforDatabases(id).subscribe(databaseResponse => {
      let data: any = databaseResponse
      this.database = data.sourceDatabases;
      this.getTable()
    })
    setTimeout(() => this.load = false, 5000)

  }

  getTable() {

    this.database.map(database => {
      let totalCount = 0;

      this.catalogService.getCatalog('tables/' + database.databaseId).subscribe(databasesWithTables => {

        let tableData: any = []
        tableData = databasesWithTables

        this.allDatabases.push(databasesWithTables)

        delete this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].id
        this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].destinationDatabaseName = this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].sourceDatabaseName
        let count = 0;

        tableData.tables.map(index => {
          let start = performance.now()

          this.catalogService.getCatalog('columns/' + index.tableId).toPromise().then(columnsFromTables => {
            let data: any = columnsFromTables
            // tableData.tables = data.columns
            // console.log(tableData.tables[tableData.tables.indexOf(index)].columns)
            this.columns = tableData
            this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables[this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables.indexOf(index)].destinationTableName = data.tableName
            this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables[this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables.indexOf(index)].columns = data.columns
            this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables[this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables.indexOf(index)].incremental = "full load"
            this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables[this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables.indexOf(index)].schema = data.schema

            this.allTables.push({
              destinationDatabaseName: database.sourceDatabaseName, sourceDatabaseName: database.sourceDatabaseName,
              tables: [this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables[this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables.indexOf(index)]]
            })



            data.columns.map(columnIndex => {

              // this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables[this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables.indexOf(index)].columns[this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables[this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables.indexOf(index)].columns.indexOf(columnIndex)].destinationDataType = this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables[this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables.indexOf(index)].columns[this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables[this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables.indexOf(index)].columns.indexOf(columnIndex)].sourceDataType

              this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables[this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables.indexOf(index)].columns[this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables[this.allDatabases[this.allDatabases.indexOf(databasesWithTables)].tables.indexOf(index)].columns.indexOf(columnIndex)].checkColumn = false

            })

          })
          let end = performance.now()
          let time = end - start
          count += time

        })

      })

    })

  }

  databaseSorter(database: any) {
    return database.sort((a: any, b: any) => (a.length < b.length) ? 1 : -1)
  }

  //drag and drop 
  dropDestination(event: CdkDragDrop<database[]>) {
    // this.load = true;

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      this.itemObjectsRight.splice(event.currentIndex)
      this.ingestionService.setStepTwoData(this.itemObjectsRight)
      this.ingestionService.setStepTwoCopyData(this.itemObjectsRight);

    }



  }

  drop(event: CdkDragDrop<database[]>) {

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      copyArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      this.itemDropped = true;

      this.mergeObjects(this.itemObjectsRight)
      this.ingestionService.setStepTwoData(this.itemObjectsRight)
      this.ingestionService.setStepTwoCopyData(this.itemObjectsRight);



    }

  }
  //combine tables if databases are the same 

  mergeObjects(array: any) {

    for (let i = 0; i < array.length; i++) {
      for (let j = 1; j < (array.length - i); j++) {

        if (array[j - 1].sourceDatabaseName == array[j].sourceDatabaseName) {
          let newArray: any = array[j - 1]
          newArray.tables = array[j - 1].tables.concat(array[j].tables)
          array.splice(j, 1)

        }
      }
    }

    return array
  }


  //delete table from droplist
  deleteDatabase(parent: any, child: any) {
    parent.splice(child, 1)

    this.ingestionService.setStepTwoData(this.itemObjectsRight)
    this.ingestionService.setStepTwoCopyData(this.itemObjectsRight)

  }

  //delete table from droplist
  deleteTable(parent: any, child: any) {
    this.redoTable.push(parent[child])

    parent.splice(child, 1)

    this.ingestionService.setStepTwoData(this.itemObjectsRight);
    this.ingestionService.setStepTwoCopyData(this.itemObjectsRight);

  }

  redoTableDelete(child: any) {

    if (this.redoTable.length > 0) {
      this.itemObjectsRight[child].tables.push(this.redoTable[this.redoTable.length - 1])

      // this.itemObjectsRight[child].tables.splice(this.redoTablePositions[this.redoTablePositions.length - 1], 0, this.redoTable[this.redoTable.length - 1])
      this.redoTable.splice(this.redoTable.indexOf(this.redoTable[this.redoTable.length - 1]))

    }

  }


  //delete column from droplist
  deleteColumn(parent: any, child: any) {
    this.redoColumn.push(parent[child])
    console.log(this.redoColumn)
    parent.splice(child, 1)
    this.ingestionService.setStepTwoData(this.itemObjectsRight);
    this.ingestionService.setStepTwoCopyData(this.itemObjectsRight);
  }

  redoColumnDelete(database: any, table: any) {
    console.log(database)
    console.log(this.itemObjectsRight[this.itemObjectsRight.indexOf(database)].tables[this.itemObjectsRight[this.itemObjectsRight.indexOf(database)].tables.indexOf(table)])
    if (this.redoColumn.length > 0) {
      // this.itemObjectsRight[child].tables.push(this.redoTable[this.redoTable.length - 1])
      this.itemObjectsRight[this.itemObjectsRight.indexOf(database)].tables[this.itemObjectsRight[this.itemObjectsRight.indexOf(database)].tables.indexOf(table)].columns.push(this.redoColumn[this.redoColumn.length - 1])
      // this.itemObjectsRight[child].tables.splice(this.redoTablePositions[this.redoTablePositions.length - 1], 0, this.redoTable[this.redoTable.length - 1])
      this.redoColumn.splice(this.redoColumn.indexOf(this.redoColumn[this.redoColumn.length - 1]))

    }

  }

  carryData() {
    this.router.navigate([`/project/${this.projectName}/ingest/select-ingestion/stepThreeCustom/${this.id}`]);

  }

  loading() {

    return new Promise((resolve, reject) => {

    })
  }

  checkIfDatabaseHasData(data: any) {
    if (data.tables.length == 0) {
      this.error(data.sourceDatabaseName)
    }
  }
  openSnackBar(status: string, statusLogo: string) {
    this.snackBar.open(status, statusLogo, {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['tertiary-active']

    });
  }
  error(databaseName: string) {
    Swal.fire({
      type: 'Error',

      title: 'Ooops!',
      text: "No tables in '" + databaseName + "'",
      confirmButtonText: 'Ok',
    })
  }

  nullcheck(value: any) {
    if (value == null) {
      return ""
    } else {
      return value + "."
    }
  }
  databaseIds: string[];
  dataBasePrinter(): string[] {
    let databaseIds: string[] = []

    this.catalogService.getCatalogforDatabases(this.id).subscribe(response => {
      let data: any;
      data = response;
      data.sourceDatabases.map(databases => {
        databaseIds.push(databases.databaseId)
      })
    })
    return databaseIds

  }

  //  tablePrinter() {
  //   let data: any;
  //   console.log(this.dataBasePrinter())
  //   for (let i = 0; i < this.databaseIds.length; i++) {
  //     console.log(i)
  //   }
  // this.dataBasePrinter().map(id => {
  //   this.catalogService.getCatalog('tables/' + id).subscribe(response => {
  //     data = response
  //     console.log(data)
  //   })
  // })

  // }

  ngOnInit(): void {

    this.getDatabase(this.id)
    // this.spinnerService.show()

    // this.getAllDatabases(this.id)
    if (this.ingestionService.getStepTwoData() != null) {
      this.itemObjectsRight = this.ingestionService.getStepTwoData()
      this.redoObjectForPositons = this.ingestionService.getStepTwoData();
      this.itemDropped = true;

    }

  }

}



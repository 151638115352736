import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSourceComponent } from 'src/app/components/projects/data-sources/data-source/data-source.component';
import { IngestionSharingServiceService } from 'src/app/ingestion-sharing-service.service';
import { CatalogService } from 'src/app/services/api/catalogService/catalog.service';
import { DataSourceControllerService } from 'src/app/services/api/data-source/data-source-controller.service';
import { StreamingdataSourceService } from 'src/app/services/api/data-source/streamingdata-source.service';
import { RdbmsIngestionControllerService } from 'src/app/services/api/rdbms-controller/rdbms-ingestion-controller.service';
import { StreamIngestionControllerService } from 'src/app/services/api/streamController/stream-ingestion-controller.service';
import { ProjectService } from 'src/app/services/project/project-service.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-edit-step-two-stream-ingestion',
  templateUrl: './edit-step-two-stream-ingestion.component.html',
  styleUrls: ['./edit-step-two-stream-ingestion.component.scss'],
})
export class EditStepTwoStreamIngestionComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private dataSourceController: DataSourceControllerService,
    private catalogService: CatalogService,
    public service: RdbmsIngestionControllerService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public projectService: ProjectService,
    private ingestionService: IngestionSharingServiceService,
    private streamingDataSourceService: StreamingdataSourceService,
    private streamIngestionControllerService: StreamIngestionControllerService,

    private IngestionsharingService: IngestionSharingServiceService
  ) {}
  csvDestinationDataTypes = [
    'INT',
    'SMALLINT',
    'BIGINT',
    'TINYINT',
    'FLOAT',
    'DOUBLE',
    'DECIMAL',
    'NUMERIC',
    'TIMESTAMP',
    'DATE',
    'INTERVAL',
    'STRING',
    'VARCHAR(255)',
    'CHAR(255)',
    'BOOLEAN',
    'BINARY',
  ];
  avroDestinationDataTypes = [
    'INT',
    'SMALLINT',
    'BIGINT',
    'TINYINT',
    'FLOAT',
    'DOUBLE',
    'DECIMAL',
    'NUMERIC',
    'STRING',
    'BOOLEAN',
    'BINARY',
  ];
  parquetDestinationDataType = [
    'INT',
    'SMALLINT',
    'BIGINT',
    'TINYINT',
    'FLOAT',
    'DOUBLE',
    'NUMERIC',
    'STRING',
    'BOOLEAN',
    'BINARY',
  ];

  showDiv = {
    stepone: true,
    steptwo: true,
    stepthree: false,
    stepfour: false,
    stepfive: false,

    stepbtnone: true,
    stepbtntwo: false,
    stepbtnthree: false,
    stepbtnfour: false,
    stepbtnfive: false,

    cancelbtn: false,
    nextbtn: true,
    nextbtntwo: false,
    nextbtnthree: false,
    nextbtnfour: false,

    backbtn: false,
    backbtntwo: false,
    backbtnthree: false,
    backbtnfour: false,
    backbtnfive: false,

    submitbtn: false,
  };
  streamConnectorId = this.activatedRoute.snapshot.params['id'];
  projectName = this.activatedRoute.snapshot.params['id2'];
  ingestionId: string = this.activatedRoute.snapshot.params['id3'];
  load: boolean = false;
  jsonCodeSnippet: any = '';
  fields: any;
  arrayItems: any;
  result: [];
  dataSource: any;
  currentIngestion: any;
  fieldsSelected: any = [];

  stepOneInformation = this.ingestionService.getStepOneStreamData();
  carryData() {
    this.router.navigate([
      `/project/${this.projectName}/ingest/select-ingestion/stepThreeStream/${this.streamConnectorId}`,
    ]);
  }
  formForFields = this.formBuilder.group({
    fields: this.formBuilder.array([]),
  });
  stepTwoForm = this.formBuilder.group({
    databaseName: ['', Validators.required],
    tableName: ['', Validators.required],
    fileType: ['csv', Validators.required],
  });

  streamIngestion = this.formBuilder.group({
    name: [''],
    description: [''],
    streamConnectorId: [''],
    destinationDatabaseName: [''],
    destinationTableName: [''],
    fileType: [''],
    projectName: this.projectName,
    fields: this.formBuilder.array([]),
    ingestionType: ['STREAM'],
  });

  submitForm(data: any) {}
  allComplete: boolean = false;

  updateAllComplete() {
    this.allComplete =
      this.formForFields.value.fields != null &&
      this.formForFields.value.fields.every((t) => t.completed);
    const result = this.formForFields.value.fields.filter(
      (item) => item.checked == true
    );
    if (result.length > 0) {
      this.fieldsSelected = true;
      console.log(this.fieldsSelected);
    } else {
      this.fieldsSelected = false;
      console.log(this.fieldsSelected);
    }
  }

  someComplete(): boolean {
    const result = this.formForFields.value.fields.filter(
      (item) => item.checked == true
    );
    if (result.length > 0) {
      this.fieldsSelected = true;
      console.log(this.fieldsSelected);
    } else {
      this.fieldsSelected = false;
      console.log(this.fieldsSelected);
    }
    if (this.formForFields.value.fields == null) {
      return false;
    }
    return (
      this.formForFields.value.fields.filter((t) => t.completed).length > 0 &&
      !this.allComplete
    );
  }
  setHiveDataType(data: any, i: number, dataType: string) {
    data.fields[i].hiveDataType = dataType;
    this.IngestionsharingService.setStepTwoStreamData(this.dataSource);
  }

  deleteField(parent: any, child: any) {
    Swal.fire({
      type: 'warning',

      title: 'Are you sure you want to delete this column?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed == true) {
        parent.splice(child, 1);
        // this.IngestionsharingService.setStepTwoData(this.itemObjectsRight);
        // this.IngestionsharingService.setStepTwoCopyData(this.itemObjectsRight);
      }
    });
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.formForFields.value.fields == null) {
      return;
    }
    this.formForFields.value.fields.forEach((t) => (t.checked = completed));
  }

  submitFields() {
    Swal.fire({
      type: 'warning',

      title: 'Are you sure you want to update this Ingestion?',
      // text: 'All information associated to this ingestion will be permanently deleted',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed == true) {
        const result = this.formForFields.value.fields.filter(
          (item) => item.checked == true
        );
        this.result = result;
        for (let i = 0; i < result.length; i++) {
          delete result[i].checked;
        }
        this.ingestionService.setStepTwoStreamData(result);

        this.streamIngestion.patchValue({
          id: this.currentIngestion.id,
          createdAt: this.currentIngestion.createdAt,
          name: this.stepOneInformation.name,
          description: this.stepOneInformation.description,
          streamConnectorId: this.currentIngestion.streamConnectorId,
          destinationDatabaseName: this.stepTwoForm.value.databaseName,
          destinationTableName: this.stepTwoForm.value.tableName,
          fileType: this.stepTwoForm.value.fileType,
          projectName: this.projectName,
        });
        this.streamIngestion.setControl(
          'fields',
          this.formBuilder.array(this.result)
        );
        this.streamIngestionControllerService
          .updateStreamIngestion(
            this.currentIngestion.id,
            this.streamIngestion.value
          )
          .subscribe((response) => {
            console.log(response);
          });
        this.router.navigate([`/project/${this.projectName}/ingest`]);
        this.success('Stream ingestion successfully updated');
      }
    });
  }
  success(status: string) {
    Swal.fire({
      type: 'Success',
      title: 'Success!',
      text: status,
      confirmButtonText: 'Ok',
    });
  }

  selectFileType(fileTypeValue: string) {
    this.stepTwoForm.patchValue({
      fileType: fileTypeValue,
    });
    // if (this.file.value.fileType == "avro" || this.file.value.fileType == "AVRO") {
    //   this.fileTypeChangeWarning("The Avro does not support timestamps or dates in this version. Timestamps and Date data types can be casted to BigInt.");
    //   for (let i = 0; i < this.itemObjectsRight.length; i++) {
    //     for (let j = 0; j < this.itemObjectsRight[i].tables.length; j++) {
    //       for (let k = 0; k < this.itemObjectsRight[i].tables[j].columns.length; k++) {
    //         this.itemObjectsRight[i].tables[j].columns[k].destinationDataType = this.returnDestinationDataType(this.itemObjectsRight[i].tables[j].columns[k].destinationDataType);
    //       }
    //     }
    //   }
    //   this.IngestionsharingService.setStepTwoData(this.itemObjectsRight);
    //   this.IngestionsharingService.setStepTwoCopyData(this.itemObjectsRight);

    // } else if (this.file.value.fileType == "parquet" || this.file.value.fileType == "PARQUET") {
    //   this.fileTypeChangeWarning("The Parquet option does not support timestamps, dates, or decimal in this version. Timestamps and Date data types can be casted to BigInt. Decimal can be casted to a lower precision data type like Double");
    //   for (let i = 0; i < this.itemObjectsRight.length; i++) {
    //     for (let j = 0; j < this.itemObjectsRight[i].tables.length; j++) {
    //       for (let k = 0; k < this.itemObjectsRight[i].tables[j].columns.length; k++) {
    //         this.itemObjectsRight[i].tables[j].columns[k].destinationDataType = this.returnDestinationDataType(this.itemObjectsRight[i].tables[j].columns[k].destinationDataType)
    //       }
    //     }
    //   }
    //   this.IngestionsharingService.setStepTwoData(this.itemObjectsRight);
    //   this.IngestionsharingService.setStepTwoCopyData(this.itemObjectsRight);

    // } else {
    //   for (let i = 0; i < this.itemObjectsRight.length; i++) {
    //     for (let j = 0; j < this.itemObjectsRight[i].tables.length; j++) {
    //       for (let k = 0; k < this.itemObjectsRight[i].tables[j].columns.length; k++) {
    //         this.itemObjectsRight[i].tables[j].columns[k].destinationDataType = this.itemObjectsRightCopy[i].tables[j].columns[k].destinationDataType;
    //       }
    //     }
    //   }
    //   this.IngestionsharingService.setStepTwoData(this.itemObjectsRight)
    //   this.IngestionsharingService.setStepTwoCopyData(this.itemObjectsRight);
    // }

    // let data: any = this.IngestionsharingService.getStepOneData();
    // data.fileType = this.file.value.fileType;
    // this.IngestionsharingService.setStepOneData(data);

    // console.log(this.IngestionsharingService.getStepOneData());
  }

  fileTypeChangeWarning(status: string) {
    Swal.fire({
      type: 'warning',

      title: 'Warning',
      text: status,
      confirmButtonText: 'Ok',
    });
  }

  ngOnInit(): void {
    this.streamIngestionControllerService
      .getSingleStreamIngestion(this.ingestionId)
      .subscribe((response) => {
        let data: any = response;
        this.currentIngestion = data;
        this.stepTwoForm.patchValue({
          databaseName: data.destinationDatabaseName,
          tableName: data.destinationTableName,
          fileType: data.fileType,
        });
        this.streamingDataSourceService
          .getSingleStreamDataSource(this.streamConnectorId)
          .subscribe((source) => {
            let datasource: any = source;
            this.dataSource = source;

            this.dataSource.fields = datasource.fields;
            this.formForFields.value.fields = datasource.fields;
            for (let i = 0; i < data.fields.length; i++) {
              for (let j = 0; j < this.formForFields.value.fields.length; j++) {
                if (
                  data.fields[i].header ==
                  this.formForFields.value.fields[j].header
                ) {
                  this.formForFields.value.fields[j].checked = true;
                }
              }
            }
          });
      });
  }
}

import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { User } from '../../models/user/user.model';
import { AuthService } from '../../services/auth/auth.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Injectable()

export class AuthGuard implements CanActivate {
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  inputDetails: any;
  constructor(private auth_service: AuthService, private router: Router, private snackBar: MatSnackBar) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let res = <any>{};
    this.auth_service.ping().subscribe(
      data => {
        if (data.hasOwnProperty("authorities")) {
          var input1 = data
          var user = new User(input1)
          sessionStorage.setItem("loggedUser", JSON.stringify(user));
        }
        return true
        // if(data.hasOwnProperty("userDetails")) {
        //   res=data;
        //   if(res.userDetails && !res.userDetails.eula){
        //     this.router.navigate(["eula"]);
        //    }
        //   this.inputDetails = data;
        //   sessionStorage.setItem('qubzLicenseExpire',JSON.stringify(this.inputDetails.userDetails.qubzLicenseExpire));
        //   sessionStorage.setItem('qubzLicenseExpireMsg',JSON.stringify(this.inputDetails.userDetails.qubzLicenseExpireMsg));
        // }
      },
      error => {
        this.router.navigate([""]);
        return false;
      });
    if (this.auth_service.logged_in()) {

      const user = JSON.parse(sessionStorage.getItem('loggedUser'));
      for (var user_auths = 0; user_auths < user.authorities.authorities.length; user_auths++) {
        for (var data_count = 0; data_count < route.data.role.length; data_count++) {
          if (user.authorities.authorities[user_auths].authority === route.data.role[data_count]) {
            return true
          }
        }
      }
      this.error("Route Not Permitted")
      return false;

    } else {
      this.router.navigate([""]);
      return false;
    }
    return false;
  }

  //snackbar
  openSnackBar(status: string, statusLogo: string) {
    this.snackBar.open(status, statusLogo, {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['tertiary-active']

    });
  }

  success(status: string) {
    Swal.fire({
      type: 'Success',

      title: 'Success!',
      text: status,
      confirmButtonText: 'Ok',
    })
  }

  error(status: string) {
    Swal.fire({
      type: 'Error',

      title: 'Ooops!',
      text: status,
      confirmButtonText: 'Ok',
    })
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.auth_service.ping().subscribe(
      data => {

      },
      error => {
        this.router.navigate(["auth"]);

      });
    return true

  }
}

import { Component, OnInit, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { CubesService } from '../../../../../services/cubes/cubes.service';
// import { JobSchedulerService } from '../../../../../services/job-scheduler/job-scheduler.service';
import { Location } from '@angular/common';
import Swal from 'sweetalert2/dist/sweetalert2.js';

// import { ProjectService } from '../../../../../services/project/project.service';
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { NgForm } from "@angular/forms";
import { ProjectService } from 'src/app/services/project/project-service.service';
import { RdbmsIngestionControllerService } from 'src/app/services/api/rdbms-controller/rdbms-ingestion-controller.service';
import { ScheduleService } from 'src/app/services/scheduler/schedule.service';
import { CustomValidators } from 'src/app/validation/custom-validators';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
// import { CommonServiceService } from '../../../../../services/common-services/common-service.service';
@Component({
  selector: 'app-new-job-schedule',
  templateUrl: './new-job-schedule.component.html',
  styleUrls: ['./new-job-schedule.component.scss'],

})
export class NewJobScheduleComponent implements OnInit {

  load: boolean = true;
  title: string;
  editPage: boolean = false;
  triggerKeyGroup: string;
  triggerKeyName: string;
  scheduleId: string;
  jobSch: any;
  projectId: string;
  loggedUser;
  projectUUID: string;
  projectName = this.route.snapshot.params['id2'];
  ingestionType: boolean;
  ingestions: any[];
  enabledValue: boolean = false;
  selected: boolean = false;
  serverTime: any;
  // jobTypes: string[] = [
  // 	'BUILD'
  // ];
  cubesList = [];
  type;
  job: any = {
    cronString: null,
    cubeName: null,
    jobType: null,
    name: null,
    status: true
  }
  cubes: any = null;
  cube;
  cronString: string;
  selectedCube: any;
  errorCron;
  checkCronExpression;
  generateScheduledTimes;
  validated: boolean;
  listData;
  todayDate;
  isEnable = false;
  cronPattern = "/^(?:[1-9]?\d|\*)(?:(?:[\/-][1-9]?\d)|(?:,[1-9]?\d)+)?$/";
  breadCrumb: any;
  schedulerObj: any = {};
  scheduleNameError: any;
  public focusSettingEventEmitter = new EventEmitter<boolean>();
  targetPage;
  selectedingestion:any;


//for autocomplete option
  
  options: string[] = [];
  filteredOptions: Observable<string[]>;
  schedulerForm:FormGroup;
// ends autocomplete option

  constructor(private route: ActivatedRoute, private location: Location, public projectService: ProjectService,
    private rdbmsService: RdbmsIngestionControllerService, private formBuilder: FormBuilder, private scheduleService: ScheduleService,
    private router: Router
  ) {
    this.loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
    this.schedulerForm = this.formBuilder.group(
      {
        // cronExpression: ["", Validators.compose([
        //   Validators.required,
        //   // CustomValidators.patternValidator(/^((((\d+,)+\d+|(\d+(\/|-|#)\d+)|\d+L?|\*(\/\d+)?|L(-\d+)?|\?|[A-Z]{3}(-[A-Z]{3})?) ?){5,7})$|(@(annually|yearly|monthly|weekly|daily|hourly|reboot))|(@every (\d+(ns|us|µs|ms|s|m|h))+)/, { hasCronPattern: true }),
  
        //  CustomValidators.patternValidator(/^\s*($|#|\w+\s*=|(\?|\*|(?:[0-5]?\d)(?:(?:-|\/|\,)(?:[0-5]?\d))?(?:,(?:[0-5]?\d)(?:(?:-|\/|\,)(?:[0-5]?\d))?)*)\s+(\?|\*|(?:[0-5]?\d)(?:(?:-|\/|\,)(?:[0-5]?\d))?(?:,(?:[0-5]?\d)(?:(?:-|\/|\,)(?:[0-5]?\d))?)*)\s+(\?|\*|(?:[01]?\d|2[0-3])(?:(?:-|\/|\,)(?:[01]?\d|2[0-3]))?(?:,(?:[01]?\d|2[0-3])(?:(?:-|\/|\,)(?:[01]?\d|2[0-3]))?)*)\s+(\?|\*|(?:0?[1-9]|[12]\d|3[01])(?:(?:-|\/|\,)(?:0?[1-9]|[12]\d|3[01]))?(?:,(?:0?[1-9]|[12]\d|3[01])(?:(?:-|\/|\,)(?:0?[1-9]|[12]\d|3[01]))?)*)\s+(\?|\*|(?:[1-9]|1[012])(?:(?:-|\/|\,)(?:[1-9]|1[012]))?(?:L|W)?(?:,(?:[1-9]|1[012])(?:(?:-|\/|\,)(?:[1-9]|1[012]))?(?:L|W)?)*|\?|\*|(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)(?:(?:-)(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC))?(?:,(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)(?:(?:-)(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC))?)*)\s+(\?|\*|(?:[0-6])(?:(?:-|\/|\,|#)(?:[0-6]))?(?:L)?(?:,(?:[0-6])(?:(?:-|\/|\,|#)(?:[0-6]))?(?:L)?)*|\?|\*|(?:MON|TUE|WED|THU|FRI|SAT|SUN)(?:(?:-)(?:MON|TUE|WED|THU|FRI|SAT|SUN))?(?:,(?:MON|TUE|WED|THU|FRI|SAT|SUN)(?:(?:-)(?:MON|TUE|WED|THU|FRI|SAT|SUN))?)*)(|\s)+(\?|\*|(?:|\d{4})(?:(?:-|\/|\,)(?:|\d{4}))?(?:,(?:|\d{4})(?:(?:-|\/|\,)(?:|\d{4}))?)*))$/, { hasCronPattern: true }),
        //   Validators.minLength(11),
  
        // ])],
        cronExpression: ["", Validators.required],
        enabled: false,
        ingestionId: [""],
        ingestionName: ["", Validators.required],
        name: ["", Validators.required],
        project: this.projectName,
        // myControl : new FormControl(["", Validators.required])
      }
    )

  }


  setIngestionType(ingestionType: boolean) {
    if (ingestionType == true) {
      this.ingestionType = true;
      // console.log(this.ingestionType);
    } else {
      this.ingestionType = false;
    }
  }

  submitScheduler(data: any) {
    // console.log('data',data);
    
    Swal.fire({
      type: 'warning',

      title: "Are you sure you want to save the scheduler '" + data.value.name + "'?",
      // text: 'All information associated to this schedule will be permanently deleted',
      showCancelButton: true, confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      console.log(result)
      if (result.isConfirmed == true) {
        this.schedulerForm.patchValue({
          cronExpression: data.value.cronExpression,
          name: data.value.name,
          project: this.projectName
        })
        this.scheduleService.createSchedule(this.schedulerForm.value).subscribe(response => {
          console.log(response);
          this.router.navigate([`/project/${this.projectName}/job-schedule-landing-page`]);
          this.success("Scheduler has been successfully submitted")
        })

      }
    })

  }
  IngestionSelect(ingestionName: any) {
    // console.log('data',this.ingestions);
    let obj=this.ingestions.find(item=>item.name===ingestionName);
    // console.log(obj)
    if(obj){
      this.schedulerForm.patchValue({
         ingestionId: obj.id,
        ingestionName: obj.name
      })
    }
    
  }
  toUTCDate(date) {
    var _utc = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    return _utc;
  };

  millisToUTCDate() {
    return this.toUTCDate(new Date());
  };
  millisToUTCDateS(scheduledTime) {
    return this.toUTCDate(new Date(scheduledTime));
  }
  buttonStatus() {

    this.enabledValue = this.schedulerForm.value.enabled = !this.schedulerForm.value.enabled

    this.schedulerForm.patchValue({
      enabled: this.enabledValue
    })

    // console.log(this.schedulerForm.value)

  }
  success(status: string) {
    Swal.fire({
      type: 'Success',

      title: 'Success!',
      text: status,
      confirmButtonText: 'Ok',
    })
  }
  ngOnInit() {

    this.rdbmsService.getAllIngestionsByProjectName(this.projectName).subscribe(data => {
      this.ingestions = data;
      // console.log(this.ingestions);
      this.filteredOptions = this.schedulerForm.get('ingestionName').valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || '')),
      );
    });
    

   // console.log('this.ingestions-2',this.ingestions);

    this.todayDate = setInterval(() => {
      // this.todayDate = new Date();
      this.scheduleService.getServerTime().subscribe(response => {
        let data: any = response;
        this.todayDate = data.serverTime;
      })
    }, 1000);
    let projectList: any = null;
    // this.projectService.list().subscribe(
    // 	data => {
    // 		projectList = data;
    // 		for (let project of projectList) {
    // 			if(project.name === this.projectName) {
    // 				this.projectId = project.uuid

    // 				if( this.route.snapshot.paramMap.get('schedulerId') ){
    // 					this.scheduleId = this.route.snapshot.paramMap.get('schedulerId')
    // 					this.targetPage='projects/'+this.projectId+'/scheduler/'+this.scheduleId+'/edit',
    // 					this.getJobSchedule(this.scheduleId, this.projectId);
    // 					this.title = "Edit Schedule"

    // 					this.validated=true;
    // 					this.editPage = true;
    // 				} else {
    // 					this.title = "Create Schedule";
    // 					this.breadCrumb ="Create Schedule"
    // 					this.targetPage='projects/'+this.projectId+'/scheduler/create';
    // 				}
    // 				this.saveLogs();
    // 			}
    // 		}
    // 	}
    // )

   
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    this.ingestions.forEach(option=>{
      if(!this.options.includes(option.name)){
        this.options.push(option.name)
      }
      
    //   console.log('this.options',this.options);
      
    })
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
    
  }

  getJobSchedule(schId: string, projId: string) {
    let userCredentials: any = JSON.parse(sessionStorage.getItem('credentials'))
    this.job = {
      cronString: null,
      cubeName: null,
      jobType: null,
      name: null,
      projectId: null,
      schedulerId: null,
      status: true,
      submitter: null,
      triggerKeyGroup: null,
      triggerKeyName: null
    }
    // 	this.jobSchedulerService.getScheduler(schId).subscribe(
    // 		data => {
    // 			this.jobSch = data;
    // 			this.job.cronString= this.jobSch.schedulerDetails.cronString,
    // 			this.job.cubeName= this.jobSch.schedulerDetails.cubeName,
    // 			this.cube=this.jobSch.schedulerDetails.cubeName,
    // 			this.job.jobType= this.jobSch.schedulerDetails.jobType,
    // 			this.type=this.job.jobType,
    // 			this.job.name= this.jobSch.schedulerDetails.name,
    // 			this.job.status= this.jobSch.schedulerDetails.status,
    // 			this.job.projectId= projId,
    // 			this.job.schedulerId= schId,
    // 			this.job.submitter= userCredentials.username,
    // 			this.job.triggerKeyGroup= this.jobSch.schedulerDetails.triggerKeyGroup,
    // 			this.job.triggerKeyName= this.jobSch.schedulerDetails.triggerKeyName
    // 			this.breadCrumb =this.job.name;
    // 			this.selectChangeHandler(this.job.jobType, 'type')
    // 			this.selectChangeHandler(this.job.cubeName, 'cube')
    // 	})
  }

  isActive() {
    if (!!this.job.name) {
      if (!!this.job.jobType && this.job.jobType === 'BUILD' && this.isValidCron() && this.job.cubeName) {
        return true;
      }
    }
    return false;
  }

  isValidCron() {
    if (!!this.job.cronString) {
      return true;
    }
    return false;
  }
  cancel() {
    this.location.back();
  }

  // submit(form: NgForm) {
  // 	if(form.value.cronString.match("(?=.*[a-z])")){
  // 		this.errorCron = "Cron String is Invalid.";
  // 		this.generateScheduledTimes="";
  // 	}else{
  // 	this.jobSchedulerService.validateCronString(form.value.cronString).subscribe(
  // 		data => {
  // 			this.listData=data;
  // 			this.validated=true;
  // 			this.errorCron="";
  //               this.checkCronExpression = true;
  // 			this.generateScheduledTimes = this.listData.longList;

  // 			if (!this.listData.errorCode) {
  // 			swal(
  // 				{
  // 					type: 'warning',
  // 					title: 'Are you sure you want to save this job?',
  // 					text: '',
  // 					showCancelButton: true, confirmButtonText: 'Yes',
  // 					cancelButtonText: 'No'
  // 				}
  // 			).then((status) => {
  // 				if(status.value == true) {
  // 					let errors: any = null;

  // 					const params = { projectId: this.projectId }
  // 					if (this.route.snapshot.paramMap.get('schedulerId')){
  // 						this.jobSchedulerService.updateScheduler(this.job).subscribe(
  // 							result => {

  // 							},
  // 							error => {
  // 								errors = error;
  // 								swal("Failed to create job", "failure", "error")
  // 							},
  // 							() => {
  // 								if(!errors) {
  // 									swal("Success!", "Scheduler has been saved successfully", "success").then((status) => {
  // 										if(status.value == true) {
  // 											this.location.back();
  // 										}
  // 									});
  // 								}
  // 							}
  // 						)
  // 					} else {
  // 						this.jobSchedulerService.createSheduler(params, this.job).subscribe(
  // 						result => {

  // 						},
  // 						error => {
  // 							errors = error;
  // 							swal("Failed to create job", "failure", "error")
  // 						},
  // 						() => {
  // 							if(!errors) {
  // 								swal("Success!", "Scheduler has been saved successfully", "success").then((status) => {
  // 									if(status.value == true) {
  // 										this.location.back();
  // 									}
  // 								});
  // 							}
  // 						}
  // 					)
  // 					}

  // 				}
  // 			});
  // 		}
  //               if (this.listData.errorCode) {
  //                   this.checkCronExpression = false;
  // 				this.errorCron = this.listData.errorMessage;

  //               }
  // 		}
  //       );
  // 	}

  // }
  // getCubes() {
  // 	this.cubeService.list({
  // 		projectName: this.projectName
  // 	}).subscribe(
  // 		data => {
  // 			this.cubes = data;
  // 			this.cubesList=[];
  // 			for (let i = 0; i < this.cubes.length; i++) {
  // 				this.cubesList.push({name:this.cubes[i].name,value:this.cubes[i].name});
  // 			  }


  // 			if(this.route.snapshot.paramMap.get('schedulerId')) {
  // 				return;
  // 			} else {
  // 				this.job.cubeName = this.cubes[0].name;
  // 			}
  // 		}
  // 	)
  // }
  // selectChangeHandler(event, selector) {
  // 	if (selector === 'type') {
  // 		if (!!event && event === 'BUILD') {
  // 			if (!this.cubes) {
  // 				this.getCubes();
  // 			}
  // 		}
  // 		this.job.jobType = event;
  // 	}
  // 	if (selector === 'cube') {
  // 		this.job.cubeName = event;
  // 	}
  // }

  isBuildJob() {
    if (!!this.job.jobType && this.job.jobType === 'BUILD') {
      return true;
    }
    return false;
  }

  checkCronScheduleTimes = function (cronString) {
    // console.log(cronString)
    this.scheduleService.validateCron(cronString).subscribe(res => {
      if (!res.isValid) {
        this.errorCron = "Cron String is Invalid.";
        this.generateScheduledTimes = "";
      } else {
        this.generateScheduledTimes = [];
        this.scheduleService.generate(cronString, 5).subscribe(
          data => {
            // console.log(data)
            this.validated = true;
            this.errorCron = "";
            this.checkCronExpression = true;
            this.generateScheduledTimes = data;
            if (data.errorCode) {
              this.checkCronExpression = false;
              this.errorCron = data.errorMessage;
  
            }
          }
        );
      }
    })
  }

  checkSchedulerNameExists() {
    let listAllJobs = JSON.parse(sessionStorage.getItem('JobList'));
    let flag = true;
    for (let [key, value] of Object.entries(listAllJobs.schedulerDetailsList)) {
      let res = <any>{};
      res = value;
      if (this.job.name.toUpperCase() == res.name.toUpperCase()) {
        flag = false;
        break;
      }
    }
    if (!flag) {
      this.scheduleNameError = 'Schedule name is already exist.';
      return;
    } else {
      this.scheduleNameError = '';
    }
  }

  ngAfterViewInit() {

    this.focusSettingEventEmitter.emit(true);

  }


  setFocus(): void {
    this.focusSettingEventEmitter.emit(true);
  }

  getEvent(){
    // console.log(this.ingestions);
  }


  // saveLogs(){
  //   var today = new Date();
  //   let param={
  //     username:this.loggedUser.username,
  //     targetPage:this.targetPage,
  //     day:today.getDay(),
  //     month:today.getMonth(),
  //     year:today.getFullYear()
  //   };
  //   this.commonServiceService.saveLogs(param).subscribe(
  //     data => {

  //     });
  // }
}
<!-- <div *ngIf="load" class="">
  <div class="loader"></div>
  <figure class="spinner-border loaderico"></figure>
</div> -->
<div class="cm-content-blocks">
  <ol class="breadcrumb">
    <li><a routerLink="/home"><i class="fa fa-home"></i>Home</a></li>
    <li><a routerLink="/project"><i class="fa fa-folder-open"></i>Projects</a></li>
    <li><a routerLink="/project/{{projectName}}"><i class="fa fa-folder"></i>{{projectName}}</a></li>
    <li><a [routerLink]="['/project', projectService.getCurrentProject(),'ingest']"><i
          class="fa fa-filter"></i>Ingest</a></li>
    <li><a [routerLink]="['/project', projectService.getCurrentProject(),'ingest', 'select-ingestion']"><i
          class="fa fa-sitemap mr-1"></i>Configure Ingestion</a></li>
    <li class="active"><i class="fa fa-filter"></i>Query Ingestion</li>
  </ol>
</div>
<div class="clearafter headbtnc">
  <h3 class="headc">
    Query Ingestion
    <div class="subhead">Configure Query Ingestion</div>
  </h3>
  <div class="create-btn-position headbtnc">
    <button class="mr-btn-back pull-right pointerc"
      [routerLink]="['/project', projectService.getCurrentProject(),'ingest']">Back to Ingest</button>
  </div>
</div>
<div class="menublock">
  <div class="progresslic clearafter ">
    <div class="fifteen_pb">
      <ul class="progressbar clearfix">
        <li class="tabli active pointerc" [ngClass]="showDiv.stepone === true ? 'active':'disabled' ">
          <section class="tabbtn">
            <section class="clearfix relativepos">
              <aside class="absoultepos stipico">
                <i class="fa fa-info" aria-hidden="true"></i>
              </aside>
              <aside class="steptxtc">
                <div class="steptitle">Data Source</div>
                <div class="stepdes mt-1">Select data source for ingestion</div>
              </aside>
            </section>
          </section>
        </li>
        <li class="tabli active pointerc" [ngClass]="showDiv.steptwo === true ?'active':'disabled'">
          <section class="tabbtn">
            <section class="clearafter relativepos">
              <aside class="absoultepos stipico">
                <i class="fa fa-database" aria-hidden="true"></i>
              </aside>
              <aside class="steptxtc">
                <div class="steptitle">Query</div>
                <div class="stepdes">Enter SQL query</div>
              </aside>
            </section>
          </section>
        </li>
        <li class="tabli pointerc" [ngClass]="showDiv.stepthree === true ?'active':'disabled' ">
          <section class="tabbtn">
            <section class="clearfix relativepos">
              <aside class="absoultepos stipico">
                <i class="fa fa-cogs" aria-hidden="true"></i>
              </aside>
              <aside class="steptxtc">
                <div class="steptitle">Destination Configuration</div>
                <div class="stepdes">Choose Destination Location and Settings</div>
              </aside>
            </section>
          </section>
        </li>
        <li class="tabli pointerc" [ngClass]="showDiv.stepfour === true ?'active':'disabled' ">
          <section class="tabbtn">
            <section class="clearfix relativepos">
              <aside class="absoultepos stipico">
                <i class="fa fa-cogs" aria-hidden="true"></i>
              </aside>
              <aside class="steptxtc">
                <div class="steptitle">Advanced Configuration</div>
                <div class="stepdes">Configure ingestion settings</div>
              </aside>
            </section>
          </section>
        </li>
      </ul>
    </div>
    <section class="tabc_config">

      <form [formGroup]="stepThreeForm" (ngSubmit)="submitForm(stepThreeForm)">

        <div class="steptwo_c" *ngIf="showDiv.stepthree">

          <div class="fifteenmb">

            <section class="clearafter">
              <aside class="fourtynine_p float-left">

                <div class="subtitle">Enter Destination Details</div>
                <p>Enter database name, table name, and destination file type choice </p>
                <div>
                  <span class="example-list-section">
                  </span>

                  <section class="inputc">
                    <aside class="col-md-12 fieldc"
                      [ngClass]="stepThreeForm.controls['databaseName'].touched && stepThreeForm.controls['databaseName'].errors?'inputc-error':''">
                      <label class="inputlabel clearafter">
                        <span class="displayblock fl fivemr">Database Name </span>
                        <span class="text-danger">*</span>

                      </label>
                      <input class="input-field" formControlName="databaseName" type="text">
                      <span class="error-mge mt-2"
                        *ngIf="stepThreeForm.controls['databaseName'].invalid && (stepThreeForm.controls['databaseName'].dirty || stepThreeForm.controls['databaseName'].touched)">
                        Database name is required.
                      </span>
                    </aside>
                  </section>
                  <section class="inputc">

                    <aside class="col-md-12 fieldc"
                      [ngClass]="stepThreeForm.controls['tableName'].touched && stepThreeForm.controls['tableName'].errors?'inputc-error':''">
                      <label class="inputlabel clearafter">
                        <span class="displayblock fl fivemr">Table Name </span>
                        <span class="text-danger">*</span>

                      </label>
                      <input class="input-field" formControlName="tableName" type="text">
                      <span class="error-mge mt-2"
                        *ngIf="stepThreeForm.controls['tableName'].invalid && (stepThreeForm.controls['tableName'].dirty || stepThreeForm.controls['tableName'].touched)">
                        Table name is required.
                      </span>
                    </aside>
                  </section>
                  <section class="inputc">

                    <aside class="col-md-12 fieldc"
                      [ngClass]="stepThreeForm.controls['fileType'].touched && stepThreeForm.controls['fileType'].errors?'inputc-error':''">

                      <label class="inputlabel clearafter">
                        <span class="displayblock fl fivemr">File Type </span>
                        <span class="text-danger">*</span>
                      </label>
                      <mat-form-field appearance="fill">
                        <mat-label>{{stepThreeForm.value.fileType}}</mat-label>

                        <mat-select formControlName="fileType">
                          <mat-option [value]="'csv'" (click)="selectFileType('csv')">CSV </mat-option>
                          <mat-option [value]="'avro'" (click)="selectFileType('avro')">AVRO </mat-option>
                          <mat-option [value]="'parquet'" (click)="selectFileType('parquet')">PARQUET </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </aside>
                  </section>

                </div>
              </aside>

              <aside class="fourtynine_p float-right">
                <div class="subtitle">Choose Ingestion Mode</div>
                <p>Choose ingestion mode and the check column the mode will increment on.</p>
                <div>
                  <span class="example-list-section">
                  </span>

                  <section class="inputc">
                    <aside class="col-md-12 fieldc"
                      [ngClass]="stepThreeForm.controls['ingestionMode'].touched && stepThreeForm.controls['ingestionMode'].errors?'inputc-error':''">

                      <label class="inputlabel clearafter">
                        <span class="displayblock fl fivemr">Ingestion Mode</span>
                        <span class="text-danger">*</span>
                      </label>
                      <mat-form-field appearance="fill">
                        <mat-label>{{stepThreeForm.value.ingestionMode}}</mat-label>

                        <mat-select formControlName="ingestionMode">
                          <mat-option [value]="'Full Load'" (click)="selectIngestionMode('Full Load')">Full Load
                          </mat-option>
                          <mat-option [value]="'Increment By Id'" (click)="selectIngestionMode('Increment By Id')">
                            Increment By Id</mat-option>
                          <mat-option [value]="'Increment By Timestamp'"
                            (click)="selectIngestionMode('Increment By Timestamp')">Increment By Timestamp</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </aside>
                  </section>
                  <section class="inputc"
                    *ngIf="stepThreeForm.value.ingestionMode==='Increment By Id' || stepThreeForm.value.ingestionMode==='Increment By Timestamp'">
                    <aside class="col-md-12 fieldc"
                      [ngClass]="stepThreeForm.controls['checkColumn'].touched && stepThreeForm.controls['checkColumn'].errors?'inputc-error':''">
                      <label class="inputlabel clearafter">
                        <span class="displayblock fl fivemr">Check Column </span>
                        <span class="text-danger">*</span>

                      </label>
                      <mat-form-field appearance="fill">
                        <mat-label>Choose Incremental Check Column</mat-label>
                        <mat-select formControlName="checkColumn">
                          <mat-option *ngFor="let column of stepTwoData.columnOptions"
                            [value]="column.destinationColumnName">
                            {{column.destinationColumnName+' ('+column.sourceDataType+')'}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <span class="error-mge mt-2"
                        *ngIf="stepThreeForm.controls['checkColumn'].invalid && (stepThreeForm.controls['checkColumn'].dirty || stepThreeForm.controls['checkColumn'].touched)">
                        Check Column is required when.
                      </span>
                    </aside>
                  </section>
                </div>
              </aside>
            </section>
          </div>
        </div>
        <footer class="tenpt lightgraybdrt clearafter footerbtns">
          <button class="tertiary-active fr btnnext" id="btnnext" type="submit" [disabled]="stepThreeForm.invalid">
            Next
            <i aria-hidden="true" class="fa fa-arrow-right"></i>
          </button>
          <button class="grey-btn fr btnpre" (click)="saveConfiguration(stepThreeForm)"
            routerLink="/project/{{projectName}}/ingest/select-ingestion/stepTwoQuery/{{id}}">
            <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
          </button>
          <button class="grey-btn fr btnpre" *ngIf="showDiv.cancelbtn">
            Cancel
          </button>
          <button class="tertiary-active fr btnnext" *ngIf="showDiv.submitbtn">
            Submit
          </button>
          <button class="grey-btn fr btnpre" *ngIf="showDiv.backbtn" (click)="showDiv.stepone = true; 
                  showDiv.steptwo = false; 
                  showDiv.stepthree = false;
                  showDiv.stepfour = false;
                  showDiv.stepfive = false;
    
                  showDiv.backbtn = false; 
                  showDiv.backbtntwo = false;
                  showDiv.backbtnthree = false; 
                  showDiv.backbtnfour= false;
                  showDiv.backbtnfive = false;
    
                  showDiv.submitbtn = false; 
                  showDiv.nextbtn = true; 
                  showDiv.nextbtntwo = false; 
                  showDiv.nextbtnthree = false; 
                  showDiv.nextbtnfour = false; 
    
                  showDiv.cancelbtn = false;">
            <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
          </button>
          <button class="grey-btn fr btnpre" *ngIf="showDiv.backbtntwo" (click)="showDiv.stepone = false; 
                  showDiv.steptwo = true; 
                  showDiv.stepthree = false;
                  showDiv.stepfour = false;
                  showDiv.stepfive = false;
    
                  showDiv.backbtn = true; 
                  showDiv.backbtntwo = false;
                  showDiv.backbtnthree = false;
                  showDiv.backbtnfour = false;
                  showDiv.submitbtn = false; 
                  showDiv.nextbtn = false; 
                  showDiv.nextbtntwo = true; 
                  showDiv.nextbtnthree = false; 
                  showDiv.nextbtnfour = false; 
    
                  showDiv.cancelbtn = false;">
            <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
          </button>
          <button class="grey-btn fr btnpre" *ngIf="showDiv.backbtnthree" (click)="showDiv.stepone = false; 
                  showDiv.steptwo = true; 
                  showDiv.stepthree = false;
                  showDiv.stepfour = false;
                  showDiv.stepfive = false;
    
                  showDiv.backbtn = false; 
                  showDiv.backbtntwo = true;
                  showDiv.backbtnthree = false; 
                  showDiv.backbtnfour = false; 
                  showDiv.backbtnfive = false; 
    
                  showDiv.submitbtn = false; 
                  showDiv.nextbtn = false; 
                  showDiv.nextbtntwo = false; 
                  showDiv.nextbtnthree = true; 
                  showDiv.nextbtnfour = false; 
                  showDiv.cancelbtn = false;">
            <i aria-hidden="true" class="fa fa-arrow-left"></i> Previous
          </button>
        </footer>
      </form>
    </section>
  </div>
</div>